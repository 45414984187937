import { doRequestAndReturnResult } from "../apiService";
import { analyticsLogEvent } from "../Analytics";
import { RoleAwareProfileWithImage } from "./models/ClanProfileModels";

export const getTaskSharedWithByTaskID = async (
  taskId: string
): Promise<RoleAwareProfileWithImage[]> => {
  analyticsLogEvent("TASK_shared_with_show");
  return doRequestAndReturnResult(
    "get",
    `/usertask/v1/todo/${taskId}/share/profiles`
  );
};
