import { getURlContent } from "./HtmlURLReplacer";

const urlRegexp = /^\b(https?|ftp|file):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]$/gm;
const normalizeHrefUrl = (url: string) => {
  let href = url;
  ['"', "'"].forEach((q) => {
    if (href.startsWith(q) && href.endsWith(q)) {
      href = href.substr(1, href.length - 2);
    }
  });
  if (href.match(urlRegexp)) return href;
  return undefined;
};

const getAnchorLink = (tagContent: string[]): string | undefined => {
  let isAnchor = false;
  let url = null;
  tagContent.forEach((value) => {
    if (value.toLowerCase() === "a") {
      isAnchor = true;
    } else if (value.startsWith("href=")) {
      const href = normalizeHrefUrl(value.substr(5));
      if (href) {
        url = href;
      }
    }
  });
  return isAnchor && url ? url : undefined;
};

export const resolveHtmlContent = async (html: string): Promise<string> => {
  // at the moment we just are trying to find anchors from html
  const anchorInfo = {
    start: -1,
    link: "",
    closing: -1
  };
  let result = html;
  for (let i = 0; i < html.length; i++) {
    if (html.charAt(i) === "<") {
      if (anchorInfo.closing === -1 && anchorInfo.link !== "") {
        anchorInfo.closing = i;
        continue;
      }
      anchorInfo.start = i;
      anchorInfo.link = "";
      anchorInfo.closing = -1;
    }
    if (html.charAt(i) !== ">") {
      continue;
    }
    if (
      anchorInfo.link !== "" &&
      anchorInfo.closing >= 0 &&
      anchorInfo.closing < i - 1
    ) {
      const tagContent = html
        .substr(anchorInfo.closing + 1, i - anchorInfo.closing - 1)
        .trim();
      if (tagContent.toLowerCase() === "/a") {
        const contentLink = await getURlContent(anchorInfo.link);
        if (contentLink) {
          result = `${html.substr(0, anchorInfo.start)}${contentLink}`;
        }
        anchorInfo.start = i;
        anchorInfo.link = "";
        anchorInfo.closing = -1;
      }
      continue;
    }
    if (
      anchorInfo.link === "" &&
      anchorInfo.start >= 0 &&
      anchorInfo.start < i - 1
    ) {
      const tagContent = html
        .substr(anchorInfo.start + 1, i - anchorInfo.start - 1)
        .trim()
        .split(/[\s\xA0]+/);
      const anchorLink = getAnchorLink(tagContent);
      if (anchorLink) {
        anchorInfo.link = anchorLink;
      }
      continue;
    }

    anchorInfo.start = i;
    anchorInfo.link = "";
    anchorInfo.closing = -1;
  }
  return result;
};
