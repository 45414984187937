import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { connect } from "../../data/connect";
import { OauthData } from "../../data/oauth/oauthDataService";
import { ReactComponent as ActivityFeedIcon } from "../../icons/button-icons/activity-feed.svg";
import { ReactComponent as ChatIcon } from "../../icons/button-icons/chat.svg";
import { ReactComponent as MenuIcon } from "../../icons/button-icons/menu.svg";
import * as ROUTES from "../../routes";
import IconButton from "../Buttons/IconButton";
import ClanHeaderRays from "../ClanHeaderRays";

const WelcomeWrapper = styled.div`
  background: #ffffff;
  border-radius: 25px;
  overflow: hidden;
`;

const WelcomeContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 24px 12px;
`;

const MenuWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`;

interface WelcomeCardProps {
  logoUrl?: string;
}

const WelcomeCard: React.FC<WelcomeCardProps> = ({ logoUrl }) => {
  const history = useHistory();

  const navigateTo = (route: string) => () => {
    history.push(route);
  };

  return (
    <WelcomeWrapper>
      <ClanHeaderRays short />
      {/* {logoUrl && <img src={logoUrl} />} */}
      <WelcomeContent>
        <MenuWrapper>
          <IconButton
            icon={<ActivityFeedIcon />}
            onClick={navigateTo(ROUTES.ACTIVITY)}
          />
          <IconButton
            icon={<ChatIcon />}
            onClick={navigateTo(ROUTES.MESSAGES_HUB)}
          />
          <IconButton
            icon={<MenuIcon />}
            onClick={navigateTo(ROUTES.SETTINGS)}
          />
        </MenuWrapper>
      </WelcomeContent>
    </WelcomeWrapper>
  );
};

export default connect<{}, WelcomeCardProps, {}>({
  mapStateToProps: ({ token, userProfile }) => ({
    logoUrl: (token as OauthData).theme?.logo?.full?.url
  }),
  component: WelcomeCard
});
