import React, { useState } from "react";
import Floater from "react-floater";
import styled, { useTheme } from "styled-components";
import { Portal } from "react-portal";

export const StyledTooltip = styled.div`
  position: relative;
  background: white;
  max-width: 80vw;
  border-radius: 10px;
  font-family: "NotoSans";
  background: ${({ theme }) => theme.primary};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
`;

const CloseTooltipBtn = styled.img`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  padding: 0.4rem;
  cursor: pointer;
`;

const InfoTooltipBtn = styled.img`
  display: block;
  margin: 0;
  padding: 0;
`;

export const TooltipHeader = styled.div`
  padding: 1.8rem 1.5rem 0;
  font-weight: 800;
  font-family: Gilroy;
  font-size: 1rem;
  line-height: 1.286rem;
  text-align: center;
  position: relative;
`;

export const TooltipContent = styled.div`
  padding: 1.8rem 1.5rem;
  line-height: 1.3rem;
  text-align: center;

  > p {
    margin: 0;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  cursor: pointer;
`;

interface TooltipComponentProps {
  content: any;
  title?: string;
  close: () => void;
}

const TooltipComponent: React.FC<TooltipComponentProps> = ({
  content,
  title,
  close
}) => {
  return (
    <StyledTooltip>
      <CloseTooltipBtn src="assets/close.svg" alt="Close" onClick={close} />
      {title && <TooltipHeader>{title}</TooltipHeader>}
      <TooltipContent>{content}</TooltipContent>
    </StyledTooltip>
  );
};

interface TooltipProps {
  title?: string;
  content: any;
}

export const InfoTooltip: React.FC<TooltipProps> = ({ title, content }) => (
  <Tooltip title={title} content={content}>
    <InfoTooltipBtn src="/assets/info.svg" alt="info" />
  </Tooltip>
);

const Tooltip: React.FC<TooltipProps> = ({ children, content, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Floater
        open={isOpen}
        component={() => (
          <TooltipComponent
            content={content}
            title={title}
            close={() => setIsOpen(false)}
          ></TooltipComponent>
        )}
        styles={{
          container: {
            display: "block"
          },
          wrapper: {
            display: "block"
          },
          arrow: {
            color: theme.primary
          }
        }}
      >
        <div className="cursor-pointer" onClick={() => setIsOpen(true)}>
          {children}
        </div>
      </Floater>

      {isOpen && (
        <Portal>
          <Backdrop onClick={() => setIsOpen(false)} />
        </Portal>
      )}
    </>
  );
};

export default Tooltip;
