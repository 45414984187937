import React from "react";
import ClanListInterestTemplate from "./ClanListInterestTemplate";
import { INTERESTS_ENDPOINTS } from "../../util/ClanInterestsEndpoints";
import { useTranslation } from "react-i18next";
import { COMMUNITY_SLIDES } from "../../pages/People";
import { FilterGroupType } from "../../util/Filters";

type ClanHobbiesProps = {
  className?: string;
  filters?: FilterGroupType[];
  isParentVisible?: boolean;
  activeIndex: COMMUNITY_SLIDES;
};

const ClanHobbies: React.FC<ClanHobbiesProps> = ({
  className,
  filters,
  activeIndex
}) => {
  const { t } = useTranslation();
  return (
    <ClanListInterestTemplate
      title="Interests"
      filters={filters}
      endpoints={INTERESTS_ENDPOINTS}
      profilesTitle={t("people_overview.interested")}
      itemClassName="grid grid-cols-3auto-middle"
      cardClassName={className}
      activeIndex={activeIndex}
      withAmounts
    />
  );
};

export default ClanHobbies;
