import React from "react";
import { useTranslation } from "react-i18next";

import ClanInfoCard from "../../components/ClanInfoCard";
import FooterButtons, {
  PrimaryFooterButton,
  SecondaryFooterButton
} from "../../components/FooterButtons";
import { ReactComponent as CareIcon } from "../../icons/care.svg";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "./Onboarding";

interface GoldenRuleStepProps {
  setCurrentStep: any;
}

const GoldenRuleStep: React.FC<GoldenRuleStepProps> = ({ setCurrentStep }) => {
  const { t } = useTranslation();

  return (
    <>
      <ClanIonContent>
        <OnboardingWrapper>
          <ClanInfoCard
            title={t("onboarding.golden_rule_heading")}
            bodyParagraphs={[
              t("onboarding.golden_rule_1"),
              t("onboarding.golden_rule_2")
            ]}
            image={<CareIcon />}
          />
        </OnboardingWrapper>
      </ClanIonContent>
      <FooterButtons>
        <SecondaryFooterButton onClick={() => setCurrentStep(6)}>
          {t("general.back")}
        </SecondaryFooterButton>
        <PrimaryFooterButton onClick={() => setCurrentStep(8)}>
          {t("onboarding.next")}
        </PrimaryFooterButton>
      </FooterButtons>
    </>
  );
};

export default GoldenRuleStep;
