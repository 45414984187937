import { Device } from "@capacitor/device";
import Rollbar from "rollbar";
import { configuration } from "../configuration";

class RollbarManager {
  rollbar: Rollbar | null = null;

  constructor() {
    if (configuration.rollbarAccessToken) {
      this.initRollbar();
    } else {
      console.info("Rollbar disabled - could not find access token.");
    }
  }

  public logError(error: Error, ...extraContext: Array<any>) {
    console.error(error);
    this.getInstance((rollbar) => rollbar.error(error, ...extraContext));
  }

  public addCustomContext(customContext = {}) {
    this.getInstance((rollbar) => {
      rollbar.configure({
        payload: {
          ...customContext
        }
      });
    });
  }

  private getInstance(cb: (rollbar: Rollbar) => void) {
    this.rollbar && cb(this.rollbar);
  }

  private async initRollbar() {
    const info = await Device.getInfo();

    this.rollbar = new Rollbar({
      accessToken: configuration.rollbarAccessToken,
      captureUncaught: true,
      captureUnhandledRejections: true,
      captureIp: false,
      environment: process.env.NODE_ENV,
      payload: {
        custom: {
          version: configuration.appVersion,
          build: configuration.appBuild,
          platform: info.platform
        }
      }
    });
  }
}

export default new RollbarManager();
