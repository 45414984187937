import {
  IonButton,
  IonChip,
  IonFooter,
  IonIcon,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonText
} from "@ionic/react";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  withRouter
} from "react-router-dom";
import ClanFooterBtn from "../../components/ClanFooterBtn";
import ClanHeaderRays from "../../components/ClanHeaderRays";
// import ClanLoader from "../../components/ClanLoader";
import { connect } from "../../data/connect";
import * as ROUTES from "../../routes";
import {
  SharedTaskEvent,
  UserTaskResultDetails
} from "../../util/models/PlannerUserTaskModels";
import { FeedTypes } from "../../util/Feeds";
import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import { useTranslation } from "react-i18next";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import { ClanIonContent } from "./PublicFeeds";
import { AuthorizedApiResult } from "../../util/ApiService";

type FeedTaskCommentsRouteProps = RouteComponentProps<{
  task_id: string;
}>;

type OwnProps = {
  feedType: FeedTypes;
};

// TODO this file is incomplete and has to be updated before use
interface FeedTaskCommentsProps extends OwnProps, FeedTaskCommentsRouteProps {}

const FeedTaskComments: React.FC<FeedTaskCommentsProps> = ({
  feedType,
  match
}) => {
  const history = useHistory();
  const location = useLocation();
  const taskId = match.params.task_id;
  const locationState = location.state as { task: UserTaskResultDetails };
  const task = locationState?.task ? locationState?.task : null;
  // TODO The new endpoint supports retrieving all task data so there is no need to pass task as prop anymore
  const commentApiEndpoint = "usertask/v1/todo/shared/{task_id}".replace(
    "{task_id}",
    taskId
  );

  const [taskFeed, setTaskFeed] = useState<SharedTaskEvent[]>();

  // const [loading, setLoading] = useState<boolean>(false);

  const loadTaskComments = async () => {
    setAnalyticsScreenName("TASK_COMMENTS");
    analyticsLogEvent("TASK_COMMENTS_list");
    const result = await AuthorizedApiResult.get<{
      events: SharedTaskEvent[];
    }>(commentApiEndpoint);
    setTaskFeed(result.events);
  };

  const ionContent = useRef<HTMLIonContentElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (location.pathname.endsWith("comments")) loadTaskComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    loadTaskComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentApiEndpoint, taskId]);

  const doRefresh = (event: CustomEvent) => {
    loadTaskComments().then(event.detail.complete);
  };

  const scrollToTop = () => {
    if (ionContent.current) ionContent.current.scrollToTop(500);
  };

  return (
    <ClanDesktopLayout>
      <IonPage>
        <ClanIonContent ref={ionContent}>
          {/* <ClanLoader message={t("general.loading")} showLoading={loading} /> */}
          <IonRefresher
            slot="fixed"
            onIonRefresh={doRefresh}
            pullFactor={0.5}
            pullMin={100}
            pullMax={200}
          >
            <IonRefresherContent />
          </IonRefresher>

          <ClanHeaderRays />
          <div className="flex flex-col items-center">
            <IonText>
              <h2>{task?.title}</h2>
            </IonText>
            <IonText>
              <span className={`self-center flex flex-wrap my-0`}>
                {task?.dueDate && (
                  <IonChip className="bg-clanGray-100 m-0 text-clanH5 self-start">
                    <IonIcon
                      size="small"
                      className="text-clanGray-200"
                      icon="/assets/planner/duedate.svg"
                    />
                    <IonLabel className="text-clanGray-200">
                      {format(new Date(task.dueDate), "LLL d")}
                    </IonLabel>
                  </IonChip>
                )}

                {task?.labels &&
                  task?.labels.map((label: string, index: number) => (
                    <IonChip
                      key={index}
                      className="bg-clanGray-100 m-0 text-clanH5 self-start"
                    >
                      <IonLabel className="text-clanGreen-100">
                        #{label}
                      </IonLabel>
                    </IonChip>
                  ))}
              </span>
            </IonText>
            <IonText>
              <IonButton
                fill="clear"
                className="m-8 rounded-full border border-solid border-clanGreen-100"
              >
                <IonLabel className="text-clanGreen-100">
                  {t("feed_task_comments.add_to_planner")}
                </IonLabel>
              </IonButton>
            </IonText>
          </div>
          <IonList className="bg-gray-100">
            {taskFeed &&
              taskFeed?.map((event: SharedTaskEvent, index: number) => {
                return <IonText key={index}>{event.type}</IonText>;
              })}
          </IonList>
          <IonSegment>
            <IonSegmentButton
              className="font-extrabold font-gilroy text-clanGreen-100"
              onClick={scrollToTop}
            >
              {t("general.scroll")}
            </IonSegmentButton>
          </IonSegment>
        </ClanIonContent>
        <IonFooter>
          <ClanFooterBtn
            buttonObjects={[
              {
                title: t("general.back"),
                disabled: false,
                callback: () => {
                  history.push(ROUTES.PUBLIC_FEEDS, { feedType });
                }
              }
            ]}
          />
        </IonFooter>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<OwnProps, {}, {}>({
  component: withRouter(FeedTaskComments)
});
