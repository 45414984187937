import {
  ModalAction,
  RemoveModalAction,
  ShowModalAction
} from "./modals.actions";
import { ModalsState } from "./modals.state";

export function modalsReducer(
  state: ModalsState,
  action: ShowModalAction | RemoveModalAction
): ModalsState {
  switch (action.type) {
    case ModalAction.ShowModal: {
      return {
        modals: [...state.modals, action.data]
      };
    }

    case ModalAction.RemoveModal: {
      const newModals = [...state.modals];

      const indexOfModal = state.modals.findIndex(
        (modal) => modal.id === action.id
      );

      if (indexOfModal >= 0) {
        newModals.splice(indexOfModal, 1);
      }

      return {
        modals: newModals
      };
    }
    default:
      return state;
  }
}
