import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FlexRow } from "./Flexbox";
import { H4Style, H6Style } from "./Typography";

const MoodText = styled.div<{ bold?: boolean }>`
  ${({ bold }) => (bold ? H4Style : H6Style)};
  margin: 0;
`;

const MoodIcon = styled.img`
  width: 18px;
  height: 18px;
`;

interface MoodIndicatorProps {
  format: string;
  mood: string;
  bold?: boolean;
}

const MoodIndicator: React.FC<MoodIndicatorProps> = ({
  format,
  mood,
  bold
}) => {
  const { t } = useTranslation();

  return (
    <FlexRow align="center" columnGap="4px">
      <MoodText bold={bold}>
        {t(format, {
          how: t(`moods.${mood?.toLowerCase()}`).toLowerCase()
        })}
      </MoodText>
      <MoodIcon alt={mood} src={`/assets/mood/${mood?.toLowerCase()}.svg`} />
    </FlexRow>
  );
};

export default MoodIndicator;
