import { IonButton, IonFooter, IonTextarea } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as MessageInputIcon } from "../icons/message_input.svg";
import ClanCameraAccess from "../util/ClanCameraAccess";
import { removeImageFromPost } from "../util/ClanPostAPIService";
import ImageButton from "./Planner/ImageButton";
import MoodButton from "./Planner/MoodButton";
import MoodModal from "./Planner/MoodModal";
import QuestionDescriptionButton from "./Planner/QuestionDescriptionButton";
import { PostFont } from "./Typography";

const SyledTextArea = styled(IonTextarea)`
  ${PostFont}
  margin: 16px;
`;
const StyledContent = styled.div`
  border-radius: 10px 10px 0 0;
  background-color: white;
  padding: 5px 0;
`;

const MessageInputWrapper = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${({ height }) =>
    height ? (height <= 200 ? height.toString() : "200") : "auto"}px;
  margin-left: 12px;
  margin-right: 4px;
`;

type ClanDiscussionFooterInputProps = {
  placeholder: string;
  onSubmit: () => void;
  ctaLabel?: string;
  canSubmit?: boolean;
  rows?: number;
  text: string | undefined;
  title?: string | undefined;
  onChangeTitle: (text: string | undefined) => void;
  onChangeText: (text: string | undefined) => void;
  onChangeMood: (text: string | undefined) => void;
  onChangeImage: (text: string | undefined) => void;
  mood: string | undefined;
  image: string | undefined;
  removeLocalImage?: (postId: string) => void;
  postId?: string;
  questionMode?: boolean;
  setOpenPostBar?: (open: boolean) => void;
  openPostBar?: boolean;
};

const ClanDiscussionFooterInput: React.FC<ClanDiscussionFooterInputProps> = ({
  placeholder,
  onSubmit,
  ctaLabel,
  questionMode = false,
  text,
  title,
  onChangeText,
  onChangeTitle,
  onChangeMood,
  onChangeImage,
  mood,
  image,
  removeLocalImage,
  postId,
  setOpenPostBar,
  openPostBar = true,
  rows = 3
}) => {
  const [addQuestionDescMode, setAddQuestionDescMode] = useState<boolean>(
    false
  );
  const [moodModalOpen, setMoodModalOpen] = useState<boolean>(false);
  const textRef = useRef<HTMLIonTextareaElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (text && text?.length !== 0) textRef.current?.setFocus();
    const textarea = textRef.current?.querySelector(".native-textarea");

    if (text?.length === 0) {
      textarea && textarea.setAttribute("style", "height:initial");
      inputWrapperRef.current?.setAttribute("style", "height:initial");
    }
    textarea &&
      textarea.setAttribute(
        "style",
        `height:${textarea.scrollHeight <= 200 ? textarea.scrollHeight : 200}px`
      );
  }, [text]);

  useEffect(() => {
    // To fix the ionic issue where the initial height of textarea is zero
    const textarea = textRef.current?.querySelector(".native-textarea");
    textarea?.setAttribute("style", "height: initial");
  }, []);

  function submit() {
    if (addQuestionDescMode) {
      setAddQuestionDescMode(false);
      return;
    }
    setOpenPostBar && setOpenPostBar(false);
    onSubmit();
  }

  return (
    <IonFooter
      onClick={() => {
        setOpenPostBar && setOpenPostBar(true);
      }}
    >
      <div className="bg-clanYellow-100 pt-3">
        <MoodModal
          isOpen={moodModalOpen}
          onDismiss={() => setMoodModalOpen(false)}
          onMoodSelect={(newMood) => {
            onChangeMood(newMood);
            setMoodModalOpen(false);
          }}
        />
        <div>
          <StyledContent>
            <MessageInputWrapper
              ref={inputWrapperRef}
              height={
                textRef.current?.querySelector(".native-textarea")?.scrollHeight
              }
            >
              <SyledTextArea
                ref={textRef}
                role="textbox"
                maxlength={2000}
                rows={rows}
                placeholder={
                  questionMode
                    ? addQuestionDescMode
                      ? t("clan_edit_post_form.add_info")
                      : t("clan_edit_post_form.question")
                    : placeholder
                }
                className="leading-6 m-4"
                value={
                  questionMode ? (addQuestionDescMode ? text : title) : text
                }
                autocapitalize="sentences"
                onIonChange={(e: any) =>
                  questionMode
                    ? addQuestionDescMode
                      ? onChangeText(e.detail.value || "")
                      : onChangeTitle(e.detail.value || "")
                    : onChangeText(e.detail.value || "")
                }
              />
              <IonButton
                style={{
                  "--padding-start": "1rem",
                  "--padding-end": "1rem"
                }}
                onClick={submit}
                disabled={
                  (questionMode && !addQuestionDescMode && !title) ||
                  (!questionMode && !text && !image)
                }
                className={`h-clanBtn text-clanH2 align-middle ${
                  (questionMode && addQuestionDescMode && ctaLabel) ||
                  (!questionMode && ctaLabel)
                    ? "bg-clanYellow-100 text-black rounded-full"
                    : ""
                }`}
                color="tertiary"
                fill="clear"
              >
                {questionMode ? (
                  <>
                    {addQuestionDescMode && ctaLabel ? (
                      <p className="tracking-normal">{ctaLabel}</p>
                    ) : (
                      <MessageInputIcon />
                    )}
                  </>
                ) : (
                  <>
                    {ctaLabel ? (
                      <p className="tracking-normal">{ctaLabel}</p>
                    ) : (
                      <MessageInputIcon />
                    )}
                  </>
                )}
              </IonButton>
            </MessageInputWrapper>
          </StyledContent>

          {openPostBar && (
            <>
              {((questionMode && !addQuestionDescMode) || !questionMode) && (
                <div
                  id="task_add_bar"
                  className="flex overflow-x-scroll border border-solid border-r-0 border-l-0 border-gray-200 items-center bg-white"
                >
                  <MoodButton
                    mood={mood}
                    onClick={() => {
                      setMoodModalOpen(true);
                    }}
                    onClear={() => onChangeMood(undefined)}
                  />
                  <ImageButton
                    image={image}
                    onClick={() => {
                      ClanCameraAccess.TakePhoto().then((result) =>
                        onChangeImage(result.image)
                      );
                    }}
                    onClear={() => {
                      postId
                        ? removeImageFromPost(postId).then(() => {
                            onChangeImage(undefined);
                            removeLocalImage && removeLocalImage(postId);
                          })
                        : onChangeImage(undefined);
                    }}
                  />
                  {questionMode && (
                    <QuestionDescriptionButton
                      hasDescription={!!text && !addQuestionDescMode}
                      onClear={() => {
                        onChangeText(undefined);
                        setAddQuestionDescMode(false);
                      }}
                      onClick={() => setAddQuestionDescMode(true)}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </IonFooter>
  );
};

export default ClanDiscussionFooterInput;
