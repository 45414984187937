import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory } from "react-router";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import CategoryHeader from "../../components/CategoryHeader";
import FlagListItem from "../../components/ListItem/FlagListItem";
import ListItem from "../../components/ListItem/ListItem";
import FlagModal from "../../components/Modals/FlagModal";
import ReportModal from "../../components/Modals/ReportModal";
import PaywallCard from "../../components/PaywallCard";
import { SectionContent } from "../../components/Section";
import { setLatestReportTimestamp } from "../../data/app/app.actions";
import { connect } from "../../data/connect";
import { ReactComponent as ReportIcon } from "../../icons/button-icons/report.svg";
import * as ROUTES from "../../routes";
import { ChartResultFlagWithProfiles } from "../../util/Analytics";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { UserComplaintListResult } from "../../util/Complaint";
import { EmptyText } from "./StatsDashboard";

interface StateProps {
  flagsPermissions: boolean;
  reportsPermissions: boolean;
}

interface DispatchProps {
  dispatchSetLatestReportTimestamp: typeof setLatestReportTimestamp;
}

interface OwnProps {
  flags: Array<ChartResultFlagWithProfiles>;
  reports: Array<UserComplaintListResult>;
  onReportAction: (report: UserComplaintListResult) => void;
}

interface AlertsProps extends DispatchProps, StateProps, OwnProps {}

const Alerts: React.FC<AlertsProps> = ({
  flagsPermissions,
  reportsPermissions,
  flags,
  reports,
  onReportAction,
  dispatchSetLatestReportTimestamp
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const FLAGS_DEMO = [
    {
      message: `<b>${t("stats.flag_demo_members")}</b> ${t(
        "stats.flag_demo_anxious"
      )}`,
      flag: "RED"
    },
    {
      message: `<b>${t("stats.flag_demo_members")}</b> ${t(
        "stats.flag_demo_connected"
      )}`
    }
  ] as Array<ChartResultFlagWithProfiles>;

  const [reportWasSeen, setReportWasSeen] = useState<boolean>(false);

  useEffect(() => {
    setAnalyticsScreenName("ANALYTICS_INSIGHT_TAB");
    setReportWasSeen(true);
  }, []);

  useEffect(() => {
    return () => {
      reports[0]?.createdAt &&
        reportWasSeen &&
        dispatchSetLatestReportTimestamp(reports[0]?.createdAt);
    };
  }, [reports, dispatchSetLatestReportTimestamp, reportWasSeen]);

  const openFlagModal = (key: string) =>
    history.push(ROUTES.STATS_DASHBOARD_FLAG.replace(":flag", key));

  const openReportModal = (id: string) =>
    history.push(ROUTES.STATS_DASHBOARD_REPORT.replace(":report", id));

  const getNewReportId = (activeReportId: string, step: number) => {
    const newReportIndex =
      (reports.findIndex((r) => r.id === activeReportId) +
        reports.length +
        step) %
      reports.length;

    return reports[newReportIndex].id;
  };

  const openPreviousReport = (activeReportId: string) => () =>
    openReportModal(getNewReportId(activeReportId, -1));

  const openNextReport = (activeReportId: string) => () =>
    openReportModal(getNewReportId(activeReportId, 1));

  const getNewFlagKey = (activeFlagKey: string, step: number) => {
    const newFlagIndex =
      (flags.findIndex((flag) => flag.key === activeFlagKey) +
        flags.length +
        step) %
      flags.length;

    return flags[newFlagIndex].key;
  };

  const openPreviousFlag = (activeFlagKey: string) => () =>
    openFlagModal(getNewFlagKey(activeFlagKey, -1));

  const openNextFlag = (activeFlagKey: string) => () =>
    openFlagModal(getNewFlagKey(activeFlagKey, 1));

  const navigateToAlerts = () => history.push(ROUTES.STATS_DASHBOARD_ALERTS);

  return (
    <div>
      <Switch>
        <Route
          exact
          path={ROUTES.STATS_DASHBOARD_FLAG}
          render={({
            match: {
              params: { flag }
            }
          }) => {
            const activeFlag = flags.find((f) => f.key === flag);

            return (
              activeFlag && (
                <FlagModal
                  flag={activeFlag}
                  close={navigateToAlerts}
                  openNext={openPreviousFlag(activeFlag.key)}
                  openPrevious={openNextFlag(activeFlag.key)}
                />
              )
            );
          }}
        />

        <Route
          exact
          path={ROUTES.STATS_DASHBOARD_REPORT}
          render={({
            match: {
              params: { report }
            }
          }) => {
            const activeReport = reports.find((r) => r.id === report);

            return (
              activeReport && (
                <ReportModal
                  report={activeReport}
                  close={navigateToAlerts}
                  onReportAction={onReportAction}
                  openNext={openNextReport(activeReport.id)}
                  openPrevious={openPreviousReport(activeReport.id)}
                />
              )
            );
          }}
        />
      </Switch>

      <CategoryHeader
        title={t("stats.flags")}
        tooltipText={t("stats.flags_tooltip")}
      />

      <SectionContent>
        {flagsPermissions ? (
          flags.length > 0 ? (
            flags.map((chartFlag, index) => (
              <FlagListItem
                key={index}
                flag={chartFlag}
                onClick={() => {
                  openFlagModal(chartFlag.key);
                  analyticsLogEvent("STATS_FLAG_CLICKED");
                }}
              />
            ))
          ) : (
            <EmptyText>{t("stats.flags_no")}</EmptyText>
          )
        ) : (
          <>
            <PaywallCard content={t("paywall.flags")} />
            {FLAGS_DEMO.map((flag, index) => (
              <FlagListItem key={index} flag={flag} />
            ))}
          </>
        )}
      </SectionContent>

      <CategoryHeader
        title={t("stats.reports")}
        tooltipText={t("stats.reports_tooltip")}
      />

      <SectionContent>
        {reports.length > 0 ? (
          reports.map((reportFlag, index) => (
            <ListItem
              key={index}
              avatar={
                reportFlag.reporterUser.value.images.thumbnail?.url ||
                "https://www.gravatar.com/avatar?d=mm&s=140"
              }
              primary={t("stats.reported", {
                recipient: reportFlag.reporterUser?.value?.firstName,
                reporter: reportFlag.reportedUser?.value?.firstName
              })}
              icon={<ReportIcon />}
              onClick={() => {
                openReportModal(reportFlag.id);
                analyticsLogEvent("STATS_REPORT_CLICKED");
              }}
            />
          ))
        ) : (
          <EmptyText>{t("stats.reports_no")}</EmptyText>
        )}
      </SectionContent>
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: ({ token }, props) => ({
    flagsPermissions: UserEnabledProperties.AnalyticsStatus.schoolFlags(token),
    reportsPermissions: UserEnabledProperties.Accessibility.antiBullyReport(
      token
    )
  }),
  mapDispatchToProps: {
    dispatchSetLatestReportTimestamp: setLatestReportTimestamp
  },
  component: Alerts
});
