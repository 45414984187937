import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import {
  getAnalytics,
  logEvent,
  setCurrentScreen,
  setUserId
} from "firebase/analytics";

import { firebaseApp } from "./configuration";
import { isNativePlatform } from "./util/AppUtils";
import RollbarManager from "./util/RollbarManager";

interface FbAnalytics {
  /**
   * Set Analytics userID
   */
  analyticsSetUserId(userId: string | undefined): Promise<void>;
  /**
   * Send Analytics Log event
   */
  analyticsLogEvent(event: string, params?: object): Promise<void>;

  /**
   * Set Analytics screen name
   */
  setAnalyticsScreenName(screen: string): Promise<void>;
}

class MobileAnalytics implements FbAnalytics {
  analyticsSetUserId = async (userId: string | undefined = "ANONYMOUS") => {
    try {
      await FirebaseAnalytics.setUserId({ userId });
    } catch (e) {
      RollbarManager.logError(e as Error);
    }
  };

  analyticsLogEvent = async (event: string, params = {}) => {
    try {
      await FirebaseAnalytics.logEvent({ name: event, params });
    } catch (e) {
      RollbarManager.logError(e as Error);
    }
  };

  setAnalyticsScreenName = async (screen: string) => {
    try {
      await FirebaseAnalytics.setScreenName({
        screenName: screen.toLowerCase()
      });
    } catch (e) {
      RollbarManager.logError(e as Error);
    }
  };
}

class PwaAnalytics implements FbAnalytics {
  analyticsSetUserId = async (userId: string | undefined = "ANONYMOUS") => {
    try {
      const analytics = getAnalytics(firebaseApp);
      setUserId(analytics, userId);
    } catch (e) {
      RollbarManager.logError(e as Error);
    }
  };

  analyticsLogEvent = async (event: string, params?: object) => {
    try {
      const analytics = getAnalytics(firebaseApp);
      logEvent(analytics, event, params);
    } catch (e) {
      RollbarManager.logError(e as Error);
    }
  };

  setAnalyticsScreenName = async (screen: string) => {
    try {
      const analytics = getAnalytics(firebaseApp);
      setCurrentScreen(analytics, screen);
    } catch (e) {
      RollbarManager.logError(e as Error);
    }
  };
}

let analytics: FbAnalytics | undefined;
if (process.env.NODE_ENV !== "test") {
  if (!isNativePlatform) {
    analytics = new PwaAnalytics();
    // noinspection JSIgnoredPromiseFromCall
    analytics.setAnalyticsScreenName("CLANBEAT_DESKTOP");
  } else {
    analytics = new MobileAnalytics();
    // noinspection JSIgnoredPromiseFromCall
    analytics.setAnalyticsScreenName("CLANBEAT_MOBILE");
  }
}

export const analyticsSetUserId = async (
  userId: string | undefined = "ANONYMOUS"
) => {
  if (!analytics) return;
  await analytics.analyticsSetUserId(userId);
};

export const analyticsLogEvent = async (
  event: string,
  params?: object
): Promise<Error | undefined> => {
  try {
    analytics?.analyticsLogEvent(event, params);
  } catch (error) {
    return error as Error;
  }
};

export const setAnalyticsScreenName = async (screen: string) => {
  analytics?.setAnalyticsScreenName(screen);
};
