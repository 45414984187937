import {
  IonButton,
  IonFooter,
  IonPage,
  IonProgressBar,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import { useAsync, useAsyncEffect } from "@react-hook/async";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { analyticsLogEvent, setAnalyticsScreenName } from "../Analytics";
import ClanDesktopLayout from "../components/ClanDesktopLayout";
import ClanLoader from "../components/ClanLoader";
import ClanSelectPersonModal from "../components/ClanSelectPersonModal";
import { SmoothRays } from "../components/ClanSmoothRays";
import { InfoTooltip } from "../components/Tooltip";
import { setAppError, setRecievedFirstSurvey } from "../data/app/app.actions";
import { getRecievedFirstSurveyCookie } from "../data/app/appDataService";
import { connect } from "../data/connect";
import { showQuickHelpModal } from "../data/modals/modals.actions";
import { ModalID } from "../data/modals/modals.state";
import { OauthData } from "../data/oauth/oauthDataService";
import { showToast } from "../data/toasts/toasts.actions";
import { ReactComponent as LlamaIcon } from "../icons/llama.svg";
import * as ROUTES from "../routes";
import {
  answerWellBeingSurveyQuestion,
  getNextWellBeingSurveyQuestion,
  getWellBeingLabelAndImages,
  skipWellBeingSurveyQuestion,
  WellBeingResponseTranslated,
  WellBeingResultType,
  WellBeingSurveyQuestion,
  WellBeingSurveyQuestionResult,
  WellBeingSurveyQuestionResultMessage,
  WellBeingSurveyQuestionResultMessageType,
  WellBeingSurveyResponseType
} from "../util/api/WellBeingService";
import { ApiRequestError, ApiResponseErrorType } from "../util/ApiService";
import {
  isReadyStateLoading,
  isReadyStatePending
} from "../util/AsyncEffectFilter";
import { ClanIonContent } from "./DiscussionFeed/PublicFeeds";

const ImageWrapper = styled.div`
  text-align: center;
  border-bottom: 2px solid ${({ theme }) => theme.primary};
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled.div`
  > * {
    font-size: 1.286rem;
    line-height: 1.571rem;
    font-family: Gilroy;
  }
`;

const SurveyIconWrapper = styled.div`
  width: 80px;
  height: 80px;
`;

interface StateProps {
  profileId?: number;
}

interface DispatchProps {
  setAppErrorConnect: typeof setAppError;
  setRecievedFirstSurveyConnect: typeof setRecievedFirstSurvey;
  dispatchToastConnect: typeof showToast;
  dispatchQuickHelpModal: typeof showQuickHelpModal;
}

type ModalMessageData = {
  text: string;
  cta: () => void;
  ctaText: string;
  chatOption?: boolean;
};

const Survey: React.FC<StateProps & DispatchProps> = ({
  profileId,
  setAppErrorConnect,
  setRecievedFirstSurveyConnect,
  dispatchToastConnect,
  dispatchQuickHelpModal
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedResponse, setSelectedResponse] = useState<number | undefined>(
    undefined
  );
  const [quickHelpKey, setQuickHelpKey] = useState<string | null>(null);
  const [surveyQuestion, setSurveyQuestion] = useState<
    WellBeingSurveyQuestion | undefined
  >();
  const [surveyQuestionResponses, setSurveyQuestionResponses] = useState<
    WellBeingResponseTranslated<WellBeingResultType>[]
  >([]);
  const [messageData, setMessageData] = useState<ModalMessageData | undefined>(
    undefined
  );
  const [questionMessages, setQuestionMessages] = useState<
    WellBeingSurveyQuestionResultMessage[]
  >();

  const [showChatWithTeacher, setShowChatWithTeacher] = useState<boolean>(
    false
  );

  const parseQuestionOrMessage = (
    questionOrMessage: WellBeingSurveyQuestion | WellBeingSurveyQuestionResult
  ) => {
    if (questionOrMessage.resultType === WellBeingSurveyResponseType.question) {
      const question = questionOrMessage as WellBeingSurveyQuestion;
      setAnalyticsScreenName(
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        "WELL_BEING_" + question.group + ":" + question.key
      );
      analyticsLogEvent("WELL_BEING_ACTION_VIEW");
      setSurveyQuestion(question);
      if (
        question.type === "number" &&
        question.minimumNumber &&
        question.maximumNumber
      ) {
        if (question.minimumNumber === 1 && question.maximumNumber === 4) {
          setSurveyQuestionResponses(
            getWellBeingLabelAndImages(
              question.minimumNumber,
              question.maximumNumber
            )
          );
        } else {
          // TODO: skip? - we do not have solution predefined for this case
        }
      }
    } else if (
      questionOrMessage.resultType === WellBeingSurveyResponseType.message
    ) {
      const message = questionOrMessage as WellBeingSurveyQuestionResult;

      if (quickHelpKey) {
        dispatchQuickHelp(
          quickHelpKey,
          message.messages.map((m) => m.message)
        );
        setQuickHelpKey(null);
      } else {
        setQuestionMessages(message.messages);
      }
    }
  };

  useEffect(() => {
    if (questionMessages && questionMessages[0]?.message) {
      setMessageData({
        text: questionMessages[0].message,
        cta: () => {
          if (questionMessages.length >= 2) {
            setQuestionMessages(questionMessages.splice(1));
          } else {
            setMessageData(undefined);
            dispatchToastConnect(t("survey.thank_you"), undefined, "info");
            if (profileId) {
              history.push(
                ROUTES.PROFILE_TRENDS.replace(
                  ":profileId",
                  profileId.toString()
                )
              );
            }
          }
        },
        ctaText: "OK",
        chatOption:
          questionMessages[0].type ===
          WellBeingSurveyQuestionResultMessageType.negativeStreak
      });
    } else setMessageData(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionMessages]);

  const [answerNumberStatus, answerNumber] = useAsync(async () => {
    const _surveyQuestionId = surveyQuestion?.id;
    const _quickHelpKey = surveyQuestion?.key;
    const answerValue = selectedResponse;
    if (answerValue === undefined) return;
    if (!_surveyQuestionId) {
      return;
    }
    setSelectedResponse(undefined);
    setSurveyQuestion(undefined);

    if (answerValue === 1 && _quickHelpKey) {
      setQuickHelpKey(_quickHelpKey);
    }

    // noinspection ES6MissingAwait
    analyticsLogEvent("WELL_BEING_ACTION_ANSWER");
    try {
      const result = await answerWellBeingSurveyQuestion(
        _surveyQuestionId,
        answerValue
      );
      parseQuestionOrMessage(result);
    } catch (error) {
      resolveQuestionOrMessageFetchingError(error, true);
    } finally {
      if (quickHelpKey) {
        dispatchQuickHelp(quickHelpKey, [
          t("quick_help_modal.p1"),
          t("quick_help_modal.p2")
        ]);
      }
      setSelectedResponse(undefined);
    }
  });

  const dispatchQuickHelp = (wellbeingKey: string, messages: Array<string>) => {
    switch (wellbeingKey) {
      case "wellbeing_morning_start_day":
        return dispatchQuickHelpModal(ModalID.QuickHelpDailyStart, messages);
      case "wellbeing_daily_evening_day_evaluation":
        return dispatchQuickHelpModal(
          ModalID.QuickHelpDailyEvaluation,
          messages
        );
      case "wellbeing_weekly_start_feelings_start":
        return dispatchQuickHelpModal(ModalID.QuickHelpWeeklyStart, messages);
      case "wellbeing_weekly_end_day_evaluation":
        return dispatchQuickHelpModal(
          ModalID.QuickHelpWeeklyEvaluation,
          messages
        );
    }
  };

  const resolveQuestionOrMessageFetchingError = useCallback(
    (error: ApiRequestError, isFromAnswering: boolean) => {
      if (error.type === ApiResponseErrorType.HTTP_NOT_FOUND) {
        if (isFromAnswering) {
          dispatchToastConnect(t("survey.thank_you"), undefined, "info");
        } else {
          dispatchToastConnect(t("survey.no_pending"), undefined, "info");
        }
        if (profileId) {
          history.push(
            ROUTES.PROFILE_TRENDS.replace(":profileId", profileId.toString())
          );
        }
      } else setAppErrorConnect(error, true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatchToastConnect, history, setAppErrorConnect, t]
  );

  const [nextStatus, callNext] = useAsync(async () => {
    setRecievedFirstSurveyConnect(true);
    setMessageData(undefined);
    try {
      const result = await getNextWellBeingSurveyQuestion();
      parseQuestionOrMessage(result);
    } catch (error) {
      resolveQuestionOrMessageFetchingError(error, true);
    }
    // TODO errors are swallowed up
  });

  const showSurveyMessage = () => {
    setMessageData({
      text:
        `
          <p>` +
        t("survey.welcome") +
        `</p>
          <p>` +
        t("survey.welcome_message_1") +
        `</p>
          <p>` +
        t("survey.welcome_message_2") +
        `</p>
          <p>` +
        t("survey.welcome_message_3") +
        `</p>
          <p>` +
        t("survey.welcome_message_4") +
        `</p>
          `,
      cta: callNext,
      ctaText: t("survey.start")
    });
  };

  const checkQuestionStatus = useAsyncEffect(async () => {
    await setAnalyticsScreenName("WELL_BEING_PAGE");
    const hasFirstQuestionAnswered = await getRecievedFirstSurveyCookie();

    if (hasFirstQuestionAnswered) {
      await doInitStatus();
    } else {
      showSurveyMessage();
    }
  }, []);

  const [initStatus, doInitStatus] = useAsync(async () => {
    try {
      const result = await getNextWellBeingSurveyQuestion();
      parseQuestionOrMessage(result);
    } catch (error) {
      resolveQuestionOrMessageFetchingError(error, false);
    }
  });

  const [skipStatus, doCallSkip] = useAsync(async () => {
    const _surveyQuestionId = surveyQuestion?.id;
    if (!_surveyQuestionId) {
      return;
    }
    setSurveyQuestion(undefined);
    // noinspection ES6MissingAwait
    analyticsLogEvent("WELL_BEING_ACTION_SKIP");
    try {
      const result = await skipWellBeingSurveyQuestion(_surveyQuestionId);
      parseQuestionOrMessage(result);
    } catch (error) {
      resolveQuestionOrMessageFetchingError(error, true);
    }
  });

  return (
    <ClanDesktopLayout>
      <IonPage className="flex justify-start">
        <ClanLoader
          showLoading={isReadyStateLoading(
            checkQuestionStatus,
            initStatus,
            nextStatus,
            skipStatus,
            answerNumberStatus
          )}
        />
        {showChatWithTeacher && (
          <ClanSelectPersonModal
            onClose={() => setShowChatWithTeacher(false)}
            isVisible={showChatWithTeacher}
            title={t("survey.adult")}
            description={t("survey.adult_message")}
            onlyTeachers
          />
        )}
        {surveyQuestion &&
          !messageData &&
          isReadyStatePending(initStatus, nextStatus) && (
            <>
              <SmoothRays />
              <ClanIonContent>
                <div className="px-clanFlowStepScreenEdge text-center flex flex-col justify-center items-center min-h-full">
                  <h2 className="m-0 mb-1">{surveyQuestion?.title}</h2>
                  <div className="m-2">
                    <InfoTooltip content={t("survey.visibility")} />
                  </div>
                  <div className="flex flex-col items-center mt-4 cursor-pointer">
                    {surveyQuestionResponses.map(
                      ({ result, component: Component }) => (
                        <div
                          key={result.result}
                          className="p-2 flex"
                          onClick={() => setSelectedResponse(result.result)}
                        >
                          <SurveyIconWrapper>
                            <Component
                              scale={2}
                              animated
                              active={selectedResponse === result.result}
                            />
                          </SurveyIconWrapper>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </ClanIonContent>
              <IonFooter className="ion-no-border">
                <IonSegment mode="md">
                  <IonSegmentButton
                    className="font-gilroy font-light bg-white"
                    onClick={() => doCallSkip()}
                  >
                    {t("survey.skip")}
                  </IonSegmentButton>
                </IonSegment>
                <IonSegment className="bg-clanYellow-100">
                  <IonSegmentButton
                    className="font-extrabold font-gilroy text-black bg-clanYellow-100"
                    disabled={!selectedResponse}
                    onClick={() => answerNumber()}
                  >
                    {t("survey.next")}
                  </IonSegmentButton>
                </IonSegment>
              </IonFooter>
            </>
          )}
        {messageData && isReadyStatePending(initStatus, nextStatus) && (
          <>
            <IonProgressBar color="primary" className="h-2" value={1} />
            <ClanIonContent>
              <div
                className={`px-clanFlowStepScreenEdge text-center flex flex-col items-center min-h-full ${
                  messageData.chatOption ? "justify-around" : "justify-center"
                }`}
              >
                <div className="w-full">
                  <ImageWrapper>
                    <LlamaIcon className="w-56" />
                  </ImageWrapper>
                  <div className="flex flex-col items-center mt-4">
                    <Message
                      dangerouslySetInnerHTML={{ __html: messageData?.text }}
                    />
                  </div>
                </div>
                {messageData?.chatOption && (
                  <div className="w-full">
                    <IonButton
                      expand="block"
                      mode="ios"
                      fill="clear"
                      size="large"
                      style={{ "--border-radius": "99px" }}
                      className="text-clanH4 font-gilroy w-full text-clanGray-200 h-16"
                      onClick={messageData?.cta}
                    >
                      {t("general.ok")}
                    </IonButton>
                    <IonButton
                      expand="block"
                      mode="ios"
                      size="large"
                      style={{ "--border-radius": "99px" }}
                      className="text-clanH4 font-gilroy font-extrabold w-full h-16"
                      onClick={() => setShowChatWithTeacher(true)}
                    >
                      {t("survey.write")}
                    </IonButton>
                  </div>
                )}
              </div>
            </ClanIonContent>
            {!messageData?.chatOption && (
              <IonFooter>
                <IonSegment className="bg-clanYellow-100">
                  <IonSegmentButton
                    className="font-extrabold font-gilroy text-black bg-clanYellow-100"
                    onClick={messageData?.cta}
                  >
                    {messageData?.ctaText}
                  </IonSegmentButton>
                </IonSegment>
              </IonFooter>
            )}
          </>
        )}
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    profileId: (state.token as OauthData).profileId
  }),
  mapDispatchToProps: {
    setAppErrorConnect: setAppError,
    setRecievedFirstSurveyConnect: setRecievedFirstSurvey,
    dispatchToastConnect: showToast,
    dispatchQuickHelpModal: showQuickHelpModal
  },
  component: Survey
});
