import { Dispatch } from "react";
import { ModalCallback, ModalID, ModalType, ModalTypes } from "./modals.state";

export enum ModalAction {
  ShowModal = "SHOW_MODAL",
  RemoveModal = "REMOVE_MODAL"
}

export interface ShowModalAction {
  type: ModalAction.ShowModal;
  data: ModalTypes;
}

export interface RemoveModalAction {
  type: ModalAction.RemoveModal;
  id: ModalID;
}

export const showQuickHelpModal = (id: ModalID, paragraphs: Array<string>) => (
  dispatch: Dispatch<any>
) => showModal({ id, type: ModalType.QuickHelp, paragraphs })(dispatch);

export const showLlamaModal = (
  id: ModalID,
  options: {
    title?: string;
    paragraphs: Array<string>;
    actions: Array<ModalCallback>;
  }
) => (dispatch: Dispatch<any>) =>
  showModal({ id, type: ModalType.Llama, ...options })(dispatch);

export const showModal = (modalConf: ModalTypes) => (
  dispatch: Dispatch<ShowModalAction>
) => {
  dispatch({
    type: ModalAction.ShowModal,
    data: modalConf
  });
};

export const removeModal = (id: ModalID) => (
  dispatch: Dispatch<RemoveModalAction>
) => {
  dispatch({ type: ModalAction.RemoveModal, id });
};
