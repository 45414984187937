//import axios from "axios";

import { resolveVideoContent } from "./VideoContent";

type Resolvers = (url: string) => Promise<string | undefined>;

const resolvers: Resolvers[] = [resolveVideoContent];

export const getURlContent = async (
  url: string
): Promise<string | undefined> => {
  if (url === null || url === undefined || url.trim().length === 0)
    return undefined;
  const _url = url.trim();

  for (let i = 0; i < resolvers.length; i++) {
    const result = await resolvers[i](_url);
    if (result) return result;
  }

  return undefined;
  // perhaps even check some images?
  // const result = await axios.head(url);
  // console.log(result);
  // return `<img src="${_url} width="100%"/>`;
  // return undefined if this url does not satisfy
};
