import { IonFooter, IonModal } from "@ionic/react";
import React, { useState } from "react";
import styled from "styled-components";

import {
  ModalCallback,
  ModalCallbackType
} from "../../data/modals/modals.state";
import { ReactComponent as LlamaIcon } from "../../icons/llama.svg";
import { ActionButton, SecondaryActionButton } from "../Buttons/Button";
import { SmoothRays } from "../ClanSmoothRays";
import { Paragraph } from "../Section";
import { H2, H6 } from "../Typography";

const ContentWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const LlamaImage = styled(LlamaIcon)`
  display: block;
  margin: 0 auto 10px;
  max-width: 150px;
  width: 100%;
`;

const LlamaTitle = styled(H2)`
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  display: inline-block;
`;

const ActionsWrapper = styled(IonFooter)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  padding-bottom: 20px;
  text-align: center;

  &::before {
    content: none;
  }
`;

const StyledButton = styled(ActionButton)`
  max-width: 400px;
  width: 80%;
  padding: 16px 0;
`;

const StyledSecondaryButton = styled(SecondaryActionButton)`
  max-width: 400px;
  width: 80%;
`;

interface LlamaModalProps {
  actions: Array<ModalCallback>;
  title?: string;
  paragraphs: Array<string>;
  onClose: () => void;
}

const LlamaModal: React.FC<LlamaModalProps> = ({
  title,
  paragraphs,
  actions,
  onClose
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const onCloseModal = (cb?: () => void) => () => {
    if (cb) cb();
    setIsOpen(false);
  };

  const closeAction = actions.find(
    (action) => action.type === ModalCallbackType.Close
  );
  const customActions = actions.filter(
    (action) => action.type !== ModalCallbackType.Close
  );

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <SmoothRays showInDesktop />

      <ContentWrapper>
        <LlamaImage />
        {!!title && <LlamaTitle>{title}</LlamaTitle>}
        {paragraphs.map((paragraph, index) => (
          <Paragraph key={index}>{paragraph}</Paragraph>
        ))}
      </ContentWrapper>
      {(closeAction || customActions.length > 0) && (
        <ActionsWrapper>
          {closeAction && (
            <H6 onClick={onCloseModal(closeAction.action)}>
              {closeAction.label}
            </H6>
          )}

          {customActions.map((action, index) =>
            action.type === ModalCallbackType.Primary ? (
              <StyledButton key={index} onClick={action.action}>
                {action.label}
              </StyledButton>
            ) : (
              <StyledSecondaryButton key={index} onClick={action.action}>
                {action.label}
              </StyledSecondaryButton>
            )
          )}
        </ActionsWrapper>
      )}
    </IonModal>
  );
};

export default LlamaModal;
