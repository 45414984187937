import { IonInput } from "@ionic/react";
import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Button from "../Buttons/Button";
import ClanBottomModal from "../ClanBottomModal";
import { H6 } from "../Typography";

const Description = styled(H6)`
  padding: 16px 20px;
  background: #fafafa;
`;

const InputWrapper = styled.div`
  padding: 16px 24px;
  min-height: 100px;
  display: flex;
  column-gap: 8px;
  align-items: center;
  text-transform: uppercase;
`;

interface AccessCodeModalProps {
  disabled?: boolean;
  onSubmit: (accessCode: string) => void;
  onClose: () => void;
}

const ACCESS_CODE_LENGTH = 8;

const AccessCodeModal: React.FC<AccessCodeModalProps> = ({
  disabled,
  onSubmit,
  onClose
}) => {
  const [accessCode, setAccessCode] = useState("");

  const onAccessCodeChange = (e: any) => setAccessCode(e.target.value);

  const submit = () => onSubmit(accessCode);

  const { t } = useTranslation();

  return (
    <ClanBottomModal isOpen={true} onDidDismiss={onClose}>
      <Description>{t("access_code_modal.description")}</Description>
      <InputWrapper>
        <IonInput
          value={accessCode}
          placeholder="XXXXXXXX"
          onIonChange={onAccessCodeChange}
          maxlength={ACCESS_CODE_LENGTH}
        />
        <Button
          disabled={disabled || accessCode.length < ACCESS_CODE_LENGTH}
          onClick={submit}
        >
          {t("access_code_modal.join")}
        </Button>
      </InputWrapper>
    </ClanBottomModal>
  );
};

export default AccessCodeModal;
