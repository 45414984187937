export enum ModalID {
  // Quick help ID-s
  QuickHelpTask = "quick_help.task",
  QuickHelpReflection = "quick_help.reflection",
  QuickHelpDailyStart = "quick_help.wellbeing_morning_start_day",
  QuickHelpDailyEvaluation = "quick_help.wellbeing_daily_evening_day_evaluation",
  QuickHelpWeeklyStart = "quick_help.wellbeing_weekly_start_feelings_start",
  QuickHelpWeeklyEvaluation = "quick_help.wellbeing_weekly_end_day_evaluation",

  LlamaInfo = "llama.info",
  DiscoverCommunityLlama = "llama.discover_community",
  AnnouncementInfo = "announcement.info"
}

export enum ModalType {
  QuickHelp,
  Llama
}

export enum ModalCallbackType {
  Primary,
  Secondary,
  Close
}

interface BaseModal {
  id: ModalID;
  type: ModalType;
}

export interface ModalCallback {
  label: string;
  action?: () => any;
  type?: ModalCallbackType;
}

// Special modal types
export interface QuickHelpModal extends BaseModal {
  type: ModalType.QuickHelp;
  paragraphs: Array<string>;
}

export interface LlamaModal extends BaseModal {
  type: ModalType.Llama;
  title?: string;
  paragraphs: Array<string>;
  actions: Array<ModalCallback>;
}

export type ModalTypes = QuickHelpModal | LlamaModal;

export type ModalsState = {
  modals: Array<ModalTypes>;
};
