import { IonPage } from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import { analyticsLogEvent } from "../../Analytics";
import ActionCard from "../../components/ActionCard";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import ClanHeaderRays from "../../components/ClanHeaderRays";
import ShareReflectionModal from "../../components/Modals/ShareReflectionModal";
import TeacherReflectionModal from "../../components/Modals/TeacherReflectionModal";
import {
  LargeSectionHeading,
  Paragraph,
  SectionContent
} from "../../components/Section";
import SubPageHeader from "../../components/SubPageHeader";
import { connect } from "../../data/connect";
import * as ROUTES from "../../routes";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";

import { ReactComponent as CheckInIcon } from "../../icons/check-in-icon.svg";

interface StateProps {
  isStudent: boolean;
}

const Reflections: React.FC<StateProps> = ({ isStudent }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const [showReflectionModal, setShowReflectionModal] = useState(false);
  const [showTeacherReflectionModal, setShowTeacherReflectionModal] = useState(
    false
  );

  const { reflectionTaskId } = (location.state || {}) as {
    reflectionTaskId?: string;
  };

  const onClose = () => history.goBack();

  return (
    <ClanDesktopLayout>
      {showTeacherReflectionModal && (
        <TeacherReflectionModal
          taskId={reflectionTaskId}
          onClose={() => setShowTeacherReflectionModal(false)}
        />
      )}

      {showReflectionModal && (
        <ShareReflectionModal
          taskId={reflectionTaskId}
          onClose={() => setShowReflectionModal(false)}
        />
      )}

      <IonPage>
        <ClanIonContent>
          <ClanHeaderRays short />

          <SubPageHeader title={t("dashboard.checkin")} onBack={onClose} />
          <LargeSectionHeading>{t("reflections.heading")}</LargeSectionHeading>
          <SectionContent>
            <Paragraph>{t("reflections.description")}</Paragraph>

            <ActionCard
              description={t("reflections.private_desc")}
              buttonLabel={t("reflections.private_label")}
              icon={<CheckInIcon />}
              onClick={() => {
                analyticsLogEvent("REFLECTIONS_PRIVATE_CLICKED");
                history.push({
                  pathname: ROUTES.PROFILE,
                  state: {
                    reflectionTaskId,
                    initialShowNewPost: true
                  }
                });
              }}
            />

            {isStudent && (
              <ActionCard
                description={t("reflections.teacher_desc")}
                buttonLabel={t("reflections.teacher_label")}
                icon={<CheckInIcon />}
                onClick={() => {
                  analyticsLogEvent("REFLECTIONS_WITH_TEACHER_CLICKED");
                  setShowTeacherReflectionModal(true);
                }}
              />
            )}

            <ActionCard
              description={t("reflections.shared_desc")}
              buttonLabel={t("reflections.shared_label")}
              icon={<CheckInIcon />}
              onClick={() => {
                analyticsLogEvent("REFLECTIONS_SHARE_CLICKED");
                setShowReflectionModal(true);
              }}
            />
          </SectionContent>
        </ClanIonContent>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: ({ token }) => ({
    isStudent: !UserEnabledProperties.Legacy.isAdult(token)
  }),
  component: Reflections
});
