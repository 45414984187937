import {
  getOauthData,
  getRefreshToken,
  OauthData
} from "../../../data/oauth/oauthDataService";
import {
  AuthorizedServerApi,
  ServerApi,
  ServerAPIBase
} from "../types/ServerApi.d";
import { ApiServiceMethod } from "../types/ApiServiceMethod.d";
import {
  ApiServiceGetRequest,
  ApiServiceRequest,
  ApiServiceRequestWithMethod
} from "../types/ApiServiceRequest.d";
import { getAxiosWrapper } from "./AxiosWrapperService";
import { ClanOauthResponse } from "../../models/ClanOauthResponse";
import { removeOauth, setOauth } from "../../../data/oauth/oauth.actions";
import { dispatch } from "../../../data/AppContext";
import { BlockingPromiseQueue } from "../../BlockingPromiseQueue";
import { OauthState } from "../../../data/oauth/oauth.state";
import {
  ApiRequestErrorImpl,
  errorToApiRequestError
} from "./ApiRequestErrorImpl";
import { AxiosResponse } from "axios";

const axiosWrapper = getAxiosWrapper();
const blockingPromiseQueue = new BlockingPromiseQueue();

const getOauthDataInner = async (): Promise<OauthData | null> => {
  try {
    const token = (await getOauthData()) as OauthData;
    if (!token.hasToken) return null;
    return token;
  } catch (error) {
    throw errorToApiRequestError(error);
  }
};

let lastRefreshTs = -1;
const doRefreshToken = async (refreshToken: string): Promise<OauthData> => {
  try {
    const result = await axiosWrapper.request<ClanOauthResponse>({
      method: ApiServiceMethod.POST,
      url: "/auth/v1/refresh",
      data: { token: refreshToken }
    });
    if (result && result.status !== 200) {
      // noinspection ExceptionCaughtLocallyJS
      throw new ApiRequestErrorImpl({
        error: new Error("[RENEW_REFRESH_TOKEN] Unmanaged result!"),
        response: result
      });
    }
    console.debug(
      "[RENEW_REFRESH_TOKEN] Renewing token successful. Updating localStorage..."
    );
    const oauthState = await setOauth(result.data)(dispatch);
    // eslint-disable-next-line
    lastRefreshTs = Date.now();
    return oauthState as OauthData;
  } catch (error) {
    if (error.response?.status === 401) {
      await removeOauth()(dispatch);
    }
    throw errorToApiRequestError(error);
  }
};

/**
 * Returns whether renew refresh token was successful
 * or throws error in case of other unexpected error
 * @param force - if refresh is forced, otherwise refresh can not be called more often than in every 5 seconds
 */

export const renewRefreshToken = async (force = false) =>
  blockingPromiseQueue.blockAndRun<OauthData>(
    async (): Promise<OauthData> => {
      const currentTs = Date.now();
      const token = await getOauthDataInner();
      if (!token) {
        throw new Error("User is not authorized!");
      }
      if (!force && currentTs - lastRefreshTs < 5000) {
        return token; // the token was refreshed 5 seconds ago
      }
      console.warn("[RENEW_REFRESH_TOKEN] going to refresh the token");
      return doRefreshToken(token.refreshToken);
    }
  );

export const getLatestRefreshedToken = async (
  reduceSeconds?: number
): Promise<OauthData | OauthState> => {
  try {
    const token = await getOauthData();
    if (!token.hasToken) return token;
    if ((token as OauthData).isTokenExpired(reduceSeconds)) {
      return renewRefreshToken();
    }
    return token;
  } catch (error) {
    throw errorToApiRequestError(error);
  }
};

export const initByRefreshToken = async (): Promise<OauthData> =>
  blockingPromiseQueue.blockAndRun<OauthData>(async () => {
    try {
      const token = await getOauthDataInner();
      if (token) {
        if (token.isTokenExpired(120)) {
          return doRefreshToken(token.refreshToken);
        }
        return token;
      }
      const refreshToken = await getRefreshToken();
      if (refreshToken) {
        return doRefreshToken(refreshToken);
      }
      throw new ApiRequestErrorImpl({
        error: new Error("No refresh token"),
        status: 401
      });
    } catch (error) {
      throw errorToApiRequestError(error);
    }
  });

const doRequestWithToken = async <T>(
  token: OauthData,
  request: ApiServiceRequestWithMethod
): Promise<AxiosResponse<T>> => {
  try {
    if (token.hasToken && token.token && token.type) {
      const authHeaders = { Authorization: `${token.type} ${token.token}` };
      request.headers = request.headers
        ? { ...request.headers, ...authHeaders }
        : { ...authHeaders };
    }
    return axiosWrapper.request<T>(request);
  } catch (error) {
    throw errorToApiRequestError(error);
  }
};

export const logoutFromBackend = async () =>
  blockingPromiseQueue.blockAndRun(async () => {
    const token = await getOauthDataInner();
    if (token) {
      try {
        const wasLogout = await doRequestWithToken<OauthData>(token, {
          method: ApiServiceMethod.POST,
          url: "/auth/v1/logout",
          data: { token: token.refreshToken }
        });
        console.debug("[LOGOUT] Backend logout response: ", wasLogout);
      } catch (error) {
        console.error("[LOGOUT] Couldn't logout from backend", error);
      }
    }
  });

export const UnAuthorizedApi: ServerApi = {
  rawRequest: <T>(request: ApiServiceRequestWithMethod) => {
    try {
      return blockingPromiseQueue.blockAndRun(async () => {
        try {
          return axiosWrapper.request<T>(request);
        } catch (error) {
          throw errorToApiRequestError(error);
        }
      });
    } catch (error) {
      throw errorToApiRequestError(error);
    }
  }
};

const mapRequest = (
  request: ApiServiceRequest | ApiServiceGetRequest | string,
  method: ApiServiceMethod
): ApiServiceRequestWithMethod => {
  if ((request as ApiServiceGetRequest).url) {
    return {
      ...(request as ApiServiceGetRequest),
      ...{ method }
    } as ApiServiceRequestWithMethod;
  } else {
    return {
      url: request,
      method
    } as ApiServiceRequestWithMethod;
  }
};

const doAuthorizedRequest = async <T>(
  request: ApiServiceRequestWithMethod
): Promise<AxiosResponse<T>> =>
  blockingPromiseQueue.whenUnblocked(async () => {
    const token = await getLatestRefreshedToken();
    if (!token.hasToken) {
      throw new ApiRequestErrorImpl({ error: "Token is not set", status: 401 });
    }
    return doRequestWithToken<T>(token as OauthData, request);
  });

const doAuthorizedRequestAndGetData = async <T>(
  request: ApiServiceRequestWithMethod
): Promise<T> => {
  const result = await doAuthorizedRequest<T>(request);
  return result.data;
};

const doAuthorizedRequestAndGetDataWithMixedRequest = <T>(
  request: ApiServiceRequest | ApiServiceGetRequest | string,
  method: ApiServiceMethod
): Promise<T> => doAuthorizedRequestAndGetData(mapRequest(request, method));

export const AuthorizedApi: AuthorizedServerApi = {
  rawRequest: <T>(request: ApiServiceRequestWithMethod) =>
    doAuthorizedRequest<T>(request),
  request: <T>(request: ApiServiceRequestWithMethod) =>
    doAuthorizedRequestAndGetData(request),
  get: <T>(request: ApiServiceGetRequest | string) =>
    doAuthorizedRequestAndGetDataWithMixedRequest<T>(
      request,
      ApiServiceMethod.GET
    ),
  post: <T>(request: ApiServiceRequest | string) =>
    doAuthorizedRequestAndGetDataWithMixedRequest<T>(
      request,
      ApiServiceMethod.POST
    ),
  put: <T>(request: ApiServiceRequest | string) =>
    doAuthorizedRequestAndGetDataWithMixedRequest<T>(
      request,
      ApiServiceMethod.PUT
    ),
  delete: <T>(request: ApiServiceRequest | string) =>
    doAuthorizedRequestAndGetDataWithMixedRequest<T>(
      request,
      ApiServiceMethod.DELETE
    )
};

const doAuthorizedRequestWithResult = async <T>(
  request: ApiServiceRequest | ApiServiceGetRequest | string,
  method: ApiServiceMethod
): Promise<T> => {
  const result = await doAuthorizedRequestAndGetDataWithMixedRequest<{
    result: T;
  }>(request, method);
  return result.result;
};

export const AuthorizedApiResult: ServerAPIBase = {
  request: async <T>(request: ApiServiceRequestWithMethod): Promise<T> => {
    const result = await doAuthorizedRequestAndGetData<{ result: T }>(request);
    return result.result;
  },
  get: <T>(request: ApiServiceGetRequest | string) =>
    doAuthorizedRequestWithResult<T>(request, ApiServiceMethod.GET),
  post: <T>(request: ApiServiceRequest | string) =>
    doAuthorizedRequestWithResult<T>(request, ApiServiceMethod.POST),
  put: <T>(request: ApiServiceRequest | string) =>
    doAuthorizedRequestWithResult<T>(request, ApiServiceMethod.PUT),
  delete: <T>(request: ApiServiceRequest | string) =>
    doAuthorizedRequestWithResult<T>(request, ApiServiceMethod.DELETE)
};
