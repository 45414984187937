import React from "react";
import styled from "styled-components";

import { ListItemCard } from "../ListItem/ListItemCard";
import { H6 } from "../Typography";

const StyledUIButton = styled(ListItemCard)`
  padding: 36px 12px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  cursor: pointer;
  text-align: center;

  svg {
    width: 100%;
  }
`;

interface VerticalUIButtonProps {
  title: string;
  icon?: any;
  onClick?: () => void;
}

const VerticalUIButton: React.FC<VerticalUIButtonProps> = ({
  title,
  icon,
  onClick
}) => (
  <StyledUIButton onClick={onClick}>
    {icon}
    <H6>{title}</H6>
  </StyledUIButton>
);

export default VerticalUIButton;
