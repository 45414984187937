// Images taken from https://github.com/twitter/twemoji/tree/gh-pages/svg
const MOOD_LIST = [
  "Excited",
  "Determined",
  "Curious",
  "Happy",
  "Peaceful",
  "Touched",
  "Thankful",
  "Connected",
  "Sad",
  "Tired",
  "Discouraged",
  "Confused",
  "Worried",
  "Anxious",
  "Frustrated",
  "Scared"
];

const validMoodList = MOOD_LIST.map((value) => value.toLowerCase());
export const isValidMood = (mood?: string | undefined): boolean => {
  if (!mood) return false;
  return validMoodList.indexOf(mood.toLowerCase()) >= 0;
};

export const MOOD_CHOICE = MOOD_LIST.map((value) => ({
  label: value,
  image: `/assets/mood/${value.toLowerCase()}.svg`
}));
