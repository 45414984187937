import { IonFooter } from "@ionic/react";
import React from "react";
import styled from "styled-components";

const StyledFooter = styled(IonFooter)`
  &::before {
    content: none;
  }
`;

const FooterButtonsWrapper = styled.footer<FooterButtonsProps>`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  background: ${({ round }) => (round ? "transparent" : "#fafafa")};

  ${({ round }) =>
    round &&
    `
    grid-gap: 24px;
    padding: 12px 24px;

    button {
      border-radius: 25px;
      height: 50px;
    }
  `}
`;

export const FooterButton = styled.button`
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  height: 60px;
  width: 100%;
  max-width: none;
  background: white;
  color: black;
  cursor: pointer;

  &:disabled {
    opacity: 0.2;
  }
`;

export const PrimaryFooterButton = styled(FooterButton)`
  background: ${({ theme }) => theme.primary};
  text-transform: uppercase;
`;

export const SecondaryFooterButton = styled(FooterButton)`
  background: #fafafa;
  text-transform: uppercase;
`;

interface FooterButtonsProps {
  round?: boolean;
}

const FooterButtons: React.FC<FooterButtonsProps> = ({
  children,
  round,
  ...rest
}) => (
  <StyledFooter {...rest}>
    <FooterButtonsWrapper round={round}>{children}</FooterButtonsWrapper>
  </StyledFooter>
);

export default FooterButtons;
