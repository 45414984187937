import { AxiosResponse } from "axios";

import { setAppError } from "./data/app/app.actions";
import { dispatch } from "./data/AppContext";
import {
  ApiServiceMethod,
  ApiServiceRequestWithMethod,
  AuthorizedApi,
  UnAuthorizedApi
} from "./util/ApiService";

// TODO: methods below can be removed and ApiService methods used instead
type AxiosRequestTypes = "get" | "post" | "put" | "delete";

/**
 * Deprecated! Use apiService instead!
 */
export const doRequest = <T>(
  requestType: AxiosRequestTypes,
  endPoint: string,
  bodyObject: object | string = {},
  params: object = {},
  headers: { [key: string]: string } = {}
): Promise<AxiosResponse<T>> => {
  const apiServiceRequest = {
    method: ApiServiceMethod.GET,
    url: endPoint,
    headers,
    data: bodyObject,
    params
  } as ApiServiceRequestWithMethod;
  switch (requestType) {
    case "get":
      apiServiceRequest.method = ApiServiceMethod.GET;
      break;
    case "post":
      apiServiceRequest.method = ApiServiceMethod.POST;
      break;
    case "put":
      apiServiceRequest.method = ApiServiceMethod.PUT;
      break;
    case "delete":
      apiServiceRequest.method = ApiServiceMethod.DELETE;
      break;
  }
  const serverApi = endPoint.startsWith("/auth")
    ? UnAuthorizedApi
    : AuthorizedApi;
  try {
    return serverApi.rawRequest<T>(apiServiceRequest);
  } catch (e) {
    setAppError(e)(dispatch); // TODO: this can be removed when we are sure errors are managed
    throw e;
  }
};

/**
 * Deprecated! Use apiService instead!
 */
export async function doRequestAndReturnResult<T>(
  requestType: AxiosRequestTypes,
  endPoint: string,
  bodyObject: object | string = {},
  params: object = {},
  headers: { [key: string]: string } = {}
): Promise<T> {
  const response = await doRequest<{ result: T }>(
    requestType,
    endPoint,
    bodyObject,
    params,
    headers
  );
  return response.data.result;
}
