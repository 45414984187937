import React from "react";
import { IonLoading } from "@ionic/react";
import { useTranslation } from "react-i18next";

interface ClanLoader {
  message?: string;
  showLoading: boolean;
}
// TODO: Right now there are two ways to have a loader. Local and Global. Local is used as a local state inside components and global is in app store
// Perhaps in future there should only be a global state to avoid inconsistencies and hard to troubleshoot issues
const ClanLoader: React.FC<ClanLoader> = ({ message, showLoading }) => {
  const { t } = useTranslation();
  if (!showLoading) return null;

  return (
    <IonLoading
      isOpen={true}
      message={message || t("general.loading")}
      mode="ios"
      spinner="circular"
    />
  );
};

export default ClanLoader;
