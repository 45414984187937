import {
  IonInput,
  IonLabel,
  IonList,
  IonChip,
  IonButton,
  IonIcon
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { addCircle } from "ionicons/icons";
import { useTranslation } from "react-i18next";

const LabelInputWrapper = styled.div`
  position: relative;
  min-width: 0;
  flex: 1;
  display: flex;
`;

const LabelListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  overflow-y: auto;
  margin-right: auto;
`;

const LabelInput = styled(IonInput)`
  min-width: 130px;
`;

interface LabelProps {
  label: string;
  onClick: () => void;
}

export const Label: React.FC<LabelProps> = ({ label, onClick }) => {
  return (
    <IonChip
      onClick={onClick}
      className="bg-clanGray-100 m-0 text-clanH5 flex-shrink-0 cursor-pointer"
    >
      <IonLabel className="text-clanGreen-100">#{label}</IonLabel>
    </IonChip>
  );
};

export type LabelSuggestion = { value: string; amount: number };

type ClanLabelInputProps = {
  labels: Array<string>;
  labelSuggestions: Array<LabelSuggestion>;
  onLabelsChange: (labelsToBeSubmitted: string[]) => void;
  onInputComplete: () => void;
};

const LABEL_INPUT_REGEX = /\s+[#]|\s+|#/g;

const ClanLabelInput: React.FC<ClanLabelInputProps> = React.memo(
  ({ labels, labelSuggestions, onLabelsChange, onInputComplete }) => {
    const labelInputRef = useRef<HTMLIonInputElement>(null);
    const [newLabel, setNewLabel] = useState<string>("");
    const [focused, setFocused] = useState<boolean>(false);
    const { t } = useTranslation();
    const [filteredLabelSuggestions, setFilteredLabelSuggestions] = useState<
      Array<LabelSuggestion>
    >([]);

    useEffect(() => {
      setFilteredLabelSuggestions(
        filterLabelSuggestions(labelSuggestions, newLabel)
      );
      focusInput();
    }, [newLabel, labelSuggestions]);

    function filterLabelSuggestions(
      suggestionsToFilter: Array<LabelSuggestion>,
      labelToAdd: string
    ) {
      return suggestionsToFilter.filter(({ value }) =>
        value.includes(labelToAdd)
      );
    }

    function addNewLabels() {
      const newLabelString = newLabel.split(LABEL_INPUT_REGEX).filter(Boolean);

      const labelsToInclude = newLabelString
        .map((labelToInclude) => labelToInclude.toLowerCase())
        .filter((extractedLabel) => !labels.includes(extractedLabel));

      onLabelsChange([...labels, ...labelsToInclude]);
      focusInput();
    }

    function removeLabel(labelToRemove: string) {
      onLabelsChange(labels.filter((label) => label !== labelToRemove));
      focusInput();
    }

    function selectLabelSuggestion(label: LabelSuggestion) {
      return () => {
        if (!labels.includes(label.value)) {
          setFocused(false);
          onLabelsChange([...labels, label.value]);
          setNewLabel("");
          focusInput();
        }
      };
    }

    function focusInput() {
      labelInputRef.current?.scrollIntoView({ behavior: "smooth" });
      labelInputRef.current?.setFocus();
      setTimeout(() => {
        labelInputRef.current?.setFocus();
      }, 500);
    }

    function onInputChange(e: CustomEvent) {
      const newValue = e.detail.value;

      const lastLetter = newValue.charAt(newValue.length - 1);

      if (lastLetter === " ") {
        addNewLabels();
        setNewLabel("");
      } else {
        setNewLabel(e.detail.value);
      }
    }

    function onInputKeyDown(e: React.KeyboardEvent<HTMLIonInputElement>) {
      if (newLabel.length !== 0) {
        if (e.keyCode === 13 || e.key === "Enter") {
          addNewLabels();
          setNewLabel("");
        }
      } else {
        if (e.key === "Backspace" && labels.length > 0) {
          removeLabel(labels[labels.length - 1]);
        }
      }
    }

    return (
      <LabelInputWrapper>
        {filteredLabelSuggestions && focused && (
          <IonList className="max-h-50vh overflow-auto z-100 absolute flex flex-col mx-2 bottom-100% left-0 shadow-md rounded-md">
            {filteredLabelSuggestions.map((label, index) => (
              <IonLabel
                key={index}
                className="p-2 cursor-pointer"
                onClick={selectLabelSuggestion(label)}
              >
                {label.value}
              </IonLabel>
            ))}
          </IonList>
        )}

        <LabelListWrapper>
          {labels.map((label) => (
            <Label
              key={label}
              label={label}
              onClick={() => removeLabel(label)}
            />
          ))}

          <LabelInput
            ref={labelInputRef}
            value={newLabel}
            onFocus={() => setFocused(true)}
            placeholder={t("planner.add_label")}
            type="text"
            maxlength={256}
            onIonChange={onInputChange}
            onKeyDown={onInputKeyDown}
          />
        </LabelListWrapper>
        <IonButton
          style={{
            "--padding-start": "0.5rem",
            "--padding-end": "0.5rem"
          }}
          onClick={() => {
            addNewLabels();
            onInputComplete();
          }}
          disabled={false}
          className="text-clanGreen-100 h-clanBtn text-clanH2 align-middle"
          color="tertiary"
          fill="clear"
        >
          <IonIcon icon={addCircle} className="text-clanGreen-100" />
        </IonButton>
      </LabelInputWrapper>
    );
  }
);

export default ClanLabelInput;
