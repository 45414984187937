import axios from "axios";
import { History } from "history";
import { TFunction } from "i18next";
import { getCountryCallingCode } from "libphonenumber-js/mobile";

import {
  ApiResponseErrorType,
  AuthorizedApiResult
} from "../../util/ApiService";
import { isNativePlatform } from "../../util/AppUtils";
import {
  tryToSignInWithCredential,
  tryToSignInWithPhoneNumber
} from "../../util/GoogleFirebase";
import { ClanProfileResponse } from "../../util/models/ClanProfileModels";
import RollbarManager from "../../util/RollbarManager";
import { SignupSteps } from "../Signup/Signup";
import { StartSteps } from "./Start";

//TODO get the list from backend and avoid using require
const countriesQuery = require("countries-code");

export type CheckSignupDataResult = {
  viewToLoad: SignupSteps;
  profileDataResult?: ClanProfileResponse;
};

export const checkSignupData = async (
  setLoading: (isLoading: boolean) => void
): Promise<CheckSignupDataResult | undefined> => {
  /* Return codes are 1-4 each pertaining to the 4 views of the profile data inquiry
      1- Name and surname
      2- Gender
      3- Birthday
      4- Picture
      5- All the required info already existing
      */

  let viewToLoad = SignupSteps.NAME;
  try {
    setLoading(true);
    const profileDataResult = await AuthorizedApiResult.get<ClanProfileResponse>(
      "/user/profile/v1"
    );

    if (profileDataResult) {
      if (profileDataResult.fullName.length === 0) {
        viewToLoad = SignupSteps.NAME;
      } else if (!profileDataResult.dateOfBirth) {
        viewToLoad = SignupSteps.BIRTHDAY;
      } else viewToLoad = SignupSteps.SUCCESS;
    }
    return {
      viewToLoad,
      profileDataResult
    } as CheckSignupDataResult;
  } catch (error) {
    if (error.type === ApiResponseErrorType.HTTP_NOT_FOUND) {
      console.debug("No existing profile data found. User has to provide...");
    } else {
      RollbarManager.logError(error as Error);
    }
  } finally {
    setLoading(false);
  }
};

export const firebaseValidate = async (
  number: string,
  appVerifier: any,
  pushToToast: (message: string) => void,
  setVerificationId: (vId: string) => void,
  setCurrentStep: (method: StartSteps) => void,
  setLoading: (isLoading: boolean) => void,
  t: TFunction
) => {
  setLoading(true);

  try {
    const verification = await tryToSignInWithPhoneNumber(number, appVerifier);
    setVerificationId(verification);
    setCurrentStep(StartSteps.SMSPIN);
  } catch (error) {
    if (!isNativePlatform) {
      pushToToast(`Error - ${error.message}`);
      appVerifier?.render().then((widgetId: any) => {
        appVerifier?.recaptcha?.reset(widgetId);
      });
    } else {
      pushToToast(t("start.sms_pin.pin_failed"));
    }
    RollbarManager.logError(error as Error, { number });
  } finally {
    setLoading(false);
  }
};

export const getFirebaseToken = async (
  history: History,
  verificationCode: string,
  setLoading: (isLoading: boolean) => void,
  pushToToast: (message: string) => void,
  verificationId: string,
  t: TFunction
) => {
  console.debug("[FIREBASE_GET_TOKEN] Entered OTP code: ", verificationCode);

  if (verificationId) {
    setLoading(true);

    try {
      await tryToSignInWithCredential(
        history,
        verificationId,
        verificationCode,
        false
      );
    } catch (error) {
      pushToToast(t("start.sms_pin.number_validation_error"));
      RollbarManager.logError(error as Error);
    } finally {
      setLoading(false);
    }
  } else {
    pushToToast(t("start.sms_pin.error"));
    RollbarManager.logError(
      new Error(
        "[FIREBASE_GET_TOKEN] Couldn't validate number. VerificationId was not received."
      )
    );
  }
};

export const countryCodeEmoji = (cc: any) => {
  // Country code regex
  const CC_REGEX = /^[a-z]{2}$/i;

  // Offset between uppercase ascii and regional indicator symbols
  const OFFSET = 127397;

  if (!CC_REGEX.test(cc)) {
    const type = typeof cc;
    throw new TypeError(
      `cc argument must be an ISO 3166-1 alpha-2 string, but got '${
        type === "string" ? cc : type
      }' instead.`
    );
  }

  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const chars = [...cc.toUpperCase()].map((c) => c.charCodeAt() + OFFSET);
  return String.fromCodePoint(...chars);
};

export type CountryInformation = {
  country: string;
  countryCode: string;
  flag: string;
  callingCode: string;
};

export const makeCountryList = (array: any[]): CountryInformation[] => {
  return array.reduce((countriesList, item) => {
    return [
      ...countriesList,
      {
        country: countriesQuery.getCountry(item),
        countryCode: item,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        callingCode: "+" + getCountryCallingCode(item),
        flag: countryCodeEmoji(item)
      }
    ].sort((a, b) => (a.country > b.country ? 1 : -1));
  }, []);
};

export const getIpCountryInfo = async (): Promise<CountryInformation> => {
  let currentCountryInformation = {} as CountryInformation;
  await axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      currentCountryInformation = {
        country: response.data.country_name,
        countryCode: response.data.country_code,
        flag: countryCodeEmoji(response.data.country_code),
        callingCode: response.data.country_calling_code
        // To just mock some random country comment above and uncomment below...
        // country: "United States of America",
        // countryCode: "US",
        // flag: countryCodeEmoji("US"),
        // callingCode: "+1"
      };
    })
    .catch((error) => {
      console.log(
        "Failed to determine country by IP address. Falling back to default.",
        error
      );
    });
  return currentCountryInformation;
};
