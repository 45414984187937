import { OauthState } from "./oauth.state";
import {
  OauthData,
  removeOauthData,
  setClanOauthResponse
} from "./oauthDataService";
import { ClanOauthResponse } from "../../util/models/ClanOauthResponse";
import { Dispatch } from "react";
import { withLoading } from "../app/app.actions";

export const SET_CLANBEAT_TOKEN = "set-clanbeat-token";

export type OauthStorageAction = {
  type: string;
  token: OauthState;
};

export const getOauthTokenActionPayload = (token: OauthState) =>
  ({
    type: SET_CLANBEAT_TOKEN,
    token
  } as OauthStorageAction);

export const removeOauth = () => async (dispatch: Dispatch<any>) => {
  await withLoading("oauth.actions:removeOauth", async () => {
    const removeResult = await removeOauthData();
    dispatch(getOauthTokenActionPayload(removeResult));
  })(dispatch);
};

export const setOauth = (token: ClanOauthResponse) => async (
  dispatch: Dispatch<any>
) => {
  return withLoading<OauthData | OauthState>(
    "oauth.actions:setOauth",
    async () => {
      const oauthResult = await setClanOauthResponse(token);
      dispatch(getOauthTokenActionPayload(oauthResult));
      return oauthResult;
    }
  )(dispatch);
};
