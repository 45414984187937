import { ImageState } from "./image.state";
import { ProfileImageAction, SET_USER_PROFILE_IMAGE } from "./image.actions";

export function userProfileImageReducer(
  state: ImageState,
  action: ProfileImageAction
): ImageState {
  if (action.type === SET_USER_PROFILE_IMAGE) {
    return action.data;
  }
  return state;
}
