import {
  IonFooter,
  IonImg,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonText
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import ClanHeaderRays from "./components/ClanHeaderRays";
import { resetAppError } from "./data/app/app.actions";
import { connect } from "./data/connect";
import * as ROUTES from "./routes";
import useNetworkStatus from "./util/hooks/useNetworkStatus";
import { ApiRequestError, ApiResponseErrorType } from "./util/ApiService";
import { errorToApiRequestError } from "./util/ApiService/lib/ApiRequestErrorImpl";

interface StateProps {
  errorConnect: ApiRequestError | string | undefined;
}

interface DispatchProps {
  resetAppErrorConnect: typeof resetAppError;
}

interface ClanErrorModalProps extends DispatchProps, StateProps {}

type KnownError = {
  title?: string;
  picUrl?: string;
  description?: string;
  action: () => void;
  buttonTitle: string;
};

const ClanErrorModal: React.FC<ClanErrorModalProps> = ({
  errorConnect,
  resetAppErrorConnect
}) => {
  const history = useHistory();
  const isConnected = useNetworkStatus();
  const { t } = useTranslation();

  const [errorToBeShown, setErrorToBeShown] = useState<KnownError | undefined>(
    undefined
  );
  // In future we can try to send a request to the same url for retry and take action depending on whether the issue is resolved
  // const ErrorUrl = `${(errorConnect as ApiRequestError)?.request?.baseUrl}${
  //   (errorConnect as AxiosError)?.config?.url
  // }`;
  useEffect(() => {
    const error = errorConnect
      ? errorToApiRequestError(errorConnect)
      : undefined;
    if (!error) {
      setErrorToBeShown(undefined);
      return;
    }
    console.warn("Managed error", error);
    switch (error.type) {
      case ApiResponseErrorType.HTTP_TOO_MANY_REQUESTS:
      case ApiResponseErrorType.HTTP_REQUEST_ABORTED:
      case ApiResponseErrorType.HTTP_PARSE_TIMEOUT:
      case ApiResponseErrorType.HTTP_NETWORK_ERROR:
      case ApiResponseErrorType.HTTP_MAX_CONTENT_EXCEEDED:
        setErrorToBeShown({
          title: t("errors.network"),
          description: t("errors.network_desc"),
          picUrl: "NETWORK_ERROR",
          action: () => {
            resetAppErrorConnect();
            history.go(0);
          },
          buttonTitle: t("errors.retry")
        });
        break;
      case ApiResponseErrorType.HTTP_UNAUTHORIZED:
      case ApiResponseErrorType.HTTP_PAYMENT_REQUIRED:
        setErrorToBeShown({
          title: t("errors.permission"),
          description: t("errors.permission_desc"),
          picUrl: "PERMISSION_ERROR",
          action: () => {
            resetAppErrorConnect();
            history.replace(ROUTES.DASHBOARD);
          },
          buttonTitle: t("errors.dashboard")
        });
        break;
      case ApiResponseErrorType.HTTP_PRECONDITION_REQUIRED:
      case ApiResponseErrorType.HTTP_PRECONDITION_FAILED:
      case ApiResponseErrorType.HTTP_TRANSFORMATION:
      case ApiResponseErrorType.HTTP_BAD_REQUEST:
      case ApiResponseErrorType.HTTP_SERVER_ERROR:
      case ApiResponseErrorType.HTTP_SERVICE_UNAVAILABLE:
        setErrorToBeShown({
          title: t("errors.server"),
          description: t("errors.server_desc"),
          picUrl: "SERVER_ERROR",
          action: () => {
            resetAppErrorConnect();
            history.go(0);
          },
          buttonTitle: t("errors.retry")
        });
        break;
      case ApiResponseErrorType.HTTP_FORBIDDEN:
      case ApiResponseErrorType.HTTP_NOT_FOUND:
        setErrorToBeShown({
          title: t("errors.not_found"),
          description: t("errors.not_found_desc"),
          picUrl: "NOT_FOUND_ERROR",
          action: () => {
            resetAppErrorConnect();
            history.replace(ROUTES.DASHBOARD);
          },
          buttonTitle: t("errors.dashboard")
        });
        break;

      case ApiResponseErrorType.HTTP_GENERIC:
        setErrorToBeShown({
          title: t("errors.default"),
          description: t("errors.default_desc"),
          picUrl: "SERVER_ERROR",
          action: () => {
            resetAppErrorConnect();
            history.go(0);
          },
          buttonTitle: t("errors.retry")
        });
        break;
      case ApiResponseErrorType.GENERIC:
        setErrorToBeShown({
          title: errorConnect?.toString(),
          description: t("errors.custom"),
          picUrl: "CUSTOM_ERROR",
          action: () => {
            resetAppErrorConnect();
            history.go(0);
          },
          buttonTitle: t("errors.public_feed")
        });
        break;

      default:
        setErrorToBeShown({
          title: t("errors.default"),
          description: t("errors.default_desc"),
          action: () => {
            resetAppErrorConnect();
            history.go(0);
          },
          buttonTitle: t("general.back")
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorConnect]);

  // TODO: Immidiately show error when no network detected. needs troubleshooting
  // useEffect(() => {
  //   console.log(networkStatus);
  //   if (
  //     availableFeatures &&
  //     availableFeatures.getStatus &&
  //     networkStatus &&
  //     !networkStatus.connected
  //   ) {
  //     setKnownErrorType(ERROR_TYPES.NETWORK_ERROR);
  //   }
  // }, [networkStatus]);

  return (
    <IonModal
      isOpen={errorToBeShown !== undefined}
      backdropDismiss={false}
      cssClass="fullpage-modal"
    >
      <ClanHeaderRays />
      <div className="overflow-y-auto">
        {errorToBeShown && errorToBeShown.picUrl && (
          <div className="text-center flex items-center justify-center">
            <IonImg src={`/assets/${errorToBeShown.picUrl}.svg`} />
          </div>
        )}
        <div className="p-clanFlowStepScreenEdge text-center">
          {errorToBeShown && errorToBeShown.title && (
            <IonText>
              <h3 className="max-w-clanFullScreenWidth">
                {errorToBeShown.title}
              </h3>
            </IonText>
          )}
          {errorToBeShown && errorToBeShown.description && (
            <IonText>
              <p>{errorToBeShown.description}</p>
            </IonText>
          )}
        </div>
      </div>
      <IonFooter className="ion-no-border">
        <IonSegment className="bg-clanYellow-100">
          <IonSegmentButton
            onClick={() => {
              history.replace(ROUTES.DASHBOARD);
              history.go(0);
            }}
            color="primary"
            className="font-extrabold font-gilroy text-clanGreen-100 bg-white"
          >
            {t("errors.home")}
          </IonSegmentButton>
          <IonSegmentButton
            disabled={!isConnected}
            onClick={errorToBeShown?.action}
            color="primary"
            className="font-extrabold font-gilroy text-black"
          >
            {!isConnected ? t("errors.offline") : errorToBeShown?.buttonTitle}
          </IonSegmentButton>
        </IonSegment>
      </IonFooter>
    </IonModal>
  );
};

export default connect<{}, StateProps>({
  mapStateToProps: (state) => ({
    errorConnect: state.appState.error
  }),
  mapDispatchToProps: {
    resetAppErrorConnect: resetAppError
  },
  component: ClanErrorModal
});
