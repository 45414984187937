import {
  DummyPaginatedStreamDataIterator,
  PaginatedStreamDataIterator,
  ResultDataApiIterator
} from "../ResultDataIterator";
import { GroupResponse } from "./OrganisationGroupService";
import { useEffect, useState } from "react";
import { Views } from "../Configuration";

export const useGroupQueryViewFilter = (
  view: Views
): ResultDataApiIterator<GroupResponse> => {
  const [iterator, setIterator] = useState<
    ResultDataApiIterator<GroupResponse>
  >(new DummyPaginatedStreamDataIterator<GroupResponse>());

  useEffect(() => {
    setIterator(
      new PaginatedStreamDataIterator<GroupResponse>(`/group/v3/view/${view}`)
    );
  }, [view]);
  return iterator;
};
