import React from "react";
import styled from "styled-components";
import { InfoTooltip } from "./Tooltip";

const StyledCategoryHeader = styled.div`
  margin: 28px auto 24px;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  padding-bottom: 0.3rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  font-family: Gilroy;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin-right: 0.3rem;
  text-transform: uppercase;
`;

const SubTitle = styled.div`
  font-family: Gilroy;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #878396;
  letter-spacing: 0.203493px;
  cursor: pointer;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface CategoryHeaderAction {
  title: string;
  action: () => void;
}

interface CategoryHeader {
  title: string;
  subtitle?: string;
  tooltipText?: string;
  actions?: Array<CategoryHeaderAction>;
  className?: string;
}

const CategoryHeader: React.FC<CategoryHeader> = ({
  title,
  subtitle,
  actions,
  tooltipText,
  className
}) => (
  <StyledCategoryHeader className={className}>
    <TitleWrapper>
      <Title>{title}</Title>
      {tooltipText && <InfoTooltip content={tooltipText} />}
    </TitleWrapper>

    <SubTitle>{subtitle}</SubTitle>
    {actions && actions.length > 0 && (
      <ActionsWrapper>
        {actions.map((action, key) => (
          <SubTitle key={key} onClick={action.action}>
            {action.title}
          </SubTitle>
        ))}
      </ActionsWrapper>
    )}
  </StyledCategoryHeader>
);

export default CategoryHeader;
