import { IonPage } from "@ionic/react";
import { useAsyncEffect } from "@react-hook/async";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";

import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import ClanLoader from "../../components/ClanLoader";
import FooterButtons, {
  PrimaryFooterButton
} from "../../components/FooterButtons";
import Header from "../../components/Headers/Header";
import { connect } from "../../data/connect";
import { OauthData } from "../../data/oauth/oauthDataService";
import { isMyProfileRoute } from "../../data/selectors/profileSelectors";
import * as ROUTES from "../../routes";
import {
  ChartResultNumber,
  ChartResultPercentage,
  fetchAnalyticsPersonalDashboard
} from "../../util/Analytics";
import { getUserProfileById } from "../../util/api/ProfileService";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { WellBeingQuestion } from "../../util/api/WellBeingService";
import {
  isReadyStateCancelOrFail,
  isReadyStateLoading
} from "../../util/AsyncEffectFilter";
import { ClanRoleAwareProfileResponse } from "../../util/models/ClanProfileModels";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import ProfileTrends from "../Profile/ProfileTrends";

type OwnProps = RouteComponentProps<{ profileId?: string }>;

interface StateProps {
  isMyProfile: boolean;
  isTeacher: boolean;
  canSeePublicWellbeing: boolean;
}

interface PublicProfileTrendsProps extends OwnProps, StateProps {}

const PublicProfileTrends: React.FC<PublicProfileTrendsProps> = ({
  isMyProfile,
  isTeacher,
  canSeePublicWellbeing
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { profileId } = useParams<{ profileId: string }>();

  const [profile, setProfile] = useState<ClanRoleAwareProfileResponse | null>(
    null
  );
  const [chartNumbers, setChartNumbers] = useState<Array<ChartResultNumber>>(
    []
  );
  const [moodPercentages, setMoodPercentages] = useState<
    Array<ChartResultPercentage>
  >([]);
  const [wellBeingQuestions, setWellBeingQuestions] = useState<
    Array<WellBeingQuestion>
  >([]);

  const fetchStatus = useAsyncEffect(async () => {
    try {
      const profileResponse = await getUserProfileById(profileId);
      if (!profileResponse || isReadyStateCancelOrFail(fetchStatus)) return;
      setProfile(profileResponse);
      if (
        !isMyProfile &&
        (!isTeacher || UserEnabledProperties.Legacy.isAdult(profileResponse))
      ) {
        return;
      }

      const response = await fetchAnalyticsPersonalDashboard(
        !isMyProfile ? profileId : undefined
      );
      if (!response || isReadyStateCancelOrFail(fetchStatus)) return;
      setChartNumbers(response?.numbers ? response.numbers : []);
      setMoodPercentages(response?.mood ? response.mood : []);
      if (isMyProfile || canSeePublicWellbeing) {
        setWellBeingQuestions(response?.wellBeing ? response.wellBeing : []);
      }
    } catch (e) {
      console.error("[FEED] Couldn't load user profile", e);
    }
    // TODO errors are swallowed up
  }, []);

  const createNewReflection = () => history.push(ROUTES.REFLECTIONS);

  const openChat = () =>
    history.push(
      ROUTES.DIRECT_MESSAGE.replace(":profile_id", profileId.toString())
    );

  return (
    <ClanDesktopLayout>
      <ClanLoader
        message={t("general.loading")}
        showLoading={isReadyStateLoading(fetchStatus)}
      />

      <IonPage>
        <Header
          title={profile?.firstName}
          avatar={profile?.images?.thumbnail?.url}
          onBack={() => history.goBack()}
        />
        <ClanIonContent>
          {(isMyProfile ||
            (isTeacher && !UserEnabledProperties.Legacy.isAdult(profile))) && (
            <ProfileTrends
              moodPercentages={moodPercentages}
              chartNumbers={chartNumbers}
              wellBeingQuestions={wellBeingQuestions}
            />
          )}
        </ClanIonContent>

        <FooterButtons>
          {isMyProfile ? (
            <PrimaryFooterButton onClick={createNewReflection}>
              {t("profile.write_reflection")}
            </PrimaryFooterButton>
          ) : (
            <PrimaryFooterButton onClick={openChat}>
              {t("profile.message")}
            </PrimaryFooterButton>
          )}
        </FooterButtons>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state, { match }) => ({
    isMyProfile: isMyProfileRoute(
      state.token as OauthData,
      match.params.profileId
    ),
    isTeacher: UserEnabledProperties.Legacy.isAdult(state.token),
    canSeePublicWellbeing: UserEnabledProperties.WellBeing.othersProfile(
      state.token
    )
  }),
  component: PublicProfileTrends
});
