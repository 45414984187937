import React from "react";
import styled from "styled-components";

import { ReactComponent as NavNextIcon } from "../icons/arrow-nav-next.svg";
import { ReactComponent as NavPrevIcon } from "../icons/arrow-nav-prev.svg";
import IconButton from "./Buttons/IconButton";
import Card from "./Card";
import { FlexCol } from "./Flexbox";
import { H5, H6 } from "./Typography";

const StyledAdvancedCard = styled(Card)`
  margin: 12px 5%;
  padding: 24px;
  display: grid;
  grid-template-columns: 40px auto 40px;
  column-gap: 12px;
  align-items: center;
`;

const NavIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.primary};

  svg {
    --color-primary: black;
    width: 20px;
    height: 20px;
  }
`;

const Delimiter = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.primary};
`;

interface AdvancedCardProps {
  primaryText?: JSX.Element | string;
  secondaryText?: string;
  onPrevClick?: () => void;
  onNextClick?: () => void;
}

const AdvancedCard: React.FC<AdvancedCardProps> = ({
  primaryText,
  secondaryText,
  onPrevClick,
  onNextClick
}) => {
  const hasPrimaryComponent = React.isValidElement(primaryText);

  return (
    <StyledAdvancedCard>
      {onPrevClick && (
        <NavIconWrapper>
          <IconButton onClick={onPrevClick} icon={<NavPrevIcon />} />
        </NavIconWrapper>
      )}

      <FlexCol rowGap="8px">
        {primaryText &&
          (hasPrimaryComponent ? primaryText : <H5>{primaryText}</H5>)}

        {primaryText && <Delimiter />}

        {secondaryText && <H6>{secondaryText}</H6>}
      </FlexCol>
      {onNextClick && (
        <NavIconWrapper>
          <IconButton onClick={onNextClick} icon={<NavNextIcon />} />
        </NavIconWrapper>
      )}
    </StyledAdvancedCard>
  );
};

export default AdvancedCard;
