import React from "react";
import styled from "styled-components";
import { H6Style } from "../Typography";

const StyledTextarea = styled.textarea`
  ${H6Style}
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 16px 12px;
  border-radius: 10px;
  resize: none;
  outline: none;
`;

interface TextareaProps {
  value?: string;
  placeholder?: string;
  rows?: number;
  onChange: (newValue: string) => void;
}
const Textarea: React.FC<TextareaProps> = ({
  value,
  placeholder,
  rows = 4,
  onChange
}) => {
  const onTextareaChange = (e: any) => onChange(e.target.value);

  return (
    <StyledTextarea
      value={value}
      placeholder={placeholder}
      onChange={onTextareaChange}
      rows={rows}
    />
  );
};

export default Textarea;
