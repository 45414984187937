import { IonContent } from "@ionic/react";
import { useAsyncEffect } from "@react-hook/async";
import React, { useState } from "react";

import { SmoothRaysInverted } from "../../components/ClanSmoothRays";
import { connect } from "../../data/connect";
import { setOauth } from "../../data/oauth/oauth.actions";
import { isReadyStateLoading } from "../../util/AsyncEffectFilter";
import { logoutFromFirebase } from "../../util/GoogleFirebase";
import { ClanOauthResponse } from "../../util/models/ClanOauthResponse";
import PrivacyTermsInput from "./PrivacyTermsInput";
import SchoolNameInput from "./SchoolNameInput";
import {
  BackButton,
  MethodTypes,
  StartSteps,
  StartToolbar,
  StartWrapper
} from "./Start";

interface OwnProps {
  setCurrentStep: (step: StartSteps) => void;
  loading: boolean;
  resetStartState: () => void;
  mode: MethodTypes;
  setMode: (method: MethodTypes) => void;
}

interface DispatchProps {
  setUserOauthConnect: (requestResponse: ClanOauthResponse) => void;
}

interface SchoolProps extends OwnProps, DispatchProps {}

const School: React.FC<SchoolProps> = ({
  setUserOauthConnect,
  loading,
  resetStartState,
  mode
}) => {
  const [oauthToken, setOauthToken] = useState<ClanOauthResponse>();
  const [privacyAndTermsChecked, setPrivacyAndTermsChecked] = useState(false);

  const initStatus = useAsyncEffect(async () => {
    if (oauthToken && privacyAndTermsChecked) {
      await setUserOauthConnect(oauthToken);
    }
  }, [oauthToken, privacyAndTermsChecked]);

  const onDataDuplication = () => {
    resetStartState();
  };

  return (
    <IonContent>
      <StartWrapper>
        <StartToolbar id="dashboard-header">
          <SmoothRaysInverted />
          {!oauthToken && (
            <BackButton
              src="/assets/arrow_back_black.svg"
              onClick={() => logoutFromFirebase().then(resetStartState)}
            />
          )}
        </StartToolbar>
        {!oauthToken && (
          <SchoolNameInput
            mode={mode}
            onDataDuplication={onDataDuplication}
            loading={loading && isReadyStateLoading(initStatus)}
            onDone={setOauthToken}
          />
        )}
        {oauthToken && !privacyAndTermsChecked && (
          <PrivacyTermsInput
            onDone={() => setPrivacyAndTermsChecked(true)}
            loading={loading && isReadyStateLoading(initStatus)}
          />
        )}
      </StartWrapper>
    </IonContent>
  );
};

export default connect<OwnProps, {}>({
  mapDispatchToProps: {
    setUserOauthConnect: setOauth
  },
  component: School
});
