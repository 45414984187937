import { IonLabel, IonNote } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import ClanFlowStepTemplate from "../../components/ClanFlowStepTemplate";
import ClanOnboardingListTemplate from "../../components/ClanOnboardingListTemplate";
import FooterButtons, {
  PrimaryFooterButton,
  SecondaryFooterButton
} from "../../components/FooterButtons";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import { updateUserProfile } from "../../data/user/profile/profile.actions";
import { UserProfileState } from "../../data/user/profile/profile.state";
import { InterestAPI } from "../../util/api/ProfileService";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "./Onboarding";
import { fetchIgnoring404, setIgnoring404 } from "./OnboardingHelper";

interface OwnProps {
  setCurrentStep: any;
  isEditing: any;
}

interface StateProps {
  userProfileStateConnect: UserProfileState;
}

interface DispatchProps {
  updateUserProfileConnect: typeof updateUserProfile;
  dispatchToast: typeof showToast;
}

interface InterestsStepProps extends StateProps, DispatchProps, OwnProps {}

const InterestsStep: React.FC<InterestsStepProps> = ({
  setCurrentStep,
  isEditing,
  updateUserProfileConnect,
  dispatchToast
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [interests, setInterests] = useState<Array<string> | undefined>(
    undefined
  );
  const [userInterests, setUserInterests] = useState<Array<string> | undefined>(
    undefined
  );

  useEffect(() => {
    fetchIgnoring404(
      [
        [InterestAPI.list, setInterests, []],
        [InterestAPI.get, setUserInterests, []]
      ],
      setLoading,
      (error) => dispatchToast(`Couldn't get list of interests:" ${error}`)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateInterests = async () => {
    if (!userInterests) return;
    const response = await setIgnoring404(
      InterestAPI.add,
      userInterests,
      setLoading,
      dispatchToast
    );
    if (response) {
      console.debug("[ONBOARDING] Successfully saved interests.");
      isEditing ? history.goBack() : setCurrentStep(5);
      await updateUserProfileConnect({ interests: response });
    }
  };

  return (
    <>
      <ClanIonContent>
        <OnboardingWrapper>
          <ClanFlowStepTemplate
            title={t("onboarding.interests_header")}
            headerClassName="text-center"
            noContentMargin
          >
            <div className="text-center">
              {userInterests && interests ? (
                <>
                  <IonNote slot="start">
                    {t("onboarding.selected", {
                      number: userInterests?.length
                    })}
                  </IonNote>
                  <ClanOnboardingListTemplate
                    callback={setUserInterests}
                    existingItems={userInterests}
                    listItems={interests}
                    newItemModalTitle={t("onboarding.new_item")}
                  />
                </>
              ) : (
                <IonLabel>{t("onboarding.loading_2")}</IonLabel>
              )}
            </div>
          </ClanFlowStepTemplate>
        </OnboardingWrapper>
      </ClanIonContent>
      <FooterButtons>
        <SecondaryFooterButton
          disabled={isLoading}
          onClick={() => (isEditing ? history.goBack() : setCurrentStep(3))}
        >
          {isEditing ? t("onboarding.cancel") : t("general.back")}
        </SecondaryFooterButton>
        <PrimaryFooterButton disabled={isLoading} onClick={updateInterests}>
          {isEditing ? t("onboarding.save") : t("onboarding.next")}
        </PrimaryFooterButton>
      </FooterButtons>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    userProfileStateConnect: state.userProfile
  }),
  mapDispatchToProps: {
    updateUserProfileConnect: updateUserProfile,
    dispatchToast: showToast
  },
  component: InterestsStep
});
