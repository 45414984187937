import { IonHeader, IonModal, IonText } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";
import ClanMoodSelect from "../ClanDiscussionPost/edit/ClanMoodSelect";
import FooterButtons, { PrimaryFooterButton } from "../FooterButtons";

type MoodModalProps = {
  isOpen: boolean;
  onMoodSelect: (mood: string) => void;
  onDismiss: () => void;
};

const MoodModal: React.FC<MoodModalProps> = ({
  isOpen,
  onMoodSelect,
  onDismiss
}) => {
  const { t } = useTranslation();
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
      <IonHeader mode="ios" className="px-4">
        <div className="mt-12 text-center">
          <IonText>
            <h3 className="my-1">{t("planner.set_mood")}</h3>
          </IonText>
          <IonText>
            <p>{t("clan_edit_post.feeling")}</p>
          </IonText>
        </div>
      </IonHeader>
      <ClanIonContent>
        <ClanMoodSelect callback={onMoodSelect} />
      </ClanIonContent>
      <FooterButtons>
        <PrimaryFooterButton onClick={onDismiss}>
          {t("planner.cancel")}
        </PrimaryFooterButton>
      </FooterButtons>
    </IonModal>
  );
};
export default MoodModal;
