import { OauthState } from "../../../data/oauth/oauth.state";
import { UserEnabledProperties } from "../../api/UserEnabledPropertiesService";
import { doRequestAndReturnResult } from "../../../apiService";
import { UserComplaintListResult } from "../types";
import {
  FilterGroupType,
  joinFilterGroupTypeId,
  resolveConstantGroupFilter
} from "../../Filters";

const COMPLAINT_BASE_URL = "complaints/v1/pending";

export const fetchReportedComplaints = async (
  oauthData: OauthState,
  filters?: FilterGroupType[]
): Promise<UserComplaintListResult[] | null> => {
  if (!UserEnabledProperties.Accessibility.antiBullyReport(oauthData)) {
    return null;
  }
  const filter = resolveConstantGroupFilter(filters);

  if (filter) {
    return doRequestAndReturnResult<UserComplaintListResult[]>(
      "get",
      `${COMPLAINT_BASE_URL}/${filter.filter}`
    );
  }

  const groups = joinFilterGroupTypeId(filters);
  if (groups) {
    return doRequestAndReturnResult<UserComplaintListResult[]>(
      "get",
      `${COMPLAINT_BASE_URL}`,
      undefined,
      // eslint-disable-next-line @typescript-eslint/camelcase
      groups ? { group_id: groups } : undefined
    );
  }
  return doRequestAndReturnResult<UserComplaintListResult[]>(
    "get",
    `${COMPLAINT_BASE_URL}`
  );
};

export const fetchComplaintActions = async (
  oauthData: OauthState,
  complaintId: string
): Promise<{ [key: string]: string[] }> => {
  return doRequestAndReturnResult<{ [key: string]: string[] }>(
    "get",
    `/complaints/v1/${complaintId}/actions`
  );
};

export const doComplaintAction = async (
  oauthData: OauthState,
  complaintId: string,
  actionKey: string,
  actionValue: string
): Promise<any[]> => {
  return doRequestAndReturnResult<any[]>(
    "put",
    `/complaints/v1/${complaintId}/actions/${actionKey}/${actionValue}`
  );
};
