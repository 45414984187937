import React, { useEffect, useState } from "react";

import { setAnalyticsScreenName } from "../../Analytics";
import {
  addCommentToPost,
  addPersonalPost,
  addQuestion,
  addReflectionToTask,
  getPostById
} from "../../util/ClanPostAPIService";
import {
  FeedTypes,
  findViewFilterByFeedType,
  savePost,
  updatePost
} from "../../util/Feeds";
import {
  ClanEditPostEditDetails,
  ClanPostResultDetailsV3
} from "../../util/models/ClanPostModels";
import ClanEditPost from "./edit/ClanEditPost";
import { Views } from "../../util/Configuration";

interface ClanEditPostWithSaveProps {
  reflectionTaskId?: string;
  feedType: FeedTypes;
  defaultPageTitle: string;
  parentPostId?: string;
  postId?: string;
  requireShare?: boolean;
  onPost: (postResult: ClanEditPostEditDetails) => void;
  onCancel: () => void;
  isOpen: boolean;
  onDidDismiss: () => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  removeLocalImage?: (postId: string) => void;
}

const ClanEditPostWithSave: React.FC<ClanEditPostWithSaveProps> = ({
  reflectionTaskId,
  feedType,
  defaultPageTitle,
  parentPostId = null,
  postId = null,
  requireShare = false,
  onPost,
  onCancel,
  isOpen,
  onDidDismiss,
  setLoading,
  loading,
  removeLocalImage
}) => {
  const [id, setId] = useState<string>();
  const [post, setPost] = useState<ClanPostResultDetailsV3>();

  useEffect(() => {
    setAnalyticsScreenName("POST_EDIT");
    (async () => {
      setId(postId ? postId : "");
      if (!postId) {
        setPost({} as ClanPostResultDetailsV3);
        return;
      }
      const result = await getPostById(postId);
      setPost(result);
    })();
  }, [postId, reflectionTaskId]);

  // TODO - this will be removed and implemented in modal
  const doPost = async (newPost: ClanEditPostEditDetails) => {
    let result = null;
    let newPostResult = null;
    try {
      setLoading(true);
      if (!id && reflectionTaskId) {
        result = await addReflectionToTask(reflectionTaskId, newPost);
      } else if (!id && parentPostId) {
        result = await addCommentToPost(parentPostId, newPost);
      }

      const view =
        feedType === FeedTypes.PERSONAL
          ? Views.ReflectionFeed
          : findViewFilterByFeedType(feedType);
      if (!view) {
        console.error(
          `[DISCUSSION_EDIT_POST] Couldn't save or edit the new post as view for ${feedType} not found!`
        );
        return;
      }

      if (result === null) {
        if (id) {
          result = await updatePost(view, id, newPost);
        } else if (feedType === FeedTypes.PERSONAL) {
          result = await addPersonalPost(newPost);
        } else if (feedType === FeedTypes.QUESTION) {
          result = await addQuestion(newPost);
        } else {
          newPostResult = await savePost(view, newPost);
        }
      }

      onPost({
        id: newPostResult?.id,
        ...result,
        image: newPost.image
      } as ClanEditPostEditDetails);
    } catch (e) {
      console.error(
        "[DISCUSSION_EDIT_POST] Couldn't save or edit the new post",
        e
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <ClanEditPost
      hasParentPost={!!parentPostId}
      feedType={feedType}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      onCancel={onCancel}
      onPost={doPost}
      requireShare={requireShare}
      defaultPageTitle={defaultPageTitle}
      post={post}
      removeLocalImage={removeLocalImage}
      setLoading={setLoading}
      loading={loading}
    />
  );
};

export default ClanEditPostWithSave;
