import React, { useState } from "react";
import {
  IonButton,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonItem,
  IonList,
  IonAlert
} from "@ionic/react";
import {
  arrowForward,
  closeCircle,
  calendarOutline,
  discOutline,
  timerOutline
} from "ionicons/icons";
import ClanDateTimePick from "./date/ClanDateTimePick";
import { parse, isPast } from "date-fns";
import { dateToDateString } from "../util/DateUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ClanIonContent } from "../pages/DiscussionFeed/PublicFeeds";

const Divider: React.FC = () => (
  <div className="border border-solid border-gray-100 border-r-0 border-l-0 border-b-0"></div>
);

type SchedulerItemProps = {
  isSet?: boolean;
  label?: string;
  icon: string;
  onClear?: () => void;
  onClick?: Function;
};

const SchedulerItem: React.FC<SchedulerItemProps> = ({
  isSet,
  icon,
  label,
  onClear,
  onClick,
  children
}) => (
  <IonItem
    className={`text-clanP ${isSet ? "text-black" : "text-clanGray-200"}`}
    onClick={(...args) => onClick && onClick(...args)}
  >
    <IonIcon
      slot="start"
      size="small"
      className={`h-4 m-1 ${isSet ? "text-clanGreen-100" : ""}`}
      icon={icon}
    />
    {isSet && (
      <IonIcon
        icon={closeCircle}
        slot="end"
        className="px-1 m-0 text-2xl text-clanGray-200 cursor-pointer"
        onClick={onClear}
      />
    )}
    {isSet && label && <span>{label}:&nbsp;</span>}
    {children}
  </IonItem>
);

const ForcedBackdropModal = styled(IonModal)`
  --backdrop-opacity: 0.35;
`;

interface TaskSchedulerModalProps {
  isOpen: boolean;
  onClose: () => void;
  targetDate: string | undefined;
  dueDate: string | undefined;
  reminder: Date | undefined;
  onTargetDateChange: (date: string | undefined) => void;
  onDueDateChange: (date: string | undefined) => void;
  onReminderChange: (date: Date | undefined) => void;
  onAddToMyList: () => void;
}

const TaskSchedulerModal: React.FC<TaskSchedulerModalProps> = ({
  isOpen,
  onClose,
  targetDate,
  dueDate,
  reminder,
  onTargetDateChange,
  onDueDateChange,
  onReminderChange,
  onAddToMyList
}) => {
  const [showAddToListAlert, setShowAddToListAlert] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <>
      <IonAlert
        isOpen={showAddToListAlert}
        mode="ios"
        onDidDismiss={() => setShowAddToListAlert(false)}
        header={t("task_scheduler_modal.due_date_remove")}
        message={t("task_scheduler_modal.due_date_message")}
        buttons={[
          {
            text: t("task_scheduler_modal.cancel"),
            handler: () => setShowAddToListAlert(false),
            cssClass: "text-black"
          },
          {
            text: t("general.ok"),
            handler: onAddToMyList,
            cssClass: "text-black"
          }
        ]}
      />
      <ForcedBackdropModal
        id="task_scheduler_modal"
        isOpen={isOpen}
        onDidDismiss={onClose}
      >
        <IonHeader mode="ios" className="px-4">
          <h5 className="text-center mb-4 mt-10 text-clanH4 font-bold text-clanGray-200">
            {t("task_scheduler_modal.schedule")}
          </h5>
        </IonHeader>
        <ClanIonContent>
          <IonList>
            <SchedulerItem
              isSet={!!dueDate}
              label={t("task_scheduler_modal.due")}
              icon={calendarOutline}
              onClear={() => onDueDateChange(undefined)}
            >
              <ClanDateTimePick
                placeholder={t("task_scheduler_modal.due_date_set")}
                className="relative"
                setTime={false}
                value={dueDate ? dueDate.toString() : undefined}
                onDelete={() => onDueDateChange(undefined)}
                onDone={(value) => onDueDateChange(dateToDateString(value))}
                dateFormat="DDD, MMM DD"
                min={(() => {
                  const date = new Date();
                  const dDate =
                    dueDate && parse(dueDate, "yyyy-MM-dd", new Date());
                  return dDate && isPast(dDate) ? dueDate : date.toISOString();
                })()}
                yearValues={(() => {
                  const currentYear = new Date().getFullYear();
                  return [currentYear, currentYear + 1].join(",");
                })()}
              />
            </SchedulerItem>

            {/* TODO: Date first, time second for reminder */}
            <SchedulerItem
              isSet={!!targetDate}
              icon={discOutline}
              label={t("task_scheduler_modal.working_on")}
              onClear={() => onTargetDateChange(undefined)}
            >
              <ClanDateTimePick
                className={`relative ${
                  targetDate ? "text-black" : "text-clanGray-200"
                } text-clanP`}
                placeholder={t("task_scheduler_modal.working_on_question")}
                value={targetDate ? targetDate : undefined}
                dateFormat="DDD, MMM DD"
                setTime={false}
                onDone={(value) => onTargetDateChange(dateToDateString(value))}
                onDelete={() => onTargetDateChange(undefined)}
                min={(() => {
                  const date = new Date();
                  const tDate =
                    targetDate && parse(targetDate, "yyyy-MM-dd", new Date());
                  return tDate ? tDate.toISOString() : date.toISOString();
                })()}
                yearValues={(() => {
                  const currentYear = new Date().getFullYear();
                  return [currentYear, currentYear + 1].join(",");
                })()}
              />
            </SchedulerItem>

            <SchedulerItem
              isSet={!!reminder}
              label={t("task_scheduler_modal.reminder")}
              icon={timerOutline}
              onClear={() => reminder && onReminderChange(undefined)}
            >
              <ClanDateTimePick
                className="relative"
                placeholder={t("task_scheduler_modal.add_reminder")}
                setTime={true}
                timeFormat="HH:mm | DDD, MMM DD"
                value={reminder ? reminder.toString() : undefined}
                onDone={onReminderChange}
                onDelete={() => onReminderChange(undefined)}
                min={(() => {
                  const date = new Date();
                  date.setHours(0);
                  return date.toISOString();
                })()}
              />
            </SchedulerItem>

            <Divider />

            {dueDate ? (
              <SchedulerItem
                onClick={() => setShowAddToListAlert(true)}
                icon={arrowForward}
              >
                <IonLabel>
                  <h5 className="text-clanGreen-100">
                    {t("task_scheduler_modal.remove_date")}
                  </h5>
                </IonLabel>
              </SchedulerItem>
            ) : (
              <SchedulerItem icon={arrowForward}>
                <ClanDateTimePick
                  className="text-clanGreen-100 text-clanH4"
                  placeholder={t("task_scheduler_modal.add_date")}
                  setTime={false}
                  onDone={(value) => onDueDateChange(dateToDateString(value))}
                  onDelete={() => onDueDateChange(undefined)}
                />
              </SchedulerItem>
            )}
          </IonList>
        </ClanIonContent>
        <IonFooter mode="ios">
          <IonButton
            id="close-scheduler-btn"
            className="m-0 mb-4 self-stretch font-extrabold font-gilroy text-clanGreen-100 text-base"
            expand="full"
            fill="clear"
            onClick={onClose}
          >
            {t("general.close")}
          </IonButton>
        </IonFooter>
      </ForcedBackdropModal>
    </>
  );
};

export default TaskSchedulerModal;
