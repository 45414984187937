import { AsyncReducerState, useAsyncEffect } from "@react-hook/async";
import { isAfter } from "date-fns";
import { UserTaskResult } from "../util/models/PlannerUserTaskModels";
import { dateToDateString } from "../util/DateUtils";
import { analyticsLogEvent } from "../Analytics";
import {
  getNextWellBeingSurveyQuestion,
  WellBeingSurveyResponseType
} from "../util/api/WellBeingService";
import { OauthState } from "../data/oauth/oauth.state";
import { fetchReportedComplaints } from "../util/Complaint";
import { ApiRequestError, AuthorizedApiResult } from "../util/ApiService";
import { getInfoModalClosedCookie } from "../data/app/appDataService";
import { UserEnabledProperties } from "../util/api/UserEnabledPropertiesService";

export const useDashboardStatus = (
  oauthState: OauthState,
  reportTimestamp: string | undefined
): [
  AsyncReducerState<boolean, ApiRequestError>,
  AsyncReducerState<boolean, ApiRequestError>,
  AsyncReducerState<UserTaskResult, ApiRequestError>,
  AsyncReducerState<boolean, ApiRequestError>
] => {
  const isAdultStatus = useAsyncEffect<boolean, ApiRequestError>(async () => {
    if (!oauthState.hasToken) {
      return false;
    }
    const isClosed = await getInfoModalClosedCookie();
    return !isClosed && UserEnabledProperties.Legacy.isAdult(oauthState);
  }, [oauthState]);

  const pendingComplaintsStatus = useAsyncEffect<
    boolean,
    ApiRequestError
  >(async () => {
    if (!oauthState.hasToken || !reportTimestamp) {
      return false;
    }
    const complaints = await fetchReportedComplaints(oauthState);
    if (!complaints || complaints.length === 0 || !complaints[0].createdAt)
      return false;
    return isAfter(
      new Date(complaints[0].createdAt),
      new Date(reportTimestamp)
    );
  }, [reportTimestamp, oauthState]);

  const pendingTaskStatus = useAsyncEffect<
    UserTaskResult | undefined,
    ApiRequestError
  >(async () => {
    if (!oauthState.hasToken) {
      return undefined;
    }
    const dateArgument = dateToDateString(new Date());
    analyticsLogEvent("tasks_list_today");
    const taskResult = await AuthorizedApiResult.get<UserTaskResult[]>(
      `/usertask/v1/todo/today/dashboard?date=${dateArgument}&limit=1`
    );
    if (taskResult && taskResult.length > 0) {
      return taskResult[0];
    }
    return undefined;
  }, [oauthState]);

  const pendingWellBeingQuestionStatus = useAsyncEffect<
    boolean,
    ApiRequestError
  >(async () => {
    if (!oauthState.hasToken) {
      return false;
    }
    const questionOrMessage = await getNextWellBeingSurveyQuestion();
    return (
      questionOrMessage &&
      questionOrMessage.resultType === WellBeingSurveyResponseType.question
    );
  }, [oauthState]);

  return [
    isAdultStatus,
    pendingComplaintsStatus,
    pendingTaskStatus,
    pendingWellBeingQuestionStatus
  ];
};
