import { getConfiguration } from "./ConfigurationCore";
import { Views } from "../types/Views.d";
import { ViewSharingConfiguration } from "../types/ViewSharings.d";
import { useState } from "react";
import { useAsyncEffect } from "@react-hook/async";

export const getConstantSharingFilters = async (
  viewFilter: Views
): Promise<ViewSharingConfiguration> => {
  const conf = await getConfiguration();
  return conf.views[viewFilter].sharing;
};

export const useSharingConstants = (view: Views) => {
  const [filterResult, setFilterResult] = useState<
    ViewSharingConfiguration | undefined
  >();
  useAsyncEffect(async () => {
    const result = await getConstantSharingFilters(view);
    setFilterResult(result);
  }, [view]);
  return filterResult;
};
