import { OauthData } from "../../../data/oauth/oauthDataService";
import { setOauth } from "../../../data/oauth/oauth.actions";
import { dispatch } from "../../../data/AppContext";
import { AuthorizedApi } from "../../ApiService";
import { CommunityJoinResponse } from "../types/CommunityJoinResponse";
import { CommunityJoinTarget } from "../types/CommunityJoinTarget.d";
import { setFeedFilters } from "../../../data/feed/feed.actions";

export const joinWithInvitationCode = async (
  oauthData: OauthData,
  joinCode: string
): Promise<CommunityJoinTarget> => {
  const result = await AuthorizedApi.put<CommunityJoinResponse>({
    url: `invitation/v1/join/${joinCode}`,
    data: oauthData.refreshToken,
    headers: { "content-type": "text/plain" }
  });
  await setOauth(result.token)(dispatch);
  await setFeedFilters([])(dispatch);
  return result.joinTarget;
};
