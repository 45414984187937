import {
  ClanPostListResult,
  ClanPostListResultV1,
  ClanPostRequest,
  ClanPostResultDetails
} from "../models/ClanPostModels";
import {
  PaginatedStreamDataIterator,
  ResultDataIterator
} from "../ResultDataIterator";
import { AuthorizedApiResult } from "../ApiService";

export const fetchDirectMessages = (): Promise<ClanPostListResult[]> =>
  AuthorizedApiResult.get<ClanPostListResult[]>("/feed/v1/direct_message");

export const initPaginatedDirectMessages = (
  profileId: string
): ResultDataIterator<ClanPostListResultV1> => {
  return new PaginatedStreamDataIterator<ClanPostListResultV1>(
    `/feed/v1/direct_message/${profileId}`
  );
};

export const fetchDirectMessagesForProfile = (
  profileId: string
): Promise<ClanPostListResultV1[]> =>
  AuthorizedApiResult.get<ClanPostListResultV1[]>(
    `/feed/v1/direct_message/${profileId}`
  );

export const postNewDirectMessage = (
  directMessage: ClanPostRequest
): Promise<ClanPostResultDetails> =>
  AuthorizedApiResult.post<ClanPostResultDetails>({
    url: "/feed/v1/direct_message/",
    data: directMessage
  });

export const pingTeacher = (profileId: number): Promise<ClanPostListResult[]> =>
  AuthorizedApiResult.put<ClanPostListResult[]>(
    `/feed/v1/direct_message/${profileId}/ping/teacher`
  );
