import { doRequestAndReturnResult } from "../../apiService";
import { SimpleProfile } from "../models/ClanProfileModels";
import { PaginatedStreamDataIterator } from "../ResultDataIterator";

export enum GroupService {
  Classroom = "LMS_google-classroom"
}

export type GroupResponse = {
  id: number;
  name: string;
  type: string;
  organisationId: number;
  partOfOrganisation: boolean;
  groupOrOrganisationPaidOrTrialPaidUntil: string | null;
  groupOrOrganisationPaidUntil: string | null;
  groupOrOrganisationTrialUntil: string | null;
  organisationPaidUntil: string | null;
  organisationTrialUntil: string | null;
};

export type GroupInvitationHashResponse = {
  id: number;
  invitationHash: string;
  groupId: number;
  expiresAt: string;
};

export type GroupDetailsResponse = {
  invitations: GroupInvitationHashResponse[]; // filled when user can see invitation
  enabledServices: string[]; // filled when user can see and / or enable and disable services defined below
  availableWellBeings: string[]; // filled when user has access to see and / or enable / disable well-beings
  availableLms: string[]; // filled when user has access to see and / or enable / disable lms
} & GroupResponse;

const getPaginatedGroupSearchByRole = (
  role: string
): PaginatedStreamDataIterator<GroupResponse> => {
  return new PaginatedStreamDataIterator(`/group/v2/membership/role/${role}`);
};

/**
 * Available when user has GROUP_LIST_LEAD_MINE enabled property
 * Use "query" as a query parameter key to search by name
 */
export const getLeadGroupSearch = () => getPaginatedGroupSearchByRole("lead");

/**
 * Available when user has GROUP_LIST_MINE enabled property
 * Use "query" as a query parameter key to search by name
 */
export const getMembershipSearch = (): PaginatedStreamDataIterator<GroupResponse> => {
  return new PaginatedStreamDataIterator(`/group/v2/membership`);
};

export const createGroup = async (
  name: string
): Promise<GroupDetailsResponse> =>
  doRequestAndReturnResult<GroupDetailsResponse>(
    "post",
    `/group/v2/organisation`,
    name,
    undefined,
    { "content-type": "text/plain" }
  );

export const getGroupDetailsById = async (
  groupId: number
): Promise<GroupDetailsResponse> =>
  doRequestAndReturnResult<GroupDetailsResponse>("get", `/group/v2/${groupId}`);

export const getGroupMembersSearch = (
  groupId: number
): PaginatedStreamDataIterator<SimpleProfile> =>
  new PaginatedStreamDataIterator(
    `/group/membership/v1/users/view/group/${groupId}`
  );

export const getMembersToAddToGroupSearch = (
  groupId: number,
  viewFilter: string,
  filter: string
): PaginatedStreamDataIterator<SimpleProfile> =>
  new PaginatedStreamDataIterator(
    `/group/membership/v1/users/view/groups/exclude/${groupId}/filter/${viewFilter}/${filter}`
  );

export const addMembersToGroup = (groupId: number, userIds: Array<number>) =>
  doRequestAndReturnResult<GroupDetailsResponse>(
    "put",
    `/group/membership/v1/group/${groupId}/member?user_id=${userIds.join(",")}`
  );

export const removeUserFromGroup = (groupId: number, userId: number) =>
  doRequestAndReturnResult<GroupDetailsResponse>(
    "delete",
    `/group/membership/v1/user/${userId}/group/${groupId}`
  );

/**
 * Available when user has GROUP_EDIT_NAME enabled property
 * @param name should not be empty and must be minimal 3 characters without whitespace
 * @param groupId group id
 */
export const setGroupNameById = async (
  groupId: number,
  name: string
): Promise<GroupDetailsResponse> =>
  doRequestAndReturnResult<GroupDetailsResponse>(
    "post",
    `/group/v2/${groupId}`,
    name,
    undefined,
    { "content-type": "text/plain" }
  );

/**
 * Enabled when user has GROUP_PREF_WELL_BEING enabled property
 */
export const switchToWellBeingInGroup = async (
  groupId: number,
  wellBeing: string
): Promise<GroupDetailsResponse> =>
  doRequestAndReturnResult<GroupDetailsResponse>(
    "put",
    `/group/v2/${groupId}/wellbeing/${wellBeing}`
  );

/**
 * Enabled when user has GROUP_PREF_WELL_BEING enabled property
 */
export const removeWellBeingForGroup = async (
  groupId: number
): Promise<GroupDetailsResponse> =>
  doRequestAndReturnResult<GroupDetailsResponse>(
    "delete",
    `/group/v2/${groupId}/wellbeing`
  );

/**
 * Enabled when user has GROUP_PREF_LMS enabled property
 */
export const enableGroupLms = async (
  groupId: number,
  lmsName: string
): Promise<GroupDetailsResponse> =>
  doRequestAndReturnResult<GroupDetailsResponse>(
    "put",
    `/group/v2/${groupId}/lms/${lmsName}`
  );

/**
 * Enabled when user has GROUP_PREF_LMS enabled property
 */
export const disableGroupLms = async (
  groupId: number,
  lmsName: string
): Promise<GroupDetailsResponse> =>
  doRequestAndReturnResult<GroupDetailsResponse>(
    "delete",
    `/group/v2/${groupId}/lms/${lmsName}`
  );
