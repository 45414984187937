import { GroupInvitationHashResponse } from "../../api/OrganisationGroupService";
import { isBefore } from "date-fns";
import { AuthorizedApiResult } from "../../ApiService";
import { useAsync, useAsyncEffect } from "@react-hook/async";
import { GroupInvitationCodeResult } from "../types/GroupInvitationCodeResult.d";

const mapGroupInvitationHashResponse = (
  value: GroupInvitationHashResponse
): GroupInvitationCodeResult => {
  const expirationDate = new Date(value.expiresAt);
  return {
    ...value,
    ...{ expirationDate, expired: isBefore(expirationDate, new Date()) }
  } as GroupInvitationCodeResult;
};

export const generateNewGroupInvitationCode = async (
  groupId: number
): Promise<GroupInvitationCodeResult> =>
  AuthorizedApiResult.post<GroupInvitationHashResponse>(
    `invitehash/v1/group/${groupId}`
  ).then((e) => mapGroupInvitationHashResponse(e));

export const getListOfGroupInvitationCodes = async (
  groupId: number
): Promise<GroupInvitationCodeResult[]> =>
  AuthorizedApiResult.get<GroupInvitationHashResponse[]>(
    `invitehash/v1/group/${groupId}`
  ).then((e) => e.map(mapGroupInvitationHashResponse));

export const useInvitationJoinCode = (
  groupId: number | string | undefined
): [GroupInvitationCodeResult | undefined, () => Promise<void>] => {
  const getAsNumber = (
    value: number | string | undefined
  ): number | undefined => {
    if (value === undefined) {
      return undefined;
    }
    return typeof groupId == "number" ? groupId : Number(groupId);
  };

  const existingJoinCode = useAsyncEffect<
    GroupInvitationCodeResult | undefined
  >(async () => {
    const _groupId = getAsNumber(groupId);
    if (_groupId === undefined) {
      return undefined;
    }
    let invalidCode: GroupInvitationCodeResult | undefined = undefined;
    try {
      const result = await getListOfGroupInvitationCodes(_groupId);
      if (result !== undefined && result.length > 0) {
        const validCode = result.find((e) => !e.expired);
        invalidCode = result[0];
        if (validCode !== undefined) {
          return validCode;
        }
      }
    } catch (error) {
      // ignore
    }
    try {
      return generateNewGroupInvitationCode(_groupId);
    } catch (e) {
      if (e.response.status !== 401) {
        throw e;
      }
    }
    return invalidCode;
  }, [groupId]);

  const [newJoinCode, createNewGroup] = useAsync<
    GroupInvitationCodeResult | undefined
  >(async () => {
    const _groupId = getAsNumber(groupId);
    if (_groupId === undefined) {
      return undefined;
    }
    return generateNewGroupInvitationCode(_groupId);
  });
  return [
    newJoinCode?.value ? newJoinCode.value : existingJoinCode.value,
    createNewGroup
  ];
};
