import { cleanUpOldStorage } from "./LocalStorageDataStorage";

export interface DataStorage {
  /**
   * Get the value with the given key.
   */
  get(options: {
    key: string;
  }): Promise<{
    value: string | null;
  }>;
  /**
   * Set the value for the given key
   */
  set(options: { key: string; value: string }): Promise<void>;

  /**
   * Set the value to the local storage for the given key
   */
  setUnsafe(options: { key: string; value: string }): Promise<void>;

  /**
   * Remove the value for this key (if any)
   */
  remove(options: { key: string }): Promise<void>;
  /**
   * Clear stored keys and values.
   */
  clear(): Promise<void>;

  /**
   * Clear only safe storage
   */
  clearSafeStorage(): Promise<void>;

  /**
   * Clear only safe storage
   */
  clearUnsafeStorage(): Promise<void>;

  /**
   * Return the list of known keys
   */
  keys(): Promise<{
    keys: string[];
  }>;
}

const SAFE_PREFIX = "1_";
const UNSAFE_PREFIX = "0_";
const fixPayload = <T extends { key: string }>(prefix: string, value: T): T => {
  return { ...value, ...{ key: prefix + value.key } };
};

const fixSafePayload = <T extends { key: string }>(value: T): T =>
  fixPayload(SAFE_PREFIX, value);
const fixUnsafePayload = <T extends { key: string }>(value: T): T =>
  fixPayload(UNSAFE_PREFIX, value);

const cleanupKeysHelper = async (
  keys: { keys: string[] },
  prefix: string,
  callable: (args: { key: string }) => Promise<void>
) => {
  try {
    await cleanUpOldStorage();
  } catch (e) {
    console.warn("Failed to clean up old storage, but we do not care!");
  }
  if (!keys || !keys.keys || keys.keys.length === 0) return;
  for (let i = 0; i < keys.keys.length; i++) {
    const key = keys.keys[i];
    if (!key || !key.startsWith(prefix)) continue;
    await callable({ key });
  }
};

class MixedStorageWrapper implements DataStorage {
  private readonly safeStorage: DataStorage;
  private readonly unsafeStorage: DataStorage;

  constructor(safeStorage: DataStorage, unsafeStorage: DataStorage) {
    this.safeStorage = safeStorage;
    this.unsafeStorage = unsafeStorage;
  }

  clear(): Promise<void> {
    return this.safeStorage.clear().finally(() => this.unsafeStorage.clear());
  }

  async clearSafeStorage(): Promise<void> {
    const keys = await this.safeStorage.keys();
    return cleanupKeysHelper(keys, SAFE_PREFIX, async (args) =>
      this.safeStorage.remove(args)
    );
  }

  async clearUnsafeStorage(): Promise<void> {
    const keys = await this.unsafeStorage.keys();
    return cleanupKeysHelper(keys, UNSAFE_PREFIX, async (args) =>
      this.unsafeStorage.remove(args)
    );
  }

  get(options: {
    key: string;
  }): Promise<{
    value: string | null;
  }> {
    // TODO check if secondaryStorage has the key- remove it
    return this.safeStorage.get(fixSafePayload(options)).then((value) => {
      if (
        value !== null &&
        value !== undefined &&
        value.value !== null &&
        value.value !== undefined
      ) {
        return value;
      }
      return this.unsafeStorage.get(fixUnsafePayload(options));
    });
  }

  keys(): Promise<{ keys: string[] }> {
    throw new Error("The keys method is not implemented!");
    //return this.mainStorage.keys();
  }

  remove(options: { key: string }): Promise<void> {
    return this.safeStorage
      .remove(fixSafePayload(options))
      .finally(() => this.unsafeStorage.remove(fixUnsafePayload(options)));
  }

  /**
   * Key must be unique! When using the same key in main storage and secondary storage then first data is checked in main storage!
   */
  set(options: { key: string; value: string }): Promise<void> {
    return this.safeStorage.set(fixSafePayload(options));
  }

  /**
   * Key must be unique! When using the same key in main storage and secondary storage then first data is checked in main storage!
   */
  setUnsafe(options: { key: string; value: string }): Promise<void> {
    return this.unsafeStorage.set(fixUnsafePayload(options));
  }
}

export const Storage: DataStorage = (() => {
  const LocalStorageDataStorage = require("./LocalStorageDataStorage")
    .LocalStorageDataStorage;
  const LocalStorage = new LocalStorageDataStorage();
  if (
    !process.env.REACT_APP_STORAGE_TYPE ||
    process.env.REACT_APP_STORAGE_TYPE !== "memory"
  ) {
    return new MixedStorageWrapper(LocalStorage, LocalStorage);
  } else {
    const MemoryDataStorage = require("./MemoryDataStorage").MemoryDataStorage;
    return new MixedStorageWrapper(new MemoryDataStorage(), LocalStorage);
  }
})();
