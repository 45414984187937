import { RouteComponentProps, useHistory } from "react-router-dom";
import { analyticsLogEvent } from "../Analytics";
import * as ROUTES from "../routes";
import ClanDesktopLayout from "../components/ClanDesktopLayout";
import ClanHeaderRays from "../components/ClanHeaderRays";
import { ClanIonContent } from "./DiscussionFeed/PublicFeeds";
import { IonButton, IonFooter, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClanLlamaMessage from "../components/ClanLlamaMessage";

type NotificationData = {
  [key: string]: string;
  lines: string;
  href: string;
  topic: string;
  button: string;
};

const GenericNotificationMessage: React.FC<RouteComponentProps> = ({
  location
}) => {
  const [messages, setMessages] = useState<string[]>([]);
  const notificationData = (location.state as {
    notificationData: NotificationData;
  })?.notificationData;
  // Example structure
  // const notificationData: NotificationData = {
  //   lines: "2",
  //   line1: "Whenever you are having a tough day - we are here",
  //   line2: "You can always cpme back to this from home page",
  //   button: "QUICK HELP",
  //   href: "http://www.clanbeat.com",
  //   topic: "Generic_message"
  // };
  const history = useHistory();

  const doCallAction = async () => {
    // noinspection ES6MissingAwait
    analyticsLogEvent("GENERIC_NOTIFICATION_ACTION_CLICKED");
  };

  const doCallSkip = async () => {
    // noinspection ES6MissingAwait
    analyticsLogEvent("GENERIC_NOTIFICATION_SKIP");
    history.replace(ROUTES.DASHBOARD);
  };

  useEffect(() => {
    if (!notificationData) return;
    const messagePragraphs: string[] = [];
    for (let i = 1; i <= Number(notificationData?.lines); i++) {
      messagePragraphs.push(notificationData[`line${i}`]);
    }
    setMessages(messagePragraphs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <ClanDesktopLayout>
        <IonPage className="flex justify-start">
          <ClanHeaderRays short />
          <ClanIonContent>
            <ClanLlamaMessage messages={messages} />
          </ClanIonContent>
          <IonFooter className="ion-no-border">
            <div
              className="h-clanBtn flex justify-center items-center text-center"
              onClick={doCallSkip}
            >
              <h6 className="cursor-pointer w-3/4 uppercase">
                {t("nudging.skip")}
              </h6>
            </div>
            <IonButton
              expand="block"
              className="my-4 mx-20 font-extrabold font-gilroy h-16 uppercase"
              style={{ "--border-radius": "100px" }}
              onClick={doCallAction}
              target="_black"
              href={notificationData?.href || "https://www.clanbeat.com"}
            >
              {notificationData?.button || "QUICK HELP"}
            </IonButton>
          </IonFooter>
        </IonPage>
      </ClanDesktopLayout>
    </>
  );
};

export default GenericNotificationMessage;
