import { IonLabel, IonNote } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import ClanFlowStepTemplate from "../../components/ClanFlowStepTemplate";
import ClanOnboardingListTemplate from "../../components/ClanOnboardingListTemplate";
import FooterButtons, {
  PrimaryFooterButton,
  SecondaryFooterButton
} from "../../components/FooterButtons";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import { updateUserProfile } from "../../data/user/profile/profile.actions";
import { UserProfileState } from "../../data/user/profile/profile.state";
import { BucketListAPI } from "../../util/api/ProfileService";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "./Onboarding";
import { fetchIgnoring404, setIgnoring404 } from "./OnboardingHelper";

interface OwnProps {
  setCurrentStep: any;
  isEditing: any;
}

interface DispatchProps {
  updateUserProfileConnect: typeof updateUserProfile;
  dispatchToast: typeof showToast;
}

interface StateProps {
  userProfileStateConnect: UserProfileState;
}

interface BucketListStepProps extends StateProps, DispatchProps, OwnProps {}

const BucketListStep: React.FC<BucketListStepProps> = ({
  dispatchToast,
  setCurrentStep,
  isEditing,
  updateUserProfileConnect
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const [bucketItems, setBucketItems] = useState<Array<string> | undefined>(
    undefined
  );
  const [userBucketList, setUserBucketList] = useState<
    Array<string> | undefined
  >(undefined);

  useEffect(() => {
    fetchIgnoring404(
      [
        [BucketListAPI.list, setBucketItems, []],
        [
          BucketListAPI.get,
          (result) =>
            setUserBucketList(
              result.filter(
                (item: any, index: any) => result.indexOf(item) === index
              )
            ),
          []
        ]
      ],
      setLoading,
      (error) => dispatchToast(`Couldn't get bucket list:" ${error}`)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveBucketList = async () => {
    if (!userBucketList) return;
    const response = await setIgnoring404(
      BucketListAPI.add,
      userBucketList,
      setLoading,
      dispatchToast
    );

    if (response) {
      console.debug("[ONBOARDING] Successfully saved bucket list.");
      isEditing ? history.goBack() : setCurrentStep(6);
      updateUserProfileConnect({ bucketList: response });
    }
  };

  return (
    <>
      <ClanIonContent>
        <OnboardingWrapper>
          <ClanFlowStepTemplate
            title={t("onboarding.bucketlist_header")}
            headerClassName="text-center"
            noContentMargin
          >
            <div className="text-center">
              {userBucketList && bucketItems ? (
                <>
                  <IonNote slot="start">
                    {t("onboarding.selected", {
                      number: userBucketList?.length
                    })}
                  </IonNote>
                  <ClanOnboardingListTemplate
                    callback={setUserBucketList}
                    existingItems={userBucketList}
                    listItems={bucketItems}
                    newItemModalTitle={t("onboarding.new_item")}
                  />
                </>
              ) : (
                <IonLabel>{t("onboarding.loading_2")}</IonLabel>
              )}
            </div>
          </ClanFlowStepTemplate>
        </OnboardingWrapper>
      </ClanIonContent>
      <FooterButtons>
        <SecondaryFooterButton
          disabled={isLoading}
          onClick={() => (isEditing ? history.goBack() : setCurrentStep(4))}
        >
          {isEditing ? t("onboarding.cancel") : t("general.back")}
        </SecondaryFooterButton>
        <PrimaryFooterButton onClick={saveBucketList} disabled={isLoading}>
          {isEditing ? t("onboarding.save") : t("onboarding.next")}
        </PrimaryFooterButton>
      </FooterButtons>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    userProfileStateConnect: state.userProfile
  }),
  mapDispatchToProps: {
    updateUserProfileConnect: updateUserProfile,
    dispatchToast: showToast
  },
  component: BucketListStep
});
