import { IonList, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as EmptyIcon } from "../../icons/empty.svg";
import { COMMUNITY_SLIDES } from "../../pages/People";
import { useCommunityProfileFilterPaginator } from "../../util/CustomHooks";
import { dateLocaleFormatter } from "../../util/DateUtils";
import { FilterGroupType } from "../../util/Filters";
import { ClanMembershipListItemResponse } from "../../util/models/ClanProfileModels";
import ClanLoader from "../ClanLoader";
import ClanProfileCard from "../ClanProfileCard";
import ClanProfileListItem from "../ClanProfileListItem";

type ClanBirthdayProps = {
  className?: string;
  filters?: FilterGroupType[];
  activeIndex: COMMUNITY_SLIDES;
};

const ClanBirthdays: React.FC<ClanBirthdayProps> = ({
  className,
  filters,
  activeIndex
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [birthdays, setBirthdays] = useState<ClanMembershipListItemResponse[]>(
    []
  );
  const [hasMore, setHasMore] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const birthdayApiPaginator = useCommunityProfileFilterPaginator(filters);
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (!birthdayApiPaginator) return;
    if (isFirstRun.current) {
      setLoading(true);
    }

    birthdayApiPaginator.setQueryArgumentNoFetch("query", searchText);
    birthdayApiPaginator
      .reset()
      .then(() => {
        birthdayApiPaginator
          .getNext()
          .then((response) => {
            setBirthdays(response);
          })
          .then(() => birthdayApiPaginator?.hasNext().then(setHasMore))
          .catch((e) => console.error("Failed to fetch birthdays", e));
      })
      .finally(() => {
        if (isFirstRun.current) {
          setLoading(false);
          isFirstRun.current = false;
        }
      });
  }, [birthdayApiPaginator, searchText]);

  async function loadNextPage() {
    if (!hasMore || !birthdayApiPaginator) return;
    setLoading(true);
    birthdayApiPaginator
      .getNext()
      .then((result) => setBirthdays([...birthdays, ...result]))
      .then(() => birthdayApiPaginator.hasNext().then(setHasMore))
      .finally(() => setLoading(false));
  }

  const formatBirthday = (person: ClanMembershipListItemResponse) => {
    if (!person.dateOfBirth) {
      return "";
    }
    return dateLocaleFormatter(person.dateOfBirth, "MMM d");
  };

  const { t } = useTranslation();

  return (
    <>
      <ClanLoader message={t("general.loading")} showLoading={loading} />
      <ClanProfileCard
        contentClassName={`px-3`}
        cardClassName={className}
        header={t("people_overview.my_people")}
        searchText={searchText}
        setSearchText={setSearchText}
      >
        <IonList className="py-0">
          {birthdays && birthdays.length > 0 ? (
            <>
              {birthdays.map(
                (person: ClanMembershipListItemResponse, itemIndex: number) => {
                  return (
                    <div key={`person_${person.profileId}_${itemIndex}`}>
                      <ClanProfileListItem
                        profile={person}
                        birthday={formatBirthday(person)}
                      />
                    </div>
                  );
                }
              )}
              <IonSegment>
                <IonSegmentButton
                  className="font-extrabold font-gilroy text-clanGreen-100"
                  disabled={!hasMore}
                  onClick={loadNextPage}
                >
                  {hasMore
                    ? t("general.list_load_more")
                    : t("general.list_end")}
                </IonSegmentButton>
              </IonSegment>
            </>
          ) : (
            <div className="mt-8 px-8">
              <EmptyIcon className="h-40" />
              <h4 className="my-4">{t("people_overview.class_empty")}</h4>
              <p className="mb-4">{t("people_overview.class_empty_message")}</p>
            </div>
          )}
        </IonList>
      </ClanProfileCard>
    </>
  );
};

export default ClanBirthdays;
