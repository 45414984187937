import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonSearchbar,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import React from "react";

interface OwnProps {
  header?: string;
  button?: { name: string; callback: any; primary: boolean };
  cardClassName?: string;
  contentClassName?: string;
  noFooter?: boolean;
  setSearchText?: (text: string) => void;
  searchText?: string;
}

type ClanProfileCardProps = OwnProps;
const ClanProfileCard: React.FC<ClanProfileCardProps> = ({
  header,
  button,
  cardClassName,
  contentClassName,
  noFooter,
  children,
  setSearchText,
  searchText
}) => {
  return (
    <>
      <IonCard
        className={`rounded-clanCard bg-white shadow-clan text-center w-11/12 flex flex-auto flex-col self-center ${cardClassName}`}
      >
        {header && (
          <IonCardHeader
            className={`flex items-center ${
              !setSearchText && "justify-center"
            }`}
          >
            <h4 className="m-4 whitespace-no-wrap">{header}</h4>
            {setSearchText && (
              <IonSearchbar
                value={searchText}
                className="p-0"
                mode="ios"
                placeholder="Search"
                onIonChange={(e) => {
                  setSearchText(e.detail.value || "");
                }}
              />
            )}
          </IonCardHeader>
        )}
        <IonCardContent
          className={`overflow-y-auto h-full ${contentClassName}`}
        >
          {children}
        </IonCardContent>
        {!noFooter && (
          <div className="min-h-clanFixedBottom w-full bottom-0">
            <IonSegment
              className={`w-full ${button?.primary ? "bg-clanYellow-100" : ""}`}
            >
              {typeof button !== "undefined" && (
                <IonSegmentButton
                  className="w-full font-extrabold font-gilroy text-clanGreen-100"
                  color={`${button.primary ? "primary" : "tertiary"}`}
                  onClick={button.callback}
                >
                  {button.name}
                </IonSegmentButton>
              )}
            </IonSegment>
          </div>
        )}
      </IonCard>
    </>
  );
};

export default ClanProfileCard;
