import { AxiosError, AxiosResponse } from "axios";
import {
  ApiRequest,
  ApiRequestError,
  ApiRequestErrorJSON,
  ApiResponse,
  ApiResponseErrorType
} from "../types/ApiRequestError.d";

const wrapResponse = (
  response?: ApiResponse | AxiosResponse
): ApiResponse | undefined => {
  if (!response) return undefined;
  const status =
    response?.status !== null && response?.status !== undefined
      ? response?.status
      : -1;
  const statusText = response?.statusText;

  return {
    url: (response as AxiosResponse)?.config?.url
      ? (response as AxiosResponse)?.config?.url
      : (response as ApiResponse)?.url,
    data: response?.data,
    status,
    statusText,
    headers: response?.headers,
    request: response?.request
  } as ApiResponse;
};

const getApiResponse = (
  error: AxiosError,
  response?: ApiResponse | AxiosResponse
): ApiResponse | undefined => {
  if (response) {
    return wrapResponse(response);
  }
  if (error.isAxiosError) {
    return wrapResponse(error.response);
  }
  return undefined;
};

const resolveErrorTypeByStatus = (
  status?: number
): ApiResponseErrorType | undefined => {
  if (status === null || status === undefined) {
    return undefined;
  }
  switch (status) {
    case 400:
      return ApiResponseErrorType.HTTP_BAD_REQUEST;
    case 401:
      return ApiResponseErrorType.HTTP_UNAUTHORIZED;
    case 402:
      return ApiResponseErrorType.HTTP_PAYMENT_REQUIRED;
    case 403:
      return ApiResponseErrorType.HTTP_FORBIDDEN;
    case 404:
      return ApiResponseErrorType.HTTP_NOT_FOUND;
    case 412:
      return ApiResponseErrorType.HTTP_PRECONDITION_FAILED;
    case 428:
      return ApiResponseErrorType.HTTP_PRECONDITION_REQUIRED;
    case 429:
      return ApiResponseErrorType.HTTP_TOO_MANY_REQUESTS;
    case 500:
      return ApiResponseErrorType.HTTP_SERVER_ERROR;
    case 503:
      return ApiResponseErrorType.HTTP_SERVICE_UNAVAILABLE;
    default:
      return undefined;
  }
};

const resolveErrorType = (error: AxiosError): ApiResponseErrorType => {
  const status = resolveErrorTypeByStatus(error.response?.status);
  if (status) {
    return status;
  }

  const defaultResult = error?.isAxiosError
    ? ApiResponseErrorType.HTTP_GENERIC
    : ApiResponseErrorType.GENERIC;

  if (!error.message) return defaultResult;

  if (error.message === "Request aborted") {
    return ApiResponseErrorType.HTTP_REQUEST_ABORTED;
  }
  if (error.message === "Network Error") {
    return ApiResponseErrorType.HTTP_NETWORK_ERROR;
  }
  if (error.message.toLowerCase().startsWith("timeout of ")) {
    return ApiResponseErrorType.HTTP_PARSE_TIMEOUT;
  }
  if (error.message.toLowerCase().startsWith("Data after transformation")) {
    return ApiResponseErrorType.HTTP_TRANSFORMATION;
  }
  if (error.message.toLowerCase().startsWith("maxContentLength size of")) {
    return ApiResponseErrorType.HTTP_MAX_CONTENT_EXCEEDED;
  }
  return defaultResult;
};

export class ApiRequestErrorImpl extends Error implements ApiRequestError {
  readonly response?: ApiResponse;
  readonly type: ApiResponseErrorType;
  readonly cause: Error;
  readonly isApiRequestError: boolean = true;
  readonly request?: ApiRequest;
  toJSON: () => ApiRequestErrorJSON;

  constructor(args: {
    error: Error | AxiosError | DOMException | string;
    response?: ApiResponse | AxiosResponse;
    status?: number;
  }) {
    super(
      typeof args.error === "string"
        ? args.error.toString()
        : args.error.message
    );
    this.name = "ApiRequestError";
    this.response = getApiResponse(args.error as AxiosError, args.response);

    // see example at https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    const typeByStatus = resolveErrorTypeByStatus(args.status);
    Object.setPrototypeOf(this, ApiRequestErrorImpl.prototype);
    if (typeof args.error === "string") {
      this.cause = new Error(args.error.toString());
      this.type = typeByStatus ? typeByStatus : ApiResponseErrorType.GENERIC;
    } else if (args.error instanceof DOMException) {
      this.cause = args.error as Error;
      this.type = ApiResponseErrorType.DOM_ERROR;
    } else {
      this.cause = args.error as Error;
      const axiosError = args.error as AxiosError;
      this.type = typeByStatus ? typeByStatus : resolveErrorType(axiosError);
    }
    this.toJSON = () => {
      return {
        type: this.type,
        message: this.message,
        name: this.name,
        cause: this.cause.toString(),
        status: this.response?.status,
        statusText: this.response?.statusText,
        stack: this.cause.stack,
        request: this.request
      } as ApiRequestErrorJSON;
    };
  }
}

export const errorToApiRequestError = (
  error: Error | DOMException | string
): ApiRequestError => {
  if ((error as ApiRequestError).isApiRequestError) {
    return error as ApiRequestError;
  }
  if (typeof error === "string") {
    return new ApiRequestErrorImpl({ error });
  }
  if ((error as AxiosError).isAxiosError) {
    return new ApiRequestErrorImpl({
      error,
      response: (error as AxiosError).response
    });
  }
  return new ApiRequestErrorImpl({ error });
};
