import { IonBackdrop, IonHeader, IonModal, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { connect } from "../../../data/connect";
import { showQuickHelpModal } from "../../../data/modals/modals.actions";
import { ModalID } from "../../../data/modals/modals.state";
import { ClanIonContent } from "../../../pages/DiscussionFeed/PublicFeeds";
import { UserEnabledProperties } from "../../../util/api/UserEnabledPropertiesService";
import {
  getConstantSharingFilters,
  Views,
  ViewSharings
} from "../../../util/Configuration";
import { FeedTypes, findViewFilterByFeedType } from "../../../util/Feeds";
import {
  ClanEditPostEditDetails,
  ClanPostResultDetailsV3
} from "../../../util/models/ClanPostModels";
import { isValidMood } from "../../../util/MoodChoice";
import ClanDiscussionFooterInput from "../../ClanDiscussionFooterInput";
import ClanHeaderRays from "../../ClanHeaderRays";
import FooterButtons, {
  PrimaryFooterButton,
  SecondaryFooterButton
} from "../../FooterButtons";
import ClanSharePost from "./ClanSharePost";

interface StateProps {
  isStudent: boolean;
}

interface DispatchProps {
  dispatchQuickHelpModal: typeof showQuickHelpModal;
}

const DiscussionBottomModal = styled(IonModal)<{ transparent: boolean }>`
  ${({ transparent, theme }) =>
    transparent &&
    `
  --background: transparent;
  --overflow: visible;
  --height: auto;
  align-items: flex-end;

  .ion-page {
    overflow: visible;
    contain: initial;
    position: relative;
    background: ${theme.primary};
  }`}
`;

const StyledHeader = styled(IonHeader)`
  height: 7rem;
  padding: 1rem;
  padding-button: 2rem;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-content: center;
`;

interface ClanEditPostProps {
  hasParentPost?: boolean;
  feedType: FeedTypes;
  defaultPageTitle: string;
  post?: ClanPostResultDetailsV3;
  requireShare: boolean;
  onPost: (postResult: ClanEditPostEditDetails) => void;
  onCancel: () => void;
  isOpen: boolean;
  onDidDismiss: () => void;
  removeLocalImage?: (postId: string) => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
}

const ClanEditPost: React.FC<
  ClanEditPostProps & DispatchProps & StateProps
> = ({
  hasParentPost,
  feedType,
  defaultPageTitle,
  post,
  requireShare,
  isOpen,
  loading,
  isStudent,
  onPost,
  onCancel,
  removeLocalImage,
  onDidDismiss,
  setLoading,
  dispatchQuickHelpModal
}) => {
  const [showShare, setShowShare] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>();
  const [text, setText] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [mood, setMood] = useState<string>();
  const [image, setImage] = useState<string>();
  const [pageTitle, setPageTitle] = useState<string>();
  const [shareGroups, setShareGroups] = useState([] as Array<number>);
  const [shareProfiles, setShareProfiles] = useState([] as Array<number>);
  const [shareRoles, setShareRoles] = useState([] as Array<string>);
  const [openPostBar, setOpenPostBar] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setShowEdit(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setTitle(post?.title || undefined);
    setText(post?.text || undefined);
    setMood(post?.mood && isValidMood(post?.mood) ? post?.mood : undefined);
    // setImage(post?.image?.thumbnail?.url || undefined);

    const { sharedGroups = [], sharedProfiles = [], sharingShortcut = "" } =
      post?.sharing || {};

    setShareGroups((sharedGroups || []).map(({ id }) => id));
    setShareProfiles((sharedProfiles || []).map(({ id }) => id));
    if (sharingShortcut) setShareRoles([sharingShortcut]);
  }, [post]);

  useEffect(() => {
    setPageTitle(defaultPageTitle);
  }, [defaultPageTitle]);

  useEffect(() => {
    if (!showEdit) {
      return;
    }
    setShowShare(false);
  }, [showEdit, post, defaultPageTitle]);

  useEffect(() => {
    if (!showShare) {
      setPageTitle(defaultPageTitle);
      return;
    }
    setPageTitle(t("clan_edit_post.share_with"));
    setShowEdit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showShare]);

  function buildPostSubtitle() {
    if (showEdit) {
      switch (feedType) {
        case FeedTypes.PERSONAL:
        case FeedTypes.SHARED_REFLECTIONS:
          return hasParentPost
            ? t("clan_edit_post.reflection_reply_placeholder")
            : t("clan_edit_post.reflection_placeholder");
        case FeedTypes.ANNOUNCEMENT_FEED:
          return hasParentPost
            ? t("clan_edit_post.announcement_reply_placeholder")
            : t("clan_edit_post.post_placeholder");
        case FeedTypes.QUESTION:
          return hasParentPost
            ? t("clan_edit_post.question_reply_placeholder")
            : t("clan_edit_post.question_placeholder");
        default:
          return hasParentPost
            ? t("clan_edit_post.post_reply_placeholder")
            : t("clan_edit_post.post_placeholder");
      }
    }
  }

  const resolveSharedShortcuts = async (): Promise<ViewSharings[] | null> => {
    if (hasParentPost) return null;
    if (feedType !== FeedTypes.ANNOUNCEMENT_FEED) {
      return shareRoles as ViewSharings[];
    }
    const constantFilters = await getConstantSharingFilters(Views.Announcement);
    if (constantFilters.filters.length === 0) {
      throw new Error("Filters for announcement feed not found!");
    }
    const defaultFilter = constantFilters.filters.find((e) => e.isDefault)
      ?.filter;
    if (!defaultFilter) {
      throw new Error("Default filter for announcement feed not found!");
    }
    return [defaultFilter];
  };

  const doPost = async () => {
    const sharedShortcuts = (await resolveSharedShortcuts()) as string[];

    await onPost({
      id: post?.id,
      title,
      text,
      image,
      mood,
      sharedGroups: shareGroups,
      sharedProfiles: shareProfiles,
      sharedShortcuts
    });

    if (
      !post?.id &&
      isStudent &&
      mood &&
      ["Worried", "Anxious", "Scared"].includes(mood)
    ) {
      dispatchQuickHelpModal(ModalID.QuickHelpTask, [
        t("quick_help_modal.p1"),
        t("quick_help_modal.p2")
      ]);
    }
  };

  const resetState = () => {
    setText(undefined);
    setMood(undefined);
    setImage(undefined);
    setTitle(undefined);
    setImage(undefined);
    setShareGroups([]);
    setShareProfiles([]);
    setShareRoles([]);
  };

  const onSubmitPost = () => {
    setLoading(true);
    doPost()
      .then(() => {
        resetState();
      })
      .finally(() => {
        onCancel();
        setLoading(false);
        setOpenPostBar(false);
      });
  };

  // TODO: Example for using llama modal. Remove when not needed anymore
  // const dispatchAnnouncementNotification = () => {
  //   dispatchLlamaModal(ModalID.LlamaInfo, {
  //     paragraphs: [
  //       "A reply to an annoucement is visible to all members in your school account.",
  //       "There are no sharing settings once you reply and all members can see your post."
  //     ],
  //     actions: [
  //       {
  //         label: "BACK",
  //         action: () => {
  //           dispathRemoveModal(ModalID.LlamaInfo);
  //           resetState();
  //         },
  //         type: ModalCallbackType.Close
  //       },
  //       {
  //         label: "POST",
  //         action: () => {
  //           doPost().finally(() => {
  //             dispathRemoveModal(ModalID.LlamaInfo);
  //             onCancel();
  //             resetState();
  //           });
  //         },
  //         type: ModalCallbackType.Primary
  //       }
  //     ]
  //   });
  // };

  const onPostDetailSubmit = () => {
    if (requireShare) {
      setShowShare(true);
    } else onSubmitPost();
  };

  return (
    <>
      <DiscussionBottomModal
        isOpen={isOpen}
        onDidDismiss={onDidDismiss}
        transparent={!showShare}
      >
        {openPostBar && (
          <IonBackdrop
            onFocus={() => {
              setOpenPostBar(false);
            }}
            visible={true}
            className="opacity-50"
          />
        )}
        {showShare && (
          <ClanIonContent
            onClick={() => {
              if (!showShare) {
                onCancel();
                onDidDismiss();
              }
            }}
          >
            <ClanHeaderRays short />
            <StyledHeader mode="ios">
              <IonText>
                <h3 className="my-1">{pageTitle}</h3>
              </IonText>
            </StyledHeader>

            <ClanSharePost
              view={findViewFilterByFeedType(feedType) || Views.DiscussionFeed}
              groups={shareGroups}
              profiles={shareProfiles}
              roles={shareRoles}
              setRoles={setShareRoles}
              setGroups={setShareGroups}
              setProfiles={setShareProfiles}
            />
          </ClanIonContent>
        )}

        {showEdit && (
          <ClanDiscussionFooterInput
            onSubmit={onPostDetailSubmit}
            removeLocalImage={removeLocalImage}
            text={text}
            onChangeText={setText}
            title={title}
            onChangeTitle={setTitle}
            image={image}
            onChangeImage={setImage}
            mood={mood}
            onChangeMood={setMood}
            questionMode={feedType === FeedTypes.QUESTION}
            {...(feedType === FeedTypes.QUESTION && { ctaLabel: "ADD" })}
            rows={7}
            postId={post?.id}
            placeholder={buildPostSubtitle() || ""}
            {...(feedType === FeedTypes.ANNOUNCEMENT_FEED && { openPostBar })}
            {...(feedType === FeedTypes.ANNOUNCEMENT_FEED && {
              setOpenPostBar
            })}
          />
        )}

        {showShare && (
          <FooterButtons>
            <SecondaryFooterButton onClick={() => setShowEdit(true)}>
              {t("general.back")}
            </SecondaryFooterButton>
            <PrimaryFooterButton
              onClick={onSubmitPost}
              disabled={
                loading ||
                (shareGroups.length === 0 &&
                  shareProfiles.length === 0 &&
                  shareRoles.length === 0)
              }
            >
              {t("clan_edit_post.post")}
            </PrimaryFooterButton>
          </FooterButtons>
        )}
      </DiscussionBottomModal>
    </>
  );
};

export default connect<ClanEditPostProps, StateProps, DispatchProps>({
  mapStateToProps: ({ token }) => ({
    isStudent: !UserEnabledProperties.Legacy.isAdult(token)
  }),
  mapDispatchToProps: {
    dispatchQuickHelpModal: showQuickHelpModal
  },
  component: ClanEditPost
});
