import { History } from "history";
import React, { useEffect, useState } from "react";

import {
  ApiResponseErrorType,
  initByRefreshToken
} from "../../util/ApiService";
import { setAppError } from "../app/app.actions";
import { signOutUser } from "../oauth/InitOauthState";
import { getOauthTokenActionPayload } from "../oauth/oauth.actions";
import { OauthState } from "../oauth/oauth.state";
import { OauthData } from "../oauth/oauthDataService";

export interface SignInStatus {
  finished: boolean;
  isSignedIn: boolean;
}

export const refreshTokenOnLoad = async (
  history: History,
  dispatch: React.Dispatch<any>
): Promise<boolean> => {
  try {
    const oauthResult = await initByRefreshToken();
    await dispatch(getOauthTokenActionPayload(oauthResult));
    // TODO: when deleted account from back end then the flow will fail!
    return true;
  } catch (error) {
    if (error.type === ApiResponseErrorType.HTTP_UNAUTHORIZED) {
      await signOutUser(history);
    } else {
      await setAppError(error)(dispatch);
      return false;
    }
    return true;
  }
};

export const useUserIdChangeState = (
  oauthSate: OauthState | OauthData
): number | null | undefined => {
  const [userId, setUserId] = useState<number | null | undefined>(undefined);
  useEffect(() => {
    if (!oauthSate.hasToken) {
      setUserId(null);
    }
    const oauthData = oauthSate as OauthData;
    if (oauthData.userId !== userId) {
      setUserId(oauthData.userId !== undefined ? oauthData.userId : null);
    }
  }, [oauthSate, userId]);
  return userId;
};
