const regexp = /(http|https)?:?(\/\/)?(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/;

const resolveVimeoHash = (url: string): string | undefined => {
  regexp.lastIndex = 0;
  const result = regexp.exec(url?.trim());
  if (!result) return undefined;
  return result[result.length - 1];
};

export const resolveVimeoLink = (url: string): Promise<string | undefined> => {
  const hash = resolveVimeoHash(url);
  if (hash === undefined) return Promise.resolve(undefined);
  return Promise.resolve(`https://player.vimeo.com/video/${hash}`);
};
