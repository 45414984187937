import axios from "axios";
import { ApiServiceRequestWithMethod } from "../types/ApiServiceRequest.d";
import { ApiServiceQueryWrapper } from "../types/ApiServiceQueryWrapper.d";
import { rawAxiosRequest } from "./AxiosRequestService";

export const getAxiosWrapper = (
  baseURL: string | undefined | null = null
): ApiServiceQueryWrapper => {
  const serverApi = axios.create({
    baseURL: baseURL ? baseURL : process.env.REACT_APP_API_ENDPOINT
  });

  return {
    request: <T>(request: ApiServiceRequestWithMethod) =>
      rawAxiosRequest<T>(serverApi, request)
  } as ApiServiceQueryWrapper;
};
