import { isToday, format, formatDistance } from "date-fns";
import { et, ru } from "date-fns/locale";
import i18n from "../i18n";

export const dateZeroPrefix = (value: number): string =>
  `${value < 10 ? "0" : ""}${value}`;

export type DateStructure = {
  day: string;
  month: string;
  year: string;
  weekDay: string;
};

enum DAYS {
  Sun,
  Mon,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat
}

export enum MONTHS {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December
}

export const dateToDateStructure = (date: Date): DateStructure => {
  return {
    day: dateZeroPrefix(date.getDate()),
    month: dateZeroPrefix(date.getMonth() + 1),
    year: `${date.getFullYear()}`,
    weekDay: DAYS[date.getDay()]
  };
};

export const dateToDateString = (date: Date): string =>
  `${date.getFullYear()}-${dateZeroPrefix(
    date.getMonth() + 1
  )}-${dateZeroPrefix(date.getDate())}`;

export const dateLocaleFormatter = (
  date: string,
  dateformat: string
): string => {
  switch (i18n.language) {
    case "et":
      return format(
        new Date(date),
        dateformat.replace(/\b(M+)\s(d)\b/, "$2o $1"),
        {
          locale: et
        }
      );
    case "ru":
      return format(new Date(date), dateformat, { locale: ru });
    default:
      return format(new Date(date), dateformat);
  }
};

export const timeAgoFormatter = (
  time: Date,
  currentDate: Date
): string | undefined => {
  switch (i18n.language) {
    case "et":
      return formatDistance(time, currentDate, {
        addSuffix: false,
        locale: et
      });
    case "ru":
      return formatDistance(time, currentDate, {
        addSuffix: false,
        locale: ru
      });
    default:
      return formatDistance(time, currentDate, {
        addSuffix: false
      });
  }
};

export const formatShortTimeDistance = (date: Date, t: any) => {
  const secondsDiff = (new Date().getTime() - date.getTime()) / 1000;

  if (secondsDiff < 60) {
    return t("time_ago.seconds", {
      number: Math.max(Math.floor(secondsDiff), 1)
    });
  } else if (secondsDiff < 3600) {
    return t("time_ago.minutes", { number: Math.floor(secondsDiff / 60) });
  } else if (secondsDiff < 7200) {
    return t("time_ago.hour", { number: 1 });
  } else if (secondsDiff < 86400) {
    return t("time_ago.hours", { number: Math.floor(secondsDiff / 3600) });
  }

  switch (i18n.language) {
    case "et":
      return format(date, "d MMM", { locale: et });
    case "ru":
      return format(date, "d MMM", { locale: ru });
    default:
      return format(date, "d MMM");
  }
};

export const formatMessageDate = (date: Date) => {
  if (isToday(date)) return format(date, "HH:mm");

  switch (i18n.language) {
    case "et":
      return format(date, "d MMM", { locale: et });
    case "ru":
      return format(date, "d MMM", { locale: ru });
    default:
      return format(date, "d MMM");
  }
};

export const formatInLocale = (date: Date, formatString: string) => {
  switch (i18n.language) {
    case "et":
      return format(date, formatString, { locale: et });
    case "ru":
      return format(date, formatString, { locale: ru });
    default:
      return format(date, formatString);
  }
};
