import { useState } from "react";
import { useAsyncEffect } from "@react-hook/async";
import { resolveHtmlContent } from "./HtmlContentParser";

export const useHtmlContentResolver = (
  html: string | null | undefined
): string | null => {
  const [htmlResult, setHtmlResult] = useState<string | null>(
    html === null || html === undefined ? null : html
  );
  useAsyncEffect(async () => {
    if (html === null || html === undefined) return;
    const result = await resolveHtmlContent(html);
    if (result) {
      setHtmlResult(result);
    }
  }, [html]);
  return htmlResult;
};
