import { useAsync } from "@react-hook/async";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { setAnalyticsScreenName } from "../../Analytics";
import { isReadyStateLoading } from "../../util/AsyncEffectFilter";
import {
  addCommentToPost,
  addPersonalPost,
  addQuestion,
  addReflectionToTask,
  getPostById
} from "../../util/ClanPostAPIService";
import {
  getConstantSharingFilters,
  Views,
  ViewSharings
} from "../../util/Configuration";
import {
  FeedTypes,
  findViewFilterByFeedType,
  savePost,
  updatePost
} from "../../util/Feeds";
import { ClanEditPostEditDetails } from "../../util/models/ClanPostModels";
import { getPostPlaceholder, getSharingLabel } from "../../util/PostUtils";
import PostModal from "./PostModal";
import ShareModal from "./ShareModal";

interface SharePostModalProps {
  postId?: string;
  reflectionTaskId?: string;
  parentPostId?: string;
  feedType: FeedTypes;
  onClose: () => void;
  onPost: (post: ClanEditPostEditDetails) => void;
}

const SharePostModal: React.FC<SharePostModalProps> = ({
  postId,
  reflectionTaskId,
  parentPostId,
  feedType,
  onClose,
  onPost
}) => {
  const [post, setPost] = useState<ClanEditPostEditDetails>({});
  const [sharedProfiles, setSharedProfiles] = useState<Array<number>>([]);
  const [sharedGroups, setSharedGroups] = useState<Array<number>>([]);
  const [sharedRoles, setSharedRoles] = useState<Array<string>>([]);
  const [hasExistingImage, setHasExistingImage] = useState(false);

  const [shareModalOpen, setShareModalOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setAnalyticsScreenName("POST_EDIT");
    (async () => {
      if (postId) {
        const result = await getPostById(postId);
        const { text, mood, sharing, image } = result || {};
        const { sharedGroups: sG, sharedProfiles: sP, sharingShortcut: sS } =
          sharing || {};

        setHasExistingImage(!!image);

        setPost({
          text,
          mood
        } as ClanEditPostEditDetails);

        setSharedGroups((sG || []).map((group) => group.id));
        setSharedProfiles((sP || []).map((profile) => profile.id));
        setSharedRoles(sS ? [sS] : []);
      }
    })();
  }, [postId]);

  const resolveSharedShortcuts = async (): Promise<ViewSharings[] | null> => {
    if (parentPostId) return null;
    if (feedType !== FeedTypes.ANNOUNCEMENT_FEED) {
      return sharedRoles as ViewSharings[];
    }

    const constantFilters = await getConstantSharingFilters(Views.Announcement);
    if (constantFilters.filters.length === 0) {
      throw new Error("Filters for announcement feed not found!");
    }
    const defaultFilter = constantFilters.filters.find((e) => e.isDefault)
      ?.filter;
    if (!defaultFilter) {
      throw new Error("Default filter for announcement feed not found!");
    }
    return [defaultFilter];
  };

  const [submitStatus, submitPost] = useAsync(async () => {
    const sharedShortcuts = (await resolveSharedShortcuts()) as string[];

    try {
      const postToSubmit = {
        ...post,
        sharedGroups,
        sharedProfiles,
        sharedShortcuts
      };
      let result = null;
      let newPostResult = null;
      if (!postId && reflectionTaskId) {
        result = await addReflectionToTask(reflectionTaskId, postToSubmit);
      } else if (!postId && parentPostId) {
        result = await addCommentToPost(parentPostId, postToSubmit);
      }

      const view =
        feedType === FeedTypes.PERSONAL
          ? Views.ReflectionFeed
          : findViewFilterByFeedType(feedType);
      if (!view) {
        throw new Error("View for feed type " + feedType + " not found!");
      }

      if (result === null) {
        if (postId) {
          result = await updatePost(view, postId, postToSubmit);
        } else if (feedType === FeedTypes.PERSONAL) {
          result = await addPersonalPost(postToSubmit);
        } else if (feedType === FeedTypes.QUESTION) {
          const { text, ...questionToPost } = postToSubmit;
          result = await addQuestion({
            ...questionToPost,
            title: text
          });
        } else {
          newPostResult = await savePost(view, postToSubmit);
        }
      }

      onPost({
        id: newPostResult?.id,
        ...result,
        image: postToSubmit.image
      } as ClanEditPostEditDetails);
    } catch (e) {
      console.error(
        "[DISCUSSION_EDIT_POST] Couldn't save or edit the new post",
        e
      );
    }
  });

  const onPostChange = (updatedPost: ClanEditPostEditDetails) => {
    setPost({ ...post, ...updatedPost } as ClanEditPostEditDetails);
  };

  const onProfilesChange = (newProfiles: Array<number>) =>
    setSharedProfiles(newProfiles);

  const onGroupsChange = (newGroups: Array<number>) =>
    setSharedGroups(newGroups);

  const onRolesChange = (newRoles: Array<string>) => setSharedRoles(newRoles);

  const onShareClicked = () => setShareModalOpen(true);

  const shareLabel = () => {
    if (
      sharedProfiles.length > 0 ||
      sharedGroups.length > 0 ||
      sharedRoles.length > 0
    ) {
      return getSharingLabel(sharedProfiles, sharedGroups, sharedRoles);
    } else {
      return t("clan_share_post.select");
    }
  };

  const shareRequired = ![
    FeedTypes.PERSONAL,
    FeedTypes.ANNOUNCEMENT_FEED
  ].includes(feedType);

  const isDisabled =
    isReadyStateLoading(submitStatus) ||
    (!post.text && !post.mood && !post.image && !hasExistingImage) ||
    (feedType === FeedTypes.QUESTION && !post.text) ||
    (shareRequired &&
      sharedProfiles.length <= 0 &&
      sharedGroups.length <= 0 &&
      sharedRoles.length <= 0);

  return (
    <>
      <PostModal
        disabled={isDisabled}
        post={post}
        shareLabel={shareLabel()}
        submitLabel={
          feedType === FeedTypes.PERSONAL
            ? t("general.save")
            : t("general.share")
        }
        textPlaceholder={getPostPlaceholder(!!parentPostId, feedType)}
        onShareClicked={shareRequired ? onShareClicked : undefined}
        onPostChange={onPostChange}
        onSubmit={submitPost}
        onClose={onClose}
      />

      {shareModalOpen && (
        <ShareModal
          view={findViewFilterByFeedType(feedType) || Views.DiscussionFeed}
          groups={sharedGroups}
          profiles={sharedProfiles}
          roles={sharedRoles}
          onGroupsChange={onGroupsChange}
          onProfilesChange={onProfilesChange}
          onRolesChange={onRolesChange}
          onClose={() => setShareModalOpen(false)}
          onSubmit={() => setShareModalOpen(false)}
        />
      )}
    </>
  );
};

export default SharePostModal;
