import { IonIcon, IonPage } from "@ionic/react";
import { useAsyncEffect } from "@react-hook/async";
import { FirebaseAuthentication } from "@robingenz/capacitor-firebase-authentication";
import { RecaptchaVerifier } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useLocation } from "react-router-dom";
import styled from "styled-components";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import ClanLoader from "../../components/ClanLoader";
import { connect } from "../../data/connect";
import { OauthState } from "../../data/oauth/oauth.state";
import { showToast } from "../../data/toasts/toasts.actions";
import i18n from "../../i18n";
import * as ROUTES from "../../routes";
import {
  authenticateWithFirebaseUser,
  testIfUserExistsWithFirebaseUser
} from "../../util/api/Authentication";
import { useQuery } from "../../util/CustomHooks";
import {
  getFirebaseIdToken,
  getRecaptchaVerifier
} from "../../util/GoogleFirebase";
import useSearchParams from "../../util/hooks/useSearchParams";
import { desktopOnlyStyles } from "../../util/ResponsiveUtils";
import RollbarManager from "../../util/RollbarManager";
import { SignupSteps } from "../Signup/Signup";
import Intro from "./Intro";
import Methods from "./Methods";
import RoleSelection from "./RoleSelection";
import School from "./School";
import SmsPin from "./SMSPin";
import {
  checkSignupData,
  CountryInformation,
  firebaseValidate,
  getFirebaseToken,
  getIpCountryInfo
} from "./StartHelper";
import StudentJoinCode from "./StudentJoinCode";
import Success from "./Success";

declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance;
    captchaOnLoad: () => void;
    recaptchaVerifier: RecaptchaVerifier;
  }
}

export const StartWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StartContentWrapper = styled.div<{ center?: boolean }>`
  background: ${({ theme }) => theme.primary};
  padding: 0 5%;
  flex: 1;
  padding-bottom: 40px;

  ${({ center }) =>
    center &&
    `
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
      &:first-child {
        align-self: initial;
      }
    }
    `}

  ${({ center }) =>
    !center &&
    `
    display: grid;
    grid-template-rows: 1fr auto;
    `}
`;

export const StartToolbar = styled.div`
  background: ${({ theme }) => theme.primary};
  width: 100%;

  ion-img {
    ${desktopOnlyStyles({
      display: "none"
    })}
  }
`;

export const BackButton = styled(IonIcon)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 1rem;
  position: relative;
  left: 5%;
  margin-left: -1rem;
  margin-top: 1rem;
  cursor: pointer;
`;

export const StartTitleWrapper = styled.section<{ left?: boolean }>`
  ${({ left }) =>
    !left &&
    `
    align-self: center;
    `}
`;

export const StartTitle = styled.h1<{ small?: boolean }>`
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  margin: 0;
  width: 80%;

  ${({ small }) =>
    small
      ? "font-size: 24px; line-height: 28px;"
      : "font-size: 45px; line-height: 45px;"}

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: white;
    margin: 10px 0;
  }
`;

export const StartDescription = styled.div`
  margin: 0;
  max-width: 70%;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
`;

interface ReCaptchaInstance {
  reset: (id: number) => any;
  render: (id: string, options: ReCaptchaRenderOptions) => any;
}

interface ReCaptchaRenderOptions {
  sitekey: string;
}

export enum StartSteps {
  INTRO = "intro",
  ROLESELECT = "roleselect",
  METHODS = "methods",
  SMSPIN = "smspin",
  SCHOOL = "school",
  STUDENTJOINCODE = "student-joincode",
  SUCCESS = "success"
}

export enum MethodTypes {
  LOGIN = "login",
  TEACHER = "teacher",
  STUDENT = "student"
}

type OwnProps = RouteComponentProps;

type StartFormData = {
  email?: string;
  number?: string;
  countryCode?: string;
  inviteHash?: string;
  otp?: string;
  schoolName?: string;
  schoolCountry?: string;
};

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface StateProps {
  clanbeatTokenConnect: OauthState;
}

interface StartProps extends DispatchProps, OwnProps, StateProps {}

export const LocationContext = React.createContext({} as CountryInformation);

const Start: React.FC<StartProps> = ({
  history,
  dispatchToast,
  clanbeatTokenConnect
}: StartProps) => {
  const [
    currentCountryInfo,
    setCurrentCountryInfo
  ] = useState<CountryInformation>({} as CountryInformation);

  const { lang } = useQuery();

  const { joinGroup, code } = useSearchParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    const fetchIPCountryInfo = async () => {
      const response = await getIpCountryInfo();
      setCurrentCountryInfo(response);
    };

    fetchIPCountryInfo();
  }, []);

  const methods = useForm<StartFormData>({
    defaultValues: {
      countryCode: process.env.REACT_APP_CONFIG_DEFAULT_COUNTRY_CODE,
      inviteHash: (code && code.length) <= 8 ? code : ""
    },
    shouldUnregister: false
  });

  const location = useLocation();

  const { initialStep } = (location.state || {}) as {
    initialStep?: StartSteps;
  };

  const [currentStep, setCurrentStep] = useState<StartSteps>(
    (!!joinGroup && StartSteps.METHODS) || initialStep || StartSteps.INTRO
  );
  const [mode, setMode] = useState<MethodTypes>(
    (!!joinGroup && MethodTypes.STUDENT) || MethodTypes.LOGIN
  );

  const [loading, setLoading] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [appVerifier, setAppVerifier] = useState<RecaptchaVerifier>();
  const [signupData, setSignupData] = useState<object>();

  useEffect(() => {
    FirebaseAuthentication.addListener("authStateChange", async ({ user }) => {
      if (user) {
        const { token } = await FirebaseAuthentication.getIdToken();
        const authResult = await authenticateWithFirebaseUser(
          history,
          { idToken: token },
          mode === MethodTypes.LOGIN
        );

        if (!authResult && mode !== MethodTypes.LOGIN) {
          onUserSignupRequired(mode);
        }
      }
    });

    return () => {
      FirebaseAuthentication.removeAllListeners();
    };
  }, [history, mode]);

  useEffect(() => {
    if (initialStep) {
      setCurrentStep(initialStep);
    }
  }, [initialStep]);

  const resetStartState = async () => {
    console.debug("[START_PAGE] Resetting local state for a new login");
    setCurrentStep(StartSteps.INTRO);
    setMode(MethodTypes.LOGIN);
    setVerificationId("");
    setPhoneNumber("");
    window.recaptchaVerifier?.render().then((widgetId) => {
      window.grecaptcha.reset(widgetId);
      setAppVerifier(undefined);
    });
    setSignupData(undefined);
  };

  const { t } = useTranslation();

  useEffect(() => {
    // Running in web for development
    if (appVerifier) return;
    const result = getRecaptchaVerifier();
    if (result) {
      setAppVerifier(result);
    }
  }, [appVerifier]);

  const onUserSignupRequired = (selectedMode: MethodTypes) => {
    if (MethodTypes.TEACHER === selectedMode) {
      setCurrentStep(StartSteps.SCHOOL);
      return StartSteps.SCHOOL;
    }
    if (MethodTypes.STUDENT === selectedMode) {
      setCurrentStep(StartSteps.STUDENTJOINCODE);
    }
  };

  const firebaseUserStepState = useAsyncEffect<
    MethodTypes | undefined
  >(async () => {
    await setAnalyticsScreenName(`START_${currentStep}`);
    if (clanbeatTokenConnect.hasToken) {
      return;
    }
    const firebaseToken = await getFirebaseIdToken();
    if (!firebaseToken) return;
    if (
      currentStep !== StartSteps.STUDENTJOINCODE &&
      ![MethodTypes.TEACHER, MethodTypes.STUDENT].includes(mode)
    ) {
      return;
    }
    await setLoading(true);
    try {
      const response = await testIfUserExistsWithFirebaseUser(firebaseToken);
      if (!response) {
        console.debug("[START_PAGE] Need valid join code for signup!");
        return mode;
      }
      console.debug("[START_PAGE] Verified! Authenticating with backend...");
      return;
    } catch (authError) {
      dispatchToast(authError);
      RollbarManager.logError(authError as Error);
      return;
    } finally {
      setLoading(false);
    }
  }, [mode, currentStep]);

  useEffect(() => {
    if (
      firebaseUserStepState.status === "success" &&
      firebaseUserStepState.value
    ) {
      onUserSignupRequired(firebaseUserStepState.value);
    }
  }, [firebaseUserStepState]);

  useEffect(() => {
    if (clanbeatTokenConnect.hasToken) {
      if (mode === MethodTypes.LOGIN) {
        analyticsLogEvent("login");
        console.debug(
          "[START_PAGE] User exists and token acquired. Logging in..."
        );
        history.replace(ROUTES.HOME);
      } else {
        console.debug(
          "[START_PAGE] Checking if user has all sign up required data"
        );
        checkSignupData(setLoading).then((signupDataResult) => {
          setSignupData(signupDataResult);
          if (
            signupDataResult &&
            signupDataResult?.viewToLoad === SignupSteps.SUCCESS
          ) {
            console.debug(
              "[START_PAGE] User exists and signup data found. Logging user in..."
            );
            history.push(ROUTES.HOME);
          } else {
            setCurrentStep(StartSteps.SUCCESS);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clanbeatTokenConnect, mode]);

  const firebaseSendSMSWrapper = async (number: string) => {
    setPhoneNumber(number);
    firebaseValidate(
      number,
      appVerifier,
      dispatchToast,
      setVerificationId,
      setCurrentStep,
      setLoading,
      t
    );
  };

  const getFirebaseTokenWrapper = async (verificationCode: string) => {
    getFirebaseToken(
      history,
      verificationCode,
      setLoading,
      dispatchToast,
      verificationId,
      t
    );
  };

  return (
    <ClanDesktopLayout leftComponent="">
      <IonPage id="start-page">
        <ClanLoader message={t("general.loading")} showLoading={loading} />
        <LocationContext.Provider value={currentCountryInfo}>
          <FormProvider {...methods}>
            {currentStep === StartSteps.INTRO && (
              <Intro setCurrentStep={setCurrentStep} />
            )}

            {currentStep === StartSteps.ROLESELECT && (
              <RoleSelection
                setMode={setMode}
                setCurrentStep={setCurrentStep}
              />
            )}

            {currentStep === StartSteps.METHODS && (
              <Methods
                mode={mode}
                loading={loading}
                setLoading={setLoading}
                firebaseSendSMS={firebaseSendSMSWrapper}
                resetStartState={resetStartState}
                onUserSignupRequired={onUserSignupRequired}
              />
            )}

            {currentStep === StartSteps.SMSPIN && (
              <SmsPin
                loading={loading}
                phoneNumber={phoneNumber}
                getFirebaseToken={getFirebaseTokenWrapper}
                resetStartState={resetStartState}
              />
            )}

            {currentStep === StartSteps.SCHOOL && (
              <School
                loading={loading}
                mode={mode}
                setMode={setMode}
                setCurrentStep={setCurrentStep}
                resetStartState={resetStartState}
              />
            )}

            {currentStep === StartSteps.STUDENTJOINCODE && (
              <StudentJoinCode
                loading={loading}
                setLoading={setLoading}
                resetStartState={resetStartState}
              />
            )}

            {currentStep === StartSteps.SUCCESS && (
              <Success signupData={signupData} />
            )}
          </FormProvider>
        </LocationContext.Provider>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: ({ token }) => ({
    clanbeatTokenConnect: token
  }),
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: Start
});
