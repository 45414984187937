import { IonButton, IonFooter, IonPage } from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { analyticsLogEvent, setAnalyticsScreenName } from "../Analytics";
import { doRequestAndReturnResult } from "../apiService";
import ClanDesktopLayout from "../components/ClanDesktopLayout";
import ClanHeaderRays from "../components/ClanHeaderRays";
import ClanLlamaMessage from "../components/ClanLlamaMessage";
import ClanLoader from "../components/ClanLoader";
import { connect } from "../data/connect";
import { showToast } from "../data/toasts/toasts.actions";
import * as ROUTES from "../routes";
import { ClanIonContent } from "./DiscussionFeed/PublicFeeds";
import { useAsyncEffect } from "@react-hook/async";
import { isReadyStateLoading } from "../util/AsyncEffectFilter";

enum NudgeAction {
  REFLECTION = "nudge.add.personal.reflection",
  COMPLETE_INTERESTS = "nudge.complete.profile.interests"
}

type NudgeRouteProps = RouteComponentProps<{
  nudge_id: string;
}>;

type NudgingResponse = {
  id: string;
  title: string;
  body: string;
  action: NudgeAction;
};

interface DispatchProps {
  dispatchToast: typeof showToast;
}

const Nudging: React.FC<NudgeRouteProps & DispatchProps> = ({
  match,
  dispatchToast
}) => {
  const history = useHistory();
  const location = useLocation();
  const [title, setTitle] = useState<string | undefined>();
  const [body, setBody] = useState<string>("");
  const [action, setAction] = useState<NudgeAction | undefined>();

  const initStatus = useAsyncEffect(async () => {
    if (!match.params.nudge_id) return;
    // noinspection ES6MissingAwait
    setAnalyticsScreenName("NUDGE_PAGE");
    setAction((location.state as { nudgeAction?: NudgeAction })?.nudgeAction);
    setTitle(undefined);
    setBody("");
    setAction(undefined);
    try {
      const response = await doRequestAndReturnResult<NudgingResponse>(
        "get",
        `/nudge/v1/${match.params.nudge_id}`
      );
      // noinspection ES6MissingAwait
      setAnalyticsScreenName(`NUDGE_${response.action}`);
      // noinspection ES6MissingAwait
      analyticsLogEvent("NUDGE_ACTION_VIEW");
      setTitle(response.title);
      setBody(response.body);
      setAction(response.action);
    } catch (error) {
      dispatchToast(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.nudge_id]);

  const doCallAction = async () => {
    // noinspection ES6MissingAwait
    analyticsLogEvent("NUDGE_ACTION_CONTINUE");
    switch (action) {
      case NudgeAction.COMPLETE_INTERESTS:
        history.replace(ROUTES.ONBOARDING, { currentStep: 4 });
        break;
      case NudgeAction.REFLECTION:
        history.push(ROUTES.REFLECTIONS);
        break;
    }
  };

  const doCallSkip = async () => {
    // noinspection ES6MissingAwait
    analyticsLogEvent("NUDGE_ACTION_SKIP");
    history.replace(ROUTES.DASHBOARD);
  };

  const { t } = useTranslation();

  return (
    <>
      <ClanDesktopLayout>
        <IonPage className="flex justify-start">
          <ClanLoader
            message={t("general.loading")}
            showLoading={isReadyStateLoading(initStatus)}
          />
          <ClanHeaderRays short />
          <ClanIonContent>
            <ClanLlamaMessage
              messages={title ? [title] : []}
              description={
                action === NudgeAction.COMPLETE_INTERESTS ? body : undefined
              }
            />
          </ClanIonContent>
          <IonFooter className="ion-no-border">
            <div
              className="h-clanBtn flex justify-center items-center text-center"
              onClick={doCallSkip}
            >
              <h6 className="cursor-pointer w-3/4">{t("nudging.skip")}</h6>
            </div>
            <IonButton
              expand="block"
              className="my-4 mx-20 font-extrabold font-gilroy h-16"
              style={{ "--border-radius": "100px" }}
              onClick={doCallAction}
            >
              {t("nudging.lets_do_it")}
            </IonButton>
          </IonFooter>
        </IonPage>
      </ClanDesktopLayout>
    </>
  );
};

export default connect<NudgeRouteProps, {}, DispatchProps>({
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: Nudging
});
