import React from "react";
import styled from "styled-components";
import { ChartResultPercentage, formatPercentage } from "../util/Analytics";

const STATISTICS_ITEMS_PER_ROW = 2;

const StatisticsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StatItemTitle = styled.div`
  max-width: 9rem;
  text-align: center;
  font-family: Gilroy;
`;

const MoodItemBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1.2rem;
  width: 50%;
  text-align: center;
  background-color: ${({ light }: { light: boolean }) =>
    light ? "white" : "#FAFAFA"};
`;

type MoodItemNumberProps = {
  positive?: boolean;
};

const MoodItemNumber = styled.div<MoodItemNumberProps>`
  margin: 0.8rem 0;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: Gilroy;
  background: ${({ theme }) => theme.primary};
  border: 1px solid ${(props) => (props.positive ? "#45DD85" : "#FF6170")};
`;

interface MoodItemProps {
  index: number;
  moodItem: ChartResultPercentage;
}

const MoodItem: React.FC<MoodItemProps> = ({ index, moodItem }) => {
  const isLight = Math.floor((index + 1) / STATISTICS_ITEMS_PER_ROW) % 2 === 0;

  return (
    <MoodItemBlock light={isLight}>
      <StatItemTitle>{moodItem.title}</StatItemTitle>
      <MoodItemNumber positive={moodItem.isPositive}>
        {formatPercentage(moodItem.percentage)}
      </MoodItemNumber>
      <span dangerouslySetInnerHTML={{ __html: moodItem.message }} />
    </MoodItemBlock>
  );
};

interface MoodPercentagesProps {
  data: Array<ChartResultPercentage>;
}

const MoodPercentages: React.FC<MoodPercentagesProps> = ({ data }) => {
  return (
    <StatisticsList>
      {data.map((moodPercentage, index) => (
        <MoodItem
          key={moodPercentage.key}
          index={index}
          moodItem={moodPercentage}
        />
      ))}
    </StatisticsList>
  );
};

export default MoodPercentages;
