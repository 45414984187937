import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useGroupQueryViewFilter } from "../../../util/api/GroupsQueryService";
import { GroupResponse } from "../../../util/api/OrganisationGroupService";
import { UserEnabledProperties } from "../../../util/api/UserEnabledPropertiesService";
import { useProfileFilterPaginator } from "../../../util/CustomHooks";
import { ClanMembershipListItemResponse } from "../../../util/models/ClanProfileModels";
import { ShareTabs } from "../../../util/ShareOptions";
import ClanSelectableItem from "../../ClanSelectableItem";
import {
  ViewSharingConfiguration,
  ViewSharings,
  Views,
  ViewTypes,
  ViewSharingConfigurationItem,
  useSharingConstants
} from "../../../util/Configuration";
import { useAsyncEffect } from "@react-hook/async";

const StyledShareHeader = styled.div`
  flex: 0 1 auto;
  padding: 2rem 3rem 1rem;
`;

const StyledShareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 90%;
`;

const StyledShareCard = styled(IonCard)`
  flex: 1 1 auto;
  overflow: auto;
  margin: 1rem 1.5rem 2rem;
  border-radius: 1rem;
`;

type ShareWithItem =
  | ViewSharingConfiguration
  | GroupResponse
  | ClanMembershipListItemResponse;

interface ClanSharePostModalProps {
  groups: Array<number>;
  profiles: Array<number>;
  roles: Array<string>;
  setRoles: (result: Array<string>) => void;
  setGroups: (result: Array<number>) => void;
  setProfiles: (result: Array<number>) => void;
  view: Views;
  defaultTab?: ShareTabs;
  disableRoles?: boolean;
}

const ClanSharePost: React.FC<ClanSharePostModalProps> = ({
  groups,
  profiles,
  defaultTab,
  setGroups,
  setProfiles,
  roles,
  setRoles,
  view,
  disableRoles = false
}) => {
  const [profilesFromResponse, setProfilesFromResponse] = useState<
    ClanMembershipListItemResponse[]
  >([]);
  const [groupsFromResponse, setGroupsFromResponse] = useState<GroupResponse[]>(
    []
  );

  const [hasMoreGroups, setHasMoreGroups] = useState(false);
  const [selectedList, selectList] = useState<ShareTabs>(getDefaultOpenedTab());
  const [searchText, setSearchText] = useState<string>("");
  const [searchTextGroup, setSearchTextGroup] = useState<string>("");
  const { t } = useTranslation();
  const [hasMoreProfiles, setHasMoreProfiles] = useState(false);

  const [shareWithItems, setShareWithItems] = useState<ShareWithItem[]>([]);

  function getDefaultOpenedTab() {
    if (roles.length > 0 && !roles.includes(ViewSharings.GROUPS_MINE)) {
      return ShareTabs.ROLE;
    } else if (profiles.length > 0) {
      return ShareTabs.INDIVIDUAL;
    } else if (
      groups.length > 0 ||
      roles.includes(ViewSharings.GROUPS_MINE) ||
      !defaultTab
    ) {
      return ShareTabs.GROUP;
    }

    return defaultTab;
  }

  const sharingConstants = useSharingConstants(view);

  const profilesAPIPaginator = useProfileFilterPaginator(view);
  useAsyncEffect(async () => {
    if (!profilesAPIPaginator) return;
    try {
      profilesAPIPaginator.setQueryArgumentNoFetch("query", searchText);
      await profilesAPIPaginator.reset();
      const response = await profilesAPIPaginator.getNext();
      setProfilesFromResponse(response);
      profilesAPIPaginator
        .hasNext()
        .then(setHasMoreProfiles)
        .catch((e) => console.error("Failed to fetch profiles next page", e));
    } catch (e) {
      console.error("Failed to get profiles");
    }
  }, [profilesAPIPaginator, searchText]);

  const groupDataIterator = useGroupQueryViewFilter(view);

  useAsyncEffect(async () => {
    if (!groupDataIterator) return;
    try {
      await groupDataIterator.setQueryArgumentNoFetch("query", searchTextGroup);
      await groupDataIterator.reset();
      const result = await groupDataIterator.getNext();
      setGroupsFromResponse(result);
      groupDataIterator
        .hasNext()
        .then(setHasMoreGroups)
        .catch(() => console.error("Failed to fetch groups next page"));
    } catch (e) {
      console.error("Failed to get groups list");
    }
  }, [groupDataIterator, searchTextGroup]);

  async function loadNextPage() {
    if (!hasMoreProfiles) return;
    switch (selectedList) {
      case ShareTabs.INDIVIDUAL:
        profilesAPIPaginator
          ?.getNext()
          .then((response) => {
            setProfilesFromResponse([...profilesFromResponse, ...response]);
          })
          .then(() => profilesAPIPaginator.hasNext().then(setHasMoreProfiles));
        break;
      case ShareTabs.GROUP:
        groupDataIterator
          .getNext()
          .then((response) => {
            setGroupsFromResponse([...groupsFromResponse, ...response]);
          })
          .then(() => groupDataIterator.hasNext().then(setHasMoreGroups));
        break;
    }
  }

  const selectProfile = (profileId: number) => {
    const reducedProfile = profiles.filter((id) => id !== profileId);
    reducedProfile.push(profileId);
    setProfiles(reducedProfile);
  };

  const deselectProfile = (profileId: number) => {
    setProfiles(profiles.filter((id) => id !== profileId));
  };

  const selectGroup = (groupId: number) => {
    setRoles([]);
    const reducedGroup = groups.filter((id) => id !== groupId);
    reducedGroup.push(groupId);
    setGroups(reducedGroup);
  };

  const deSelectGroup = (groupId: number) => {
    setGroups(groups.filter((id) => id !== groupId));
  };

  const selectRole = (dataItem: ViewSharingConfigurationItem): void => {
    setGroups([]);
    setRoles([dataItem.filter]);
  };

  useEffect(() => {
    switch (selectedList) {
      case ShareTabs.INDIVIDUAL:
        setShareWithItems(profilesFromResponse);
        break;
      case ShareTabs.GROUP:
        setShareWithItems(
          sharingConstants && groupsFromResponse.length > 4
            ? [
                ...sharingConstants.filters
                  .filter((filter) => filter.type === ViewTypes.GROUP)
                  .map(
                    (filter) =>
                      (({
                        name: filter.label,
                        type: filter.type,
                        filter: filter.filter
                      } as unknown) as GroupResponse)
                  ),
                ...groupsFromResponse
              ]
            : groupsFromResponse
        );
        break;
      case ShareTabs.ROLE:
        sharingConstants &&
          setShareWithItems(
            sharingConstants.filters.filter(
              (filter) => filter.type === ViewTypes.ROLE
            )
          );
        break;
    }
  }, [
    groupsFromResponse,
    profilesFromResponse,
    sharingConstants,
    selectedList
  ]);

  const canBeSelected = (dataItem: ShareWithItem) =>
    selectedList !== ShareTabs.INDIVIDUAL ||
    !!(dataItem as ClanMembershipListItemResponse).profileId;

  function doWithProfile<T>(
    dataItem: ShareWithItem,
    method: (profileId: number) => T
  ): T | false {
    const item = dataItem as ClanMembershipListItemResponse;
    if (item.profileId) {
      return method(item.profileId);
    }
    return false;
  }

  const isSelected = (dataItem: ShareWithItem): boolean => {
    const isRoleSelected = roles.includes(
      (dataItem as ViewSharingConfigurationItem).filter
    );

    switch (selectedList) {
      case ShareTabs.INDIVIDUAL:
        return doWithProfile(dataItem, (id) => profiles.includes(id));
      case ShareTabs.GROUP:
        return (dataItem as ViewSharingConfigurationItem).type ===
          ViewTypes.GROUP
          ? isRoleSelected
          : groups.includes((dataItem as GroupResponse).id);
      case ShareTabs.ROLE:
        return isRoleSelected;
    }
  };

  const isAllGroupsSelected = useMemo(
    () =>
      sharingConstants?.filters.some(
        (filter) =>
          filter.type === ViewTypes.GROUP && roles.includes(filter.filter)
      ),
    [roles, sharingConstants]
  );

  return (
    <StyledShareWrapper>
      <StyledShareHeader>
        <IonSegment
          id="clan-share-segment"
          className="bg-clanYellow-100"
          onIonChange={(e) => {
            selectList(e.detail.value as ShareTabs);
          }}
          value={selectedList}
          mode="ios"
        >
          <IonSegmentButton value={ShareTabs.GROUP}>
            <IonLabel>{t("clan_share_post.group").toUpperCase()}</IonLabel>
          </IonSegmentButton>
          {!disableRoles &&
            sharingConstants?.filters.filter(
              (filter) => filter.type === ViewTypes.ROLE
            ).length !== 0 && (
              <IonSegmentButton value={ShareTabs.ROLE}>
                <IonLabel>{t("clan_share_post.role").toUpperCase()}</IonLabel>
              </IonSegmentButton>
            )}
          <IonSegmentButton value={ShareTabs.INDIVIDUAL}>
            <IonLabel>{t("clan_share_post.inidividuals")}</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </StyledShareHeader>

      <StyledShareCard>
        <IonCardHeader>
          {selectedList !== ShareTabs.ROLE && (
            <IonItem>
              <IonSearchbar
                value={
                  selectedList === ShareTabs.GROUP
                    ? searchTextGroup
                    : searchText
                }
                className="p-0"
                mode="ios"
                placeholder={t("clan_share_post.search")}
                onIonChange={(e) =>
                  selectedList === ShareTabs.GROUP
                    ? setSearchTextGroup(e.detail.value || "")
                    : setSearchText(e.detail.value || "")
                }
              />
            </IonItem>
          )}
          <IonItem className="whitespace-normal">
            {groups.length || profiles.length || roles.length ? (
              <IonLabel>
                {isAllGroupsSelected ? t("clan_share_post.all_selected") : ""}
                {ShareTabs.INDIVIDUAL === selectedList && profiles.length > 0
                  ? `${profiles.length} ${t("clan_share_post.person", {
                      count: profiles.length
                    })} ${t("clan_share_post.selected")}`
                  : ""}
                {ShareTabs.GROUP === selectedList && groups.length > 0
                  ? `${groups.length} ${t("clan_share_post.group", {
                      count: groups.length
                    })} ${t("clan_share_post.selected")}`
                  : ""}
                {ShareTabs.ROLE === selectedList &&
                !isAllGroupsSelected &&
                roles.length > 0
                  ? `${roles.length} ${t("clan_share_post.role", {
                      count: roles.length
                    })} ${t("clan_share_post.selected")}`
                  : ""}
              </IonLabel>
            ) : (
              <IonLabel className="whitespace-normal">
                {t("clan_share_post.select")}
              </IonLabel>
            )}
          </IonItem>
        </IonCardHeader>
        <IonCardContent>
          {shareWithItems?.map((dataItem: ShareWithItem, index) => {
            return (
              <ClanSelectableItem
                key={`share_rwo_${index}`}
                uniqueKey={`share_row_${index}`}
                isSelectable={canBeSelected(dataItem)}
                onSelect={() => {
                  switch (selectedList) {
                    case ShareTabs.INDIVIDUAL:
                      doWithProfile(dataItem, selectProfile);
                      setRoles([]);
                      setGroups([]);
                      break;
                    case ShareTabs.GROUP:
                      (dataItem as ViewSharingConfigurationItem).type ===
                      ViewTypes.GROUP
                        ? selectRole(dataItem as ViewSharingConfigurationItem)
                        : selectGroup((dataItem as GroupResponse).id);
                      setProfiles([]);
                      break;
                    case ShareTabs.ROLE:
                      selectRole(dataItem as ViewSharingConfigurationItem);
                      setProfiles([]);
                      setGroups([]);
                      break;
                  }
                }}
                onDeselect={() => {
                  switch (selectedList) {
                    case ShareTabs.INDIVIDUAL:
                      doWithProfile(dataItem, deselectProfile);
                      break;
                    case ShareTabs.GROUP:
                      (dataItem as ViewSharingConfigurationItem).type ===
                      ViewTypes.GROUP
                        ? setRoles([])
                        : deSelectGroup((dataItem as GroupResponse).id);
                      break;
                    case ShareTabs.ROLE:
                      setRoles([]);
                      break;
                  }
                }}
                selected={isSelected(dataItem)}
                profileFullName={
                  selectedList === ShareTabs.INDIVIDUAL ||
                  selectedList === ShareTabs.GROUP
                    ? (dataItem as ClanMembershipListItemResponse).name
                    : (dataItem as ViewSharingConfigurationItem).label
                }
                {...(selectedList === ShareTabs.INDIVIDUAL && {
                  profileThumbnailUrl: (dataItem as ClanMembershipListItemResponse)
                    .images?.thumbnail?.url
                })}
                {...(selectedList === ShareTabs.INDIVIDUAL && {
                  isTeacher: UserEnabledProperties.Legacy.isAdult(
                    dataItem as ClanMembershipListItemResponse
                  )
                })}
              />
            );
          })}
          {selectedList !== ShareTabs.ROLE && (
            <IonSegment>
              <IonSegmentButton
                className="font-extrabold font-gilroy text-clanGreen-100"
                disabled={
                  selectedList === ShareTabs.INDIVIDUAL
                    ? !hasMoreProfiles
                    : !hasMoreGroups
                }
                onClick={loadNextPage}
              >
                {hasMoreProfiles
                  ? t("general.list_load_more")
                  : t("general.list_end")}
              </IonSegmentButton>
            </IonSegment>
          )}
        </IonCardContent>
      </StyledShareCard>
    </StyledShareWrapper>
  );
};

export default ClanSharePost;
