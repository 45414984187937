export const SOCIAL_LINK_LIST = [
  "Whatsapp",
  "Facebook",
  "Instagram",
  "Discord",
  "Snapchat",
  "Tiktok",
  "Twitch",
  "Xbox",
  "PS4",
  "Youtube",
  "Twitter"
];

export type ResponseSocialLinksType = {
  [key: string]: { value: string; url?: string };
};

export type UserSocialLinksType = { name: string; url?: string; value: string };

export const mapSocialLinks = (
  socialLinks?: ResponseSocialLinksType
): UserSocialLinksType[] => {
  if (!socialLinks) {
    return [];
  }
  return SOCIAL_LINK_LIST.filter((label) => socialLinks[label]).map((label) => {
    return {
      name: label,
      value: socialLinks[label].value,
      url: socialLinks[label].url
    };
  });
};

export const mapSocialLinksWithEmptyValues = (
  socialLinks?: ResponseSocialLinksType
): UserSocialLinksType[] => {
  if (!socialLinks) {
    return [];
  }
  return SOCIAL_LINK_LIST.map((label) => {
    return {
      name: label,
      value: socialLinks[label]?.value || "",
      url: socialLinks[label]?.url
    };
  });
};
