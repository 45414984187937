import { FeedPostShareRequest } from "../types/FeedPostShareRequest.d";
import {
  ClanEditPostEditDetails,
  ClanPostResultDetails
} from "../../models/ClanPostModels";
import { doRequestAndReturnResult } from "../../../apiService";
import { analyticsLogEvent } from "../../../Analytics";
import { Views } from "../../Configuration";

const sendPostEvent = (view: Views, method: string) => {
  // noinspection JSIgnoredPromiseFromCall
  analyticsLogEvent(`POST_${method}`);
  // noinspection JSIgnoredPromiseFromCall
  analyticsLogEvent(`POST_${view}_${method}`);
};

export const reSharePost = async (
  view: Views,
  postId: string,
  post: FeedPostShareRequest
): Promise<ClanPostResultDetails> => {
  sendPostEvent(view, "re-share");
  return doRequestAndReturnResult(
    "put",
    `/feed/v3/view/${view}/${postId}/share`,
    post
  );
};

export const savePost = async (
  view: Views,
  post: ClanEditPostEditDetails
): Promise<ClanPostResultDetails> => {
  sendPostEvent(view, "save");
  return doRequestAndReturnResult("post", `/feed/v3/view/${view}`, {
    ...post
  });
};

export const updatePost = async (
  view: Views,
  postId: string,
  post: ClanEditPostEditDetails
): Promise<ClanPostResultDetails> => {
  sendPostEvent(view, "update");
  return doRequestAndReturnResult("put", `/feed/v3/view/${view}/${postId}`, {
    ...post
  });
};
