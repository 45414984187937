export enum CommunityJoinType {
  group = "group",
  community = "community"
}

export interface CommunityJoinTarget {
  type: CommunityJoinType;
  id: number | string;
  name: string;
}
