import { IonButton, IonIcon, IonInput } from "@ionic/react";
import { addCircle } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { WEIGHTSCALE } from "../../pages/Planner/PlannerDay";
import { FeedTypes } from "../../util/Feeds";
import { removeQuery } from "../../util/ReactRouterUtils";
import ClanBottomModal from "../ClanBottomModal";
import ClanPostSharingModal from "../ClanDiscussionPost/ClanPostSharingModal";
import TaskSchedulerModal from "../TaskSchedulerModal";
import ClanLabelInput, { Label } from "./ClanLabelInput";
import LabelsButton from "./LabelsButton";
import MoodButton from "./MoodButton";
import MoodModal from "./MoodModal";
import ScheduleButton from "./ScheduleButton";
import WeightButton from "./WeightButton";
import * as ROUTES from "../../routes";
import { useTranslation } from "react-i18next";
import { Views } from "../../util/Configuration";

const TaskInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
  margin-left: 12px;
  margin-right: 4px;
`;

export type NewTask = {
  title: string;
  date?: Date;
  dueDate?: Date;
  reminder?: Date;
  mood?: string;
  labels?: string[];
  weight?: number;
};

type NewTaskModalProps = {
  initialDay: string;
  existingLabels: { value: string; amount: number }[];
  onClose: () => void;
  onSubmit: (newTask: NewTask) => void;
  enableDirectShare: boolean;
};

const NewTaskModal: React.FC<NewTaskModalProps> = ({
  initialDay,
  existingLabels,
  onClose,
  onSubmit,
  enableDirectShare = false
}) => {
  const [title, setTitle] = useState<string>("");
  const [date, setDate] = useState<string | undefined>();
  const [dueDate, setDueDate] = useState<string | undefined>(initialDay);
  const [reminder, setReminder] = useState<Date | undefined>();
  const [mood, setMood] = useState<string>();
  const [weight, setWeight] = useState<WEIGHTSCALE>();
  const [labels, setLabels] = useState<Array<string>>([]);

  const [showLabelInput, setShowLabelInput] = useState<boolean>(false);
  const [moodModalOpen, setMoodModalOpen] = useState<boolean>(false);
  const [isSchedulerModalOpen, setSchedulerModalOpen] = useState<boolean>(
    false
  );
  const [showSharingModal, setShowSharingModal] = useState<boolean>(false);

  const [taskbarAnimProps, setTaskbarAnimProps] = useSpring(() => ({
    config: { tension: 250, clamp: true },
    marginBottom: "0px"
  }));

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setTaskbarAnimProps({ marginBottom: showLabelInput ? "-50px" : "0px" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLabelInput]);

  function switchWeight() {
    switch (weight) {
      case undefined:
        setWeight(WEIGHTSCALE.EASY);
        break;
      case WEIGHTSCALE.EASY:
        setWeight(WEIGHTSCALE.MEDIUM);
        break;
      case WEIGHTSCALE.MEDIUM:
        setWeight(WEIGHTSCALE.HARD);
        break;
      case WEIGHTSCALE.HARD:
        setWeight(undefined);
        break;
    }
  }

  function onLabelsChange(newLabels: Array<string>) {
    setLabels(newLabels);
  }

  function submit() {
    onSubmit({
      title,
      date: date !== undefined ? new Date(date) : undefined,
      dueDate: dueDate !== undefined ? new Date(dueDate) : undefined,
      reminder,
      mood,
      labels,
      weight
    });
    setTitle("");
    setDate(undefined);
    setDueDate(undefined);
    setReminder(undefined);
    setLabels([]);
    setWeight(undefined);
    setMood(undefined);
  }

  function isScheduled(): boolean {
    return !!dueDate || !!reminder || !!date;
  }

  return (
    <>
      <TaskSchedulerModal
        isOpen={isSchedulerModalOpen}
        onClose={() => setSchedulerModalOpen(false)}
        targetDate={date}
        dueDate={dueDate}
        reminder={reminder}
        onTargetDateChange={setDate}
        onDueDateChange={setDueDate}
        onReminderChange={setReminder}
        onAddToMyList={() => {
          setDueDate(undefined);
        }}
      />

      {showSharingModal && (
        <ClanPostSharingModal
          enableDirectShare
          view={Views.TasksFeed}
          post={
            {
              title,
              targetDate: date !== undefined ? new Date(date) : undefined,
              dueDate: dueDate !== undefined ? new Date(dueDate) : undefined,
              reminders: reminder,
              mood,
              labels,
              weight
            } as NewTask
          }
          onClose={() => {
            setShowSharingModal(false);
            removeQuery(location, history, "directShare");
          }}
          onDone={() => {
            setShowSharingModal(false);
            removeQuery(location, history, "directShare");
            history.push(ROUTES.PUBLIC_FEEDS, {
              feedType: FeedTypes.TASK
            });
          }}
        />
      )}

      <MoodModal
        isOpen={moodModalOpen}
        onDismiss={() => setMoodModalOpen(false)}
        onMoodSelect={(newMood) => {
          setMood(newMood);
          setMoodModalOpen(false);
        }}
      />

      <ClanBottomModal
        isOpen={true}
        onDidDismiss={() => {
          onClose();
          removeQuery(location, history, "directShare");
          removeQuery(location, history, "inputMode");
        }}
      >
        {!showLabelInput ? (
          <>
            <TaskInputWrapper id="add-task-input">
              <IonInput
                value={title}
                placeholder={t("planner.add_new")}
                maxlength={100}
                onIonChange={(e) => {
                  setTitle(e.detail.value || "");
                }}
              />
              <IonButton
                style={{
                  "--padding-start": "1rem",
                  "--padding-end": "1rem"
                }}
                onClick={() =>
                  enableDirectShare ? setShowSharingModal(true) : submit()
                }
                disabled={!title}
                className={`h-clanBtn text-clanH2 align-middle ${
                  enableDirectShare
                    ? "bg-clanYellow-100 text-black rounded-full"
                    : "text-clanGreen-100"
                }`}
                color="tertiary"
                fill="clear"
              >
                {enableDirectShare ? (
                  <p className="tracking-normal">{t("planner.share")}</p>
                ) : (
                  <IonIcon icon={addCircle} className="text-clanGreen-100" />
                )}
              </IonButton>
            </TaskInputWrapper>
            {labels.length > 0 && (
              <div className="pb-2">
                {labels.map((label) => (
                  <Label key={label} label={label} onClick={() => null} />
                ))}
              </div>
            )}
          </>
        ) : (
          <TaskInputWrapper>
            <ClanLabelInput
              labels={labels}
              labelSuggestions={existingLabels}
              onLabelsChange={onLabelsChange}
              onInputComplete={() => setShowLabelInput(false)}
            />
          </TaskInputWrapper>
        )}

        <animated.div
          id="task_add_bar"
          className="flex overflow-x-scroll border border-solid border-r-0 border-l-0 border-gray-200 items-center bg-white pb-2"
          style={taskbarAnimProps}
        >
          <ScheduleButton
            isScheduled={isScheduled()}
            onClick={() => {
              setSchedulerModalOpen(true);
            }}
          />

          <WeightButton
            weight={weight}
            onClick={switchWeight}
            onClear={() => setWeight(undefined)}
          />

          <LabelsButton
            isSet={labels.length !== 0}
            onClick={() => {
              setShowLabelInput(true);
            }}
            onClear={() => setLabels([])}
          />

          <MoodButton
            mood={mood}
            onClick={() => {
              setMoodModalOpen(true);
            }}
            onClear={() => setMood(undefined)}
          />
        </animated.div>
      </ClanBottomModal>
    </>
  );
};

export default NewTaskModal;
