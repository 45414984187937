import { FirebaseAuthentication } from "@robingenz/capacitor-firebase-authentication";

import { isNativePlatform } from "../../AppUtils";
import RollbarManager from "../../RollbarManager";
import { getFirebaseAuthentication } from "./FirebaseAuthConfiguration";

export const logoutFromFirebase = async (): Promise<void> => {
  try {
    await FirebaseAuthentication.signOut();
  } catch (error) {
    RollbarManager.logError(error as Error);
  }

  if (isNativePlatform) return;

  try {
    await getFirebaseAuthentication().signOut();
  } catch (error) {
    RollbarManager.logError(error as Error);
  }
};
