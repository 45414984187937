import React, { useEffect, useState } from "react";
import { IonDatetime } from "@ionic/react";
import { PickerButton } from "@ionic/core/dist/types/components/picker/picker-interface";
import { useTranslation } from "react-i18next";

type ClanDateTimePickProps = {
  value?: string;
  setTime: boolean;
  placeholder: string;
  onDone: (newValue: Date, oldValue?: Date) => void;
  onDelete?: (value: Date) => void;
  className?: string;
  onCancel?: () => void;
  min?: string;
  max?: string;
  yearValues?: string;
  dateFormat?: string;
  timeFormat?: string;
};

const ClanDateTimePick: React.FC<ClanDateTimePickProps> = ({
  value,
  setTime,
  onDone,
  onDelete,
  placeholder,
  className,
  onCancel,
  min,
  max,
  yearValues,
  dateFormat,
  timeFormat
}) => {
  const [pickerButtons, setPickerButtons] = useState<PickerButton[]>([]);
  const [displayFormat, setDisplayFormat] = useState<string>(
    "D MMM YYYY HH:mm"
  );
  const [pickerFormat, setPickerFormat] = useState<string>("YYYY-MM-DD H:mm");
  const { t } = useTranslation();

  useEffect(() => {
    const oldValue = value ? new Date(value) : undefined;
    const buttons = [];
    if (oldValue && onDelete) {
      buttons.push({
        text: t("date_time_pick.remove"),
        handler: () => {
          if (value) onDelete(oldValue);
          return true;
        }
      });
    }
    buttons.push({
      text: t("date_time_pick.cancel"),
      handler: onCancel ? () => onCancel() : () => true
    });
    buttons.push({
      text: t("date_time_pick.set"),
      handler: (e: any) => {
        const newDate = new Date();
        newDate.setFullYear(e.year.value, e.month.value - 1, e.day.value);
        if (e.hour) {
          newDate.setHours(e.hour.value, e.minute.value, 0);
        }
        onDone(newDate, oldValue);
        return true;
      }
    });
    if (setTime) {
      setDisplayFormat(timeFormat ? timeFormat : "HH:mm DD.MM");
      setPickerFormat("YYYY-MMM-DD H:mm");
    } else {
      setDisplayFormat(dateFormat ? dateFormat : "DD MMM YYYY");
      setPickerFormat("YYYY-MMM-DD");
    }
    setPickerButtons(buttons);
  }, [value, onDone, onDelete, setTime, onCancel, timeFormat, dateFormat, t]);

  return (
    <IonDatetime
      className={`p-0 ${className}`}
      placeholder={placeholder}
      displayFormat={displayFormat}
      pickerFormat={pickerFormat}
      min={min}
      max={max}
      minuteValues="0,15,30,45"
      yearValues={yearValues}
      value={value ? value : undefined}
      pickerOptions={{
        buttons: pickerButtons
      }}
    />
  );
};

export default ClanDateTimePick;
