const regexp = /(?:.+?)?(?:\/v\/|watch\/|\?v=|&v=|youtu\.be\/|\/v=|^youtu\.be\/|watch%3Fv%3D)([a-zA-Z0-9_-]+)+/g;
//const regexp = /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed)(?:(?:(?=\/[^&\s\?]+(?!\S))\/)|(?:\S*v=|v\/)))([^&\s\?]+)/gm;
//const regexp = /(?:https?:\/\/)(.*)/gm;

const resolveYoutubeHash = (url: string): string | undefined => {
  regexp.lastIndex = 0;
  const result = regexp.exec(url?.trim());
  if (!result) return undefined;
  return result[1];
};

export const resolveYoutubeLink = (
  url: string
): Promise<string | undefined> => {
  const hash = resolveYoutubeHash(url);
  if (hash === undefined) return Promise.resolve(undefined);
  return Promise.resolve(`https://www.youtube.com/embed/${hash}?autoplay=0`);
};
