import React from "react";

import { ReactComponent as SmoothRaysImage } from "../icons/smooth-rays.svg";
import { ReactComponent as TopRaysImage } from "../icons/top-rays.svg";

interface ClanHeaderRaysProps {
  className?: string;
  short?: boolean;
}

const ClanHeaderRays: React.FC<ClanHeaderRaysProps> = ({
  className = "",
  short = false
}) => {
  return short ? (
    <SmoothRaysImage id="clan-rays" className={`w-full ${className}`} />
  ) : (
    <TopRaysImage id="clan-rays" className={`w-full ${className}`} />
  );
};

export default ClanHeaderRays;
