import React from "react";
import { connect } from "../../data/connect";
import { removeModal } from "../../data/modals/modals.actions";
import { ModalID, ModalType, ModalTypes } from "../../data/modals/modals.state";
import QuickHelpModal from "../../pages/QuickHelp/QuickHelpModal";
import { Paragraph } from "../Section";
import LlamaModal from "./LlamaModal";

interface DispatchProps {
  dismissModal: Function;
}

interface StateProps {
  modals: Array<ModalTypes>;
}

const ClanModalManager: React.FC<StateProps & DispatchProps> = ({
  modals,
  dismissModal
}) => {
  if (modals.length === 0) return null;
  const activeModal = modals[0];
  const onClose = (id: ModalID) => () => dismissModal(id);

  switch (activeModal.type) {
    case ModalType.QuickHelp:
      return (
        <QuickHelpModal key={activeModal.id} onClose={onClose(activeModal.id)}>
          {activeModal.paragraphs.map((paragraph, index) => (
            <Paragraph key={index} fontSize="18px">
              {paragraph}
            </Paragraph>
          ))}
        </QuickHelpModal>
      );

    case ModalType.Llama:
      return (
        <LlamaModal
          key={activeModal.id}
          title={activeModal.title}
          paragraphs={activeModal.paragraphs}
          actions={activeModal.actions}
          onClose={onClose(activeModal.id)}
        />
      );

    default:
      return null;
  }
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    modals: state.modals.modals
  }),
  mapDispatchToProps: {
    dismissModal: removeModal
  },
  component: ClanModalManager
});
