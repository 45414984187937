import { FirebaseAuthentication } from "@robingenz/capacitor-firebase-authentication";

import { EmailAuthenticationType } from "../types/EmailAuthenticationType.d";
import {
  FirebaseApiServiceError,
  FirebaseApiServiceErrorType
} from "../types/FirebaseApiServiceError.d";

const authenticateEmail = (type: EmailAuthenticationType) => {
  switch (type) {
    case EmailAuthenticationType.GOOGLE:
      return FirebaseAuthentication.signInWithGoogle();
    case EmailAuthenticationType.MICROSOFT:
      return FirebaseAuthentication.signInWithMicrosoft();
  }
  throw new Error(`${type} not implemented!`);
};

export const authenticateWithFirebaseEmail = async (
  type: EmailAuthenticationType
): Promise<boolean> => {
  const signInResult = await authenticateEmail(type);

  const { credential, user } = signInResult;
  if (credential && user && user.email) {
    if (!user.emailVerified) {
      // TODO: we need to ask for verification otherwise back end will throw an error
      throw new FirebaseApiServiceError(
        "E-mail is not verified!",
        FirebaseApiServiceErrorType.MAIL_NOT_VERIFIED
      );
    }

    return true;
  } else {
    throw new FirebaseApiServiceError("User or credentials missing!");
  }
};
