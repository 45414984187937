import { DataStorage } from "./DataStorage";
import { Storage } from "@capacitor/storage";

export const cleanUpOldStorage = async () => {
  try {
    return await Storage.removeOld();
  } catch (error) {
    console.error("Failed to clean up old storage, but we do not care!", error);
  }
};

export class LocalStorageDataStorage implements DataStorage {
  clear(): Promise<void> {
    return Storage.clear();
  }

  get(options: {
    key: string;
  }): Promise<{
    value: string | null;
  }> {
    return Storage.get(options);
  }

  keys(): Promise<{ keys: string[] }> {
    return Storage.keys();
  }

  remove(options: { key: string }): Promise<void> {
    return Storage.remove(options);
  }

  set(options: { key: string; value: string }): Promise<void> {
    return Storage.set(options);
  }

  setUnsafe(options: { key: string; value: string }): Promise<void> {
    throw new Error("The setLocal is not allowed!");
  }

  clearSafeStorage(): Promise<void> {
    throw new Error("The clearSafeStorage is not allowed!");
  }

  clearUnsafeStorage(): Promise<void> {
    throw new Error("The clearSafeStorage is not allowed!");
  }
}
