import { resolveVideoAskLink } from "./VideoAskt";
import { resolveVimeoLink } from "./Vimeo";
import { resolveYoutubeLink } from "./Youtube";

type VideoContentResolverType = {
  title: string;
  permissions: string;
  resolve: (url: string) => Promise<string | undefined>;
};

const DEFAULT_PERMISSIONS =
  "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture";

const resolvers: VideoContentResolverType[] = [
  {
    title: "VideoAsk",
    resolve: (url: string) => resolveVideoAskLink(url),
    permissions: DEFAULT_PERMISSIONS
  },
  {
    title: "Vimeo",
    resolve: (url: string) => resolveVimeoLink(url),
    permissions: DEFAULT_PERMISSIONS
  },
  {
    title: "YouTube",
    resolve: (url: string) => resolveYoutubeLink(url),
    permissions: DEFAULT_PERMISSIONS + "; clipboard-write"
  }
];

const getVideoContent = (url: string, resolver: VideoContentResolverType) => {
  return `<VideoContent><iframe src="${url}" title="${resolver.title} video player" frameborder="0" allow="${resolver.permissions}" allowfullscreen></iframe></VideoContent>`;
};

export const resolveVideoContent = async (
  url: string
): Promise<string | undefined> => {
  const _url = url.trim();
  for (let i = 0; i < resolvers.length; i++) {
    const result = await resolvers[i].resolve(_url);
    if (result) {
      return getVideoContent(result, resolvers[i]);
    }
  }
  return undefined;
};
