import { Dispatch } from "react";
import { FeedFiltersAction } from "./feed.reducer";

export const SET_FEED_FILTER = "FEED.SET_FEED_FILTER";

export const setFeedFilters = (filters: any) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_FEED_FILTER,
    data: { filters }
  } as FeedFiltersAction);
};
