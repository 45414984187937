import { IonPage, IonSegment, IonSegmentButton } from "@ionic/react";
import { useAsync } from "@react-hook/async";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";

import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import ClanLoader from "../../components/ClanLoader";
import FooterButtons, {
  PrimaryFooterButton
} from "../../components/FooterButtons";
import Header from "../../components/Headers/Header";
import SelectableListItem from "../../components/ListItem/SelectableListItem";
import AccessCodeModal from "../../components/Modals/AccessCodeModal";
import { SectionContent } from "../../components/Section";
import { connect } from "../../data/connect";
import { OauthState } from "../../data/oauth/oauth.state";
import { OauthData } from "../../data/oauth/oauthDataService";
import { showToast } from "../../data/toasts/toasts.actions";
import { isReadyStateLoading } from "../../util/AsyncEffectFilter";
import { joinWithInvitationCode } from "../../util/CommunityJoin";
import {
  getOrganisationDataIterator,
  OrganisationListResult,
  switchOrganisation
} from "../../util/Organisation";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";

const organisationDataIterator = getOrganisationDataIterator();

interface StateProps {
  oauthState: OauthState;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

const OrganisationSwitch: React.FC<StateProps & DispatchProps> = ({
  oauthState,
  dispatchToast
}) => {
  const history = useHistory();
  const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);

  const [organisations, setOrganisations] = useState<
    Array<OrganisationListResult>
  >([]);

  const [hasMoreOrganisations, setHasMoreOrganisations] = useState<boolean>(
    false
  );

  const [selectOrganisationStatus, selectOrganisation] = useAsync(
    async (id: number) => {
      if (!oauthState.hasToken || !id) return;
      try {
        await switchOrganisation(oauthState as OauthData, id);
        dispatchToast(t("nest_selection.success_toast"));
        history.goBack();
      } catch (e) {
        dispatchToast(
          t("TODO: translate this- can not switch to this organisation!")
        );
      }
    }
  );

  const [loadMoreState, loadMore] = useAsync(async () => {
    const result = await organisationDataIterator.getNext();
    setOrganisations([...organisations, ...result]);
    organisationDataIterator.hasNext().then(setHasMoreOrganisations);
  });

  const [initStatus, initOrganisations] = useAsync(async () => {
    await organisationDataIterator.reset();
    setOrganisations([]);
    await loadMore();
  });

  const [joinStatus, joinWithCode] = useAsync(async (accessCode: string) => {
    try {
      const result = await joinWithInvitationCode(
        oauthState as OauthData,
        accessCode
      );
      initOrganisations();
      setShowAccessCodeModal(false);
      dispatchToast(
        t("access_code_modal.success_toast", { label: result.name })
      );
    } catch (e) {
      dispatchToast(t("access_code_modal.error_toast"));
    }
  });

  useEffect(() => {
    initOrganisations();
    setAnalyticsScreenName("NESTS");
    analyticsLogEvent("NESTS_show");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  return (
    <ClanDesktopLayout>
      <IonPage>
        {isReadyStateLoading(
          joinStatus,
          initStatus,
          loadMoreState,
          selectOrganisationStatus
        ) && <ClanLoader showLoading={true} />}

        {showAccessCodeModal && (
          <AccessCodeModal
            disabled={isReadyStateLoading(joinStatus)}
            onSubmit={joinWithCode}
            onClose={() => setShowAccessCodeModal(false)}
          />
        )}

        <Header
          title={t("nest_selection.title")}
          onBack={() => history.goBack()}
        />

        <ClanIonContent>
          <SectionContent>
            {organisations.map(({ id, name, current }) => (
              <SelectableListItem
                key={id}
                selected={current}
                onClick={() => {
                  analyticsLogEvent("SELECT_NEST_CLICK");
                  !current && selectOrganisation(id);
                }}
              >
                {name}
              </SelectableListItem>
            ))}
          </SectionContent>

          {hasMoreOrganisations && (
            <IonSegment>
              <IonSegmentButton
                className="font-extrabold font-gilroy text-clanGreen-100"
                onClick={loadMore}
              >
                {t("general.list_load_more")}
              </IonSegmentButton>
            </IonSegment>
          )}
        </ClanIonContent>
        <FooterButtons>
          <PrimaryFooterButton
            onClick={() => {
              analyticsLogEvent("JOIN_NEST_CLICK");
              setShowAccessCodeModal(true);
            }}
          >
            {t("nest_selection.join")}
          </PrimaryFooterButton>
        </FooterButtons>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    oauthState: state.token
  }),
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: OrganisationSwitch
});
