import { IonPage } from "@ionic/react";
import { useAsyncEffect } from "@react-hook/async";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";

import { analyticsLogEvent } from "../../Analytics";
import ActionCard from "../../components/ActionCard";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import { SmoothRays } from "../../components/ClanToolbarWithRays";
import StaffSearchModal from "../../components/Modals/StaffSearchModal";
import {
  LargeSectionHeading,
  MediumSectionHeading,
  Paragraph,
  SectionContent
} from "../../components/Section";
import SubPageHeader from "../../components/SubPageHeader";
import * as ROUTES from "../../routes";
import { pingTeacher } from "../../util/api/DirectMessageService";
import { getPaginatedProfiles } from "../../util/api/ProfileService";
import { isReadyStateCancelOrFail } from "../../util/AsyncEffectFilter";
import { Views } from "../../util/Configuration";
import { useViewFilterResult } from "../../util/Filters";
import { ClanMembershipListItemResponse } from "../../util/models/ClanProfileModels";
import { PaginatedStreamDataIterator } from "../../util/ResultDataIterator";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { ReactComponent as WellbeingYellowLargeIcon } from "../../icons/wellbeing-yellow-large.svg";

const QuickHelpPage: React.FC<RouteComponentProps> = () => {
  const [staffSelection, setStaffSelection] = useState<
    Array<ClanMembershipListItemResponse>
  >([]);

  const [staffSelectionOpen, setStaffSelectionOpen] = useState(false);
  const [supportListApi, setSupportListApi] = useState<
    PaginatedStreamDataIterator<ClanMembershipListItemResponse> | undefined
  >(undefined);

  const resolvedFilters = useViewFilterResult(undefined, Views.SupportList);

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (resolvedFilters && resolvedFilters.defaultFilter) {
      setSupportListApi(
        getPaginatedProfiles(Views.SupportList, [resolvedFilters.defaultFilter])
      );
    } else {
      setSupportListApi(undefined);
    }
  }, [resolvedFilters]);

  useAsyncEffect(async () => {
    try {
      if (supportListApi === undefined || !staffSelectionOpen) {
        return;
      }
      await supportListApi.reset();
      const result = await supportListApi.getNext();
      setStaffSelection(result);
    } catch (error) {
      console.error("Error while fetching staff!", error);
    }
  }, [supportListApi, staffSelectionOpen]);

  const fetchMoreStatus = useAsyncEffect(async () => {
    try {
      if (supportListApi === undefined || staffSelection.length === 0) {
        return;
      }
      const hasNext = await supportListApi.hasNext();
      if (!hasNext || isReadyStateCancelOrFail(fetchMoreStatus)) {
        return;
      }
      const next = await supportListApi.getNext();
      if (isReadyStateCancelOrFail(fetchMoreStatus)) return;
      setStaffSelection([...staffSelection, ...next]);
    } catch (error) {
      console.error("Error while fetching more staff!.", error);
    }
  }, [staffSelection]);

  const onClose = () => history.goBack();

  const onCounsellorSelected = (
    selectedMember: ClanMembershipListItemResponse
  ) => {
    const { profileId } = selectedMember;

    if (profileId) {
      pingTeacher(profileId).finally(() => {
        history.push(
          ROUTES.DIRECT_MESSAGE.replace(":profile_id", profileId.toString())
        );
      });
    }
  };

  const closeStaffSelection = () => setStaffSelectionOpen(false);

  const isEstonian = i18n.language === "et";
  const isRussian = i18n.language === "ru";

  const getCopingStrategiesLink = () => {
    switch (i18n.language) {
      case "et":
        return "https://peaasi.ee/";
      case "ru":
        return "https://peaasi.ee/ru/";
      default:
        return "https://www.clanbeat.com/worry-and-anxiety-techniques-for-students/";
    }
  };

  return (
    <ClanDesktopLayout>
      {staffSelectionOpen && (
        <StaffSearchModal
          title={t("quick_help.modal_title")}
          description={t("quick_help.modal_description")}
          staffList={staffSelection}
          onStaffClick={onCounsellorSelected}
          onClose={closeStaffSelection}
        />
      )}

      <IonPage>
        <ClanIonContent>
          <SmoothRays />

          <SubPageHeader title={t("quick_help.title")} onBack={onClose} />

          <LargeSectionHeading>
            {t("quick_help.hello_friend")}
          </LargeSectionHeading>
          <SectionContent>
            <Paragraph>{t("quick_help.virtual_hug")}</Paragraph>
            <Paragraph>{t("quick_help.get_in_touch")}</Paragraph>

            <ActionCard
              description={t("quick_help.talk_to_counsellor")}
              buttonLabel={t("quick_help.contact_title")}
              icon={<WellbeingYellowLargeIcon />}
              onClick={() => {
                analyticsLogEvent("QUICK_HELP_COUNSELLOR_CLICKED");
                setStaffSelectionOpen(true);
              }}
            />

            {(isEstonian || isRussian) && (
              <ActionCard
                description={t("quick_help.outside_school")}
                buttonLabel={t("quick_help.get_help_here")}
                icon={<WellbeingYellowLargeIcon />}
                onClick={() => {
                  analyticsLogEvent("QUICK_HELP_OUTSIDE_SCHOOL_CLICKED");
                }}
                href="https://www.lasteabi.ee/"
              />
            )}
          </SectionContent>

          <MediumSectionHeading>
            {t("quick_help.not_comfortable")}
          </MediumSectionHeading>
          <SectionContent>
            <Paragraph>{t("quick_help.strategies_methods")}</Paragraph>

            <ActionCard
              description={t("quick_help.coping_strategies")}
              buttonLabel={t("quick_help.learn")}
              icon={<WellbeingYellowLargeIcon />}
              onClick={() => {
                analyticsLogEvent("QUICK_HELP_LEARN_CLICKED");
              }}
              href={getCopingStrategiesLink()}
            />
          </SectionContent>
        </ClanIonContent>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default QuickHelpPage;
