import styled from "styled-components";

interface IconProps {
  size?: string;
  width?: string;
  height?: string;
  onClick?: any;
}

const Icon = styled.img<IconProps>`
  ${({ width, size }) => (width || size) && `width: ${width || size};`}
  ${({ height, size }) => (height || size) && `height: ${height || size};`}
  ${({ onClick }) => !!onClick && "cursor: pointer;"}
`;

export default Icon;
