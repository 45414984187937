import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import React from "react";

interface ClanFooterBtnProps {
  buttonObjects: {
    title: string;
    disabled?: boolean;
    callback?: any;
  }[];
}

const ClanFooterBtn: React.FC<ClanFooterBtnProps> = ({ buttonObjects }) => {
  //TODO: Refactor to not allow more than 2 buttons with second as primary
  // const slicedBtnObjs =
  //   buttonObjects.length !== 0 ? buttonObjects.slice(1) : [];
  return buttonObjects ? (
    <IonSegment id="clan-footer-btn" className="bg-clanGray-100">
      {buttonObjects?.length !== 0 &&
        buttonObjects?.map((buttonObj, index, buttonObjs) => (
          <IonSegmentButton
            key={index}
            className={`font-extrabold font-gilroy max-w-full ${
              buttonObjs.length % 2 === index
                ? "text-clanGreen-100"
                : "bg-clanYellow-100 text-black"
            }`}
            disabled={buttonObj.disabled}
            onClick={(): void => {
              buttonObj?.callback && buttonObj.callback();
            }}
          >
            <IonLabel>{buttonObj?.title && buttonObj?.title}</IonLabel>
          </IonSegmentButton>
        ))}
    </IonSegment>
  ) : (
    <></>
  );
};

export default ClanFooterBtn;
