import {
  getUserProfileData,
  storeClanProfileResponse
} from "../user/profile/profileDataService";
import {
  getuserImageData,
  storeClanProfileImages
} from "../user/image/imageDataService";
import { getSetUserProfileActionPayload } from "../user/profile/profile.actions";
import { getSetProfileImageActionPayload } from "../user/image/image.actions";
import { ClanProfileResponse } from "../../util/models/ClanProfileModels";
import { getAddAppErrorActionPayload } from "../app/app.actions";
import React from "react";
import {
  ApiResponseErrorType,
  AuthorizedApiResult
} from "../../util/ApiService";
import { ImageState } from "../user/image/image.state";
import { UserProfileState } from "../user/profile/profile.state";
import { ClanImages } from "../../util/models/ClanImageModels";

const getUserProfileState = async (
  dispatch: React.Dispatch<any>
): Promise<{
  profile: UserProfileState;
  images: ClanImages | undefined;
}> => {
  const userProfileData = await getUserProfileData();
  if (userProfileData.isCached) {
    return { profile: userProfileData, images: undefined };
  }
  try {
    const profileResponse = await AuthorizedApiResult.get<ClanProfileResponse>(
      "/user/profile/v1"
    );
    const result = await storeClanProfileResponse(profileResponse);
    return { profile: result, images: profileResponse.images };
  } catch (error) {
    if (error.type !== ApiResponseErrorType.HTTP_NOT_FOUND) {
      if (error?.response?.message) {
        dispatch(getAddAppErrorActionPayload(error.response.message));
      } else {
        dispatch(getAddAppErrorActionPayload(error));
      }
    }
    return {
      profile: {
        isCached: false,
        isLocked: true,
        isLoaded: true
      },
      images: undefined
    };
  }
};

const getUserProfileImage = async (
  dispatch: React.Dispatch<any>
): Promise<{ profile: UserProfileState; image: ImageState }> => {
  const profileAndImages = await getUserProfileState(dispatch);
  if (profileAndImages.images) {
    const imageState = await storeClanProfileImages(profileAndImages.images);
    return { profile: profileAndImages.profile, image: imageState };
  }
  const userImageData = await getuserImageData();
  return { profile: profileAndImages.profile, image: userImageData };
};

export const fetchAndDispatchProfile = async (
  dispatch: React.Dispatch<any>,
  isSignedIn: boolean
) => {
  if (!isSignedIn) {
    await dispatch(
      getSetProfileImageActionPayload({
        isCached: false
      })
    );
    await dispatch(
      getSetUserProfileActionPayload({
        isCached: false,
        isLocked: true,
        isLoaded: false
      })
    );
    return;
  }
  const { profile, image } = await getUserProfileImage(dispatch);
  await dispatch(getSetProfileImageActionPayload(image));
  await dispatch(getSetUserProfileActionPayload(profile));
};
