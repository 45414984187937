import { IonPopover, IonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { connect } from "../data/connect";
import { removeToast } from "../data/toasts/toasts.actions";
import { Toast } from "../data/toasts/toasts.state";

import { ReactComponent as LlamaIcon } from "../icons/llama.svg";

const ImageWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column-start: 2;
`;

const StyledButton = styled.a<{ primary?: boolean }>`
  background: ${({ primary, theme }) => (primary ? theme.primary : "#FFFFFF")};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 25px;
  color: black;
  font-family: Gilroy;
  text-decoration: none;
  padding: 0.75rem 1rem;
  min-width: 8rem;
  display: inline-block;
  font-weight: bold;
  margin-right: 0.75rem;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

const CloseButton = styled.div`
  font-family: Gilroy;
  font-weight: bold;
  font-size: 14px;
  color: black;
  margin-top: 0.5rem;
  padding: 0.5rem;
  justify-self: end;
  position: absolute;
  right: 10px;
  cursor: pointer;
`;

const StyledImg = styled(LlamaIcon)`
  max-width: 14rem !important;
  min-width: 10rem;
  width: 42vw;
`;

interface StateProps {
  toasts: Array<Toast>;
}

interface DispatchProps {
  onToastDismiss: Function;
}

const ClanToastManager: React.FC<StateProps & DispatchProps> = ({
  toasts,
  onToastDismiss
}) => {
  const [activeToast, setActiveToast] = useState<Toast | null>(toasts[0]);
  const { t } = useTranslation();

  useEffect(() => {
    activateToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toasts]);

  function activateToast() {
    if (!activeToast && toasts.length > 0) {
      setActiveToast(toasts[0]);
    }
  }

  function cleanupActiveToast() {
    setActiveToast(null);
    onToastDismiss(activeToast);
  }

  if (!activeToast) return null;

  return (
    <>
      {activeToast.type === "toast" ? (
        <IonToast
          isOpen
          message={activeToast.message}
          position="bottom"
          duration={activeToast.duration}
          mode="ios"
          color={
            activeToast.message?.toLowerCase().startsWith("error")
              ? "danger"
              : "medium"
          }
          onDidDismiss={cleanupActiveToast}
          buttons={[
            {
              text: t("general.close"),
              role: "cancel"
            }
          ]}
        />
      ) : (
        <IonPopover
          onDidDismiss={cleanupActiveToast}
          cssClass="info-card"
          mode="ios"
          animated
          isOpen
        >
          <ImageWrapper>
            <StyledImg />
            <p className="text-center">{activeToast.message}</p>
            {activeToast.buttons && (
              <div className="text-center">
                {activeToast.buttons.map(
                  ({ label, primary, callback, href }) => (
                    <StyledButton
                      key={label}
                      primary={primary}
                      onClick={() => {
                        callback && callback();
                        cleanupActiveToast();
                      }}
                      href={href}
                      target="_blank"
                    >
                      {label}
                    </StyledButton>
                    // <IonButton
                    //   className={`font-extrabold font-gilroy ${
                    //     button.primary
                    //       ? "border-2 border-clanYellow-100 border-solid text-clanH4"
                    //       : "text-clanGray-200 text-clanH5"
                    //   } rounded-full text-black`}
                    //   style={{ "--border-radius": "9999px" }}
                    //   color={button.primary ? "primary" : "none"}
                    //   onClick={() => {
                    //     button.callback && button.callback();
                    //     cleanupActiveToast();
                    //   }}
                    //   href={button.href}
                    //   target="_blank"
                    // >
                    //   {button.label}
                    // </IonButton>
                  )
                )}
              </div>
            )}
          </ImageWrapper>
          <CloseButton onClick={cleanupActiveToast}>
            {activeToast.label}
          </CloseButton>
        </IonPopover>
      )}
    </>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    toasts: state.toasts.toasts
  }),
  mapDispatchToProps: {
    onToastDismiss: removeToast
  },
  component: ClanToastManager
});
