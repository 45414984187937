import { IonTextarea } from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ClanCameraAccess from "../../util/ClanCameraAccess";
import { ClanEditPostEditDetails } from "../../util/models/ClanPostModels";
import { ActionButton } from "../Buttons/Button";
import ClanBottomModal from "../ClanBottomModal";
import ImageButton from "../Planner/ImageButton";
import MoodButton from "../Planner/MoodButton";
import MoodModal from "../Planner/MoodModal";
import SelectButton from "../SelectButton";
import { PostFont } from "../Typography";

const ShareSection = styled.div`
  padding: 16px 24px;
  background: #fafafa;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
`;

const ShareLabel = styled.div``;

const StyledTextArea = styled(IonTextarea)`
  ${PostFont}
  --padding-top: 0px;
  max-height: calc(80vh - 40px);
  margin: 24px;
  width: calc(100% - 48px);
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 10px;
  border-top: 1px solid ${({ theme }) => theme.primary};
`;

const Spacer = styled.div`
  flex: 1;
`;

interface PostModalProps {
  post?: ClanEditPostEditDetails;
  textPlaceholder: string;
  shareLabel?: string;
  submitLabel?: string;
  disabled?: boolean;
  onShareClicked?: () => void;
  onPostChange: (newPost: ClanEditPostEditDetails) => void;
  onSubmit: () => void;
  onClose: () => void;
}

const PostModal: React.FC<PostModalProps> = ({
  post,
  shareLabel,
  submitLabel,
  onShareClicked,
  textPlaceholder,
  disabled,
  onPostChange,
  onSubmit,
  onClose
}) => {
  const [moodModalOpen, setMoodModalOpen] = useState(false);

  const { t } = useTranslation();

  const onTextChange = (e: any) =>
    onPostChange({ ...post, text: e.detail.value || "" });

  const onDismissMoodModal = () => setMoodModalOpen(false);
  const onOpenMoodModal = () => setMoodModalOpen(true);

  const onMoodSelect = (newMood: string) => {
    onPostChange({ ...post, mood: newMood });
    setMoodModalOpen(false);
  };

  const onClearMood = () => onPostChange({ ...post, mood: undefined });

  const onTakePhoto = () =>
    ClanCameraAccess.TakePhoto()
      .then((result) => onPostChange({ ...post, image: result.image }))
      .catch((e) => console.log(e));

  const onClearPhoto = () => onPostChange({ ...post, image: undefined });

  const { text, mood, image } = post || {};

  return (
    <>
      {moodModalOpen && (
        <MoodModal
          isOpen={true}
          onDismiss={onDismissMoodModal}
          onMoodSelect={onMoodSelect}
        />
      )}

      <ClanBottomModal isOpen={true} onDidDismiss={onClose}>
        {onShareClicked && (
          <ShareSection>
            <ShareLabel>{t("post_modal.share_with")}</ShareLabel>
            <SelectButton bold onClick={onShareClicked}>
              {shareLabel}
            </SelectButton>
          </ShareSection>
        )}

        <StyledTextArea
          autoGrow
          maxlength={2000}
          rows={5}
          placeholder={textPlaceholder}
          value={text || ""}
          autocapitalize="sentences"
          onIonChange={onTextChange}
        />

        <FooterWrapper>
          <MoodButton
            mood={mood}
            onClick={onOpenMoodModal}
            onClear={onClearMood}
          />
          <ImageButton
            image={image}
            onClick={onTakePhoto}
            onClear={onClearPhoto}
          />
          <Spacer />
          <ActionButton disabled={disabled} onClick={onSubmit}>
            {submitLabel || t("general.share")}
          </ActionButton>
        </FooterWrapper>
      </ClanBottomModal>
    </>
  );
};

export default PostModal;
