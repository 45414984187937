import { ISOLanguages, OauthState } from "./oauth.state";
import { isAfter } from "date-fns";
import {
  ClanOauthResponse,
  ClanOauthResponseOrganisationTheme
} from "../../util/models/ClanOauthResponse";
import { hasValue, Optional, valuesOf } from "../../util/NullSafe";
import { Storage } from "../../util/storage/DataStorage";
import { clearCachedData } from "../../util/CachedData";
import { BlockingPromiseQueue } from "../../util/BlockingPromiseQueue";

const CB_OAUTH_DATA = "oauth";
const CB_REFRESH_TOKEN = "rt";
const SCOPE_FEATURE = /^(?:(GROUP|ORGANISATION)\.(\d+)\.)?FEATURE_([\w\\-]+)$/;
const SCOPE_ROLE = /^(?:(GROUP|ORGANISATION)\.(\d+)\.)?ROLE_(\w+)$/;
// noinspection RegExpUnnecessaryNonCapturingGroup
const SCOPE_LANGUAGE = /^(?:LANGUAGE\.ISO)(\d+)\.(\w+)$/;
// noinspection RegExpUnnecessaryNonCapturingGroup
const ENABLED_PROPS = /^(?:ENABLED_)([\w\\-]+)$/;
const CONTEXT_ORGANISATION_NAME = /^CONTEXT\.ORG\.NAME_(.+)$/;

const DEFAULT_LANGUAGES = {
  iso2: "en",
  iso3: "eng"
} as ISOLanguages;

const queue = new BlockingPromiseQueue();

interface LegacyApiJsonToken {
  aud: "clanbeat-app";
  iss: "clanbeat-api";
  iat: number; // issued at
  exp: number; // expires at
  sub: string; // hash of user, account and profile
  uid: string; // comma separated list of user id
  sid: string; // string of session Id
  oid: number; //organisation ID
}

interface ApiV2JsonToken {
  aud: "clanbeat-app";
  iss: "clanbeat-api-2";
  iat: number; // issued at
  exp: number; // expires at
  sub: "tk" | "rt"; // if is token or refresh token
  u_aid: number; // user account id
  u_uid: number; // user id,
  u_pid?: number; // profiled id
  uid: string; // deprecated
  u_sid: string; // still old session id,
  u_oid: number; // organisation ID
}

interface APIv3JsonToken {
  aud: "clanbeat-app";
  iss: "clanbeat-api-3";
  iat: number; // issued at
  exp: number; // expires at
  s_exp: number; // the other expiration time; in case of refresh token then token expiration otherwise refresh token expiration
  sub: "tk" | "rt"; // if is token or refresh token
  u_aid: string; // user account id
  u_uid: string; // user id,
  s_tid?: string; // sesson token ID
  uid: string; // deprecated
  u_sid: string; // UUID of session id,

  u_org?: { id: string; l: string }; // organisation definition
  u_prf?: { id: string; l: string }; // profile definition
}

const cleanUpStorage = async () => {
  clearCachedData();
  await Storage.remove({ key: CB_OAUTH_DATA });
  await Storage.remove({ key: CB_REFRESH_TOKEN });
};

const setStorage = async (token: OauthData) => {
  clearCachedData();
  await Storage.set({
    key: CB_OAUTH_DATA,
    value: JSON.stringify([
      5, // version for future if we need to store more
      token.token,
      token.refreshToken,
      token.type,
      token.refreshTimestamp,
      token.roles,
      token.features,
      token.languages,
      token.enabledProperties,
      token.contextName,
      token.theme
    ])
  });
  await Storage.setUnsafe({
    key: CB_REFRESH_TOKEN,
    value: token.refreshToken
  });
};

const solveScopeRegexpToArray = (
  array: string[],
  regexp: RegExpExecArray | null
) => {
  Optional.ofNullable(regexp)
    .filter((v) => v.length > 0)
    .map((v) => {
      v.shift();
      return v;
    })
    .filter((v) => v.length > 0)
    .map((v) => {
      return v.filter(hasValue).join(".");
    })
    .filter((v) => v !== "")
    .ifPresent((v) => array.push(v));
};

const getFeaturesAndRolesFromScope = (
  scopes: string[]
): [string[], string[], string[], ISOLanguages, string | undefined] => {
  const roles: string[] = [];
  const features: string[] = [];
  const enabledProps: string[] = [];
  let contextOrganisation: string | undefined = undefined;
  const languages = {
    iso2: "n/a",
    iso3: "n/a"
  } as ISOLanguages;
  scopes.forEach((scope) => {
    solveScopeRegexpToArray(roles, SCOPE_ROLE.exec(scope));
    solveScopeRegexpToArray(features, SCOPE_FEATURE.exec(scope));
    solveScopeRegexpToArray(enabledProps, ENABLED_PROPS.exec(scope));
    const languageResult = SCOPE_LANGUAGE.exec(scope);
    const orgContext = CONTEXT_ORGANISATION_NAME.exec(scope);
    if (orgContext) {
      try {
        contextOrganisation = orgContext[1];
      } catch (e) {
        console.error("Could not resolve context organisation", e);
      }
    }
    if (
      languageResult &&
      languageResult.length >= 3 &&
      languageResult[1] &&
      languageResult[2]
    ) {
      if (languageResult[1] === "3") {
        languages.iso3 = languageResult[2].toLowerCase();
      } else if (languageResult[1] === "2") {
        languages.iso2 = languageResult[2].toLowerCase();
      }
    }
  });
  return [
    roles,
    features,
    enabledProps,
    languages.iso2 !== "n/a" && languages.iso3 !== "n/a" // Both should be provided
      ? languages
      : DEFAULT_LANGUAGES,
    contextOrganisation
  ];
};

export type OauthData = {
  roles: string[];
  features: string[];
  token: string;
  type: string;
  refreshToken: string;
  isTokenExpired: (reduceSeconds?: number) => boolean;
  enabledProperties: string[];
  hasPropertyEnabled: (property: string) => boolean;
  refreshTimestamp: string;
  userId: number;
  accountId: number;
  profileId?: number;
  contextName: string;
  theme?: ClanOauthResponseOrganisationTheme;
} & OauthState;

interface JsonTokenResult {
  userId: string;
  accountId: string;
  profileId?: string;
  organisation?: { id: string; name: string };
  issued: Date; // TODO: this must be used in future
  expires: Date; // TODO: this must be used in future
}

const resolveUserData = (tokenStr: string): JsonTokenResult => {
  const base64Url = tokenStr.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const token:
    | LegacyApiJsonToken
    | ApiV2JsonToken
    | APIv3JsonToken = JSON.parse(jsonPayload);
  const issuedAt = new Date(token.iat * 1000);
  const expiresAt = new Date(token.exp * 1000);
  if (token.iss === "clanbeat-api" || token.iss === "clanbeat-api-2") {
    // legacy support
    const [accountId, userId, profileId] = token.uid.split(";");
    return {
      userId,
      accountId,
      profileId,
      organisation: undefined, // the old token has short lifecycle- we do not care about it
      issued: issuedAt,
      expires: expiresAt
    };
  }
  return {
    userId: token.u_uid,
    accountId: token.u_aid,
    profileId: token.u_prf?.id,
    organisation: token.u_org
      ? { id: token.u_org.id, name: token.u_org.l }
      : undefined,
    issued: issuedAt,
    expires: expiresAt
  };
};

const castToOauthData = (
  token?: string,
  refreshToken?: string,
  tokenType?: string,
  tokenExpiryTimestamp?: string,
  roles?: string[],
  features?: string[],
  languages?: ISOLanguages,
  enabledProps?: string[],
  contextOrganisation?: string,
  theme?: ClanOauthResponseOrganisationTheme
): OauthData | OauthState => {
  const languagesProvided = languages || DEFAULT_LANGUAGES;
  if (!token || !refreshToken || !tokenType || !tokenExpiryTimestamp) {
    return {
      hasToken: false,
      languages: languagesProvided
    } as OauthState;
  }
  const tokenData = resolveUserData(token);
  return {
    hasToken: true,
    type: tokenType,
    token,
    theme,
    refreshToken,
    roles,
    features,
    languages: languagesProvided,
    accountId: parseInt(tokenData.accountId),
    userId: parseInt(tokenData.userId),
    profileId: tokenData.profileId ? parseInt(tokenData.profileId) : undefined,
    refreshTimestamp: tokenExpiryTimestamp,
    isTokenExpired: (reduceSeconds = 30) => {
      const expiredDate = new Date(tokenExpiryTimestamp);
      expiredDate.setSeconds(expiredDate.getSeconds() - reduceSeconds);
      return isAfter(new Date(), expiredDate);
    },
    hasFeature: (feature: string) => {
      if (!features) return false;
      return features.find((r) => r === feature) !== undefined;
    },
    hasFeatureInGroup: (feature: string, groupId: number) => {
      if (!features) return false;
      const featureValue = `GROUP.${groupId}.${feature}`;
      return features.find((r) => r === featureValue) !== undefined;
    },
    hasFeatureInOrganisation: (feature: string, organisationId: number) => {
      if (!features) return false;
      const featureValue = `ORGANISATION.${organisationId}.${feature}`;
      return features.find((r) => r === featureValue) !== undefined;
    },
    hasRole: (role: string) => {
      if (!roles) return false;
      return roles.find((r) => r === role) !== undefined;
    },
    hasRoleInGroup: (role: string, groupId: number) => {
      if (!roles) return false;
      const roleValue = `GROUP.${groupId}.${role}`;
      return roles.find((r) => r === roleValue) !== undefined;
    },
    hasRoleInOrganisation: (role: string, organisationId: number) => {
      if (!roles) return false;
      const roleValue = `ORGANISATION.${organisationId}.${role}`;
      return roles.find((r) => r === roleValue) !== undefined;
    },
    enabledProperties: enabledProps,
    hasPropertyEnabled: (property: string) => {
      if (!enabledProps) return false;
      return enabledProps.find((r) => r === property) !== undefined;
    },
    contextName: tokenData.organisation?.name
      ? tokenData.organisation?.name
      : contextOrganisation
  } as OauthData;
};

export const removeOauthData = async (): Promise<OauthState> => {
  return queue.blockAndRun<OauthState>(async () => {
    await cleanUpStorage();
    return {
      hasToken: false,
      languages: DEFAULT_LANGUAGES
    } as OauthState;
  });
};

export const cacheOauthData = async (token: OauthData | OauthState) => {
  return queue.blockAndRun(async () => {
    if (token.hasToken) {
      clearCachedData();
      await setStorage(token as OauthData);
    } else {
      await cleanUpStorage();
    }
  });
};

export const setClanOauthResponse = async (
  token: ClanOauthResponse
): Promise<OauthData | OauthState> => {
  const refreshTs = token.refresh_ts.endsWith("Z")
    ? token.refresh_ts
    : `${token.refresh_ts}Z`;

  const [
    roles,
    features,
    enabledProps,
    languages,
    contextOrganisation
  ] = getFeaturesAndRolesFromScope(valuesOf(token.scope));

  const oauthData = castToOauthData(
    token.token,
    token.refreshToken,
    token.token_type,
    refreshTs,
    roles,
    features,
    languages,
    enabledProps,
    contextOrganisation,
    token.theme
  );
  await cacheOauthData(oauthData);
  return oauthData;
};

export const getRefreshToken = async (): Promise<string | undefined> =>
  queue.blockAndRun(async () =>
    Storage.get({ key: CB_REFRESH_TOKEN }).then((e) =>
      e?.value ? e?.value : undefined
    )
  );

export const getOauthData = async (): Promise<OauthData | OauthState> => {
  const storageResultRaw = await queue.blockAndRun(async () =>
    Storage.get({ key: CB_OAUTH_DATA })
  );
  if (!storageResultRaw.value) {
    return {
      hasToken: false,
      languages: DEFAULT_LANGUAGES
    } as OauthState;
  }
  const storageResult = JSON.parse(storageResultRaw.value);

  if (storageResult[0] === 0) {
    return castToOauthData(
      storageResult[1],
      storageResult[2],
      storageResult[3],
      storageResult[4]
    );
  } else if (storageResult[0] === 1) {
    return castToOauthData(
      storageResult[1],
      storageResult[2],
      storageResult[3],
      storageResult[4],
      storageResult[5],
      storageResult[6]
    );
  } else if (storageResult[0] === 2) {
    return castToOauthData(
      storageResult[1],
      storageResult[2],
      storageResult[3],
      storageResult[4],
      storageResult[5],
      storageResult[6],
      storageResult[7]
    );
  } else if (storageResult[0] === 3) {
    return castToOauthData(
      storageResult[1],
      storageResult[2],
      storageResult[3],
      storageResult[4],
      storageResult[5],
      storageResult[6],
      storageResult[7],
      storageResult[8]
    );
  } else if (storageResult[0] === 4) {
    return castToOauthData(
      storageResult[1],
      storageResult[2],
      storageResult[3],
      storageResult[4],
      storageResult[5],
      storageResult[6],
      storageResult[7],
      storageResult[8],
      storageResult[9]
    );
  } else {
    return castToOauthData(
      storageResult[1],
      storageResult[2],
      storageResult[3],
      storageResult[4],
      storageResult[5],
      storageResult[6],
      storageResult[7],
      storageResult[8],
      storageResult[9],
      storageResult[10]
    );
  }
};

export const isSameProfile = (
  oauthState: OauthState,
  profileIdToCompare: number
) => {
  if (!oauthState || !oauthState.hasToken) return false;
  return profileIdToCompare === (oauthState as OauthData).profileId;
};
