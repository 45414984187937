import {
  IonCol,
  IonContent,
  IonIcon,
  IonInput,
  IonRow,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import {
  getCountries,
  parsePhoneNumberFromString
} from "libphonenumber-js/mobile";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PrimaryButton } from "../../components/Buttons/Button";
import { SmoothRaysInverted } from "../../components/ClanSmoothRays";
import FooterButtons, { FooterButton } from "../../components/FooterButtons";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import {
  authenticateWithFirebaseEmail,
  EmailAuthenticationType,
  getFirebaseIdToken
} from "../../util/GoogleFirebase";
import RollbarManager from "../../util/RollbarManager";
import {
  BackButton,
  LocationContext,
  MethodTypes,
  StartContentWrapper,
  StartDescription,
  StartTitle,
  StartTitleWrapper,
  StartToolbar,
  StartWrapper
} from "./Start";
import { makeCountryList } from "./StartHelper";

const Divider = styled.div`
  width: 100%;
  position: relative;
  margin: 20px 0;

  div {
    background: white;
    text-align: center;
    margin: 0 auto;
    font-size: 18px;
    font-family: Gilroy;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    line-height: 50px;
    position: relative;
  }

  &::before {
    content: "";
    display: block;
    border-bottom: 1px solid white;
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
  }
`;

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface OwnProps {
  firebaseSendSMS: (number: string) => Promise<void>;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  mode: MethodTypes;
  resetStartState: () => void;
  onUserSignupRequired: (selectedMode: MethodTypes) => void;
}

interface MethodsProps extends OwnProps, DispatchProps {}

const COUNTRIES = makeCountryList(getCountries());

const Methods: React.FC<MethodsProps> = ({
  firebaseSendSMS,
  dispatchToast,
  loading,
  setLoading,
  mode,
  resetStartState,
  onUserSignupRequired
}) => {
  const {
    getValues,
    setValue,
    trigger,
    errors,
    watch,
    control
  } = useFormContext();
  const [inputMode, setInputMode] = useState<boolean>(
    mode === MethodTypes.LOGIN
  );

  const watchNumber = watch("number");
  const watchEmail = watch("email");
  const { t } = useTranslation();
  const currentCountryInfo = useContext(LocationContext);

  useEffect(() => {
    setInputMode(mode === MethodTypes.LOGIN);
  }, [mode]);

  useEffect(() => {
    setValue(
      "countryCode",
      `${currentCountryInfo.callingCode}:${currentCountryInfo.countryCode}`
    );
  }, [currentCountryInfo, setValue]);

  function getTitle() {
    if (mode !== MethodTypes.LOGIN) {
      if (inputMode) {
        return t("start.methods.new_account_title");
      } else if (mode === MethodTypes.TEACHER) {
        return t("start.methods.signup_teacher");
      } else if (mode === MethodTypes.STUDENT) {
        return t("start.methods.signup_student");
      }
    } else {
      return t("start.methods.login_title");
    }
  }

  function getSubtitle() {
    if (mode !== MethodTypes.LOGIN) {
      if (inputMode) {
        if (mode === MethodTypes.TEACHER) {
          return t("start.methods.teacher_signup_description");
        } else if (mode === MethodTypes.STUDENT) {
          return t("start.methods.student_signup_description");
        }
      } else {
        return (
          <>
            <p>{t("start.methods.signup_subtitle_1")}</p>
            <p>{t("start.methods.signup_subtitle_3")}</p>
          </>
        );
      }
    }
  }

  const onSubmit = async () => {
    if (
      await trigger([
        "number",
        "countryCode",
        ...(mode === MethodTypes.TEACHER ? ["email"] : [])
      ])
    ) {
      const fullNumber = `${getValues("countryCode").split(":")[0]}${getValues(
        "number"
      )}`;
      const fullNumberParser = parsePhoneNumberFromString(fullNumber);
      if (fullNumberParser?.isPossible()) {
        console.debug(`[START_PAGE] Full number is ${fullNumber}`);
        const firebaseToken = await getFirebaseIdToken();
        if (firebaseToken && mode === MethodTypes.TEACHER) {
          onUserSignupRequired(mode);
        } else {
          await firebaseSendSMS(fullNumber);
        }
      } else {
        dispatchToast(t("start.methods.number_not_exist"));
      }
    } else {
      dispatchToast(
        `Error - ${Object.keys(errors).reduce((allErrors, key) => {
          return allErrors.concat(errors[key].message);
        }, "")}`
      );
    }
  };

  const signInUserWithEmail = async (type: EmailAuthenticationType) => {
    setLoading(true);
    try {
      await authenticateWithFirebaseEmail(type);
    } catch (error) {
      RollbarManager.logError(error as Error);
      dispatchToast(t("start.methods.error_generic"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <IonContent>
        <StartWrapper>
          <StartToolbar>
            <SmoothRaysInverted />
            <BackButton
              src="/assets/arrow_back_black.svg"
              onClick={() => {
                resetStartState();
              }}
            />
          </StartToolbar>

          <StartContentWrapper center={inputMode}>
            <StartTitleWrapper left={inputMode}>
              <StartTitle>{getTitle()}</StartTitle>
              {mode !== MethodTypes.LOGIN && (
                <StartDescription>{getSubtitle()}</StartDescription>
              )}
            </StartTitleWrapper>

            <section>
              {inputMode ? (
                <>
                  {mode === MethodTypes.TEACHER && (
                    <IonRow>
                      <IonCol size="12">
                        <Controller
                          control={control}
                          name="email"
                          render={({ onChange }) => (
                            <IonInput
                              onIonChange={(e) => onChange(e.detail.value)}
                              className="text-clanH2 text-black font-extrabold font-gilroy"
                              placeholder={t("start.methods.email_placeholder")}
                              inputmode="email"
                              type="email"
                              autocomplete="email"
                              required
                            />
                          )}
                          defaultValue={null}
                          rules={{
                            required: {
                              value: mode === MethodTypes.TEACHER,
                              message: t("start.methods.email_required")
                            },
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: t("start.methods.email_format")
                            }
                          }}
                        />
                      </IonCol>
                    </IonRow>
                  )}
                  <IonRow className="mt-6">
                    <IonCol size="4">
                      <Controller
                        name="countryCode"
                        control={control}
                        defaultValue={getValues("countryCode")}
                        render={({ onChange, value }) => (
                          <IonSelect
                            onIonChange={(e) => onChange(e.detail.value)}
                            interface="action-sheet"
                            placeholder={t("start.methods.country_placeholder")}
                            value={value}
                          >
                            {COUNTRIES.map((country) => (
                              <IonSelectOption
                                key={country.countryCode}
                                value={`${country.callingCode}:${country.countryCode}`}
                              >
                                {country.flag} {country.country}{" "}
                                {country.callingCode}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: t("start.methods.country_required")
                          },
                          pattern: {
                            // TODO change regex
                            value: /(\+\d{1,3})/,
                            message: t("start.methods.country_invalid")
                          }
                        }}
                      />
                    </IonCol>
                    <IonCol size="8">
                      <Controller
                        control={control}
                        render={({ onChange }) => (
                          <IonInput
                            onIonChange={(e) => onChange(e.detail.value)}
                            className="number text-clanH2 text-black font-extrabold font-gilroy"
                            placeholder="123456789"
                            inputmode="tel"
                            type="tel"
                          />
                        )}
                        name="number"
                        defaultValue={null}
                        rules={{
                          required: {
                            value: true,
                            message: t("start.methods.number_required")
                          },
                          minLength: {
                            value: 5,
                            message: t("start.methods.min_length")
                          },
                          pattern: {
                            value: /^[0-9\b]+$/,
                            message: t("start.methods.number_format")
                          }
                        }}
                      />
                    </IonCol>
                  </IonRow>
                  {mode === MethodTypes.LOGIN && (
                    <Divider>
                      <div>{t("start.methods.or")}</div>
                    </Divider>
                  )}
                </>
              ) : (
                <PrimaryButton fullWidth onClick={() => setInputMode(true)}>
                  <IonIcon className="pr-4" src={"/assets/phone.svg"} />
                  {t("start.methods.use_phone")}
                </PrimaryButton>
              )}
              {((mode === MethodTypes.LOGIN && inputMode) ||
                (mode !== MethodTypes.LOGIN && !inputMode)) && (
                <>
                  <PrimaryButton
                    fullWidth
                    onClick={() =>
                      signInUserWithEmail(EmailAuthenticationType.GOOGLE)
                    }
                  >
                    <IonIcon className="pr-4" src={"/assets/google.svg"} />
                    {t("start.methods.use_google")}
                  </PrimaryButton>
                  <PrimaryButton
                    fullWidth
                    onClick={() =>
                      signInUserWithEmail(EmailAuthenticationType.MICROSOFT)
                    }
                  >
                    <IonIcon className="pr-4" src={"/assets/microsoft.svg"} />
                    {t("start.methods.use_microsoft")}
                  </PrimaryButton>
                </>
              )}
            </section>
          </StartContentWrapper>
        </StartWrapper>
      </IonContent>
      {inputMode && (
        <FooterButtons>
          <FooterButton
            disabled={
              loading ||
              (mode === MethodTypes.TEACHER
                ? !watchEmail || !watchNumber
                : !watchNumber)
            }
            onClick={onSubmit}
          >
            {t("general.next")}
          </FooterButton>
        </FooterButtons>
      )}
    </>
  );
};

export default connect<OwnProps, DispatchProps>({
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: Methods
});
