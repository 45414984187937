import { Network } from "@capacitor/network";
import { useEffect, useState } from "react";

const useNetworkStatus = () => {
  // Expecting to have a connection when initialized because unconnected triggers might pop up errors
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    (async () => {
      const status = await Network.getStatus();
      setIsConnected(status.connected);
    })();

    Network.addListener("networkStatusChange", (status) => {
      setIsConnected(status.connected);
    });

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  return isConnected;
};

export default useNetworkStatus;
