import React, { useState } from "react";
import { connect } from "../data/connect";
import { OauthData } from "../data/oauth/oauthDataService";
import { FlexRow } from "./Flexbox";
import NestPickerModal from "./Modals/NestPickerModal";
import { ReactComponent as FaviconDownIcon } from "../icons/favicon-down.svg";
import styled from "styled-components";

const FavIconDown = styled(FaviconDownIcon)`
  cursor: pointer;
`;

const NestLabel = styled.span`
  cursor: pointer;
`;

interface StateProps {
  contextName: string;
}
const NestPicker: React.FC<StateProps> = ({ contextName }) => {
  const [nestPickerOpen, setNestPickerOpen] = useState(false);

  const openNestPicker = () => setNestPickerOpen(true);
  const onNestPickerClose = () => setNestPickerOpen(false);

  return (
    <>
      {nestPickerOpen && <NestPickerModal onClose={onNestPickerClose} />}
      <FlexRow onClick={openNestPicker} align="center" columnGap="4px">
        <NestLabel>{contextName}</NestLabel>
        <FavIconDown />
      </FlexRow>
    </>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: ({ token }) => ({
    contextName: (token as OauthData)?.contextName
  }),
  component: NestPicker
});
