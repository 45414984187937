import {
  IonCard,
  IonHeader,
  IonItem,
  IonList,
  IonModal,
  IonSearchbar,
  IonText
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ReactComponent as TeacherBadgeIcon } from "../icons/teacher_badge.svg";
import { ClanIonContent } from "../pages/DiscussionFeed/PublicFeeds";
import * as ROUTES from "../routes";
import { UserEnabledProperties } from "../util/api/UserEnabledPropertiesService";
import { AuthorizedApiResult } from "../util/ApiService";
import { RoleAwareProfileWithImage } from "../util/models/ClanProfileModels";
import ClanProfileAvatar from "./ClanProfileAvatar";
import { SmoothRays } from "./ClanSmoothRays";
import FooterButtons, { PrimaryFooterButton } from "./FooterButtons";

type ClanSelectPersonModalType = {
  onClose: () => void;
  isVisible: boolean;
  title?: string;
  description?: string;
  onlyTeachers?: true;
};

const ClanSelectPersonModal: React.FC<ClanSelectPersonModalType> = ({
  onClose,
  isVisible,
  title,
  description,
  onlyTeachers = false
}) => {
  const history = useHistory();
  const [profilesFromResponse, setProfilesFromResponse] = useState<
    RoleAwareProfileWithImage[]
  >([]);

  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [searchResult, setSearchResult] = useState<Array<number>>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      if (!isVisible) {
        return;
      }
      const responseProfiles = await AuthorizedApiResult.get<
        RoleAwareProfileWithImage[]
      >("user/profile/v1/profiles/sharewith");

      setProfilesFromResponse(
        onlyTeachers
          ? responseProfiles.filter((profile) =>
              UserEnabledProperties.Legacy.isAdult(profile)
            )
          : responseProfiles
      );
      setSearchResult([...responseProfiles].map((profile) => profile.id));
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (!searchText) {
      setSearchResult([...profilesFromResponse].map((profile) => profile.id));
      return;
    }
    const valueToSearch = searchText.trim().toLowerCase();
    setSearchResult(
      [...profilesFromResponse]
        .filter(
          (profile) => profile.name.toLowerCase().indexOf(valueToSearch) > -1
        )
        .map((profile) => profile.id)
    );
  }, [profilesFromResponse, searchText]);

  return (
    <IonModal isOpen onDidDismiss={onClose}>
      <ClanIonContent>
        <SmoothRays />
        <IonHeader mode="ios" className="px-4 pb-8">
          <div className="mt-12 text-center">
            <IonText>
              <h3 className="my-1">
                {title || t("clan_select_person_modal.send_message")}
              </h3>
            </IonText>
            {description && (
              <IonText>
                <p className="text-clanGray-200">{description}</p>
              </IonText>
            )}
          </div>
        </IonHeader>
        <IonCard className="mt-4 mx-6 rounded-clanCard h-clanCardFull overflow-y-auto">
          <IonItem>
            <IonSearchbar
              value={searchText}
              className="p-0"
              mode="ios"
              placeholder={t("clan_select_person_modal.search")}
              onIonChange={(e) => setSearchText(e.detail.value)}
            />
          </IonItem>
          <IonList>
            {profilesFromResponse.length !== 0 ? (
              searchResult.length !== 0 ? (
                profilesFromResponse
                  .filter((profile) => searchResult.indexOf(profile.id) > -1)
                  .map((person, index) => {
                    return (
                      <div
                        key={index}
                        className={`text-center mx-2 my-1 rounded-full p-2 m-2 grid grid-cols-3auto-middle bg-clanGray-100 cursor-pointer`}
                        onClick={() =>
                          history.push(
                            ROUTES.DIRECT_MESSAGE.replace(
                              ":profile_id",
                              person.id?.toString()
                            )
                          )
                        }
                      >
                        <ClanProfileAvatar
                          src={person?.images?.thumbnail?.url}
                          alt={`${person?.name} avatar`}
                          className="w-8 h-8 self-center justify-start"
                        />

                        <span className="whitespace-normal text-black self-center px-4">
                          <span>{person?.name}</span>
                          {UserEnabledProperties.UserStatus.hasTeacherBadge(
                            person
                          ) && (
                            <TeacherBadgeIcon className="align-middle m-2" />
                          )}
                        </span>
                      </div>
                    );
                  })
              ) : (
                <div className="text-center mx-2 my-1 rounded-full p-2 m-2">
                  {t("clan_select_person_modal.no_user_found")}
                </div>
              )
            ) : (
              <div className="text-center mx-2 my-1 rounded-full p-2 m-2">
                {t("clan_select_person_modal.loading_classmates")}
              </div>
            )}
          </IonList>
        </IonCard>
      </ClanIonContent>
      <FooterButtons>
        <PrimaryFooterButton onClick={onClose}>
          {t("general.back")}
        </PrimaryFooterButton>
      </FooterButtons>
    </IonModal>
  );
};

export default ClanSelectPersonModal;
