import { ConstraintViolationError } from "../types/ConstraintViolationError.d";
import {
  ApiRequestError,
  ApiResponseErrorType
} from "../types/ApiRequestError.d";

const resolveConstraintViolationError = (
  error: Error
): ConstraintViolationError | undefined => {
  const apiRequestError = error as ApiRequestError;
  if (
    apiRequestError.isApiRequestError &&
    apiRequestError.type === ApiResponseErrorType.HTTP_PRECONDITION_FAILED &&
    apiRequestError?.response?.data?.errors !== undefined &&
    apiRequestError?.response?.data?.errors !== null &&
    Array.isArray(apiRequestError?.response?.data?.errors)
  ) {
    return apiRequestError?.response?.data as ConstraintViolationError;
  }
};

export const resolveFirstConstraintViolationErrorProperty = (
  error: Error
): string | undefined => {
  const constraintError = resolveConstraintViolationError(error);
  if (
    !constraintError?.errors ||
    constraintError.errors?.length === 0 ||
    constraintError?.errors[0]?.property === undefined
  ) {
    return;
  }
  return constraintError.errors[0].property;
};
