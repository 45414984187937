import { IonItem, IonList, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";

import ClanDiscussionPostReportModal from "../../components/ClanDiscussionPost/ClanDiscussionPostReportModal";
import PostListItem from "../../components/ClanDiscussionPost/PostListItem";
import ClanDiscussionSkeleton from "../../components/ClanDiscussionSkeleton";
import TabFilter from "../../components/TabFilter";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import { PUBLIC_PROFILE_FEED_COMMENTS } from "../../routes";
import { getSharedPrivatePostsByProfileId } from "../../util/ClanPostAPIService";
import {
  ClanPostListResult,
  ClanPostListResultV1
} from "../../util/models/ClanPostModels";

const FeedTabFilter = styled(TabFilter)`
  padding-bottom: 0;
`;

enum PublicProfileFilter {
  ALL = "ALL",
  PRIVATELY_SHARED = "SHARED"
}

interface OwnProps {
  profileId: string;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

const PublicProfileFeed: React.FC<OwnProps & DispatchProps> = ({
  profileId,
  dispatchToast
}) => {
  const history = useHistory();

  const [posts, setPosts] = useState<Array<ClanPostListResult>>([]);
  const [postsLoading, setPostsLoading] = useState<boolean>(false);

  const [activeFilter, setActiveFilter] = useState<PublicProfileFilter>(
    PublicProfileFilter.ALL
  );

  const [postToReport, setPostToReport] = useState<ClanPostListResult | null>(
    null
  );

  const { t } = useTranslation();

  function onFeedTypeFilterChange(newFilter: string) {
    setActiveFilter(newFilter as PublicProfileFilter);
  }

  useEffect(() => {
    loadSharedFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapPosts = (p: Array<ClanPostListResultV1>) =>
    p.map(
      ({ groupShareAmount, profileShareAmount, ...post }) =>
        ({
          ...post,
          sharing: {
            groupsAmount: groupShareAmount,
            profilesAmount: profileShareAmount,
            organisationsAmount: 0,
            sharingShortcut: ""
          }
        } as ClanPostListResult)
    );

  async function loadSharedFeed() {
    setPostsLoading(true);

    try {
      const result = await getSharedPrivatePostsByProfileId(profileId);

      setPosts(mapPosts(result));
    } catch (e) {
      dispatchToast(e);
      console.error("[FEED] Couldn't load discussion feed", e);
    } finally {
      setPostsLoading(false);
    }
  }

  const onReportPost = (p: ClanPostListResult) => () => setPostToReport(p);

  const onCancelReport = () => setPostToReport(null);

  const onOpenComments = (post: ClanPostListResult) => () =>
    history.push(
      PUBLIC_PROFILE_FEED_COMMENTS.replace(":post_id", post.id).replace(
        ":profileId",
        profileId
      )
    );

  function filterPosts(
    allPosts: Array<ClanPostListResult>
  ): Array<ClanPostListResult> {
    switch (activeFilter) {
      case PublicProfileFilter.ALL:
        return allPosts;
      case PublicProfileFilter.PRIVATELY_SHARED:
        return allPosts.filter((post) => post.sharing.profilesAmount > 0);
      default: {
        return allPosts;
      }
    }
  }

  const filteredPosts = filterPosts(posts);

  return (
    <>
      <FeedTabFilter
        active={activeFilter}
        options={PublicProfileFilter}
        onChange={onFeedTypeFilterChange}
      />

      {postToReport?.id && (
        <ClanDiscussionPostReportModal
          title={t("general.report_to_teacher")}
          postId={postToReport?.id}
          onDone={() => setPostToReport(null)}
          onCancel={onCancelReport}
          isOpen={!!postToReport}
        />
      )}

      {postsLoading ? (
        <ClanDiscussionSkeleton numOfSkeletonPosts={10} />
      ) : (
        <>
          {filteredPosts.length === 0 ? (
            <IonItem className="mt-3 text-center">
              <div className="text-center w-full">
                {t("public_profile_feed.no_reflections")}
              </div>
            </IonItem>
          ) : (
            <>
              <IonList className="bg-gray-100 pt-0">
                {filteredPosts.map((post) => (
                  <PostListItem
                    key={post.id}
                    post={post}
                    onReport={onReportPost(post)}
                    onOpenComments={onOpenComments(post)}
                  />
                ))}
              </IonList>
              {/* <FeedPostList
                posts={filteredPosts}
                onPostReport={onOpenReport}
                onOpenPostComments={onOpenComments}
              /> */}
              <IonSegment>
                <IonSegmentButton
                  disabled
                  className="font-extrabold font-gilroy text-clanGreen-100"
                >
                  {t("general.list_end")}
                </IonSegmentButton>
              </IonSegment>
            </>
          )}
        </>
      )}
    </>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: PublicProfileFeed
});
