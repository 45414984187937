import React from "react";
import { ClanPostResultTask } from "../../../util/models/ClanPostModels";
import styled, { useTheme } from "styled-components";
import { H6 } from "../../Typography";
import IconBadge from "../../IconBadge";

const TaskSectionWrapper = styled.div`
  background: #fafafa;
  padding: 8px;
`;

const TaskSection = styled.div`
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 8px;
`;

const TaskName = styled(H6)`
  color: #878396;
`;

interface PostTaskReflectionProps {
  task: ClanPostResultTask;
}

const PostTaskReflection: React.FC<PostTaskReflectionProps> = ({ task }) => {
  const theme = useTheme();

  return (
    <TaskSectionWrapper>
      <TaskSection>
        <IconBadge
          size={24}
          src="/assets/icon/arrow-right.svg"
          bg={theme.primary}
        />
        <TaskName>"{task.name}"</TaskName>
      </TaskSection>
    </TaskSectionWrapper>
  );
};

export default PostTaskReflection;
