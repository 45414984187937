import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent
} from "@ionic/react";
import { format, isAfter, isToday, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";
import { analyticsLogEvent, setAnalyticsScreenName } from "../Analytics";
import ClanDesktopLayout from "../components/ClanDesktopLayout";
import ClanLoader from "../components/ClanLoader";
import ClanToolbarWithRays from "../components/ClanToolbarWithRays";
import { setLatestActivityTimestamp } from "../data/app/app.actions";
import { connect } from "../data/connect";
import {
  ActivityFeedPayload,
  NotificationHistoryResult,
  getNotificationHistory,
  navigateOnActivity
} from "../util/api/ActivityFeedNavigationService";
import { timeAgoFormatter } from "../util/DateUtils";
import { ClanIonContent } from "./DiscussionFeed/PublicFeeds";
import { useAsync, useAsyncEffect } from "@react-hook/async";
import {
  isReadyStateCancelOrFail,
  isReadyStateLoading
} from "../util/AsyncEffectFilter";

export const UnreadDot = styled.div`
  height: 10px;
  width: 10px;
  min-width: 10px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 99px;
  margin-left: 0.5rem;
`;

interface DispatchProps {
  dispatchSetLatestActivityTimestamp: typeof setLatestActivityTimestamp;
}

interface StateProps {
  lastActivityTimestampConnect: string | undefined;
}

interface ActivityProps extends DispatchProps, StateProps {}

const Activity: React.FC<ActivityProps> = ({
  dispatchSetLatestActivityTimestamp,
  lastActivityTimestampConnect
}) => {
  const history = useHistory();
  const [activities, setActivities] = useState<NotificationHistoryResult[]>([]);
  const goToView = (activity: NotificationHistoryResult) => {
    navigateOnActivity(history, activity.data as ActivityFeedPayload);
  };

  const fetchStatus = useAsyncEffect(async () => {
    setAnalyticsScreenName("ACTIVITY_FEED");
    analyticsLogEvent("ACTIVITY_FEED_fetch");
    const response = await getNotificationHistory();
    if (!response || isReadyStateCancelOrFail(fetchStatus)) return;
    await setActivities(response.slice(0, 20));
    // TODO errors are swallowed up
  }, []);

  const [refreshStatus, callRefresh] = useAsync(async () => {
    setAnalyticsScreenName("ACTIVITY_FEED");
    analyticsLogEvent("ACTIVITY_FEED_refresh");
    const response = await getNotificationHistory();
    if (!response || isReadyStateCancelOrFail(fetchStatus)) return;
    await setActivities(response.slice(0, 20));
    // TODO errors are swallowed up
  });

  useEffect(() => {
    return () => {
      activities[0]?.created &&
        dispatchSetLatestActivityTimestamp(activities[0]?.created);
    };
  }, [activities, dispatchSetLatestActivityTimestamp]);

  const getTimeAgo = (time: Date) => {
    const currentDate = new Date();
    if (
      currentDate.getDay() !== time.getDay() ||
      currentDate.getMonth() !== time.getMonth() ||
      currentDate.getFullYear() !== time.getFullYear()
    ) {
      return timeAgoFormatter(time, currentDate);
    }
    return format(new Date(time), "HH:mm");
  };

  const { t } = useTranslation();

  return (
    <ClanDesktopLayout>
      <IonPage>
        <ClanLoader
          message={t("activity.loading")}
          showLoading={isReadyStateLoading(fetchStatus, refreshStatus)}
        />
        <ClanIonContent>
          <IonRefresher
            slot="fixed"
            onIonRefresh={callRefresh}
            pullFactor={0.5}
            pullMin={100}
            pullMax={200}
          >
            <IonRefresherContent />
          </IonRefresher>
          <ClanToolbarWithRays>{t("activity.title")}</ClanToolbarWithRays>
          <div className="relative w-full">
            {activities.length !== 0 ? (
              <IonList>
                {activities.map(
                  (activity: NotificationHistoryResult, index) => {
                    return (
                      <IonItem
                        onClick={() => goToView(activity)}
                        key={index}
                        className="border border-t-0 border-l-0 border-r-0 border-clanYellow-100 border-solid cursor-pointer"
                        style={{
                          "--padding-start": "0.7rem"
                        }}
                      >
                        <IonAvatar
                          slot="start"
                          className="h-10 w-10 my-4 self-start border-clanYellow-100 border-solid border"
                        >
                          <img src={activity.icon} alt="activity.icon" />
                        </IonAvatar>
                        <IonLabel className="mt-2">
                          <h5 className="font-light text-clanGray-200 uppercase">
                            {`${getTimeAgo(parseISO(activity.created))} ${
                              !isToday(parseISO(activity.created))
                                ? t("activity.ago")
                                : ""
                            }`}
                          </h5>
                          <p
                            className={`whitespace-normal text-clanP text-black font-normal mb-2 ${
                              activity?.body ? "font-gilroy font-extrabold" : ""
                            }`}
                          >
                            {activity?.title[0].toUpperCase() +
                              activity.title.substring(1)}
                          </p>
                          {activity?.body && (
                            <div className="flex flex-auto items-center bg-clanGray-100 rounded-clanCard min-h-clanNotificationBody border border-solid border-clanYellow-100 p-2">
                              <p
                                className="text-black whitespace-normal"
                                dangerouslySetInnerHTML={{
                                  __html: activity?.body
                                }}
                              />
                            </div>
                          )}
                        </IonLabel>
                        {lastActivityTimestampConnect &&
                          isAfter(
                            new Date(activity.created),
                            new Date(lastActivityTimestampConnect)
                          ) && <UnreadDot />}
                      </IonItem>
                    );
                  }
                )}
              </IonList>
            ) : (
              <div className="p-3 text-center">{t("activity.no_activity")}</div>
            )}
          </div>
        </ClanIonContent>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    lastActivityTimestampConnect: state.appState.lastActivityTimestamp
  }),
  mapDispatchToProps: {
    dispatchSetLatestActivityTimestamp: setLatestActivityTimestamp
  },
  component: Activity
});
