import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { ReactComponent as TeacherBadgeIcon } from "../../../icons/teacher_badge.svg";
import * as ROUTES from "../../../routes";
import { UserEnabledProperties } from "../../../util/api/UserEnabledPropertiesService";
import { useShortTimeDistanceTranslation } from "../../../util/CustomHooks";
import { ClanPostListResult } from "../../../util/models/ClanPostModels";
import { isValidMood } from "../../../util/MoodChoice";
import ClanProfileAvatar from "../../ClanProfileAvatar";
import { FlexRow, ManSpreader } from "../../Flexbox";
import Icon from "../../Icon";
import MoodIndicator from "../../MoodIndicator";
import { H4 } from "../../Typography";
import { PostType } from "../PostListItem";
import PostTaskReflection from "./PostTaskReflection";

const PostHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const PostHeading = styled(FlexRow)`
  width: 90%;
  margin: 0 auto;
`;

const ProfileName = styled(H4)`
  margin: 0;
`;

const TeacherBadge = styled(TeacherBadgeIcon)`
  display: block;
`;

interface PostHeaderProps {
  post: PostType;
  openSettings: () => void;
}

const PostHeader: React.FC<PostHeaderProps> = ({ post, openSettings }) => {
  const dateTranslator = useShortTimeDistanceTranslation();

  const history = useHistory();

  const { createdAt } = post || {};

  const openPersonProfile = (personId: number) => () =>
    history.push(
      ROUTES.PUBLIC_PROFILE.replace(":profileId", personId.toString())
    );

  const hasValidMood = isValidMood(post.mood);

  const hasTeacherBadge = UserEnabledProperties.UserStatus.hasTeacherBadge(
    post.userProfile
  );

  return (
    <PostHeaderWrapper>
      <PostHeading gap="8px" align="center">
        <ClanProfileAvatar
          src={post.userProfile?.images?.thumbnail?.url}
          onClick={openPersonProfile(post.userProfile?.id)}
        />
        <div>
          <FlexRow
            align="center"
            onClick={openPersonProfile(post.userProfile?.id)}
            className="cursor-pointer"
            columnGap="8px"
          >
            <ProfileName>
              {(post as ClanPostListResult).userProfile?.name}
            </ProfileName>
            {hasTeacherBadge && <TeacherBadge />}
          </FlexRow>
          {post.mood && hasValidMood && (
            <MoodIndicator
              format="clan_discussion_post.feeling"
              mood={post.mood}
            />
          )}
        </div>

        <ManSpreader />

        <span>{dateTranslator(new Date(createdAt))}</span>

        <Icon
          size="24px"
          src="/assets/icon/settings.svg"
          onClick={openSettings}
          alt=""
        />
      </PostHeading>

      {post.task && <PostTaskReflection task={post.task} />}
    </PostHeaderWrapper>
  );
};

export default PostHeader;
