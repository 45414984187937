import {
  getConstantFilters,
  getConstantSharingFilters,
  ViewFilterConfiguration,
  ViewFilterConfigurationItem,
  ViewFilters,
  ViewFiltersType,
  Views,
  ViewSharingConfiguration,
  ViewSharingConfigurationItem,
  ViewSharings,
  ViewSharingType,
  ViewTypes
} from "../../Configuration";
import { FeedTypes, findViewFilterByFeedType } from "../../Feeds";
import { ResolvedFilters } from "../types";
import { useState } from "react";
import { useAsyncEffect } from "@react-hook/async";
import { ViewFilterSharingBaseItem } from "../../Configuration/types/ViewFilterSharingBase";

type FilterOrSharingResponse =
  | ViewSharingConfiguration
  | ViewFilterConfiguration;

const doResolveConfiguraton = async <T extends FilterOrSharingResponse>(
  fetch: (view: Views) => Promise<T>,
  feedType?: FeedTypes,
  viewFilter?: Views
): Promise<undefined | T> => {
  if (feedType !== undefined) {
    const result = findViewFilterByFeedType(feedType);
    if (result) {
      return fetch(result);
    }
  } else if (viewFilter !== undefined) {
    return fetch(viewFilter);
  }
  return Promise.resolve(undefined);
};

type ViewOrSharingFilters = ViewSharings | ViewFilters;
type ViewOrSharingType = ViewSharingType | ViewFiltersType;

type ResolvedFiltersItemType<
  FILTER extends ViewOrSharingFilters,
  TYPE extends ViewOrSharingType
> = ResolvedFilters<ViewFilterSharingBaseItem<FILTER, TYPE>>;

export type DefaultFilter<
  FILTER extends ViewOrSharingFilters,
  TYPE extends ViewOrSharingType
> = {
  isDefault: boolean;
} & ResolvedFiltersItemType<FILTER, TYPE>;

const getDefaultFilterResult = <
  FILTER extends ViewOrSharingFilters,
  TYPE extends ViewOrSharingType
>() => {
  return {
    isDefault: true,
    groupsFilters: [],
    userFilters: [],
    // if there is any group or user filter to choose from
    hasFilters: false,

    defaultFilter: undefined,
    // if filter view is available
    hasFilterView: false,
    // if to show non paid notification
    showNotPaidNotification: false
  } as DefaultFilter<FILTER, TYPE>;
};

const useViewFilterOrSharing = <
  FILTER extends ViewOrSharingFilters,
  TYPE extends ViewOrSharingType,
  R extends FilterOrSharingResponse
>(
  fetchData: (view: Views) => Promise<R>,
  feedType?: FeedTypes,
  viewFilter?: Views
): ResolvedFiltersItemType<FILTER, TYPE> => {
  const [filterResult, setFilterResult] = useState<DefaultFilter<FILTER, TYPE>>(
    getDefaultFilterResult()
  );

  useAsyncEffect(async () => {
    const result = await doResolveConfiguraton(fetchData, feedType, viewFilter);
    if (!result) {
      setFilterResult(getDefaultFilterResult());
      return;
    }

    const resultValue: DefaultFilter<FILTER, TYPE> = getDefaultFilterResult();
    resultValue.isDefault = false;

    result.filters.forEach((filter) => {
      let added = false;
      if (filter.isDefault && !resultValue.defaultFilter) {
        resultValue.defaultFilter = filter;
        added = true;
        // corner case. On groups we list all the groups
        if (filter.type === ViewTypes.GROUP) {
          resultValue.hasFilters = true;
        }
      }
      if (filter.type === ViewTypes.GROUP && !added) {
        resultValue.hasFilters = true;
        resultValue.groupsFilters.push(filter);
      } else if (filter.type !== ViewTypes.GROUP) {
        resultValue.hasFilters = true;
        resultValue.userFilters.push(filter);
      }
    });
    resultValue.hasFilters =
      resultValue.hasFilters ||
      resultValue.userFilters.length > 0 ||
      resultValue.groupsFilters.length > 0;

    resultValue.showNotPaidNotification = result.showNoFilterWarning;
    resultValue.hasFilterView =
      result.showFilters || result.showNoFilterWarning;

    setFilterResult(resultValue);
  }, [feedType, viewFilter]);
  return filterResult;
};

export const useViewFilterResult = (
  feedType?: FeedTypes,
  viewFilter?: Views
): ResolvedFilters<ViewFilterConfigurationItem> =>
  useViewFilterOrSharing<ViewFilters, ViewFiltersType, ViewFilterConfiguration>(
    getConstantFilters,
    feedType,
    viewFilter
  );

export const useSharingResult = (
  feedType?: FeedTypes,
  viewFilter?: Views
): ResolvedFilters<ViewSharingConfigurationItem> =>
  useViewFilterOrSharing<
    ViewSharings,
    ViewSharingType,
    ViewSharingConfiguration
  >(getConstantSharingFilters, feedType, viewFilter);
