import styled, { css } from "styled-components";

import Card from "./Card";
import { H4Style } from "./Typography";

const Badge = styled(Card)<{ active?: boolean; onClick?: any }>`
  ${H4Style}
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  border: 2px solid;
  border-color: #fafafa;
  background: ${({ theme, active }) => (active ? theme.primary : "white")};
  font-weight: 400;
  padding: 10px 14px;
  line-height: 22px;
  margin: 0;
  white-space: pre;
  transition: all 0.3s ease-in-out;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export default Badge;
