import { IonButton } from "@ionic/react";
import React from "react";
import styled, { css } from "styled-components";

import { H4Style, H5Style } from "../Typography";

interface BaseButtonProps {
  fullWidth?: boolean;
}

const BaseButton = styled(IonButton)<BaseButtonProps>`
  --border-radius: 25px;
  --border-color: white;
  --border-style: solid;
  --border-width: 1px;

  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  height: 50px;
  margin: 0.35rem 0;
  text-transform: initial;

  ${({ fullWidth }) => fullWidth && "width: 100%;"}
`;

export const PrimaryButton = styled(BaseButton)<BaseButtonProps>`
  --background: white;
`;

export const SecondaryButton = styled(BaseButton)``;

const ActionButtonBase = css`
  ${H4Style}
  margin: 0;
  padding: 8px 16px;
  background: ${({ theme }) => theme.primary};
  border-radius: 25px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.2;
  }
`;

export const ActionButton = styled.button`
  ${ActionButtonBase}
`;

export const SecondaryActionButton = styled.button<{ strong?: boolean }>`
  ${ActionButtonBase}
  background: #FAFAFA;
  color: ${({ strong }) => (strong ? "#000" : "#878396")};
`;

export const ActionLink = styled.a`
  ${ActionButtonBase}
`;

export const SecondaryActionLink = styled.a<{ strong?: boolean }>`
  ${ActionButtonBase}
  background: #FAFAFA;
  color: ${({ strong }) => (strong ? "#000" : "#878396")};
`;

interface StyledButtonProps {
  secondary?: boolean;
  light?: boolean;
  large?: boolean;
  disabled?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  padding: 8px 16px;
  border-radius: 50px;
  background: ${({ secondary, theme }) =>
    secondary ? "#FAFAFA" : theme.primary};

  ${({ light }) => (light ? H5Style : H4Style)}
  ${({ disabled }) => disabled && "opacity: 0.33;"}

  margin: 0;
`;

interface ButtonProps extends StyledButtonProps {
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
);

export default Button;
