import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow
} from "@ionic/react";
import {
  add,
  endOfWeek,
  isSameDay,
  isSameWeek,
  startOfWeek,
  sub
} from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { useGesture } from "react-use-gesture";
import styled from "styled-components";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import CategoryHeader from "../../components/CategoryHeader";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import ClanLoader from "../../components/ClanLoader";
import ClanToolbarWithRays from "../../components/ClanToolbarWithRays";
import FooterButtons, {
  PrimaryFooterButton
} from "../../components/FooterButtons";
import ClanTaskCompleteModal from "../../components/Planner/ClanTaskCompleteModal";
import NewTaskModal from "../../components/Planner/NewTaskModal";
import TaskItem from "../../components/Planner/TaskItem";
import { connect } from "../../data/connect";
import { showQuickHelpModal } from "../../data/modals/modals.actions";
import { ModalID } from "../../data/modals/modals.state";
import { ReactComponent as ArrowYellowIcon } from "../../icons/arrow-yellow.svg";
import * as ROUTES from "../../routes";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { ApiServiceMethod, AuthorizedApiResult } from "../../util/ApiService";
import {
  dateLocaleFormatter,
  DateStructure,
  dateToDateString,
  dateToDateStructure
} from "../../util/DateUtils";
import { UserTaskResult } from "../../util/models/PlannerUserTaskModels";
import { mobileOnlyStyles } from "../../util/ResponsiveUtils";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { ReactComponent as ArrowNavNextIcon } from "../../icons/arrow-nav-next.svg";
import { ReactComponent as ArrowNavPrevIcon } from "../../icons/arrow-nav-prev.svg";

const StyledNav = styled.div`
  background-image: none;
  border-radius: 999px;
  background-color: #fafafa;
  width: 3rem;
  height: 3rem;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${mobileOnlyStyles({
    display: "none"
  })}
`;

const StyledNavLeft = styled(StyledNav)`
  left: 1.2rem;
`;

const StyledNavRight = styled(StyledNav)`
  right: 1.2rem;
`;

const StyledCategoryHeader = styled(CategoryHeader)`
  margin-bottom: 0.7rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const WhiteWrapper = styled.div`
  background-color: white;
`;

const GridWrapper = styled.div`
  border-bottom: 10px solid ${({ theme }) => theme.primary};
  padding-bottom: 1.4rem;
`;

const WeekDaysGrid = styled(IonGrid)`
  padding-bottom: 0;
`;

const CalendarDay = styled(IonCol)<{ isSelected: boolean; isCurrent: boolean }>`
  border-radius: 10px;
  text-align: center;
  font-weight: 300;
  line-height: 1rem;
  margin: 0 0.2rem;
  cursor: pointer;

  background: ${({ isSelected, isCurrent, theme }) =>
    isSelected ? theme.primary : isCurrent ? `${theme.primary}50` : "white"};
`;

const DayOfWeek = styled.div<{ light: boolean }>`
  color: ${({ light }) => (light ? "#878396" : "black")};
`;

const DayNumber = styled.div`
  margin: 1.4rem 0 0.7rem;
`;

const TaskCountIndicator = styled.div<{ light: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 0.2rem;

  > div {
    margin: 3px 1px;
    width: 3px;
    height: 3px;
    background-color: ${({ light }) => (light ? "#fff" : "#878396")};
    border-radius: 50%;
  }
`;

const TasksWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
`;

const AddTaskBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const ArrowIcon = styled(ArrowYellowIcon)`
  width: 1.7rem;
  height: 2.2rem;
  margin-right: 0.7rem;
`;

const BASE_URL = "/usertask/v1/todo";

export enum WEIGHTSCALE {
  EASY = 1,
  MEDIUM = 5,
  HARD = 9
}

interface StateProps {
  isStudent: boolean;
}

interface DispatchProps {
  dispatchQuickHelpModal: typeof showQuickHelpModal;
}

// TODO: we might want to point to specific date, for now using RouteComponentProps to pass from TabRoute
const PlannerDay: React.FC<
  RouteComponentProps & StateProps & DispatchProps
> = ({ location, isStudent, dispatchQuickHelpModal }) => {
  const history = useHistory();

  const locationState = location.state as {
    dateChosen?: Date;
  };

  const directShare =
    new URLSearchParams(location.search).get("directShare") === "true";

  const inputMode =
    new URLSearchParams(location.search).get("inputMode") === "true";

  const dateSearch = new URLSearchParams(location.search).get("current");

  const [loading, setLoading] = useState(false);
  const [dateChosen, setDateChosen] = useState<Date>(
    dateSearch ? new Date(dateSearch) : locationState?.dateChosen || new Date()
  );
  const [dateShownRef, setDateShownRef] = useState<Date>(new Date());
  const [weekDays, setWeekDays] = useState<
    { date: Date; value: DateStructure; dateString: string }[]
  >([]);

  const [weekTasks, setWeekTasks] = useState<Array<UserTaskResult>>([]);
  const [showTaskAddBar, setShowTaskAddBar] = useState<boolean>(false);

  const [taskCompleted, setTaskCompleted] = useState<UserTaskResult>();
  const [existingLabels, setExistingLabels] = useState<
    { value: string; amount: number }[]
  >([]);

  useEffect(() => {
    if (directShare || inputMode) {
      setShowTaskAddBar(true);
    }
  }, [directShare, inputMode]);

  function selectDate(newDate: Date) {
    history.replace({
      search: `?current=${dateToDateString(newDate)}`
    });
    setDateChosen(newDate);
  }

  const AnimatedGrid = animated(WeekDaysGrid);

  const withLoading = (callback: () => Promise<any>) => {
    (async () => {
      await setLoading(true);
      try {
        await callback();
      } finally {
        await setLoading(false);
      }
    })();
  };

  const updateTask = (task: UserTaskResult) => {
    setWeekTasks(
      weekTasks.map((existingTask) => {
        if (existingTask.id === task.id) {
          return {
            ...existingTask,
            completedDate: task.completedDate
          };
        }

        return existingTask;
      })
    );
    // TODO: Find task by date or id and replace it
  };

  const getExistingLabels = async () =>
    AuthorizedApiResult.get<{ value: string; amount: number }[]>(
      `${BASE_URL}/labels/list`
    ).then(setExistingLabels);

  const switchComplete = (task: UserTaskResult) => {
    const method = task.completedDate
      ? ApiServiceMethod.DELETE
      : ApiServiceMethod.PUT;
    const taskId = task.id;
    withLoading(async () => {
      analyticsLogEvent(
        method === "put"
          ? "PLANNER_ITEM_complete"
          : "PLANNER_ITEM_completeRevert"
      );
      await AuthorizedApiResult.request<UserTaskResult>({
        method,
        url: `${BASE_URL}/${taskId}/complete`
      }).then(updateTask);
    });
    method === "put" && setTaskCompleted(task);
  };

  const loadWeekTasks = async (selectedDate: Date) => {
    const startDate = startOfWeek(selectedDate, { weekStartsOn: 1 });
    const endDate = endOfWeek(selectedDate, { weekStartsOn: 1 });
    setDateShownRef(selectedDate);

    withLoading(async () => {
      const newWeekTasks = await AuthorizedApiResult.get<UserTaskResult[]>(
        `${BASE_URL}?start=${dateToDateString(
          startDate
        )}&end=${dateToDateString(endDate)}`
      );

      const newWeekDays = new Array(7).fill(startDate).map((date, index) => {
        const day = new Date(date);
        day.setDate(startDate.getDate() + index);
        const dateString = dateToDateString(day);

        return {
          date: day,
          value: dateToDateStructure(day),
          dateString
        };
      });

      setWeekTasks(newWeekTasks);
      setWeekDays(newWeekDays);

      if (
        isSameWeek(selectedDate, new Date()) &&
        !isSameWeek(dateChosen, new Date())
      ) {
        selectDate(new Date());
      } else if (!isSameWeek(dateChosen, newWeekDays[0].date)) {
        selectDate(newWeekDays[0].date);
      }
    });
  };

  const { t } = useTranslation();

  useEffect(() => {
    setAnalyticsScreenName("PLANNER_LIST");
    getExistingLabels();
    loadWeekTasks(dateChosen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addTask = ({
    title,
    date,
    dueDate,
    reminder,
    mood,
    labels,
    weight
  }: {
    title: string;
    date?: Date;
    dueDate?: Date;
    reminder?: Date;
    mood?: string;
    labels?: string[];
    weight?: number;
  }) => {
    const args = {
      title,
      ...(date && { date: dateToDateString(date) }),
      ...(dueDate && { dueDate: dateToDateString(dueDate) }),
      ...(reminder && { reminder: reminder.toISOString() }),
      ...(mood && { mood }),
      ...(labels && { labels }),
      ...(weight && { weight })
    };

    withLoading(async () => {
      analyticsLogEvent("PLANNER_ITEM_add");
      await AuthorizedApiResult.post({ url: BASE_URL, data: args });
      loadWeekTasks(date || dateChosen);

      if (date) {
        selectDate(date);
      }
      await getExistingLabels();
      setShowTaskAddBar(false);

      if (
        isStudent &&
        mood &&
        ["Worried", "Anxious", "Scared"].includes(mood)
      ) {
        dispatchQuickHelpModal(ModalID.QuickHelpTask, [
          t("quick_help_modal.p1"),
          t("quick_help_modal.p2")
        ]);
      }
    });
  };

  const [styles, setGridAnimation] = useSpring(() => ({
    to: {
      x: 0,
      opacity: 1
    },
    config: { tension: 300, friction: 55, mass: 3 }
  }));

  const bind = useGesture({
    onDrag: ({ movement: [xm], down }) => {
      setGridAnimation({
        to: [
          {
            x: down ? xm : 0,
            opacity: 1
          }
        ]
      });
    },
    onDragEnd: (state) => {
      if (state.movement[0] > 50) {
        setGridAnimation({
          to: { x: 400, opacity: 0 }
        });
        setGridAnimation({
          from: { x: -400, opacity: 0 },
          to: { x: 0, opacity: 1 },
          delay: 100
        });
        loadWeekTasks(sub(dateShownRef, { days: 7 }));
      } else if (state.movement[0] < -50) {
        setGridAnimation({
          to: { x: -400, opacity: 0 }
        });
        setGridAnimation({
          from: { x: 400, opacity: 0 },
          to: { x: 0, opacity: 1 },
          delay: 100
        });
        loadWeekTasks(add(dateShownRef, { days: 7 }));
      } else {
        setGridAnimation({ x: 0, opacity: 1 });
      }
    }
  });

  function countUnfinishedTasksOnDate(dateString: string) {
    return Math.min(
      5,
      weekTasks.filter(
        (task: UserTaskResult) =>
          (task.targetDate === dateString || task.dueDate === dateString) &&
          !task.completedDate
      ).length
    );
  }

  function getFilteredTasks(completed: boolean) {
    const currentDateString = dateToDateString(dateChosen);

    return weekTasks.filter(
      (task) =>
        !!task.completedDate === completed &&
        (task.targetDate === currentDateString ||
          task.dueDate === currentDateString)
    );
  }

  const pendingTasks = getFilteredTasks(false);
  const completedTasks = getFilteredTasks(true);

  return (
    <ClanDesktopLayout>
      <IonPage id="planner_day">
        {showTaskAddBar && (
          <NewTaskModal
            existingLabels={existingLabels}
            initialDay={dateToDateString(dateChosen)}
            onClose={() => setShowTaskAddBar(false)}
            onSubmit={addTask}
            enableDirectShare={directShare}
          />
        )}

        <ClanLoader message={t("general.loading")} showLoading={loading} />

        {taskCompleted && (
          <ClanTaskCompleteModal
            taskCompleted={taskCompleted}
            setTaskCompleted={setTaskCompleted}
          />
        )}

        <ClanIonContent style={{ "--background": "#fafafa" }}>
          <WhiteWrapper>
            <ClanToolbarWithRays hideNav={true}>
              <HeaderWrapper>
                <h3 id="planner-title">
                  {dateLocaleFormatter(dateChosen.toString(), "EEEE, MMM d")}
                </h3>
                {(!isSameDay(new Date(), dateChosen) ||
                  !isSameWeek(dateChosen, dateShownRef)) && (
                  <IonButton
                    fill="clear"
                    className="m-0 ml-3 z-100"
                    style={{
                      "--padding-end": "0px",
                      "--padding-start": "0px"
                    }}
                    onClick={() => {
                      const currentDate = new Date();
                      selectDate(currentDate);
                      setDateShownRef(currentDate);
                      loadWeekTasks(currentDate);
                    }}
                  >
                    <IonIcon
                      className="pr-2 text-3xl"
                      icon="/assets/planner/back_to_today.svg"
                    />
                  </IonButton>
                )}
              </HeaderWrapper>
            </ClanToolbarWithRays>

            <GridWrapper>
              <AnimatedGrid {...bind()} style={styles}>
                <IonRow>
                  <StyledNavLeft
                    onClick={() => {
                      setGridAnimation({
                        to: { x: 400, opacity: 0 }
                      });
                      setGridAnimation({
                        from: { x: -400, opacity: 0 },
                        to: { x: 0, opacity: 1 },
                        delay: 100
                      });
                      loadWeekTasks(sub(dateShownRef, { days: 7 }));
                    }}
                  >
                    <ArrowNavPrevIcon />
                  </StyledNavLeft>
                  {weekDays.map(({ date, value, dateString }) => (
                    <CalendarDay
                      isSelected={isSameDay(dateChosen, date)}
                      isCurrent={isSameDay(new Date(), date)}
                      onClick={() => selectDate(date)}
                      key={`my_day_${value.year}_${value.month}_${value.day}`}
                    >
                      <DayOfWeek light={["Sat", "Sun"].includes(value.weekDay)}>
                        {dateLocaleFormatter(date.toString(), "EEEEE")}
                      </DayOfWeek>

                      <DayNumber>{value.day}</DayNumber>

                      <TaskCountIndicator
                        light={
                          isSameDay(dateChosen, date) ||
                          isSameDay(new Date(), date)
                        }
                      >
                        {new Array(countUnfinishedTasksOnDate(dateString))
                          .fill(1)
                          .map((val, index) => (
                            <div key={index} />
                          ))}
                      </TaskCountIndicator>
                    </CalendarDay>
                  ))}
                  <StyledNavRight
                    onClick={() => {
                      setGridAnimation({
                        to: { x: -400, opacity: 0 }
                      });
                      setGridAnimation({
                        from: { x: 400, opacity: 0 },
                        to: { x: 0, opacity: 1 },
                        delay: 100
                      });
                      loadWeekTasks(add(dateShownRef, { days: 7 }));
                    }}
                  >
                    <ArrowNavNextIcon />
                  </StyledNavRight>
                </IonRow>
              </AnimatedGrid>
            </GridWrapper>
          </WhiteWrapper>

          <StyledCategoryHeader
            title={t("planner_day.todo")}
            actions={[
              {
                title: t("planner_day.view_all"),
                action: () =>
                  history.push(ROUTES.PLANNER_EXISTING, {
                    dateChosen
                  })
              }
            ]}
          />

          <TasksWrapper>
            {pendingTasks.length === 0 ? (
              <AddTaskBtn
                onClick={() =>
                  history.push(ROUTES.PLANNER_EXISTING, {
                    dateChosen
                  })
                }
              >
                <ArrowIcon />
                <div>{t("planner_day.nothing")}</div>
              </AddTaskBtn>
            ) : (
              pendingTasks.map((item) => (
                <TaskItem
                  key={item.id}
                  task={item}
                  // dateChosen={dateChosen}
                  onClick={() =>
                    item.id &&
                    history.push(
                      ROUTES.PLANNER_TASK.replace(":task_id", item.id),
                      {
                        dateChosen
                      }
                    )
                  }
                  onToggleCompleted={() => switchComplete(item)}
                />
              ))
            )}
          </TasksWrapper>

          {completedTasks.length > 0 && (
            <>
              <StyledCategoryHeader
                title={t("planner_day.completed")}
                actions={[
                  {
                    title: t("planner_day.view_all"),
                    action: () =>
                      history.push(ROUTES.PLANNER_EXISTING, {
                        dateChosen
                      })
                  }
                ]}
              />

              <TasksWrapper>
                {completedTasks.map((item, key) => (
                  <TaskItem
                    key={key}
                    task={item}
                    // dateChosen={dateChosen}
                    onClick={() =>
                      item.id &&
                      history.push(
                        ROUTES.PLANNER_TASK.replace(":task_id", item.id),
                        {
                          dateChosen
                        }
                      )
                    }
                    onToggleCompleted={() => switchComplete(item)}
                  />
                ))}
              </TasksWrapper>
            </>
          )}
        </ClanIonContent>
        {!showTaskAddBar && (
          <FooterButtons>
            <PrimaryFooterButton
              onClick={() => {
                setShowTaskAddBar(true);
              }}
            >
              {t("planner_day.add")}
            </PrimaryFooterButton>
          </FooterButtons>
        )}
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: ({ token }) => ({
    isStudent: !UserEnabledProperties.Legacy.isAdult(token)
  }),
  mapDispatchToProps: {
    dispatchQuickHelpModal: showQuickHelpModal
  },
  component: PlannerDay
});
