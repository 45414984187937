import { IonIcon, IonRouterLink } from "@ionic/react";
import {
  checkmarkOutline,
  homeSharp,
  logOutOutline,
  menuOutline,
  peopleCircleOutline
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import {
  updateLatestActivityTimestamp,
  updateLatestChatTimestamps
} from "../data/app/app.actions";
import { LatestTimestampForChatId } from "../data/app/app.state";
import { connect } from "../data/connect";
import { removeOauth } from "../data/oauth/oauth.actions";
import { OauthState } from "../data/oauth/oauth.state";
import { OauthData } from "../data/oauth/oauthDataService";
import { ImageState } from "../data/user/image/image.state";
import * as ROUTES from "../routes";
import { isNativePlatform } from "../util/AppUtils";
import { signOutUser } from "../data/oauth/InitOauthState";
import { usePendingItemsInfo } from "../util/PendingItemsInfo";

import { ReactComponent as NotificationsYellowIcon } from "../icons/notifications-yellow.svg";
import { ReactComponent as DiscussionYellowIcon } from "../icons/discussion-yellow.svg";
import { ReactComponent as FeedsActiveIcon } from "../icons/feeds-active.svg";

const StyledDesktopNav = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  box-sizing: content-box;
  background: #fafafa;
  margin-left: 2rem;
  overflow: auto;
  max-height: calc(100vh - 44px);
`;

interface StyledNavBtnProps {
  active?: string;
}

const StyledNavBtn = styled.a<StyledNavBtnProps>`
  color: ${({ active, theme }) => (active === "true" ? theme.primary : "#666")};
  flex-direction: row;
  align-items: center;
  display: flex;
  text-decoration: none;
  cursor: pointer;
`;

const NavBtnContent = styled.div<{ active?: boolean }>`
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  background-color: #fafafa;
  border-radius: 50%;
  position: relative;
  ${({ active }) => active && "border: 1px solid #45DD85; margin: -1px;"}

  ion-img {
    width: 100%;
    height: 100%;
  }
`;

const NavBtnLabel = styled.div<StyledNavBtnProps>`
  height: 4rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  margin-left: 1rem;
  font-family: Gilroy;
  color: ${({ active }) => (active === "true" ? "black" : "#666")};
  ${({ active }) => active === "true" && "font-weight: bold;"}
`;

const NavBtnIcon = styled(IonIcon)`
  font-size: 1.5rem;
  display: block;
  top: 50%;
  transform: translate3D(-50%, -50%, 0);
  position: absolute;
  left: 50%;
`;

const NavBtnIconWrapper = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  top: 50%;
  transform: translate3D(-50%, -50%, 0);
  position: absolute;
  left: 50%;
`;

const ProfileAvatar = styled.img`
  object-fit: cover;
`;

const NavInfoContent = styled.div`
  position: relative;
  spacing: 10px;
  margin-left: 4rem;
  margin-top: 20px;

  > ion-router-link > img {
    margin-top: 10px;
    height: 40px;
    cursor: pointer;
  }
`;

interface NavBtnProps {
  id: string;
  active?: boolean;
  icon?: string;
  label: string;
  children?: (active: boolean | undefined) => JSX.Element;
  className?: string;
  onClick: () => void | Promise<boolean>;
  pending?: boolean;
}

const NavBtn: React.FC<NavBtnProps> = ({
  children,
  id,
  active,
  icon,
  label,
  className,
  pending,
  onClick
}) => {
  return (
    <StyledNavBtn
      className={className}
      id={id}
      active={active?.toString()}
      onClick={onClick}
    >
      <NavBtnContent active={pending}>
        {icon ? <NavBtnIcon icon={icon} /> : children && children(active)}
      </NavBtnContent>
      <NavBtnLabel active={active?.toString()}>{label}</NavBtnLabel>
    </StyledNavBtn>
  );
};

interface DesktopNavOwnProps {
  className?: string;
}
interface DesktopNavStateProps {
  profileImagesConnect: ImageState;
  oauthState: OauthState | OauthData;
  lastActivityTimestampConnect: string | undefined;
  chatHubLatestTimestampsConnect: LatestTimestampForChatId[] | undefined;
}

interface DesktopNavDispatchProps {
  removeOauthConnect: typeof removeOauth;
  dispatchUpdateLatestActivityTimestamp: typeof updateLatestActivityTimestamp;
  dispatchUpdateLatestChatTimestamps: typeof updateLatestChatTimestamps;
}

interface DesktopNavProps
  extends DesktopNavOwnProps,
    DesktopNavStateProps,
    DesktopNavDispatchProps {}

const DesktopNav: React.FC<DesktopNavProps> = ({
  lastActivityTimestampConnect,
  chatHubLatestTimestampsConnect,
  dispatchUpdateLatestActivityTimestamp,
  dispatchUpdateLatestChatTimestamps,
  profileImagesConnect,
  oauthState,
  className
}) => {
  const location = useLocation();
  const history = useHistory();
  const { activitiesPending, pendingChatMessages } = usePendingItemsInfo(
    oauthState,
    lastActivityTimestampConnect,
    chatHubLatestTimestampsConnect
  );
  const [showAppRecommendation, setShowAppRecommendation] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    dispatchUpdateLatestActivityTimestamp();
    dispatchUpdateLatestChatTimestamps();

    setShowAppRecommendation(!isNativePlatform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isActive = (route: string) => location.pathname === route;

  return (
    <StyledDesktopNav className={className}>
      <NavBtn
        id="nav-profile-desktop"
        onClick={() => history.push(ROUTES.PROFILE)}
        active={isActive(ROUTES.PROFILE)}
        label={t("desktop_nav.my_profile")}
        className="mt-8"
      >
        {() => (
          <ProfileAvatar
            alt={t("desktop_nav.my_profile")}
            src={
              profileImagesConnect.profileImageThumbnail
                ? profileImagesConnect.profileImageThumbnail
                : `/assets/navigation/profile.svg`
            }
          />
        )}
      </NavBtn>
      <NavBtn
        id="nav-dashboard-desktop"
        onClick={() => history.push(ROUTES.DASHBOARD)}
        active={isActive(ROUTES.DASHBOARD)}
        icon={homeSharp}
        label={t("desktop_nav.home")}
      />
      <NavBtn
        id="nav-notifications-desktop"
        onClick={() => history.push(ROUTES.ACTIVITY)}
        active={isActive(ROUTES.ACTIVITY)}
        label={t("desktop_nav.notifications")}
        pending={activitiesPending}
      >
        {(active) =>
          active ? (
            <NotificationsYellowIcon />
          ) : (
            <ProfileAvatar
              alt={t("desktop_nav.notifications")}
              src={`/assets/navigation/notifications.svg`}
            />
          )
        }
      </NavBtn>
      <NavBtn
        id="nav-messages-desktop"
        onClick={() => history.push(ROUTES.MESSAGES_HUB)}
        active={isActive(ROUTES.MESSAGES_HUB)}
        label={t("desktop_nav.messages")}
        pending={pendingChatMessages}
      >
        {(active) =>
          active ? (
            <DiscussionYellowIcon />
          ) : (
            <ProfileAvatar
              alt={t("desktop_nav.messages")}
              src={`/assets/navigation/discussion.svg`}
            />
          )
        }
      </NavBtn>
      <NavBtn
        id="nav-planner-desktop"
        onClick={() => history.push(ROUTES.PLANNER_DAY)}
        active={isActive(ROUTES.PLANNER_DAY)}
        icon={checkmarkOutline}
        label={t("desktop_nav.planner")}
      />
      <NavBtn
        id="nav-feeds-desktop"
        onClick={() => history.push(ROUTES.PUBLIC_FEEDS)}
        active={isActive(ROUTES.PUBLIC_FEEDS)}
        label={t("desktop_nav.feeds")}
      >
        {(active) =>
          active ? (
            <NavBtnIconWrapper>
              <FeedsActiveIcon />
            </NavBtnIconWrapper>
          ) : (
            <NavBtnIcon src={`/assets/navigation/feeds.svg`} />
          )
        }
      </NavBtn>
      <NavBtn
        id="nav-people-desktop"
        onClick={() => history.push(ROUTES.PEOPLE)}
        active={isActive(ROUTES.PEOPLE)}
        icon={peopleCircleOutline}
        label={t("desktop_nav.community")}
      />
      <NavBtn
        id="nav-settings-desktop"
        onClick={() => history.push(ROUTES.SETTINGS)}
        active={isActive(ROUTES.SETTINGS)}
        icon={menuOutline}
        label={t("desktop_nav.settings_and_information")}
      />

      {showAppRecommendation && (
        <NavInfoContent>
          <h6>{t("desktop_nav.mobile_recommendation")}</h6>
          <IonRouterLink
            href="https://apps.apple.com/ee/app/clanbeat/id1502082688"
            color="success"
            target="_blank"
          >
            <img src={`/assets/apple-app-store-badge.png`} alt="appstore" />
          </IonRouterLink>
          <br />
          <IonRouterLink
            href="https://play.google.com/store/apps/details?id=clanbeat.app"
            color="success"
            target="_blank"
          >
            <img src={`/assets/google-play-badge.png`} alt="playstore" />
          </IonRouterLink>
        </NavInfoContent>
      )}

      <NavBtn
        id="nav-logout-desktop"
        icon={logOutOutline}
        label={t("desktop_nav.logout")}
        className="mt-auto"
        onClick={() => {
          signOutUser(history);
        }}
      />
    </StyledDesktopNav>
  );
};

export default connect<
  DesktopNavOwnProps,
  DesktopNavStateProps,
  DesktopNavDispatchProps
>({
  mapStateToProps: (state) => ({
    profileImagesConnect: state.userProfileImage,
    oauthState: state.token,
    lastActivityTimestampConnect: state.appState.lastActivityTimestamp,
    chatHubLatestTimestampsConnect: state.appState.chatHubLatestTimestamps
  }),
  mapDispatchToProps: {
    removeOauthConnect: removeOauth,
    dispatchUpdateLatestActivityTimestamp: updateLatestActivityTimestamp,
    dispatchUpdateLatestChatTimestamps: updateLatestChatTimestamps
  },
  component: DesktopNav
});
