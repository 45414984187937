import {
  IonItem,
  IonList,
  IonSearchbar,
  IonLabel,
  IonInput
} from "@ionic/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ClanQuestionModal from "./ClanQuestionModal";
import ClanProfileCard from "./ClanProfileCard";
import { capitalize } from "../util/StringUtils";
import { useTranslation } from "react-i18next";

interface ClanOnboardingListTemplateProps {
  listItems: string[];
  existingItems: string[];
  callback: any;
  newItemModalTitle?: string;
}
const ClanOnboardingListTemplate: React.FC<ClanOnboardingListTemplateProps> = ({
  listItems,
  existingItems,
  callback,
  newItemModalTitle
}) => {
  const [searchText, setSearchText] = useState<string>();
  const [newItem, setNewItem] = useState<string>();
  const [listItemsResult, setListItemsResult] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);

  const [listItemsSearchSubset, setListItemsSearchSubset] = useState<
    Array<string>
  >([]);
  // Increment amount used for throttling list loading
  const loadMoreIncrement = 50;
  const [showSearch, setShowSearch] = useState<boolean | undefined>(false);
  const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);

  // Show existing items
  useLayoutEffect(() => {
    setSelectedItems(existingItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Send selection to parent
  useLayoutEffect(() => {
    callback(selectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const clickHandler = (e: any) => {
    selectItem(e.currentTarget.textContent);
  };

  useEffect(() => {
    // TODO: Workaround to avoid performance issues on list loading
    // Later a window virtualized library like https://github.com/bvaughn/react-window can be used
    const items = [...listItemsResult];
    const loadMore = () => {
      setListItemsSearchSubset((prevSubset) => {
        if (prevSubset.length + loadMoreIncrement < items.length) {
          setTimeout(loadMore, 200);
        } else {
          setShowSearch(true);
        }
        return items.slice(0, prevSubset.length + loadMoreIncrement);
      });
    };
    loadMore();
  }, [listItemsResult]);

  // Attach search to search items
  useEffect(() => {
    if (searchText !== undefined) {
      const text = searchText?.trim().toLowerCase();
      const searchResult = listItemsResult.filter(
        (item) => item.toLowerCase().indexOf(text) > -1
      );
      setListItemsSearchSubset(searchResult);
    }
  }, [searchText, listItemsResult]);

  useEffect(() => {
    setListItemsResult(
      [...listItems].sort((val1, val2) => {
        const val1Lower = val1.toLowerCase();
        const val2Lower = val2.toLowerCase();
        return val1Lower === val2Lower ? 0 : val1Lower > val2Lower ? 1 : -1;
      })
    );
  }, [listItems]);

  const selectItem = (item: string) => {
    if (existingItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item] as any);
    }
  };

  const addInterest = () => {
    if (newItem?.trim()) {
      const valueToAdd = capitalize(newItem.trim());
      setSelectedItems([...selectedItems, valueToAdd]);
      // if already exists in the list, just use existing
      const existingItem =
        listItemsResult[
          listItemsResult.findIndex(
            (item) => valueToAdd.toLowerCase() === item.toLowerCase()
          )
        ];

      if (existingItem) {
        selectItem(existingItem);
      } else {
        selectItem(valueToAdd);
        setListItemsResult(
          [...listItemsResult, valueToAdd].sort((val1, val2) => {
            const val1Lower = val1.toLowerCase();
            const val2Lower = val2.toLowerCase();
            return val1Lower === val2Lower ? 0 : val1Lower > val2Lower ? 1 : -1;
          })
        );
      }
    }
    setShowAddItemModal(false);
    setSearchText("");
  };

  const { t } = useTranslation();

  return (
    <>
      <ClanQuestionModal
        title={newItemModalTitle ? newItemModalTitle : ""}
        subtitle={t("clan_onboarding_list_template.subtitle")}
        isOpen={showAddItemModal}
        loading={false}
        onNo={() => {
          setShowAddItemModal(false);
          setNewItem("");
        }}
        yesText={t("clan_onboarding_list_template.add")}
        key="onboarding_add"
        onYes={addInterest}
      >
        <IonList className="text-center mx-clanFlowStepScreenEdge">
          <IonInput
            autofocus
            value={newItem ? newItem : searchText?.trim()}
            className="number text-clanH2 text-black font-extrabold font-gilroy my-6"
            placeholder={t("clan_onboarding_list_template.add_new_item")}
            onIonChange={(e) =>
              setNewItem(e.detail.value ? e.detail.value : "")
            }
          />
        </IonList>
      </ClanQuestionModal>
      <ClanProfileCard
        cardClassName="h-clanCard mx-0 w-full"
        contentClassName="px-3"
        noFooter
      >
        <p onClick={() => setShowAddItemModal(true)} className="cursor-pointer">
          {t("clan_onboarding_list_template.tap_to_add")}
        </p>
        <IonItem>
          <IonSearchbar
            mode="ios"
            className="p-0 text-clanH5 text-clanGray-200"
            placeholder={
              showSearch
                ? t("clan_onboarding_list_template.search")
                : t("clan_onboarding_list_template.loading_list")
            }
            onIonChange={(e) => setSearchText(e.detail.value)}
            disabled={!showSearch}
          />
        </IonItem>

        <IonList>
          {listItemsSearchSubset.length !== 0 ? (
            listItemsSearchSubset.map((item: string) => {
              return (
                <h5
                  className={`clan-list-item mb-2 cursor-pointer ${
                    existingItems.includes(item)
                      ? "border border-solid border-clanGreen-100"
                      : ""
                  }`}
                  key={item}
                  onClick={clickHandler}
                >
                  {item}
                </h5>
              );
            })
          ) : searchText?.trim() ? (
            <>
              <IonItem>
                <IonLabel
                  className="text-center whitespace-normal font-extrabold text-clanGreen-100 cursor-pointer"
                  onClick={() => {
                    setNewItem(searchText?.trim());
                    setShowAddItemModal(true);
                  }}
                >
                  {t("clan_onboarding_list_template.create_new")}
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel className="text-center whitespace-normal">
                  {t("clan_onboarding_list_template.not_added", {
                    interest: searchText?.trim()
                  })}
                </IonLabel>
              </IonItem>
            </>
          ) : (
            <IonItem>{t("clan_onboarding_list_template.no_results")}</IonItem>
          )}
        </IonList>
      </ClanProfileCard>
    </>
  );
};

export default ClanOnboardingListTemplate;
