type FetchIgnoring404<T> = [() => Promise<T>, (value: T) => void, T];

async function doAsyncRequestWithFlow<T>(
  action: () => Promise<T>,
  onStart: () => void,
  onFinish: () => void,
  onError: (error: string) => void
): Promise<T | undefined> {
  try {
    onStart();
    return await action();
  } catch (error) {
    onError(`Couldn't fetch:" ${error}`);
  } finally {
    onFinish();
  }
  return undefined;
}

async function doIgnoring404<T>(
  callable: () => Promise<T>,
  defaultValue: T
): Promise<T> {
  try {
    return await callable();
  } catch (error) {
    //TODO: 404 Most probably this means list is empty in future we need to have better error status codes
    if (error.response?.status !== 404) {
      throw error;
    }
    return defaultValue;
  }
}

export async function fetchIgnoring404(
  callables: FetchIgnoring404<any>[],
  setLoading: (status: boolean) => void,
  setErrorMsg: (error: string) => void
) {
  await doAsyncRequestWithFlow<null>(
    async () => {
      for (let i = 0; i < callables.length; i++) {
        const result = await doIgnoring404(callables[i][0], callables[i][2]);
        await callables[i][1](result);
      }
      return null;
    },
    () => setLoading(true),
    () => setLoading(false),
    (error) => setErrorMsg(`Couldn't fetch:" ${error}`)
  );
}

export async function setIgnoring404<T, R>(
  setter: (value: T) => Promise<R>,
  data: T,
  setLoading: (status: boolean) => void,
  setErrorMsg: (error: string) => void
): Promise<R | null | undefined> {
  return doAsyncRequestWithFlow<R | null>(
    async () => doIgnoring404(() => setter(data), null),
    () => setLoading(true),
    () => setLoading(false),
    (error) => setErrorMsg(`Couldn't fetch:" ${error}`)
  );
}
