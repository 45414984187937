import React from "react";
import styled from "styled-components";
import { IonModal } from "@ionic/react";

const BottomStyledModal = styled(IonModal)`
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  --background: transparent;
  --overflow: visible;
  --height: auto;
  align-items: flex-end;

  .ion-page {
    overflow: visible;
    contain: initial;
    position: relative;
  }
`;

const StyledModalContent = styled.div`
  border-top: 16px solid ${({ theme }) => theme.primary};
  background-color: white;
`;

interface ClanBottomModalProps {
  isOpen?: boolean;
  onDidDismiss?: () => void;
}

const ClanBottomModal: React.FC<ClanBottomModalProps> = ({
  isOpen = false,
  onDidDismiss,
  children
}) => {
  return (
    <BottomStyledModal isOpen={isOpen} onDidDismiss={onDidDismiss}>
      <StyledModalContent>{children}</StyledModalContent>
    </BottomStyledModal>
  );
};

export default ClanBottomModal;
