import {
  ADD_LOADER_STATE,
  APP_RESET,
  AppAction,
  REMOVE_LOADER_STATE,
  RESET_APP_ERROR,
  SET_APP_ERROR,
  SET_APP_READY,
  SET_INFO_MODAL_CLOSED,
  SET_TUTORIAL_OPENED,
  SET_RECIEVED_FIRST_SURVEY,
  SET_LATEST_ACTIVITY_TIMESTAMP,
  SET_LATEST_CHAT_TIMESTAMPS,
  SET_LATEST_REPORT_TIMESTAMP,
  SET_GIVEN_FEEDBACK
} from "./app.actions";
import { CBAppState } from "./app.state";

export function appReducer(state: CBAppState, action: AppAction): CBAppState {
  switch (action.type) {
    case ADD_LOADER_STATE: {
      return {
        ...state,
        isLoading: true,
        loaders: [...state.loaders, action.data]
      };
    }

    case REMOVE_LOADER_STATE: {
      const loaders = [...state.loaders];
      const index = loaders.indexOf(action.data);
      if (index >= 0) loaders.splice(index, 1);

      return {
        ...state,
        isLoading: loaders.length > 0,
        loaders
      };
    }

    case SET_APP_ERROR:
      return {
        ...state,
        error: action.data
      };

    case RESET_APP_ERROR:
      return {
        ...state,
        error: undefined
      };

    case SET_APP_READY:
      return {
        ...state,
        isReady: true
      };

    case APP_RESET:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        loaders: [],
        isReady: true
      };

    case SET_INFO_MODAL_CLOSED:
      return {
        ...state,
        infoModalClosed: action.data
      };

    case SET_TUTORIAL_OPENED:
      return {
        ...state,
        tutorialOpened: action.data
      };
    case SET_LATEST_ACTIVITY_TIMESTAMP:
      return {
        ...state,
        lastActivityTimestamp: action.data
      };

    case SET_LATEST_REPORT_TIMESTAMP:
      return {
        ...state,
        lastReportTimestamp: action.data
      };

    case SET_RECIEVED_FIRST_SURVEY:
      return {
        ...state,
        hasRecievedFirstSurvey: action.data
      };

    case SET_GIVEN_FEEDBACK:
      return {
        ...state,
        feedbackGiven: action.data
      };

    case SET_LATEST_CHAT_TIMESTAMPS:
      return {
        ...state,
        chatHubLatestTimestamps: action.data
      };

    default:
      return state;
  }
}
