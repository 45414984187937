import axios from "axios";
import { errorToApiRequestError } from "../../ApiService/lib/ApiRequestErrorImpl";
import { ApiRequestError } from "../../ApiService";

const fetchBlob = async (url: string): Promise<Blob> => {
  try {
    const axiosResult = await axios({
      url,
      method: "GET",
      responseType: "blob"
    });
    return axiosResult.data;
  } catch (error) {
    throw errorToApiRequestError(error);
  }
};

const fetchFromUrlWithCallbacks = async (
  url: string,
  resolve: (value: string | ArrayBuffer) => void,
  reject: (value: ApiRequestError) => void
) => {
  const blobData = await fetchBlob(url);
  const reader = new FileReader();
  reader.addEventListener(
    "load",
    () => {
      const blobResult = reader.result;
      if (blobResult) {
        resolve(blobResult);
      } else {
        reject(
          errorToApiRequestError(
            "I was not able to read the content. Result is null or empty!"
          )
        );
      }
    },
    false
  );
  reader.addEventListener("abort", () =>
    reject(
      errorToApiRequestError(
        reader.error ? reader.error : "Error while reading DOM"
      )
    )
  );

  reader.addEventListener("error", () =>
    reject(
      errorToApiRequestError(
        reader.error ? reader.error : "Error while reading DOM"
      )
    )
  );

  try {
    reader.readAsDataURL(blobData);
  } catch (error) {
    reject(errorToApiRequestError(error));
  }
};

const fetchBlobFromURL = (url: string): Promise<string | ArrayBuffer> =>
  new Promise<string | ArrayBuffer>((resolve, reject) =>
    fetchFromUrlWithCallbacks(url, resolve, reject).catch(reject)
  );

export const fetchBlobStringOrUndefinedFromURL = (
  url: string | null | undefined
): Promise<string | undefined> => {
  if (!url) return Promise.resolve(undefined);
  return new Promise<string>((resolve) => {
    try {
      fetchBlobFromURL(url)
        .then((value) => resolve(value.toString()))
        .catch((error) => {
          console.error(`Tried to fetch image from ${url} failed.`, error);
          resolve(undefined);
        });
    } catch (error) {
      console.error(`Error while initializing fetching from ${url}.`, error);
      resolve(undefined);
    }
  });
};
