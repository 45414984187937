import { IonContent, IonInput } from "@ionic/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { SmoothRaysInverted } from "../../components/ClanSmoothRays";
import FooterButtons, { FooterButton } from "../../components/FooterButtons";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import {
  BackButton,
  StartContentWrapper,
  StartDescription,
  StartTitle,
  StartTitleWrapper,
  StartToolbar,
  StartWrapper
} from "./Start";

const StyledInput = styled(IonInput)`
  padding: 0;
`;

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface OwnProps {
  phoneNumber: string;
  getFirebaseToken: any;
  loading: boolean;
  resetStartState: () => void;
}

interface SmsPinProps extends OwnProps, DispatchProps {}

const SmsPin: React.FC<SmsPinProps> = ({
  phoneNumber,
  getFirebaseToken,
  dispatchToast,
  loading,
  resetStartState
}) => {
  const { getValues, trigger, errors, watch, control } = useFormContext();
  const watchOTP = watch("otp");
  const { t } = useTranslation();

  const onSubmit = async () => {
    if (await trigger("otp")) {
      console.debug("[START_PAGE] Entered OTP is", getValues().otp);
      getFirebaseToken(getValues().otp);
    } else {
      dispatchToast(
        `Error - ${Object.keys(errors).reduce((allErrors, key) => {
          return allErrors.concat(errors[key].message);
        }, "")}`
      );
    }
  };

  return (
    <>
      <IonContent>
        <StartWrapper>
          <StartToolbar id="dashboard-header">
            <SmoothRaysInverted />
            <BackButton
              src="/assets/arrow_back_black.svg"
              onClick={() => {
                resetStartState();
              }}
            />
          </StartToolbar>

          <StartContentWrapper>
            <StartTitleWrapper>
              <StartTitle>
                <Controller
                  control={control}
                  render={({ onChange }) => (
                    <StyledInput
                      onIonChange={(e) => onChange(e.detail.value)}
                      type="tel"
                      inputMode="tel"
                      required
                      className="otp text-clanH2 text-black font-extrabold font-gilroy tracking-extrawide"
                      placeholder={t("start.sms_pin.pin_placeholder")}
                    />
                  )}
                  name="otp"
                  defaultValue={null}
                  rules={{
                    required: {
                      value: true,
                      message: t("start.sms_pin.code_required")
                    },
                    minLength: {
                      value: 6,
                      message: t("start.sms_pin.code_longer")
                    },
                    maxLength: {
                      value: 6,
                      message: t("start.sms_pin.code_shorter")
                    }
                  }}
                />
              </StartTitle>
              <StartDescription>
                <span>
                  <b>
                    {t("start.sms_pin.description_1", { phone: phoneNumber })}
                  </b>{" "}
                  {t("start.sms_pin.description_2")}
                </span>
                <br />
                <br />
                <span>{t("start.sms_pin.description_3")}</span>
              </StartDescription>
            </StartTitleWrapper>
          </StartContentWrapper>
        </StartWrapper>
      </IonContent>
      <FooterButtons>
        <FooterButton disabled={loading || !watchOTP} onClick={onSubmit}>
          {t("general.next")}
        </FooterButton>
      </FooterButtons>
    </>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: SmsPin
});
