import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Badge from "./Badge";

const TabFilterWrapper = styled.div`
  background: #fafafa;
`;
const StyledTabFilterWrapper = styled.div`
  overflow-x: auto;
  padding: 12px 5%;
`;

const StyledTabFilter = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  column-gap: 8px;
`;

interface TabFilterProps {
  id?: string;
  isKeyBased?: boolean;
  translated?: boolean;
  active?: any;
  options?: { [key: string]: string };
  onChange?: (newActiveTab: any) => void;
  className?: string;
}

const TabFilter: React.FC<TabFilterProps> = ({
  id,
  isKeyBased = false,
  translated = false,
  active,
  options,
  onChange,
  className,
  children
}) => {
  const onTabChange = (tab: any, key: any) => () =>
    onChange && onChange(isKeyBased ? key : tab);

  const { t } = useTranslation();

  return (
    <TabFilterWrapper id={id} className={className}>
      <StyledTabFilterWrapper>
        <StyledTabFilter>
          {Object.entries(options || {}).map(([key, tab], index) => (
            <Badge
              key={index}
              active={isKeyBased ? key === active : tab === active}
              onClick={onTabChange(tab, key)}
            >
              {translated
                ? tab
                : t("tabs." + tab.replace(/ /g, "").toLowerCase())}
            </Badge>
          ))}
          {children}
        </StyledTabFilter>
      </StyledTabFilterWrapper>
    </TabFilterWrapper>
  );
};

export default TabFilter;
