import {
  IonButton,
  IonFooter,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRange
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import { SmoothRays } from "../../components/ClanSmoothRays";
import { ReactComponent as BalloonIcon } from "../../icons/balloon.svg";
import { AuthorizedApiResult } from "../../util/ApiService";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";

type ChatQuestionProps = {
  id: string;
  question: JsonSchema;
  doAnswer: Function;
};

const ChatQuestion: React.FC<ChatQuestionProps> = ({
  id,
  question,
  doAnswer
}) => {
  const { title, answer, description, message } = question;

  return (
    <>
      <ChatItem title={title} description={description} incoming />
      {answer ? (
        <ChatItem title={answer} />
      ) : (
        <ChatQuestionAnswer
          questionId={id}
          question={question}
          doAnswer={doAnswer}
        />
      )}
      {message && <ChatItem title={message} incoming />}
    </>
  );
};

type ChatQuestionAnswerProps = {
  questionId: string;
  question: JsonSchema;
  doAnswer: Function;
};

const ChatQuestionAnswer: React.FC<ChatQuestionAnswerProps> = ({
  questionId,
  question,
  doAnswer
}) => {
  const { type, minimum, maximum } = question;
  switch (type) {
    case "boolean":
      return <BooleanAnswerItem questionId={questionId} doAnswer={doAnswer} />;
    case "number":
      return (
        <RangeAnswerItem
          questionId={questionId}
          doAnswer={doAnswer}
          min={minimum}
          max={maximum}
        />
      );
    default:
      return null;
  }
};

type RangeAnswerItemProps = {
  questionId: string;
  doAnswer: Function;
  min?: number;
  max?: number;
};

const RangeAnswerItem: React.FC<RangeAnswerItemProps> = ({
  questionId,
  doAnswer,
  min,
  max
}) => (
  <IonRange
    className="p-0 my-3"
    min={min}
    max={max}
    step={1}
    snaps
    onIonChange={(e) => doAnswer(questionId, e.detail.value as number)}
  >
    <div className="flex flex-col" slot="start">
      <BalloonIcon className="h-10 my-3" />
      <IonLabel className="mt-2 mx-auto w-full block text-center">
        <Trans>chatbot.low</Trans>
      </IonLabel>
    </div>
    <div slot="end">
      <BalloonIcon className="h-12" />
      <IonLabel className="mt-2 mx-auto w-full block text-center">
        <Trans>chatbot.high</Trans>
      </IonLabel>
    </div>
  </IonRange>
);

type BooleanAnswerItemProps = {
  questionId: string;
  doAnswer: Function;
};

const BooleanAnswerItem: React.FC<BooleanAnswerItemProps> = ({
  questionId,
  doAnswer
}) => {
  function answer(value: boolean) {
    return () => doAnswer(questionId, value);
  }

  return (
    <IonList className="flex flex-row justify-end p-0 my-3">
      <IonButton
        style={{ "--border-radius": "0.9375rem !important" }}
        className="my-0 ml-2 font-notoSansRegular"
        mode="ios"
        onClick={answer(true)}
      >
        <Trans>chatbot.yes</Trans>
      </IonButton>
      <IonButton
        style={{ "--border-radius": "0.9375rem !important" }}
        className="my-0 ml-2 font-notoSansRegular"
        mode="ios"
        onClick={answer(false)}
      >
        <Trans>general.no</Trans>
      </IonButton>
    </IonList>
  );
};

type ChatItemProps = {
  incoming?: boolean;
  title: string;
  description?: string;
};

const ChatItem: React.FC<ChatItemProps> = ({
  incoming,
  title,
  description
}) => (
  <IonItem
    className={`my-3 ${incoming ? "" : "self-end"}`}
    style={{
      "--min-height": "14px",
      "--inner-padding-end": "0px",
      "--inner-padding-start": "0px",
      "--padding-end": "0px",
      "--padding-start": "0px",
      maxWidth: "90%"
    }}
  >
    <p
      className={`py-3 px-6 m-0 rounded-clanCard font-notoSansRegular ${
        incoming ? "bg-clanGray-100" : "bg-clanYellow-100"
      } ${incoming ? "" : "text-right"}`}
    >
      {title}
      <br />
      {description}
    </p>
  </IonItem>
);

type JsonSchema = {
  title: string;
  description?: string;
  maximum?: number;
  minimum?: number;
  type: "string" | "number" | "boolean";

  answer?: any;
  message?: string | null;
};

type Question = { [key: string]: JsonSchema };

type QuestionHistory = {
  question: Question;
  answers: {
    [key: string]: string;
  };
};

type CustomFormResponse = {
  question: Question;
  response: string;
  history: QuestionHistory[];
};

const Chatbot: React.FC = () => {
  const history = useHistory();
  const ionContent = useRef<HTMLIonContentElement>(null);

  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [questionHistory, setQuestionHistory] = useState<Question[]>([]);
  const [activeQuestion, setActiveQuestion] = useState<Question | null>(null);

  function goBack() {
    history.goBack();
  }

  function mapAnswer(question: JsonSchema, answer: any) {
    switch (question.type) {
      case "boolean":
        return answer ? <Trans>general.yes</Trans> : <Trans>general.no</Trans>;
      default:
        return answer;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom(initialLoading ? 0 : 200);
    }, 10);
  }, [questionHistory, activeQuestion, initialLoading]);

  useEffect(() => {
    (async () => {
      setAnalyticsScreenName("CHATBOT");
      analyticsLogEvent("CHATBOT_next_question");
      const result = await AuthorizedApiResult.get<CustomFormResponse>(
        "customform/v1/next"
      );
      setQuestionHistory(
        (result.history || []).reduce(
          (historyItems: Question[], historyItem: QuestionHistory) => [
            ...historyItems,
            ...Object.entries(historyItem.question).map(
              ([questionId, questionData]) =>
                ({
                  [questionId]: {
                    ...questionData,
                    answer: mapAnswer(
                      questionData,
                      historyItem.answers[questionId]
                    ),
                    message: historyItem.answers[`${questionId}.response`]
                  } as JsonSchema
                } as Question)
            )
          ],
          []
        )
      );

      setActiveQuestion(result.question || null);
      setInitialLoading(false);
    })();
  }, []);

  const scrollToBottom = (duration: number) =>
    ionContent.current && ionContent.current.scrollToBottom(duration);

  // TODO: This is temp function. Will be used when skipping feature is needed
  // const onSkipLatest = async () => {
  //   if (activeQuestion) {
  //     const activeQuestionId = Object.keys(activeQuestion)[0];

  //     doRequest(
  //       "post",
  //       `customform/v1/question/${encodeURIComponent(activeQuestionId)}/skip`
  //     );
  //     goBack();
  //   }
  // };

  const doAnswer = async (questionId: string, value: number | boolean) => {
    analyticsLogEvent("CHATBOT_answer");
    const result = await AuthorizedApiResult.post<CustomFormResponse>({
      url: `customform/v1/question/${encodeURIComponent(questionId)}`,
      data: { value }
    });

    if (activeQuestion) {
      setQuestionHistory([
        ...questionHistory,
        {
          [questionId]: {
            ...activeQuestion[questionId],
            answer: mapAnswer(activeQuestion[questionId], value),
            message: result.response
          }
        }
      ]);
    }

    setActiveQuestion(result.question || null);
  };

  return (
    <ClanDesktopLayout>
      <IonPage>
        <ClanIonContent ref={ionContent} id="chatbot" scrollEvents>
          <SmoothRays />

          <div className="mx-5 pb-40">
            <h4 className="mt-8">
              <Trans>chatbot.question</Trans>
            </h4>

            <p className="m-0">
              <Trans>chatbot.question_description</Trans>
            </p>
            <IonList className="flex flex-col">
              {questionHistory &&
                questionHistory.map((questionHistoryItem) =>
                  Object.entries(
                    questionHistoryItem
                  ).map(([questionId, questionItem]) => (
                    <ChatQuestion
                      key={questionId}
                      id={questionId}
                      question={questionItem}
                      doAnswer={doAnswer}
                    />
                  ))
                )}

              {activeQuestion &&
                Object.entries(
                  activeQuestion
                ).map(([questionId, questionItem]) => (
                  <ChatQuestion
                    key={questionId}
                    id={questionId}
                    question={questionItem}
                    doAnswer={doAnswer}
                  />
                ))}
            </IonList>
          </div>
        </ClanIonContent>
        <IonFooter className="flex flex-col items-center" mode="ios">
          {!!activeQuestion && (
            <IonButton
              className="m-0 flex-shrink text-black text-base"
              fill="clear"
              onClick={goBack}
            >
              <Trans>chatbot.skip</Trans>
            </IonButton>
          )}
          {!activeQuestion && (
            <IonButton
              className="m-0 self-stretch font-extrabold font-gilroy text-black text-base"
              expand="full"
              onClick={goBack}
            >
              <Trans>chatbot.yay</Trans>
            </IonButton>
          )}
        </IonFooter>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default Chatbot;
