import { IonItem, IonLabel } from "@ionic/react";
import React from "react";
import styled from "styled-components";

import { ReactComponent as TeacherBadgeIcon } from "../icons/teacher_badge.svg";
import ClanProfileAvatar from "./ClanProfileAvatar";

const GroupFilter = styled(IonItem)`
  border-radius: 25rem;
  margin: 10px 12px;
  cursor: pointer;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --padding-start: 20px;
  --padding-end: 0;
  --background: #fafafa;
  --min-height: auto;
`;

const GroupFilterTitle = styled(IonLabel)`
  font-size: 14px;
  font-family: Gilroy;
  text-transform: uppercase;
`;

const Checkbox = styled.input`
  visibility: hidden;
  width: 28px;
`;

const CheckboxLabel = styled.label<{ selected?: boolean }>`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  right: 0;
  position: absolute;
  top: -2px;
  width: 28px;
  ${({ selected }) =>
    selected && "background-color: #45DD85; border-color: #45DD85;"}

  &:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
    ${({ selected }) => selected && "opacity: 1"}
  }
`;

interface ClanSelectableItemProps {
  onSelect: () => void;
  onDeselect: () => void;
  selected: boolean;
  profileThumbnailUrl?: string;
  profileFullName: string;
  isTeacher?: boolean;
  uniqueKey: string;
  isSelectable?: boolean;
}

const ClanSelectableItem: React.FC<ClanSelectableItemProps> = ({
  onSelect,
  onDeselect,
  selected,
  profileThumbnailUrl,
  profileFullName,
  uniqueKey,
  isTeacher = false,
  isSelectable = true
}) => {
  return (
    <GroupFilter
      key={uniqueKey}
      onClick={() => {
        selected ? onDeselect() : onSelect();
      }}
    >
      {profileThumbnailUrl && (
        <ClanProfileAvatar
          src={profileThumbnailUrl}
          alt={`${profileFullName} avatar`}
          className="w-8 h-8 mr-2 self-center justify-start"
        />
      )}
      <GroupFilterTitle>
        {profileFullName}
        {isTeacher && <TeacherBadgeIcon className="align-middle m-2" />}
      </GroupFilterTitle>
      {isSelectable && (
        <div className="relative">
          <Checkbox
            type="checkbox"
            checked={selected}
            onChange={() => (selected ? onDeselect() : onSelect())}
          />
          <CheckboxLabel selected={selected} />
        </div>
      )}
    </GroupFilter>
  );
};

export default ClanSelectableItem;
