import {
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ClanFlowStepTemplate from "../../components/ClanFlowStepTemplate";
import { connect } from "../../data/connect";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "../Onboarding/Onboarding";
import { SignupSteps } from "./Signup";

interface StateProps {
  isTeacher: boolean;
}

interface OwnProps {
  pushToErrors: (message: string) => void;
  currentStep: SignupSteps;
  setCurrentStep: (step: SignupSteps) => void;
  loading: boolean;
  submitSignupData: any;
  setCurrentFooterBtns: any;
  isEditing: boolean;
  history: any;
}

interface NameStepProps extends OwnProps, StateProps {}

const NameStep: React.FC<NameStepProps> = ({
  pushToErrors,
  currentStep,
  setCurrentStep,
  loading,
  submitSignupData,
  setCurrentFooterBtns,
  isEditing,
  history,
  isTeacher
}) => {
  const { trigger, errors, watch, control, getValues } = useFormContext();
  const watchFirstName = watch("firstName");
  const watchLastName = watch("lastName");
  const { t } = useTranslation();
  const nameStepBtn = async () => {
    if (await trigger(["firstName", "lastName"])) {
      isEditing ? submitSignupData() : setCurrentStep(SignupSteps.GENDER);
    } else {
      pushToErrors(
        Object.keys(errors).reduce((allErrors, key) => {
          return allErrors.concat(errors[key].message);
        }, "")
      );
    }
  };

  useEffect(() => {
    if (currentStep === SignupSteps.NAME) {
      setCurrentFooterBtns(
        isEditing
          ? [
              {
                title: t("general.cancel"),
                disabled: false,
                callback: () => history.goBack()
              },
              {
                title: t("general.ok"),
                disabled:
                  loading ||
                  !watchFirstName ||
                  watchFirstName?.length === 0 ||
                  watchLastName?.length === 0,
                callback: nameStepBtn
              }
            ]
          : [
              {
                title: t("general.back"),
                disabled: false,
                callback: () => setCurrentStep(SignupSteps.APP_LANGUAGE)
              },
              {
                title: t("general.next"),
                disabled:
                  loading ||
                  !watchFirstName ||
                  watchFirstName?.length === 0 ||
                  watchLastName?.length === 0,
                callback: nameStepBtn
              }
            ]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, isEditing, loading, watchFirstName, watchLastName]);

  const titlesList = ["Mr", "Mrs", "Ms", "Miss"];

  if (currentStep !== SignupSteps.NAME) {
    return null;
  }

  return (
    <ClanIonContent>
      <OnboardingWrapper>
        <ClanFlowStepTemplate title={t("signup.name_header")}>
          <IonItem id="clan-flowstep-item" className="mb-1">
            <Controller
              control={control}
              defaultValue={null}
              render={({ onChange }) => (
                <IonInput
                  onIonChange={(e) => onChange(e.detail.value)}
                  className="firstName text-clanH2 text-black font-extrabold font-gilroy"
                  autocapitalize="on"
                  placeholder={t("signup.first_name")}
                  value={getValues("firstName")}
                />
              )}
              name="firstName"
              rules={{
                required: {
                  value: true,
                  message: t("signup.first_name_required")
                }
              }}
            />
          </IonItem>

          <IonItem id="clan-flowstep-item" className="mb-1">
            <Controller
              control={control}
              defaultValue={null}
              render={({ onChange }) => (
                <IonInput
                  onIonChange={(e) => onChange(e.detail.value)}
                  className="lastName text-clanH2 text-black font-extrabold font-gilroy"
                  autocapitalize="on"
                  placeholder={t("signup.last_name")}
                  value={getValues("lastName")}
                />
              )}
              name="lastName"
              rules={{
                required: {
                  value: true,
                  message: t("signup.last_name_required")
                }
              }}
            />
          </IonItem>

          {isTeacher && (
            <IonItem id="clan-flowstep-item" className="mb-1">
              <IonLabel className="text-clanH4 text-black font-extrabold font-gilroy">
                {t("titles.title")}
              </IonLabel>
              <Controller
                control={control}
                defaultValue={null}
                render={({ onChange }) => (
                  <IonSelect
                    onIonChange={(e) => onChange(e.detail.value)}
                    interface="action-sheet"
                    className="text-clanH2 text-black font-extrabold font-gilroy"
                    value={getValues("title")}
                  >
                    {titlesList.map((title, index) => (
                      <IonSelectOption key={index} value={title}>
                        {t("titles." + title.toLowerCase())}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                )}
                name="title"
              />
            </IonItem>
          )}
        </ClanFlowStepTemplate>
      </OnboardingWrapper>
    </ClanIonContent>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: ({ token }) => ({
    isTeacher: UserEnabledProperties.Legacy.isAdult(token)
  }),
  component: NameStep
});
