import styled, { css } from "styled-components";

import { desktopOnlyStyles } from "../util/ResponsiveUtils";

import { ReactComponent as SmoothRaysImage } from "../icons/smooth-rays.svg";
import { ReactComponent as SmoothRaysInvertedImage } from "../icons/smooth-rays-inverted.svg";

const SmoothRaysBase = css`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`;

export const SmoothRays = styled(SmoothRaysImage)<{ showInDesktop?: boolean }>`
  ${SmoothRaysBase}

  ${({ showInDesktop }) =>
    !showInDesktop &&
    desktopOnlyStyles({
      display: "none"
    })}
`;

export const SmoothRaysInverted = styled(SmoothRaysInvertedImage)<{
  showInDesktop?: boolean;
}>`
  ${SmoothRaysBase}

  ${({ showInDesktop }) =>
    !showInDesktop &&
    desktopOnlyStyles({
      display: "none"
    })}
`;
