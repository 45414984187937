import { IonAvatar, IonItem } from "@ionic/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import ClanFlowStepTemplate from "../../components/ClanFlowStepTemplate";
import { ReactComponent as DefaultAvatarIcon } from "../../icons/default-avatar.svg";
import ClanCameraAccess from "../../util/ClanCameraAccess";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "../Onboarding/Onboarding";
import { SignupSteps } from "./Signup";

interface ProfileImageStepProps {
  currentStep: SignupSteps;
  pushToErrors: (message: string) => void;
  setCurrentStep: (step: SignupSteps) => void;
  loading: boolean;
  submitProfileImage: any;
  setCurrentFooterBtns: any;
  isEditing: any;
  history: any;
  image: string | undefined;
  setImage: (image: string) => void;
}

const ProfileImageStep: React.FC<ProfileImageStepProps> = ({
  pushToErrors,
  currentStep,
  setCurrentStep,
  loading,
  submitProfileImage,
  setCurrentFooterBtns,
  isEditing,
  history,
  image,
  setImage
}) => {
  const takePicture = async (): Promise<void> => {
    try {
      const photo = await ClanCameraAccess.TakePhoto();
      setImage(photo.image);
    } catch (error) {
      pushToErrors(error.message);
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (isEditing && image) {
      setImage(image);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  const profileImageStepBtn = async () => {
    if (image) {
      submitProfileImage();
    } else {
      setCurrentStep(SignupSteps.SUCCESS);
      //pushToErrors("Please upload an image first");
    }
  };

  useEffect(() => {
    if (currentStep === SignupSteps.PROFILE_IMAGE) {
      setCurrentFooterBtns(
        isEditing
          ? [
              {
                title: t("general.cancel"),
                disabled: false,
                callback: () => history.goBack()
              },
              {
                title: t("general.ok"),
                disabled: loading,
                callback: profileImageStepBtn
              }
            ]
          : [
              {
                title: t("general.back"),
                disabled: false,
                callback: () => setCurrentStep(SignupSteps.BIRTHDAY)
              },
              {
                title: t("general.next"),
                disabled: loading,
                callback: profileImageStepBtn
              }
            ]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, image, loading]);

  if (currentStep !== SignupSteps.PROFILE_IMAGE) {
    return null;
  }

  return (
    <ClanIonContent>
      <OnboardingWrapper>
        <ClanFlowStepTemplate
          title={t("signup.picture_header")}
          contentClassName="text-center"
        >
          <IonItem>
            <IonAvatar className="block h-clanProfileAvatar w-clanProfileAvatar mx-auto mb-6 border border-solid border-clanYellow-100 m-1 rounded-full self-center">
              {image ? (
                <img id="profilephoto" src={image} alt="avatar" />
              ) : (
                <DefaultAvatarIcon id="profilephoto" />
              )}
            </IonAvatar>
          </IonItem>

          <h4
            onClick={() => takePicture()}
            className="text-clanGreen-100 self-center cursor-pointer"
          >
            {t("signup.picture_add")}
          </h4>
        </ClanFlowStepTemplate>
      </OnboardingWrapper>
    </ClanIonContent>
  );
};

export default ProfileImageStep;
