import { IonPage, IonSlide, IonSlides } from "@ionic/react";
import React, { useRef, useState } from "react";
import styled from "styled-components";

import ClanDesktopLayout from "../components/ClanDesktopLayout";
import { FlexRow } from "../components/Flexbox";
import ClanBirthdays from "../components/PeopleOverview/ClanBirthdays";
import ClanBucketList from "../components/PeopleOverview/ClanBucketList";
import ClanHobbies from "../components/PeopleOverview/ClanHobbies";
import ClanLanguages from "../components/PeopleOverview/ClanLanguages";
import ActiveSchoolFilter from "../components/SchoolView/ActiveSchoolFilter";
import FilterButton from "../components/SchoolView/SchoolFilter";
import { ReactComponent as ArrowNavNextIcon } from "../icons/arrow-nav-next.svg";
import { ReactComponent as ArrowNavPrevIcon } from "../icons/arrow-nav-prev.svg";
import { Views } from "../util/Configuration";
import { useScreenSizeInfo } from "../util/CustomHooks";
import { useFilterResult } from "../util/Filters";
import { mobileOnlyStyles } from "../util/ResponsiveUtils";

const FilterWrapper = styled(FlexRow)`
  margin: 16px;
  column-gap: 8px;
  align-items: center;
`;

const PeopleWrapper = styled.div`
  background: ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: env(safe-area-inset-top);
`;

const StyledNav = styled.div`
  background-image: none;
  border-radius: 999px;
  background-color: #fafafa;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${mobileOnlyStyles({
    display: "none"
  })}
`;

const StyledNavLeft = styled(StyledNav)`
  left: 1.2rem;
`;

const StyledNavRight = styled(StyledNav)`
  right: 1.2rem;
`;

const StyledSlides = styled(IonSlides)`
  width: 100%;
  height: 100%;
`;

const StyledSlide = styled(IonSlide)`
  > div {
    > ion-card {
      height: calc(100% - 30px);
      margin-bottom: 20px;
    }
  }

  > ion-card {
    height: calc(100% - 30px);
    margin-bottom: 20px;
  }
`;

const SlideWithNav: React.FC<{
  disableLeft?: boolean;
  disableRight?: boolean;
  ionSlidesRef: React.RefObject<HTMLIonSlidesElement>;
  id?: string;
}> = ({
  disableLeft = false,
  disableRight = false,
  ionSlidesRef,
  id,
  children
}) => {
  return (
    <StyledSlide id={id}>
      {!disableLeft && (
        <StyledNavLeft
          className="swiper-button-prev"
          onClick={() => ionSlidesRef.current?.slidePrev()}
        >
          <ArrowNavPrevIcon />
        </StyledNavLeft>
      )}
      {children}
      {!disableRight && (
        <StyledNavRight
          className="swiper-button-next"
          onClick={() => ionSlidesRef.current?.slideNext()}
        >
          <ArrowNavNextIcon />
        </StyledNavRight>
      )}
    </StyledSlide>
  );
};

type PeopleProps = {};

export enum COMMUNITY_SLIDES {
  BIRTHDAYS,
  INTERESTS,
  BUCKETLIST,
  LANGUAGES
}

const People: React.FC<PeopleProps> = () => {
  const ionSlidesRef = useRef<HTMLIonSlidesElement>(null);
  const { isDesktopSize } = useScreenSizeInfo();
  const [activeIndex, setActiveIndex] = useState<COMMUNITY_SLIDES>(
    COMMUNITY_SLIDES.BIRTHDAYS
  );

  const [filters, setFilters, resolvedFilters] = useFilterResult(
    undefined,
    Views.Community
  );

  const onClearFilters = () =>
    setFilters(
      resolvedFilters.defaultFilter ? [resolvedFilters.defaultFilter] : []
    );

  return (
    <ClanDesktopLayout>
      <IonPage>
        <PeopleWrapper>
          <FilterWrapper>
            <ActiveSchoolFilter
              filters={filters}
              clearFilters={onClearFilters}
            />

            <FilterButton
              resolvedFilters={resolvedFilters}
              setFilters={setFilters}
              filters={filters}
              view={Views.Community}
            />
          </FilterWrapper>

          <StyledSlides
            onIonSlideDidChange={() =>
              ionSlidesRef.current
                ?.getActiveIndex()
                .then((i) => setActiveIndex(i))
            }
            ref={ionSlidesRef}
            id="people-slides"
            options={{
              speed: 400,
              slidesPerView: isDesktopSize ? 1 : 1.1,
              centeredSlides: true,
              spaceBetween: 0,
              hideOnClick: true,
              navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
                hideOnClick: true
              }
            }}
          >
            <SlideWithNav
              id="clan-birthdays-slide"
              ionSlidesRef={ionSlidesRef}
              disableLeft
            >
              <ClanBirthdays
                activeIndex={activeIndex}
                {...(filters.length > 0 && { filters })}
                {...(isDesktopSize && { className: "px-16" })}
              />
            </SlideWithNav>

            <SlideWithNav id="clan-interests-slide" ionSlidesRef={ionSlidesRef}>
              <ClanHobbies
                activeIndex={activeIndex}
                {...(isDesktopSize && { className: "px-16" })}
                {...(filters.length > 0 && { filters })}
              />
            </SlideWithNav>

            <SlideWithNav ionSlidesRef={ionSlidesRef}>
              <ClanBucketList
                activeIndex={activeIndex}
                {...(isDesktopSize && { className: "px-16" })}
                {...(filters.length > 0 && { filters })}
              />
            </SlideWithNav>

            <SlideWithNav ionSlidesRef={ionSlidesRef} disableRight>
              <ClanLanguages
                activeIndex={activeIndex}
                {...(isDesktopSize && { className: "px-16" })}
                {...(filters.length > 0 && { filters })}
              />
            </SlideWithNav>
          </StyledSlides>
        </PeopleWrapper>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default People;
