import {
  ClanArrayRequest,
  ClanMembershipListItemResponse,
  ClanProfileListItemResponse,
  ClanRoleAwareProfileResponse
} from "../models/ClanProfileModels";
import { doRequest, doRequestAndReturnResult } from "../../apiService";
import { UserSocialLinksType } from "../SocialLinks";
import {
  PaginatedStreamDataIterator,
  PaginatedStreamListItemResultWithOwnerIterator
} from "../ResultDataIterator";
import { ClanLabelAmount } from "../models/ClanValueAmount";
import { Views, ViewFilters } from "../Configuration";
import {
  FilterGroupType,
  joinFilterGroupTypeId,
  resolveConstantGroupFilter
} from "../Filters";
import { ListItemType } from "../Feeds";

export const getUserProfileById = async (
  profileId: number | string
): Promise<ClanRoleAwareProfileResponse> => {
  return doRequestAndReturnResult<ClanRoleAwareProfileResponse>(
    "get",
    `/user/profile/v1/${profileId}`
  );
};

export const getProfilesWithBirthdays = async (): Promise<
  ClanProfileListItemResponse[]
> => {
  return doRequestAndReturnResult<ClanProfileListItemResponse[]>(
    "get",
    "/user/profile/v1/profiles/group/sort/birthday"
  );
};

const resolveConstantFilterAndArguments = <T>(
  filters: FilterGroupType[],
  filterCallable: (filter: ViewFilters) => string,
  noFilterCallable: () => string
): PaginatedStreamDataIterator<T> => {
  const filter = resolveConstantGroupFilter(filters);
  const result = new PaginatedStreamDataIterator<T>(
    filter === undefined ? noFilterCallable() : filterCallable(filter.filter)
  );
  const getGroupIdList = joinFilterGroupTypeId(filters);
  if (getGroupIdList !== undefined && getGroupIdList.length > 0) {
    result.setQueryArgumentNoFetch("group_id", getGroupIdList);
  }
  return result;
};

export const getPaginatedProfilesForView = (
  view: Views
): PaginatedStreamDataIterator<ClanMembershipListItemResponse> => {
  return new PaginatedStreamDataIterator(
    `/group/membership/v1/users/view/${view}`
  );
};

export const getPaginatedProfiles = (
  viewFilter: Views,
  filters: FilterGroupType[]
): PaginatedStreamDataIterator<ClanMembershipListItemResponse> =>
  resolveConstantFilterAndArguments(
    filters,
    (filter) =>
      `/group/membership/v1/users/view/filter/${viewFilter}/${filter}`,
    () => `/group/membership/v1/users/view/${viewFilter}`
  );

export const getPaginatedProfilesDefault = (
  viewFilter: Views,
  filter: ViewFilters
): PaginatedStreamDataIterator<ClanMembershipListItemResponse> => {
  return new PaginatedStreamDataIterator(
    `/group/membership/v1/users/view/filter/${viewFilter}/${filter}`
  );
};

export const getPaginatedProfilesForItem = (
  viewFilter: Views,
  filters: FilterGroupType[],
  itemType: ListItemType,
  item: string
): PaginatedStreamListItemResultWithOwnerIterator<ClanMembershipListItemResponse> => {
  const filter = resolveConstantGroupFilter(filters);
  const url = filter
    ? `/group/membership/v1/users/view/filter/${viewFilter}/${filter.filter}/item/${itemType}/${item}`
    : `/group/membership/v1/users/view/${viewFilter}/item/${itemType}/${item}`;
  const result = new PaginatedStreamListItemResultWithOwnerIterator<ClanMembershipListItemResponse>(
    url
  );
  const getGroupIdList = joinFilterGroupTypeId(filters);
  if (getGroupIdList !== undefined && getGroupIdList.length > 0) {
    result.setQueryArgumentNoFetch("group_id", getGroupIdList);
  }
  return result;
};

export const getPaginatedLabelAmounts = (
  viewFilter: Views,
  filters: FilterGroupType[],
  itemType: ListItemType
): PaginatedStreamDataIterator<ClanLabelAmount> =>
  resolveConstantFilterAndArguments(
    filters,
    (filter) =>
      `/group/membership/v1/users/view/filter/${viewFilter}/${filter}/${itemType}`,
    () => `/group/membership/v1/users/view/${viewFilter}/${itemType}`
  );

export type ProfileNameKey = {
  name: string;
  key: string;
};

function doProfileRequest<T>(
  method: "get" | "put",
  endpoint: string,
  bodyObject?: object
): Promise<T> {
  return doRequestAndReturnResult<T>(method, endpoint, bodyObject);
}

const doProfileNameKeyRequest = async (
  method: "get" | "put",
  endpoint: string,
  bodyObject?: ClanArrayRequest
) => doProfileRequest<ProfileNameKey[]>(method, endpoint, bodyObject);

export const ProfileNationalitiesAPI = {
  list: async (): Promise<ProfileNameKey[]> =>
    doProfileNameKeyRequest("get", "/locale/nationality/v1/"),
  get: async (): Promise<ProfileNameKey[]> =>
    doProfileNameKeyRequest("get", "/user/profile/v1/nationalities"),
  put: async (values: string[]): Promise<ProfileNameKey[]> =>
    doProfileNameKeyRequest("put", "/user/profile/v1/nationalities", {
      values
    } as ClanArrayRequest)
};

export const ProfileLanguagesAPI = {
  list: async (): Promise<ProfileNameKey[]> =>
    doProfileNameKeyRequest("get", "/locale/languages/v1/ISO1"),
  get: async (): Promise<ProfileNameKey[]> =>
    doRequestAndReturnResult<ProfileNameKey[]>(
      "get",
      "/user/profile/v1/languages/spoken"
    ),
  put: async (values: string[]): Promise<ProfileNameKey[]> =>
    doProfileNameKeyRequest("put", "/user/profile/v1/languages/spoken", {
      values
    } as ClanArrayRequest)
};

export const InterestAPI = {
  list: async () =>
    doProfileRequest<string[]>("get", "/constants/interests/v1"),
  get: async () =>
    doProfileRequest<string[]>("get", "/user/profile/v1/interests"),
  add: async (values: string[]) =>
    doProfileRequest<string[]>("put", "/user/profile/v1/interests", {
      values
    } as ClanArrayRequest)
};

export const BucketListAPI = {
  list: async (): Promise<string[]> =>
    doProfileRequest("get", "/constants/bucket_list/v1"),
  get: async (): Promise<string[]> =>
    doProfileRequest("get", "/user/profile/v1/bucket_list"),
  add: async (values: string[]): Promise<string[]> =>
    doProfileRequest("put", "/user/profile/v1/bucket_list", {
      values
    } as ClanArrayRequest)
};

export type SocialLinkResponse = { [key: string]: UserSocialLinksType };
export type SocialLinkRequest = { [key: string]: string };

export const SocialLinkApi = {
  get: async (): Promise<SocialLinkResponse> => {
    const result = await doRequest("get", "/user/profile/v1/social_links");
    return result.data as SocialLinkResponse;
  },
  add: async (values: SocialLinkRequest): Promise<SocialLinkResponse> => {
    const result = await doRequest("put", "/user/profile/v1/social_links", {
      values
    });
    return result.data as SocialLinkResponse;
  }
};
