import { ToastsState, Toast } from "./toasts.state";
import { SHOW_TOAST, REMOVE_TOAST } from "./toasts.actions";

export type ToastAction = {
  type: string;
  data: Toast;
};

export function toastsReducer(
  state: ToastsState,
  action: ToastAction
): ToastsState {
  switch (action.type) {
    case SHOW_TOAST: {
      return {
        toasts: [...state.toasts, action.data]
      };
    }
    case REMOVE_TOAST: {
      const newToasts = [...state.toasts];
      const indexOfToast = state.toasts.findIndex(
        (toast) => toast.message === action.data.message
      );

      if (indexOfToast >= 0) {
        newToasts.splice(indexOfToast, 1);
      }

      return {
        toasts: newToasts
      };
    }
    default:
      return state;
  }
}
