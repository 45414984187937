import {
  IonActionSheet,
  IonAvatar,
  IonHeader,
  IonIcon,
  IonPage,
  IonPopover,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import { useAsync } from "@react-hook/async";
import { differenceInMinutes } from "date-fns";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import Backdrop from "../../components/Backdrop";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import ClanDiscussionPostRemoveModal from "../../components/ClanDiscussionPost/ClanDiscussionPostRemoveModal";
import ClanDiscussionPostReportModal from "../../components/ClanDiscussionPost/ClanDiscussionPostReportModal";
import ClanFooterInput from "../../components/ClanFooterInput";
import ClanLoader from "../../components/ClanLoader";
import ChatMessage from "../../components/ListItem/ChatMessage";
import { H4 } from "../../components/Typography";
import { updateMessageRead } from "../../data/app/app.actions";
import { connect } from "../../data/connect";
import { ReactComponent as TeacherBadgeIcon } from "../../icons/teacher_badge.svg";
import {
  fetchDirectMessagesForProfile,
  initPaginatedDirectMessages,
  postNewDirectMessage
} from "../../util/api/DirectMessageService";
import { getUserProfileById } from "../../util/api/ProfileService";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import {
  isReadyStateCancelOrFail,
  isReadyStateLoading
} from "../../util/AsyncEffectFilter";
import { Views } from "../../util/Configuration";
import { usePrevious } from "../../util/CustomHooks";
import { updatePost } from "../../util/Feeds";
import { waitUntilElementExists } from "../../util/HTMLUtils";
import {
  ClanEditPostEditDetails,
  ClanPostListResultV1
} from "../../util/models/ClanPostModels";
import { ClanRoleAwareProfileResponse } from "../../util/models/ClanProfileModels";
import { ResultDataIterator } from "../../util/ResultDataIterator";
import { addPostImageToFeed } from "./DiscussionHelper";

const MessagesWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const StyledIonPopover = styled(IonPopover)`
  --width: 260px;
`;

const StyledHeader = styled(IonHeader)`
  min-height: 6rem;
  padding: 1rem;
  padding-top: max(1rem, env(safe-area-inset-top));
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-content: center;
  background: #fafafa;
`;

export const StyledAvatar = styled(IonAvatar)`
  margin: 0 0.5rem 0 0;
`;

const HeaderTitle = styled(H4)`
  margin: 0;
`;

let directMessageIterator: ResultDataIterator<ClanPostListResultV1>;

interface DispatchProps {
  dispatchMessageRead: typeof updateMessageRead;
}

const DirectMessage: React.FC<DispatchProps> = ({ dispatchMessageRead }) => {
  const [openPostBar, setOpenPostBar] = useState<boolean>(false);
  const [allMessages, setAllMessages] = useState<ClanPostListResultV1[]>([]);
  const [editMessageId, setEditMessageId] = useState<string | undefined>();

  const [reportMessageId, setReportMessageId] = useState<string | undefined>();
  const [removeMessageId, setRemoveMessageId] = useState<string | undefined>();
  const [menuOpenMessageId, setMenuOpenMessageId] = useState<
    string | undefined
  >();
  const [chatParty, setChatParty] = useState<ClanRoleAwareProfileResponse>();

  const [messagesWithLocalImages, setMessagesWithLocalImages] = useState<
    ClanEditPostEditDetails[]
  >([]);
  const [allMessagesWithLocalImages, setAllMessagesWithLocalImages] = useState<
    ClanPostListResultV1[]
  >([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const contentRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/camelcase
  const { profile_id: profileId } = useParams<{ profile_id: string }>();
  // eslint-disable-next-line @typescript-eslint/camelcase
  const history = useHistory();
  const { t } = useTranslation();

  const previousAllMessages = usePrevious(allMessages);

  const allMessagesRef = useRef(allMessages);
  useEffect(() => {
    allMessagesRef.current = allMessages;
  }, [allMessages]);

  function scrollToBottom(smootly: boolean) {
    const height = contentRef.current?.scrollHeight || 0;
    contentRef.current?.scrollTo({
      top: height,
      left: 0,
      behavior: smootly ? "smooth" : "auto"
    });
  }

  useEffect(() => {
    directMessageIterator = initPaginatedDirectMessages(profileId);
    directMessageIterator.reset().then(() =>
      directMessageIterator.getNext().then(async (result) => {
        await setAllMessages([...result.reverse()]);
        result.length !== 0 &&
          waitUntilElementExists(
            `#message-${result[result.length - 1].id}`,
            2000
          )
            .then(() => {
              // setTimeout(() => contentRef.current?.scrollToBottom(500), 1000);
              setTimeout(() => {
                scrollToBottom(false);
              }, 500);
            })

            .catch(() => console.warn("Last message not yet loaded"));
        directMessageIterator.hasNext().then(setHasMore);
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  const setOrAddNewMessages = (
    currentMessages: ClanPostListResultV1[],
    pulledMessages: ClanPostListResultV1[],
    replaceNotFoundWithDeleted = false
  ) => {
    const existingMessages = currentMessages.map((existingMessage, index) => {
      const newMessage = pulledMessages.find(
        (recentMessage) => recentMessage.id === existingMessage.id
      );
      if (newMessage) return newMessage;
      // If the missing message is amongs the first iterator results replace with mock message
      /*else if (
        replaceNotFoundWithDeleted &&
        index > currentMessages.length - pulledMessages.length
      ) {
        return ({
          createdAt: existingMessage.createdAt,
          groupShareAmount: 0,
          html: "<i>This message was deleted</i>",
          id: null,
          images: {},
          mood: null,
          permissions: {
            remove: false,
            report: false,
            like: false,
            comment: false,
            edit: false
          },
          profileShareAmount: 1,
          reactions: {},
          repliesAmount: 0,
          sharedGroups: null,
          sharedProfiles: null,
          task: null,
          title: null,
          userProfile: existingMessage.userProfile
        } as unknown) as ClanPostListResult;
      }*/
      return existingMessage;
    });
    const newMessages = pulledMessages.filter(
      (newMessage) =>
        !existingMessages.find(
          (existingMessage) => existingMessage.id === newMessage.id
        )
    );
    setAllMessages(
      [...existingMessages, ...newMessages.reverse()].sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
    );
  };

  useEffect(() => {
    const loadMessages = async () => {
      const recentMessages = await fetchDirectMessagesForProfile(profileId);
      setOrAddNewMessages(allMessagesRef.current, recentMessages, true);
    };

    const timerId = setInterval(() => {
      // console.warn(`Fetching new allMessages with timer ${timerId}`);
      // noinspection JSIgnoredPromiseFromCall
      loadMessages();
    }, 5000);
    return () => {
      // console.warn(
      //   `Stopping message polling from backend for timer ${timerId}`
      // );
      clearInterval(timerId);
    };
  }, [allMessagesRef, profileId]);

  const [userProfileStatus, loadUserProfile] = useAsync(async () => {
    try {
      const response = await getUserProfileById(profileId);
      if (!response || isReadyStateCancelOrFail(userProfileStatus)) return;
      setChatParty(response);
    } catch (e) {
      // TODO errors are swallowed up
      console.error("[DIRECT_MESSAGING] Couldn't load chat party profile", e);
    }
  });

  const loadNextPage = useCallback(async () => {
    if (!hasMore) return;
    if (!directMessageIterator) return;
    directMessageIterator
      .getNext()
      .then((result) => setOrAddNewMessages(allMessagesRef.current, result))
      .then(() => directMessageIterator.hasNext().then(setHasMore));
  }, [hasMore, allMessagesRef]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    loadUserProfile();
    // noinspection JSIgnoredPromiseFromCall
    setAnalyticsScreenName("DIRECT_MESSAGE_PAGE");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  const memorizedEditMessagePermissions = useMemo(() => {
    const getMessagePermissions = (messageId: string | undefined) => {
      if (!messageId) return undefined;
      return allMessages?.find((message) => message.id === messageId)
        ?.permissions;
    };
    return getMessagePermissions(menuOpenMessageId);
  }, [menuOpenMessageId, allMessages]);

  useEffect(() => {
    if (
      contentRef.current &&
      !menuOpenMessageId &&
      !editMessageId &&
      !reportMessageId &&
      !removeMessageId &&
      allMessages?.length !== 0 &&
      allMessages?.length !== previousAllMessages?.length &&
      allMessages?.length - previousAllMessages?.length < 5
    ) {
      setTimeout(() => {
        scrollToBottom(true);
      }, 500);

      // setTimeout(() => contentRef.current?.scrollToBottom(500), 500);
    }
  }, [
    editMessageId,
    menuOpenMessageId,
    allMessages,
    previousAllMessages,
    removeMessageId,
    reportMessageId
  ]);

  useEffect(() => {
    if (allMessages) {
      setAllMessagesWithLocalImages(
        addPostImageToFeed(
          allMessages,
          messagesWithLocalImages ? messagesWithLocalImages : []
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMessages, messagesWithLocalImages]);

  const [submitStatus, submit] = useAsync(
    async ({ id, text, image, mood }: ClanEditPostEditDetails) => {
      const args = {
        ...(id && { id }),
        ...(text && { text }),
        ...(mood && { mood }),
        ...(image && { image }),
        sharedProfiles: Array.from([Number(profileId)])
      } as ClanEditPostEditDetails;

      // noinspection ES6MissingAwait
      analyticsLogEvent("DIRECT_MESSAGE_POST_ADD_OR_UPDATE");
      if (id) {
        const result = await updatePost(Views.Chat, id, args);
        if (image) {
          setMessagesWithLocalImages([
            ...messagesWithLocalImages,
            ({
              ...result,
              image
            } as unknown) as ClanEditPostEditDetails
          ]);
        }
        setAllMessages(
          allMessages.map((message) =>
            message.id === id
              ? (({
                  ...result,
                  ...(image && { images: { thumbnail: { url: image } } }),
                  html: result.text
                } as unknown) as ClanPostListResultV1)
              : message
          )
        );
        return;
      }
      const result = await postNewDirectMessage(args);
      dispatchMessageRead(result.id);

      if (image) {
        setMessagesWithLocalImages([
          ...messagesWithLocalImages,
          ({ ...result, image } as unknown) as ClanEditPostEditDetails
        ]);
      }
      setAllMessages([
        ...allMessages,
        ({
          ...result,
          ...(image && { image }),
          html: result.text
        } as unknown) as ClanPostListResultV1
      ]);

      // contentRef.current?.scrollToBottom(300);
      setTimeout(() => {
        scrollToBottom(true);
      }, 500);
      setOpenPostBar(false);
    }
  );

  const onOpenPostBarChange = (newOpenPostBar: boolean) => {
    setOpenPostBar(newOpenPostBar);
    if (newOpenPostBar) {
      setTimeout(() => {
        scrollToBottom(false);
      }, 0);
    }
  };

  return (
    <ClanDesktopLayout>
      <IonPage>
        {removeMessageId && (
          <ClanDiscussionPostRemoveModal
            title={t("general.delete_post")}
            postId={removeMessageId}
            removeQuestion={t("general.delete_post_confirm")}
            onDone={() => {
              setAllMessages(
                allMessages.filter((message) => message.id !== removeMessageId)
              );
              setRemoveMessageId(undefined);
            }}
            onCancel={() => setRemoveMessageId(undefined)}
            isOpen={!!removeMessageId}
          />
        )}
        {reportMessageId && (
          <ClanDiscussionPostReportModal
            title={t("general.report_to_teacher")}
            postId={reportMessageId}
            onDone={() => setReportMessageId(undefined)}
            onCancel={() => setReportMessageId(undefined)}
            isOpen={!!reportMessageId}
          />
        )}
        <StyledHeader mode="ios">
          <IonIcon
            className="p-4 mr-4 h-6 w-6 cursor-pointer"
            src="/assets/arrow_back_black.svg"
            onClick={() => history.goBack()}
          />
          <HeaderTitle>
            {chatParty?.fullName}{" "}
            {chatParty &&
              UserEnabledProperties.UserStatus.hasTeacherBadge(chatParty) && (
                <TeacherBadgeIcon className="align-middle m-2" />
              )}
          </HeaderTitle>
        </StyledHeader>
        {openPostBar && (
          <Backdrop
            zIndex={10}
            onClick={() => {
              setOpenPostBar(false);
              setEditMessageId(undefined);
            }}
          />
        )}
        <ClanLoader
          message={t("general.loading")}
          showLoading={isReadyStateLoading(submitStatus)}
        />
        <MessagesWrapper ref={contentRef}>
          {hasMore && (
            <IonSegment>
              <IonSegmentButton
                className="font-extrabold font-gilroy text-clanGreen-100"
                disabled={!hasMore}
                onClick={loadNextPage}
              >
                {hasMore ? t("general.list_load_more") : t("general.list_end")}
              </IonSegmentButton>
            </IonSegment>
          )}
          {allMessagesWithLocalImages.length !== 0 ? (
            allMessagesWithLocalImages.map(
              (message: ClanPostListResultV1, index) => (
                <ChatMessage
                  key={index}
                  message={message}
                  showTime={
                    differenceInMinutes(
                      new Date(message?.createdAt),
                      new Date(allMessagesWithLocalImages[index - 1]?.createdAt)
                    ) >= 1
                  }
                  setMenuOpenMessageId={setMenuOpenMessageId}
                />
              )
            )
          ) : (
            <p className="m-8">{t("directmessage.no_messages")}</p>
          )}
        </MessagesWrapper>
        <ClanFooterInput
          onSubmit={submit}
          setEditMessageId={setEditMessageId}
          editMessageId={editMessageId}
          setOpenPostBar={onOpenPostBarChange}
          openPostBar={openPostBar}
          placeholder={
            chatParty?.firstName
              ? t("directmessage.send_message_placeholder_with_name", {
                  name: `${chatParty?.firstName}`
                })
              : t("directmessage.send_message_placeholder")
          }
        />
        {memorizedEditMessagePermissions && (
          <IonActionSheet
            mode="ios"
            translucent
            isOpen={menuOpenMessageId !== undefined}
            onDidDismiss={() => setMenuOpenMessageId(undefined)}
            buttons={[
              ...(memorizedEditMessagePermissions?.edit
                ? [
                    {
                      text: t("general.edit"),
                      handler: () => {
                        setEditMessageId(menuOpenMessageId);
                      }
                    }
                  ]
                : []),
              ...(memorizedEditMessagePermissions?.remove
                ? [
                    {
                      text: t("general.remove"),
                      handler: () => {
                        setRemoveMessageId(menuOpenMessageId);
                      }
                    }
                  ]
                : []),
              ...(memorizedEditMessagePermissions?.report
                ? [
                    {
                      text: t("general.report"),
                      handler: () => {
                        setReportMessageId(menuOpenMessageId);
                      }
                    }
                  ]
                : [])
            ]}
          />
        )}
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<DispatchProps>({
  mapDispatchToProps: {
    dispatchMessageRead: updateMessageRead
  },
  component: DirectMessage
});
