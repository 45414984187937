import { IonHeader, IonIcon } from "@ionic/react";
import React, { ReactNode } from "react";
import styled from "styled-components";

const StyledSubPageHeader = styled(IonHeader)`
  border-bottom: 3px solid ${({ theme }) => theme.primary};
`;

const HeaderContent = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
`;

interface SubPageHeaderProps {
  title: string | ReactNode;
  onBack: () => void;
}

const SubPageHeader: React.FC<SubPageHeaderProps> = ({
  title,
  onBack,
  children,
  ...rest
}) => (
  <StyledSubPageHeader mode="ios">
    <HeaderContent {...rest}>
      <IonIcon
        className="p-4 mr-4 h-6 w-6 cursor-pointer"
        src="/assets/arrow_back_black.svg"
        onClick={onBack}
      />
      <h3 className="my-1 self-center">{title}</h3>
    </HeaderContent>
    {children}
  </StyledSubPageHeader>
);

export default SubPageHeader;
