import styled from "styled-components";

const Backdrop = styled.div<{ zIndex?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(238, 238, 238, 0.75);
  z-index: ${({ zIndex }) => zIndex || 2};
`;

export default Backdrop;
