import { IonContent, IonImg } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useScreenSizeInfo } from "../../util/CustomHooks";

import {
  PrimaryButton,
  SecondaryButton
} from "../../components/Buttons/Button";
import { SmoothRaysInverted } from "../../components/ClanSmoothRays";
import ClanToolbar from "../../components/ClanToolbar";
import {
  StartContentWrapper,
  StartDescription,
  StartSteps,
  StartTitle,
  StartTitleWrapper,
  StartToolbar,
  StartWrapper
} from "./Start";

const StyledToolbar = styled(ClanToolbar)`
  padding-bottom: 1rem !important;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

interface IntroProps {
  setCurrentStep: (step: StartSteps) => void;
}

const Intro: React.FC<IntroProps> = ({ setCurrentStep }) => {
  const { t } = useTranslation();
  const { isDesktopSize } = useScreenSizeInfo();

  return (
    <IonContent>
      <StartWrapper>
        <StartToolbar id="dashboard-header">
          <SmoothRaysInverted />
          {!isDesktopSize && (
            <StyledToolbar>
              <IonImg
                src="/assets/cb-logo-web.png"
                alt="logo"
                style={{ width: "160px", maxWidth: "90%" }}
              />
            </StyledToolbar>
          )}
        </StartToolbar>
        <StartContentWrapper>
          <StartTitleWrapper>
            <StartTitle>{t("start.intro.title")}</StartTitle>
            <StartDescription>{t("start.intro.description")}</StartDescription>
          </StartTitleWrapper>
          <ButtonsWrapper>
            <PrimaryButton
              onClick={() => setCurrentStep(StartSteps.ROLESELECT)}
            >
              {t("start.intro.get_started_btn")}
            </PrimaryButton>
            <SecondaryButton onClick={() => setCurrentStep(StartSteps.METHODS)}>
              {t("start.intro.existing_account_btn")}
            </SecondaryButton>
          </ButtonsWrapper>
        </StartContentWrapper>
      </StartWrapper>
    </IonContent>
  );
};

export default Intro;
