import { IonSegment, IonSegmentButton } from "@ionic/react";
import { useAsync } from "@react-hook/async";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import { connect } from "../../data/connect";
import { OauthState } from "../../data/oauth/oauth.state";
import { OauthData } from "../../data/oauth/oauthDataService";
import { showToast } from "../../data/toasts/toasts.actions";
import { ReactComponent as NestIcon } from "../../icons/button-icons/nest.svg";
import { isReadyStateLoading } from "../../util/AsyncEffectFilter";
import { joinWithInvitationCode } from "../../util/CommunityJoin";
import {
  getOrganisationDataIterator,
  OrganisationListResult,
  switchOrganisation
} from "../../util/Organisation";
import ClanBottomModal from "../ClanBottomModal";
import FooterButtons, { PrimaryFooterButton } from "../FooterButtons";
import IconHeading from "../Headings/IconHeading";
import SelectableListItem from "../ListItem/SelectableListItem";
import { SectionContent } from "../Section";
import AccessCodeModal from "./AccessCodeModal";

interface OwnProps {
  onClose: () => void;
}
interface StateProps {
  oauthState: OauthState;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface NestPickerModalProps extends StateProps, DispatchProps, OwnProps {}

const NestPickerModal: React.FC<NestPickerModalProps> = ({
  oauthState,
  onClose,
  dispatchToast
}) => {
  const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);

  const organisationDataIterator = useRef(getOrganisationDataIterator());

  const [organisations, setOrganisations] = useState<
    Array<OrganisationListResult>
  >([]);

  const [hasMoreOrganisations, setHasMoreOrganisations] = useState<boolean>(
    false
  );

  const [selectOrganisationStatus, selectOrganisation] = useAsync(
    async (id: number) => {
      if (!oauthState.hasToken || !id) return;
      try {
        await switchOrganisation(oauthState as OauthData, id);
        dispatchToast(t("nest_selection.success_toast"));
        onClose();
      } catch (e) {
        dispatchToast(
          t("TODO: translate this- can not switch to this organisation!")
        );
      }
    }
  );

  const [loadMoreState, loadMore] = useAsync(async () => {
    const result = await organisationDataIterator.current.getNext();
    setOrganisations([...organisations, ...result]);
    organisationDataIterator.current.hasNext().then(setHasMoreOrganisations);
  });

  const [initStatus, initOrganisations] = useAsync(async () => {
    await organisationDataIterator.current.reset();
    setOrganisations([]);
    await loadMore();
  });

  const [joinStatus, joinWithCode] = useAsync(async (accessCode: string) => {
    try {
      const result = await joinWithInvitationCode(
        oauthState as OauthData,
        accessCode
      );
      initOrganisations();
      setShowAccessCodeModal(false);
      dispatchToast(
        t("access_code_modal.success_toast", { label: result.name })
      );
    } catch (e) {
      dispatchToast(t("access_code_modal.error_toast"));
    }
  });

  useEffect(() => {
    initOrganisations();
    setAnalyticsScreenName("NESTS");
    analyticsLogEvent("NESTS_show");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  const closeNestPickerModal = () => {
    if (!showAccessCodeModal) onClose();
  };

  return (
    <>
      {showAccessCodeModal && (
        <AccessCodeModal
          disabled={isReadyStateLoading(joinStatus)}
          onSubmit={joinWithCode}
          onClose={() => setShowAccessCodeModal(false)}
        />
      )}

      <ClanBottomModal isOpen={true} onDidDismiss={closeNestPickerModal}>
        <SectionContent>
          <IconHeading
            title={t("nestpicker.title")}
            description={t("nestpicker.subtitle")}
            icon={<NestIcon />}
          />

          {!isReadyStateLoading(initStatus) &&
            organisations.map(({ id, name, current }) => (
              <SelectableListItem
                key={id}
                selected={current}
                onClick={() => {
                  if (isReadyStateLoading(selectOrganisationStatus)) return;
                  analyticsLogEvent("SELECT_NEST_CLICK");
                  !current && selectOrganisation(id);
                }}
              >
                {name}
              </SelectableListItem>
            ))}
        </SectionContent>

        {hasMoreOrganisations && (
          <IonSegment>
            <IonSegmentButton
              disabled={isReadyStateLoading(loadMoreState)}
              className="font-extrabold font-gilroy text-clanGreen-100"
              onClick={loadMore}
            >
              {t("general.list_load_more")}
            </IonSegmentButton>
          </IonSegment>
        )}

        <FooterButtons>
          <PrimaryFooterButton
            onClick={() => {
              analyticsLogEvent("JOIN_NEST_CLICK");
              setShowAccessCodeModal(true);
            }}
          >
            {t("nest_selection.join")}
          </PrimaryFooterButton>
        </FooterButtons>
      </ClanBottomModal>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    oauthState: state.token
  }),
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: NestPickerModal
});
