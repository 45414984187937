import styled, { css } from "styled-components";

export const CardStyle = css`
  border-radius: 25px;
  background: #ffffff;
  border: 2px solid #fafafa;
  box-shadow: 0px 8px 20px rgba(217, 154, 119, 0.08),
    0px 6px 10px rgba(64, 64, 64, 0.05), 0px 4px 8px rgba(64, 64, 64, 0.04),
    0px 3px 6px rgba(64, 64, 64, 0.03), 0px 2px 3px rgba(64, 64, 64, 0.02),
    0px 1px 2px rgba(64, 64, 64, 0.0112458);
`;

const Card = styled.div`
  ${CardStyle}
`;

export default Card;
