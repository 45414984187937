import React from "react";
import styled from "styled-components";

import { ActionLink } from "./Buttons/Button";

const ActionCardWrapper = styled.div`
  position: relative;
  border-radius: 25px;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 -2px 6px -2px rgb(0 0 0 / 5%);
  margin: 20px 0;
`;

const Description = styled.p`
  margin: 0 80px 5px 5px;
  font-family: Gilroy;
`;

const IconWrapper = styled.div`
  position: absolute;
  padding: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.primary};
  bottom: -10px;
  right: -10px;
  background: #fafafa;

  svg,
  img {
    display: block;
    width: 50px;
    height: 50px;
  }
`;

interface ActionCardProps {
  description: string;
  buttonLabel: string;
  icon: JSX.Element;
  onClick?: () => void;
  className?: string;
  href?: string;
}

const ActionCard: React.FC<ActionCardProps> = ({
  description,
  buttonLabel,
  icon,
  onClick,
  href,
  className
}) => (
  <ActionCardWrapper className={className}>
    <Description>{description}</Description>
    <ActionLink onClick={onClick} href={href} target="_blank">
      {buttonLabel}
    </ActionLink>
    <IconWrapper>{icon}</IconWrapper>
  </ActionCardWrapper>
);

export default ActionCard;
