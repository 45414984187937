import { OauthState } from "../data/oauth/oauth.state";
import { OauthData } from "../data/oauth/oauthDataService";
import { LatestTimestampForChatId } from "../data/app/app.state";
import { isAfter } from "date-fns";
import { getNotificationHistory } from "./api/ActivityFeedNavigationService";
import { fetchDirectMessages } from "./api/DirectMessageService";
import { useEffect, useState } from "react";
import { useAsyncEffect } from "@react-hook/async";

const timeAwareValue = <T>(
  callable: (value: T) => Promise<boolean>
): ((token: OauthState, value: T) => Promise<boolean>) => {
  let lastTimestamp = -1;
  let lastData = false;
  return async (token: OauthState, value: T): Promise<boolean> => {
    if (!token?.hasToken) return lastData;
    const currentTime = new Date().getTime();
    if (isAfter(lastTimestamp, currentTime)) {
      return lastData;
    }
    lastTimestamp = currentTime + 50000000;
    try {
      lastData = await callable(value);
    } finally {
      // eslint-disable-next-line
      lastTimestamp = currentTime + 5000;
    }
    return lastData;
  };
};

const checkLastActivity = timeAwareValue(
  async (lastActivity?: string): Promise<boolean> => {
    if (!lastActivity) return false;
    try {
      const result = await getNotificationHistory();
      if (!result || result.length === 0) return false;
      return isAfter(new Date(result[0].created), new Date(lastActivity));
    } catch (error) {
      console.error("Error while checking last activity", error);
      return false;
    }
  }
);

const checkLastMessage = timeAwareValue(
  async (
    chatHubLatestTimestamps?: LatestTimestampForChatId[]
  ): Promise<boolean> => {
    if (!chatHubLatestTimestamps || chatHubLatestTimestamps.length === 0)
      return false;
    try {
      const result = await fetchDirectMessages();
      if (!result || result.length === 0) return false;
      return result.some((newLatest) => {
        const matchingCachedLatest = chatHubLatestTimestamps.find(
          (cachedLatest) => cachedLatest.id === newLatest.id
        );
        if (!matchingCachedLatest) return true;
        return isAfter(
          new Date(newLatest.createdAt),
          new Date(matchingCachedLatest.timestamp)
        );
      });
    } catch (error) {
      console.error("Error while checking last messages", error);
      return false;
    }
  }
);

const useMethodAsyncEffect = <S>(
  token: OauthState | OauthData,
  otherArgument: S,
  callable: (
    token: OauthState | OauthData,
    otherArgument: S
  ) => Promise<boolean>
): boolean => {
  const [lastState, setLastState] = useState<boolean>(false);
  const [lastCheck, setLastCheck] = useState<number>(0);
  const stateStatus = useAsyncEffect(async () => {
    const currentTime = new Date().getTime();
    if (isAfter(lastCheck, currentTime)) {
      return lastState;
    }
    setLastCheck(currentTime + 100);
    try {
      return await callable(token, otherArgument);
    } finally {
      setLastCheck(new Date().getTime());
    }
  }, [token, otherArgument]);
  useEffect(() => {
    if (stateStatus.value !== null && stateStatus.value !== undefined) {
      if (stateStatus.value !== lastState) {
        setLastState(stateStatus.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStatus]);
  return lastState;
};

export function usePendingItemsInfo(
  token: OauthState | OauthData,
  lastActivityTimestamp?: string,
  chatHubLatestTimestamps?: LatestTimestampForChatId[]
) {
  const activitiesPending = useMethodAsyncEffect(
    token,
    lastActivityTimestamp,
    checkLastActivity
  );
  const pendingChatMessages = useMethodAsyncEffect(
    token,
    chatHubLatestTimestamps,
    checkLastMessage
  );
  return {
    activitiesPending,
    pendingChatMessages
  };
}
