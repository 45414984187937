import React from "react";
import styled from "styled-components";

interface ScrollWrapperProps {
  itemsCount: number;
  visibleColumns: number;
}

const ScrollWrapper = styled.div<ScrollWrapperProps>`
  display: grid;
  grid-auto-flow: column;
  overflow: auto hidden;
  scroll-snap-type: x mandatory;
  column-gap: 8px;
  margin: 0px -5% -20px;
  padding-left: 5%;
  padding-bottom: 8px;
  scroll-padding: 0px 24px;
  grid-template-columns: repeat(
    ${({ itemsCount }) => itemsCount},
    calc((95% - 16px) / ${({ visibleColumns }) => visibleColumns})
  );

  &::after {
    content: "";
    display: block;
    width: 8px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface HorizontalScrollProps {
  visibleColumns?: number;
}

const HorizontalScroll: React.FC<HorizontalScrollProps> = ({
  visibleColumns,
  children
}) => (
  <ScrollWrapper
    itemsCount={React.Children.toArray(children).length}
    visibleColumns={visibleColumns || 3}
  >
    {children}
  </ScrollWrapper>
);

export default HorizontalScroll;
