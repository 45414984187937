import { OauthState } from "./oauth.state";
import { OauthStorageAction, SET_CLANBEAT_TOKEN } from "./oauth.actions";

export function oauthReducer(
  state: OauthState,
  action: OauthStorageAction
): OauthState {
  if (action.type === SET_CLANBEAT_TOKEN) {
    return action.token;
  }
  return state;
}
