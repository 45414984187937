import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";

export const useLargeTabs = (type: any) => {
  const history = useHistory();
  const location = useLocation();

  function setTab(newTab: typeof type) {
    history.replace({
      search: "?" + new URLSearchParams({ tab: newTab }).toString()
    });
  }

  const searchParams = new URLSearchParams(location.search);
  const searchTabParam = searchParams.get("tab") as typeof type;

  return [searchTabParam || Object.values(type)[0], setTab, !!searchTabParam];
};

const Navigation = styled.div<{ long?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ long }) => (long ? "space-around" : "center")};
  padding: 0 1.2rem 1.4rem;
`;

const NavIcon = styled.div<{ active?: boolean }>`
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 50%;
  display: inline-block;
  width: 5.5rem;
  height: 5.5rem;
  position: relative;

  background: ${({ active = false, theme }) =>
    active ? theme.primary : "transparent"};

  > svg,
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: block;
  }
`;

const LongNavIcon = styled(NavIcon)`
  border: unset;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -15px;
  background: ${({ active = false }) => (active ? "white" : "#E0E0E0")};
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0.8rem;
  cursor: pointer;

  > span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    margin-top: 0.3rem;
    text-transform: uppercase;
  }
`;

const LongNavItem = styled(NavItem)<{ active?: boolean }>`
  flex-direction: row;
  overflow: hidden;
  box-shadow: 0px 7px 10px rgb(212 189 189 / 50%);
  border-radius: 9999px;
  background: ${({ active = false, theme }) =>
    active ? theme.primary : "transparent"};
  > span {
    white-space: nowrap;
    padding: 0px 1rem;
    margin-top: unset;
  }
`;

interface TabBtnProps extends TabButton {
  id?: string;
  title: string;
  icon: string | JSX.Element;
  activeIcon: string | JSX.Element;
  isActive: boolean;
  onClick: () => void;
  long: boolean;
}

const TabBtn: React.FC<TabBtnProps> = ({
  id,
  title,
  icon,
  activeIcon,
  isActive,
  onClick,
  long
}) =>
  long ? (
    <LongNavItem id={id} onClick={onClick} active={isActive}>
      <LongNavIcon active={isActive}>
        {isActive ? (
          typeof activeIcon === "string" ? (
            <img src={activeIcon} alt={title} />
          ) : (
            activeIcon
          )
        ) : typeof icon === "string" ? (
          <img src={icon} alt={title} />
        ) : (
          icon
        )}
      </LongNavIcon>
      <span>{title}</span>
    </LongNavItem>
  ) : (
    <NavItem id={id} onClick={onClick}>
      <NavIcon active={isActive}>
        {isActive ? (
          typeof activeIcon === "string" ? (
            <img src={activeIcon} alt={title} />
          ) : (
            activeIcon
          )
        ) : typeof icon === "string" ? (
          <img src={icon} alt={title} />
        ) : (
          icon
        )}
      </NavIcon>
      <span>{title}</span>
    </NavItem>
  );

interface TabButton {
  id?: string;
  key: any;
  title: string;
  icon: string | JSX.Element;
  activeIcon: string | JSX.Element;
  onClick?: () => void;
}

interface LargeTabsProps {
  type: any;
  tabs: Array<TabButton>;
  long?: boolean;
}

const LargeTabs: React.FC<LargeTabsProps> = ({ long = false, type, tabs }) => {
  const [activeTab, setActiveTab, hasInitialTab] = useLargeTabs(type);

  const navRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    hasInitialTab &&
      setTimeout(() => {
        scrollIntoView();
      }, 200);
  }, [hasInitialTab]);

  function scrollIntoView() {
    const scrollElement = document
      .querySelector("ion-content")
      ?.shadowRoot?.querySelector(".scroll-y");

    const offsetTop = navRef.current?.getBoundingClientRect()?.top;

    if (offsetTop !== undefined && scrollElement) {
      scrollElement?.scroll({
        top: (scrollElement?.scrollTop || 0) + offsetTop - 20,
        behavior: "smooth"
      });
    }
  }

  function onTabClick(key: any, cb?: () => void) {
    return () => {
      if (cb) {
        cb();
      } else {
        setActiveTab(key);
        setTimeout(() => {
          scrollIntoView();
        }, 200);
      }
    };
  }

  return (
    <Navigation ref={navRef} long={long}>
      {tabs.map(({ id, key, title, icon, activeIcon, onClick }) => (
        <TabBtn
          id={id}
          key={key}
          title={title}
          icon={icon}
          activeIcon={activeIcon}
          isActive={activeTab === key}
          onClick={onTabClick(key, onClick)}
          long={long}
        />
      ))}
    </Navigation>
  );
};

export default LargeTabs;
