import React from "react";
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider as StyledThemeProvider
} from "styled-components";

import { connect } from "./data/connect";
import { OauthData } from "./data/oauth/oauthDataService";
import { ClanOauthResponseOrganisationTheme } from "./util/models/ClanOauthResponse";

const Default = {
  primary: "#fee661",
  foregroundColor: "#000"
};

const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  svg {
    --color-primary: ${({ theme }) => theme.primary};
  }

  :root {
    --ion-color-primary: ${({ theme }) => theme.primary};
  }

  .bg-clanYellow-100 {
    --bg-opacity: 1 !important;
    background-color: ${({ theme }) => theme.primary} !important;
    background-color: ${({ theme }) => theme.primary} var(--bg-opacity);
  }

  .border-clanYellow-100 {
    --border-opacity: 1 !important;
    border-color: ${({ theme }) => theme.primary} !important;
    border-color: ${({ theme }) => theme.primary} var(--bg-opacity);
  }

  .info-card .popover-content {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

interface ThemeProviderProps {
  theme?: ClanOauthResponseOrganisationTheme;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ theme, children }) => {
  const mappedTheme = {
    primary: theme?.mainBackgroundColor || Default.primary,
    foreground: theme?.mainForegroundColor || Default.foregroundColor
  } as DefaultTheme;

  return (
    <StyledThemeProvider theme={mappedTheme}>
      <GlobalStyle theme={mappedTheme} />
      {children}
    </StyledThemeProvider>
  );
};

export default connect<{}, ThemeProviderProps>({
  mapStateToProps: ({ token }) => ({
    theme: (token as OauthData).theme
  }),
  component: ThemeProvider
});
