import {
  IonButton,
  IonCol,
  IonFooter,
  IonGrid,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText
} from "@ionic/react";
import { useAsyncEffect } from "@react-hook/async";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import ClanFooterBtn from "../../components/ClanFooterBtn";
import ClanLoader from "../../components/ClanLoader";
import { connect } from "../../data/connect";
import { useSignOutUser } from "../../data/oauth/InitOauthState";
import { OauthState } from "../../data/oauth/oauth.state";
import { OauthData } from "../../data/oauth/oauthDataService";
import { UserProfileState } from "../../data/user/profile/profile.state";
import * as ROUTES from "../../routes";
import { isReadyStateLoading } from "../../util/AsyncEffectFilter";
import { useSwitchOrganisationResult } from "../../util/Organisation";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { SignupSteps } from "../Signup/Signup";
import { configuration } from "../../configuration";

type OwnProps = RouteComponentProps;

interface StateProps {
  oauthState: OauthState | OauthData;
  profileConnect: UserProfileState;
}

interface SettingsProps extends StateProps, OwnProps {}

const Settings: React.FC<SettingsProps> = ({
  history,
  oauthState,
  profileConnect
}: SettingsProps) => {
  // TODO: When removing this option do remove the service as well as it is only place where it is used. Or confirm if it is
  const [
    organisationsCount,
    organisationsCountStatus
  ] = useSwitchOrganisationResult();

  const [signOutUserStatus, doSignOutUser] = useSignOutUser();

  useAsyncEffect(async () => {
    await setAnalyticsScreenName("SETTINGS");
    await analyticsLogEvent("SETTINGS_show");
  }, [profileConnect]);

  const { t } = useTranslation();

  const { appVersion, appBuild } = configuration;

  return (
    <ClanDesktopLayout>
      <IonPage>
        <ClanLoader
          message="Please wait. Loading..."
          showLoading={isReadyStateLoading(
            organisationsCountStatus,
            signOutUserStatus
          )}
        />
        <ClanIonContent className="text-center">
          <div className="text-center mt-clanFlowHeaderTop w-full">
            <IonText>
              <h3 className="p-4">
                {t("settings.settings_information_1")}
                <br />
                {t("settings.settings_information_2")}
              </h3>
            </IonText>
          </div>
          <IonGrid className="w-full justify-center items-center m-0">
            <IonRow className="w-full">
              {oauthState.hasToken && (
                <IonCol size="12" className="p-0">
                  <IonButton
                    expand="block"
                    className="w-full font-extrabold font-gilroy h-clanBtn text-clanH4 align-middle"
                    color="secondary"
                    onClick={() => {
                      analyticsLogEvent("SETTINGS_EDIT_PROFILE_CLICKED");
                      history.push(ROUTES.PERSONALIZE_VIEW);
                    }}
                  >
                    {t("settings.title_caps")}
                  </IonButton>
                </IonCol>
              )}
              {oauthState.hasToken && (
                <IonCol size="12" className="p-0">
                  <IonButton
                    expand="block"
                    className="w-full font-extrabold font-gilroy h-clanBtn text-clanH4 align-middle"
                    color="secondary"
                    onClick={() => {
                      analyticsLogEvent("SETTINGS_SET_LANGUAGE_CLICKED");
                      history.push(ROUTES.SIGN_UP, {
                        viewToLoad: SignupSteps.APP_LANGUAGE,
                        isEditing: true
                      });
                    }}
                  >
                    {t("settings.edit_app_language")}
                  </IonButton>
                </IonCol>
              )}
              <IonCol size="12" className="p-0">
                <IonButton
                  expand="block"
                  className="w-full font-extrabold font-gilroy h-clanBtn text-clanH4 align-middle"
                  color="secondary"
                  target="_blank"
                  href="https://www.clanbeat.com/materials"
                  onClick={() => {
                    analyticsLogEvent("SETTINGS_MATERIALS_CLICKED");
                  }}
                >
                  {t("settings.materials")}
                </IonButton>
              </IonCol>
              <IonCol size="12" className="p-0">
                <IonButton
                  expand="block"
                  className="w-full font-extrabold font-gilroy h-clanBtn text-clanH4 align-middle"
                  color="secondary"
                  target="_blank"
                  href="https://www.clanbeat.com/get-in-touch/"
                  onClick={() => {
                    analyticsLogEvent("SETTINGS_GET_IN_TOUCH_CLICKED");
                  }}
                >
                  {t("settings.get_in_touch")}
                </IonButton>
              </IonCol>
              {oauthState.hasToken && organisationsCount > 1 && (
                <IonCol size="12" className="p-0">
                  <IonButton
                    expand="block"
                    className="w-full font-extrabold font-gilroy h-clanBtn text-clanH4 align-middle"
                    color="secondary"
                    onClick={() => {
                      analyticsLogEvent("SETTINGS_CHANGE_NEST_CLICKED");
                      history.push(ROUTES.SETTINGS_ORGANISATIONS);
                    }}
                  >
                    {t("settings.edit_app_organisations")}
                  </IonButton>
                </IonCol>
              )}
              <IonCol size="12" className="p-0">
                <IonButton
                  expand="block"
                  className="w-full font-extrabold font-gilroy h-clanBtn text-clanH4 align-middle"
                  color="secondary"
                  target="_blank"
                  href="https://forms.gle/Tcf9KCn7yzvX2cyd6"
                  onClick={() => {
                    analyticsLogEvent("SETTINGS_REPORT_ISSUE_CLICKED");
                  }}
                >
                  {t("settings.report")}
                </IonButton>
              </IonCol>
              <IonCol size="12" className="p-0">
                <IonButton
                  expand="block"
                  className="w-full font-extrabold font-gilroy h-clanBtn text-clanH4 align-middle"
                  color="secondary"
                  target="_blank"
                  href="https://www.clanbeat.com/legal/"
                  onClick={() => {
                    analyticsLogEvent("SETTINGS_LEGAL_CLICKED");
                  }}
                >
                  {t("settings.terms")}
                </IonButton>
              </IonCol>
              <IonCol size="12" className="p-0">
                <IonButton
                  expand="block"
                  target="_blank"
                  className="w-full font-extrabold font-gilroy h-clanBtn text-clanH4 align-middle"
                  color="secondary"
                  href="https://www.clanbeat.com/privacy/"
                  onClick={() => {
                    analyticsLogEvent("SETTINGS_PRIVACY_CLICKED");
                  }}
                >
                  {t("settings.privacy")}
                </IonButton>
              </IonCol>
              {oauthState.hasToken && (
                <IonCol size="12" className="p-0">
                  <IonButton
                    expand="block"
                    color="secondary"
                    className="w-full font-extrabold font-gilroy h-clanBtn text-clanH4 align-middle"
                    onClick={() => {
                      analyticsLogEvent("SETTINGS_LOGOUT_CLICKED");
                      doSignOutUser();
                    }}
                  >
                    {t("settings.logout")}
                  </IonButton>
                </IonCol>
              )}
              <IonCol size="12">
                <IonText>
                  <div className="mx-12">
                    <p>{`Version: ${appVersion || "-"}  Build: ${
                      appBuild || "-"
                    }`}</p>
                    <p>{t("settings.credits")}</p>
                    {t("settings.discover")}{" "}
                    <IonRouterLink
                      href="https://www.clanbeat.com"
                      color="success"
                      target="_blank"
                    >
                      Clanbeat.com
                    </IonRouterLink>
                    <br />
                    {t("settings.disclaimer")}
                    <br />
                    2020
                  </div>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </ClanIonContent>
        <IonFooter>
          <ClanFooterBtn
            buttonObjects={[
              {
                title: t("general.back"),
                disabled: false,
                callback: async () => history.goBack()
              }
            ]}
          />
        </IonFooter>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<OwnProps, StateProps>({
  mapStateToProps: (state) => ({
    oauthState: state.token,
    profileConnect: state.userProfile
  }),
  component: Settings
});
