import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import {
  getPaginatedLabelAmounts,
  getPaginatedProfiles,
  getPaginatedProfilesForItem,
  getPaginatedProfilesForView
} from "./api/ProfileService";
import { ClanMembershipListItemResponse } from "./models/ClanProfileModels";
import { ClanLabelAmount } from "./models/ClanValueAmount";
import {
  PaginatedStreamDataIterator,
  PaginatedStreamListItemResultWithOwnerIterator
} from "./ResultDataIterator";
import { Views } from "./Configuration";
import { FilterGroupType } from "./Filters";
import { ListItemType } from "./Feeds";
import { useTranslation } from "react-i18next";
import { formatShortTimeDistance } from "./DateUtils";

const widthBreakPoint = Number(process.env.REACT_APP_CONFIG_DESKTOP_BREAKPOINT);

export function useQuery() {
  const urlParams = new URLSearchParams(useLocation().search);
  return Object.fromEntries(urlParams);
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current as T;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useScreenSizeInfo() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    ...windowDimensions,
    isDesktopSize: windowDimensions.width >= widthBreakPoint
  };
}

export function useCommunityProfileFilterPaginator(
  filters?: FilterGroupType[]
) {
  const [profileFilterPaginator, setProfileFilterPaginator] = useState<
    PaginatedStreamDataIterator<ClanMembershipListItemResponse>
  >();
  useEffect(() => {
    if (filters) {
      const profileFilterAPI = getPaginatedProfiles(Views.Community, filters);
      setProfileFilterPaginator(profileFilterAPI);
    }
  }, [filters]);
  return profileFilterPaginator;
}

export function useProfileFilterPaginator(view: Views) {
  const [profileFilterPaginator, setProfileFilterPaginator] = useState<
    PaginatedStreamDataIterator<ClanMembershipListItemResponse>
  >();
  useEffect(() => {
    if (!view) {
      return;
    }
    const profileFilterAPI = getPaginatedProfilesForView(view);
    setProfileFilterPaginator(profileFilterAPI);
  }, [view]);
  return profileFilterPaginator;
}

export function useCommunityLabelAmountFilterPaginator(
  listItemType: ListItemType,
  filters?: FilterGroupType[]
) {
  const [labelAmountPaginator, setLabelAmountPaginator] = useState<
    PaginatedStreamDataIterator<ClanLabelAmount>
  >();
  useEffect(() => {
    if (filters) {
      const profileFilterAPI = getPaginatedLabelAmounts(
        Views.Community,
        filters,
        listItemType
      );
      setLabelAmountPaginator(profileFilterAPI);
    }
  }, [filters, listItemType]);
  return labelAmountPaginator;
}

export function useProfilesForItemPaginator(
  listItemType: ListItemType,
  item?: ClanLabelAmount,
  filters?: FilterGroupType[]
) {
  const [paginator, setPaginator] = useState<
    PaginatedStreamListItemResultWithOwnerIterator<ClanMembershipListItemResponse>
  >();
  useEffect(() => {
    if (filters) {
      if (filters && item?.id) {
        const profileFilterAPI = getPaginatedProfilesForItem(
          Views.Community,
          filters,
          listItemType,
          item.id
        );
        setPaginator(profileFilterAPI);
      }
    }
  }, [filters, listItemType, item]);
  return paginator;
}

export const useShortTimeDistanceTranslation = () => {
  const { t } = useTranslation();

  const translateShortTimeDistance = (date: Date) =>
    formatShortTimeDistance(date, t);

  return translateShortTimeDistance;
};
