import {
  IonFooter,
  IonList,
  IonModal,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import { analyticsLogEvent } from "../../Analytics";
import { connect } from "../../data/connect";
import { updateUserProfile } from "../../data/user/profile/profile.actions";
import {
  UserProfileData,
  UserProfileState
} from "../../data/user/profile/profile.state";
import {
  BUCKET_LIST_ENDPOINTS,
  INTERESTS_ENDPOINTS
} from "../../util/ClanInterestsEndpoints";
import { ClanInterestsModel } from "../../util/models/ClanInterestsModel";
import { ClanProfileListItemResponse } from "../../util/models/ClanProfileModels";
import ClanProfileCard from "../ClanProfileCard";
import ClanProfileListItem from "../ClanProfileListItem";
import { InterestType } from "./ClanSimpleInterests";
import { useTranslation } from "react-i18next";
import { AuthorizedApiResult } from "../../util/ApiService";

interface OwnProps {
  type: InterestType;
  interest: string;
  onClose: () => void;
}

interface StateProps {
  profileConnect: UserProfileState;
}

interface DispatchProps {
  updateUserProfileConnect: typeof updateUserProfile;
}

interface InterestModalProps extends OwnProps, StateProps, DispatchProps {}

const InterestModal: React.FC<InterestModalProps> = ({
  type,
  interest,
  onClose,
  profileConnect,
  updateUserProfileConnect
}) => {
  const ENDPOINT = {
    [InterestType.Interest]: INTERESTS_ENDPOINTS,
    [InterestType.BucketList]: BUCKET_LIST_ENDPOINTS
  };

  const [interestedPeople, setInterestedPeople] = useState<
    ClanProfileListItemResponse[]
  >([]);

  const [interestCreator, setInterestCreator] = useState<string | undefined>();

  const exists = getMyInterests().includes(interest);
  const endpoints = ENDPOINT[type];

  function getMyInterests() {
    switch (type) {
      case InterestType.Interest:
        return profileConnect.interests || [];
      case InterestType.BucketList:
        return profileConnect.bucketList || [];
      default:
        return [];
    }
  }

  function getProfilesTitle() {
    switch (type) {
      case InterestType.Interest:
        return t("people_overview.interested");
      case InterestType.BucketList:
        return t("people_overview.hopeful");
      default:
        return "";
    }
  }

  const loadPeople = async () => {
    analyticsLogEvent("list_profiles");

    if (!interest) {
      return;
    }

    const response = await AuthorizedApiResult.get<ClanInterestsModel>(
      endpoints.listProfiles(interest)
    );
    setInterestedPeople(response.users);
    setInterestCreator(response.item.owner?.name);
  };

  useEffect(() => {
    loadPeople();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setChanges = (interestsResult: Array<string>) => {
    const userProfileData = {} as UserProfileData;

    if (type === InterestType.Interest) {
      userProfileData.interests = interestsResult;
    } else if (type === InterestType.BucketList) {
      userProfileData.bucketList = interestsResult;
    }

    updateUserProfileConnect(userProfileData);
    analyticsLogEvent(`${endpoints.screenName}_update`);
    onClose();
  };

  const onAddClick = async () => {
    if (!interest || exists) {
      return;
    }
    const result = await AuthorizedApiResult.post<Array<string>>({
      url: endpoints.addToProfile,
      data: { values: [interest] }
    });
    analyticsLogEvent(`${endpoints.screenName}_add`);
    setChanges(result);
  };

  const onRemoveClick = async () => {
    if (!interest || !exists) {
      return;
    }
    const result = await AuthorizedApiResult.delete<Array<string>>({
      url: endpoints.removeFromProfile,
      data: { values: [interest] }
    });
    analyticsLogEvent(`${endpoints.screenName}_remove`);
    setChanges(result);
  };

  const { t } = useTranslation();

  return (
    <IonModal isOpen onDidDismiss={onClose}>
      <div className="text-center mt-12 text-black w-full">
        <h3 className="my-1">{interest}</h3>
        <h5>{interestCreator ? `Created by: ${interestCreator}` : null}</h5>
      </div>

      <ClanProfileCard
        header={`${interestedPeople.length} ${getProfilesTitle()}`}
        contentClassName="px-3"
      >
        <IonList className="py-0">
          {interestedPeople
            ? interestedPeople.map((profile) => {
                return (
                  <ClanProfileListItem
                    profile={profile}
                    key={`interest_profile_${profile.id}`}
                    onNavigation={onClose}
                  />
                );
              })
            : null}
        </IonList>
      </ClanProfileCard>
      <IonFooter>
        <IonSegment>
          <IonSegmentButton
            color="primary"
            className="font-extrabold font-gilroy text-clanGreen-100 bg-clanGray-100"
            onClick={onClose}
          >
            {t("general.back")}
          </IonSegmentButton>
          <IonSegmentButton
            color="primary"
            className="text-black bg-clanYellow-100"
            onClick={exists ? onRemoveClick : onAddClick}
          >
            <h4>
              {exists
                ? t("people_overview.remove_from_profile")
                : t("people_overview.add_to_profile")}
            </h4>
          </IonSegmentButton>
        </IonSegment>
      </IonFooter>
    </IonModal>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    profileConnect: state.userProfile
  }),
  mapDispatchToProps: {
    updateUserProfileConnect: updateUserProfile
  },
  component: InterestModal
});
