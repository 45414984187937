import React from "react";
import styled from "styled-components";
import { H6Style } from "./Typography";

const StyledSelectButton = styled.div<{ bold?: boolean; selected?: boolean }>`
  ${H6Style}

  cursor: pointer;
  padding: 12px 16px;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(217, 154, 119, 0.08),
    0px 6px 10px rgba(64, 64, 64, 0.05), 0px 4px 8px rgba(64, 64, 64, 0.04),
    0px 3px 6px rgba(64, 64, 64, 0.03), 0px 2px 3px rgba(64, 64, 64, 0.02),
    0px 1px 2px rgba(64, 64, 64, 0.0112458);
  border-radius: 25px;

  border: 2px solid
    ${({ selected, theme }) => (selected ? theme.primary : "#fafafa")};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;

interface SelectButtonProps {
  onClick?: () => void;
  bold?: boolean;
  selected?: boolean;
}

const SelectButton: React.FC<SelectButtonProps> = ({
  children,
  onClick,
  bold,
  selected
}) => (
  <StyledSelectButton bold={bold} selected={selected} onClick={onClick}>
    {children}
  </StyledSelectButton>
);

export default SelectButton;
