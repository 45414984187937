import { IonPage } from "@ionic/react";
import { AsyncReducerState } from "@react-hook/async";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { analyticsLogEvent, setAnalyticsScreenName } from "../Analytics";
import BigUIButton, { LlamaUIButton } from "../components/Buttons/BigUIButton";
import LinkWrapper from "../components/Buttons/LinkWrapper";
import VerticalUIButton from "../components/Buttons/VerticalUIButton";
import ClanDesktopLayout from "../components/ClanDesktopLayout";
import ClanLoader from "../components/ClanLoader";
import ClanSelectPersonModal from "../components/ClanSelectPersonModal";
import TeacherOnboardingCards from "../components/Dashboard/TeacherOnboardingCards";
import WelcomeCard from "../components/Dashboard/WelcomeCard";
import { Heading4 } from "../components/Headings/Headings";
import HorizontalScroll from "../components/HorizontalScroll";
import { Section } from "../components/Section";
import {
  setAppError,
  setInfoModalClosed,
  updateLatestReportTimestamp
} from "../data/app/app.actions";
import { connect } from "../data/connect";
import { showLlamaModal } from "../data/modals/modals.actions";
import { OauthState } from "../data/oauth/oauth.state";
import { OauthData } from "../data/oauth/oauthDataService";
import { ReactComponent as CheckInIcon } from "../icons/button-icons/check-in.svg";
import { ReactComponent as DashboardIcon } from "../icons/button-icons/dashboard.svg";
import { ReactComponent as HealthyThinkingIcon } from "../icons/button-icons/healthy-thinking.svg";
import { ReactComponent as ManageIcon } from "../icons/button-icons/manage.svg";
import { ReactComponent as NestIcon } from "../icons/button-icons/nest.svg";
import { ReactComponent as NewPlanIcon } from "../icons/button-icons/new-plan.svg";
import { ReactComponent as PlannerIcon } from "../icons/button-icons/planner.svg";
import { ReactComponent as ReflectionsIcon } from "../icons/button-icons/reflections.svg";
import { ReactComponent as SupportIcon } from "../icons/button-icons/support.svg";
import { ReactComponent as TrendsIcon } from "../icons/button-icons/trends.svg";
import * as ROUTES from "../routes";
import { UserEnabledProperties } from "../util/api/UserEnabledPropertiesService";
import { ApiRequestError, ApiResponseErrorType } from "../util/ApiService";
import { isReadyStateLoading } from "../util/AsyncEffectFilter";
import { getConfiguration } from "../util/Configuration";
import { FeedTypes } from "../util/Feeds";
import useScreenWidthTrigger from "../util/hooks/useScreenWidthTrigger";
import { mobileOnlyStyles } from "../util/ResponsiveUtils";
import { useDashboardStatus } from "./DashboardUseEffects";
import { ClanIonContent } from "./DiscussionFeed/PublicFeeds";

const ContentWithBackground = styled(ClanIonContent)`
  ${mobileOnlyStyles({
    "--background": `url("/assets/bg-vertical.jpg")`
  })}
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/assets/bg-vertical.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;

  ${mobileOnlyStyles({
    display: "none"
  })}
`;

const HeadingSection = styled(Section)`
  margin-top: calc(24px + env(safe-area-inset-top));
`;

interface StateProps {
  tokenConnect: OauthState;
  contextName: string;
  isTeacher: boolean;
  groupManagementVisible: boolean;
  lastReportTimestampConnect: string | undefined;
}

interface DispatchProps {
  dispatchSetAppError: typeof setAppError;
  dispatchInfoModalClosed: typeof setInfoModalClosed;
  dispatchUpdateLatestReportTimestamp: typeof updateLatestReportTimestamp;
  dispatchLlamaModal: typeof showLlamaModal;
}

interface OwnProps extends StateProps, RouteComponentProps, DispatchProps {}

const Dashboard: React.FC<OwnProps> = ({
  tokenConnect,
  contextName,
  isTeacher,
  groupManagementVisible,
  dispatchInfoModalClosed,
  dispatchSetAppError,
  lastReportTimestampConnect,
  dispatchUpdateLatestReportTimestamp
}) => {
  const history = useHistory();
  const showDeviceGrid = useScreenWidthTrigger({ min: 0, max: 1100 });

  const [showGetStarted, setShowGetStarted] = useState(false);
  const [showTeacherInfoModal, setShowTeacherInfoModal] = useState<boolean>(
    false
  );
  const [showDirectMessageModal, setShowDirectMessageModal] = useState<boolean>(
    false
  );

  const [
    wellBeingQuestionPending,
    setWellBeingQuestionPending
  ] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    setAnalyticsScreenName("DASHBOARD");
    handleShowGetStarted();
    dispatchUpdateLatestReportTimestamp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowGetStarted = async () => {
    try {
      const { otherUsersInContext } = await getConfiguration();
      setShowGetStarted(otherUsersInContext === 0);
    } catch (e) {
      console.error("Failed to fetch other users in context.");
    }
  };

  const [isAdultStatus, pendingWellBeingQuestionStatus] = useDashboardStatus(
    tokenConnect,
    lastReportTimestampConnect
  );

  const isMeaningfulError = (
    status: AsyncReducerState<any, ApiRequestError>,
    dispatcher: typeof setAppError
  ): boolean => {
    if (status.status !== "error" || !status.error)
      return status.status === "success";
    if (status.error.type === ApiResponseErrorType.HTTP_NOT_FOUND) {
      return false;
    }
    dispatcher(status.error);
    console.error(
      "[DASHBOARD] Couldn't determine if questions are pending",
      status.error
    );
    return false;
  };

  useEffect(() => {
    if (isMeaningfulError(isAdultStatus, dispatchSetAppError)) {
      setShowTeacherInfoModal(!!isAdultStatus.value);
    }
  }, [isAdultStatus, dispatchSetAppError]);

  useEffect(() => {
    if (
      isMeaningfulError(pendingWellBeingQuestionStatus, dispatchSetAppError)
    ) {
      setWellBeingQuestionPending(!!pendingWellBeingQuestionStatus.value);
    }
  }, [pendingWellBeingQuestionStatus, dispatchSetAppError]);

  const onTeacherInfoModalClosed = () => {
    dispatchInfoModalClosed(true);
    setShowTeacherInfoModal(false);
  };

  return (
    <>
      {showDirectMessageModal && (
        <ClanSelectPersonModal
          onClose={() => setShowDirectMessageModal(false)}
          isVisible={showDirectMessageModal}
        />
      )}

      {showTeacherInfoModal && (
        <TeacherOnboardingCards onClose={onTeacherInfoModalClosed} />
      )}

      <ClanLoader
        message={t(`general.loading`)}
        showLoading={isReadyStateLoading(
          isAdultStatus,
          pendingWellBeingQuestionStatus
        )}
      />

      <ClanDesktopLayout>
        <IonPage id="dashboard">
          <ContentWithBackground>
            <BackgroundImage />

            <HeadingSection>
              <WelcomeCard />
            </HeadingSection>

            {isTeacher && showGetStarted && (
              <Section>
                <LlamaUIButton
                  primaryText={t("dashboard.get_started_description")}
                  secondaryText={t("dashboard.get_started_title")}
                  onClick={() => {
                    analyticsLogEvent("DASHBOARD_TEACHER_GETSTARTED_CLICKED");
                    history.push(ROUTES.GET_STARTED);
                  }}
                />
              </Section>
            )}

            <Section>
              <Heading4 title={contextName} />

              <HorizontalScroll visibleColumns={showDeviceGrid ? 3 : 4}>
                {wellBeingQuestionPending && (
                  <VerticalUIButton
                    title={t("dashboard.check_in")}
                    icon={<CheckInIcon />}
                    onClick={() => {
                      analyticsLogEvent("DASHBOARD_STUDENT_WELL_BEING_CLICKED");
                      history.push(ROUTES.WELLBEING_SURVEY_VIEW);
                    }}
                  />
                )}

                <VerticalUIButton
                  title={t("dashboard.reflections")}
                  icon={<ReflectionsIcon />}
                  onClick={() => {
                    analyticsLogEvent("DASHBOARD_REFLECTIONS_CLICKED");
                    history.push(ROUTES.PUBLIC_FEEDS, {
                      feedType: FeedTypes.SHARED_REFLECTIONS
                    });
                  }}
                />

                {isTeacher && (
                  <VerticalUIButton
                    title={t("dashboard.teacher_dashboard")}
                    icon={<DashboardIcon />}
                    onClick={() => {
                      analyticsLogEvent("DASHBOARD_TEACHER_DASHBOARD_CLICKED");
                      history.push(ROUTES.STATS_DASHBOARD_MOOD);
                    }}
                  />
                )}

                {isTeacher && groupManagementVisible && (
                  <VerticalUIButton
                    title={t("dashboard.manage_groups")}
                    icon={<ManageIcon />}
                    onClick={() => {
                      analyticsLogEvent("DASHBOARD_GROUPS_VIEW_CLICKED");
                      history.push(ROUTES.GROUPS_VIEW);
                    }}
                  />
                )}

                {!isTeacher && (
                  <VerticalUIButton
                    title={t("dashboard.trends")}
                    icon={<TrendsIcon />}
                    onClick={() => {
                      analyticsLogEvent("DASHBOARD_STUDENT_TRENDS_CLICKED");
                      history.push(
                        ROUTES.PROFILE_TRENDS.replace(
                          ":profileId",
                          (
                            (tokenConnect as OauthData).profileId || ""
                          )?.toString()
                        )
                      );
                    }}
                  />
                )}

                {!isTeacher && (
                  <VerticalUIButton
                    title={t("dashboard.support")}
                    icon={<SupportIcon />}
                    onClick={() => {
                      analyticsLogEvent("DASHBOARD_STUDENT_QUICK_HELP_CLICKED");
                      history.push(ROUTES.QUICK_HELP_VIEW);
                    }}
                  />
                )}
              </HorizontalScroll>
            </Section>

            <Section>
              <Heading4 title={t("dashboard.habits_title")} />

              <HorizontalScroll visibleColumns={showDeviceGrid ? 3 : 4}>
                <VerticalUIButton
                  title={t("dashboard.planner")}
                  icon={<PlannerIcon />}
                  onClick={() => history.push(ROUTES.PLANNER_DAY)}
                />
                <VerticalUIButton
                  title={t("dashboard.new_plan")}
                  icon={<NewPlanIcon />}
                  onClick={() =>
                    history.push({
                      pathname: ROUTES.PLANNER_DAY,
                      search: "?directShare=true"
                    })
                  }
                />
                <LinkWrapper href="https://www.clanbeat.com/worry-and-anxiety-techniques-for-students/#healthy-thinking">
                  <VerticalUIButton
                    title={t("dashboard.healthy_thinking")}
                    icon={<HealthyThinkingIcon />}
                  />
                </LinkWrapper>
              </HorizontalScroll>
            </Section>

            <Section>
              <BigUIButton
                icon={<NestIcon />}
                secondaryText={t("dashboard.pick_nest_title")}
                primaryText={t("dashboard.pick_nest_description")}
                onClick={() => history.push(ROUTES.SETTINGS_ORGANISATIONS)}
              />
            </Section>
          </ContentWithBackground>
        </IonPage>
      </ClanDesktopLayout>
    </>
  );
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: ({ token, appState }) => ({
    tokenConnect: token,
    contextName: (token as OauthData)?.contextName,
    lastReportTimestampConnect: appState.lastReportTimestamp,
    isTeacher: UserEnabledProperties.Legacy.isAdult(token),
    groupManagementVisible: UserEnabledProperties.Group.groupHub(token)
  }),
  mapDispatchToProps: {
    dispatchSetAppError: setAppError,
    dispatchInfoModalClosed: setInfoModalClosed,
    dispatchUpdateLatestReportTimestamp: updateLatestReportTimestamp,
    dispatchLlamaModal: showLlamaModal
  },
  component: Dashboard
});
