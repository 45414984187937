// Images taken from https://github.com/twitter/twemoji/tree/gh-pages/svg
import { ReactionTypes } from "../types/index.d";
import { ClanReactions } from "../../models/ClanPostModels";
import { ClanComplaintReactions } from "../../Analytics/types/ClanComplaintReactions.d";

export const REACTION_LIST = Object.entries(ReactionTypes).map((reactions) => ({
  key: reactions[0] as ReactionTypes,
  label: reactions[1],
  image: `/assets/reaction/${reactions[0].toLowerCase()}.svg`,
  imageActive: `/assets/reaction/${reactions[0].toLowerCase()}-black.svg`
}));

const REACTION_TYPES = Object.keys(ReactionTypes);

export const sortAndFilterReactionTypes = (reactions: ClanReactions) =>
  Object.entries(reactions)
    .sort(([, data1], [, data2]) =>
      (data1?.amount || 0) > (data2?.amount || 0) ? -1 : 1
    )
    .map(([reactionKey]) => reactionKey.toUpperCase())
    .filter((reactionType) => REACTION_TYPES.indexOf(reactionType) > -1)
    .map((reactionType) => reactionType as ReactionTypes);

export const filterReactionTypes = (
  reactions: ClanReactions | ClanComplaintReactions | undefined | null
): ReactionTypes[] =>
  reactions
    ? Object.keys(reactions)
        .map((reactionType) => reactionType.toUpperCase())
        .filter((reactionType) => REACTION_TYPES.indexOf(reactionType) > -1)
        .map((reactionType) => reactionType as ReactionTypes)
    : [];

export const filterReactionTypesToValues = (
  reactions: ClanReactions | undefined | null
): {
  reaction: ReactionTypes;
  amount: number;
  likedByMe: boolean;
}[] => {
  if (reactions === undefined || reactions === null) return [];
  return filterReactionTypes(reactions).map((reactionType) => {
    const reactionValue = reactions[reactionType] || reactions[reactionType];
    if (reactionValue === undefined) {
      return { amount: 0, likedByMe: false, reaction: reactionType };
    } else {
      return { ...reactionValue, reaction: reactionType };
    }
  });
};
