import {
  BaseItemItemsModel,
  ClanLabelValueItemItem
} from "./models/ClanInterestsModel";
import { AuthorizedApi, AuthorizedApiResult } from "./ApiService";

export interface ResultDataIterator<T> {
  getNext(): Promise<T[]>;
  hasNext(): Promise<boolean>;
  reset(): Promise<void>;
}

export interface ResultDataApiIterator<T> extends ResultDataIterator<T> {
  setQueryArgumentNoFetch(key: string, value: string): boolean;
  removeQueryArgumentNoFetch(key: string): boolean;
  setQueryArgument(key: string, value: string): Promise<void>;
}

type PageInfo = {
  previousHash: string | null;
  hash: string;
  nextHash: string | null;
};

type PageInfoAwareResult<T> = {
  pageInfo: PageInfo;
  result: T[];
};

export type PageInfoAwareContainerResult<T> = {
  pageInfo: PageInfo;
  result: T;
};

export class BaseResultDataIterator {
  protected readonly pendingQueue: string[] = [];

  protected addToQueue(keyword: string): void {
    this.pendingQueue.push(keyword);
  }

  protected getQueueIndex(keyword: string): number {
    return this.pendingQueue.indexOf(keyword);
  }

  protected removeFromQueue(keyword: string): boolean {
    const index = this.getQueueIndex(keyword);
    if (index < 0) return false;
    this.pendingQueue.splice(index, 1);
    return true;
  }

  protected async doWithoutWaitOnQueue<R>(
    keyword: string,
    callback: () => Promise<R>
  ): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      this.addToQueue(keyword);
      callback()
        .then(resolve)
        .catch(reject)
        .finally(() => this.removeFromQueue(keyword));
    });
  }

  protected async awaitOnQueueComplete<R>(
    callback: () => Promise<R>
  ): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      awaitPending(
        () => this.pendingQueue.length > 0,
        () =>
          this.doWithoutWaitOnQueue("await", callback)
            .then(resolve)
            .catch(reject)
      );
    });
  }
}

/// sometimes we need to get only one item
export class SingleObjectIterator<T>
  extends BaseResultDataIterator
  implements ResultDataIterator<T> {
  private readonly apiPath: string;
  private result: T | undefined;

  constructor(apiPath: string) {
    super();
    this.apiPath = apiPath;
  }

  private async initAPI() {
    this.result = await AuthorizedApiResult.get<T>(this.apiPath);
  }

  async reset() {
    return this.awaitOnQueueComplete(() => this.initAPI());
  }

  async getNext(): Promise<T[]> {
    return this.awaitOnQueueComplete(async () => {
      if (this.result === undefined) {
        await this.initAPI();
      }
      if (this.result === undefined) {
        return [];
      }
      return [this.result];
    });
  }

  async hasNext(): Promise<boolean> {
    return false;
  }
}

export class SingleStreamDataIterator<T>
  extends BaseResultDataIterator
  implements ResultDataIterator<T> {
  private readonly apiPath: string;
  private result: T[] | undefined;
  private index = 0;
  private readonly limit: number;

  constructor(
    apiPath: string,
    limit = Number(process.env.REACT_APP_CONFIG_DISCUSSION_FEED_POST_INCREMENT)
  ) {
    super();
    this.apiPath = apiPath;
    this.limit = limit;
  }

  private async initAPI() {
    this.index = 0;
    this.result = await AuthorizedApiResult.get<T[]>(this.apiPath);
  }

  async reset() {
    return this.awaitOnQueueComplete(() => this.initAPI());
  }

  async getNext(): Promise<T[]> {
    return this.awaitOnQueueComplete(async () => {
      if (this.result === undefined) {
        await this.initAPI();
      }
      if (this.result === undefined || this.index >= this.result.length) {
        return [];
      }
      const result = this.result.slice(
        this.index,
        this.limit >= this.result.length
          ? this.result.length
          : this.index + this.limit
      );
      this.index += this.limit;
      return result;
    });
  }

  async hasNext(): Promise<boolean> {
    return this.awaitOnQueueComplete(async () => {
      if (this.result === undefined) {
        await this.initAPI();
      }
      return this.result !== undefined && this.index < this.result.length;
    });
  }
}

const awaitPending = (pending: () => boolean, callback: () => void) => {
  if (pending()) {
    setTimeout(() => {
      awaitPending(pending, callback);
    }, 1);
  } else {
    callback();
  }
};

export class DummyPaginatedStreamDataIterator<T>
  implements ResultDataApiIterator<T> {
  getNext(): Promise<T[]> {
    return Promise.resolve([]);
  }
  hasNext(): Promise<boolean> {
    return Promise.resolve(false);
  }
  removeQueryArgumentNoFetch(key: string): boolean {
    return false;
  }
  reset(): Promise<void> {
    return Promise.resolve(undefined);
  }
  setQueryArgument(key: string, value: string): Promise<void> {
    return Promise.resolve(undefined);
  }
  setQueryArgumentNoFetch(key: string, value: string): boolean {
    return false;
  }
}

export class PaginatedStreamDataIterator<T>
  extends BaseResultDataIterator
  implements ResultDataApiIterator<T> {
  private readonly apiPath: string;
  private nextHash: string | undefined;
  private result: T[] | undefined;
  private queryArgs: { [key: string]: string } = {};
  initialDataLoaded = false;

  constructor(apiPath: string) {
    super();
    this.apiPath = apiPath;
  }

  setQueryArgumentNoFetch(key: string, value: string): boolean {
    if (!value || !value.trim()) {
      return this.removeQueryArgumentNoFetch(key);
    } else if (this.queryArgs[key] !== value) {
      this.queryArgs[key] = value;
      return true;
    }
    return false;
  }

  removeQueryArgumentNoFetch(key: string): boolean {
    if (this.queryArgs[key] !== undefined) {
      delete this.queryArgs[key];
      return true;
    }
    return false;
  }

  async setQueryArgument(key: string, value: string) {
    if (!value || !value.trim()) {
      await this.removeQueryArgument(key);
    } else {
      this.queryArgs[key] = value;
      await this.reset();
    }
  }

  async removeQueryArgument(key: string) {
    if (this.removeQueryArgumentNoFetch(key)) {
      await this.reset();
    }
  }

  private processResult(result: PageInfoAwareResult<T>) {
    this.nextHash =
      result?.result?.length > 0 && result?.pageInfo?.nextHash
        ? result?.pageInfo?.nextHash
        : undefined;
    this.result = result.result;
  }

  private async initAPI() {
    this.nextHash = undefined;
    const result = await AuthorizedApi.get<PageInfoAwareResult<T>>({
      url: this.apiPath,
      params: this.queryArgs
    });
    this.initialDataLoaded = true;
    this.processResult(result);
  }

  async reset(): Promise<void> {
    return this.awaitOnQueueComplete(() => this.initAPI());
  }

  private fetchNext() {
    if (!this.nextHash) {
      this.result = [];
      return;
    }
    // we do not care about result so disabling promise call check
    // noinspection JSIgnoredPromiseFromCall
    this.doWithoutWaitOnQueue("fetch", async () => {
      const result = await AuthorizedApi.get<PageInfoAwareResult<T>>({
        url: this.apiPath,
        params: {
          ...this.queryArgs,
          ...{ listHash: this.nextHash }
        }
      });
      this.processResult(result);
    });
  }

  async getNext(): Promise<T[]> {
    return this.awaitOnQueueComplete(async () => {
      if (this.result === undefined) {
        await this.initAPI();
      }
      const result: T[] = this.result !== undefined ? [...this.result] : [];
      this.fetchNext();
      return result;
    });
  }

  async hasNext(): Promise<boolean> {
    return this.awaitOnQueueComplete(async () => {
      if (this.result === undefined) {
        await this.initAPI();
      }
      return this.result !== undefined && this.result.length > 0;
    });
  }
}

type PaginatedStreamListItemResultWithOwnerIteratorResult<T> = {
  pageInfo: PageInfo;
  result: BaseItemItemsModel<ClanLabelValueItemItem, T>;
};

export class PaginatedStreamListItemResultWithOwnerIterator<T>
  extends BaseResultDataIterator
  implements ResultDataApiIterator<T> {
  private readonly apiPath: string;
  private nextHash: string | undefined;
  private result: T[] | undefined;
  private queryArgs: { [key: string]: string } = {};
  private item?: ClanLabelValueItemItem;

  constructor(apiPath: string) {
    super();
    this.apiPath = apiPath;
  }

  setQueryArgumentNoFetch(key: string, value: string): boolean {
    if (!value || !value.trim()) {
      return this.removeQueryArgumentNoFetch(key);
    } else if (this.queryArgs[key] !== value) {
      this.queryArgs[key] = value;
      return true;
    }
    return false;
  }

  removeQueryArgumentNoFetch(key: string): boolean {
    if (this.queryArgs[key] !== undefined) {
      delete this.queryArgs[key];
      return true;
    }
    return false;
  }

  async setQueryArgument(key: string, value: string) {
    if (!value || !value.trim()) {
      await this.removeQueryArgument(key);
    } else {
      this.queryArgs[key] = value;
      await this.reset();
    }
  }

  async removeQueryArgument(key: string) {
    if (this.removeQueryArgumentNoFetch(key)) {
      await this.reset();
    }
  }

  private processResult(
    result: PaginatedStreamListItemResultWithOwnerIteratorResult<T>
  ) {
    this.nextHash =
      result.result.items?.length > 0 && result?.pageInfo?.nextHash
        ? result?.pageInfo?.nextHash
        : undefined;
    this.item = result.result.item;
    this.result = result.result.items;
  }

  private async initAPI() {
    this.nextHash = undefined;
    const result = await AuthorizedApi.get<
      PaginatedStreamListItemResultWithOwnerIteratorResult<T>
    >({
      url: this.apiPath,
      params: this.queryArgs
    });
    this.processResult(result);
  }

  async reset(): Promise<void> {
    return this.awaitOnQueueComplete(() => this.initAPI());
  }

  private fetchNext() {
    if (!this.nextHash) {
      return;
    }
    // we do not care about result so disabling promise call check
    // noinspection JSIgnoredPromiseFromCall
    this.doWithoutWaitOnQueue("fetch", async () => {
      const result = await AuthorizedApi.get<
        PaginatedStreamListItemResultWithOwnerIteratorResult<T>
      >({
        url: this.apiPath,
        params: {
          ...this.queryArgs,
          ...{ listHash: this.nextHash }
        }
      });
      this.processResult(result);
    });
  }

  async getLastItem(): Promise<ClanLabelValueItemItem | undefined> {
    return this.awaitOnQueueComplete(async () => {
      if (this.result === undefined) {
        await this.initAPI();
      }
      return this.item ? { ...this.item } : undefined;
    });
  }

  async getNext(): Promise<T[]> {
    return this.awaitOnQueueComplete(async () => {
      if (this.result === undefined) {
        await this.initAPI();
      }
      const result: T[] = this.result !== undefined ? [...this.result] : [];
      this.fetchNext();
      return result;
    });
  }

  async hasNext(): Promise<boolean> {
    return this.awaitOnQueueComplete(async () => {
      if (this.result === undefined) {
        await this.initAPI();
      }
      return this.result !== undefined && this.result.length > 0;
    });
  }
}
