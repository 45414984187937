import React from "react";
import styled from "styled-components";

const IconBtnWrapper = styled.div`
  cursor: pointer;

  svg {
    display: block;
  }
`;

interface IconButtonProps {
  icon: JSX.Element;
  onClick?: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({ icon, onClick }) => {
  return <IconBtnWrapper onClick={onClick}>{icon}</IconBtnWrapper>;
};

export default IconButton;
