import React, { useState } from "react";

import { connect } from "../../data/connect";
import { selectMyInterestsByType } from "../../data/selectors/profileSelectors";
import InterestList from "../ClanInterestList";
import InterestModal from "./InterestModal";
import { useTranslation } from "react-i18next";

export enum InterestType {
  Interest,
  BucketList
}

interface OwnProps {
  type: InterestType;
  interests: Array<string>;
  wrapped?: boolean;
  highlightMatching?: boolean;
}

interface StateProps {
  highlightedInterests: Array<string>;
}

interface ClanSimpleInterestsProps extends OwnProps, StateProps {}

const ClanSimpleInterests: React.FC<ClanSimpleInterestsProps> = ({
  type,
  interests = [],
  wrapped = false,
  highlightedInterests
}) => {
  const [selectedInterest, setSelectedInterest] = useState<string | undefined>(
    undefined
  );
  const { t } = useTranslation();

  function getEmptyText() {
    switch (type) {
      case InterestType.Interest:
        return t("people_overview.no_interests");
      case InterestType.BucketList:
        return t("people_overview.bucket_list_empty");
      default:
        return "";
    }
  }
  const onInterestClick = (interest: string) => {
    setSelectedInterest(interest);
  };

  return (
    <>
      {selectedInterest && (
        <InterestModal
          type={type}
          interest={selectedInterest}
          onClose={() => setSelectedInterest(undefined)}
        />
      )}

      {interests.length > 0 ? (
        <InterestList
          wrapped={wrapped}
          interests={interests}
          highlighted={highlightedInterests}
          onClick={onInterestClick}
        />
      ) : (
        <p>{getEmptyText()}</p>
      )}
    </>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state, props) => ({
    highlightedInterests: props.highlightMatching
      ? selectMyInterestsByType(props.type, state)
      : []
  }),
  component: ClanSimpleInterests
});
