import { IonButton, IonIcon, IonLabel } from "@ionic/react";
import { closeCircle, imageOutline } from "ionicons/icons";
import React from "react";
import { useTranslation } from "react-i18next";

type ImageButtonProps = {
  image: string | undefined;
  onClick: () => void;
  onClear: () => void;
};

const ImageButton: React.FC<ImageButtonProps> = ({
  image,
  onClick,
  onClear
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center mx-1">
      <IonButton
        style={{
          "--padding-start": "0.5rem",
          "--padding-end": "0.5rem"
        }}
        fill="clear"
        color="dark"
        mode="ios"
        onClick={onClick}
        className="p-0 m-0"
        disabled={image?.length === 0}
      >
        <IonLabel
          className={`m-0 p-0 text-clanP ${
            image ? "text-clanGreen-100" : "text-clanGray-200"
          }`}
        >
          {image ? t("planner.image") : t("planner.image_add")}
        </IonLabel>
        <IonIcon
          className={`h-4 p-0 m-0 ${
            image ? "text-black" : "text-clanGray-200"
          }`}
          slot="start"
          icon={imageOutline}
        />
      </IonButton>

      {image && image?.length !== 0 && (
        <IonIcon
          icon={closeCircle}
          slot="end"
          className="p-0 h-8 text-clanGray-200 cursor-pointer"
          onClick={onClear}
        />
      )}
    </div>
  );
};

export default ImageButton;
