import {
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  withRouter
} from "react-router-dom";
import styled from "styled-components";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import ClanDiscussionPostRemoveModal from "../../components/ClanDiscussionPost/ClanDiscussionPostRemoveModal";
import ClanDiscussionPostReportModal from "../../components/ClanDiscussionPost/ClanDiscussionPostReportModal";
import ClanEditPostWithSave from "../../components/ClanDiscussionPost/ClanEditPostWithSave";
import PostListItem from "../../components/ClanDiscussionPost/PostListItem";
import ClanLoader from "../../components/ClanLoader";
import { FlexCol } from "../../components/Flexbox";
import FooterButtons, {
  PrimaryFooterButton
} from "../../components/FooterButtons";
import Header from "../../components/Headers/Header";
import LlamaBox from "../../components/LlamaBox";
import { H6 } from "../../components/Typography";
import { setAppError } from "../../data/app/app.actions";
import {
  getDiscoverCommunitySeenCookie,
  setDiscoverCommunitySeenCookie
} from "../../data/app/appDataService";
import { connect } from "../../data/connect";
import { removeModal, showLlamaModal } from "../../data/modals/modals.actions";
import { ModalCallbackType, ModalID } from "../../data/modals/modals.state";
import { PROFILE, PUBLIC_FEEDS } from "../../routes";
import { ApiRequestError } from "../../util/ApiService";
import { getCommentsFeedAPIByPostId } from "../../util/ClanPostAPIService";
import { FeedTypes } from "../../util/Feeds";
import {
  ClanEditPostEditDetails,
  ClanPostListResult,
  ClanPostListResultReplies
} from "../../util/models/ClanPostModels";
import { ResultDataIterator } from "../../util/ResultDataIterator";
import { scrollToItem } from "../../util/ScrollUtils";
import { addPostImageToFeed } from "./DiscussionHelper";
import { ClanIonContent } from "./PublicFeeds";
import { OauthData } from "../../data/oauth/oauthDataService";
import { switchOrganisation } from "../../util/Organisation";
import { OauthState } from "../../data/oauth/oauth.state";

const MainClanDiscussionPost = styled(PostListItem)`
  margin-top: 0;
`;

const ContentWrapper = styled(FlexCol)`
  margin-bottom: 24px;
`;

const GrayContent = styled(ClanIonContent)`
  --background: #e5e5e5;
  background: #e5e5e5;
`;

type FeedPostCommentsRouteProps = RouteComponentProps<{
  post_id: string;
}>;

interface StateProps {
  oauthState: OauthState;
}

type OwnProps = {
  feedType: FeedTypes;
};

interface DispatchProps {
  setAppErrorConnect: typeof setAppError;
  dispatchLlamaModal: typeof showLlamaModal;
  dispatchRemoveModal: typeof removeModal;
}

interface FeedPostCommentsProps
  extends OwnProps,
    FeedPostCommentsRouteProps,
    StateProps {}

const FeedPostComments: React.FC<FeedPostCommentsProps & DispatchProps> = ({
  oauthState,
  feedType,
  match,
  setAppErrorConnect,
  dispatchLlamaModal,
  dispatchRemoveModal
}) => {
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();

  const searchParams = new URLSearchParams(location.search);
  const shouldAskConsent = searchParams.get("askConsent") === "true";

  let feedPathUrl: string;

  switch (feedType) {
    case FeedTypes.PERSONAL: {
      feedPathUrl = PROFILE;
      break;
    }
    case FeedTypes.DISCUSSION: {
      feedPathUrl = PUBLIC_FEEDS;
      break;
    }
    case FeedTypes.QUESTION: {
      feedPathUrl = PUBLIC_FEEDS;
      break;
    }
    case FeedTypes.PUBLIC: {
      break;
    }
    case FeedTypes.SHARED_REFLECTIONS: {
      feedPathUrl = PUBLIC_FEEDS;
      break;
    }
  }

  const requireShare = ![
    FeedTypes.PERSONAL,
    FeedTypes.PUBLIC,
    FeedTypes.SHARED_REFLECTIONS
  ].includes(feedType);

  const [postId, setPostId] = useState<string | undefined>(undefined);
  const [post, setPost] = useState<ClanPostListResultReplies | undefined>();
  const [comments, setComments] = useState<ClanPostListResult[]>([]);
  const [
    commentsResultWithLocalImages,
    setCommentsResultsWithLocalImages
  ] = useState<Array<ClanPostListResult>>([]);
  const [commentsWithLocalImages, setCommentsWithLocalImages] = useState<
    ClanEditPostEditDetails[]
  >([]);

  const [editPostId, setEditPostId] = useState<string>();
  const [showPostEdit, setShowPostEdit] = useState<boolean>(false);
  const [reportPostId, setReportPostId] = useState<string>("");
  const [deletePostId, setDeletePostId] = useState<string>("");

  const [editCommentId, setEditCommentId] = useState<string>();
  const [showCommentEdit, setShowCommentEdit] = useState<boolean>(false);
  const [reportCommentId, setReportCommentId] = useState<string>("");
  const [deleteCommentId, setDeleteCommentId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const [commentModalTitle, setCommentModalTitle] = useState<string>("");
  const [commentApiEndpoint, setCommentApiEndpoint] = useState<
    ResultDataIterator<ClanPostListResult> | undefined
  >();
  const [postIdToHightlight, setPostIdToHighlight] = useState<string | null>();

  const [hasMore, setHasMore] = useState<boolean>(false);
  const [postTitleAndId, setPostTitleAndId] = useState<{
    id?: number;
    name: string;
  }>({ id: undefined, name: t("feed_post_comments.title") });

  const postDeleteDone = () => {
    setDeletePostId("");
    history.go(-2);
  };

  useEffect(() => {
    if (
      !post?.userContext?.organisations ||
      post.userContext.organisations.length === 0
    ) {
      setPostTitleAndId({ id: undefined, name: t("feed_post_comments.title") });
      return;
    }
    setPostTitleAndId({
      id: post?.userContext?.organisations[0].id,
      name: post?.userContext?.organisations[0].name
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  const reloadPostComments = async (
    newOrEditedComment?: ClanEditPostEditDetails
  ) => {
    setLoading(true);
    setPost(undefined);
    setComments([]);
    setAnalyticsScreenName("POST_COMMENTS");
    setEditPostId("");
    setReportPostId("");
    setReportPostId("");
    setShowPostEdit(false);

    setEditCommentId("");
    setReportCommentId("");
    setDeleteCommentId("");
    setShowCommentEdit(false);

    analyticsLogEvent("POST_COMMENTS_list");
    newOrEditedComment &&
      setCommentsWithLocalImages((oldList) => [...oldList, newOrEditedComment]);
    try {
      if (
        newOrEditedComment &&
        newOrEditedComment?.parentPostId &&
        postId !== newOrEditedComment.parentPostId
      ) {
        setCommentApiEndpoint(
          getCommentsFeedAPIByPostId(newOrEditedComment.parentPostId)
        );
        // The listener below will call reloading the post
        return;
      }
      await commentApiEndpoint?.reset();
      const result = await commentApiEndpoint?.getNext();
      if (result && result.length > 0) {
        const mainPost = result.shift();
        if (mainPost) {
          setPost(mainPost);
          if (shouldAskConsent && mainPost.title)
            showSystemQuestionModal(mainPost.title);
        }
        setComments(result);
      }
      commentApiEndpoint?.hasNext().then(setHasMore);
    } catch (e) {
      setAppErrorConnect(e as ApiRequestError);
    } finally {
      setLoading(false);
      if (newOrEditedComment) setPostIdToHighlight(newOrEditedComment.id);
    }
  };

  const showSystemQuestionModal = (title: string) => {
    history.replace(location.pathname);

    getDiscoverCommunitySeenCookie()
      .then((discoverCommunityShown: boolean) => {
        if (discoverCommunityShown) {
          showQuestionLlama(title);
        } else {
          setDiscoverCommunitySeenCookie(true);

          dispatchLlamaModal(ModalID.DiscoverCommunityLlama, {
            title: t("llama_modal.discover_title"),
            paragraphs: [
              t("llama_modal.discover_content1"),
              t("llama_modal.discover_content2")
            ],
            actions: [
              {
                label: t("general.skip"),
                action: () => {
                  if (feedPathUrl) {
                    history.push(feedPathUrl, { feedType });
                  } else {
                    history.goBack();
                  }
                  dispatchRemoveModal(ModalID.DiscoverCommunityLlama);
                },
                type: ModalCallbackType.Close
              },
              {
                label: t("general.answer"),
                action: () => {
                  dispatchRemoveModal(ModalID.DiscoverCommunityLlama);
                  showQuestionLlama(title);
                },
                type: ModalCallbackType.Primary
              }
            ]
          });
        }
      })
      .catch(() => {
        showQuestionLlama(title);
      });
  };

  const showQuestionLlama = (title: string) => {
    dispatchLlamaModal(ModalID.LlamaInfo, {
      title,
      paragraphs: [t("llama_modal.visible_to_group")],
      actions: [
        {
          label: t("general.skip"),
          action: () => {
            if (feedPathUrl) {
              history.push(feedPathUrl, { feedType });
            } else {
              history.goBack();
            }
            dispatchRemoveModal(ModalID.LlamaInfo);
          },
          type: ModalCallbackType.Close
        },
        {
          label: t("general.answer"),
          action: () => {
            dispatchRemoveModal(ModalID.LlamaInfo);
            doEditComment();
          },
          type: ModalCallbackType.Primary
        }
      ]
    });
  };

  const loadNextPage = async () => {
    if (!commentApiEndpoint) {
      console.error("No iterator defined!");
      return;
    }
    analyticsLogEvent("POST_COMMENTS_next");
    const hasNext = await commentApiEndpoint.hasNext();
    setHasMore(hasNext);
    if (!hasNext) return;
    const result = await commentApiEndpoint.getNext();
    setComments([...comments, ...result]);
    commentApiEndpoint.hasNext().then(setHasMore);
  };

  const ionContent = useRef<HTMLIonContentElement>(null);

  useEffect(() => {
    setShowPostEdit(!!editPostId);
    setShowCommentEdit(!!editCommentId);
    analyticsLogEvent("POST_COMMENTS_edit");
  }, [editPostId, editCommentId]);

  useEffect(() => {
    setCommentsResultsWithLocalImages(
      addPostImageToFeed(comments, commentsWithLocalImages)
    );
  }, [commentsWithLocalImages, comments]);

  useEffect(() => {
    if (
      location.pathname?.endsWith("comments") &&
      match.params.post_id !== postId
    ) {
      setPost(undefined);
      setComments([]);
      setHasMore(false);
      setLoading(true);
      setPostId(match.params.post_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, match.params.post_id, postId]);

  useEffect(() => {
    if (postId) {
      setCommentApiEndpoint(getCommentsFeedAPIByPostId(postId));
    }
  }, [postId]);

  useEffect(() => {
    if (commentApiEndpoint !== undefined) {
      reloadPostComments();
    }
    // ignore reloadPostComments
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentApiEndpoint]);

  const doEditComment = async (commentId?: string) => {
    await setEditCommentId(commentId);
    if (commentId) {
      await setCommentModalTitle(t("feed_post_comments.edit_comment"));
    } else {
      await setCommentModalTitle(t("feed_post_comments.add_comment"));
    }
    setShowCommentEdit(true);
  };

  const doRefresh = (event: CustomEvent) => {
    reloadPostComments().then(event.detail.complete);
  };

  useEffect(() => {
    if (postIdToHightlight) {
      scrollToItem(postIdToHightlight, feedType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postIdToHightlight]);

  const isSystemQuestion =
    feedType === FeedTypes.QUESTION && post && post.systemPost;

  const goBack = async () => {
    if (oauthState.hasToken && postTitleAndId.id) {
      await switchOrganisation(oauthState as OauthData, postTitleAndId.id);
    }
    if (feedPathUrl) {
      history.push(feedPathUrl, { feedType });
    } else {
      history.goBack();
    }
  };

  const onDeletePost = (p: ClanPostListResult) => () => setDeletePostId(p.id);
  const onReportPost = (p: ClanPostListResult) => () => setReportPostId(p.id);
  const onEditPost = (p: ClanPostListResult) => () => setEditPostId(p.id);

  const onDeleteComment = (c: ClanPostListResult) => () =>
    setDeleteCommentId(c.id);
  const onReportComment = (c: ClanPostListResult) => () =>
    setReportCommentId(c.id);
  const onEditComment = (c: ClanPostListResult) => () => doEditComment(c.id);

  return (
    <ClanDesktopLayout>
      <IonPage id="comments-page">
        <Header title={postTitleAndId.name} onBack={() => goBack()} />

        <ClanDiscussionPostRemoveModal
          title={t("general.delete_post")}
          postId={deletePostId}
          removeQuestion={t("general.delete_post_confirm")}
          onDone={postDeleteDone}
          onCancel={() => setDeletePostId("")}
          isOpen={!!deletePostId}
        />
        <ClanDiscussionPostReportModal
          title={t("general.report_to_teacher")}
          postId={reportPostId}
          onDone={reloadPostComments}
          onCancel={() => setReportPostId("")}
          isOpen={!!reportPostId}
        />

        <ClanEditPostWithSave
          feedType={feedType}
          parentPostId={match.params.post_id}
          onDidDismiss={() => {
            setEditPostId("");
            setShowPostEdit(false);
          }}
          onCancel={() => {
            setEditPostId("");
            setShowPostEdit(false);
          }}
          onPost={reloadPostComments}
          requireShare={requireShare}
          defaultPageTitle={t("feed_post_comments.edit_post")}
          postId={editPostId}
          isOpen={showPostEdit}
          removeLocalImage={(commentId) =>
            setCommentsWithLocalImages(
              commentsWithLocalImages.filter(
                (comment) => comment?.id !== commentId
              )
            )
          }
          setLoading={setLoading}
          loading={loading}
        />

        <ClanDiscussionPostRemoveModal
          title={t("general.delete_comment")}
          postId={deleteCommentId}
          removeQuestion={t("general.delete_comment_confirm")}
          onDone={(commentIdToBeRemoved: string) => {
            setComments(
              comments.filter((comment) => comment.id !== commentIdToBeRemoved)
            );
            setDeleteCommentId("");
          }}
          onCancel={() => setDeleteCommentId("")}
          isOpen={!!deleteCommentId}
        />
        <ClanDiscussionPostReportModal
          title={t("general.report_to_teacher")}
          postId={reportCommentId}
          onDone={reloadPostComments}
          onCancel={() => setReportCommentId("")}
          isOpen={!!reportCommentId}
        />

        <ClanEditPostWithSave
          feedType={
            feedType === FeedTypes.QUESTION ? FeedTypes.DISCUSSION : feedType
          }
          parentPostId={match.params.post_id}
          onDidDismiss={() => {
            setEditCommentId("");
            setShowCommentEdit(false);
          }}
          onCancel={() => {
            setEditCommentId("");
            setShowCommentEdit(false);
          }}
          removeLocalImage={(commentId) =>
            setCommentsWithLocalImages(
              commentsWithLocalImages.filter(
                (comment) => comment?.id !== commentId
              )
            )
          }
          onPost={reloadPostComments}
          requireShare={false}
          defaultPageTitle={commentModalTitle}
          postId={editCommentId}
          isOpen={showCommentEdit}
          setLoading={setLoading}
          loading={loading}
        />

        <GrayContent ref={ionContent}>
          <ClanLoader message={t("general.loading")} showLoading={loading} />
          <IonRefresher
            slot="fixed"
            onIonRefresh={doRefresh}
            pullFactor={0.5}
            pullMin={100}
            pullMax={200}
          >
            <IonRefresherContent />
          </IonRefresher>
          <ContentWrapper>
            {post && (
              <MainClanDiscussionPost
                key={post.id}
                post={post}
                showReactions={feedType !== FeedTypes.PERSONAL}
                onDelete={onDeletePost(post)}
                onReport={onReportPost(post)}
                onEdit={onEditPost(post)}
              />
            )}

            {(commentsResultWithLocalImages || []).length === 0 && !loading && (
              <LlamaBox>
                <H6>
                  {isSystemQuestion
                    ? t("feed_post_comments.share_answer")
                    : t("feed_post_comments.share_reply")}
                </H6>
              </LlamaBox>
            )}

            {commentsResultWithLocalImages?.map((comment) => (
              <PostListItem
                isComment
                key={comment.id}
                post={comment}
                showReactions={feedType !== FeedTypes.PERSONAL}
                onDelete={onDeleteComment(comment)}
                onReport={onReportComment(comment)}
                onEdit={onEditComment(comment)}
              />
            ))}
            {post && (commentsResultWithLocalImages || []).length >= 5 && (
              <IonSegment>
                <IonSegmentButton
                  className="font-extrabold font-gilroy text-clanGreen-100"
                  disabled={!hasMore}
                  onClick={loadNextPage}
                >
                  {hasMore
                    ? t("general.list_load_more")
                    : t("general.list_end")}
                </IonSegmentButton>
              </IonSegment>
            )}
          </ContentWrapper>

          {post && (commentsResultWithLocalImages || []).length >= 5 && (
            <IonSegment>
              <IonSegmentButton
                className="font-extrabold font-gilroy text-clanGreen-100"
                onClick={() => {
                  if (ionContent.current) ionContent.current.scrollToTop(500);
                }}
              >
                {t("general.scroll")}
              </IonSegmentButton>
            </IonSegment>
          )}
        </GrayContent>
        {post && (
          <FooterButtons>
            <PrimaryFooterButton onClick={() => doEditComment(undefined)}>
              {feedType === FeedTypes.QUESTION && post.systemPost
                ? t("general.answer")
                : t("general.comment")}
            </PrimaryFooterButton>
          </FooterButtons>
        )}
      </IonPage>
    </ClanDesktopLayout>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    oauthState: state.token
  }),
  mapDispatchToProps: {
    setAppErrorConnect: setAppError,
    dispatchLlamaModal: showLlamaModal,
    dispatchRemoveModal: removeModal
  },
  component: withRouter(FeedPostComments)
});
