import { IonAvatar } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as DefaultAvatarIcon } from "../../icons/default-avatar.svg";
import { ReactComponent as TeacherBadgeIcon } from "../../icons/teacher_badge.svg";
import { ProfileNameKey } from "../../util/api/ProfileService";
import { dateLocaleFormatter } from "../../util/DateUtils";
import { desktopOnlyStyles } from "../../util/ResponsiveUtils";
import ClanSecondaryToolbar from "../ClanSecondaryToolbar";

const ProfileMetaSection = styled.div`
  width: 100%;
  padding: 0 1.8rem 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;

  ${desktopOnlyStyles({
    marginTop: "2rem"
  })}
`;

const ProfileToolbar = styled(ClanSecondaryToolbar)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const ProfileName = styled.h2`
  margin-top: 0.8rem;
  margin-bottom: 0;
`;

const ProfileDescription = styled.p`
  line-height: 22px;
  letter-spacing: 0.2px;
  margin: 0;
`;

interface ProfileMetaProps {
  showToolbar?: boolean;
  fullName?: string;
  profileImage?: string;
  dateOfBirth?: string;
  nationalities?: Array<ProfileNameKey | string>;
  languages?: Array<ProfileNameKey | string>;
  isTeacher?: boolean;
  onProfileImageChange?: () => void;
}

const ProfileMeta: React.FC<ProfileMetaProps> = ({
  showToolbar = false,
  fullName,
  profileImage,
  dateOfBirth,
  nationalities,
  languages,
  isTeacher = false,
  onProfileImageChange
}) => {
  const { t } = useTranslation();
  function getCommaAnds(data: any) {
    const array: string[] = [];
    data?.forEach((ele: { name: string }) => {
      array.push(ele.name);
    });
    const last = array?.pop();
    return `${array?.join(", ")} ${
      array.length > 0 ? t("profile_meta.and") : ""
    } ${last}`;
  }

  function getLanguagesAndNationality() {
    const hasNationalities = nationalities && nationalities?.length > 0;
    const hasLanguages = languages && languages.length > 0;

    if (hasNationalities && hasLanguages) {
      return t("profile_meta.introduction", {
        languages: getCommaAnds(languages),
        nationalities: getCommaAnds(nationalities)
      });
    } else if (hasNationalities) {
      return t("profile_meta.introduction_nationalities", {
        nationalities: getCommaAnds(nationalities)
      });
    } else if (hasLanguages) {
      return t("profile_meta.introduction_languages", {
        languages: getCommaAnds(languages)
      });
    }

    return "";
  }

  function getProfileDescriptionText() {
    const languagesText = getLanguagesAndNationality();

    const birthdayText = dateOfBirth
      ? t("profile_meta.birthday", {
          time: dateLocaleFormatter(dateOfBirth, "MMMM d")
        })
      : null;

    return [languagesText, birthdayText].join(" ").trim();
  }

  return (
    <ProfileMetaSection>
      {showToolbar && <ProfileToolbar />}
      <IonAvatar className="h-clanAvatar w-clanAvatar overflow-hidden flex items-center justify-center border-4 border-solid border-clanYellow-100">
        {profileImage ? (
          <img
            id="profilephoto"
            src={profileImage}
            onClick={onProfileImageChange}
            className="cursor-pointer"
            alt="avatar"
          />
        ) : (
          <DefaultAvatarIcon
            id="profilephoto"
            className="cursor-pointer"
            onClick={onProfileImageChange}
          />
        )}
      </IonAvatar>
      <ProfileName>
        {fullName || ""}
        {isTeacher && (
          <span className="align-middle m-2">
            <TeacherBadgeIcon />
          </span>
        )}
      </ProfileName>

      <ProfileDescription>
        {isTeacher && (
          <>
            {t("profile_meta.happy")}
            <br />
            <br />
          </>
        )}
        {getProfileDescriptionText()}
      </ProfileDescription>
    </ProfileMetaSection>
  );
};

export default ProfileMeta;
