import { ViewTypes } from "./ViewTypes";
import {
  ViewFilterSharingBase,
  ViewFilterSharingBaseItem
} from "./ViewFilterSharingBase";

export enum ViewFilters {
  UserOrgAll = "USER_ORG_ALL",
  SpecificGroup = "SPECIFIC_GROUP",
  AllGroups = "ALL_GROUPS",
  MyGroups = "MY_GROUPS",
  GroupsMembership = "GROUPS_MEMBERSHIP",
  AllUsersInGroup = "ALL_USERS_IN_GROUP",
  UserOrgTeachers = "USER_ORG_TEACHERS",
  UserOrgStaff = "USER_ORG_STAFF",
  UserOrgStudent = "USER_ORG_STUDENT",
  UserOrgSupport = "USER_ORG_SUPPORT",
  UserOrgGroupLead = "USER_ORG_GROUP_LEAD",
  UserOrgAdmin = "USER_ORG_ADMIN"
}

export type ViewFiltersType = ViewTypes.GROUP | ViewTypes.USER;

export type ViewFilterConfigurationItem = ViewFilterSharingBaseItem<
  ViewFilters,
  ViewFiltersType
>;

export type ViewFilterConfiguration = ViewFilterSharingBase<
  ViewFilters,
  ViewFiltersType
>;
