import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { firebaseApp } from "../../../configuration";
import { Capacitor } from "@capacitor/core";

export const getFirebaseAuthentication = () => getAuth(firebaseApp);

export const getRecaptchaVerifier = (): RecaptchaVerifier | undefined => {
  if (Capacitor.isNativePlatform()) return undefined;
  return new RecaptchaVerifier(
    "recaptcha-container",
    {
      size: "invisible",
      callback() {
        console.debug("[FIREBASE_VALIDATE] recaptcha resolved");
      },
      "expired-callback"(): void {
        console.debug("[FIREBASE_VALIDATE] Captcha expired");
      }
    },
    getFirebaseAuthentication()
  );
};
