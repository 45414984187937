export enum ApiResponseErrorType {
  GENERIC = "Error",
  DOM_ERROR = "DOM_ERROR",
  HTTP_GENERIC = "Http error",
  HTTP_PRECONDITION_FAILED = "Precondition Failed",
  HTTP_PRECONDITION_REQUIRED = "Precondition Required",
  HTTP_TOO_MANY_REQUESTS = "Too Many Requests",
  HTTP_NETWORK_ERROR = "Network Error",
  HTTP_REQUEST_ABORTED = "Request Aborted",
  HTTP_PARSE_TIMEOUT = "ERR_PARSE_TIMEOUT",
  HTTP_TRANSFORMATION = "Transformation",
  HTTP_MAX_CONTENT_EXCEEDED = "Maximum content",
  HTTP_UNAUTHORIZED = "Unauthorized",
  HTTP_NOT_FOUND = "Not found",
  HTTP_FORBIDDEN = "Forbidden",
  HTTP_BAD_REQUEST = "BAD_REQUEST",
  HTTP_SERVER_ERROR = "SERVER_ERROR",
  HTTP_SERVICE_UNAVAILABLE = "Service Unavailable", // in our case it might be a maintenance mode
  HTTP_PAYMENT_REQUIRED = "Payment Required"
}

export interface ApiResponse<T = any> {
  url?: string;
  data?: T;
  status: number;
  statusText: string;
  headers?: any;
  request?: any;
}

export interface ApiRequestErrorJSON {
  type: ApiResponseErrorType;
  message: string;
  name: string;
  cause: string;
  status?: number;
  statusText?: string;
  stack: string;
  request?: ApiRequest;
}

export interface ApiRequest {
  url?: string;
  baseUrl: string;
}

export interface ApiRequestError extends Error {
  isApiRequestError: boolean;
  response?: ApiResponse;
  request?: ApiRequest;
  type: ApiResponseErrorType;
  cause: Error;
  toJSON: () => ApiRequestErrorJSON;
}
