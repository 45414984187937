import { Camera, CameraResultType, ImageOptions } from "@capacitor/camera";

interface ClanTakePictureProps {
  height?: number;
  width?: number;
  preserveAspectRatio?: boolean;
}

const DEFAULT_PROPS = {
  quality: Number(process.env.REACT_APP_CONFIG_PICTURE_UPLOAD_QUALITY),
  allowEditing: false,
  height: Number(process.env.REACT_APP_CONFIG_PICTURE_UPLOAD_MAX_HEIGHT),
  width: Number(process.env.REACT_APP_CONFIG_PICTURE_UPLOAD_MAX_WIDTH),
  preserveAspectRatio: true,
  resultType: CameraResultType.Base64
} as ImageOptions;

interface ClanTakePictureResult {
  image: string;
  value: string;
}

const TakePhoto = async (
  params?: ClanTakePictureProps
): Promise<ClanTakePictureResult> => {
  const cameraPhoto = await Camera.getPhoto({
    ...DEFAULT_PROPS,
    ...params
  });
  if (cameraPhoto.base64String) {
    return {
      image: `data:image/png;base64,${cameraPhoto.base64String}`,
      value: cameraPhoto.base64String
    };
  }
  throw new Error("UPS! Camera does not support base64!");
};

export default {
  TakePhoto
};
