import React from "react";
import styled from "styled-components";

const StyledLink = styled.a`
  text-decoration: inherit;
  color: inherit;
`;

interface LinkWrapperProps {
  href?: string;
}

const LinkWrapper: React.FC<LinkWrapperProps> = ({ href, children }) => (
  <StyledLink href={href} target="_blank">
    {children}
  </StyledLink>
);

export default LinkWrapper;
