import { UserProfileState } from "./profile.state";
import {
  SET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  UserProfileAction
} from "./profile.actions";

export function userProfileReducer(
  state: UserProfileState,
  action: UserProfileAction
): UserProfileState {
  if (action.type === UPDATE_USER_PROFILE) {
    return { ...state, ...action.data };
  } else if (action.type === SET_USER_PROFILE) {
    return action.data as UserProfileState;
  }
  return state;
}
