import React from "react";

import { ReactComponent as TeacherBadgeIcon } from "../icons/teacher_badge.svg";
import { UserEnabledProperties } from "../util/api/UserEnabledPropertiesService";
import { IdentityAware } from "../util/models/ClanProfileModels";

interface TeacherBadgeProps {
  profile: IdentityAware;
}

const ClanTeacherBadge: React.FC<TeacherBadgeProps> = ({ profile }) => {
  return (
    <>
      {UserEnabledProperties.UserStatus.hasTeacherBadge(profile) && (
        <span className="align-middle m-2">
          <TeacherBadgeIcon />
        </span>
      )}
    </>
  );
};

export default ClanTeacherBadge;
