import {
  UserPropertiesDataMap,
  UserPropertiesPersistedDataType
} from "../types/UserPropertiesData.d";
import {
  fetchUserProperties,
  sendUserProperties,
  sendUserProperty
} from "./UserPropertiesAPI";
import { Storage } from "../../storage/DataStorage";

const serverUserProperties = [
  UserPropertiesPersistedDataType.INFO_MODAL_SEEN,
  UserPropertiesPersistedDataType.TUTORIAL_OPENED,
  UserPropertiesPersistedDataType.FEEDBACK_GIVEN,
  UserPropertiesPersistedDataType.RECIEVED_FIRST_SURVEY,
  UserPropertiesPersistedDataType.DISCOVER_COMMUNITY_SEEN
];

export const setUserProperty = async (
  key: UserPropertiesPersistedDataType,
  value: string
) => {
  if (serverUserProperties.indexOf(key) > -1) {
    const oldValue = await Storage.get({ key });
    await Storage.setUnsafe({ key, value });
    if (oldValue?.value !== value) {
      await sendUserProperty(key, value);
    }
  } else {
    await Storage.setUnsafe({ key, value });
  }
};

export const setUserPropertyAsBoolean = async (
  key: UserPropertiesPersistedDataType,
  value: boolean
) => {
  return setUserProperty(key, value.toString());
};

export const getUserProperty = async (
  key: UserPropertiesPersistedDataType
): Promise<string | null> => {
  return Storage.get({ key }).then((data) =>
    data.value === undefined ? null : data.value
  );
};

export const getUserPropertyAsBoolean = async (
  key: UserPropertiesPersistedDataType
): Promise<boolean> => getUserProperty(key).then((data) => data === "true");

export const refreshAndCacheUserProperties = async () => {
  try {
    const result = await fetchUserProperties();
    for (const [key, value] of Object.entries(result)) {
      if (value !== null && value !== undefined) {
        await Storage.setUnsafe({ key, value: value.toString() });
      }
    }
  } catch (e) {
    console.error("Error while refreshing the properties", e);
  }
};

export const cleanupCachedData = async () => {
  const props: UserPropertiesDataMap = {};
  const keys = Object.values(UserPropertiesPersistedDataType);
  for (const item in keys) {
    const key = keys[item];
    const value = await getUserProperty(key);
    const nullableValue = value !== null ? value.toString() : null;
    if (serverUserProperties.indexOf(key) > -1) {
      props[key] = nullableValue;
    }
    await Storage.remove({ key });
  }
  await sendUserProperties(props);
};
