import { IonModal, IonTextarea } from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";
import { SmoothRays } from "../ClanSmoothRays";
import FooterButtons, { PrimaryFooterButton } from "../FooterButtons";
import SubPageHeader from "../SubPageHeader";
import { TextareaChangeEventDetail } from "@ionic/core";

const InputWrapper = styled.div`
  max-width: 90%;
  margin: 3.6rem auto 0;
`;

const GroupNameInput = styled(IonTextarea)``;

interface NewGroupModalProps {
  onSubmit: (name: string) => void;
  onClose: () => void;
}

const NewGroupModal: React.FC<NewGroupModalProps> = ({ onSubmit, onClose }) => {
  const [name, setName] = useState("");

  const { t } = useTranslation();

  const onNameChange = (e: CustomEvent<TextareaChangeEventDetail>) => {
    const value = e.detail.value;
    if (!value?.trim()) {
      if (name !== "") setName("");
      return;
    }
    if (value?.trim() === name) return;
    const results: string[] = [];
    for (let i = 0; i < value.length; i++) {
      const character = value.charAt(i);
      if (
        character === " " ||
        character === "\n" ||
        character === "\r" ||
        character === "\t"
      ) {
        if (results.length > 0 && results[results.length - 1] !== " ") {
          results.push(" ");
        }
        continue;
      }
      results.push(character);
    }
    setName(results.join(""));
  };

  const onSubmitForm = () => onSubmit(name.trim());

  return (
    <IonModal isOpen onDidDismiss={onClose}>
      <ClanIonContent>
        <SmoothRays />

        <SubPageHeader
          title={t("groups_view.create_group_title")}
          onBack={onClose}
        />
        <InputWrapper>
          <GroupNameInput
            onIonChange={onNameChange}
            className="firstName text-clanH2 text-black font-extrabold font-gilroy"
            placeholder={t("groups_view.new_group_name_placeholder")}
            rows={5}
            value={name}
          />
        </InputWrapper>
      </ClanIonContent>
      <FooterButtons>
        <PrimaryFooterButton disabled={!name} onClick={onSubmitForm}>
          {t("general.create")}
        </PrimaryFooterButton>
      </FooterButtons>
    </IonModal>
  );
};

export default NewGroupModal;
