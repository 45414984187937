import {
  IonFooter,
  IonList,
  IonModal,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import { useAsync, useAsyncEffect } from "@react-hook/async";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import { doRequest } from "../../apiService";
import { connect } from "../../data/connect";
import { updateUserProfile } from "../../data/user/profile/profile.actions";
import { UserProfileState } from "../../data/user/profile/profile.state";
import { ReactComponent as EmptyIcon } from "../../icons/empty.svg";
import { COMMUNITY_SLIDES } from "../../pages/People";
import {
  isReadyStateCancelOrFail,
  isReadyStateLoading
} from "../../util/AsyncEffectFilter";
import {
  BUCKET_LIST_ENDPOINTS,
  ClanInterestsEndpoints,
  INTERESTS_ENDPOINTS
} from "../../util/ClanInterestsEndpoints";
import {
  useCommunityLabelAmountFilterPaginator,
  useProfilesForItemPaginator
} from "../../util/CustomHooks";
import { ListItemType } from "../../util/Feeds";
import { FilterGroupType } from "../../util/Filters";
import { ClanMembershipListItemResponse } from "../../util/models/ClanProfileModels";
import { ClanLabelAmount } from "../../util/models/ClanValueAmount";
import ClanLoader from "../ClanLoader";
import ClanProfileCard from "../ClanProfileCard";
import ClanProfileListItem from "../ClanProfileListItem";

interface OwnProps {
  title: string;
  filters?: FilterGroupType[];
  endpoints: ClanInterestsEndpoints;
  profilesTitle: string;
  noManage?: boolean;
  cardClassName?: string;
  itemClassName?: string;
  withAmounts?: boolean;
  activeIndex: COMMUNITY_SLIDES;
  // onlyMine?: boolean;
  button?: { name: string; callback: any; primary: boolean };
}

interface DispatchProps {
  updateUserProfileConnect: typeof updateUserProfile;
}

interface StateProps {
  userProfileConnect: UserProfileState;
}

interface ClanListInterestTemplateProps
  extends OwnProps,
    DispatchProps,
    StateProps {}

const ClanListInterestTemplate: React.FC<ClanListInterestTemplateProps> = ({
  title,
  filters,
  endpoints,
  profilesTitle,
  updateUserProfileConnect,
  userProfileConnect,
  noManage = false,
  cardClassName = "",
  itemClassName = "",
  withAmounts,
  activeIndex,
  // onlyMine,
  button = undefined
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [labelAmounts, setLableAmounts] = useState<ClanLabelAmount[]>([]);
  const [myLabelAmounts, setMyLabelAmounts] = useState<ClanLabelAmount[]>([]);
  const [
    selectedLabelAmount,
    setSelectedLabelAmount
  ] = useState<ClanLabelAmount>();

  const [labelAmountProfiles, setLabelAmountProfiles] = useState<
    ClanMembershipListItemResponse[]
  >([]);
  const [hasMore, setHasMore] = useState(false);
  const [profilesHasMore, setProfilesHasMore] = useState<boolean>();
  const [interestCreator, setInterestCreator] = useState<string | undefined>();

  const { t } = useTranslation();
  const listItemType = useMemo(() => {
    switch (endpoints.screenName) {
      case "INTERESTS":
        return ListItemType.Interest;
      case "BUCKET_LIST":
        return ListItemType.BucketList;
      case "LANGUAGES":
        return ListItemType.Languages;
      default:
        return ListItemType.Interest;
    }
  }, [endpoints.screenName]);

  const labelAmountPaginator = useCommunityLabelAmountFilterPaginator(
    listItemType,
    filters
  );

  const labelAmountItemPaginator = useProfilesForItemPaginator(
    listItemType,
    selectedLabelAmount,
    filters
  );

  useEffect(() => {
    setMyLabelAmounts(labelAmounts.filter((item) => item.mine));
  }, [labelAmounts]);

  useAsyncEffect(async () => {
    if (!labelAmountPaginator) return;
    try {
      await labelAmountPaginator.setQueryArgumentNoFetch("query", searchText);
      await labelAmountPaginator.reset();
      const result = await labelAmountPaginator.getNext();
      setLableAmounts(result);
      labelAmountPaginator
        .hasNext()
        .then(setHasMore)
        .catch((error) => console.error("Failed to fetch next page", error));
    } catch (error) {
      console.error("Failed to fetch label value pairs", error);
    }
  }, [labelAmountPaginator, searchText]);

  useEffect(() => {
    setAnalyticsScreenName(endpoints.screenName);
    analyticsLogEvent(`${endpoints.screenName}_list`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadNextProfilesPage = async (forceClean = false) => {
    if (forceClean) {
      setLabelAmountProfiles([]);
      setInterestCreator(undefined);
    }
    if (!labelAmountItemPaginator) {
      setLabelAmountProfiles([]);
      setInterestCreator(undefined);
      return;
    }
    try {
      const result = await labelAmountItemPaginator.getNext();
      if (forceClean) {
        setLabelAmountProfiles([...result]);
      } else {
        setLabelAmountProfiles([...labelAmountProfiles, ...result]);
      }
      const lastItem = await labelAmountItemPaginator.getLastItem();
      setInterestCreator(lastItem?.owner?.name);
      labelAmountItemPaginator
        .hasNext()
        .then(setProfilesHasMore)
        .catch((error) => console.error("Failed to fetch next page", error));
    } catch (error) {
      console.error("Failed to fetch label value pairs", error);
    }
  };

  useAsyncEffect(async () => {
    if (!labelAmountItemPaginator) {
      return;
    }
    analyticsLogEvent("list_profile_items");
    await loadNextProfilesPage(true);
  }, [labelAmountItemPaginator]);

  const isInterestSelected = (item: string): boolean => {
    // return onlyMine ? false : myLabelAmounts.includes(item);
    return !!myLabelAmounts.find((labelAmount) => labelAmount.label === item);
  };

  const onRowClick = (labelAmount: ClanLabelAmount) => {
    setSelectedLabelAmount(labelAmount);
  };

  const setChanges = () => {
    setSelectedLabelAmount(undefined);
    analyticsLogEvent(`${endpoints.screenName}_update`);
    labelAmountPaginator?.reset().then(() => {
      labelAmountPaginator
        .getNext()
        .then((response) => {
          setLableAmounts(response);
        })
        .then(() => labelAmountPaginator.hasNext().then(setHasMore));
    });
  };

  const onAddClick = async () => {
    if (
      !selectedLabelAmount ||
      myLabelAmounts.find(
        (labelAmount) => labelAmount.label === selectedLabelAmount.label
      )
    ) {
      return;
    }
    await doRequest("post", endpoints.addToProfile, {
      values: [selectedLabelAmount.label]
    });
    analyticsLogEvent(`${endpoints.screenName}_add`);
    if (endpoints === BUCKET_LIST_ENDPOINTS) {
      userProfileConnect.bucketList &&
        updateUserProfileConnect({
          bucketList: [
            ...userProfileConnect.bucketList,
            selectedLabelAmount.label
          ]
        });
    } else if (endpoints === INTERESTS_ENDPOINTS) {
      if (userProfileConnect.interests) {
        userProfileConnect.interests &&
          updateUserProfileConnect({
            interests: [
              ...userProfileConnect.interests,
              selectedLabelAmount.label
            ]
          });
      }
    }
    setChanges();
  };

  const onRemoveClick = async () => {
    if (
      !selectedLabelAmount ||
      !myLabelAmounts.find(
        (labelAmount) => labelAmount.label === selectedLabelAmount.label
      )
    ) {
      return;
    }
    await doRequest("delete", endpoints.removeFromProfile, {
      values: [selectedLabelAmount.label]
    });
    analyticsLogEvent(`${endpoints.screenName}_remove`);
    if (endpoints === BUCKET_LIST_ENDPOINTS) {
      userProfileConnect.bucketList &&
        updateUserProfileConnect({
          bucketList: userProfileConnect.bucketList.filter(
            (item) => item !== selectedLabelAmount.label
          )
        });
    } else if (endpoints === INTERESTS_ENDPOINTS) {
      if (userProfileConnect.interests) {
        userProfileConnect.interests &&
          updateUserProfileConnect({
            interests: userProfileConnect.interests.filter(
              (item) => item !== selectedLabelAmount.label
            )
          });
      }
    }
    setChanges();
  };

  const [nextPageStatus, loadNextPage] = useAsync(async () => {
    if (!hasMore || !labelAmountPaginator) return;
    const result = await labelAmountPaginator.getNext();
    if (!result || isReadyStateCancelOrFail(nextPageStatus)) return;
    setLableAmounts([...labelAmounts, ...result]);
    const _hasMore = await labelAmountPaginator.hasNext();
    if (isReadyStateCancelOrFail(nextPageStatus)) return;
    setHasMore(_hasMore);
    // TODO errors are swallowed up
  });

  return (
    <>
      <ClanLoader
        message={t("general.loading")}
        showLoading={isReadyStateLoading(nextPageStatus)}
      />
      <IonModal
        isOpen={!!selectedLabelAmount}
        onDidDismiss={() => {
          setSelectedLabelAmount(undefined);
        }}
      >
        <div className="text-center mt-12 text-black w-full">
          <h3 className="my-1">
            {selectedLabelAmount ? selectedLabelAmount.label : null}
          </h3>
          <h5>
            {interestCreator
              ? t("people_overview.created_by", { name: interestCreator })
              : null}
          </h5>
        </div>

        <ClanProfileCard
          header={`${labelAmountProfiles.length} ${profilesTitle}`}
          contentClassName="px-3"
        >
          <IonList className="py-0">
            <>
              {labelAmountProfiles
                ? labelAmountProfiles.map((profile) => {
                    return (
                      <ClanProfileListItem
                        profile={profile}
                        key={`interest_profile_${profile.profileId}`}
                        onNavigation={() => {
                          setSelectedLabelAmount(undefined);
                        }}
                      />
                    );
                  })
                : null}
              <IonSegment>
                <IonSegmentButton
                  className="font-extrabold font-gilroy text-clanGreen-100"
                  disabled={!profilesHasMore}
                  onClick={() => loadNextProfilesPage(false)}
                >
                  {profilesHasMore
                    ? t("general.list_load_more")
                    : t("general.list_end")}
                </IonSegmentButton>
              </IonSegment>
            </>
          </IonList>
        </ClanProfileCard>
        <IonFooter>
          <IonSegment>
            <IonSegmentButton
              color="primary"
              className="font-extrabold font-gilroy text-clanGreen-100 bg-clanGray-100"
              onClick={() => {
                setSelectedLabelAmount(undefined);
              }}
            >
              {t("general.back")}
            </IonSegmentButton>
            {noManage ? null : (
              <IonSegmentButton
                color="primary"
                className="text-black bg-clanYellow-100"
                onClick={
                  selectedLabelAmount &&
                  isInterestSelected(selectedLabelAmount.label)
                    ? onRemoveClick
                    : onAddClick
                }
              >
                <h4>
                  {selectedLabelAmount &&
                  isInterestSelected(selectedLabelAmount.label)
                    ? t("people_overview.remove_from_profile")
                    : t("people_overview.add_to_profile")}
                </h4>
              </IonSegmentButton>
            )}
          </IonSegment>
        </IonFooter>
      </IonModal>
      <ClanProfileCard
        header={t(
          "people_overview." + title.toLocaleLowerCase().replace(/\s/g, "")
        )}
        cardClassName={cardClassName}
        contentClassName="px-3"
        {...(title !== "Languages" && {
          searchText,
          setSearchText
        })}
        {...(button && {
          button
        })}
      >
        <IonList>
          {labelAmounts && labelAmounts.length > 0 ? (
            <>
              {labelAmounts.map((item: ClanLabelAmount, itemIndex: number) => {
                return (
                  <div
                    key={`interest_${itemIndex}`}
                    className={`${itemClassName} text-center mx-2 my-1 p-3 rounded-full bg-clanGray-100 cursor-pointer ${
                      isInterestSelected(item.label)
                        ? "border border-solid border-clanGreen-100"
                        : ""
                    }`}
                    onClick={() => onRowClick(item)}
                  >
                    <span className="mr-auto" />
                    <span className="whitespace-normal text-black self-center px-4">
                      {item.label}
                    </span>
                    {withAmounts && (
                      <span className="ml-auto whitespace-normal text-black self-center">
                        {item.amount}
                      </span>
                    )}
                  </div>
                );
              })}
              <IonSegment>
                <IonSegmentButton
                  className="font-extrabold font-gilroy text-clanGreen-100"
                  disabled={!hasMore}
                  onClick={loadNextPage}
                >
                  {hasMore
                    ? t("general.list_load_more")
                    : t("general.list_end")}
                </IonSegmentButton>
              </IonSegment>
            </>
          ) : (
            <div className="mt-8 px-8">
              <EmptyIcon className="h-40" />
              <h4 className="my-4">
                {t(
                  "people_overview.not_added_" +
                    title.toLowerCase().replace(/\s/g, "")
                )}
              </h4>
              <p className="mb-4">
                {t(
                  "people_overview.nothing_to_show_" +
                    title.toLowerCase().replace(/\s/g, "")
                )}
              </p>
              <p>{t("people_overview.fill_profile")}</p>
            </div>
          )}
        </IonList>
      </ClanProfileCard>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    userProfileConnect: state.userProfile
  }),
  mapDispatchToProps: {
    updateUserProfileConnect: updateUserProfile
  },
  component: ClanListInterestTemplate
});
