export enum Views {
  Community = "COMMUNITY",
  DiscussionFeed = "DISCUSSION_FEED",
  DataDashboard = "DATA_DASHBOARD",
  GroupMembers = "GROUP_MEMBERS",
  Announcement = "ANNOUNCEMENT_FEED",
  QuestionFeed = "QUESTION_FEED",
  ReflectionFeed = "REFLECTION_FEED",
  SupportList = "SUPPORT_LIST",
  TasksFeed = "TASK_FEED",
  Chat = "CHAT"
}
