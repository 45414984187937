import { IonIcon } from "@ionic/react";
import { format } from "date-fns";
import { et } from "date-fns/locale";
import { checkmarkCircle, ellipseOutline } from "ionicons/icons";
import React from "react";
import styled from "styled-components";
import i18n from "../../i18n";

import { WEIGHTSCALE } from "../../pages/Planner/PlannerDay";
import { UserTaskResult } from "../../util/models/PlannerUserTaskModels";

import { ReactComponent as Weight1Icon } from "../../icons/planner/weight-1.svg";
import { ReactComponent as Weight2Icon } from "../../icons/planner/weight-2.svg";
import { ReactComponent as Weight3Icon } from "../../icons/planner/weight-3.svg";

const WEIGHT_ICONS = {
  1: <Weight1Icon />,
  2: <Weight2Icon />,
  3: <Weight3Icon />
};

const TaskItemWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StyledTaskItem = styled.div<{ isExternal: boolean }>`
  display: grid;
  grid-template-columns: 1fr minmax(0, 4fr) 1fr;
  place-items: center;
  margin-bottom: 0.7rem;
  background: #ffffff;
  box-shadow: 0px 7px 10px rgba(212, 189, 189, 0.05);

  position: relative;
  background-clip: padding-box;
  border-radius: 25px;
  border: solid 1px transparent;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: ${({ isExternal }) =>
      isExternal
        ? "linear-gradient(#4285f4, #34a853, #fbbc05, #ea4335)"
        : "white"};
  }
`;

const MetaWrapper = styled.div`
  width: 100%;
  padding: 1.8rem 0;
  cursor: pointer;
`;

const TaskTitle = styled.div`
  font-family: Gilroy;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.3rem;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  padding-bottom: 0.25rem;
  max-height: 4.15rem;
  overflow: hidden;
`;

const MetaItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const ActionWrapper = styled.div``;
const WeightWrapper = styled.div`
  cursor: pointer;
`;

const CompleteIcon = styled(IonIcon)`
  color: #45dd85;
  font-size: 1.8rem;
  display: block;
  cursor: pointer;
`;

const StyledTaskChip = styled.div<{ nonFlexible?: boolean }>`
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 25px;
  padding: 0.5rem 1rem;
  margin-right: 0.25rem;
  margin-top: 0.25rem;
  min-width: ${({ nonFlexible }) => (nonFlexible ? "fit-content" : 0)};
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
`;

interface TaskChipProps {
  label: string;
  nonFlexible?: boolean;
  onClick?: () => void;
}

const TaskChip: React.FC<TaskChipProps> = ({ label, onClick, nonFlexible }) => (
  <StyledTaskChip nonFlexible={nonFlexible} onClick={onClick}>
    {label}
  </StyledTaskChip>
);

interface TaskItemProps {
  task: UserTaskResult;
  onClick?: () => void;
  onToggleCompleted?: () => void;
}

const TaskItem: React.FC<TaskItemProps> = ({
  task,
  onClick,
  onToggleCompleted
}) => {
  const { title, labels, weight, dueDate, completedDate } = task;

  const getWeightIcon = (w: number) => {
    switch (w) {
      case WEIGHTSCALE.EASY:
        return 1;
      case WEIGHTSCALE.MEDIUM:
        return 2;
      case WEIGHTSCALE.HARD:
        return 3;
      default:
        return null;
    }
  };

  const getLabelsToShow = (labelsArr: Array<string> = []) => {
    if ((!dueDate && labelsArr.length === 3) || labelsArr.length === 2) {
      return { visible: labelsArr, extraCount: 0 };
    }

    const [visibleLabel, ...hiddenLabels] = labelsArr;
    return {
      visible: visibleLabel ? [visibleLabel] : [],
      extraCount: hiddenLabels.length
    };
  };

  const weightIcon = getWeightIcon(weight);
  const labelsToShow = getLabelsToShow(labels);

  const isExternal =
    (task.sharing?.externalId || "").indexOf("google-classroom") === 0;

  return (
    <TaskItemWrapper>
      <StyledTaskItem isExternal={isExternal}>
        <WeightWrapper onClick={onClick}>
          {weightIcon && WEIGHT_ICONS[weightIcon]}
        </WeightWrapper>
        <MetaWrapper onClick={onClick}>
          <TaskTitle>{title}</TaskTitle>
          <MetaItemsWrapper>
            {dueDate && (
              <TaskChip
                nonFlexible
                label={
                  i18n.language === "et"
                    ? format(new Date(dueDate), "LLL d", { locale: et })
                    : format(new Date(dueDate), "LLL d")
                }
              />
            )}

            {labelsToShow.visible.map((label) => (
              <TaskChip key={label} label={label} />
            ))}

            {labelsToShow.extraCount > 0 && (
              <TaskChip label={`+${labelsToShow.extraCount}`} />
            )}
          </MetaItemsWrapper>
        </MetaWrapper>
        <ActionWrapper>
          <CompleteIcon
            icon={completedDate ? checkmarkCircle : ellipseOutline}
            onClick={onToggleCompleted}
          />
        </ActionWrapper>
      </StyledTaskItem>
    </TaskItemWrapper>
  );
};

export default TaskItem;
