import {
  IonFooter,
  IonHeader,
  IonItem,
  IonList,
  IonNote,
  IonPage,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import { doRequest } from "../../apiService";
import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";
import * as ROUTES from "../../routes";
import { dateToDateString } from "../../util/DateUtils";
import { UserTaskResult } from "../../util/models/PlannerUserTaskModels";
import ClanDesktopLayout from "../ClanDesktopLayout";
import ClanLoader from "../ClanLoader";
import PlannerItem from "./PlannerItem";
import { useAsync, useAsyncEffect } from "@react-hook/async";
import {
  isReadyStateCancelOrFail,
  isReadyStateLoading
} from "../../util/AsyncEffectFilter";
import { AuthorizedApiResult } from "../../util/ApiService";

const ExistingTasks: React.FC<RouteComponentProps> = ({ location }) => {
  const locationState = location.state as { dateChosen: Date; label?: string };
  const basePath = "/usertask/v1/todo";
  // dateChosen should always be present for sorting, if not we take today as a reference for sorting
  const dateChosen = locationState?.dateChosen
    ? locationState?.dateChosen
    : new Date();
  const labelChosen = locationState?.label ? locationState?.label : undefined;
  const [pastTasks, setPastTasks] = useState<UserTaskResult[]>([]);
  const [futureTasks, setFutureTasks] = useState<UserTaskResult[]>([]);
  const [noDateTasks, setNoDateTasks] = useState<UserTaskResult[]>([]);
  const [tasksToShow, setTasksToShow] = useState<UserTaskResult[]>([]);
  const [completedTasks, setCompletedTasks] = useState<UserTaskResult[]>([]);
  const [sharedTasks, setSharedTasks] = useState<UserTaskResult[]>([]);

  const history = useHistory();
  const { t } = useTranslation();

  const addTaskToDate = (task: UserTaskResult) => {
    const taskId = task.id;
    const dateArgument = dateToDateString(new Date(dateChosen));
    (async () => {
      analyticsLogEvent("TASK_add_date");
      await doRequest("put", `${basePath}/${taskId}/date?date=${dateArgument}`);
    })().then(() => history.goBack());
  };

  const isTaskAfterToday = (currentDate: Date) => (reverse: boolean) => (
    task: UserTaskResult
  ): boolean => {
    if (!task.dueDate) return false;
    const taskDate = new Date(task.dueDate);

    if (reverse) {
      return currentDate > taskDate;
    } else {
      return taskDate > currentDate;
    }
  };

  const divideTasks = async (dateSelected: Date, tasks: UserTaskResult[]) => {
    const pastFilter = isTaskAfterToday(dateSelected)(true);
    const futureFilter = isTaskAfterToday(dateSelected)(false);
    const uncompletedTasks = tasks.filter((task) => !task.completedDate);

    setPastTasks(uncompletedTasks.filter(pastFilter));
    setFutureTasks(uncompletedTasks.filter(futureFilter));
    setNoDateTasks(uncompletedTasks.filter((task) => !task.dueDate));
    setCompletedTasks(tasks.filter((task) => !!task.completedDate));
    setSharedTasks(tasks.filter((task) => !!task.sharing?.mySharing));
  };

  enum LIST_TYPES {
    ALL,
    PAST,
    UPCOMING,
    REMEMBER,
    COMPLETED,
    SHARED
  }

  const [activeListType, setActiveListType] = useState<LIST_TYPES>(
    LIST_TYPES.ALL
  );

  const [pendingListStatus, getPendingList] = useAsync(async () => {
    const dateSelected = new Date(dateChosen);
    const dateArgument = dateToDateString(dateSelected);
    setAnalyticsScreenName("PLANNER_LIST_PENDING");
    analyticsLogEvent("TASK_list_pending");

    const result = await AuthorizedApiResult.get<UserTaskResult[]>(
      `${basePath}/pending?date=${dateArgument}`
    );
    if (isReadyStateCancelOrFail(pendingListStatus)) return;
    await divideTasks(dateSelected, result);
    // TODO errors are swallowed up
  });

  const [labelListStatus, getLabelList] = useAsync(async () => {
    const dateSelected = new Date(dateChosen);
    setAnalyticsScreenName("PLANNER_LIST_LABEL");
    analyticsLogEvent("TASK_list_label");

    const result = await AuthorizedApiResult.get<UserTaskResult[]>(
      `${basePath}/labels/list/item?item=${labelChosen}`
    );
    if (isReadyStateCancelOrFail(labelListStatus)) return;
    await divideTasks(dateSelected, result);
    // TODO errors are swallowed up
  });

  useAsyncEffect(async () => {
    if (location.pathname === ROUTES.PLANNER_EXISTING)
      if (labelChosen !== undefined) {
        await getLabelList();
      } else {
        await getPendingList();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, labelChosen]);

  useEffect(() => {
    switch (activeListType) {
      case LIST_TYPES.ALL:
        setTasksToShow([...pastTasks, ...futureTasks, ...noDateTasks]);
        break;
      case LIST_TYPES.PAST:
        setTasksToShow(pastTasks);
        break;
      case LIST_TYPES.UPCOMING:
        setTasksToShow(futureTasks);
        break;
      case LIST_TYPES.REMEMBER:
        setTasksToShow(noDateTasks);
        break;
      case LIST_TYPES.SHARED:
        setTasksToShow(sharedTasks);
        break;
      case LIST_TYPES.COMPLETED:
        setTasksToShow(completedTasks);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pastTasks, futureTasks, noDateTasks, activeListType]);

  return (
    <>
      <ClanLoader
        message="Please wait. Loading..."
        showLoading={isReadyStateLoading(pendingListStatus, labelListStatus)}
      />
      <ClanDesktopLayout>
        <IonPage>
          <IonHeader mode="ios" className="px-4">
            <div className="mt-12 text-center">
              <h2 className="m-0">
                {labelChosen
                  ? labelChosen.charAt(0).toUpperCase() + labelChosen.slice(1)
                  : t("planner.my_tasks")}
              </h2>
            </div>
          </IonHeader>
          <ClanIonContent>
            <IonSegment
              scrollable
              swipeGesture
              onIonChange={(e) => {
                if (e.detail.value) {
                  switch (e.detail.value) {
                    case LIST_TYPES[LIST_TYPES.ALL]:
                      setActiveListType(LIST_TYPES.ALL);
                      break;
                    case LIST_TYPES[LIST_TYPES.PAST]:
                      setActiveListType(LIST_TYPES.PAST);
                      break;
                    case LIST_TYPES[LIST_TYPES.UPCOMING]:
                      setActiveListType(LIST_TYPES.UPCOMING);
                      break;
                    case LIST_TYPES[LIST_TYPES.REMEMBER]:
                      setActiveListType(LIST_TYPES.REMEMBER);
                      break;
                    case LIST_TYPES[LIST_TYPES.SHARED]:
                      setActiveListType(LIST_TYPES.SHARED);
                      break;
                    case LIST_TYPES[LIST_TYPES.COMPLETED]:
                      setActiveListType(LIST_TYPES.COMPLETED);
                      break;
                    default:
                      setActiveListType(LIST_TYPES.ALL);
                  }
                }
              }}
              value={LIST_TYPES[activeListType]}
              color="success"
            >
              <IonSegmentButton
                value={LIST_TYPES[LIST_TYPES.ALL]}
                layout="icon-start"
              >
                <IonNote>{t("planner.due")}</IonNote>
              </IonSegmentButton>
              {pastTasks.length !== 0 && (
                <IonSegmentButton
                  value={LIST_TYPES[LIST_TYPES.PAST]}
                  layout="icon-start"
                >
                  <IonNote>{t("planner.past")}</IonNote>
                </IonSegmentButton>
              )}
              {futureTasks.length !== 0 && (
                <IonSegmentButton
                  value={LIST_TYPES[LIST_TYPES.UPCOMING]}
                  layout="icon-start"
                >
                  <IonNote>{t("planner.upcoming")}</IonNote>
                </IonSegmentButton>
              )}
              {noDateTasks.length !== 0 && (
                <IonSegmentButton
                  value={LIST_TYPES[LIST_TYPES.REMEMBER]}
                  layout="icon-start"
                >
                  <IonNote>{t("planner.no_due_date")}</IonNote>
                </IonSegmentButton>
              )}
              {sharedTasks.length !== 0 && (
                <IonSegmentButton
                  value={LIST_TYPES[LIST_TYPES.SHARED]}
                  layout="icon-start"
                >
                  <IonNote>{t("planner.shared")}</IonNote>
                </IonSegmentButton>
              )}
              {completedTasks.length !== 0 && (
                <IonSegmentButton
                  value={LIST_TYPES[LIST_TYPES.COMPLETED]}
                  layout="icon-start"
                >
                  <IonNote>{t("planner.completed")}</IonNote>
                </IonSegmentButton>
              )}
            </IonSegment>
            <IonList>
              {activeListType !== LIST_TYPES.ALL ? (
                tasksToShow.length !== 0 ? (
                  tasksToShow.map((task: UserTaskResult, index: number) => (
                    <PlannerItem
                      dateChosen={dateChosen}
                      cardClassName="px-5"
                      className="pb-2"
                      chipClassName="ml-4"
                      key={index}
                      item={task}
                      onAdd={() => addTaskToDate(task)}
                      onClick={() =>
                        task.id &&
                        history.push(
                          ROUTES.PLANNER_TASK.replace(":task_id", task.id),
                          { dateChosen }
                        )
                      }
                    />
                  ))
                ) : (
                  <IonItem className="text-center mx-2 my-1">
                    {t("planner.no_task")}
                  </IonItem>
                )
              ) : (
                <>
                  {pastTasks.length !== 0 && (
                    <IonItem className="text-center mx-2 my-1 rounded-full text-clanGray-200">
                      {t("planner.past")}
                    </IonItem>
                  )}
                  {pastTasks.length !== 0 &&
                    pastTasks.map((task: UserTaskResult, index: number) => (
                      <PlannerItem
                        dateChosen={dateChosen}
                        cardClassName="px-5"
                        className="pb-2"
                        chipClassName="ml-4"
                        key={index}
                        item={task}
                        onAdd={() => addTaskToDate(task)}
                        onClick={() =>
                          history.push(
                            ROUTES.PLANNER_TASK.replace(":task_id", task.id),
                            { dateChosen }
                          )
                        }
                      />
                    ))}
                  {futureTasks.length !== 0 && (
                    <IonItem className="text-center mx-2 my-1 rounded-full text-clanGray-200">
                      {t("planner.upcoming")}
                    </IonItem>
                  )}
                  {futureTasks.length !== 0 &&
                    futureTasks.map((task: UserTaskResult, index: number) => (
                      <PlannerItem
                        dateChosen={dateChosen}
                        cardClassName="px-5"
                        className="pb-2"
                        chipClassName="ml-4"
                        key={index}
                        item={task}
                        onAdd={() => addTaskToDate(task)}
                        onClick={() =>
                          history.push(
                            ROUTES.PLANNER_TASK.replace(":task_id", task.id),
                            { dateChosen }
                          )
                        }
                      />
                    ))}
                  {noDateTasks.length !== 0 && (
                    <IonItem className="text-center mx-2 my-1 rounded-full text-clanGray-200">
                      {t("planner.no_due_date")}
                    </IonItem>
                  )}
                  {noDateTasks.length !== 0 &&
                    noDateTasks.map((task: UserTaskResult, index: number) => (
                      <PlannerItem
                        dateChosen={dateChosen}
                        cardClassName="px-5"
                        className="pb-2"
                        chipClassName="ml-4"
                        key={index}
                        item={task}
                        onAdd={() => addTaskToDate(task)}
                        onClick={() =>
                          history.push(
                            ROUTES.PLANNER_TASK.replace(":task_id", task.id),
                            { dateChosen }
                          )
                        }
                      />
                    ))}
                </>
              )}
            </IonList>
          </ClanIonContent>
          <IonFooter>
            <IonSegment className="bg-clanYellow-100">
              <IonSegmentButton
                color="primary"
                className="font-extrabold font-gilroy text-black"
                onClick={() => history.goBack()}
              >
                {t("general.back")}
              </IonSegmentButton>
            </IonSegment>
          </IonFooter>
        </IonPage>
      </ClanDesktopLayout>
    </>
  );
};

export default ExistingTasks;
