import {
  IonFooter,
  IonModal,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ClanProfileCard from "../ClanProfileCard";
import ClanProfileListItem from "../ClanProfileListItem";
import { RoleAwareProfileWithImage } from "../../util/models/ClanProfileModels";
import { useTranslation } from "react-i18next";
import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";

interface ClanPostSharedWithViewProps {
  viewReactions: (id: string) => Promise<RoleAwareProfileWithImage[]>;
  targetId?: string;
  onHide: () => void;
  title: string;
}

const ClanPostSharedWithView: React.FC<ClanPostSharedWithViewProps> = ({
  viewReactions,
  targetId,
  onHide,
  title
}) => {
  const [sharedWith, setSharedWith] = useState<RoleAwareProfileWithImage[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!targetId) return;
    viewReactions(targetId).then(setSharedWith).catch(console.error);
  }, [targetId, viewReactions]);

  return (
    <>
      <IonModal isOpen={!!targetId} onDidDismiss={onHide}>
        <ClanIonContent>
          <div className="h-full flex flex-col justify-center items-center bg-clanGray-100">
            <h3 className="mt-12">{t("clan_discussion_post.shared_with")}</h3>
            <ClanProfileCard
              header={title}
              cardClassName="w-full h-clanCard"
              contentClassName="px-3"
            >
              {sharedWith ? (
                sharedWith.map((person, itemIndex) => (
                  <ClanProfileListItem
                    key={`person_${person.id}_${itemIndex}`}
                    profile={person}
                    onNavigation={onHide}
                  />
                ))
              ) : (
                <span>{t("clan_discussion_post.loading")}</span>
              )}
            </ClanProfileCard>
          </div>
        </ClanIonContent>
        <IonFooter>
          <IonSegment className="w-full bottom-0 bg-clanYellow-100">
            <IonSegmentButton
              className="font-extrabold font-gilroy text-black"
              color="tertiary"
              onClick={onHide}
            >
              {t("general.back")}
            </IonSegmentButton>
          </IonSegment>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default ClanPostSharedWithView;
