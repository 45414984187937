import { IonHeader, IonIcon, IonPage } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";
import styled from "styled-components";

import CategoryHeader from "../../components/CategoryHeader";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import { SmoothRays } from "../../components/ClanToolbarWithRays";
import SettingsButton from "../../components/SettingsButton";
import { connect } from "../../data/connect";
import { ImageState } from "../../data/user/image/image.state";
import { UserProfileState } from "../../data/user/profile/profile.state";
import { ReactComponent as ArrowYellowIcon } from "../../icons/arrow-yellow.svg";
import * as ROUTES from "../../routes";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { SignupSteps } from "../Signup/Signup";

const StyledHeader = styled(IonHeader)`
  padding: 1rem;
  padding-button: 2rem;
  border-bottom: 3px solid ${({ theme }) => theme.primary};
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center !important;
  justify-content: center !important;
`;

const StyledCategoryHeader = styled(CategoryHeader)`
  margin: 2rem 1.8rem 0.7rem;
`;

const SettingsButtonsWrapper = styled.div`
  margin: 0 1.8rem 0.7rem;
  width: calc(100% - 3.6rem);
`;

export const ArrowIcon = styled(ArrowYellowIcon)`
  width: 1.7rem;
  height: 2.2rem;
`;

interface StateProps {
  profileConnect: UserProfileState;
  profileImagesConnect: ImageState;
}

interface EditBioProps extends RouteComponentProps, StateProps {}

const EditBio: React.FC<EditBioProps> = ({
  profileConnect,
  profileImagesConnect
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onClose = () => history.goBack();

  const openSignupStep = (view: SignupSteps, locationState = {}) => () => {
    history.push(ROUTES.SIGN_UP, {
      viewToLoad: view,
      isEditing: true,
      ...locationState
    });
  };

  const openOnboardingStep = (step: number) => () => {
    history.push(ROUTES.ONBOARDING, { currentStep: step });
  };

  return (
    <ClanDesktopLayout>
      <IonPage>
        <ClanIonContent>
          <SmoothRays />

          <StyledHeader mode="ios">
            <IonIcon
              className="p-4 mr-4 h-6 w-6 cursor-pointer"
              src="/assets/arrow_back_black.svg"
              onClick={onClose}
            />
            <h3 className="my-1 self-center">{t("edit_bio.title")}</h3>
          </StyledHeader>

          <StyledCategoryHeader title={t("edit_bio.title_caps")} />
          <SettingsButtonsWrapper>
            <SettingsButton
              title={t("edit_bio.image_btn")}
              onClick={openSignupStep(SignupSteps.PROFILE_IMAGE, {
                profileImagesResult: {
                  url: profileImagesConnect.profileImage
                }
              })}
              indicator={<ArrowIcon />}
            />

            <SettingsButton
              title={t("edit_bio.name_btn")}
              onClick={openSignupStep(SignupSteps.NAME, {
                profileDataResult: profileConnect
              })}
              indicator={<ArrowIcon />}
            />

            <SettingsButton
              title={t("edit_bio.birthday_btn")}
              onClick={openSignupStep(SignupSteps.BIRTHDAY, {
                profileDataResult: profileConnect
              })}
              indicator={<ArrowIcon />}
            />

            {profileConnect.isLocked === false && (
              <>
                <SettingsButton
                  title={t("edit_bio.nationality_btn")}
                  onClick={openOnboardingStep(2)}
                  indicator={<ArrowIcon />}
                />

                <SettingsButton
                  title={t("edit_bio.languages_btn")}
                  onClick={openOnboardingStep(3)}
                  indicator={<ArrowIcon />}
                />
              </>
            )}
          </SettingsButtonsWrapper>
        </ClanIonContent>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<RouteComponentProps, StateProps>({
  mapStateToProps: (state) => ({
    profileConnect: state.userProfile,
    profileImagesConnect: state.userProfileImage
  }),
  component: EditBio
});
