import React from "react";
import { useLocation } from "react-router";
import styled, { useTheme } from "styled-components";

import { connect } from "../data/connect";
import { OauthState } from "../data/oauth/oauth.state";
import * as ROUTES from "../routes";
import { mobileOnlyStyles } from "../util/ResponsiveUtils";
import BottomDesktopNav from "./DesktopNav";

const dMax = Number(process.env.REACT_APP_CONFIG_DESKTOP_MAX_WIDTH) || 1440;
const dMin = Number(process.env.REACT_APP_CONFIG_DESKTOP_BREAKPOINT) || 800;

const DesktopGrid = styled.div`
  display: grid;
  grid-template-columns:
    minmax(${dMin / 4}px, ${dMax / 4}px) minmax(${dMin / 2}px, ${dMax / 2}px)
    minmax(${dMin / 4}px, ${dMax / 4}px);
  height: 100%;

  ${mobileOnlyStyles({
    width: "100%",
    display: "block"
  })}
`;

const SideColumn = styled.div<{ color?: string }>`
  width: 100%;
  background: ${({ color }) => color || "#fafafa"};

  ${mobileOnlyStyles({
    display: "none"
  })}
`;

const ContentWrapper = styled.div`
  height: 100%;
  flex: 1;
  position: relative;
`;

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;

interface StateProps {
  oauthState: OauthState;
}

interface OwnProps {
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
}

interface ClanDesktopLayoutProps extends OwnProps, StateProps {}

const ClanDesktopLayout: React.FC<ClanDesktopLayoutProps> = ({
  oauthState,
  leftComponent = oauthState.hasToken ? <BottomDesktopNav /> : null,
  rightComponent,
  children
}) => {
  const location = useLocation();
  const showYellowSides = location.pathname === ROUTES.START;
  const theme = useTheme();

  return (
    <DesktopWrapper>
      <DesktopGrid>
        <SideColumn color={showYellowSides ? theme.primary : "#fafafa"}>
          {leftComponent}
        </SideColumn>
        <ContentWrapper>{children}</ContentWrapper>
        <SideColumn color={showYellowSides ? theme.primary : "#fafafa"}>
          {rightComponent}
        </SideColumn>
      </DesktopGrid>
    </DesktopWrapper>
  );
};

export default connect<OwnProps, StateProps>({
  mapStateToProps: (state) => ({
    oauthState: state.token
  }),
  component: ClanDesktopLayout
});
