import React from "react";
import styled from "styled-components";

import { ReactComponent as DefaultAvatarIcon } from "../icons/default-avatar.svg";

const StyledAvatar = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  display: block;
  border-radius: 50%;
`;

const PlaceholderAvatar = styled(DefaultAvatarIcon)`
  width: 40px;
  height: 40px;
`;

interface AvatarProps {
  url?: string;
}

const Avatar: React.FC<AvatarProps> = ({ url }) => {
  return url ? <StyledAvatar src={url} alt="Avatar" /> : <PlaceholderAvatar />;
};

export default Avatar;
