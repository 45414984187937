import { IonPage, IonRefresher, IonRefresherContent } from "@ionic/react";
import { useAsync } from "@react-hook/async";
import { isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import ClanDesktopLayout from "../components/ClanDesktopLayout";
import ClanLoader from "../components/ClanLoader";
import ClanSelectPersonModal from "../components/ClanSelectPersonModal";
import FooterButtons, {
  PrimaryFooterButton
} from "../components/FooterButtons";
import Header from "../components/Headers/Header";
import MessageListItem from "../components/ListItem/MessageListItem";
import { Paragraph, Section } from "../components/Section";
import {
  setLatestChatTimestamps,
  updateLatestChatTimestamps
} from "../data/app/app.actions";
import { LatestTimestampForChatId } from "../data/app/app.state";
import { connect } from "../data/connect";
import { fetchDirectMessages } from "../util/api/DirectMessageService";
import {
  isReadyStateCancelOrFail,
  isReadyStateLoading,
  isReadyStatePending
} from "../util/AsyncEffectFilter";
import { ClanPostListResult } from "../util/models/ClanPostModels";
import { ClanIonContent } from "./DiscussionFeed/PublicFeeds";

interface DispatchProps {
  setLatestChatTimestampsConnect: typeof setLatestChatTimestamps;
  updateLatestChatTimestampsConnect: typeof updateLatestChatTimestamps;
}

interface StateProps {
  chatHubLatestTimestampsConnect: LatestTimestampForChatId[] | undefined;
}

interface MessgaesHubProps extends DispatchProps, StateProps {}
const MessagesHub: React.FC<MessgaesHubProps> = ({
  setLatestChatTimestampsConnect,
  chatHubLatestTimestampsConnect,
  updateLatestChatTimestampsConnect
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [messages, setMessages] = useState<ClanPostListResult[]>([]);
  const [showDirectMessageModal, setShowDirectMessageModal] = useState<boolean>(
    false
  );

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    loadChats();
    !chatHubLatestTimestampsConnect && updateLatestChatTimestampsConnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const messagesToIdTimestampPair = (): LatestTimestampForChatId[] => {
      return messages.map((message) => {
        return { timestamp: message.createdAt, id: message.id };
      });
    };

    return () => {
      messages.length !== 0 &&
        setLatestChatTimestampsConnect(messagesToIdTimestampPair());
    };
  }, [messages, setLatestChatTimestampsConnect]);

  const [loadChatsStatus, loadChats] = useAsync(async () => {
    const response = await fetchDirectMessages();
    if (!response || isReadyStateCancelOrFail(loadChatsStatus)) return;
    await setMessages(response);
    // TODO errors are swallowed up
  });

  async function doRefresh(event: CustomEvent) {
    await loadChats();
    event.detail.complete();
  }

  const hasPendingMessages = (messageId: string, createdAt: string) => {
    if (!chatHubLatestTimestampsConnect) return false;

    const matchingCachedLatest = chatHubLatestTimestampsConnect.find(
      (cachedLatest) => cachedLatest.id === messageId
    );

    return matchingCachedLatest
      ? isAfter(new Date(createdAt), new Date(matchingCachedLatest?.timestamp))
      : true;
  };

  const openNewMessageModal = () => setShowDirectMessageModal(true);

  return (
    <>
      {showDirectMessageModal && (
        <ClanSelectPersonModal
          onClose={() => setShowDirectMessageModal(false)}
          isVisible={showDirectMessageModal}
        />
      )}

      <ClanDesktopLayout>
        <IonPage>
          <Header
            title={t("messages_hub.title")}
            onBack={() => history.goBack()}
          />

          <ClanLoader
            message={t("messages_hub.loading")}
            showLoading={isReadyStateLoading(loadChatsStatus)}
          />

          <ClanIonContent>
            <IonRefresher
              slot="fixed"
              onIonRefresh={doRefresh}
              pullFactor={0.5}
              pullMin={100}
              pullMax={200}
            >
              <IonRefresherContent />
            </IonRefresher>
            <Section>
              {isReadyStatePending(loadChatsStatus) &&
                (messages.length > 0 ? (
                  messages.map(({ id, html, mood, createdAt, userProfile }) => (
                    <MessageListItem
                      key={id}
                      unread={hasPendingMessages(id, createdAt)}
                      createdAt={createdAt}
                      profile={userProfile}
                      mood={mood}
                      content={html}
                    />
                  ))
                ) : (
                  <Paragraph>{t("messages_hub.no_messages")}</Paragraph>
                ))}
            </Section>
          </ClanIonContent>
          <FooterButtons>
            <PrimaryFooterButton onClick={openNewMessageModal}>
              {t("messages_hub.new_message")}
            </PrimaryFooterButton>
          </FooterButtons>
        </IonPage>
      </ClanDesktopLayout>
    </>
  );
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    chatHubLatestTimestampsConnect: state.appState.chatHubLatestTimestamps
  }),
  mapDispatchToProps: {
    setLatestChatTimestampsConnect: setLatestChatTimestamps,
    updateLatestChatTimestampsConnect: updateLatestChatTimestamps
  },
  component: MessagesHub
});
