import React from "react";
import styled from "styled-components";
import { desktopOnlyStyles } from "../util/ResponsiveUtils";
import ClanToolbar from "./ClanToolbar";

export const SmoothRays = styled.img`
  width: 100%;

  ${desktopOnlyStyles({
    display: "none"
  })}
`;

const ClanToolbarWithRays = ({ ...props }) => (
  <>
    <SmoothRays />
    <ClanToolbar {...props} />
  </>
);

export default ClanToolbarWithRays;
