import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { IonPage, IonFooter, IonSegment, IonSegmentButton } from "@ionic/react";
import ClanHeaderRays from "../components/ClanHeaderRays";
import { STATS_DASHBOARD_ALERTS } from "../routes";
import { useTranslation } from "react-i18next";
import ClanDesktopLayout from "../components/ClanDesktopLayout";
import { ClanIonContent } from "./DiscussionFeed/PublicFeeds";

const Report: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <ClanDesktopLayout>
      <IonPage>
        <ClanIonContent>
          <ClanHeaderRays />
          {t("report.flag")}
        </ClanIonContent>
        <IonFooter>
          <IonSegment className="bg-clanYellow-100">
            <IonSegmentButton
              className="font-extrabold font-gilroy text-black"
              onClick={() => {
                history.push(STATS_DASHBOARD_ALERTS);
              }}
            >
              {t("general.back")}
            </IonSegmentButton>
          </IonSegment>
        </IonFooter>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default Report;
