import React from "react";
import { IonButton, IonLabel, IonIcon } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import { useTranslation } from "react-i18next";

type LabelsButtonProps = {
  isSet: boolean;
  onClick: () => void;
  onClear: () => void;
};

const LabelsButton: React.FC<LabelsButtonProps> = ({
  isSet,
  onClick,
  onClear
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center mx-1">
      <IonButton
        style={{
          "--padding-start": "0.5rem",
          "--padding-end": "0.5rem"
        }}
        fill="clear"
        color="dark"
        mode="ios"
        onClick={onClick}
        className="p-0 m-0 cursor-pointer"
      >
        <IonLabel
          className={`m-0 p-0 text-clanP ${
            isSet ? "text-clanGreen-100" : "text-clanGray-200"
          }`}
        >
          {t("planner.label")}
        </IonLabel>
        <IonIcon
          className={`h-8 p-0 m-0 ${
            isSet ? "text-clanGreen-100" : "text-clanGray-200"
          }`}
          slot="start"
          icon="/assets/planner/label.svg"
        />
      </IonButton>

      {isSet && (
        <IonIcon
          icon={closeCircle}
          slot="end"
          className="p-0 h-8 text-clanGray-200 cursor-pointer"
          onClick={onClear}
        />
      )}
    </div>
  );
};

export default LabelsButton;
