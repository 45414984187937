import { IonItem } from "@ionic/react";
import React, { useState } from "react";
import styled from "styled-components";

import CategoryHeader from "../../components/CategoryHeader";
import SocialIcon from "../../components/ClanSocialIcon";
import TabFilter from "../../components/TabFilter";
import ClanSimpleInterests, {
  InterestType
} from "../../components/PeopleOverview/ClanSimpleInterests";
import { UserSocialLinksType } from "../../util/SocialLinks";
import { useTranslation } from "react-i18next";
import { OauthState } from "../../data/oauth/oauth.state";
import { OauthData } from "../../data/oauth/oauthDataService";
import { connect } from "../../data/connect";
import { FeatureService } from "../../util/api/FeatureService";

const AboutWrapper = styled.div`
  padding-bottom: 2rem;
`;

const AboutSection = styled.div`
  margin-bottom: 1.8rem;
`;

const SectionContent = styled.div`
  width: 100%;
  padding: 0 2.5rem;
`;

export enum ProfileAboutSection {
  All = "ALL",
  Interests = "INTERESTS",
  BucketList = "BUCKET LIST",
  Contacts = "CONTACTS"
}

interface PublicProfileAboutProps {
  socialContacts: Array<UserSocialLinksType>;
  bucketList: Array<string>;
  interests: Array<string>;
}

interface StateProps {
  oauthState: OauthState | OauthData;
}

const PublicProfileAbout: React.FC<PublicProfileAboutProps & StateProps> = ({
  socialContacts,
  bucketList,
  interests,
  oauthState
}) => {
  const socialLinksDisabled = FeatureService.PROFILE_DISABLE_SOCIAL_LINKS(
    oauthState
  );

  const [activeTab, setActiveTab] = useState<ProfileAboutSection>(
    ProfileAboutSection.All
  );

  const onTabChange = (newTab: any) =>
    setActiveTab(newTab as ProfileAboutSection);

  const isVisible = (tab: ProfileAboutSection) =>
    [ProfileAboutSection.All, tab].includes(activeTab);

  const { t } = useTranslation();

  const ProfileAboutSectionValues = Object.keys(ProfileAboutSection)
    .map((key) => [
      key,
      ProfileAboutSection[key as keyof typeof ProfileAboutSection]
    ])
    .filter(
      (values) =>
        values[1] !== ProfileAboutSection.Contacts || !socialLinksDisabled
    )
    .reduce((map, values) => ({ ...map, [values[0]]: values[1] }), {});

  return (
    <AboutWrapper>
      <TabFilter
        active={activeTab}
        options={ProfileAboutSectionValues}
        onChange={onTabChange}
      />

      {isVisible(ProfileAboutSection.Interests) && (
        <AboutSection>
          <CategoryHeader
            title={t("profile_about.interests")}
            tooltipText={t("profile_about.interests_desc")}
          />
          <SectionContent>
            <ClanSimpleInterests
              wrapped
              highlightMatching
              type={InterestType.Interest}
              interests={interests}
            />
          </SectionContent>
        </AboutSection>
      )}

      {isVisible(ProfileAboutSection.BucketList) && (
        <AboutSection>
          <CategoryHeader
            title={t("profile_about.bucket_list")}
            tooltipText={t("profile_about.bucket_list_desc")}
          />
          <SectionContent>
            <ClanSimpleInterests
              highlightMatching
              type={InterestType.BucketList}
              interests={bucketList}
            />
          </SectionContent>
        </AboutSection>
      )}

      {!socialLinksDisabled && isVisible(ProfileAboutSection.Contacts) && (
        <AboutSection>
          <CategoryHeader
            title={t("profile_about.contacts")}
            tooltipText={t("profile_about.contacts_desc")}
          />
          <SectionContent>
            {socialContacts.length > 0 ? (
              socialContacts?.map((socialContact: UserSocialLinksType) => (
                <IonItem
                  key={`user_social_${socialContact.name}`}
                  button={false}
                  color="tertiary"
                  href={socialContact.url}
                  target="_blank"
                  className="clan-list-item mb-2 p-0"
                >
                  <SocialIcon
                    iconName={socialContact.name}
                    className="mr-0 h-6 w-6"
                  />
                  <h5 className="text-center text-black flex-1 pl-3 pr-6">
                    {socialContact.name}: {socialContact.value}
                  </h5>
                </IonItem>
              ))
            ) : (
              <p>{t("profile_about.no_contacts")}</p>
            )}
          </SectionContent>
        </AboutSection>
      )}
    </AboutWrapper>
  );
};

export default connect<PublicProfileAboutProps, {}, StateProps>({
  mapStateToProps: (state) => ({
    oauthState: state.token
  }),
  component: PublicProfileAbout
});
