import { FirebaseAuthentication } from "@robingenz/capacitor-firebase-authentication";
import { getFirebaseAuthentication } from "./FirebaseAuthConfiguration";
import { FirebaseUser } from "../../../data/app/app.state";
import { isNativePlatform } from "../../AppUtils";

const getCapacitorIdToken = async (): Promise<FirebaseUser | null> => {
  const user = await FirebaseAuthentication.getCurrentUser();
  if (!user?.user) {
    return null;
  }
  const token = await FirebaseAuthentication.getIdToken();
  if (!token.token) {
    return null;
  }
  return {
    email: user.user.email ? user.user.email : undefined,
    idToken: token.token
  };
};

const getFirebaseAuthenticationToken = async (): Promise<FirebaseUser | null> => {
  const auth = getFirebaseAuthentication();
  if (!auth?.currentUser) return null;
  const token = await auth.currentUser.getIdToken();
  if (!token) return null;
  return {
    email: auth.currentUser.email ? auth.currentUser.email : undefined,
    idToken: token
  };
};

export const getFirebaseIdToken = async (): Promise<FirebaseUser | null> => {
  const capacitorResult = await getCapacitorIdToken();
  if (capacitorResult || isNativePlatform) return capacitorResult;
  return getFirebaseAuthenticationToken();
};
