import { IonAvatar } from "@ionic/react";
import React from "react";

import { ReactComponent as DefaultAvatarIcon } from "../icons/default-avatar.svg";

interface ClanProfileAvatarProps {
  src?: string;
  alt?: string;
  onClick?: () => void;
  className?: string;
}

const ClanProfileAvatar: React.FC<ClanProfileAvatarProps> = ({
  src,
  alt = "avatar",
  onClick,
  className = ""
}) => (
  <IonAvatar className={`tab-button flex-shrink-0 cursor-pointer ${className}`}>
    {src ? (
      <img
        src={src}
        alt={alt}
        onClick={(e) => {
          if (onClick) {
            e.stopPropagation();
            onClick();
          }
        }}
      />
    ) : (
      <DefaultAvatarIcon
        onClick={(e) => {
          if (onClick) {
            e.stopPropagation();
            onClick();
          }
        }}
      />
    )}
  </IonAvatar>
);

export default ClanProfileAvatar;
