import { IonButton, IonFooter, IonTextarea } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as MessageInputIcon } from "../icons/message_input.svg";
import ClanCameraAccess from "../util/ClanCameraAccess";
import { getPostById } from "../util/ClanPostAPIService";
import {
  ClanEditPostEditDetails,
  ClanPostResultDetailsV3
} from "../util/models/ClanPostModels";
import ImageButton from "./Planner/ImageButton";
import MoodButton from "./Planner/MoodButton";
import MoodModal from "./Planner/MoodModal";

const StyledContent = styled.div`
  border-radius: 10px 10px 0 0;
  background-color: white;
  padding: 5px 0;
`;

const MessageInputWrapper = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${({ height }) =>
    height ? (height <= 200 ? height.toString() : "200") : "auto"}px;
  margin-left: 12px;
  margin-right: 4px;
`;

type ClanFooterInputProps = {
  placeholder: string;
  editMessageId?: string | undefined;
  setEditMessageId?: (id: string | undefined) => void | undefined;
  onSubmit: (post: ClanEditPostEditDetails) => void;
  setOpenPostBar: (open: boolean) => void;
  openPostBar: boolean;
  directShareMode?: boolean;
  canSubmit?: boolean;
  onSubmitFallback?: () => void;
  rows?: number;
  disabled?: boolean;
};

const ClanFooterInput: React.FC<ClanFooterInputProps> = ({
  placeholder,
  editMessageId,
  setEditMessageId,
  onSubmit,
  setOpenPostBar,
  disabled = false,
  openPostBar,
  directShareMode = false,
  canSubmit = true,
  onSubmitFallback,
  rows = 3
}) => {
  const [editMessage, setEditMessage] = useState<ClanPostResultDetailsV3>();
  const [text, setText] = useState<string | undefined>();
  const [mood, setMood] = useState<string | undefined>();
  const [image, setImage] = useState<string>();
  const [moodModalOpen, setMoodModalOpen] = useState<boolean>(false);
  const textRef = useRef<HTMLIonTextareaElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    editMessage?.text ? setText(editMessage?.text) : setText(undefined);
    editMessage?.mood ? setMood(editMessage?.mood) : setMood(undefined);
    // Setting empty string in case image already exists, edit and delete is currently not possible
    editMessage?.image?.thumbnail?.url
      ? setImage(editMessage?.image?.thumbnail?.url)
      : setImage(undefined);
    editMessage ? setOpenPostBar(true) : setOpenPostBar(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMessage]);

  useEffect(() => {
    editMessageId
      ? getPostById(editMessageId).then((message: ClanPostResultDetailsV3) =>
          setEditMessage(message)
        )
      : setEditMessage(undefined);
  }, [editMessageId]);

  function submit() {
    if (!canSubmit) {
      onSubmitFallback && onSubmitFallback();
      return;
    }

    onSubmit({
      id: editMessage?.id,
      text,
      mood,
      image
    });

    setText(undefined);
    setMood(undefined);
    setImage(undefined);
    setOpenPostBar(false);
    setEditMessageId && setEditMessageId(undefined);

    textRef.current
      ?.querySelector(".native-textarea")
      ?.setAttribute("style", "height: 0");
  }

  function onInputKeyDown(e: React.KeyboardEvent<HTMLIonTextareaElement>) {
    if (e.shiftKey || (e.keyCode !== 13 && e.key !== "Enter")) return;

    e.preventDefault();
    if (
      textRef.current &&
      textRef.current.value &&
      textRef.current.value.trim().length > 0
    ) {
      submit();
    }
  }

  useEffect(() => {
    if (text && text?.length !== 0) textRef.current?.setFocus();
    const textarea = textRef.current?.querySelector(".native-textarea");

    if (text?.length === 0) {
      textarea && textarea.setAttribute("style", "height:initial");
      inputWrapperRef.current?.setAttribute("style", "height:initial");
    }
    textarea &&
      textarea.setAttribute(
        "style",
        `height:${textarea.scrollHeight <= 200 ? textarea.scrollHeight : 200}px`
      );
  }, [text]);

  return (
    <IonFooter
      onClick={() => {
        setOpenPostBar(true);
      }}
    >
      <div className="bg-clanYellow-100 pt-3">
        <>
          <MoodModal
            isOpen={moodModalOpen}
            onDismiss={() => setMoodModalOpen(false)}
            onMoodSelect={(newMood) => {
              setMood(newMood);
              setMoodModalOpen(false);
            }}
          />
          <div>
            <StyledContent>
              <MessageInputWrapper
                ref={inputWrapperRef}
                height={
                  textRef.current?.querySelector(".native-textarea")
                    ?.scrollHeight
                }
              >
                <IonTextarea
                  ref={textRef}
                  role="textbox"
                  maxlength={2000}
                  rows={rows}
                  placeholder={placeholder}
                  className="leading-6 m-4"
                  value={text}
                  autocapitalize="sentences"
                  onIonChange={(e: any) => setText(e.detail.value || "")}
                  onKeyDown={onInputKeyDown}
                />
                <IonButton
                  style={{
                    "--padding-start": "1rem",
                    "--padding-end": "1rem"
                  }}
                  onClick={submit}
                  disabled={(!text?.trim() && !image && !mood) || disabled}
                  className={`h-clanBtn text-clanH2 align-middle ${
                    directShareMode
                      ? "bg-clanYellow-100 text-black rounded-full"
                      : ""
                  }`}
                  color="tertiary"
                  fill="clear"
                >
                  {directShareMode ? (
                    <p className="tracking-normal">{t("general.share")}</p>
                  ) : (
                    <MessageInputIcon />
                  )}
                </IonButton>
              </MessageInputWrapper>
            </StyledContent>

            {openPostBar && (
              <div
                id="task_add_bar"
                className="flex overflow-x-scroll border border-solid border-r-0 border-l-0 border-gray-200 items-center bg-white"
              >
                <MoodButton
                  mood={mood}
                  onClick={() => {
                    setMoodModalOpen(true);
                  }}
                  onClear={() => setMood(undefined)}
                />
                <ImageButton
                  image={image}
                  onClick={() => {
                    ClanCameraAccess.TakePhoto().then((result) =>
                      setImage(result.image)
                    );
                  }}
                  onClear={() => setImage(undefined)}
                />
              </div>
            )}
          </div>
        </>
      </div>
    </IonFooter>
  );
};

export default ClanFooterInput;
