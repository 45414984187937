import { PaginatedStreamDataIterator } from "../../ResultDataIterator";
import { OrganisationListResult } from "../types";
import { ClanOauthResponse } from "../../models/ClanOauthResponse";
import { getOauthData, OauthData } from "../../../data/oauth/oauthDataService";
import { doRequestAndReturnResult } from "../../../apiService";
import { setOauth } from "../../../data/oauth/oauth.actions";
import { dispatch } from "../../../data/AppContext";
import { getCachedMapHolder } from "../../CachedData";
import { AuthorizedApi } from "../../ApiService";
import { useValueAsyncEffect } from "../../AsyncHooks";

const BASE_PATH = "/v2/organisations/switch";

export const getOrganisationDataIterator = (): PaginatedStreamDataIterator<OrganisationListResult> => {
  return new PaginatedStreamDataIterator<OrganisationListResult>(BASE_PATH);
};

export const switchOrganisation = async (
  token: OauthData,
  organisationId: number
) => {
  const refreshResponse = await AuthorizedApi.put<ClanOauthResponse>({
    url: `${BASE_PATH}/${organisationId}`,
    data: token.refreshToken,
    headers: { "content-type": "text/plain" }
  });
  await setOauth(refreshResponse)(dispatch);
};

const ORGANISATION_COUNT = getCachedMapHolder<number>(
  300000, // 5 minutes
  async () => {
    try {
      const result = await doRequestAndReturnResult<OrganisationListResult[]>(
        "get",
        BASE_PATH
      );
      return result.length;
    } catch (e) {
      console.error("Error while fetching list of organisations", e);
      return 0;
    }
  }
);

export const useSwitchOrganisationResult = () =>
  useValueAsyncEffect<number>(
    0,
    async () => {
      const token = await getOauthData();
      if (!token.hasToken) return undefined;
      return ORGANISATION_COUNT("organisation_switch_count");
    },
    []
  );
