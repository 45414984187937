import React from "react";
import styled from "styled-components";
import ChoiceLine from "./ChoiceLine";
import { ChoiceId, ChoiceValue } from "../types";

const LineList = styled.div`
  margin: 0 auto 0.7rem;
  width: 90%;
`;

interface ChoiceTemplateProps<ID extends ChoiceId> {
  values: ChoiceValue<ID>[];
  selected: ID[];
  onClick: (id: ID) => void;
}

export default function <ID extends ChoiceId>({
  values,
  selected,
  onClick
}: ChoiceTemplateProps<ID>) {
  return (
    <LineList>
      {values.map((value) => (
        <ChoiceLine
          label={value.label}
          selected={selected.includes(value.id)}
          onClick={() => onClick(value.id)}
          key={value.id}
        />
      ))}
    </LineList>
  );
}
