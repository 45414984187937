export async function waitUntilElementExists(
  selector: string,
  timeout = 1000
): Promise<HTMLElement> {
  return new Promise((resolve, reject) => {
    const timeoutFunc = setTimeout(() => {
      window.clearInterval(intervalFunc);
      reject(new Error(`Did not find "${selector}" element in ${timeout}ms.`));
    }, timeout);

    const intervalFunc = setInterval(() => {
      const elementToFind = document.querySelector(selector) as HTMLElement;

      if (elementToFind && isVisible(elementToFind)) {
        window.clearInterval(intervalFunc);
        window.clearTimeout(timeoutFunc);
        resolve(elementToFind);
      }
    }, 100);
  });
}

function isVisible(element: HTMLElement) {
  return !!(
    element.offsetWidth ||
    element.offsetHeight ||
    element.getClientRects().length
  );
}
