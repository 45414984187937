import { PaginatedStreamDataIterator } from "./ResultDataIterator";
import { SharedUserTaskResult } from "./models/PlannerUserTaskModels";
import { useEffect, useState } from "react";
import { usePrevious } from "./CustomHooks";
import { ClanPostListResult } from "./models/ClanPostModels";
import { FeedTypes } from "./Feeds";
import {
  FilterGroupType,
  joinFilterGroupTypeId,
  resolveConstantGroupFilter
} from "./Filters";

function getIterator<T>(
  filters: FilterGroupType[],
  feedType: FeedTypes
): PaginatedStreamDataIterator<T> {
  const filter = resolveConstantGroupFilter(filters);
  const result = new PaginatedStreamDataIterator<T>(
    filter === undefined
      ? `/feed/v3/view/${feedType}`
      : `/feed/v3/view/filter/${feedType}/${filter.filter}`
  );
  const getGroupIdList = joinFilterGroupTypeId(filters);
  if (getGroupIdList !== undefined && getGroupIdList.length > 0) {
    result.setQueryArgumentNoFetch("group_id", getGroupIdList);
  }
  return result;
}

function getPersonalIterator(): PaginatedStreamDataIterator<any> {
  return new PaginatedStreamDataIterator(`/feed/v3/view/${FeedTypes.PERSONAL}`);
}

function getTaskFeedIterator(): PaginatedStreamDataIterator<SharedUserTaskResult> {
  return new PaginatedStreamDataIterator<SharedUserTaskResult>(
    "/usertask/v2/todo/shared"
  );
}

export function useFeedsFilterPaginator(
  feedType: FeedTypes,
  filters: FilterGroupType[]
) {
  const [feedFilterPaginator, setFeedFilterPaginator] = useState<
    PaginatedStreamDataIterator<ClanPostListResult | SharedUserTaskResult>
  >();
  const previousFeedType = usePrevious(feedType);

  useEffect(() => {
    switch (feedType) {
      case FeedTypes.ANNOUNCEMENT_FEED: {
        const feedAPI = getIterator<ClanPostListResult>([], feedType);
        setFeedFilterPaginator(feedAPI);
        break;
      }
      case FeedTypes.SHARED_REFLECTIONS:
      case FeedTypes.QUESTION:
      case FeedTypes.DISCUSSION: {
        if (previousFeedType !== FeedTypes.ANNOUNCEMENT_FEED) {
          const feedAPI = getIterator<ClanPostListResult>(filters, feedType);
          setFeedFilterPaginator(feedAPI);
        }

        break;
      }
      case FeedTypes.TASK: {
        if (previousFeedType !== FeedTypes.ANNOUNCEMENT_FEED) {
          setFeedFilterPaginator(getTaskFeedIterator());
        }
        break;
      }
      case FeedTypes.PERSONAL:
        setFeedFilterPaginator(getPersonalIterator());
        break;
      case FeedTypes.PUBLIC:
        setFeedFilterPaginator(undefined);
        break;
      default:
        setFeedFilterPaginator(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, feedType]);

  return feedFilterPaginator;
}
