import { IonCard, IonCardContent, IonItem, IonText } from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../routes";
import { SharedUserTaskResult } from "../../util/models/PlannerUserTaskModels";
import PlannerItem from "../Planner/PlannerItem";
import ClanPostSharedWithView from "./ClanPostSharedWithView";
import ClanSharingInfoInline from "../ClanSharingInfoInline";
import { getTaskSharedWithByTaskID } from "../../util/ClanTaskAPIService";
import { dateLocaleFormatter } from "../../util/DateUtils";
import { useTranslation } from "react-i18next";
import { useHtmlContentResolver } from "../../util/Html";

interface ClanTaskPostProps {
  task: SharedUserTaskResult;
  commentsPage?: string;
}

const ClanTaskPost: React.FC<ClanTaskPostProps> = ({ task }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [sharedTaskId, setSharedTaskId] = useState<string | undefined>();
  // const goToCommentsPage = async (target?: HTMLElement) => {
  //   if (target?.tagName?.toUpperCase() === "A") return;
  //   if (
  //       target?.tagName?.toLowerCase() === "span" &&
  //       target?.classList?.contains("shared-with")
  //   )
  //     return;
  //   if (commentsPage && task.id) {
  //     history.push(commentsPage.replace(":task_id", task.id), { task });
  //   }
  // };

  const htmlResult = useHtmlContentResolver(task?.noteHtml);

  return (
    <>
      <ClanPostSharedWithView
        viewReactions={getTaskSharedWithByTaskID}
        targetId={sharedTaskId}
        onHide={() => setSharedTaskId(undefined)}
        title={t("clantaskpost.visible_to_task")}
      />
      <div>
        <IonCard
          data-task-id={task.id}
          className="shadow-none overflow-visible"
        >
          <IonCardContent className="p-0">
            <IonItem>
              <PlannerItem
                item={task}
                className="w-full pb-2"
                onAdd={() =>
                  history.push(ROUTES.PLANNER_TASK, {
                    taskToBeGrabbed: task,
                    dateChosen: task.targetDate
                      ? new Date(task.targetDate)
                      : undefined
                  })
                }
                addDisabled={
                  task.sharing?.mySharing || !!task.sharing?.acceptedDate
                }
                sharedTaskOwner={task.owner}
              />
            </IonItem>
            {htmlResult && (
              <IonItem className="py-3">
                {" "}
                <IonText className="text-justify w-full">
                  <section
                    className="text-clanP text-left whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{
                      __html: htmlResult
                    }}
                  />
                </IonText>
              </IonItem>
            )}
          </IonCardContent>
          <IonItem>
            <h5 className="text-gray-500 m-0">
              <span>
                {t("clantaskpost.shared")} {task.owner?.name} |
              </span>
              {task.sharing && (
                <span>
                  {dateLocaleFormatter(
                    task.sharing.sharedDate,
                    "HH:mm | MMM d"
                  )}
                </span>
              )}
              <ClanSharingInfoInline
                onInfoClicked={() => setSharedTaskId(task.id)}
                groupsAmount={task.sharing?.sharedGroupsAmount}
                profilesAmount={task.sharing?.sharedProfilesAmount}
              />
            </h5>
          </IonItem>
        </IonCard>
      </div>
    </>
  );
};

export default ClanTaskPost;
