import { IonContent } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";

import FooterButtons, { FooterButton } from "../../components/FooterButtons";
import { connect } from "../../data/connect";
import { ReactComponent as LlamaIcon } from "../../icons/llama.svg";
import * as ROUTES from "../../routes";

const SuccessPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 90%;
  margin: 0 auto;
`;

const StyledImg = styled(LlamaIcon)`
  max-width: 250px;
  width: 100%;
`;

const Title = styled.div`
  font-family: Gilroy;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin: 20px 0 10px;
`;

const Description = styled.div`
  font-family: Gilroy;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
`;

const StyledFooterButton = styled(FooterButton)`
  background: ${({ theme }) => theme.primary};
`;

interface OwnProps {
  signupData?: object;
}

interface StartStep4Props extends OwnProps, RouteComponentProps {}

const Success: React.FC<StartStep4Props> = ({ signupData, history }) => {
  const { t } = useTranslation();

  return (
    <>
      <IonContent>
        <SuccessPage>
          <SuccessWrapper>
            <StyledImg />
            <Title>{t("start.success.title")}</Title>
            <Description>{t("start.success.description")}</Description>
          </SuccessWrapper>
        </SuccessPage>
      </IonContent>
      <FooterButtons>
        <StyledFooterButton
          onClick={() => history.push(ROUTES.SIGN_UP, signupData)}
        >
          {t("general.next")}
        </StyledFooterButton>
      </FooterButtons>
    </>
  );
};

export default connect<OwnProps, {}, {}>({
  component: withRouter(Success)
});
