import React from "react";
import styled from "styled-components";

const StyledSettingsButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.primary};
  box-shadow: 0px 7px 10px rgba(212, 189, 189, 0.25);
  border-radius: 10px;
  padding: 0.7rem;
  width: 100%;
  margin-bottom: 0.7rem;
  cursor: pointer;
`;

const StyledSettingsLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.primary};
  box-shadow: 0px 7px 10px rgba(212, 189, 189, 0.25);
  border-radius: 10px;
  padding: 0.7rem;
  width: 100%;
  margin-bottom: 0.7rem;
  text-decoration: none;
  color: black;
`;

const ButtonTitle = styled.span`
  flex: 1;
  text-align: left;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 2.2rem;
`;

const StyledIndicator = styled.span`
  width: 2.2rem;
  height: 2.2rem;
  background: ${({ theme }) => theme.primary};
  border-radius: 50%;
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 2.2rem;
  text-align: center;
`;

interface SettingsButtonProps {
  title: string;
  indicator?: any;
  onClick?: () => void;
  href?: string;
}

interface IndicatorProps {
  value: any;
}

const Indicator: React.FC<IndicatorProps> = ({ value }) => {
  if (["string", "number"].includes(typeof value)) {
    return <StyledIndicator>{value}</StyledIndicator>;
  }

  return value;
};

const SettingsButton: React.FC<SettingsButtonProps> = ({
  title,
  onClick,
  indicator,
  href
}) => {
  const indicatorExists = indicator !== undefined && indicator !== null;

  if (href) {
    return (
      <StyledSettingsLink href={href} target="_blank">
        <ButtonTitle>{title}</ButtonTitle>
        {indicatorExists && <Indicator value={indicator} />}
      </StyledSettingsLink>
    );
  }

  return (
    <StyledSettingsButton onClick={onClick}>
      <ButtonTitle>{title}</ButtonTitle>
      {indicatorExists && <Indicator value={indicator} />}
    </StyledSettingsButton>
  );
};

export default SettingsButton;
