import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import ClanErrorModal from "../ClanErrorModal";
import BottomNav from "../components/BottomNav";
import ClanLoader from "../components/ClanLoader";
import ExistingTasksModal from "../components/Planner/ExistingTasks";
import TaskPage from "../components/Planner/TaskPage";
import { connect } from "../data/connect";
import * as ROUTES from "../routes";
import { FeedTypes } from "../util/Feeds";
import Activity from "./Activity";
import Chatbot from "./Chatbot/Chatbot";
import Dashboard from "./Dashboard";
import DirectMessage from "./DiscussionFeed/DirectMessage";
import FeedPostComments from "./DiscussionFeed/FeedPostComments";
import FeedTaskComments from "./DiscussionFeed/FeedTaskComments";
import PublicFeeds from "./DiscussionFeed/PublicFeeds";
import GenericNotificationMessage from "./GenericNotificationMessage";
import GetStarted from "./GetStarted";
import GroupAddMembersView from "./Group/GroupAddMembersView";
import GroupListView from "./Group/GroupListView";
import GroupPreferences from "./Group/GroupPreferences";
import GroupSingleView from "./Group/GroupSingleView";
import MessagesHub from "./MessagesHub";
import Nudging from "./Nudging";
import Onboarding from "./Onboarding/Onboarding";
import People from "./People";
import EditBio from "./Personalize/EditBio";
import Personalize from "./Personalize/Personalize";
import PlannerDay from "./Planner/PlannerDay";
import Profile from "./Profile/Profile";
import PublicProfile from "./PublicProfile/PublicProfile";
import PublicProfileTrends from "./PublicProfile/PublicProfileTrends";
import QuickHelpPage from "./QuickHelp/QuickHelpPage";
import Reflections from "./Reflections/Reflections";
import Report from "./Report";
import OrganisationSwitch from "./Settings/OrganisationSwitch";
import StatsDashboard from "./StatsDashboard/StatsDashboard";
import Survey from "./Survey";

interface StateProps {
  isLoadingConnect: boolean;
}

type MainTabsProps = StateProps;
const TabRoot: React.FC<MainTabsProps> = ({ isLoadingConnect }) => {
  const location = useLocation();
  const { t } = useTranslation();

  if (location.pathname === ROUTES.HOME) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  const showBottomNav =
    ![
      ROUTES.ONBOARDING,
      ROUTES.PLANNER_EXISTING,
      ROUTES.CHATBOT,
      ROUTES.STATS_DASHBOARD,
      ROUTES.REPORT,
      ROUTES.WELLBEING_SURVEY_VIEW
    ].includes(location.pathname) &&
    !location.pathname.includes("directmessage") &&
    !location.pathname.includes("nudging") &&
    !location.pathname.includes("task");

  return (
    <>
      <ClanErrorModal />
      <ClanLoader
        message={t("general.loading")}
        showLoading={isLoadingConnect}
      />
      <Switch>
        <Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
        <Route exact path={ROUTES.GROUPS_VIEW} component={GroupListView} />
        <Route exact path={ROUTES.GROUP_VIEW} component={GroupSingleView} />
        <Route
          exact
          path={ROUTES.GROUP_PREFERENCES_VIEW}
          component={GroupPreferences}
        />
        <Route
          exact
          path={ROUTES.GROUP_ADD_MEMBERS_VIEW}
          component={GroupAddMembersView}
        />
        <Route exact path={ROUTES.GET_STARTED} component={GetStarted} />
        <Route exact path={ROUTES.REFLECTIONS} component={Reflections} />
        <Route exact path={ROUTES.QUICK_HELP_VIEW} component={QuickHelpPage} />
        <Route exact path={ROUTES.REPORT} render={Report} />
        <Route exact path={ROUTES.ACTIVITY} component={Activity} />
        <Route exact path={ROUTES.PERSONALIZE_VIEW} component={Personalize} />
        <Route exact path={ROUTES.EDIT_BIO_VIEW} component={EditBio} />
        <Route exact path={ROUTES.DIRECT_MESSAGE} component={DirectMessage} />
        <Route exact path={ROUTES.MESSAGES_HUB} component={MessagesHub} />
        <Route exact path={ROUTES.ONBOARDING} component={Onboarding} />
        <Route exact path={ROUTES.PUBLIC_PROFILE} component={PublicProfile} />
        <Route
          exact
          path={ROUTES.PROFILE_TRENDS}
          component={PublicProfileTrends}
        />
        <Route exact path={ROUTES.PROFILE} component={Profile} />
        <Route exact path={ROUTES.PUBLIC_FEEDS} component={PublicFeeds} />
        <Route
          exact
          path={ROUTES.DISCUSSION_COMMENTS}
          render={() => <FeedPostComments feedType={FeedTypes.DISCUSSION} />}
        />
        <Route
          exact
          path={ROUTES.QUESTION_COMMENTS}
          render={() => <FeedPostComments feedType={FeedTypes.QUESTION} />}
        />
        <Route
          exact
          path={ROUTES.PROFILE_FEED_COMMENTS}
          render={() => <FeedPostComments feedType={FeedTypes.PERSONAL} />}
        />
        <Route
          exact
          path={ROUTES.ANNOUNCEMENT_COMMENTS}
          render={() => (
            <FeedPostComments feedType={FeedTypes.ANNOUNCEMENT_FEED} />
          )}
        />
        <Route
          exact
          path={ROUTES.PUBLIC_PROFILE_FEED_COMMENTS}
          render={() => <FeedPostComments feedType={FeedTypes.PUBLIC} />}
        />
        <Route
          exact
          path={ROUTES.TASK_COMMENTS}
          render={() => <FeedTaskComments feedType={FeedTypes.TASK} />}
        />
        <Route
          exact
          path={ROUTES.SHARED_FEED_COMMENTS}
          render={() => (
            <FeedPostComments feedType={FeedTypes.SHARED_REFLECTIONS} />
          )}
        />
        <Route exact path={ROUTES.PEOPLE} component={People} />

        <Route exact path={ROUTES.PLANNER_DAY} component={PlannerDay} />
        {/* Edit task */}
        <Route exact path={ROUTES.PLANNER_TASK} component={TaskPage} />
        <Route
          exact
          path={ROUTES.PLANNER_EXISTING}
          component={ExistingTasksModal}
        />
        <Route exact path={ROUTES.CHATBOT} component={Chatbot} />
        <Route exact path={ROUTES.NUDGE_VIEW} component={Nudging} />
        <Route exact path={ROUTES.WELLBEING_SURVEY_VIEW} component={Survey} />
        <Route
          exact
          path={ROUTES.SETTINGS_ORGANISATIONS}
          component={OrganisationSwitch}
        />
        <Route
          exact
          path={ROUTES.GENERIC_NOTIFICATION_MESSAGE}
          component={GenericNotificationMessage}
        />
        <Route path={ROUTES.STATS_DASHBOARD} component={StatsDashboard} />
      </Switch>

      {showBottomNav && <BottomNav />}
    </>
  );
};

export default connect<{}, StateProps>({
  mapStateToProps: (state) => ({
    errorsConnect: state.appState.error,
    isLoadingConnect: state.appState.isLoading,
    profileImagesConnect: state.userProfileImage
  }),
  component: TabRoot
});
