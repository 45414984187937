import {
  IonFooter,
  IonHeader,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonText
} from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ClanIonContent } from "../pages/DiscussionFeed/PublicFeeds";

interface ClanQuestionModalProps {
  title: string;
  subtitle?: string;
  onYes?: () => void;
  onNo: () => void;
  yesText?: string;
  noText?: string;
  isOpen: boolean;
  loading: boolean;
  disableSubmit?: boolean;
}

const ClanQuestionModal: React.FC<ClanQuestionModalProps> = ({
  title,
  subtitle,
  children,
  onYes,
  onNo,
  isOpen,
  loading,
  disableSubmit = false
}) => {
  const { t } = useTranslation();

  return (
    <>
      <IonModal isOpen={isOpen} onDidDismiss={onNo}>
        <IonHeader mode="ios" className="px-4 mt-12">
          <div>
            <div className="mt-12 text-center">
              <IonText>
                <h3 className="my-1">{title}</h3>
              </IonText>
              {subtitle && (
                <IonText>
                  <h6 className="m-0">{subtitle}</h6>
                </IonText>
              )}
            </div>
          </div>
        </IonHeader>
        <ClanIonContent>{children}</ClanIonContent>
        <IonFooter>
          <IonSegment>
            <IonSegmentButton
              color="primary"
              className="font-extrabold uppercase font-gilroy text-clanGreen-100 bg-clanGray-100"
              onClick={onNo}
              disabled={loading}
            >
              {t("general.cancel")}
            </IonSegmentButton>
            {!onYes ? null : (
              <IonSegmentButton
                color="primary"
                className="font-extrabold uppercase font-gilroy text-black bg-clanYellow-100"
                onClick={onYes}
                disabled={disableSubmit}
              >
                {t("general.yes")}
              </IonSegmentButton>
            )}
          </IonSegment>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default ClanQuestionModal;
