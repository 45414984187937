import { IonActionSheet } from "@ionic/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Zoomable from "react-instagram-zoom";
import styled from "styled-components";

import {
  getPostSharedWithByPostId,
  getReactionsByPostId
} from "../../util/ClanPostAPIService";
import { useHtmlContentResolver } from "../../util/Html";
import {
  ClanCommentPostResult,
  ClanPostListResult,
  ClanPostWithCommentsResult,
  ClanReactionsDetails
} from "../../util/models/ClanPostModels";
import { getSharingText } from "../ClanSharingInfoInline";
import { H6, PostFont } from "../Typography";
import ClanPostSharedWithView from "./ClanPostSharedWithView";
import ClanReactionsDetailView from "./ClanReactionsDetailView";
import PostFooter from "./PostListItem/PostFooter";
import PostHeader from "./PostListItem/PostHeader";

const PostCard = styled.div<{ isComment?: boolean }>`
  padding: 20px 0 12px;
  margin: 12px 0 24px;

  ${({ isComment }) =>
    isComment &&
    `
    padding: 0;
    margin: 20px 0;
    `}

  ${({ isComment }) => !isComment && "background: white;"}
`;

const CuriousTitle = styled(H6)`
  max-width: 90%;
  margin: 0 auto;
`;

const PostContent = styled.div<{
  bordered?: boolean;
  isComment?: boolean;
  hasImage?: boolean;
}>`
  width: 90%;
  margin: 16px auto 12px;
  cursor: pointer;
  user-select: text;

  p {
    ${PostFont}
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${({ isComment }) =>
    isComment &&
    `
    background: white;
    border-radius: 10px;
    padding: 16px;
  `}

  ${({ bordered, theme }) =>
    bordered &&
    `
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid ${theme.primary};

    > img {
      margin: 0 -20px -20px;
      max-width: calc(100% + 40px);
      width: calc(100% + 40px);
    }

    > *:last-child:not(img) {
      margin-bottom: 0;
    }

    > *:last-child:not(img) > p:last-child {
      margin-bottom: 0;
    }
  `}

  ${({ hasImage, isComment }) =>
    hasImage &&
    isComment &&
    `
    margin-bottom: 0;
    border-radius: 10px 10px 0 0;
  `}
`;

const PostImage = styled.img`
  width: 100%;
  margin-bottom: 16px;
  display: block;
  max-height: 500px;
  object-fit: cover;
`;

const PostImageWrapper = styled.div<{
  isComment?: boolean;
  appending?: boolean;
}>`
  ${({ isComment }) =>
    isComment &&
    `
    max-width: 90%;
    margin: 0 auto;

    img {
      border-radius: 10px;
    }
  `}

  ${({ appending }) =>
    appending
      ? `
    background: white;
    border-radius: 0 0 10px 10px;
    `
      : "margin-top: 16px;"}
`;

export type PostType =
  | ClanPostListResult
  | ClanCommentPostResult
  | ClanPostWithCommentsResult;

interface PostListItemProps {
  post: PostType;
  showReactions?: boolean;
  isComment?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  onReport?: () => void;
  onShare?: () => void;
  onOpenComments?: () => void;
  className?: string;
}

const PostListItem: React.FC<PostListItemProps> = ({
  post,
  showReactions,
  isComment,
  onEdit,
  onDelete,
  onReport,
  onShare,
  onOpenComments,
  className
}) => {
  const [showActionSheet, setShowActionSheet] = useState<boolean>(false);
  const [showReactionDetails, setShowReactionDetails] = useState<boolean>(
    false
  );
  const [
    reactionsDetails,
    setReactionsDetails
  ] = useState<ClanReactionsDetails>();
  const { t } = useTranslation();
  const htmlResult = useHtmlContentResolver(post?.html);

  const [reactionsLoading, setReactionsLoading] = useState<boolean>(true);
  const [sharedPostId, setSharedPostId] = useState<string | undefined>();

  // TODO: in future we should not force to uppercase but instead set the text fields to enter the uppercase or do it in CSS
  const capitalize = (value: string): string => {
    if (!value) return "";
    if (value.length === 1) return value.charAt(0).toUpperCase();
    return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
  };

  useEffect(() => {
    if (!showReactionDetails) return;
    setReactionsLoading(true);
    getReactionsByPostId(post.id)
      .then(setReactionsDetails)
      .catch(console.error)
      .finally(() => setReactionsLoading(false));
  }, [post.id, showReactionDetails]);

  const isSystemPost = (post as ClanPostListResult).systemPost;

  const onOpenSettings = () => setShowActionSheet(true);
  const onShowReactionDetails = () => setShowReactionDetails(true);

  const { profilesAmount, groupsAmount, sharingShortcut } =
    (post as ClanPostListResult).sharing || {};

  const actionButtons = useMemo(() => {
    const enabledActions = [];
    const sharingText = getSharingText(
      sharingShortcut,
      profilesAmount,
      groupsAmount,
      t
    );

    if (sharingText) {
      enabledActions.push({
        text: sharingText,
        handler: () => setSharedPostId(post.id)
      });
    }

    const { edit: canEdit, remove: canRemove, report: canReport } =
      post.permissions || {};

    if (canEdit && !!onEdit) {
      enabledActions.push({
        text: t("general.edit"),
        handler: onEdit
      });
    }

    if (canRemove && !!onDelete) {
      enabledActions.push({
        text: t("general.remove"),
        handler: onDelete
      });
    }

    if (canReport && onReport) {
      enabledActions.push({
        text: t("general.report"),
        handler: onReport
      });
    }

    return enabledActions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post.permissions]);

  const hasImage = !!(post as ClanPostListResult).image?.thumbnail?.url;

  return (
    <>
      <ClanPostSharedWithView
        viewReactions={getPostSharedWithByPostId}
        targetId={sharedPostId}
        onHide={() => setSharedPostId(undefined)}
        title={t("clan_discussion_post.visible_to")}
      />
      <ClanReactionsDetailView
        showReactionDetails={showReactionDetails}
        setShowReactionDetails={setShowReactionDetails}
        reactionsLoading={reactionsLoading}
        reactionsDetails={reactionsDetails}
      />

      <PostCard
        data-post-id={post.id}
        className={className}
        isComment={isComment}
      >
        {isSystemPost && (
          <CuriousTitle>{t("clan_discussion_post.just_curious")}</CuriousTitle>
        )}

        {!isSystemPost && (
          <PostHeader post={post} openSettings={onOpenSettings} />
        )}

        {(post?.title || htmlResult) && (
          <PostContent
            bordered={isSystemPost}
            isComment={isComment}
            hasImage={hasImage}
            onClick={onOpenComments}
          >
            {post?.title && <p>{capitalize(post?.title)}</p>}

            {htmlResult && (
              <div
                dangerouslySetInnerHTML={{
                  __html: htmlResult
                }}
              />
            )}
          </PostContent>
        )}

        {!isSystemPost && hasImage && (
          <PostImageWrapper
            isComment={isComment}
            appending={!!post?.title || !!htmlResult}
          >
            <Zoomable>
              <PostImage
                onClick={onOpenComments}
                src={(post as ClanPostListResult).image?.thumbnail?.url}
                alt=""
              />
            </Zoomable>
          </PostImageWrapper>
        )}

        <PostFooter
          post={post}
          showReactions={showReactions && post.permissions?.like}
          isComment={isComment}
          openComments={onOpenComments}
          onShare={onShare}
          showReactionDetails={onShowReactionDetails}
        />
      </PostCard>

      <IonActionSheet
        mode="ios"
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={actionButtons}
      />
    </>
  );
};

export default PostListItem;
