import "./styles/index.css";
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "./configuration";

import {
  IonApp,
  IonButton,
  IonButtons,
  IonHeader,
  IonImg,
  IonToolbar,
  setupConfig
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { differenceInCalendarWeeks } from "date-fns";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, useHistory } from "react-router-dom";
import styled from "styled-components";

import ClanLoader from "./components/ClanLoader";
import ClanToastManager from "./components/ClanToastManager";
import ClanModalManager from "./components/Modals/ClanModalManager";
import {
  setGivenFeedback,
  setInfoModalClosed,
  updateGivenFeedback,
  updateRecievedFirstSurvey
} from "./data/app/app.actions";
import { getInfoModalClosedCookie } from "./data/app/appDataService";
import { AppContextProvider } from "./data/AppContext";
import { connect } from "./data/connect";
import { OauthState } from "./data/oauth/oauth.state";
import {
  ProfileInfoType,
  selectProfileInfo
} from "./data/selectors/profileSelectors";
import { showToast } from "./data/toasts/toasts.actions";
import i18n from "./i18n";
import Settings from "./pages/Settings/Settings";
import Signup from "./pages/Signup/Signup";
import Start from "./pages/Start/Start";
import TabRoot from "./pages/TabRoot";
import * as ROUTES from "./routes";
import { UserEnabledProperties } from "./util/api/UserEnabledPropertiesService";
import { mobileOnlyStyles } from "./util/ResponsiveUtils";
import { ApiRequestError } from "./util/ApiService";
import ClanErrorModal from "./ClanErrorModal";
import { useAsyncEffect } from "@react-hook/async";
import { refreshAndCacheUserProperties } from "./util/UserProperties";
import { isSuccessOrError } from "./util/AsyncEffectFilter";
import ThemeProvider from "./ThemeProvider";

/* Core CSS required for Ionic components to work properly */
// import "@ionic/react/css/display.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
/* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/typography.css";

const DesktopHeader = styled(IonHeader)`
  ${mobileOnlyStyles({
    display: "none"
  })}
`;

const AppStyled = styled(IonApp)<{ maxWidth: number }>`
  font-family: notoSans;
  max-width: ${({ maxWidth }) => `${maxWidth}px` || "1440px"};
  margin: 0 auto;
`;

const App: React.FC = () => {
  setupConfig({
    mode: "md",
    rippleEffect: false
  });

  return (
    <IonReactRouter>
      <AppContextProvider>
        <IonicAppConnected />
      </AppContextProvider>
    </IonReactRouter>
  );
};

interface StateProps {
  isLoading: boolean;
  hasToken: boolean;
  tokenConnect: OauthState;
  isAppReady: boolean;
  isProfileLockedConnect: boolean;
  infoModalClosed: boolean;
  profileCreationConnect?: string;
  feedbackGiven: boolean;
  isProfileLoadedConnect?: boolean;
  error?: ApiRequestError | string;
}

interface DispatchProps {
  dispatchInfoModalClosed: typeof setInfoModalClosed;
  dispatchUpdateRecievedFirstSurvey: typeof updateRecievedFirstSurvey;
  dispatchToast: typeof showToast;
  dispatchUpdateGivenFeedback: typeof updateGivenFeedback;
  dispatchSetGivenFeedback: typeof setGivenFeedback;
}

interface IonicAppProps extends StateProps, DispatchProps {}

const IonicApp: React.FC<IonicAppProps> = ({
  isLoading,
  hasToken,
  tokenConnect,
  isAppReady,
  isProfileLockedConnect,
  dispatchInfoModalClosed,
  dispatchUpdateRecievedFirstSurvey,
  profileCreationConnect,
  dispatchToast,
  dispatchUpdateGivenFeedback,
  dispatchSetGivenFeedback,
  feedbackGiven,
  isProfileLoadedConnect,
  error
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const modalsChecked = useRef(false);
  const cacheCheck = useAsyncEffect<boolean>(async () => {
    if (!tokenConnect.hasToken) {
      if (modalsChecked.current) {
        modalsChecked.current = false;
      }
      return false;
    }
    if (modalsChecked.current) return false;
    modalsChecked.current = true;
    await refreshAndCacheUserProperties();
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenConnect]);

  const dispatchCheck = useAsyncEffect<boolean>(async () => {
    if (!isSuccessOrError(cacheCheck)) return false;
    if (cacheCheck.value !== true) return false;
    await dispatchUpdateRecievedFirstSurvey();
    await dispatchUpdateGivenFeedback();

    const isClosed = await getInfoModalClosedCookie();
    await dispatchInfoModalClosed(isClosed);
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheCheck]);

  useEffect(() => {
    tokenConnect.languages?.iso2 &&
      i18n.changeLanguage(tokenConnect.languages.iso2);
  }, [tokenConnect]);

  useEffect(() => {
    if (
      hasToken &&
      isSuccessOrError(dispatchCheck) &&
      dispatchCheck.value === true &&
      !feedbackGiven &&
      profileCreationConnect &&
      differenceInCalendarWeeks(new Date(), new Date(profileCreationConnect), {
        weekStartsOn: 1
      }) >= 2
    ) {
      const hasTeacherFeedback = UserEnabledProperties.General.hasTeacherFeedback(
        tokenConnect
      );
      let feedbackLink;
      if (
        tokenConnect.languages?.iso2 &&
        tokenConnect.languages?.iso2 === "en"
      ) {
        feedbackLink = hasTeacherFeedback
          ? "https://docs.google.com/forms/d/e/1FAIpQLSfbVcbOiIgx9_k90YC6aEqGKTVXaixuenfUNktYvPs12-F-ag/viewform?usp=sf_link"
          : "https://docs.google.com/forms/d/e/1FAIpQLSeY1GW27S42AnO8jpCXSwOGx6TBDnZ3lM0_0lBhy20yfWTgVQ/viewform?usp=sf_link";
      } else if (
        tokenConnect.languages?.iso2 &&
        tokenConnect.languages?.iso2 === "et"
      ) {
        feedbackLink = hasTeacherFeedback
          ? "https://docs.google.com/forms/d/e/1FAIpQLSd_tHkLR8QI_tBmyTrwexnWVfpl5I7i2_MJH9Rv_UxkD8Db5A/viewform?usp=sf_link"
          : "https://docs.google.com/forms/d/e/1FAIpQLSfh0xmXBEv_1s-stMOp2V7PpG7mBh2pDtjX2kWASjbgEqwhlA/viewform?usp=sf_link";
      }
      dispatchToast(
        hasTeacherFeedback
          ? t("general.feedback_message_teacher")
          : t("general.feedback_message_student"),
        undefined,
        "info",
        [
          {
            label: t("general.feedback_never"),
            callback: () => dispatchSetGivenFeedback(true)
          },
          {
            label: t("general.give_feedback"),
            href: feedbackLink,
            primary: true,
            callback: () => dispatchSetGivenFeedback(true)
          }
        ]
      );
    }
  }, [
    dispatchSetGivenFeedback,
    dispatchToast,
    feedbackGiven,
    hasToken,
    profileCreationConnect,
    t,
    tokenConnect,
    tokenConnect.languages,
    dispatchCheck
  ]);

  if (isAppReady) {
    return (
      <ThemeProvider>
        <div id="recaptcha-container" />
        <AppStyled
          maxWidth={Number(process.env.REACT_APP_CONFIG_DESKTOP_MAX_WIDTH)}
        >
          <ClanLoader
            message="Please wait. Loading..."
            showLoading={isLoading}
          />
          <DesktopHeader
            mode="ios"
            className="bg-clanYellow-100"
            id="dashboard-header-desktop"
          >
            <IonToolbar mode="ios">
              <IonButtons slot="secondary">
                <IonButton onClick={() => history.push(ROUTES.DEFAULT)}>
                  <IonImg
                    src="/assets/cb-logo-web.png"
                    alt="logo"
                    style={{ width: "90%", maxWidth: "173px" }}
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </DesktopHeader>
          <Route
            exact
            path={ROUTES.START}
            render={(props) => <Start {...props} />}
          />
          <Route
            exact
            path={ROUTES.SIGN_UP}
            render={(props) => <Signup {...props} />}
          />
          <Route exact path={ROUTES.SETTINGS} component={Settings} />
          <Route
            exact
            path={ROUTES.DEFAULT}
            render={(props) =>
              hasToken ? (
                <Redirect to={ROUTES.HOME} {...props} />
              ) : (
                <Redirect to={ROUTES.START} {...props} />
              )
            }
          />
          <Route
            path={ROUTES.HOME}
            render={(props) =>
              hasToken ? (
                isProfileLoadedConnect ? (
                  isProfileLockedConnect ? (
                    <Redirect to={ROUTES.SIGN_UP} {...props} />
                  ) : (
                    <TabRoot />
                  )
                ) : null
              ) : (
                <Redirect to={ROUTES.START} {...props} />
              )
            }
          />
          <ClanToastManager />
          <ClanModalManager />
        </AppStyled>
      </ThemeProvider>
    );
    //TODO: This has to be styled or use Ionic elements
  } else if (error) {
    return <ClanErrorModal />;
  } else {
    return <h2>App is Loading...</h2>;
  }
};

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isLoading: state.appState.isLoading,
    hasToken: state.token.hasToken,
    tokenConnect: state.token,
    error: state.appState.error,
    isAppReady: state.appState.isReady,
    isProfileLockedConnect: state.userProfile.isLocked,
    isProfileLoadedConnect: state.userProfile.isLoaded,
    infoModalClosed: state.appState.infoModalClosed,
    feedbackGiven: state.appState.feedbackGiven,
    profileCreationConnect: selectProfileInfo(
      ProfileInfoType.ProfileCreationDate,
      state
    )
  }),
  mapDispatchToProps: {
    dispatchInfoModalClosed: setInfoModalClosed,
    dispatchUpdateRecievedFirstSurvey: updateRecievedFirstSurvey,
    dispatchUpdateGivenFeedback: updateGivenFeedback,
    dispatchToast: showToast,
    dispatchSetGivenFeedback: setGivenFeedback
  },
  component: IonicApp
});
