import React from "react";
import { useTranslation } from "react-i18next";

import ClanInfoCard from "../../components/ClanInfoCard";
import FooterButtons, {
  PrimaryFooterButton
} from "../../components/FooterButtons";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "./Onboarding";

interface OnboardingIntroStepProps {
  setCurrentStep: (step: number) => void;
}

const OnboardingIntroStep: React.FC<OnboardingIntroStepProps> = ({
  setCurrentStep
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ClanIonContent>
        <OnboardingWrapper>
          <ClanInfoCard
            title={t("onboarding.step1_header")}
            bodyParagraphs={[
              t("onboarding.step1_body"),
              t("onboarding.step1_body_2")
            ]}
          />
        </OnboardingWrapper>
      </ClanIonContent>
      <FooterButtons>
        <PrimaryFooterButton onClick={() => setCurrentStep(3)}>
          {t("onboarding.start")}
        </PrimaryFooterButton>
      </FooterButtons>
    </>
  );
};

export default OnboardingIntroStep;
