export interface ClanInterestsEndpoints {
  // listOthers: string;
  // listMine: string;
  listProfiles: (item: string) => string;
  addToProfile: string;
  removeFromProfile: string;
  screenName: string;
}
export const BUCKET_LIST_ENDPOINTS = {
  screenName: "BUCKET_LIST",
  // listOthers: "/user/profile/v1/bucket_list/group/list",
  // listMine: "/user/profile/v1/bucket_list",
  listProfiles: (item) => {
    return `/user/profile/v1/bucket_list/v2/group/list/item?item=${encodeURIComponent(
      item
    )}`;
  },
  addToProfile: "/user/profile/v1/bucket_list",
  removeFromProfile: "/user/profile/v1/bucket_list"
} as ClanInterestsEndpoints;

export const INTERESTS_ENDPOINTS = {
  screenName: "INTERESTS",
  // listOthers: "/user/profile/v1/interests/group/list",
  // listMine: "/user/profile/v1/interests",
  listProfiles: (item) => {
    return `/user/profile/v1/interests/v2/group/list/item?item=${encodeURIComponent(
      item
    )}`;
  },
  addToProfile: "/user/profile/v1/interests",
  removeFromProfile: "/user/profile/v1/interests"
} as ClanInterestsEndpoints;

export const LANGUAGES_ENDPOINTS = {
  screenName: "LANGUAGES",
  // listOthers: "/user/profile/v1/languages/group/list",
  // listMine: "/user/profile/v1/languages/spoken",
  listProfiles: (item) => {
    return `/user/profile/v1/languages/group/list/item?item=${encodeURIComponent(
      item
    )}`;
  },
  addToProfile: "/user/profile/v1/languages/spoken",
  removeFromProfile: "/user/profile/v1/languages/spoken"
} as ClanInterestsEndpoints;
