import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { SecondaryActionLink } from "./Buttons/Button";
import { H6 } from "./Typography";

const StyledPaywallCard = styled.div`
  background: #fee661;
  border-radius: 25px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: start;
`;

interface PaywallCardProps {
  content: string;
}

const PaywallCard: React.FC<PaywallCardProps> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <StyledPaywallCard>
      <H6>{content}</H6>
      <SecondaryActionLink
        strong
        target="_blank"
        href="mailto:support@clabeat.com?subject=My%20subscription&body=Email%20or%20phone%20associated%20with%20my%20Clanbeat%20account%3A"
      >
        {t("paywall.subscribe")}
      </SecondaryActionLink>
    </StyledPaywallCard>
  );
};

export default PaywallCard;
