import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { doRequestAndReturnResult } from "../../apiService";
import ClanFlowStepTemplate from "../../components/ClanFlowStepTemplate";
import { SignupSteps } from "./Signup";
import { useAsyncEffect } from "@react-hook/async";
import { isReadyStateLoading } from "../../util/AsyncEffectFilter";
import ClanLoader from "../../components/ClanLoader";
import { ChoiceValue } from "../../util/ChoiceTemplate";
import ChoiceLines from "../../util/ChoiceTemplate/template/ChoiceLines";
import { renewRefreshToken } from "../../util/ApiService";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "../Onboarding/Onboarding";

enum AppLanguages {
  English = "ENG",
  Estonian = "EST",
  Russian = "RUS",
  Georgian = "KAT"
}

const APP_LANGUAGES = [
  { id: AppLanguages.English, label: "English" },
  { id: AppLanguages.Estonian, label: "Eesti keel" },
  { id: AppLanguages.Russian, label: "По-русски" },
  { id: AppLanguages.Georgian, label: "ქართული" }
] as ChoiceValue<AppLanguages>[];

type LanguageResponse = {
  key: AppLanguages;
  label: string;
};

interface AppLanguageStepProps {
  currentStep: SignupSteps;
  setCurrentStep: (step: SignupSteps) => void;
  loading: boolean;
  setCurrentFooterBtns: any;
  isEditing: boolean;
}

const AppLanguageStep: React.FC<AppLanguageStepProps> = ({
  currentStep,
  setCurrentStep,
  loading,
  setCurrentFooterBtns,
  isEditing
}) => {
  const [selectedAppLanguage, setSelectedAppLanguage] = useState<AppLanguages>(
    AppLanguages.English
  );

  const selectedAppLanguageRef = useRef(selectedAppLanguage);

  useEffect(() => {
    selectedAppLanguageRef.current = selectedAppLanguage;
  }, [selectedAppLanguage]);

  const history = useHistory();
  const { t } = useTranslation();
  const appLanguageStepBtn = async () => {
    try {
      await doRequestAndReturnResult(
        "post",
        `/user/v1/language/${selectedAppLanguageRef.current}`
      );
      await renewRefreshToken();
    } finally {
      if (isEditing) {
        history.goBack();
      } else {
        setCurrentStep(SignupSteps.NAME);
      }
    }
  };

  const loadLanguage = useAsyncEffect(async () => {
    if (currentStep !== SignupSteps.APP_LANGUAGE) {
      return;
    }
    const response = await doRequestAndReturnResult<LanguageResponse>(
      "get",
      `/user/v1/language`
    );
    const indexOfLang = Object.values(AppLanguages).indexOf(response.key);
    if (indexOfLang > 0) setSelectedAppLanguage(response.key);
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === SignupSteps.APP_LANGUAGE) {
      setCurrentFooterBtns(
        isEditing
          ? [
              {
                title: t("general.cancel"),
                disabled: false,
                callback: () => history.goBack()
              },
              {
                title: t("general.ok"),
                disabled: loading,
                callback: appLanguageStepBtn
              }
            ]
          : [
              {
                title: t("general.next"),
                disabled: loading,
                callback: appLanguageStepBtn
              }
            ]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, isEditing, loading]);

  if (currentStep !== SignupSteps.APP_LANGUAGE) {
    return null;
  }
  return (
    <>
      {isReadyStateLoading(loadLanguage) && <ClanLoader showLoading={true} />}
      <ClanIonContent>
        <OnboardingWrapper>
          <ClanFlowStepTemplate title={t("signup.app_language")}>
            <ChoiceLines
              values={APP_LANGUAGES}
              selected={selectedAppLanguage ? [selectedAppLanguage] : []}
              onClick={setSelectedAppLanguage}
            />
          </ClanFlowStepTemplate>
        </OnboardingWrapper>
      </ClanIonContent>
    </>
  );
};

export default AppLanguageStep;
