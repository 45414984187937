import * as H from "history";

export const removeQuery = (
  location: H.Location,
  history: H.History,
  queryName: string
) => {
  const queryParams = new URLSearchParams(location.search);
  if (queryParams.has(queryName)) {
    queryParams.delete(queryName);
    history.replace({
      search: queryParams.toString()
    });
  }
};
