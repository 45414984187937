import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ClanFlowStepTemplate from "../../components/ClanFlowStepTemplate";
import Textarea from "../../components/Form/Textarea";
import Grid from "../../components/Grid";
import SelectableListItem from "../../components/ListItem/SelectableListItem";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "../Onboarding/Onboarding";
import { SignupSteps } from "./Signup";

const GenderGrid = styled(Grid)`
  margin-bottom: 12px;
`;

interface GenderStepProps {
  pushToErrors: (message: string) => void;
  currentStep: SignupSteps;
  setCurrentStep: (step: SignupSteps) => void;
  loading: boolean;
  setCurrentFooterBtns: any;
}

const GenderStep: React.FC<GenderStepProps> = ({
  currentStep,
  setCurrentStep,
  loading,
  setCurrentFooterBtns
}) => {
  const { watch, control } = useFormContext();
  const { t } = useTranslation();
  const watchGender = watch("gender");
  const watchGenderInfo = watch("genderInfo");

  useEffect(() => {
    if (currentStep === SignupSteps.GENDER) {
      setCurrentFooterBtns([
        {
          title: t("general.back"),
          disabled: false,
          callback: () => {
            setCurrentStep(SignupSteps.NAME);
          }
        },
        {
          title: t("general.next"),
          disabled: loading,
          callback: () => {
            setCurrentStep(SignupSteps.BIRTHDAY);
          }
        }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, loading, watchGender]);

  if (currentStep !== SignupSteps.GENDER) {
    return null;
  }

  const onSelectGender = (value: string) => () => {
    if (watchGenderInfo) {
      control.setValue("genderInfo", "");
    }

    control.setValue("gender", watchGender === value ? null : value);
  };

  const onGenderInformationChange = (newGenderInfo: string) => {
    if (watchGender) {
      control.setValue("gender", null);
    }
    control.setValue("genderInfo", newGenderInfo);
  };

  return (
    <ClanIonContent>
      <OnboardingWrapper>
        <ClanFlowStepTemplate
          title={t("signup.gender_header")}
          subtitle={`${t("signup.gender_subtitle1")}\n\n${t(
            "signup.gender_subtitle2"
          )}`}
        >
          <GenderGrid columns="auto auto" gap="12px">
            <SelectableListItem
              small
              selected={watchGender === "male"}
              bold={watchGender === "male"}
              onClick={onSelectGender("male")}
            >
              {t("signup.gender_male")}
            </SelectableListItem>
            <SelectableListItem
              small
              selected={watchGender === "female"}
              bold={watchGender === "female"}
              onClick={onSelectGender("female")}
            >
              {t("signup.gender_female")}
            </SelectableListItem>
          </GenderGrid>

          <Textarea
            placeholder="Or add your gender information here.."
            value={watchGenderInfo}
            rows={4}
            onChange={onGenderInformationChange}
          />
        </ClanFlowStepTemplate>
      </OnboardingWrapper>
    </ClanIonContent>
  );
};

export default GenderStep;
