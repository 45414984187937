import { AsyncReducerState, AsyncStatus } from "@react-hook/async";

const isInStatus = (
  provided: AsyncReducerState<any, any>[],
  statusesToFind: AsyncStatus[]
): boolean => {
  return (
    provided
      .map((status) => status.status)
      .find((status) => statusesToFind.indexOf(status) >= 0) !== undefined
  );
};

export const isReadyStateLoading = (
  ...args: AsyncReducerState<any, any>[]
): boolean => {
  return isInStatus(args, ["loading"]);
};

export const isReadyStatePending = (
  ...args: AsyncReducerState<any, any>[]
): boolean => {
  return isInStatus(args, ["idle", "success"]);
};

export const isReadyStateNotPending = (
  ...args: AsyncReducerState<any, any>[]
): boolean => {
  return !isInStatus(args, ["idle", "success"]);
};

export const isReadyStateCancelOrFail = (
  ...args: AsyncReducerState<any, any>[]
): boolean => {
  return isInStatus(args, ["cancelled", "error"]);
};

export const isSuccessOrError = (
  state: AsyncReducerState<any, any>
): boolean => {
  return state.status === "success" || state.status === "error";
};

export const isSuccessOrErrorStates = (
  ...state: AsyncReducerState<any, any>[]
): boolean => {
  return !state.some((e) => !isSuccessOrError(e));
};
