import { FilterGroupType } from "../../util/Filters";
import { SET_FEED_FILTER } from "./feed.actions";
import { FeedState } from "./feed.state";

export type FeedFiltersAction = {
  type: string;
  data: { filters: Array<FilterGroupType> };
};

type FeedAction = FeedFiltersAction;

export function feedReducer(state: FeedState, action: FeedAction): FeedState {
  switch (action.type) {
    case SET_FEED_FILTER: {
      return {
        ...state,
        activeFilters: action.data.filters
      };
    }

    default:
      return state;
  }
}
