import { IonIcon } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { MOOD_CHOICE } from "../../../util/MoodChoice";

const MoodGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  text-align: center;
  gap: 2rem 10px;
  margin: 2rem 0;
`;

const MoodItem = styled.div`
  cursor: pointer;
`;

interface ClanMoodSelectProps {
  callback: (mood: string) => void;
}

const ClanMoodSelect: React.FC<ClanMoodSelectProps> = ({ callback }) => {
  const { t } = useTranslation();
  return (
    <MoodGrid>
      {MOOD_CHOICE.map(
        (item: { label: string; image: string }, itemIndex: number) => {
          return (
            <MoodItem
              key={`mood_item_${itemIndex}`}
              onClick={(): any => {
                callback(item.label);
              }}
            >
              <IonIcon src={item.image} className="visible w-12 h-12" />
              <p className="max-w-xs">
                {t("moods." + item.label.toLowerCase())}
              </p>
            </MoodItem>
          );
        }
      )}
    </MoodGrid>
  );
};

export default ClanMoodSelect;
