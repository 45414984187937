import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { FeedTypes } from "../../util/Feeds";
import Feed from "../DiscussionFeed/Feed";
import { FilterGroupType } from "../../util/Filters";

interface ProfileInsightsProps {
  showNewPost?: boolean;
  setShowNewPost: (showNewPost: boolean) => void;
}
const DEFAULT_FILTER = [] as Array<FilterGroupType>;
const ProfileInsights: React.FC<ProfileInsightsProps> = ({
  showNewPost = false,
  setShowNewPost
}) => {
  const location = useLocation();

  const { reflectionTaskId, highlightedPostId } = (location.state || {}) as {
    reflectionTaskId?: string;
    highlightedPostId?: string;
  };

  const ionReflectionColElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showNewPost) {
      ionReflectionColElement.current?.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }, [showNewPost]);
  return (
    <div ref={ionReflectionColElement}>
      <Feed
        id="profile-feed"
        filterEnabled
        reflectionTaskId={reflectionTaskId}
        feedType={FeedTypes.PERSONAL}
        setShowNewPost={setShowNewPost}
        showNewPost={showNewPost}
        highlightPostId={highlightedPostId}
        filters={DEFAULT_FILTER}
      />
    </div>
  );
};

export default ProfileInsights;
