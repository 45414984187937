import React from "react";
import { WEIGHTSCALE } from "../../pages/Planner/PlannerDay";
import { IonButton, IonLabel, IonIcon } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import { useTranslation } from "react-i18next";

type WeightButtonProps = {
  weight: WEIGHTSCALE | undefined;
  onClick: () => void;
  onClear: () => void;
};

const WeightButton: React.FC<WeightButtonProps> = ({
  weight,
  onClick,
  onClear
}) => {
  const { t } = useTranslation();
  function getWeightStyles(w: WEIGHTSCALE | undefined) {
    switch (w) {
      case WEIGHTSCALE.EASY:
        return {
          weightClass: "text-clanGreen-100",
          weightText: t("planner.weight_easy"),
          weightIcon: "weight-easy"
        };
      case WEIGHTSCALE.MEDIUM:
        return {
          weightClass: "text-clanGreen-100",
          weightText: t("planner.weight_medium"),
          weightIcon: "weight-medium"
        };
      case WEIGHTSCALE.HARD:
        return {
          weightClass: "text-clanGreen-100",
          weightText: t("planner.weight_hard"),
          weightIcon: "weight-hard"
        };
      default:
        return {
          weightClass: "text-clanGray-200",
          weightText: t("planner.weight_difficulty"),
          weightIcon: "weight-none"
        };
    }
  }

  const { weightClass, weightText, weightIcon } = getWeightStyles(weight);

  return (
    <div className="flex items-center mx-1">
      <IonButton
        style={{
          "--padding-start": "0.5rem",
          "--padding-end": "0.5rem"
        }}
        fill="clear"
        color="dark"
        mode="ios"
        onClick={onClick}
        className="p-0 m-0"
      >
        <IonLabel className={`m-0 p-0 text-clanP ${weightClass}`}>
          {weightText}
        </IonLabel>
        <IonIcon
          className={`h-4 p-0 m-0 ${weightClass}`}
          slot="start"
          icon={`/assets/planner/${weightIcon}.svg`}
        />
      </IonButton>

      {weight !== undefined && (
        <IonIcon
          icon={closeCircle}
          slot="end"
          className="p-0 h-8 text-clanGray-200 cursor-pointer"
          onClick={onClear}
        />
      )}
    </div>
  );
};

export default WeightButton;
