import { UserProfileData, UserProfileState } from "./profile.state";
import { Dispatch } from "react";
import { withLoading } from "../../app/app.actions";
import { cacheUserProfileData, getUserProfileData } from "./profileDataService";

export const UPDATE_USER_PROFILE = "update-user-profile";
export const SET_USER_PROFILE = "set-user-profile";

export type UserProfileAction = {
  type: string;
  data: UserProfileState | UserProfileData;
};

export const getSetUserProfileActionPayload = (
  userProfileData: UserProfileState
) =>
  ({
    type: SET_USER_PROFILE,
    data: userProfileData
  } as UserProfileAction);

export const setUserProfile = (userProfileState: UserProfileState) => async (
  dispatch: Dispatch<any>
) => {
  withLoading("profile.actions:setUserProfile", async () => {
    dispatch(getSetUserProfileActionPayload(userProfileState));
  })(dispatch);
};

export const updateUserProfile = (userProfileState: UserProfileData) => async (
  dispatch: Dispatch<any>
) => {
  withLoading("profile.actions:updateState", async () => {
    // Caching profile state to storage
    const existingProfileData = await getUserProfileData();
    await cacheUserProfileData({ ...existingProfileData, ...userProfileState });

    dispatch({
      type: UPDATE_USER_PROFILE,
      data: userProfileState
    } as UserProfileAction);
  })(dispatch);
};
