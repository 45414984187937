import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import {
  WellBeingQuestion,
  WellBeingResponseTranslated
} from "../../util/api/WellBeingService";
import { dateLocaleFormatter } from "../../util/DateUtils";
import Card from "../Card";
import { FlexCol, FlexRow } from "../Flexbox";
import { H4, H6 } from "../Typography";

const CheckInCard = styled(Card)<{ simple?: boolean; onClick?: any }>`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 12px;
  align-items: center;
  margin: 12px 0;
  white-space: pre-line;

  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;
    `}

  padding: ${({ simple }) =>
    simple ? "16px 8px 16px 16px" : "24px 8px 24px 16px"};

  ${({ simple }) =>
    simple &&
    css`
      background: #fafafa;
    `}

  h4 {
    margin: 0;
  }
`;

const Delimiter = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.primary};
`;

interface CheckInItemProps {
  wellbeingQuestion: WellBeingResponseTranslated<WellBeingQuestion>;
  simple?: boolean;
  onClick?: () => void;
}

const CheckInListItem: React.FC<CheckInItemProps> = ({
  wellbeingQuestion,
  simple,
  onClick
}) => {
  const { t } = useTranslation();

  const {
    result,
    component: Component,
    progressIcon: ProgressIcon
  } = wellbeingQuestion;

  return (
    <CheckInCard onClick={onClick} simple={simple}>
      <FlexCol rowGap="8px">
        <H4>{result.label}</H4>
        <Delimiter />
        {!simple && (
          <H6>
            {t("wellbeing_list.asked", {
              time: dateLocaleFormatter(result.dateTime, "hh a, MMM d"),
              answered: result.profilesAnswered,
              total: result.profilesTotal
            })}
          </H6>
        )}
      </FlexCol>
      <FlexRow columnGap="4px" align="center">
        <Component active scale={1.2} />
        <ProgressIcon />
      </FlexRow>
    </CheckInCard>
  );
};

export default CheckInListItem;
