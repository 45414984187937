import {
  IonFooter,
  IonIcon,
  IonItem,
  IonModal,
  IonNote,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { ClanReactionsDetails } from "../../util/models/ClanPostModels";
import { ReactionTypes, REACTION_LIST } from "../../util/Reactions";
import ClanProfileCard from "../ClanProfileCard";
import ClanProfileListItem from "../ClanProfileListItem";
import { RoleAwareProfileWithImage } from "../../util/models/ClanProfileModels";
import { useTranslation } from "react-i18next";
import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";

interface OwnProps {
  showReactionDetails: boolean;
  setShowReactionDetails: (showReactionDetails: boolean) => void;
  reactionsDetails: ClanReactionsDetails | undefined;
  reactionsLoading: boolean;
}

type ClanReactionsDetailViewProps = OwnProps;

const ClanReactionsDetailView: React.FC<ClanReactionsDetailViewProps> = ({
  showReactionDetails,
  setShowReactionDetails,
  reactionsDetails,
  reactionsLoading
}) => {
  const [activeSegment, setActiveSegment] = useState<ReactionTypes | "ALL">(
    "ALL"
  );
  const [allUniqueReactionPersons, setAllUniqueReactionPersons] = useState<
    RoleAwareProfileWithImage[]
  >();
  const { t } = useTranslation();

  useEffect(() => {
    const getUniquePersons = () => {
      //TODO: Here we can filter the reactionsDetail object to make sure that reactions that are not in ReactionTypes wouldn't be included
      const allPersonsArrays =
        reactionsDetails &&
        Object.values(reactionsDetails).reduce(
          (
            acc: RoleAwareProfileWithImage[],
            reactionProfiles: RoleAwareProfileWithImage[]
          ) => reactionProfiles && acc?.concat(reactionProfiles, [])
        );
      setAllUniqueReactionPersons(
        allPersonsArrays?.filter(
          (person1: RoleAwareProfileWithImage | undefined, index, self) => {
            if (person1) {
              return (
                self.findIndex((person2) => person2.id === person1.id) === index
              );
            } else return [];
          }
        )
      );
    };
    getUniquePersons();
  }, [reactionsDetails]);

  return (
    <>
      <IonModal
        isOpen={showReactionDetails}
        onDidDismiss={() => setShowReactionDetails(false)}
      >
        <ClanIonContent>
          <div className="h-full flex flex-col justify-center items-center bg-clanGray-100">
            <h3 className="mt-12">{t("clan_discussion_post.reactions")}</h3>
            <ClanProfileCard
              header={
                activeSegment === "ALL"
                  ? t("clan_discussion_post.all_reactions")
                  : ReactionTypes[
                      activeSegment.valueOf() as keyof typeof ReactionTypes
                    ]
              }
              cardClassName="h-clanCard"
              contentClassName="px-3"
            >
              <IonSegment
                scrollable
                onIonChange={(e) =>
                  setActiveSegment(e.detail.value as ReactionTypes)
                }
                value={activeSegment}
                color="success"
              >
                <IonSegmentButton
                  value="ALL"
                  layout="icon-start"
                  className="w-12 items-center mx-1"
                >
                  <IonNote>{t("clan_discussion_post.all")}</IonNote>
                  <IonNote className="ml-1">
                    {allUniqueReactionPersons
                      ? allUniqueReactionPersons.length
                      : 0}
                  </IonNote>
                </IonSegmentButton>
                {REACTION_LIST.map(
                  (
                    item: { label: string; image: string; key: string },
                    itemIndex: number
                  ) => {
                    return (
                      <IonSegmentButton
                        key={`reaction_${itemIndex}`}
                        value={item.key as keyof typeof ReactionTypes}
                        layout="icon-start"
                        className="w-12 items-center mx-1"
                      >
                        {reactionsDetails && (
                          <IonNote className="ml-1">
                            {reactionsDetails[item.key as ReactionTypes]
                              ? reactionsDetails[item.key as ReactionTypes]
                                  ?.length
                              : 0}
                          </IonNote>
                        )}
                        <IonIcon icon={item.image} />
                      </IonSegmentButton>
                    );
                  }
                )}
              </IonSegment>
              {reactionsDetails && !reactionsLoading ? (
                activeSegment !== "ALL" ? (
                  reactionsDetails[activeSegment] === undefined ? (
                    <IonItem>{t("clan_discussion_post.no_reactions")}</IonItem>
                  ) : (
                    reactionsDetails[
                      activeSegment
                    ]?.map((person, itemIndex: number) => (
                      <ClanProfileListItem
                        key={`person_${person.id}_${itemIndex}`}
                        profile={person}
                        onNavigation={() => setShowReactionDetails(false)}
                      />
                    ))
                  )
                ) : (
                  allUniqueReactionPersons &&
                  (allUniqueReactionPersons?.length === 0 ? (
                    <span>{t("clan_discussion_post.no_such_reaction")}</span>
                  ) : (
                    allUniqueReactionPersons?.map(
                      (
                        person: RoleAwareProfileWithImage,
                        itemIndex: number
                      ) => {
                        return (
                          <ClanProfileListItem
                            key={`person_${person.id}_${itemIndex}`}
                            profile={person}
                            onNavigation={() => setShowReactionDetails(false)}
                          />
                        );
                      }
                    )
                  ))
                )
              ) : (
                <span>{t("clan_discussion_post.loading")}</span>
              )}
            </ClanProfileCard>
          </div>
        </ClanIonContent>
        <IonFooter>
          <IonSegment className="w-full bottom-0 bg-clanYellow-100">
            <IonSegmentButton
              className="font-extrabold font-gilroy text-black"
              color="tertiary"
              onClick={() => setShowReactionDetails(false)}
            >
              {t("general.back")}
            </IonSegmentButton>
          </IonSegment>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default ClanReactionsDetailView;
