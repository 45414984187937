import { useAsyncEffect } from "@react-hook/async";
import React, {
  createContext,
  Dispatch,
  ReducerAction,
  ReducerState,
  useEffect,
  useReducer,
  useRef
} from "react";
import { useHistory } from "react-router-dom";

import { analyticsSetUserId } from "../Analytics";
import { configuration } from "../configuration";
import { isNativePlatform } from "../util/AppUtils";
import {
  registerPushNotificationListeners,
  registerPushNotificationToken
} from "../util/ClanPushNotifications";
import RollbarManager from "../util/RollbarManager";
import {
  getAppResetActionPayload,
  getSetAppReadyActionPayload
} from "./app/app.actions";
import { fetchAndDispatchProfile } from "./AppContextState/AppContextProfileState";
import {
  refreshTokenOnLoad,
  useUserIdChangeState
} from "./AppContextState/AppContextUserLoginState";
import { OauthData } from "./oauth/oauthDataService";
import { AppState, initialState, reducers } from "./state";
import { cacheImageData } from "./user/image/imageDataService";

export interface AppContextState {
  state: AppState;
  dispatch: React.Dispatch<any>;
}

export const AppContext = createContext<AppContextState>({
  state: initialState,
  dispatch: () => undefined
});

const logger = (reducer: any) => {
  return (state: any, action: any) => {
    console.log("------------STATE CHANGING-----------");
    console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
    console.log(
      "%cPrevious State:",
      "color: #9E9E9E; font-weight: 700;",
      state
    );
    console.log(
      "%cNext State:",
      "color: #47B04B; font-weight: 700;",
      reducer(state, action)
    );
    console.log("---------STATE CHANGE ENDED---------");
    return reducer(state, action);
  };
};

const loggerReducer = logger(reducers);

const appReducer = configuration.showStoreLogs ? loggerReducer : reducers;
export let store: ReducerState<typeof appReducer>;
export let dispatch: Dispatch<ReducerAction<typeof appReducer>>;

export const AppContextProvider: React.FC = (props) => {
  [store, dispatch] = useReducer(appReducer, initialState);
  const history = useHistory();

  const userIdState = useUserIdChangeState(store.token);

  const refreshTokenOnLoadStatus = useAsyncEffect(
    async () => refreshTokenOnLoad(history, dispatch),
    []
  );

  useEffect(() => {
    registerPushNotificationListeners(history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAsyncEffect(async () => {
    if (userIdState) {
      await analyticsSetUserId(userIdState.toString());

      RollbarManager.addCustomContext({
        person: {
          id: userIdState.toString(),
          username: (store.token as OauthData).accountId
        }
      });
    } else {
      await analyticsSetUserId();
      RollbarManager.addCustomContext({
        person: { id: null, username: null }
      });
    }
  }, [userIdState]);

  useAsyncEffect(async () => {
    if (userIdState === undefined) return;
    if (
      refreshTokenOnLoadStatus.status !== "success" &&
      refreshTokenOnLoadStatus.status !== "error"
    ) {
      return;
    }
    if (!refreshTokenOnLoadStatus.value) {
      return;
    }
    await fetchAndDispatchProfile(dispatch, userIdState !== null);
    if (userIdState === null) {
      await dispatch(getAppResetActionPayload());
    } else {
      await dispatch(getSetAppReadyActionPayload());
    }
  }, [userIdState, refreshTokenOnLoadStatus]);

  // 0 = no, 1 - working, 2 - set. This is state just to prevent spamming push notifications
  const isTokenRegistered = useRef<0 | 1 | 2>(0);
  useEffect(() => {
    if (!isNativePlatform) {
      return;
    }
    if (isTokenRegistered.current === 1) {
      return;
    }
    if (!userIdState) {
      isTokenRegistered.current = 0;
      return;
    }
    if (isTokenRegistered.current === 2) {
      return;
    }
    isTokenRegistered.current = 1;

    registerPushNotificationToken()
      .then(() => {
        console.debug("[AppContext] Push notification initialized");
      })
      .finally(() => {
        isTokenRegistered.current = 2;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdState]);

  const isImagesDataInitializedRun = useRef(true);
  useAsyncEffect(async () => {
    if (isImagesDataInitializedRun.current) {
      isImagesDataInitializedRun.current = false;
      return;
    }
    await cacheImageData(store.userProfileImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.userProfileImage]);

  return (
    <AppContext.Provider
      value={{
        state: store,
        dispatch
      }}
    >
      {props?.children}
    </AppContext.Provider>
  );
};
