import { IonAvatar, IonText } from "@ionic/react";
import React from "react";

import { ReactComponent as CBLogoRoundIcon } from "../icons/cb-logo-round.svg";
import { ReactComponent as CBLogoIcon } from "../icons/cb-logo.svg";

interface ClanInfoCardProps {
  title: string;
  bodyParagraphs?: string[];
  image?: JSX.Element;
}

const ClanInfoCard: React.FC<ClanInfoCardProps> = ({
  title,
  bodyParagraphs,
  image
}) => {
  return (
    <div className="self-center flex flex-col w-full h-full justify-center items-center">
      <CBLogoIcon className="w-48 my-16" />
      <IonAvatar className="h-clanAvatar w-clanAvatar overflow-hidden flex items-center justify-center border-4 border-solid border-clanYellow-100 self-center">
        <div className="rounded-full h-clanCardCircle w-clanCardCircle border-clanYellow-100 border-solid z-10">
          {image || <CBLogoRoundIcon />}
        </div>
      </IonAvatar>
      <IonText className="mt-8 text-center p-4">
        <h2>{title}</h2>
        {bodyParagraphs &&
          bodyParagraphs.map((p, index) => (
            <h6 className="mb-4" key={index}>
              {p}
            </h6>
          ))}
      </IonText>
    </div>
  );
};

export default ClanInfoCard;
