import React from "react";
import { IonButton, IonLabel, IonIcon } from "@ionic/react";
import { closeCircle, happyOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

type MoodButtonProps = {
  mood: string | undefined;
  onClick: () => void;
  onClear: () => void;
};

const MoodButton: React.FC<MoodButtonProps> = ({ mood, onClick, onClear }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center mx-1">
      <IonButton
        style={{
          "--padding-start": "0.5rem",
          "--padding-end": "0.5rem"
        }}
        fill="clear"
        color="dark"
        mode="ios"
        onClick={onClick}
        className="p-0 m-0 cursor-pointer"
      >
        <IonLabel
          className={`m-0 p-0 text-clanP ${
            mood ? "text-clanGreen-100" : "text-clanGray-200"
          }`}
        >
          {mood
            ? t("moods." + mood?.toLowerCase()).toLowerCase()
            : t("planner.mood")}
        </IonLabel>
        <IonIcon
          className={`h-4 p-0 m-0 ${mood ? "text-black" : "text-clanGray-200"}`}
          slot="start"
          icon={`${
            mood ? `/assets/mood/${mood?.toLowerCase()}.svg` : happyOutline
          }`}
        />
      </IonButton>

      {mood && (
        <IonIcon
          icon={closeCircle}
          slot="end"
          className="p-0 h-8 text-clanGray-200 cursor-pointer"
          onClick={onClear}
        />
      )}
    </div>
  );
};

export default MoodButton;
