import {
  ClanEditPostEditDetails,
  ClanPostListResult,
  ClanPostListResultV1
} from "../../util/models/ClanPostModels";

// Workaround to make the new post image show before it's retrievable from backend
export function addPostImageToFeed<
  T extends ClanPostListResult | ClanPostListResultV1
>(feedArray: Array<T>, postsWithNewImages?: ClanEditPostEditDetails[]): T[] {
  return feedArray.map((postInFeed: T) => {
    const matchingPost = postsWithNewImages?.find(
      (postWithLocalImg) => postWithLocalImg.id === postInFeed.id
    );
    return matchingPost !== undefined && matchingPost?.image
      ? {
          ...postInFeed,
          image: {
            thumbnail: { url: matchingPost.image }
          },
          // TODO: duplicating the image as different parts of code have image in different properties, remove when unified
          images: {
            thumbnail: { url: matchingPost.image }
          }
        }
      : postInFeed;
  });
}
