import {
  IonPage,
  IonSearchbar,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import { useAsync } from "@react-hook/async";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";
import styled from "styled-components";

import {
  ActionLink,
  SecondaryActionLink
} from "../../components/Buttons/Button";
import CategoryHeader from "../../components/CategoryHeader";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import { FlexRow } from "../../components/Flexbox";
import FooterButtons, {
  PrimaryFooterButton
} from "../../components/FooterButtons";
import NewGroupModal from "../../components/Group/NewGroupModal";
import Header from "../../components/Headers/Header";
import { Section, SectionContent } from "../../components/Section";
import SettingsButton from "../../components/SettingsButton";
import { H2, H6, H6Style } from "../../components/Typography";
import { connect } from "../../data/connect";
import { OauthData } from "../../data/oauth/oauthDataService";
import { showToast } from "../../data/toasts/toasts.actions";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import * as ROUTES from "../../routes";
import {
  createGroup,
  getLeadGroupSearch,
  GroupDetailsResponse,
  GroupResponse
} from "../../util/api/OrganisationGroupService";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { formatInLocale } from "../../util/DateUtils";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";

const LlamaError = styled.div`
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 10px;
  width: 90%;
  margin: 3.6rem auto 0;
  padding: 1.6rem;
  text-align: center;

  img {
    margin: 0 auto;
    height: 120px;
  }

  p {
    max-width: 80%;
    margin: 0.75rem auto;
  }
`;

const StyledSearchBar = styled(IonSearchbar)`
  background: #fafafa;
  padding: 12px 5%;
  height: 60px;

  --box-shadow: none;
  --border-radius: 25px;

  input {
    line-height: 22px !important;
    padding: 8px 20px 8px 52px !important;
  }

  ion-icon {
    top: 8px !important;
  }
`;

const GroupsList = styled.div`
  margin: 0 auto 0.7rem;
  width: 90%;
`;

const ContextNameBar = styled.div`
  ${H6Style}
  background: #fafafa;
  padding: 12px 5%;
  color: #878396;
`;

const SearchResultList = styled(GroupsList)`
  margin-top: 1.4rem;
`;

const Delimiter = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
`;

const TrialSection = styled(Section)`
  display: grid;
  row-gap: 8px;
  justify-items: flex-start;

  h2 {
    text-transform: uppercase;
    margin: 0;
    white-space: pre;
  }
`;

interface StateProps {
  contextName: string;
  readGroupListAsLead: boolean;
  createGroupEnabled: boolean;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

const GroupSearchApi = getLeadGroupSearch();

const GroupListView: React.FC<
  RouteComponentProps & StateProps & DispatchProps
> = ({
  contextName,
  readGroupListAsLead,
  createGroupEnabled,
  dispatchToast
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [groups, setGroups] = useState<Array<GroupResponse>>([]);
  const [hasMore, setHasMore] = useState(false);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [showNewGroup, setShowNewGroup] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);

  const forwardToGroupManagement = (groupId: number) => () =>
    history.push(ROUTES.GROUP_VIEW.replace(":groupId", groupId.toString()));

  useEffect(() => {
    if (readGroupListAsLead) resetGroupSearch(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  async function resetGroupSearch(query: string) {
    GroupSearchApi.setQueryArgument("query", query || "");
    await GroupSearchApi.reset();
    GroupSearchApi.getNext()
      .then((response) => {
        setGroups(response);
        setInitialDataLoaded(true);
      })
      .then(() => GroupSearchApi.hasNext().then(setHasMore))
      .catch((e) => console.error("Failed to fetch groups I lead", e));
  }

  async function loadNextPage() {
    if (!hasMore) return;
    GroupSearchApi.getNext()
      .then((result) => setGroups([...groups, ...result]))
      .then(() => GroupSearchApi.hasNext().then(setHasMore));
  }

  const onClose = () => history.push(ROUTES.DASHBOARD);

  // TODO: implement loading based on status
  const [createNewGroupStatus, createNewGroup] = useAsync<GroupDetailsResponse>(
    async (name) => {
      closeNewGroupModal();
      return createGroup(name);
    }
  );

  useEffect(() => {
    const status = createNewGroupStatus.status;
    const id = createNewGroupStatus.value?.id;
    if (status === "success" && id) {
      forwardToGroupManagement(id)();
      return;
    }
    if (status === "error" || (status === "success" && !id)) {
      console.error(createNewGroupStatus.error);
      if (!dispatchToast) {
        return;
      }
      dispatchToast(
        t("groups_view.create_group_unavailable"),
        undefined,
        "info",
        [
          {
            label: t("general.cancel")
          },
          {
            label: t("groups_view.get_in_touch"),
            href: "https://www.clanbeat.com/get-in-touch/",
            primary: true
          }
        ]
      );
    }
    // eslint-disable-next-line
  }, [createNewGroupStatus]);

  const openNewGroupModal = () => {
    if (createGroupEnabled) {
      setShowNewGroup(true);
    } else {
      dispatchToast(
        t("groups_view.create_group_unavailable"),
        undefined,
        "info",
        [
          {
            label: t("general.cancel")
          },
          {
            label: t("groups_view.get_in_touch"),
            href: "https://www.clanbeat.com/get-in-touch/",
            primary: true
          }
        ]
      );
    }
  };

  const closeNewGroupModal = () => setShowNewGroup(false);

  const toggleSearch = () => {
    searchVisible ? closeSearch() : openSearch();
  };

  const openSearch = () => {
    setSearchVisible(true);
  };

  const closeSearch = () => {
    setSearchVisible(false);
    setSearch("");
  };

  const calculateTrial = () => {
    const maxGroup = groups.reduce<GroupResponse | undefined>(
      (mGroup, group) => {
        if (!mGroup) {
          return group.groupOrOrganisationPaidOrTrialPaidUntil
            ? group
            : undefined;
        }

        if (
          group.groupOrOrganisationPaidOrTrialPaidUntil &&
          mGroup.groupOrOrganisationPaidOrTrialPaidUntil
        ) {
          return isBefore(
            new Date(mGroup.groupOrOrganisationPaidOrTrialPaidUntil),
            new Date(group.groupOrOrganisationPaidOrTrialPaidUntil)
          )
            ? group
            : mGroup;
        }

        return mGroup;
      },
      undefined
    );

    return getTrialContent(maxGroup);
  };

  const getTrialContent = (group?: GroupResponse) => {
    const {
      groupOrOrganisationPaidOrTrialPaidUntil,
      groupOrOrganisationPaidUntil,
      groupOrOrganisationTrialUntil,
      organisationPaidUntil,
      organisationTrialUntil
    } = group || {};

    const isSubscriptionBased = isPaid(group);

    if (
      !groupOrOrganisationPaidOrTrialPaidUntil ||
      isBefore(new Date(groupOrOrganisationPaidOrTrialPaidUntil), new Date())
    ) {
      return {
        title: t("groups_view.basic_tier"),
        subtitle:
          isSubscriptionBased === null
            ? null
            : isSubscriptionBased
            ? `${t("groups_view.subscription_ended")} ${formatInLocale(
                new Date(groupOrOrganisationPaidUntil || ""),
                "d MMM yyyy"
              )}.`
            : `${t("groups_view.trial_expired")} ${formatInLocale(
                new Date(groupOrOrganisationTrialUntil || ""),
                "d MMM yyyy"
              )}.`,
        showSub: true
      };
    } else {
      if (isSubscriptionBased && groupOrOrganisationPaidUntil) {
        return {
          title: `${t("groups_view.subscription")}:\n${
            groupOrOrganisationPaidUntil === organisationPaidUntil
              ? t("groups_view.connected_groups")
              : t("groups_view.single_group")
          }`,
          subtitle: `${t("groups_view.next_billing")} ${formatInLocale(
            new Date(groupOrOrganisationPaidUntil),
            "d MMM yyyy"
          )}.`,
          showCancel: true,
          showUpgrade: groupOrOrganisationPaidUntil !== organisationPaidUntil
        };
      } else if (groupOrOrganisationTrialUntil) {
        return {
          title: `${t("groups_view.trial")}:\n${
            groupOrOrganisationTrialUntil === organisationTrialUntil
              ? t("groups_view.connected_groups")
              : t("groups_view.single_group")
          }`,
          subtitle: `${t("groups_view.trial_expires")} ${formatInLocale(
            new Date(groupOrOrganisationTrialUntil),
            "d MMM yyyy"
          )}.`,
          showSub: true
        };
      }
    }
  };

  const isPaid = (group?: GroupResponse) => {
    if (group) {
      if (
        group.groupOrOrganisationPaidUntil &&
        group.groupOrOrganisationTrialUntil
      ) {
        return !isAfter(
          new Date(group.groupOrOrganisationTrialUntil),
          new Date(group.groupOrOrganisationPaidUntil)
        );
      } else if (group.groupOrOrganisationPaidUntil) {
        return true;
      } else if (group.groupOrOrganisationTrialUntil) {
        return false;
      }
    }

    return null;
  };

  const trialData = calculateTrial();

  return (
    <ClanDesktopLayout>
      <IonPage>
        {showNewGroup && (
          <NewGroupModal
            onSubmit={createNewGroup}
            onClose={closeNewGroupModal}
          />
        )}
        <ClanIonContent>
          <Header
            title={t("groups_view.title")}
            onBack={onClose}
            actions={<SearchIcon onClick={toggleSearch} />}
          />

          <ContextNameBar>{contextName}</ContextNameBar>

          {searchVisible && (
            <StyledSearchBar
              placeholder={t("general.search")}
              debounce={250}
              value={search || ""}
              onIonChange={(e) => setSearch(e.detail.value || "")}
            />
          )}

          {!searchVisible && !!trialData && initialDataLoaded && (
            <TrialSection>
              <H2>{trialData.title}</H2>
              <Delimiter />
              {trialData.subtitle && <H6>{trialData.subtitle}</H6>}
              <FlexRow align="center" justify="start" columnGap="8px">
                {trialData.showCancel && (
                  <SecondaryActionLink
                    target="_blank"
                    href="mailto:support@clabeat.com?subject=My%20subscription&body=Email%20or%20phone%20associated%20with%20my%20Clanbeat%20account%3A"
                  >
                    {t("general.cancel")}
                  </SecondaryActionLink>
                )}
                {trialData.showUpgrade && (
                  <ActionLink
                    target="_blank"
                    href="mailto:support@clabeat.com?subject=My%20subscription&body=Email%20or%20phone%20associated%20with%20my%20Clanbeat%20account%3A"
                  >
                    {t("paywall.upgrade")}
                  </ActionLink>
                )}
                {trialData.showSub && (
                  <ActionLink
                    target="_blank"
                    href="mailto:support@clabeat.com?subject=My%20subscription&body=Email%20or%20phone%20associated%20with%20my%20Clanbeat%20account%3A"
                  >
                    {t("paywall.subscribe")}
                  </ActionLink>
                )}
              </FlexRow>
            </TrialSection>
          )}

          {!readGroupListAsLead && (
            <LlamaError>
              <img
                src="/assets/survey/llama.svg"
                alt={t("groups_view.no_lead_rights")}
              />
              <p>{t("groups_view.no_lead_rights")}</p>
            </LlamaError>
          )}

          {readGroupListAsLead &&
            (searchVisible || !!search ? (
              <SearchResultList>
                {groups.length > 0 ? (
                  groups.map(({ id, name }) => (
                    <SettingsButton
                      key={id}
                      title={name}
                      onClick={forwardToGroupManagement(id)}
                    />
                  ))
                ) : (
                  <p>{t("groups_view.no_groups_found")}</p>
                )}
              </SearchResultList>
            ) : (
              <>
                <CategoryHeader title={t("groups_view.groups_I_lead")} />
                {groups.length > 0 ? (
                  <SectionContent>
                    {groups.map(({ id, name }) => (
                      <SettingsButton
                        key={id}
                        title={name}
                        onClick={forwardToGroupManagement(id)}
                      />
                    ))}
                    <IonSegment>
                      <IonSegmentButton
                        className="font-extrabold font-gilroy text-clanGreen-100"
                        disabled={!hasMore}
                        onClick={loadNextPage}
                      >
                        {hasMore
                          ? t("general.list_load_more")
                          : t("general.list_end")}
                      </IonSegmentButton>
                    </IonSegment>
                  </SectionContent>
                ) : (
                  initialDataLoaded && (
                    <SectionContent>
                      <p>{t("groups_view.no_groups_found")}</p>
                    </SectionContent>
                  )
                )}
              </>
            ))}
        </ClanIonContent>

        {createGroupEnabled && (
          <FooterButtons>
            <PrimaryFooterButton onClick={openNewGroupModal}>
              {t("groups_view.create_group")}
            </PrimaryFooterButton>
          </FooterButtons>
        )}
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: ({ token }) => ({
    contextName: (token as OauthData)?.contextName,
    readGroupListAsLead: UserEnabledProperties.Group.readGroupListAsLead(token),
    createGroupEnabled: UserEnabledProperties.Group.createGroup(token)
  }),
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: GroupListView
});
