import { IonCheckbox, IonContent, IonInput, IonRouterLink } from "@ionic/react";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";

import { SmoothRaysInverted } from "../../components/ClanSmoothRays";
import FooterButtons, { FooterButton } from "../../components/FooterButtons";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import { authenticateWithFirebaseUser } from "../../util/api/Authentication";
import {
  getFirebaseIdToken,
  logoutFromFirebase
} from "../../util/GoogleFirebase";
import RollbarManager from "../../util/RollbarManager";
import {
  BackButton,
  StartContentWrapper,
  StartDescription,
  StartTitle,
  StartTitleWrapper,
  StartToolbar,
  StartWrapper
} from "./Start";

const StyledInput = styled(IonInput)`
  --padding-bottom: 0;
  --padding-start: 0;
`;

const PrivacyWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px 10px;
  align-items: center;
  margin: 20px 0;
`;

const StyledCheckbox = styled(IonCheckbox)`
  --border-color: black;
  --ion-color-success: black;
  --checkmark-color: white;
  --checkmark-width: 2px;
`;

const PrivacyLink = styled(IonRouterLink)`
  font-family: Gilroy;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: black;
`;

interface PrivacyProps {
  privacyChecked: boolean;
  setPrivacyChecked: (checked: boolean) => void;
  termsChecked: boolean;
  setTermsChecked: (checked: boolean) => void;
}

export const Privacy: React.FC<PrivacyProps> = ({
  privacyChecked,
  setPrivacyChecked,
  termsChecked,
  setTermsChecked
}) => {
  const { t } = useTranslation();
  return (
    <PrivacyWrapper>
      <StyledCheckbox
        checked={privacyChecked}
        slot="end"
        mode="ios"
        color="success"
        onIonChange={(e) => setPrivacyChecked(e.detail.checked)}
      />
      <PrivacyLink
        href="https://www.clanbeat.com/privacy/"
        color="success"
        target="_blank"
      >
        {t("start.privacy.privacy_policy")}
      </PrivacyLink>

      <StyledCheckbox
        checked={termsChecked}
        slot="end"
        mode="ios"
        color="success"
        onIonChange={(e) => setTermsChecked(e.detail.checked)}
      />
      <PrivacyLink
        href="https://www.clanbeat.com/legal/"
        color="success"
        target="_blank"
      >
        {t("start.privacy.terms")}
      </PrivacyLink>
    </PrivacyWrapper>
  );
};

interface OwnProps {
  setLoading: (loading: boolean) => void;
  resetStartState: () => void;
  loading: boolean;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface StudentJoinCodeProps extends OwnProps, DispatchProps {}

const StudentJoinCode: React.FC<StudentJoinCodeProps> = ({
  setLoading,
  resetStartState,
  dispatchToast,
  loading
}) => {
  const { getValues, trigger, errors, watch, control } = useFormContext();
  const watchInviteHash = watch("inviteHash");
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = async () => {
    if (privacyChecked && termsChecked) {
      if (await trigger("inviteHash")) {
        const firebaseToken = await getFirebaseIdToken();
        if (!firebaseToken) {
          dispatchToast(t("start.join_code.something_wrong"));
          RollbarManager.logError(
            new Error(
              "[START_PAGE_SIGNUP] User has not signed in with firebase"
            )
          );
          return;
        }
        try {
          setLoading(true);
          await authenticateWithFirebaseUser(
            history,
            firebaseToken,
            false,
            getValues().inviteHash
          );
        } catch (authError) {
          dispatchToast(t("start.join_code.auth_error"));
          RollbarManager.logError(authError as Error);
        } finally {
          setLoading(false);
        }
      } else {
        dispatchToast(
          `Error - ${Object.keys(errors).reduce((allErrors, key) => {
            return allErrors.concat(errors[key].message);
          }, "")}`
        );
      }
    } else {
      dispatchToast(t("start.privacy.accept_error"));
    }
  };

  return (
    <>
      <IonContent>
        <StartWrapper>
          <StartToolbar id="dashboard-header">
            <SmoothRaysInverted />
            <BackButton
              src="/assets/arrow_back_black.svg"
              onClick={() => logoutFromFirebase().then(resetStartState)}
            />
          </StartToolbar>

          <StartContentWrapper center>
            <StartTitleWrapper>
              <StartTitle>
                <Controller
                  control={control}
                  render={({ onChange, value }) => (
                    <StyledInput
                      value={value}
                      onIonChange={(e) => onChange(e.detail.value)}
                      className="inviteHash text-clanH2 text-black font-extrabold font-gilroy tracking-extrawide uppercase"
                      placeholder={t("start.join_code.placeholder")}
                      maxlength={8}
                      type="text"
                      autocapitalize="characters"
                      required
                    />
                  )}
                  name="inviteHash"
                  defaultValue={watchInviteHash}
                  rules={{
                    required: {
                      value: true,
                      message: t("start.join_code.validation_required")
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9]{8}$/,
                      message: t("start.join_code.validation_invalid")
                    }
                  }}
                />
              </StartTitle>
              <StartDescription>
                {t("start.join_code.description")}
              </StartDescription>
            </StartTitleWrapper>

            <Privacy
              privacyChecked={privacyChecked}
              termsChecked={termsChecked}
              setPrivacyChecked={setPrivacyChecked}
              setTermsChecked={setTermsChecked}
            />
          </StartContentWrapper>
        </StartWrapper>
      </IonContent>
      <FooterButtons>
        <FooterButton disabled={loading || !watchInviteHash} onClick={onSubmit}>
          {t("general.next")}
        </FooterButton>
      </FooterButtons>
    </>
  );
};

export default connect<OwnProps, DispatchProps>({
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: StudentJoinCode
});
