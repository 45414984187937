import {
  ActionPerformed as LocalActionPerformed,
  LocalNotifications,
  LocalNotificationSchema,
  Schedule
} from "@capacitor/local-notifications";
import {
  ActionPerformed as PushActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token
} from "@capacitor/push-notifications";
import { History } from "history";

import { doRequest } from "../apiService";
import {
  ActivityFeedTopic,
  navigateOnActivity
} from "./api/ActivityFeedNavigationService";
import { isNativePlatform } from "./AppUtils";
import { BlockingPromiseQueue } from "./BlockingPromiseQueue";
import RollbarManager from "./RollbarManager";

interface PushNotificationProps {
  id: string;
  title: string | undefined;
  body: string | undefined;
}

export const registerPushNotificationListeners = (history: History) => {
  if (!isNativePlatform) {
    return;
  }

  PushNotifications.addListener("registration", (pushDeviceToken: Token) => {
    subscribePushNotificationToken(pushDeviceToken.value);
  });

  PushNotifications.addListener("registrationError", (pushError) => {
    RollbarManager.logError(pushError);
  });

  PushNotifications.addListener(
    "pushNotificationReceived",
    (notification: PushNotificationSchema) => {
      const notificationData: PushNotificationProps = {
        id: notification.id,
        title: notification.title,
        body: notification.body
      };
      // If app is in foreground turn push into local notification (unless user is in direct chat view and receiving direct chat notifications)
      if (
        history.location.pathname.includes("directmessage") &&
        notification.data.topic === ActivityFeedTopic.DIRECT_MESSAGE
      ) {
        return;
      } else if (notification.body || notification.title) {
        triggerLocalNotification(
          notification.body,
          notification.title,
          notification.data
        );
      }

      return notificationData;
    }
  );

  // This is called when push notification is clicked
  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification: PushActionPerformed | any) => {
      navigateOnActivity(history, notification.notification.data);
    }
  );

  LocalNotifications.addListener(
    "localNotificationActionPerformed",
    (notification: LocalActionPerformed | any) => {
      navigateOnActivity(history, notification.notification.data);
    }
  );
};

export const removePushNotificationListeners = async () => {
  if (isNativePlatform) {
    return PushNotifications.removeAllListeners();
  }
};

const subscribePushNotificationToken = async (token: string) => {
  try {
    await doRequest("post", "user/notification/v1/firebase/subscribe", {
      token
    });
  } catch (subError) {
    console.error(
      "[PUSH NOTIFICATION]: push token subscription error",
      subError
    );
  }
};

const _registerPushNotificationToken = async () => {
  try {
    const permissionResult = await PushNotifications.requestPermissions();

    if (permissionResult.receive === "granted") {
      // Register with Apple / Google to receive push via APNS/FCM
      await PushNotifications.register();
    } else {
      console.error("[PUSH NOTIFICATION]: push token registration error");
    }
  } catch (error) {
    console.error("[PUSH NOTIFICATIONS]: push Token error", error);
  }
};

const pushNotificationJobQueue = new BlockingPromiseQueue();

export const registerPushNotificationToken = async () => {
  if (!isNativePlatform) {
    return;
  }

  return pushNotificationJobQueue.blockAndRun(async () =>
    _registerPushNotificationToken()
  );
};

export const triggerLocalNotification = async (
  body?: string,
  title?: string,
  data?: {},
  schedule?: Schedule
) =>
  LocalNotifications.schedule({
    notifications: [
      {
        ...(title ? { title } : { title: "" }),
        ...(body ? { body } : { body: "" }),
        ...(data && { data }),
        id: Math.floor(Math.random() * 10),
        schedule: schedule || { at: new Date(Date.now() + 1000) }
      } as LocalNotificationSchema
    ]
  });
