import { IonPage } from "@ionic/react";
import { useAsyncEffect } from "@react-hook/async";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";

import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import LargeTabs, { useLargeTabs } from "../../components/ClanLargeTabs";
import ClanLoader from "../../components/ClanLoader";
import FooterButtons, {
  PrimaryFooterButton,
  SecondaryFooterButton
} from "../../components/FooterButtons";
import ProfileMeta from "../../components/Profile/ProfileMeta";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import { ReactComponent as ProfileBgImage } from "../../icons/profile-bg.svg";
import * as ROUTES from "../../routes";
import { getUserProfileById } from "../../util/api/ProfileService";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import {
  isReadyStateCancelOrFail,
  isReadyStateLoading,
  isReadyStatePending
} from "../../util/AsyncEffectFilter";
import { ClanRoleAwareProfileResponse } from "../../util/models/ClanProfileModels";
import { mapSocialLinks } from "../../util/SocialLinks";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import PublicProfileAbout from "./PublicProfileAbout";
import PublicProfileFeed from "./PublicProfileFeed";

export enum PublicProfileTabs {
  InsightsTab = "Insights",
  AboutTab = "About",
  TrendsTab = "Trends"
}

type OwnProps = RouteComponentProps;

interface StateProps {
  isTeacher: boolean;
  canSeePublicWellbeing: boolean;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface PublicProfileProps extends OwnProps, StateProps, DispatchProps {}

const PublicProfile: React.FC<PublicProfileProps> = ({
  isTeacher,
  dispatchToast,
  canSeePublicWellbeing
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { profileId } = useParams<{ profileId: string }>();

  const [activeTab] = useLargeTabs(PublicProfileTabs);

  const [user, setUser] = useState<ClanRoleAwareProfileResponse | null>(null);

  const fetchStatus = useAsyncEffect(async () => {
    try {
      const profileResponse = await getUserProfileById(profileId);
      if (!profileResponse || isReadyStateCancelOrFail(fetchStatus)) return;
      setUser(profileResponse);
    } catch (e) {
      dispatchToast(e);
      console.error("[FEED] Couldn't load user profile", e);
    }
    // TODO errors are swallowed up
  }, []);

  const openProfileChat = () =>
    history.push(
      ROUTES.DIRECT_MESSAGE.replace(":profile_id", profileId.toString())
    );

  const openTrendsPage = () =>
    history.push(
      ROUTES.PROFILE_TRENDS.replace(":profileId", profileId.toString())
    );

  return (
    <ClanDesktopLayout>
      <ClanLoader
        message={t("general.loading")}
        showLoading={isReadyStateLoading(fetchStatus)}
      />
      <IonPage>
        {user && isReadyStatePending(fetchStatus) && (
          <>
            <ClanIonContent>
              <ProfileBgImage className="w-full -mb-32" />

              <ProfileMeta
                fullName={user.fullName}
                profileImage={user.images?.full?.url}
                dateOfBirth={user.dateOfBirth}
                nationalities={user.nationalities}
                languages={user.languages}
                isTeacher={UserEnabledProperties.UserStatus.hasTeacherBadge(
                  user
                )}
              />

              <LargeTabs
                type={PublicProfileTabs}
                tabs={[
                  {
                    key: PublicProfileTabs.InsightsTab,
                    title: t("profile.insights"),
                    icon: "/assets/profile-insights.svg",
                    activeIcon: "/assets/profile-insights-white.svg"
                  },
                  {
                    key: PublicProfileTabs.AboutTab,
                    title: t("profile.about"),
                    icon: "/assets/about.svg",
                    activeIcon: "/assets/about-white.svg"
                  },
                  ...(isTeacher && !UserEnabledProperties.Legacy.isAdult(user)
                    ? [
                        {
                          key: PublicProfileTabs.TrendsTab,
                          title: t("profile.trends"),
                          icon: "/assets/insights.svg",
                          activeIcon: "/assets/insights-white.svg",
                          onClick: openTrendsPage
                        }
                      ]
                    : [])
                ]}
              />

              {activeTab === PublicProfileTabs.InsightsTab && (
                <PublicProfileFeed profileId={profileId} />
              )}

              {activeTab === PublicProfileTabs.AboutTab && (
                <PublicProfileAbout
                  interests={user?.interests || []}
                  bucketList={user?.bucketList || []}
                  socialContacts={mapSocialLinks(user?.socialLinks)}
                />
              )}
            </ClanIonContent>
            <FooterButtons>
              <SecondaryFooterButton onClick={() => history.goBack()}>
                {t("general.back")}
              </SecondaryFooterButton>
              <PrimaryFooterButton onClick={openProfileChat}>
                {t("profile.message")}
              </PrimaryFooterButton>
            </FooterButtons>
          </>
        )}
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    isTeacher: UserEnabledProperties.Legacy.isAdult(state.token),
    canSeePublicWellbeing: UserEnabledProperties.WellBeing.othersProfile(
      state.token
    )
  }),
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: PublicProfile
});
