import { IonImg } from "@ionic/react";
import React from "react";
import styled from "styled-components";

import { ChartResultFlagWithProfiles } from "../../util/Analytics";
import ListItem from "./ListItem";

const Label = styled.span<{ color?: string }>`
  white-space: normal;
  font-size: 1rem;
  line-height: 1.29;

  b {
    color: ${({ color }) => (color === "RED" ? "#FF6471" : "#45DD85")};
  }
`;

const FlagIndicator = styled(IonImg)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.8rem;
`;

interface FlagListItemProps {
  flag: ChartResultFlagWithProfiles;
  onClick?: () => void;
}

const FlagListItem: React.FC<FlagListItemProps> = ({ flag, onClick }) => {
  const { message, flag: flagType } = flag;

  return (
    <ListItem
      primary={
        <Label
          color={flagType}
          dangerouslySetInnerHTML={{
            __html: message
          }}
        />
      }
      icon={
        <FlagIndicator
          src={`/assets/flag-${flagType === "RED" ? "red" : "green"}.svg`}
        />
      }
      onClick={onClick}
    />
  );
};

export default FlagListItem;
