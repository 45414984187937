import {
  IonCheckbox,
  IonContent,
  IonPage,
  IonSearchbar,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import styled from "styled-components";

import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import { SmoothRays } from "../../components/ClanSmoothRays";
import TabFilter from "../../components/TabFilter";
import FooterButtons, {
  PrimaryFooterButton
} from "../../components/FooterButtons";
import SubPageHeader from "../../components/SubPageHeader";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import * as ROUTES from "../../routes";
import {
  addMembersToGroup,
  getMembersToAddToGroupSearch
} from "../../util/api/OrganisationGroupService";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import {
  getConstantFilters,
  ViewFilterConfigurationItem,
  ViewFilters,
  Views
} from "../../util/Configuration";
import { SimpleProfile } from "../../util/models/ClanProfileModels";
import { PaginatedStreamDataIterator } from "../../util/ResultDataIterator";

const SearchableSubPageHeader = styled(SubPageHeader)`
  padding-bottom: 0.4rem;
`;

const StyledSearchBar = styled(IonSearchbar)`
  padding: 0;
  margin: 0 auto 0.7rem;
  width: 90%;
  height: 36px;
`;

const StyledTabFilter = styled(TabFilter)`
  padding: 0;
  text-transform: uppercase;

  > div {
    padding-top: 1.4rem;
  }
`;

const SearchResultsWrapper = styled.div`
  padding-top: 0.6rem;
`;

const StyledMemberItem = styled.div`
  background: #fafafa;
  border-radius: 1.9rem;
  margin: 0 0.75rem 0.4rem;
  padding: 0.7rem 1.1em;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Avatar = styled.img`
  width: 2.25rem;
  height: 2.25rem;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.primary};
  margin: 0;
  display: block;
`;
const MemberName = styled.div`
  margin-left: 10px;
  flex: 1;
  font-family: Gilroy;
  text-transform: uppercase;
`;

interface AddMemberItemProps {
  name: string;
  thumbnail?: string;
  isAdded: boolean;
  onToggle: (isAdded: boolean) => void;
  isTeacher?: boolean;
}

const AddMemberItem: React.FC<AddMemberItemProps> = ({
  thumbnail,
  name,
  isAdded,
  onToggle
}) => {
  const onMemberClicked = () => onToggle(!isAdded);

  return (
    <StyledMemberItem onClick={onMemberClicked}>
      <Avatar src={thumbnail || "/assets/navigation/profile.svg"} />
      <MemberName>{name}</MemberName>
      <IonCheckbox checked={isAdded} mode="ios" color="success" />
    </StyledMemberItem>
  );
};

interface StateProps {
  manageMembers: boolean;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

let SearchApi: PaginatedStreamDataIterator<SimpleProfile>;

const GroupAddMembersView: React.FC<
  RouteComponentProps & StateProps & DispatchProps
> = ({ dispatchToast, manageMembers }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { groupId } = useParams<{ groupId: string }>();

  const [search, setSearch] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Array<SimpleProfile>>([]);
  const [initialSearchDone, setInitialSearchDone] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const [constantFilters, setConstantFilters] = useState<
    Array<ViewFilterConfigurationItem>
  >([]);
  const [activeFilter, setActiveFilter] = useState<string>("");
  const [usersToAdd, setUsersToAdd] = useState<Array<number>>([]);

  function buildApiForFilter(filter: string) {
    const api = getMembersToAddToGroupSearch(
      Number(groupId),
      Views.GroupMembers,
      filter
    );
    api.setQueryArgument("query", search || "");
    return api;
  }

  useEffect(() => {
    getConstantFilters(Views.GroupMembers)
      .then((filters) => {
        setConstantFilters(
          filters.filters.filter(
            (filter) => filter.filter !== ViewFilters.GroupsMembership
          )
        );
        onFilterChange(filters.filters[0].filter);
      })
      .catch((e) => console.error("Failed to fetch constant filters", e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function resetSearchApi(query: string) {
    if (!SearchApi) return;
    SearchApi.setQueryArgument("query", query || "");
    await SearchApi.reset();
    SearchApi.getNext()
      .then((response) => {
        setSearchResults(response);
        setInitialSearchDone(true);
      })
      .then(() => SearchApi.hasNext().then(setHasMore))
      .catch((e) => console.error("Failed to fetch groups I lead", e));
  }

  async function loadNextPage() {
    if (!hasMore || !SearchApi) return;
    SearchApi.getNext()
      .then((result) => setSearchResults(searchResults.concat(result)))
      .then(() => SearchApi.hasNext().then(setHasMore));
  }

  const onSearchChange = (e: any) => {
    setSearch(e.target.value || "");
    if (SearchApi) resetSearchApi(e.target.value);
  };

  const onClose = () =>
    history.push(ROUTES.GROUP_VIEW.replace(":groupId", groupId.toString()));

  const addUsersToGroup = () => {
    if (!manageMembers) {
      return dispatchToast(
        t("group_add_members.member_management_unavailable"),
        undefined,
        "info",
        [
          {
            label: t("general.cancel")
          },
          {
            label: t("groups_view.get_in_touch"),
            href: "https://www.clanbeat.com/get-in-touch/",
            primary: true
          }
        ]
      );
    } else if (usersToAdd.length === 0) {
      return dispatchToast(t("group_add_members.no_members_selected"));
    }

    addMembersToGroup(Number(groupId), usersToAdd)
      .then(() => {
        history.push(ROUTES.GROUP_VIEW.replace(":groupId", groupId.toString()));
      })
      .catch((e) => console.error("Failed to add members to group", e));
  };

  const onFilterChange = (newFilter: any) => {
    setActiveFilter(newFilter);
    SearchApi = buildApiForFilter(newFilter);
    resetSearchApi(search);
  };

  const toggleUserInList = (id: number) => () =>
    setUsersToAdd(
      usersToAdd.includes(id)
        ? usersToAdd.filter((userId) => userId !== id)
        : usersToAdd.concat([id])
    );

  return (
    <ClanDesktopLayout>
      <IonPage>
        <IonContent>
          <SmoothRays />

          <SearchableSubPageHeader
            title={t("group_add_members.title")}
            onBack={onClose}
          >
            <StyledSearchBar
              placeholder={t("general.search")}
              debounce={250}
              value={search || ""}
              onIonChange={onSearchChange}
            />
          </SearchableSubPageHeader>

          <StyledTabFilter
            isKeyBased
            translated
            active={activeFilter}
            options={constantFilters.reduce(
              (allFilters, filter) => ({
                ...allFilters,
                [filter.filter]: filter.label
              }),
              {}
            )}
            onChange={onFilterChange}
          />

          <SearchResultsWrapper>
            {searchResults.map((member) => (
              <AddMemberItem
                key={member.userId}
                name={member.name}
                thumbnail={member.images?.thumbnail?.url}
                isAdded={usersToAdd.includes(member.userId)}
                onToggle={toggleUserInList(member.userId)}
              />
            ))}
            {initialSearchDone && (
              <IonSegment>
                <IonSegmentButton
                  className="font-extrabold font-gilroy text-clanGreen-100"
                  disabled={!hasMore}
                  onClick={loadNextPage}
                >
                  {hasMore
                    ? t("general.list_load_more")
                    : t("general.list_end")}
                </IonSegmentButton>
              </IonSegment>
            )}
          </SearchResultsWrapper>
        </IonContent>
        <FooterButtons>
          <PrimaryFooterButton onClick={addUsersToGroup}>
            {t("general.add")}
          </PrimaryFooterButton>
        </FooterButtons>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: ({ token }) => ({
    manageMembers: UserEnabledProperties.ManageGroup.manageMembers(token)
  }),
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: GroupAddMembersView
});
