import { Toast, ToastButton } from "./toasts.state";
import { Dispatch } from "react";
import { ToastAction } from "./toasts.reducer";
import i18n from "../../i18n";

export const SHOW_TOAST = "show-toast";
export const REMOVE_TOAST = "remove-toast";
export const DEFAULT_TIMEOUT = 3000;
export const AVG_CHARS_IN_WORD = 5; // average number of characters in English words;
export const AVG_READ_SPEED = 3.3; // words per second

export const showToast = (
  message: string,
  duration: number = message
    ? (message.length / AVG_CHARS_IN_WORD / AVG_READ_SPEED) * 1000
    : DEFAULT_TIMEOUT,
  type = "toast",
  buttons = [] as ToastButton[],
  label = i18n.t("general.close")
) => (dispatch: Dispatch<any>) => {
  const messageString = message
    ? message.toString()
    : "Error! Oh snap! Something went wrong!";
  dispatch({
    type: SHOW_TOAST,
    data: { message: messageString, duration, type, buttons, label } as Toast
  } as ToastAction);
};

export const removeToast = (toast: Toast) => (dispatch: Dispatch<any>) => {
  dispatch({ type: REMOVE_TOAST, data: toast } as ToastAction);
};
