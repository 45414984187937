import { IonCol } from "@ionic/react";
import React from "react";
import styled from "styled-components";

import { LargeSectionHeading, Paragraph, SectionContent } from "./Section";

const StyledParagraph = styled(Paragraph)`
  white-space: pre-wrap;
`;

interface ClanFlowStepTemplateProps {
  title: string;
  subtitle?: string;
  headerClassName?: string;
  contentClassName?: string;
  noContentMargin?: boolean;
}

const ClanFlowStepTemplate: React.FC<ClanFlowStepTemplateProps> = ({
  title,
  subtitle,
  headerClassName = "",
  contentClassName = "",
  noContentMargin = false,
  children
}) => {
  return (
    <IonCol
      size="12"
      className={`w-full mt-clanFlowHeaderTop ${headerClassName}`}
    >
      {title.length > 0 && <LargeSectionHeading>{title}</LargeSectionHeading>}

      <SectionContent>
        {subtitle && subtitle.length > 0 && (
          <StyledParagraph>{subtitle}</StyledParagraph>
        )}
        <div
          className={`"w-full ${
            noContentMargin ? "" : "mt-clanFlowContentTop"
          } ${contentClassName}`}
        >
          <div>{children}</div>
        </div>
      </SectionContent>
    </IonCol>
  );
};

export default ClanFlowStepTemplate;
