import { useEffect, useState } from "react";
import { FeedTypes } from "../../Feeds/types/FeedTypes.d";
import { ViewFilterConfigurationItem, Views } from "../../Configuration";
import { FilterGroupType, ResolvedFilters } from "../types/index.d";
import { DefaultFilter, useViewFilterResult } from "./ResolveFilterOrSharing";

export const useFilterResult = (
  feedType?: FeedTypes,
  viewFilter?: Views,
  defaultFilters?: Array<FilterGroupType>,
  filtersChangeCB?: (newFilters: Array<FilterGroupType>) => void
): [
  FilterGroupType[],
  (values: FilterGroupType[]) => void,
  ResolvedFilters<ViewFilterConfigurationItem>
] => {
  const filterResult = useViewFilterResult(feedType, viewFilter);
  const [filters, setFilters] = useState<Array<FilterGroupType>>([]);

  const onFiltersChange = (newFilters: Array<FilterGroupType>) => {
    setFilters(newFilters);
    !!filtersChangeCB && filtersChangeCB(newFilters);
  };

  useEffect(() => {
    if (defaultFilters && defaultFilters.length > 0) {
      const hasInvalidFilters = defaultFilters.some(
        (filter) =>
          ![
            ...(filterResult.defaultFilter ? [filterResult.defaultFilter] : []),
            ...filterResult.groupsFilters,
            ...filterResult.userFilters
          ].some((allowedFilter) => filter.type === allowedFilter.type)
      );

      if (!hasInvalidFilters) {
        return setFilters(defaultFilters);
      }
    }

    if ((filterResult as DefaultFilter<any, any>).isDefault) {
      if (filters.length !== 0) {
        onFiltersChange([]);
      }
      return;
    }

    if (filterResult.defaultFilter) {
      onFiltersChange([filterResult.defaultFilter]);
    } else if (filters.length !== 0) {
      onFiltersChange([]);
    }
    // eslint-disable-next-line
  }, [filterResult]);
  return [filters, onFiltersChange, filterResult];
};
