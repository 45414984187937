import {
  ClanEditPostEditDetails,
  ClanPostListResultReplies,
  ClanPostListResultV1,
  ClanPostResultDetails,
  ClanPostResultDetailsV3,
  ClanReactionsDetails
} from "./models/ClanPostModels";
import { doRequest, doRequestAndReturnResult } from "../apiService";
import { ReactionTypes } from "./Reactions";
import { PaginatedStreamDataIterator } from "./ResultDataIterator";
import { analyticsLogEvent } from "../Analytics";
import { RoleAwareProfileWithImage } from "./models/ClanProfileModels";

export const addPersonalPost = async (
  post: ClanEditPostEditDetails
): Promise<ClanPostResultDetails> => {
  analyticsLogEvent("POST_personal");
  return doRequestAndReturnResult("post", "/feed/v1/personal", { ...post });
};

export const addQuestion = async (
  post: ClanEditPostEditDetails,
  taskId?: string
): Promise<ClanPostResultDetails> => {
  analyticsLogEvent("POST_question");
  return doRequestAndReturnResult("post", "/feed/v1/question", {
    ...{ taskId },
    ...post
  });
};

export const getPostById = async (
  postId: string
): Promise<ClanPostResultDetailsV3> => {
  analyticsLogEvent("POST_load");
  return doRequestAndReturnResult("get", `/feed/v3/${postId}`);
};

export const deletePostById = async (postId: string) => {
  analyticsLogEvent("POST_delete");
  doRequest("delete", `/feed/v2/${postId}`);
};

export const getReactionsByPostId = async (
  postId: string
): Promise<ClanReactionsDetails> => {
  analyticsLogEvent("POST_reactions_show");
  return doRequestAndReturnResult("get", `/feed/v3/reactions/${postId}`);
};

export const getPostSharedWithByPostId = async (
  postId: string
): Promise<RoleAwareProfileWithImage[]> => {
  analyticsLogEvent("POST_shared_with_show");
  return doRequestAndReturnResult("get", `/feed/v3/shared/${postId}`);
};

export const reportPost = async (postId: string, description: string) => {
  analyticsLogEvent("POST_report");
  return doRequest("post", `/feed/v2/${postId}/report`, { description });
};

export const addReactionToPost = async (
  postId: string,
  reaction: string
): Promise<ClanPostResultDetails> => {
  analyticsLogEvent("POST_react");
  return doRequestAndReturnResult(
    "put",
    `/feed/v2/${postId}/like`,
    {},
    { reaction }
  );
};

export const removeReactionFromPost = async (
  postId: string,
  reaction: ReactionTypes
): Promise<ClanPostResultDetails> => {
  analyticsLogEvent("POST_react_remove");
  return doRequestAndReturnResult(
    "delete",
    `/feed/v2/${postId}/like`,
    {},
    { reaction }
  );
};

export const removeImageFromPost = async (
  postId: string
): Promise<ClanPostResultDetails> =>
  doRequestAndReturnResult("delete", `/feed/v2/${postId}/image`);

export const removeMoodFromPost = async (
  postId: string
): Promise<ClanPostResultDetails> =>
  doRequestAndReturnResult("delete", `/feed/v2/${postId}/mood`);

export const addCommentToPost = async (
  postId: string,
  comment: ClanEditPostEditDetails
): Promise<ClanPostResultDetails> => {
  analyticsLogEvent("POST_comment");
  return doRequestAndReturnResult(
    "post",
    `/feed/v2/${postId}/comment`,
    comment
  );
};

export const getCommentsFeedAPIByPostId = (postId: string) => {
  return new PaginatedStreamDataIterator<ClanPostListResultReplies>(
    `/feed/v3/replies/${postId}`
  );
};

export const getSharedPrivatePostsByProfileId = async (
  profileId: string
): Promise<Array<ClanPostListResultV1>> => {
  analyticsLogEvent("POST_private_profile_shared");
  return doRequestAndReturnResult(
    "get",
    `/feed/v1/personal/profile/${profileId}/shared`
  );
};

export const addReflectionToTask = async (
  taskId: string,
  post: ClanEditPostEditDetails
): Promise<ClanPostResultDetails> => {
  analyticsLogEvent("TASK_reflect");
  return doRequestAndReturnResult(
    "post",
    `/feed/v1/task/${taskId}/reflection`,
    { ...post }
  );
};
