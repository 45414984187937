import React from "react";
import styled from "styled-components";

const Badge = styled.div<{ size: number; bg?: string; onClick?: any }>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background: ${({ bg }) => bg || "transparent"};
  border-radius: 50%;

  ${({ onClick }) => !!onClick && "cursor: pointer;"}
`;

const BadgeIcon = styled.img`
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
`;

interface IconBadgeProps {
  size: number;
  src: string;
  bg?: string;
  alt?: string;
  onClick?: () => void;
}

const IconBadge: React.FC<IconBadgeProps> = ({
  size,
  src,
  bg,
  alt,
  onClick
}) => (
  <Badge size={size} onClick={onClick} bg={bg}>
    <BadgeIcon src={src} alt={alt || ""} />
  </Badge>
);

export default IconBadge;
