import { IonSelect, IonSelectOption } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import ClanFlowStepTemplate from "../../components/ClanFlowStepTemplate";
import FooterButtons, {
  PrimaryFooterButton,
  SecondaryFooterButton
} from "../../components/FooterButtons";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import { updateUserProfile } from "../../data/user/profile/profile.actions";
import { UserProfileState } from "../../data/user/profile/profile.state";
import {
  ProfileNameKey,
  ProfileNationalitiesAPI
} from "../../util/api/ProfileService";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "./Onboarding";
import { fetchIgnoring404, setIgnoring404 } from "./OnboardingHelper";

interface OwnProps {
  setCurrentStep: any;
  isEditing: any;
}

interface DispatchProps {
  updateUserProfileConnect: typeof updateUserProfile;
  dispatchToast: typeof showToast;
}

interface StateProps {
  userProfileStateConnect: UserProfileState;
}

interface NationalitiesStepProps extends StateProps, DispatchProps, OwnProps {}

const NationalitiesStep: React.FC<NationalitiesStepProps> = ({
  setCurrentStep,
  isEditing,
  dispatchToast,
  updateUserProfileConnect
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const title = t("onboarding.nationality_header");
  const subTitle = t("onboarding.nationality_multiple");

  const [nationalities, setNationalities] = useState<ProfileNameKey[]>([]);
  const [userNationalities, setUserNationalities] = useState<ProfileNameKey[]>(
    []
  );

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchIgnoring404(
      [
        [ProfileNationalitiesAPI.list, setNationalities, []],
        [ProfileNationalitiesAPI.get, setUserNationalities, []]
      ],
      setLoading,
      dispatchToast
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateNationalities = async () => {
    const values = userNationalities.map((n) => n.key);
    const response = await setIgnoring404(
      ProfileNationalitiesAPI.put,
      values,
      setLoading,
      dispatchToast
    );
    if (response) {
      console.debug("[OnBoarding_Page] Successfully saved nationalities.");
      await updateUserProfileConnect({
        nationalities: response
      });
      isEditing ? history.goBack() : setCurrentStep(3);
    }
  };

  return (
    <>
      <ClanIonContent>
        <OnboardingWrapper>
          <ClanFlowStepTemplate
            title={title}
            subtitle={subTitle}
            headerClassName="text-center"
          >
            <div className="text-center">
              <IonSelect
                id="clanSelectBox"
                name="nationalities"
                className="text-clanH2 h-full font-extrabold font-gilroy"
                placeholder={t("onboarding.nationality")}
                multiple={true}
                onIonChange={(e): void => {
                  setUserNationalities(e.detail.value);
                }}
                value={userNationalities}
                compareWith={(n1, n2) => {
                  return Array.isArray(n2)
                    ? n2.some((n) => n.key === n1.key)
                    : n2.key === n1.key;
                }}
              >
                {nationalities.map(
                  (nationality: { name: string; key: string }) => {
                    return (
                      <IonSelectOption
                        key={nationality.key}
                        value={nationality}
                      >
                        {nationality.name}
                      </IonSelectOption>
                    );
                  }
                )}
              </IonSelect>
            </div>
          </ClanFlowStepTemplate>
        </OnboardingWrapper>
      </ClanIonContent>
      <FooterButtons>
        <SecondaryFooterButton
          disabled={isLoading}
          onClick={() => (isEditing ? history.goBack() : setCurrentStep(1))}
        >
          {isEditing ? t("onboarding.cancel") : t("general.back")}
        </SecondaryFooterButton>
        <PrimaryFooterButton onClick={updateNationalities} disabled={isLoading}>
          {isEditing ? t("onboarding.save") : t("onboarding.next")}
        </PrimaryFooterButton>
      </FooterButtons>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    userProfileStateConnect: state.userProfile
  }),
  mapDispatchToProps: {
    updateUserProfileConnect: updateUserProfile,
    dispatchToast: showToast
  },
  component: NationalitiesStep
});
