import { IonContent } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { SmoothRaysInverted } from "../../components/ClanSmoothRays";
import {
  BackButton,
  MethodTypes,
  StartContentWrapper,
  StartDescription,
  StartSteps,
  StartTitle,
  StartTitleWrapper,
  StartToolbar,
  StartWrapper
} from "./Start";

const StyledRoleButton = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0 10px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  background: #ffffff;
  border: 1px solid #fafafa;
  border-radius: 25px;
  padding: 18px 0 18px 10px;

  &:first-child {
    margin-bottom: 10px;
  }
`;

const RoleIcon = styled.div`
  grid-row: 1 / span 2;
  width: 55px;
  height: 55px;
  padding: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.primary};

  img {
    height: 100%;
    display: block;
    margin: 0 auto;
  }
`;

const RoleTitle = styled.div`
  font-family: Gilroy;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  padding-bottom: 5px;
  margin-bottom: 5px;
`;

const RoleDescription = styled.div`
  font-family: Gilroy;
  font-size: 14px;
  line-height: 18px;
  padding-right: 10px;
  letter-spacing: 0.203493px;
`;

const LoginLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

interface RoleButtonProps {
  title: string;
  description: string;
  icon?: string;
  onClick: () => void;
}

const RoleButton: React.FC<RoleButtonProps> = ({
  title,
  description,
  icon,
  onClick
}) => (
  <StyledRoleButton onClick={onClick}>
    <RoleIcon>
      <img src={icon} alt="icon" />
    </RoleIcon>
    <RoleTitle>{title}</RoleTitle>
    <RoleDescription>{description}</RoleDescription>
  </StyledRoleButton>
);

interface RoleSelectionProps {
  setCurrentStep: (step: StartSteps) => void;
  setMode: (method: MethodTypes) => void;
}

const RoleSelection: React.FC<RoleSelectionProps> = ({
  setCurrentStep,
  setMode
}) => {
  const { t } = useTranslation();

  return (
    <IonContent>
      <StartWrapper>
        <StartToolbar id="dashboard-header">
          <SmoothRaysInverted />
          <BackButton
            src="/assets/arrow_back_black.svg"
            onClick={() => {
              setCurrentStep(StartSteps.INTRO);
            }}
          />
        </StartToolbar>

        <StartContentWrapper>
          <StartTitleWrapper>
            <StartTitle>{t("start.role_selection.title")}</StartTitle>
            <StartDescription>
              {t("start.role_selection.description")}{" "}
              <LoginLink
                onClick={() => {
                  setMode(MethodTypes.LOGIN);
                  setCurrentStep(StartSteps.METHODS);
                }}
              >
                {t("start.role_selection.log_in")}
              </LoginLink>
            </StartDescription>
          </StartTitleWrapper>

          <section>
            <RoleButton
              title={t("start.role_selection.teacher_title")}
              description={t("start.role_selection.teacher_description")}
              icon="/assets/teacher.svg"
              onClick={() => {
                setMode(MethodTypes.TEACHER);
                setCurrentStep(StartSteps.METHODS);
              }}
            />
            <RoleButton
              title={t("start.role_selection.student_title")}
              description={t("start.role_selection.student_description")}
              icon="/assets/students.svg"
              onClick={() => {
                setMode(MethodTypes.STUDENT);
                setCurrentStep(StartSteps.METHODS);
              }}
            />
          </section>
        </StartContentWrapper>
      </StartWrapper>
    </IonContent>
  );
};

export default RoleSelection;
