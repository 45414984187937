import { LatestTimestampForChatId } from "./app.state";
import {
  getUserProperty,
  getUserPropertyAsBoolean,
  setUserProperty,
  setUserPropertyAsBoolean
} from "../../util/UserProperties/lib/CachedUserProperties";
import { UserPropertiesPersistedDataType } from "../../util/UserProperties/types/UserPropertiesData.d";

export const setLatestChathubTimestampsData = async (
  timestamps: LatestTimestampForChatId[]
) =>
  setUserProperty(
    UserPropertiesPersistedDataType.LATEST_CHATHUB_TIMESTAMPS,
    JSON.stringify(timestamps)
  );

export const getLatestChathubTimestampsData = async (): Promise<
  LatestTimestampForChatId[]
> => {
  const result = await getUserProperty(
    UserPropertiesPersistedDataType.LATEST_CHATHUB_TIMESTAMPS
  );
  return result ? JSON.parse(result) : undefined;
};

export const setLatestActivityTimestampData = async (timestamp: string) =>
  setUserProperty(
    UserPropertiesPersistedDataType.LATEST_ACTIVITY_TIMESTAMP,
    timestamp
  );

export const setLatestReportTimestampData = async (timestamp: string) =>
  setUserProperty(
    UserPropertiesPersistedDataType.LATEST_REPORT_TIMESTAMP,
    timestamp
  );

export const getLatestActivityTimestampData = async () =>
  getUserProperty(UserPropertiesPersistedDataType.LATEST_ACTIVITY_TIMESTAMP);
export const getLatestReportTimestampData = async () =>
  getUserProperty(UserPropertiesPersistedDataType.LATEST_REPORT_TIMESTAMP);

export const getInfoModalClosedCookie = () =>
  getUserPropertyAsBoolean(UserPropertiesPersistedDataType.INFO_MODAL_SEEN);

export const getTutorialOpenedCookie = () =>
  getUserPropertyAsBoolean(UserPropertiesPersistedDataType.TUTORIAL_OPENED);

export const getFeedbackGivenCookie = () =>
  getUserPropertyAsBoolean(UserPropertiesPersistedDataType.FEEDBACK_GIVEN);

export const getRecievedFirstSurveyCookie = () =>
  getUserPropertyAsBoolean(
    UserPropertiesPersistedDataType.RECIEVED_FIRST_SURVEY
  );

export const getDiscoverCommunitySeenCookie = () =>
  getUserPropertyAsBoolean(
    UserPropertiesPersistedDataType.DISCOVER_COMMUNITY_SEEN
  );

export const setRecievedFirstSurveyCookie = async (closed: boolean) =>
  setUserPropertyAsBoolean(
    UserPropertiesPersistedDataType.RECIEVED_FIRST_SURVEY,
    closed
  );

export const setInfoModalClosedCookie = async (closed: boolean) =>
  setUserPropertyAsBoolean(
    UserPropertiesPersistedDataType.INFO_MODAL_SEEN,
    closed
  );

export const setTutorialOpenedCookie = async (opened: boolean) =>
  setUserPropertyAsBoolean(
    UserPropertiesPersistedDataType.TUTORIAL_OPENED,
    opened
  );

export const setFeedbackGivenCookie = async (opened: boolean) =>
  setUserPropertyAsBoolean(
    UserPropertiesPersistedDataType.FEEDBACK_GIVEN,
    opened
  );

export const setDiscoverCommunitySeenCookie = async (opened: boolean) =>
  setUserPropertyAsBoolean(
    UserPropertiesPersistedDataType.DISCOVER_COMMUNITY_SEEN,
    opened
  );
