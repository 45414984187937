import { getCachedMapHolder } from "../../CachedData";
import { doRequestAndReturnResult } from "../../../apiService";
import {
  ClanbeatConfiguration,
  ClanbeatConfigurationViews,
  ViewSharings,
  ViewFilters,
  ViewTypes,
  ViewFiltersType,
  ViewSharingType,
  ViewSharingConfiguration,
  ViewFilterConfiguration
} from "../index";
import { TranslationConfiguration } from "../types/Translations.d";

export type ViewFilterConfigurationResult<
  V extends ViewFilters | ViewSharings,
  T extends ViewTypes
> = {
  filter: V;
  label: string;
  type: T;
  isDefault: boolean;
};

type ViewConfigurationResultData<
  V extends ViewFilters | ViewSharings,
  T extends ViewTypes
> = {
  data?: ViewFilterConfigurationResult<V, T>[];
  showFilters: boolean;
  showNoFilterWarning: boolean;
};

type ViewConfigurationResponse = {
  filters: ViewConfigurationResultData<ViewFilters, ViewFiltersType>;
  sharing: ViewConfigurationResultData<ViewSharings, ViewSharingType>;
};

type ConfigurationResponse = {
  views: { [key: string]: ViewConfigurationResponse };
  translations: TranslationConfiguration;
  otherUsersInContext: number;
};

const CONF_CACHE = getCachedMapHolder<ClanbeatConfiguration>(
  300000, // 5 minutes
  async () => {
    const result = await doRequestAndReturnResult<ConfigurationResponse>(
      "get",
      `/configuration/v1`
    );
    const views: { [key: string]: ClanbeatConfigurationViews } = {};

    Object.entries(result.views).forEach(([key, viewConf]) => {
      const filters = {
        filters: viewConf.filters.data,
        showFilters: viewConf.filters.showFilters,
        showNoFilterWarning: viewConf.filters.showNoFilterWarning
      } as ViewFilterConfiguration;

      const sharing = {
        filters: viewConf.sharing.data,
        showFilters: viewConf.sharing.showFilters,
        showNoFilterWarning: viewConf.sharing.showNoFilterWarning
      } as ViewSharingConfiguration;

      views[key] = { filters, sharing } as ClanbeatConfigurationViews;
    });

    return {
      views,
      translations: result.translations,
      otherUsersInContext: result.otherUsersInContext
    } as ClanbeatConfiguration;
  }
);

export const getConfiguration = (): Promise<ClanbeatConfiguration> =>
  CONF_CACHE("CONFIGURATION");
