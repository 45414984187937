import { Clipboard } from "@capacitor/clipboard";
import {
  IonAlert,
  IonButton,
  IonPage,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import { useAsync, useAsyncEffect } from "@react-hook/async";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import CategoryHeader from "../../components/CategoryHeader";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import { FlexRow } from "../../components/Flexbox";
import Header from "../../components/Headers/Header";
import ListItem from "../../components/ListItem/ListItem";
import ClanInput from "../../components/Modals/ClanInput";
import { SectionContent } from "../../components/Section";
import { connect } from "../../data/connect";
import { OauthData } from "../../data/oauth/oauthDataService";
import { showToast } from "../../data/toasts/toasts.actions";
import { ReactComponent as BgArrowRightIcon } from "../../icons/bg-arrow-right.svg";
import { ReactComponent as BgRemoveIcon } from "../../icons/bg-remove.svg";
import { ReactComponent as TeacherBadgeIcon } from "../../icons/teacher_badge.svg";
import * as ROUTES from "../../routes";
import {
  getGroupDetailsById,
  getGroupMembersSearch,
  GroupDetailsResponse,
  removeUserFromGroup
} from "../../util/api/OrganisationGroupService";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { ApiRequestError } from "../../util/ApiService";
import { dateLocaleFormatter } from "../../util/DateUtils";
import { setGroupNameRequest, useInvitationJoinCode } from "../../util/Group";
import { SimpleProfile } from "../../util/models/ClanProfileModels";
import { PaginatedStreamDataIterator } from "../../util/ResultDataIterator";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";

const MembersWrapper = styled(SectionContent)`
  margin-bottom: 24px;
`;

const MemberListItem = styled(ListItem)`
  margin: 8px 0;
`;

interface GroupMemberItemProps {
  name: string;
  thumbnail?: string;
  isLead: boolean;
  disableUser?: () => void;
  removeUserUser?: () => void;
}

const GroupMemberItem: React.FC<GroupMemberItemProps> = ({
  thumbnail,
  name,
  isLead,
  disableUser
}) => {
  return (
    <MemberListItem
      avatar={thumbnail || `/assets/navigation/profile.svg`}
      primary={
        <FlexRow gap="4px" align="center">
          {name}
          {isLead && <TeacherBadgeIcon />}
        </FlexRow>
      }
      icon={<BgRemoveIcon onClick={disableUser} />}
    />
  );
};

interface StateProps {
  myUserId: number;
  readAccessCode: boolean;
  refreshAccessCode: boolean;
  manageMembers: boolean;
  canRemoveUser: boolean;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface GroupSingleViewProps
  extends DispatchProps,
    StateProps,
    RouteComponentProps {}

let GroupMembersApi: PaginatedStreamDataIterator<SimpleProfile>;

const GroupSingleView: React.FC<GroupSingleViewProps> = ({
  myUserId,
  readAccessCode,
  refreshAccessCode,
  manageMembers,
  canRemoveUser,
  dispatchToast
}) => {
  const history = useHistory();
  const { groupId } = useParams<{ groupId: string }>();
  const { t } = useTranslation();

  const [showRefreshAlert, setShowRefreshAlert] = useState<boolean>(false);
  const [groupMembers, setGroupMembers] = useState<Array<SimpleProfile>>([]);
  const [hasMoreMembers, setHasMoreMembers] = useState(false);
  const [membersInitialDataLoaded, setMembersInitialDataLoaded] = useState(
    false
  );

  const groupResponse = useAsyncEffect<GroupDetailsResponse>(async () => {
    await initializeMemberApi();
    return getGroupDetailsById(Number(groupId));
  }, [groupId]);

  const [changedGroupResponse, changeGroupResponse] = useAsync<
    GroupDetailsResponse | undefined,
    ApiRequestError
  >(async (name: string) => {
    if (groupId === undefined) return;
    const _name = name.trim();
    if (_name.length > 0) {
      return setGroupNameRequest(groupId, _name);
    }
    return undefined;
  });

  const group = changedGroupResponse.value
    ? changedGroupResponse.value
    : groupResponse.value;

  const changeGroupName = useCallback(changeGroupResponse, [groupId]);

  async function initializeMemberApi() {
    if (!groupId) return;
    GroupMembersApi = getGroupMembersSearch(Number(groupId));
    await GroupMembersApi.reset();
    GroupMembersApi.getNext()
      .then((response) => {
        setGroupMembers(response);
        setMembersInitialDataLoaded(true);
      })
      .then(() => GroupMembersApi.hasNext().then(setHasMoreMembers))
      .catch((e) => console.error("Failed to fetch groups I lead", e));
  }

  async function loadNextMembersPage() {
    if (!hasMoreMembers) return;
    GroupMembersApi.getNext()
      .then((result) => setGroupMembers([...groupMembers, ...result]))
      .then(() => GroupMembersApi.hasNext().then(setHasMoreMembers));
  }

  const onClose = () => {
    history.push(ROUTES.GROUPS_VIEW);
  };

  const [invitationJoinCode, refreshInvitationJoinCode] = useInvitationJoinCode(
    groupId
  );

  const openPreferences = () =>
    history.push(
      ROUTES.GROUP_PREFERENCES_VIEW.replace(":groupId", groupId.toString())
    );

  const openAddNewMembers = () =>
    history.push(
      ROUTES.GROUP_ADD_MEMBERS_VIEW.replace(":groupId", groupId.toString())
    );

  const removeUser = async (id: number) => {
    try {
      await removeUserFromGroup(Number(groupId), id);
      setGroupMembers(groupMembers.filter(({ userId }) => userId !== id));
    } catch (e) {
      console.error("Failed to remove user from group", e);
    }
  };

  const disableUser = (member: SimpleProfile) => () => {
    if (member.identity.includes("GROUP_LEAD")) {
      const notAllowedToRemove =
        groupMembers.filter((groupMember) =>
          groupMember.identity.includes("GROUP_LEAD")
        ).length <= 1;

      if (notAllowedToRemove)
        return dispatchToast(t("group_view.at_least_one_lead"));
    }

    if (member.userId === myUserId) {
      dispatchToast(t("group_view.remove_me_confirmation"), undefined, "info", [
        {
          label: t("general.no")
        },
        {
          label: t("general.yes"),
          callback: () => {
            // TODO: // Remove myself from this group - does this work the same way as removing someone else?
            removeUser(member.userId).then(() =>
              history.push(ROUTES.DASHBOARD)
            );
          },
          primary: true
        }
      ]);
    } else {
      dispatchToast(
        t("group_view.remove_confirmation", { name: member.name }),
        undefined,
        "info",
        [
          {
            label: t("general.no")
          },
          {
            label: t("general.yes"),
            callback: () => removeUser(member.userId),
            primary: true
          }
        ]
      );
    }
  };

  const copyToClipboard = (codeToBeCopied: string) =>
    Clipboard.write({
      string: codeToBeCopied
    }).then(() => dispatchToast(t("dashboard_components.join_code_copied")));

  return (
    <ClanDesktopLayout>
      <IonPage>
        <IonAlert
          isOpen={showRefreshAlert}
          mode="ios"
          onDidDismiss={() => setShowRefreshAlert(false)}
          header={t("dashboard_components.refreshing_join_code")}
          message={t("dashboard_components.refreshing_join_code_message")}
          buttons={[
            {
              text: t("dashboard_components.cancel"),
              handler: () => setShowRefreshAlert(false),
              cssClass: "text-clanGreen-100"
            },
            {
              text: t("dashboard_components.refresh"),
              handler: () => refreshInvitationJoinCode(),
              cssClass: "text-clanGreen-100"
            }
          ]}
        />

        <Header
          title={
            <ClanInput
              value={group?.name}
              onSet={changeGroupName}
              onCancel={changeGroupName}
            />
          }
          onBack={onClose}
        />

        <ClanIonContent>
          {(readAccessCode || refreshAccessCode) && (
            <>
              <CategoryHeader
                title={t("group_view.access_code_title")}
                actions={
                  refreshAccessCode
                    ? [
                        {
                          title: t("dashboard_components.refresh_uc"),
                          action: () => setShowRefreshAlert(true)
                        }
                      ]
                    : []
                }
              />
              <SectionContent>
                <div className="text-center flex items-center justify-center flex-col">
                  {invitationJoinCode && readAccessCode && (
                    <div>
                      <p className="mt-0">
                        {t("dashboard_components.invite_members")}
                      </p>
                      <h1 id="join_code" className="mt-6 mb-0">
                        {invitationJoinCode.invitationHash}
                      </h1>
                      <span className="text-clanGray-200">
                        {invitationJoinCode && !invitationJoinCode.expired
                          ? t("dashboard_components.expires_at", {
                              time: dateLocaleFormatter(
                                invitationJoinCode.expiresAt,
                                "HH:mm | d MMM yyyy"
                              )
                            })
                          : t("dashboard_components.expired")}
                      </span>
                      <div className="flex-row mt-2">
                        <IonButton
                          fill="clear"
                          className="rounded-clanListItem border border-solid border-clanYellow-100 text-black"
                          onClick={() => {
                            copyToClipboard(invitationJoinCode?.invitationHash);
                          }}
                        >
                          {t("dashboard_components.copy")}
                        </IonButton>
                      </div>
                    </div>
                  )}
                </div>
              </SectionContent>
            </>
          )}

          <>
            <CategoryHeader title={t("group_view.group_management_title")} />
            <SectionContent>
              <ListItem
                primary={t("group_view.group_preferences")}
                onClick={openPreferences}
                icon={<BgArrowRightIcon />}
              />

              {group?.partOfOrganisation && manageMembers && (
                <ListItem
                  primary={t("group_view.add_new_members")}
                  onClick={openAddNewMembers}
                  icon={<BgArrowRightIcon />}
                />
              )}
            </SectionContent>
          </>

          <CategoryHeader
            title={t("group_view.active_members", {
              count: groupMembers.length
            })}
          />

          <MembersWrapper>
            {groupMembers.map((member) => (
              <GroupMemberItem
                key={member.userId}
                thumbnail={member.images?.thumbnail?.url}
                name={member.name}
                isLead={member.identity.includes("GROUP_LEAD")}
                disableUser={
                  canRemoveUser && member.userId !== myUserId
                    ? disableUser(member)
                    : undefined
                }
              />
            ))}

            {(hasMoreMembers ||
              (membersInitialDataLoaded && groupMembers.length > 20)) && (
              <IonSegment>
                <IonSegmentButton
                  className="font-extrabold font-gilroy text-clanGreen-100"
                  disabled={!hasMoreMembers}
                  onClick={loadNextMembersPage}
                >
                  {hasMoreMembers
                    ? t("general.list_load_more")
                    : t("general.list_end")}
                </IonSegmentButton>
              </IonSegment>
            )}
          </MembersWrapper>
        </ClanIonContent>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: ({ token }) => ({
    myUserId: (token as OauthData).userId,
    readAccessCode: UserEnabledProperties.Group.readAccessCode(token),
    refreshAccessCode: UserEnabledProperties.Group.refreshAccessCode(token),
    manageMembers: UserEnabledProperties.ManageGroup.manageMembers(token),
    canRemoveUser: UserEnabledProperties.ManageGroup.removeUser(token)
  }),
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: GroupSingleView
});
