import { IonContent, IonModal } from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ClanHeaderRays from "../ClanHeaderRays";
import FooterButtons, {
  PrimaryFooterButton,
  SecondaryFooterButton
} from "../FooterButtons";
import { Heading2 } from "../Headings/Headings";

import { ReactComponent as WelcomeIcon } from "../../icons/onboarding/welcome.svg";
import { ReactComponent as ExploreIcon } from "../../icons/onboarding/explore.svg";
import { ReactComponent as InviteIcon } from "../../icons/onboarding/invite.svg";

const CenteredContent = styled(IonContent)`
  &::part(scroll) {
    display: flex;
    align-items: center;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const IconCard = styled.div`
  display: block;
  margin: 0 auto;
  width: 80%;
  padding: 36px;
  background: #fafafa;
  border-radius: 25px;
  margin-bottom: 24px;

  svg {
    width: 100%;
  }
`;

enum OnboardingStep {
  Welcome,
  Explore,
  Invite
}

const OnboardingSteps = [
  OnboardingStep.Welcome,
  OnboardingStep.Explore,
  OnboardingStep.Invite
];

const StepsContent = [
  {
    title: "teacher_onboarding.title1",
    description: "teacher_onboarding.description1",
    icon: <WelcomeIcon />
  },
  {
    title: "teacher_onboarding.title2",
    description: "teacher_onboarding.description2",
    icon: <ExploreIcon />
  },
  {
    title: "teacher_onboarding.title3",
    description: "teacher_onboarding.description3",
    icon: <InviteIcon />
  }
];

interface TeacherOnboardingCardsProps {
  onClose: () => void;
}

const TeacherOnboardingCards: React.FC<TeacherOnboardingCardsProps> = ({
  onClose
}) => {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState<OnboardingStep>(
    OnboardingStep.Welcome
  );

  const onNext = () => setActiveStep(activeStep + 1);
  const onBack = () => setActiveStep(activeStep - 1);

  const { title, description, icon } = StepsContent[activeStep];

  const isLastStep = activeStep >= OnboardingSteps.length - 1;

  return (
    <IonModal isOpen onDidDismiss={onClose}>
      <ClanHeaderRays short />
      <CenteredContent>
        <ContentWrapper>
          <IconCard>{icon}</IconCard>
          <Heading2 centered title={t(title)} description={t(description)} />
        </ContentWrapper>
      </CenteredContent>
      <FooterButtons round>
        {activeStep !== 0 && (
          <SecondaryFooterButton onClick={onBack}>
            {t("general.back")}
          </SecondaryFooterButton>
        )}

        {isLastStep ? (
          <PrimaryFooterButton onClick={onClose}>
            {t("general.close")}
          </PrimaryFooterButton>
        ) : (
          <PrimaryFooterButton onClick={onNext}>
            {t("general.next")}
          </PrimaryFooterButton>
        )}
      </FooterButtons>
    </IonModal>
  );
};

export default TeacherOnboardingCards;
