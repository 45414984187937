import {
  IonAlert,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonText,
  IonTextarea
} from "@ionic/react";
import { useAsyncEffect } from "@react-hook/async";
import {
  alarm,
  calendarClear,
  checkmarkCircle,
  ellipseOutline
} from "ionicons/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import { getAddAppErrorActionPayload } from "../../data/app/app.actions";
import { dispatch } from "../../data/AppContext";
import { ReactComponent as FlowArrowIcon } from "../../icons/flow_arrow.svg";
import { ReactComponent as Weight1Icon } from "../../icons/planner/weight-1.svg";
import { ReactComponent as Weight2Icon } from "../../icons/planner/weight-2.svg";
import { ReactComponent as Weight3Icon } from "../../icons/planner/weight-3.svg";
import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";
import { WEIGHTSCALE } from "../../pages/Planner/PlannerDay";
import * as ROUTES from "../../routes";
import { ApiServiceMethod, AuthorizedApiResult } from "../../util/ApiService";
import { Views } from "../../util/Configuration";
import { dateLocaleFormatter, dateToDateString } from "../../util/DateUtils";
import { FeedTypes } from "../../util/Feeds";
import {
  AcceptTaskRequest,
  SharedUserTaskResult,
  UserTaskResult,
  UserTaskResultDetails
} from "../../util/models/PlannerUserTaskModels";
import CategoryHeader from "../CategoryHeader";
import ClanBottomModal from "../ClanBottomModal";
import ClanDesktopLayout from "../ClanDesktopLayout";
import ClanMoodSelect from "../ClanDiscussionPost/edit/ClanMoodSelect";
import ClanSharePost from "../ClanDiscussionPost/edit/ClanSharePost";
import ClanFooterBtn from "../ClanFooterBtn";
import ClanLoader from "../ClanLoader";
import { SmoothRays } from "../ClanSmoothRays";
import FooterButtons, {
  PrimaryFooterButton,
  SecondaryFooterButton
} from "../FooterButtons";
import SettingsButton from "../SettingsButton";
import TaskSchedulerModal from "../TaskSchedulerModal";
import ClanLabelInput, { LabelSuggestion } from "./ClanLabelInput";
import ClanTaskCompleteModal from "./ClanTaskCompleteModal";
import ClanTaskSharedModal from "./ClanTaskSharedModal";

const TaskHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: between;
  padding: 1.4rem 1.8rem;
`;

const TaskTitle = styled(IonTextarea)`
  flex: 1;
  margin: 0;
  font-family: Gilroy;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  --padding-top: 0;
`;

const CompleteIcon = styled(IonIcon)`
  color: #45dd85;
  font-size: 1.8rem;
  display: block;
  margin-right: 0.7rem;
  cursor: pointer;
`;

const DeleteIcon = styled(IonIcon)`
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
`;

const ScheduleIcon = styled(IonIcon)`
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.3rem;
`;

const LabelInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
  padding-bottom: 8px;
  margin: 0 12px;
`;

const SectionWrapper = styled.div`
  width: 90%;
  max-width: calc(100vw - 3.6rem);
  margin: 0 auto;
`;

const WeightAndMoodWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  height: 3.6rem;
  background: #fafafa;
`;

const WeightAndMoodSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    margin-right: 0.3rem;
  }
`;

const MoodIcon = styled.img`
  width: 1.4rem;
  height: 1.4rem;
`;

const ScheduleItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.2rem;

  &::last-child {
    margin-bottom: 0;
  }
`;

const ScheduleTitle = styled.div`
  font-family: Gilroy;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  margin-right: 0.3rem;
`;

const Chip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 25px;
  padding: 0.5rem 1rem;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  margin: 0.3rem 0.3rem 0.3rem 0;
  cursor: pointer;
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const NotesInput = styled(IonTextarea)`
  margin: 0;

  --padding-top: 0;
`;

const ArrowIcon = styled(FlowArrowIcon)`
  width: 1.7rem;
  height: 2.2rem;
`;

const EmptyButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  > div {
    margin-left: 0.7rem;
  }
`;

type TaskPageProps = RouteComponentProps<{
  task_id: string;
}>;

const TaskPage: React.FC<TaskPageProps> = ({ match, history, location }) => {
  const locationState = location.state as {
    dateChosen?: Date;
    taskToBeGrabbed?: SharedUserTaskResult;
  };
  const dateChosen = locationState?.dateChosen
    ? locationState?.dateChosen
    : undefined;

  const taskToBeGrabbed = locationState?.taskToBeGrabbed
    ? locationState?.taskToBeGrabbed
    : undefined;
  const taskId =
    match.params.task_id !== ":task_id" ? match.params.task_id : undefined;
  const basePath = "/usertask/v1/todo";
  const [item, setItem] = useState<UserTaskResultDetails | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [notes, setNotes] = useState<string | undefined | null>(undefined);
  const [title, setTitle] = useState<string | undefined | null>(undefined);
  const [showDelAlert, setShowDelAlert] = useState<boolean>(false);
  const [taskCompleted, setTaskCompleted] = useState<UserTaskResult>();
  const [moodModalOpen, setMoodModalOpen] = useState<boolean>(false);
  const [showLabelInput, setShowLabelInput] = useState<boolean>(false);
  const [existingLabels, setExistingLabels] = useState<LabelSuggestion[]>([]);
  const [showShare, setShowShare] = useState<boolean>(false);
  const [shareMessage, setShareMessage] = useState<string>("");
  const [shareGroups, setShareGroups] = useState([] as Array<number>);
  const [shareRoles, setShareRoles] = useState([] as Array<string>);
  const [shareProfiles, setShareProfiles] = useState([] as Array<number>);
  const [isSchedulerModalOpen, setSchedulerModalOpen] = useState<boolean>(
    false
  );
  const [editingNotes, setEditingNotes] = useState(false);
  const classroomLink = useMemo(() => {
    const itemCreationEvent = Array.isArray(item?.events) && item?.events[0];
    const classroomIdsArr =
      itemCreationEvent && itemCreationEvent.externalId?.split(":");
    return classroomIdsArr &&
      Array.isArray(classroomIdsArr) &&
      classroomIdsArr[0] === "google-classroom"
      ? `https://classroom.google.com/c/${btoa(classroomIdsArr[1])}/a/${btoa(
          classroomIdsArr[2]
        )}/details`
      : undefined;
  }, [item]);

  async function withLoadingAndErrorCheck(
    callBack: () => Promise<void>
  ): Promise<boolean> {
    await setLoading(true);
    try {
      await callBack();
      return true;
    } catch (error) {
      dispatch(getAddAppErrorActionPayload(error));
      return false;
    } finally {
      await setLoading(false);
    }
  }

  useEffect(() => {
    setNotes(item?.note);
    setTitle(item?.title);
  }, [item]);

  useAsyncEffect(async () => {
    await setAnalyticsScreenName("PLANNER_ITEM");
    if (!taskId) {
      if (taskToBeGrabbed) {
        const {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          sharing,
          ...taskToBeGrabbedWithoutSharingInfo
        } = taskToBeGrabbed;
        setItem({ ...taskToBeGrabbedWithoutSharingInfo, events: [] });
      }
      return;
    }
    await withLoadingAndErrorCheck(async () => {
      const taskItem = await AuthorizedApiResult.get<UserTaskResultDetails>(
        `${basePath}/${taskId}`
      );
      setItem(taskItem);
      updateShareData(taskItem);

      const result = await AuthorizedApiResult.get<LabelSuggestion[]>(
        `${basePath}/labels/list`
      );
      setExistingLabels(result);
      await analyticsLogEvent("PLANNER_ITEM_load");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePath, taskId, taskToBeGrabbed]);

  function updateShareData(taskItem: UserTaskResultDetails | undefined) {
    setShareProfiles(
      (taskItem?.sharing?.sharedProfiles || []).map(({ id }) => id)
    );
    setShareGroups((taskItem?.sharing?.sharedGroups || []).map(({ id }) => id));
  }

  //TODO: PlannerDay.tsx has exactly the same method. Maybe they should be merged?
  const switchComplete = async () => {
    if (!item || !taskId || taskToBeGrabbed) return;
    const method = item.completedDate
      ? ApiServiceMethod.DELETE
      : ApiServiceMethod.PUT;
    await withLoadingAndErrorCheck(async () => {
      await analyticsLogEvent(
        method === ApiServiceMethod.PUT
          ? "PLANNER_ITEM_complete"
          : "PLANNER_ITEM_completeRevert"
      );
      const result = await AuthorizedApiResult.request<UserTaskResultDetails>({
        method,
        url: `${basePath}/${taskId}/complete`
      });
      setItem(result);
      method === "put" && setTaskCompleted(item);
    });
  };

  const removeTargetDate = async () => {
    if (!item) return;
    if (!taskId && taskToBeGrabbed) {
      setItem({
        ...item,
        targetDate: undefined
      });
      return;
    }
    await withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.delete<UserTaskResultDetails>(
        `${basePath}/${taskId}/date`
      );
      setItem(result);
    });
  };

  const setTargetDate = async (value: Date) => {
    const dateArgument = dateToDateString(value);
    if (!item) return;
    if (!taskId && taskToBeGrabbed) {
      setItem({
        ...item,
        targetDate: dateArgument,
        reminders: [],
        dueDate: undefined
      });
      return;
    }
    await withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.put<UserTaskResultDetails>(
        `${basePath}/${taskId}/date?date=${dateArgument}`
      );
      setItem(result);
    });
  };

  const removeDueDate = async () => {
    if (!item) return;
    if (!taskId && taskToBeGrabbed) {
      setItem({
        ...item,
        dueDate: undefined
      });
      return;
    }
    await withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.delete<UserTaskResultDetails>(
        `${basePath}/${taskId}/duedate`
      );
      setItem(result);
    });
  };

  const setDueDate = async (value: Date) => {
    if (!item) return;
    const dateArgument = dateToDateString(value);
    if (!taskId && taskToBeGrabbed) {
      setItem({
        ...item,
        dueDate: dateArgument
      });
      return;
    }
    await withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.put<UserTaskResultDetails>(
        `${basePath}/${taskId}/duedate?date=${dateArgument}`
      );
      setItem(result);
    });
  };

  const saveValue = async (
    path: string,
    require: boolean,
    value?: string | null
  ) => {
    if (!item) return;
    if (require && !value) return;
    if (!taskId && taskToBeGrabbed) {
      setItem({
        ...item,
        [path]: value
      });
      return;
    }
    await withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.put<UserTaskResultDetails>({
        url: `${basePath}/${taskId}/${path}`,
        data: { value }
      });
      setItem(result);
    });
  };

  const setReminder = async (value: Date, oldValue?: Date) => {
    if (!item) return;
    if (!oldValue) {
      await addReminder(value);
    } else {
      await changeReminder(value, oldValue);
    }
  };

  const addReminder = async (value: Date) => {
    if (!item) return;
    if (!taskId && taskToBeGrabbed) {
      setItem({
        ...item,
        reminders: [value.toISOString()]
      });
      return;
    }
    await withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.put<UserTaskResultDetails>({
        url: `${basePath}/${taskId}/reminder`,
        data: { date: value.toISOString() }
      });
      setItem(result);
    });
  };

  const changeReminder = async (value: Date, oldValue?: Date) => {
    if (!item || !oldValue) return;
    if (!taskId && taskToBeGrabbed) {
      setItem({
        ...item,
        reminders: [value.toISOString()]
      });
      return;
    }
    await withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.put<UserTaskResultDetails>({
        url: `${basePath}/${taskId}/reminder`,
        data: {
          date: value.toISOString(),
          previousDate: oldValue.toISOString()
        }
      });
      setItem(result);
    });
  };

  const removeReminder = async (value: Date) => {
    if (!item) return;
    if (!taskId && taskToBeGrabbed) {
      setItem({
        ...item,
        reminders: []
      });
      return;
    }
    await withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.delete<UserTaskResultDetails>({
        url: `${basePath}/${taskId}/reminder`,
        data: { date: value.toISOString() }
      });
      setItem(result);
    });
  };

  const deleteTask = async () => {
    if (!item || !taskId || taskToBeGrabbed) return;
    const result = await withLoadingAndErrorCheck(async () => {
      await analyticsLogEvent("PLANNER_ITEM_remove");
      await AuthorizedApiResult.delete(`${basePath}/${taskId}`);
    });
    if (result) {
      history.goBack();
    }
  };

  const saveNotes = async () => {
    await saveValue("note", false, notes);
    setEditingNotes(false);
  };
  const saveTitle = async () => saveValue("title", true, title);

  const { t } = useTranslation();

  const setLabels = (value: string[]) => {
    if (!item) return;
    if (!taskId && taskToBeGrabbed) {
      setItem({
        ...item,
        labels: value
      });
      return;
    }
    withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.put<UserTaskResultDetails>({
        url: `${basePath}/${taskId}/labels`,
        data: { value }
      });
      setItem(result);
    });
  };

  const setMood = async (value: string) => {
    if (!item) return;
    if (!taskId && taskToBeGrabbed) {
      setItem({
        ...item,
        mood: value
      });
      setMoodModalOpen(false);
      return;
    }
    await withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.put<UserTaskResultDetails>({
        url: `${basePath}/${taskId}/mood`,
        data: { value }
      });
      setItem(result);
    });
    setMoodModalOpen(false);
  };
  const removeMood = async () => {
    if (!item) return;
    if (!taskId && taskToBeGrabbed) {
      setItem({
        ...item,
        mood: undefined
      });
      setMoodModalOpen(false);
      return;
    }
    await withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.delete<UserTaskResultDetails>(
        `${basePath}/${taskId}/mood`
      );
      setItem(result);
    });
    setMoodModalOpen(false);
  };

  const setWeight = () => {
    if (!item) return;
    if (!taskId && taskToBeGrabbed) {
      return setItem({
        ...item,
        weight: WEIGHTSCALE.EASY
      });
    }

    switch (item.weight) {
      case WEIGHTSCALE.EASY:
        return updateWeight(WEIGHTSCALE.MEDIUM);
      case WEIGHTSCALE.MEDIUM:
        return updateWeight(WEIGHTSCALE.HARD);
      case WEIGHTSCALE.HARD:
        return removeWeight();
      default:
        return updateWeight(WEIGHTSCALE.EASY);
    }
  };

  const updateWeight = async (value: WEIGHTSCALE) =>
    withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.put<UserTaskResultDetails>({
        url: `${basePath}/${taskId}/weight`,
        data: { value }
      });
      setItem(result);
    });

  const removeWeight = () =>
    withLoadingAndErrorCheck(async () => {
      const result = await AuthorizedApiResult.delete<UserTaskResultDetails>(
        `${basePath}/${taskId}/weight`
      );
      setItem(result);
    });

  const setShare = async () => {
    if (!item) return;
    if (!taskId || taskToBeGrabbed) return;
    await withLoadingAndErrorCheck(async () => {
      await analyticsLogEvent("PLANNER_ITEM_share");
      const updatedTask = await AuthorizedApiResult.put<UserTaskResultDetails>({
        url: `${basePath}/${taskId}/share`,
        data: {
          sharedGroups: shareGroups,
          sharedProfiles: shareProfiles,
          sharedShortcuts: shareRoles
        }
      });
      setItem(updatedTask);
      updateShareData(updatedTask);

      if (updatedTask.title) {
        setShareMessage(updatedTask.title);
      }
    });
    setShowShare(false);
  };

  const addTask = async () => {
    if (!item?.id) return;

    const copyItem: AcceptTaskRequest = {
      title: item.title,
      ...(item.targetDate && {
        date: item.targetDate
      }),
      ...(item.dueDate && {
        dueDate: item.dueDate
      }),
      ...(item.reminders && {
        reminder: item.reminders[0]
      }),
      ...(item.labels && {
        labels: item.labels
      }),
      ...(item.weight && {
        weight: item.weight
      }),
      ...(item.mood && {
        mood: item.mood
      }),
      ...(item.note && {
        notes: item.note
      }),
      sharedId: item.id
    };
    await withLoadingAndErrorCheck(async () => {
      if (copyItem.sharedId) {
        await analyticsLogEvent("PLANNER_ITEM_accept");
      } else {
        await analyticsLogEvent("PLANNER_ITEM_add");
      }
      const result = await AuthorizedApiResult.post<{ id: string }>({
        url: basePath,
        data: copyItem
      });
      let dateSelected = null;
      if (copyItem?.dueDate) dateSelected = new Date(copyItem?.dueDate);
      if (copyItem?.date) dateSelected = new Date(copyItem?.date);

      if (dateSelected) {
        history.replace(ROUTES.PLANNER_DAY, {
          dateChosen: dateSelected
        });
      } else {
        history.push(ROUTES.PLANNER_TASK.replace(":task_id", result.id), {
          dateChosen: dateSelected
        });
      }
    });
  };

  function onClickLabel(label: string) {
    return () => {
      history.push(ROUTES.PLANNER_EXISTING, {
        dateChosen,
        label
      });
    };
  }

  function onShareModalDismissed() {
    setShareMessage("");
    history.push(ROUTES.PUBLIC_FEEDS, {
      feedType: FeedTypes.TASK,
      initialShowNewPost: false,
      highlightedPostId: item?.id
    });
  }

  function getWeightIcon() {
    switch (item?.weight) {
      case WEIGHTSCALE.EASY:
        return <Weight1Icon />;
      case WEIGHTSCALE.MEDIUM:
        return <Weight2Icon />;
      case WEIGHTSCALE.HARD:
        return <Weight3Icon />;
      default:
        return <img src="/assets/planner/weight-none.svg" alt="weight" />;
    }
  }

  function getWeightLabel() {
    switch (item?.weight) {
      case WEIGHTSCALE.EASY:
        return t("task_page.weight_easy");
      case WEIGHTSCALE.MEDIUM:
        return t("task_page.weight_medium");
      case WEIGHTSCALE.HARD:
        return t("task_page.weight_hard");
      default:
        return t("task_page.set_difficulty");
    }
  }

  const labelSuggestions = existingLabels.filter(
    (labelSuggestion: LabelSuggestion) =>
      !(item?.labels || []).includes(labelSuggestion.value)
  );

  return (
    <>
      <ClanLoader message={t("general.loading")} showLoading={loading} />
      <IonAlert
        isOpen={showDelAlert}
        mode="ios"
        onDidDismiss={() => setShowDelAlert(false)}
        header={t("task_page.todo_remove")}
        buttons={[
          {
            text: t("task_page.cancel"),
            handler: () => setShowDelAlert(false),
            cssClass: "text-black"
          },
          {
            text: t("task_page.yes"),
            handler: deleteTask,
            cssClass: "text-black"
          }
        ]}
      />
      <TaskSchedulerModal
        isOpen={isSchedulerModalOpen}
        onClose={() => setSchedulerModalOpen(false)}
        targetDate={item?.targetDate}
        dueDate={item?.dueDate}
        reminder={
          item?.reminders && item.reminders.length > 0
            ? new Date(item?.reminders[0])
            : undefined
        }
        onTargetDateChange={(newTargetDate) => {
          if (newTargetDate) {
            setTargetDate(new Date(newTargetDate));
          } else {
            removeTargetDate();
          }
        }}
        onDueDateChange={(newDueDate) => {
          if (newDueDate) {
            setDueDate(new Date(newDueDate));
          } else {
            removeDueDate();
          }
        }}
        onReminderChange={(newReminder) => {
          if (newReminder) {
            setReminder(new Date(newReminder));
          } else if (item?.reminders) {
            removeReminder(new Date(item?.reminders[0]));
          }
        }}
        onAddToMyList={() => {
          removeDueDate();
        }}
      />

      <ClanBottomModal
        isOpen={showLabelInput}
        onDidDismiss={() => setShowLabelInput(false)}
      >
        <LabelInputWrapper>
          <ClanLabelInput
            labels={item?.labels || []}
            labelSuggestions={labelSuggestions}
            onLabelsChange={setLabels}
            onInputComplete={() => setShowLabelInput(false)}
          />
        </LabelInputWrapper>
      </ClanBottomModal>
      <ClanDesktopLayout>
        <IonPage>
          {taskCompleted && (
            <ClanTaskCompleteModal
              taskCompleted={taskCompleted}
              setTaskCompleted={setTaskCompleted}
            />
          )}

          {item !== undefined && (
            <ClanTaskSharedModal
              sharedGroups={shareGroups}
              sharedProfiles={shareProfiles}
              taskSharedTitle={shareMessage}
              onDone={onShareModalDismissed}
            />
          )}

          <IonModal
            isOpen={moodModalOpen}
            onDidDismiss={() => setMoodModalOpen(false)}
          >
            <IonHeader mode="ios" className="px-4">
              <div className="mt-12 text-center">
                <IonText>
                  <h3 className="my-1">{t("task_page.set_mood")}</h3>
                </IonText>
                <IonText>
                  <p>{t("task_page.set_mood_question")}</p>
                </IonText>
              </div>
            </IonHeader>
            <ClanIonContent>
              <ClanMoodSelect
                callback={(task) => {
                  setMood(task);
                }}
              />
            </ClanIonContent>
            <IonFooter>
              <ClanFooterBtn
                buttonObjects={[
                  {
                    title: t("task_page.cancel_uc"),
                    disabled: false,
                    callback: () => setMoodModalOpen(false)
                  },
                  {
                    title: t("task_page.remove_mood"),
                    disabled: false,
                    callback: () => {
                      removeMood();
                    }
                  }
                ]}
              />
            </IonFooter>
          </IonModal>

          {showShare && (
            <ClanSharePost
              view={Views.TasksFeed}
              setGroups={setShareGroups}
              setProfiles={setShareProfiles}
              roles={shareRoles}
              setRoles={setShareRoles}
              groups={shareGroups}
              profiles={shareProfiles}
            />
          )}

          {!showShare && (
            <ClanIonContent style={{ "--padding-bottom": "40px" }}>
              <SmoothRays />

              {!showShare && (
                <TaskHeader>
                  <CompleteIcon
                    icon={
                      item?.completedDate ? checkmarkCircle : ellipseOutline
                    }
                    onClick={() => {
                      switchComplete();
                    }}
                  />

                  <TaskTitle
                    autocapitalize="sentences"
                    value={title}
                    maxlength={100}
                    placeholder={t("task_page.enter_title")}
                    onIonChange={(e) => setTitle(e.detail.value)}
                    onIonBlur={saveTitle}
                    autoGrow={true}
                    rows={1}
                  />

                  {taskId && !taskToBeGrabbed && (
                    <DeleteIcon
                      onClick={() => setShowDelAlert(true)}
                      icon="/assets/common/delete.svg"
                    />
                  )}
                </TaskHeader>
              )}

              <WeightAndMoodWrapper>
                <WeightAndMoodSection onClick={setWeight}>
                  {getWeightIcon()}
                  <div>{getWeightLabel()}</div>
                </WeightAndMoodSection>

                <WeightAndMoodSection onClick={() => setMoodModalOpen(true)}>
                  <MoodIcon
                    alt="Mood"
                    src={`/assets/mood/${
                      item?.mood?.toLowerCase() || "undefined"
                    }.svg`}
                  />
                  <div>
                    {item?.mood
                      ? `${t("task_page.feeling")} ${t(
                          `moods.${item.mood.toLowerCase()}`
                        ).toLowerCase()}`
                      : t("task_page.set_mood_short")}
                  </div>
                </WeightAndMoodSection>
              </WeightAndMoodWrapper>

              <CategoryHeader
                title={t("task_page.schedule")}
                actions={[
                  {
                    title: t("task_page.edit"),
                    action: () => setSchedulerModalOpen(true)
                  }
                ]}
              />
              <SectionWrapper>
                {item?.dueDate && (
                  <ScheduleItem>
                    <ScheduleTitle>{t("task_page.due_date")}</ScheduleTitle>
                    <Chip>
                      <ScheduleIcon icon={calendarClear} />
                      {dateLocaleFormatter(item.dueDate.toString(), "dd.LL")}
                    </Chip>
                  </ScheduleItem>
                )}

                {item?.targetDate && (
                  <ScheduleItem>
                    <ScheduleTitle>
                      {t("task_page.working_on_it")}
                    </ScheduleTitle>
                    <Chip>
                      <ScheduleIcon icon={calendarClear} />
                      {dateLocaleFormatter(item.targetDate.toString(), "dd.LL")}
                    </Chip>
                  </ScheduleItem>
                )}

                {item?.reminders && item?.reminders?.length > 0 && (
                  <ScheduleItem>
                    <ScheduleTitle>{t("task_page.reminder")}</ScheduleTitle>
                    {item.reminders.map((reminder, index) => (
                      <Chip key={index}>
                        <ScheduleIcon icon={alarm} />
                        {dateLocaleFormatter(reminder, "HH.mm | dd.LL")}
                      </Chip>
                    ))}
                  </ScheduleItem>
                )}

                {!item?.dueDate &&
                  !item?.targetDate &&
                  (!item?.reminders || item.reminders.length <= 0) && (
                    <EmptyButton onClick={() => setSchedulerModalOpen(true)}>
                      <ArrowIcon />
                      <div>{t("task_page.add_schedule")}</div>
                    </EmptyButton>
                  )}
              </SectionWrapper>

              <CategoryHeader
                title={t("task_page.labels")}
                actions={[
                  {
                    title: t("task_page.edit"),
                    action: () => setShowLabelInput(true)
                  }
                ]}
              />

              <SectionWrapper>
                {(item?.labels?.length || 0) > 0 ? (
                  <LabelsWrapper>
                    {item?.labels?.map((label) => (
                      <Chip key={label} onClick={onClickLabel(label)}>
                        {label}
                      </Chip>
                    ))}
                  </LabelsWrapper>
                ) : (
                  <EmptyButton onClick={() => setShowLabelInput(true)}>
                    <ArrowIcon />
                    <div>{t("task_page.add_labels")}</div>
                  </EmptyButton>
                )}
              </SectionWrapper>

              <CategoryHeader
                title={t("task_page.notes")}
                actions={[
                  {
                    title: t("task_page.edit"),
                    action: () => setEditingNotes(true)
                  }
                ]}
              />
              <SectionWrapper>
                {!notes && !editingNotes && (
                  <EmptyButton onClick={() => setEditingNotes(true)}>
                    <ArrowIcon />
                    <div>{t("task_page.add_notes")}</div>
                  </EmptyButton>
                )}

                {editingNotes && (
                  <NotesInput
                    value={notes}
                    onIonChange={(e) => setNotes(e.detail.value)}
                    placeholder={t("task_page.add_notes_placeholder")}
                    autocapitalize="sentences"
                    onIonBlur={saveNotes}
                    autoGrow={true}
                    autofocus
                  />
                )}

                {item?.noteHtml && !editingNotes && (
                  <section
                    style={{ margin: 0 }}
                    dangerouslySetInnerHTML={{ __html: item?.noteHtml }}
                  />
                )}
              </SectionWrapper>

              <CategoryHeader title={t("task_page.more")} />
              <SectionWrapper>
                <SettingsButton
                  title={t("task_page.reflect_on_task")}
                  onClick={() =>
                    history.push(ROUTES.REFLECTIONS, {
                      reflectionTaskId: item?.id
                    })
                  }
                  indicator={<ArrowIcon />}
                />

                {taskId && !taskToBeGrabbed && !showShare && (
                  <SettingsButton
                    title={t("task_page.ask_a_question")}
                    indicator={<ArrowIcon />}
                    onClick={() => {
                      history.push(ROUTES.PUBLIC_FEEDS, {
                        feedType: FeedTypes.QUESTION,
                        initialShowNewPost: true
                      });
                    }}
                  />
                )}

                <SettingsButton
                  title={t("task_page.share")}
                  onClick={() => setShowShare(true)}
                  indicator={<ArrowIcon />}
                />

                {classroomLink && (
                  <SettingsButton
                    title={t("task_page.open_in_classroom")}
                    indicator={<ArrowIcon />}
                    href={classroomLink}
                  />
                )}
              </SectionWrapper>
            </ClanIonContent>
          )}

          {showShare && (
            <FooterButtons>
              <SecondaryFooterButton
                onClick={() => {
                  updateShareData(item);
                  setShowShare(false);
                }}
              >
                {t("general.back")}
              </SecondaryFooterButton>
              <PrimaryFooterButton
                onClick={() => setShare()}
                disabled={
                  loading ||
                  (shareGroups.length === 0 &&
                    shareProfiles.length === 0 &&
                    shareRoles.length === 0)
                }
              >
                {t("task_page.post")}
              </PrimaryFooterButton>
            </FooterButtons>
          )}

          {!showShare && !showLabelInput && (
            <FooterButtons>
              {!taskId && taskToBeGrabbed ? (
                <>
                  <SecondaryFooterButton onClick={() => history.goBack()}>
                    {t("general.back")}
                  </SecondaryFooterButton>
                  <PrimaryFooterButton onClick={() => addTask()}>
                    {t("task_page.add_to_planner")}
                  </PrimaryFooterButton>
                </>
              ) : (
                <PrimaryFooterButton onClick={() => history.goBack()}>
                  {t("general.back")}
                </PrimaryFooterButton>
              )}
            </FooterButtons>
          )}
        </IonPage>
      </ClanDesktopLayout>
    </>
  );
};

export default TaskPage;
