import React from "react";
import ClanListInterestTemplate from "./ClanListInterestTemplate";
import { BUCKET_LIST_ENDPOINTS } from "../../util/ClanInterestsEndpoints";
import { useTranslation } from "react-i18next";
import { COMMUNITY_SLIDES } from "../../pages/People";
import { FilterGroupType } from "../../util/Filters";

type ClanBucketListProps = {
  className?: string;
  filters?: FilterGroupType[];
  isParentVisible?: boolean;
  activeIndex: COMMUNITY_SLIDES;
};

const ClanBucketList: React.FC<ClanBucketListProps> = ({
  className,
  filters,
  activeIndex
}) => {
  const { t } = useTranslation();
  return (
    <ClanListInterestTemplate
      filters={filters}
      title="Bucket list"
      endpoints={BUCKET_LIST_ENDPOINTS}
      profilesTitle={t("people_overview.hopeful")}
      itemClassName="grid grid-cols-3auto-middle"
      cardClassName={className}
      activeIndex={activeIndex}
      withAmounts
    />
  );
};

export default ClanBucketList;
