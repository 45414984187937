import React, { useState } from "react";
import ClanQuestionModal from "../ClanQuestionModal";
import { deletePostById } from "../../util/ClanPostAPIService";
import { useTranslation } from "react-i18next";

interface ClanDiscussionPostRemoveModalProps {
  title: string;
  postId: string;
  removeQuestion: string;
  onDone: (postIdToBeRemoved: string) => void;
  onCancel: () => void;
  isOpen: boolean;
}

const ClanDiscussionPostRemoveModal: React.FC<ClanDiscussionPostRemoveModalProps> = ({
  title,
  postId,
  removeQuestion,
  onDone,
  isOpen,
  onCancel
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <>
      <ClanQuestionModal
        subtitle={removeQuestion}
        title={title}
        onYes={() => {
          setLoading(true);
          deletePostById(postId)
            .then(() => {
              setLoading(false);
              onDone(postId);
            })
            .catch((error) => {
              setLoading(false);
              console.error(error);
              onCancel();
            });
        }}
        onNo={onCancel}
        noText={t("clan_discussion_post.cancel")}
        isOpen={isOpen}
        loading={loading}
      />
    </>
  );
};

export default ClanDiscussionPostRemoveModal;
