import React from "react";
import styled from "styled-components";

import { ReactComponent as LlamaIcon } from "../icons/llama.svg";

const Message = styled.p`
  font-size: 1.286rem;
  line-height: 1.571rem;
  font-family: Gilroy;
`;

const Llama = styled(LlamaIcon)`
  border-bottom: 2px solid ${({ theme }) => theme.primary};
  padding-bottom: 1rem;
  width: 14rem;
`;
const Container = styled.div`
  padding-left: 1.563rem;
  padding-right: 1.563rem;
  text-align: center;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

interface ClanLlamaMessageProps {
  messages: string[];
  description?: string;
  className?: string;
}

const ClanLlamaMessage: React.FC<ClanLlamaMessageProps> = ({
  messages,
  description,
  className
}) => {
  return (
    <Container className={className}>
      <div className="w-full">
        <Llama />
        {messages.length !== 0 && (
          <div className="flex flex-col items-center mt-4">
            {messages.map((message, index) => (
              <Message key={index}>{message}</Message>
            ))}
          </div>
        )}
        {description && (
          <section dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
    </Container>
  );
};

export default ClanLlamaMessage;
