import { OAuth2Client } from "@byteowls/capacitor-oauth2";
import { IonIcon, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";
import styled from "styled-components";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import CategoryHeader from "../../components/CategoryHeader";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import { SmoothRays } from "../../components/ClanToolbarWithRays";
import SettingsButton from "../../components/SettingsButton";
import SubPageHeader from "../../components/SubPageHeader";
import { configuration } from "../../configuration";
import { connect } from "../../data/connect";
import { OauthState } from "../../data/oauth/oauth.state";
import { OauthData } from "../../data/oauth/oauthDataService";
import { showToast } from "../../data/toasts/toasts.actions";
import { ImageState } from "../../data/user/image/image.state";
import { UserProfileState } from "../../data/user/profile/profile.state";
import * as ROUTES from "../../routes";
import { FeatureService } from "../../util/api/FeatureService";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { renewRefreshToken } from "../../util/ApiService";
import { isNativePlatform } from "../../util/AppUtils";
import {
  authorizeWeb,
  CLASSROOM_SCOPES,
  unauthorizeClassroom
} from "../../util/ClassroomService";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";

const StyledCategoryHeader = styled(CategoryHeader)`
  margin: 2rem 1.8rem 0.7rem;
`;

const SettingsButtonsWrapper = styled.div`
  margin: 0 1.8rem 0.7rem;
  width: calc(100% - 3.6rem);
`;

const BioCompleteIndicator = styled(IonIcon)`
  width: 1.2rem;
  height: 1.2rem;
  background: ${({ theme }) => theme.primary};
  border-radius: 50%;
  padding: 0.5rem;
`;

interface StateProps {
  tokenConnect: OauthData | OauthState;
  classroomAuthenticated: boolean;
  classroomEnabled: boolean;
  profileConnect: UserProfileState;
  profileImagesConnect: ImageState;
}

interface DispatchProps {
  dispatchToastConnect: typeof showToast;
}

interface PersonalizeProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {}

const Personalize: React.FC<PersonalizeProps> = ({
  tokenConnect,
  profileConnect,
  profileImagesConnect,
  classroomEnabled,
  classroomAuthenticated,
  dispatchToastConnect
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    isLocked,
    bucketList = [],
    interests = [],
    socialLinks = {},
    fullName,
    dateOfBirth,
    languages = [],
    nationalities = []
  } = profileConnect;

  useEffect(() => {
    setAnalyticsScreenName("PERSONALIZE");
    analyticsLogEvent("PERSONALIZE_show");
  }, []);

  const onClose = () => {
    history.push(ROUTES.DASHBOARD);
  };

  const openEditBio = () => history.push(ROUTES.EDIT_BIO_VIEW);

  const openOnboardingStep = (step: number) => () => {
    history.push(ROUTES.ONBOARDING, { currentStep: step });
  };

  const connectGoogleClassroom = async () => {
    if (!isNativePlatform) {
      await authorizeWeb((tokenConnect as OauthData).userId)
        .then(() => {
          checkTokenStateChange();
        })
        .catch((e) => {
          console.error("User closed classroom auth flow", e);
          dispatchToastConnect(e);
        });
    } else {
      const additionalParams = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        access_type: "offline",
        // eslint-disable-next-line @typescript-eslint/camelcase
        include_granted_scopes: "true",
        prompt: "consent"
      };

      const appBaseParams = {
        appId: configuration.oauthClientId,
        responseType: "code",
        redirectUrl: `${configuration.apiUrl}/lms/v1/google-classroom/auth/callback`,
        additionalParameters: additionalParams
      };

      const oauthOptions = {
        authorizationBaseUrl: "https://accounts.google.com/o/oauth2/auth",
        scope: CLASSROOM_SCOPES,
        android: {
          ...appBaseParams,
          state: btoa(
            JSON.stringify([
              0,
              (tokenConnect as OauthData).userId,
              "clanbeat.app:/"
            ])
          )
        },
        ios: {
          ...appBaseParams,
          state: btoa(
            JSON.stringify([
              0,
              (tokenConnect as OauthData).userId,
              "clanbeat.app:/"
            ])
          )
        }
      };

      try {
        await OAuth2Client.authenticate(oauthOptions);
        checkTokenStateChange();
      } catch (error) {
        console.error(
          "Authentication rejected/cancelled",
          JSON.stringify(error),
          error
        );
        checkTokenStateChange();
      }
    }
  };

  const disconnectGoogleClassroom = async () => {
    try {
      await unauthorizeClassroom();
    } catch (e) {
      console.error(`Failed to disconnect google classroom!`, e);
    } finally {
      await renewRefreshToken();
    }
  };

  const checkTokenStateChange = () => {
    const oldAuthenticationState = classroomAuthenticated;

    renewRefreshToken().then((token) => {
      const newAuthenticationState = UserEnabledProperties.LMS.googleClassroom.authorized(
        token as OauthState
      );

      if (!oldAuthenticationState && newAuthenticationState) {
        dispatchToastConnect(
          t("personalize.classroom_connected"),
          undefined,
          "info"
        );
      }
    });
  };

  const calculateBioProgress = () => {
    const progressArray = [
      !!fullName,
      !!dateOfBirth,
      languages.length > 0,
      nationalities.length > 0,
      !!profileImagesConnect.profileImage
    ];

    return Math.round(
      (progressArray.filter(Boolean).length / progressArray.length) * 100
    );
  };

  const bioProgress = calculateBioProgress();

  const socialLinksDisabled = FeatureService.PROFILE_DISABLE_SOCIAL_LINKS(
    tokenConnect
  );

  return (
    <ClanDesktopLayout>
      <IonPage>
        <ClanIonContent>
          <SmoothRays />

          <SubPageHeader title={t("personalize.title")} onBack={onClose} />

          <StyledCategoryHeader title={t("personalize.edit_profile")} />
          <SettingsButtonsWrapper>
            <SettingsButton
              title={t("personalize.edit_bio")}
              indicator={
                bioProgress === 100 ? (
                  <BioCompleteIndicator src="/assets/check-white.svg" />
                ) : (
                  `${calculateBioProgress()}%`
                )
              }
              onClick={openEditBio}
            />
            {isLocked === false && (
              <>
                <SettingsButton
                  title={t("personalize.interests_btn")}
                  onClick={openOnboardingStep(4)}
                  indicator={interests.length}
                />
                <SettingsButton
                  title={t("personalize.bucket_list_btn")}
                  onClick={openOnboardingStep(5)}
                  indicator={bucketList.length}
                />
                {!socialLinksDisabled && (
                  <SettingsButton
                    title={t("personalize.contacts_btn")}
                    onClick={openOnboardingStep(6)}
                    indicator={Object.keys(socialLinks).length}
                  />
                )}
              </>
            )}
          </SettingsButtonsWrapper>

          {classroomEnabled && (
            <>
              <StyledCategoryHeader
                title={t("personalize.miscellaneous_title")}
              />

              <SettingsButtonsWrapper>
                {classroomAuthenticated ? (
                  <SettingsButton
                    title={t("personalize.disconnect_classroom")}
                    indicator={
                      <img
                        src="assets/classroom.svg"
                        alt={t("personalize.disconnect_classroom")}
                      />
                    }
                    onClick={disconnectGoogleClassroom}
                  />
                ) : (
                  <SettingsButton
                    title={t("personalize.connect_classroom")}
                    indicator={
                      <img
                        src="assets/classroom.svg"
                        alt={t("personalize.connect_classroom")}
                      />
                    }
                    onClick={connectGoogleClassroom}
                  />
                )}
              </SettingsButtonsWrapper>
            </>
          )}

          <StyledCategoryHeader title={t("account.title")} />
          <SettingsButtonsWrapper>
            <>
              <SettingsButton
                title={t("account.delete_account_btn")}
                href={`https://form.typeform.com/to/yQk8DxdK#user_id=${
                  (tokenConnect as OauthData).userId
                }`}
              />
            </>
          </SettingsButtonsWrapper>
        </ClanIonContent>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    tokenConnect: state.token,
    profileConnect: state.userProfile,
    profileImagesConnect: state.userProfileImage,
    classroomEnabled: UserEnabledProperties.LMS.googleClassroom.enabled(
      state.token
    ),
    classroomAuthenticated: UserEnabledProperties.LMS.googleClassroom.authorized(
      state.token
    )
  }),
  mapDispatchToProps: {
    dispatchToastConnect: showToast
  },
  component: Personalize
});
