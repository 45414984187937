import React from "react";
import styled from "styled-components";

const LineButton = styled.a<{ active?: boolean }>`
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-family: Gilroy;
  font-weight: bold;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 50px;
  color: black;
  padding: 10px 0;
  width: 88%;
  height: 50px;
  display: block;
  margin: 10px auto;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  ${({ active, theme }) => active && `background: ${theme.primary}`}
`;

interface SelectableLineProps {
  label: string;
  selected: boolean;
  onClick?: () => void;
}

const ChoiceLine: React.FC<SelectableLineProps> = ({
  label,
  selected,
  onClick
}) => {
  return selected ? (
    <LineButton active={true}>{label}</LineButton>
  ) : (
    <LineButton active={false} onClick={onClick}>
      {label}
    </LineButton>
  );
};

export default ChoiceLine;
