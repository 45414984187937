import { InterestType } from "../../components/PeopleOverview/ClanSimpleInterests";
import { OauthData } from "../oauth/oauthDataService";
import { AppState } from "../state";

export enum ProfileInfoType {
  ProfileCreationDate
}

export function selectMyInterestsByType(type: InterestType, state: AppState) {
  switch (type) {
    case InterestType.Interest:
      return state.userProfile.interests || [];
    case InterestType.BucketList:
      return state.userProfile.bucketList || [];
    default:
      return [];
  }
}

export function selectProfileInfo(type: ProfileInfoType, state: AppState) {
  switch (type) {
    case ProfileInfoType.ProfileCreationDate:
      return state.userProfile.profileCreatedAt;
    default:
      return undefined;
  }
}

export const isMyProfileRoute = (token: OauthData, profileId?: string) =>
  !!profileId && token.profileId === parseInt(profileId);
