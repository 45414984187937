import styled, { css } from "styled-components";

export const BaseTypography = css`
  font-family: Gilroy;
  font-style: normal;
  color: #000000;
`;

export const H2Style = css`
  ${BaseTypography}
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
`;

export const H3Style = css`
  ${BaseTypography}
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  margin: 20px 0 10px;
`;

export const H4Style = css`
  ${BaseTypography}
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  margin: 20px 0 10px;
`;

export const H5Style = css`
  ${BaseTypography}
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
`;

export const H6Style = css`
  ${BaseTypography}
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.2px;
`;

export const H2 = styled.h2`
  ${H2Style}
`;

export const H3 = styled.h3`
  ${H3Style}
`;

export const H4 = styled.h4`
  ${H4Style}
`;

export const H5 = styled.h5`
  ${H5Style}
`;

export const H6 = styled.h6`
  ${H6Style}
`;

export const PostFont = css`
  font-family: "Didact";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
`;
