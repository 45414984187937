import { UserProfileState } from "./profile.state";
import { ClanProfileResponse } from "../../../util/models/ClanProfileModels";

import { Storage } from "../../../util/storage/DataStorage";

const USER_PROFILE_DATA = "userProfileData";

type StoredProfileData = {
  pn: string; // full name
  pf: string; // first name
  pl: string; // last name
  pg: string; // gender
  pp?: string; // prefix aka title
  pt: string; // profile type
  pd: string; // profile date of birth
  lb?: Array<string>; // profile bucket list
  li?: Array<string>; // list of interests
  ls?: object; // social links
  ln?: Array<string>; // nationalities
  ll?: Array<string>; // list of languages
  pc?: string; // profile creation date
};

const checkState = (userProfileState: UserProfileState): UserProfileState => {
  const onBoardingData = [
    userProfileState.fullName,
    userProfileState.firstName,
    userProfileState.lastName
  ];

  userProfileState.isLocked = onBoardingData.some(
    (onBoardingItem: string | undefined) => {
      if (!onBoardingItem) {
        return true;
      }
      return onBoardingItem.trim().length === 0;
    }
  );

  return userProfileState;
};

const concatNameParts = (
  firstName?: string,
  lastName?: string
): string | undefined => {
  const fullNameParts = [];
  if (firstName && firstName.trim() !== "") {
    fullNameParts.push(firstName);
  }
  if (lastName && lastName.trim() !== "") {
    fullNameParts.push(lastName);
  }
  return fullNameParts.length > 0 ? fullNameParts.join(" ").trim() : undefined;
};

const castToUserProfileData = (
  profileData?: StoredProfileData
): UserProfileState => {
  if (!profileData) {
    return {
      isLocked: true,
      isCached: false
    } as UserProfileState;
  }

  const userProfileState = {
    type: profileData.pt,
    title: profileData.pp,
    fullName: concatNameParts(profileData.pf, profileData.pl),
    firstName: profileData.pf,
    lastName: profileData.pl,
    gender: profileData.pg,
    dateOfBirth: profileData.pd,
    bucketList: profileData.lb,
    interests: profileData.li,
    socialLinks: profileData.ls,
    profileCreatedAt: profileData.pc,
    nationalities: profileData.ln,
    languages: profileData.ll,
    isLocked: true,
    isCached: true,
    isLoaded: true
  } as UserProfileState;
  return checkState(userProfileState);
};

export const removeUserProfileData = async (): Promise<UserProfileState> => {
  await Storage.remove({ key: USER_PROFILE_DATA });
  return {
    isLocked: true,
    isCached: false
  } as UserProfileState;
};

export const cacheUserProfileData = async (
  userProfileState: UserProfileState
) => {
  if (
    Object.keys(userProfileState).length === 2 &&
    !userProfileState.isCached &&
    userProfileState.isLocked
  ) {
    await Storage.remove({ key: USER_PROFILE_DATA });
    return checkState(userProfileState);
  }

  const dataToStore = {
    pt: userProfileState.type,
    pp: userProfileState.title,
    pf: userProfileState.firstName,
    pl: userProfileState.lastName,
    pg: userProfileState.gender,
    pd: userProfileState.dateOfBirth,
    lb: userProfileState.bucketList,
    li: userProfileState.interests,
    ls: userProfileState.socialLinks,
    ln: userProfileState.nationalities,
    ll: userProfileState.languages,
    pc: userProfileState.profileCreatedAt
  } as StoredProfileData;
  await Storage.set({
    key: USER_PROFILE_DATA,
    value: JSON.stringify(dataToStore)
  });
  return checkState(userProfileState);
};

export const storeClanProfileResponse = async (
  userProfileResponse: ClanProfileResponse
): Promise<UserProfileState> => {
  const userProfileState = {
    type: userProfileResponse.type,
    title: userProfileResponse.title,
    fullName: userProfileResponse.fullName,
    firstName: userProfileResponse.firstName,
    lastName: userProfileResponse.lastName,
    gender: userProfileResponse.gender,
    dateOfBirth: userProfileResponse.dateOfBirth,
    bucketList: userProfileResponse.bucketList,
    interests: userProfileResponse.interests,
    socialLinks: userProfileResponse.socialLinks,
    nationalities: userProfileResponse.nationalities,
    languages: userProfileResponse.languages,
    profileCreatedAt: userProfileResponse.profileCreatedAt,
    isLocked: false,
    isCached: false,
    isLoaded: true
  } as UserProfileState;
  await cacheUserProfileData(userProfileState);
  userProfileState.isCached = true;
  return checkState(userProfileState);
};

export const getUserProfileData = async (): Promise<UserProfileState> => {
  const data: any = await Storage.get({ key: USER_PROFILE_DATA });
  if (data !== null) {
    return castToUserProfileData(JSON.parse(data.value) as StoredProfileData);
  }
  return {
    isLocked: true,
    isCached: false
  } as UserProfileState;
};
