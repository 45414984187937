import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CategoryHeader from "../../components/CategoryHeader";
import { ClanCard, ClanCardContent } from "../../components/ClanCard";
import { SectionContent } from "../../components/Section";
import { connect } from "../../data/connect";
import { ChartResultNumber } from "../../util/Analytics";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";

const UsageStatsCard = styled(ClanCard)`
  margin: 12px 0;
`;

const NumberCardContent = styled(ClanCardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 30px 35px;
  text-align: center;
`;

type MoodItemNumberProps = {
  positive?: boolean;
};

const MoodItemNumber = styled.div<MoodItemNumberProps>`
  margin: 0 0 0.8rem;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: Gilroy;

  color: black;
  background: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.primary};
  margin-top: 0;
`;

const StatItemTitle = styled.div`
  max-width: 9rem;
  text-align: center;
  font-family: Gilroy;
`;

interface StateProps {
  usagePermissions: boolean;
}

interface OwnProps {
  chartNumbers: Array<ChartResultNumber>;
}

interface UsageStatsProps extends OwnProps, StateProps {}

const UsageStats: React.FC<UsageStatsProps> = ({ chartNumbers }) => {
  const { t } = useTranslation();
  // {chartNumbers.length > 0 && (
  //     )}
  return (
    <>
      <CategoryHeader
        title={t("stats.usage")}
        subtitle={t("stats.usage_subtitle")}
        tooltipText={t("stats.usage_tooltip")}
      />

      <SectionContent>
        {chartNumbers.map((chartNumber, index) => (
          <UsageStatsCard key={index}>
            <NumberCardContent>
              <MoodItemNumber>{chartNumber.number}</MoodItemNumber>
              <StatItemTitle>{chartNumber.title}</StatItemTitle>
              <span
                dangerouslySetInnerHTML={{
                  __html: chartNumber.message
                }}
              />
            </NumberCardContent>
          </UsageStatsCard>
        ))}
      </SectionContent>
    </>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: ({ token }) => ({
    usagePermissions: UserEnabledProperties.AnalyticsStatus.usageStats(token)
  }),
  component: UsageStats
});
