import { ClanImages } from "../../../util/models/ClanImageModels";
import { ImageState } from "./image.state";
import { Storage } from "../../../util/storage/DataStorage";
import { fetchBlobStringOrUndefinedFromURL } from "../../../util/BlobFromURlReader";

const USER_PROFILE_IMAGE_DATA = "userImageData";
const USER_PROFILE_THUMBNAIL_DATA = "userThumbnailData";

export const removeUserImagesData = async (): Promise<ImageState> => {
  try {
    await Storage.remove({ key: USER_PROFILE_IMAGE_DATA });
  } finally {
    await Storage.remove({ key: USER_PROFILE_THUMBNAIL_DATA });
  }
  return {
    profileImageThumbnail: undefined,
    profileImage: undefined,
    isCached: false
  } as ImageState;
};

export const cacheImageData = async (imageState: ImageState) => {
  if (imageState.profileImage) {
    await Storage.set({
      key: USER_PROFILE_IMAGE_DATA,
      value: imageState.profileImage
    });
  } else {
    await Storage.remove({
      key: USER_PROFILE_IMAGE_DATA
    });
  }
  if (imageState.profileImageThumbnail) {
    await Storage.set({
      key: USER_PROFILE_THUMBNAIL_DATA,
      value: imageState.profileImageThumbnail
    });
  } else {
    await Storage.remove({ key: USER_PROFILE_THUMBNAIL_DATA });
  }
};

export const storeClanProfileImages = async (
  userImageData: ClanImages
): Promise<ImageState> => {
  const response = {
    profileImageThumbnail: undefined,
    profileImage: undefined,
    isCached: false
  } as ImageState;

  response.profileImage = await fetchBlobStringOrUndefinedFromURL(
    userImageData.full?.url
  );

  response.profileImageThumbnail = await fetchBlobStringOrUndefinedFromURL(
    userImageData.thumbnail?.url
  );
  if (!response.profileImageThumbnail) {
    // fail back- sometimes thumbnail can not be loaded
    response.profileImageThumbnail = response.profileImage;
  }
  await cacheImageData(response);
  response.isCached = true;
  return response;
};

const parseCachedImageData = (data?: string) => {
  if (!data) return undefined;
  if (data.startsWith('"')) {
    return JSON.parse(data); // support already existing JSON formatted images
  }
  return data;
};

export const getuserImageData = async (): Promise<ImageState> => {
  const userProfileImage: any = await Storage.get({
    key: USER_PROFILE_IMAGE_DATA
  });
  const userProfileThumbnail: any = await Storage.get({
    key: USER_PROFILE_THUMBNAIL_DATA
  });

  return {
    profileImageThumbnail: parseCachedImageData(userProfileThumbnail.value),
    profileImage: parseCachedImageData(userProfileImage.value),
    isCached: userProfileImage.value && userProfileThumbnail.value
  } as ImageState;
};
