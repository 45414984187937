import { useAsync } from "@react-hook/async";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import * as ROUTES from "../../routes";
import {
  isReadyStateCancelOrFail,
  isReadyStateLoading
} from "../../util/AsyncEffectFilter";
import {
  addPersonalPost,
  addReflectionToTask
} from "../../util/ClanPostAPIService";
import { reSharePost, FeedTypes } from "../../util/Feeds";
import { ClanEditPostEditDetails } from "../../util/models/ClanPostModels";
import PostModal from "./PostModal";
import ShareModal from "./ShareModal";
import { Views } from "../../util/Configuration";
import { useTranslation } from "react-i18next";
import { getSharingLabel } from "../../util/PostUtils";

interface ShareReflectionModalProps {
  onClose: () => void;
  taskId?: string;
}

const ShareReflectionModal: React.FC<ShareReflectionModalProps> = ({
  onClose,
  taskId
}) => {
  const [post, setPost] = useState<ClanEditPostEditDetails>({});
  const [sharedProfiles, setSharedProfiles] = useState<Array<number>>([]);
  const [sharedGroups, setSharedGroups] = useState<Array<number>>([]);
  const [sharedRoles, setSharedRoles] = useState<Array<string>>([]);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  const [submitStatus, submitReflection] = useAsync(async () => {
    if (taskId) {
      const sharedPost = await addReflectionToTask(taskId, post);

      if (!sharedPost || isReadyStateCancelOrFail(submitStatus)) return;

      return history.push(ROUTES.PUBLIC_FEEDS, {
        feedType: FeedTypes.SHARED_REFLECTIONS,
        highlightedPostId: sharedPost.id
      });
    }

    const reflection = await addPersonalPost(post);
    if (!reflection || isReadyStateCancelOrFail(submitStatus)) return;

    const sharedPost = await reSharePost(Views.ReflectionFeed, reflection.id, {
      sharedProfiles,
      sharedGroups,
      sharedShortcuts: sharedRoles
    });

    if (!sharedPost || isReadyStateCancelOrFail(submitStatus)) return;

    onClose();

    history.push(ROUTES.PUBLIC_FEEDS, {
      feedType: FeedTypes.SHARED_REFLECTIONS,
      highlightedPostId: sharedPost.id
    });
  });

  const onGroupsChange = (newGroups: Array<number>) =>
    setSharedGroups(newGroups);

  const onProfilesChange = (newProfiles: Array<number>) =>
    setSharedProfiles(newProfiles);

  const onRolesChange = (newRoles: Array<string>) => setSharedRoles(newRoles);

  const onPostChange = (newPost: ClanEditPostEditDetails) =>
    setPost({ ...post, ...newPost });

  const onShareClicked = () => setShareModalOpen(true);

  const shareLabel = () => {
    if (
      sharedProfiles.length > 0 ||
      sharedGroups.length > 0 ||
      sharedRoles.length > 0
    ) {
      return getSharingLabel(sharedProfiles, sharedGroups, sharedRoles);
    } else {
      return t("clan_share_post.select");
    }
  };

  const isDisabled =
    isReadyStateLoading(submitStatus) ||
    !post.text ||
    (sharedProfiles.length <= 0 &&
      sharedGroups.length <= 0 &&
      sharedRoles.length <= 0);

  return (
    <>
      <PostModal
        post={post}
        textPlaceholder={t("dashboard_components.feeling_question")}
        shareLabel={shareLabel()}
        disabled={isDisabled}
        onShareClicked={onShareClicked}
        onPostChange={onPostChange}
        onSubmit={submitReflection}
        onClose={onClose}
      />

      {shareModalOpen && (
        <ShareModal
          view={Views.ReflectionFeed}
          groups={sharedGroups}
          profiles={sharedProfiles}
          roles={sharedRoles}
          onGroupsChange={onGroupsChange}
          onProfilesChange={onProfilesChange}
          onRolesChange={onRolesChange}
          onClose={() => setShareModalOpen(false)}
          onSubmit={() => setShareModalOpen(false)}
        />
      )}
    </>
  );
};

export default ShareReflectionModal;
