import { IonCol, IonInput, IonItem, IonLabel, IonList } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import ClanFlowStepTemplate from "../../components/ClanFlowStepTemplate";
import ClanSocialIcon from "../../components/ClanSocialIcon";
import FooterButtons, {
  PrimaryFooterButton,
  SecondaryFooterButton
} from "../../components/FooterButtons";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import { updateUserProfile } from "../../data/user/profile/profile.actions";
import { UserProfileState } from "../../data/user/profile/profile.state";
import { SocialLinkApi } from "../../util/api/ProfileService";
import {
  mapSocialLinksWithEmptyValues,
  UserSocialLinksType
} from "../../util/SocialLinks";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "./Onboarding";
import { fetchIgnoring404, setIgnoring404 } from "./OnboardingHelper";

type UserSocialLinksValidationType = { valid: boolean } & UserSocialLinksType;

interface OwnProps {
  setCurrentStep: any;
  isEditing: any;
}

interface DispatchProps {
  updateUserProfileConnect: typeof updateUserProfile;
  dispatchToast: typeof showToast;
}

interface StateProps {
  userProfileStateConnect: UserProfileState;
}

interface SocialLinksStepProps extends StateProps, DispatchProps, OwnProps {}

const SocialLinksStep: React.FC<SocialLinksStepProps> = ({
  setCurrentStep,
  isEditing,
  dispatchToast,
  updateUserProfileConnect
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [userSocialContacts, setUserSocialContacts] = useState<
    UserSocialLinksValidationType[]
  >([]);

  useEffect(() => {
    fetchIgnoring404(
      [
        [
          SocialLinkApi.get,
          async (result) => {
            await updateUserProfileConnect({ socialLinks: result });
            await setUserSocialContacts(
              mapSocialLinksWithEmptyValues(result).map((item) => {
                return {
                  ...item,
                  valid: true
                } as UserSocialLinksValidationType;
              })
            );
          },
          {}
        ]
      ],
      setLoading,
      dispatchToast
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewValue = (
    socialLinksType: UserSocialLinksValidationType,
    value?: string | null
  ) => {
    socialLinksType.value = value || "";
    socialLinksType.valid = true;
  };

  const updateSocialLinks = async () => {
    const socialLinksRequest = userSocialContacts
      .filter((item) => item.value && item.value.trim().length > 0)
      .reduce((map: { [key: string]: string }, item) => {
        map[item.name] = item.value;
        return map;
      }, {});

    const response = await setIgnoring404(
      SocialLinkApi.add,
      socialLinksRequest,
      setLoading,
      dispatchToast
    );
    if (response) {
      await updateUserProfileConnect({ socialLinks: response });
      const errorKeys = Object.keys(socialLinksRequest).filter(
        (key) => !response[key]
      );
      if (errorKeys.length > 0) {
        const socialContacts = [...userSocialContacts].map((item) => {
          return { ...item, valid: errorKeys.indexOf(item.name) === -1 };
        });
        setUserSocialContacts(socialContacts);
        return;
      }
      console.debug("[ONBOARDING] Successfully saved bucket list.");
    }
    isEditing ? history.goBack() : setCurrentStep(7);
  };

  return (
    <>
      <ClanIonContent>
        <OnboardingWrapper>
          <IonCol size="12" className="px-0 mt-12">
            <ClanFlowStepTemplate
              title={t("onboarding.connect_header")}
              subtitle={t("onboarding.connect_subtitle")}
              headerClassName="text-center"
              noContentMargin
            >
              <IonList className="mt-2">
                {userSocialContacts.map((socialLink) => (
                  <IonItem
                    key={`social_edit_${socialLink.name}`}
                    color={socialLink.valid ? undefined : "danger"}
                    className="border border-t-0 border-l-0 border-r-0 border-gray-200 border-solid"
                  >
                    <IonLabel
                      position="floating"
                      className="text-clanH4 text-black flex items-center pr-1"
                    >
                      <ClanSocialIcon
                        className="mr-2 h-full"
                        iconName={socialLink.name}
                      />{" "}
                      {socialLink.name}
                    </IonLabel>
                    <IonInput
                      className="text-clanP text-black"
                      value={socialLink.value}
                      onIonChange={(e) =>
                        setNewValue(socialLink, e.detail.value)
                      }
                    />
                  </IonItem>
                ))}
              </IonList>
            </ClanFlowStepTemplate>
          </IonCol>
        </OnboardingWrapper>
      </ClanIonContent>
      <FooterButtons>
        <SecondaryFooterButton
          disabled={isLoading}
          onClick={() => (isEditing ? history.goBack() : setCurrentStep(5))}
        >
          {isEditing ? t("onboarding.cancel") : t("general.back")}
        </SecondaryFooterButton>
        <PrimaryFooterButton disabled={isLoading} onClick={updateSocialLinks}>
          {isEditing ? t("onboarding.save") : t("onboarding.next")}
        </PrimaryFooterButton>
      </FooterButtons>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    userProfileStateConnect: state.userProfile
  }),
  mapDispatchToProps: {
    updateUserProfileConnect: updateUserProfile,
    dispatchToast: showToast
  },
  component: SocialLinksStep
});
