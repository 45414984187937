import { FilterGroupType } from "../types/index.d";
import { ViewSharingConfigurationItem } from "../../Configuration";

export const joinFilterGroupTypeId = (
  values?: FilterGroupType[]
): string | undefined => {
  if (!values || values.length === 0) return undefined;
  const groupIdsString = values
    ?.filter((filter) => !!filter.groupId)
    .map((groupFilter) => groupFilter.groupId)
    .join(",");
  return groupIdsString.length > 0 ? groupIdsString : undefined;
};

export const resolveConstantGroupFilter = (
  filters?: FilterGroupType[]
): ViewSharingConfigurationItem | undefined => {
  return filters?.find((filter) => filter && filter.groupId === undefined);
};
