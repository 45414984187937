import React from "react";
import { ListItemCard } from "./ListItemCard";
import styled from "styled-components";
import { H6 } from "../Typography";

const StyledListItem = styled(ListItemCard)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const StyledPrimaryText = styled(H6)<{ bold?: boolean }>`
  flex: 1;
  ${({ bold }) => bold && `font-weight: bold;`}
`;

interface CheckToggleProps {
  small?: boolean;
  selected?: boolean;
}

const CheckToggle = styled.div<CheckToggleProps>`
  width: ${({ small }) => (small ? "20" : "32")}px;
  height: ${({ small }) => (small ? "20" : "32")}px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ selected, theme }) => selected && `background: ${theme.primary}`}

  img {
    display: block;
    ${({ small }) => small && `width: 10px;`}
  }
`;

interface SelectableListItemProps {
  small?: boolean;
  bold?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

const SelectableListItem: React.FC<SelectableListItemProps> = ({
  selected,
  bold,
  small,
  children,
  ...props
}) => (
  <StyledListItem {...props}>
    <StyledPrimaryText bold={bold}>{children}</StyledPrimaryText>
    <CheckToggle selected={selected} small={small}>
      {selected && <img alt="" src="/assets/check-black.svg" />}
    </CheckToggle>
  </StyledListItem>
);

export default SelectableListItem;
