import { History } from "history";
import * as ROUTES from "../../routes";
import { FeedTypes } from "../Feeds";
import { analyticsLogEvent } from "../../Analytics";
import { AuthorizedApiResult } from "../ApiService";

export enum ActivityFeedTopic {
  GROUP_JOIN = "group.join",
  POST_SHARE = "post.share",
  POST_REPLY = "post.reply",
  POST_REACT = "post.react",
  QUESTION_SHARE = "question.share",
  QUESTION_REPLY = "question.reply",
  QUESTION_REACT = "question.react",
  ONBOARDING_UNFINISHED = "ob.pending",
  ONBOARDING_PROFILE_COMPLETE = "ob.profile.done",
  ONBOARDING_COMPLETE = "ob.done",
  PROFILE_IMAGE_UPLOADED = "image.uploaded.profile",
  USER_TASK_TODO_SHARE = "user.task.todo.share",
  USER_TASK_TODO_REMINDER = "user.task.todo.reminder",
  USER_TASK_TODO_SHARE_ACCEPT = "user.task.todo.share.accept",
  CHATBOT_QUESTION_NEW = "chatbot.question.new",
  CHATBOT_QUESTION_ANSWER = "chatbot.question.answered",
  NUDGE_GENERIC = "nudge.generic",
  USER_COMPLAINT = "user.complaint",
  DIRECT_MESSAGE = "message.direct",
  DIRECT_MESSAGE_REACT = "message.direct.react",
  WELL_BEING_GENERIC = "wellbeing.generic",
  GENERIC_MESSAGE = "message.generic"
}

export enum ActivityFeedId {
  GROUP_ID = "group_id",
  PROFILE_ID = "profile_id",
  USER_ID = "user_id",

  POST_ID = "post_id",
  POST_REPLY_ID = "post_reply_id",

  QUESTION_ID = "question_id",
  QUESTION_REPLY = "question_reply_id",

  REACTION = "reaction",

  USER_TASK_TODO_ID = "user_task_todo_id",

  NUDGE_ID = "nudge_id",
  NUDGE_ACTION = "nudge_action",

  COMPLAINT_ID = "complaint_id",
  TEXT = "text"
}

export type ActivityFeedPayload = { topic: ActivityFeedTopic } & {
  [key in string]: string;
};

export const navigateOnActivity = (
  history: History,
  notificationData: ActivityFeedPayload
) => {
  switch (notificationData.topic) {
    case ActivityFeedTopic.DIRECT_MESSAGE:
      history.push(
        ROUTES.DIRECT_MESSAGE.replace(
          ":profile_id",
          notificationData[ActivityFeedId.PROFILE_ID]
        )
      );
      break;
    case ActivityFeedTopic.DIRECT_MESSAGE_REACT:
      history.push(
        ROUTES.DIRECT_MESSAGE.replace(
          ":profile_id",
          notificationData[ActivityFeedId.PROFILE_ID]
        )
      );
      break;
    case ActivityFeedTopic.POST_SHARE:
      history.push(
        ROUTES.DISCUSSION_COMMENTS.replace(
          ":post_id",
          notificationData[ActivityFeedId.POST_ID]
        )
      );
      break;
    case ActivityFeedTopic.GROUP_JOIN:
      history.push(ROUTES.PEOPLE);
      break;
    case ActivityFeedTopic.POST_REPLY:
      history.push(
        ROUTES.DISCUSSION_COMMENTS.replace(
          ":post_id",
          notificationData[ActivityFeedId.POST_ID]
        )
      );
      break;
    case ActivityFeedTopic.POST_REACT:
      history.push(
        ROUTES.DISCUSSION_COMMENTS.replace(
          ":post_id",
          notificationData[ActivityFeedId.POST_ID]
        )
      );
      break;
    case ActivityFeedTopic.ONBOARDING_PROFILE_COMPLETE:
      history.push(ROUTES.ONBOARDING);
      break;
    case ActivityFeedTopic.ONBOARDING_COMPLETE:
      history.push(ROUTES.ONBOARDING);
      break;
    case ActivityFeedTopic.QUESTION_REPLY:
      history.push(
        ROUTES.QUESTION_COMMENTS.replace(
          ":post_id",
          notificationData[ActivityFeedId.QUESTION_ID]
        )
      );
      break;
    case ActivityFeedTopic.QUESTION_REACT:
      history.push(
        ROUTES.QUESTION_COMMENTS.replace(
          ":post_id",
          notificationData[ActivityFeedId.QUESTION_ID]
        )
      );
      break;
    case ActivityFeedTopic.QUESTION_SHARE: {
      history.push(
        `${ROUTES.QUESTION_COMMENTS.replace(
          ":post_id",
          notificationData[ActivityFeedId.QUESTION_ID]
        )}${
          notificationData.type === "auto_question" ? "?askConsent=true" : ""
        }`
      );
      break;
    }
    case ActivityFeedTopic.USER_TASK_TODO_REMINDER:
      history.push(
        ROUTES.PLANNER_TASK.replace(
          ":task_id",
          notificationData[ActivityFeedId.USER_TASK_TODO_ID]
        )
      );
      break;
    case ActivityFeedTopic.USER_TASK_TODO_SHARE:
      history.push(ROUTES.PUBLIC_FEEDS, {
        feedType: FeedTypes.TASK
      });
      break;
    case ActivityFeedTopic.USER_TASK_TODO_SHARE_ACCEPT:
      history.push(ROUTES.PUBLIC_FEEDS, {
        feedType: FeedTypes.TASK
      });
      break;
    case ActivityFeedTopic.CHATBOT_QUESTION_NEW:
    case ActivityFeedTopic.CHATBOT_QUESTION_ANSWER:
      history.push(ROUTES.CHATBOT);
      break;
    case ActivityFeedTopic.NUDGE_GENERIC:
      history.push(
        ROUTES.NUDGE_VIEW.replace(
          ":nudge_id",
          notificationData[ActivityFeedId.NUDGE_ID]
        ),
        {
          nudgeAction: notificationData[ActivityFeedId.NUDGE_ACTION]
        }
      );
      break;
    case ActivityFeedTopic.WELL_BEING_GENERIC:
      history.push(ROUTES.WELLBEING_SURVEY_VIEW);
      break;
    case ActivityFeedTopic.USER_COMPLAINT:
      history.push(ROUTES.STATS_DASHBOARD_ALERTS, {
        complaintId: notificationData[ActivityFeedId.COMPLAINT_ID]
      });
      break;
    case ActivityFeedTopic.GENERIC_MESSAGE:
      history.push(ROUTES.GENERIC_NOTIFICATION_MESSAGE, {
        notificationData
      });
      break;
  }
};

export type NotificationHistoryResult = {
  topic: ActivityFeedTopic;
  title: string;
  body?: string;
  icon?: string;
  data: { [key: string]: string }; // ActivityFeedId as key and id value as string
  created: string; // zoned date time
  pinned: boolean;
};

export const getNotificationHistory = async (): Promise<
  NotificationHistoryResult[]
> => {
  // noinspection ES6MissingAwait
  analyticsLogEvent("ACTIVITY_FEED_list");
  return AuthorizedApiResult.get<NotificationHistoryResult[]>(
    "/user/notification/v1/history"
  );
};
