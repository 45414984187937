import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  ClanPostListResult,
  ClanReactions
} from "../../../util/models/ClanPostModels";
import {
  filterReactionTypesToValues,
  ReactionTypes
} from "../../../util/Reactions";
import { FlexRow, ManSpreader } from "../../Flexbox";
import Icon from "../../Icon";
import IconBadge from "../../IconBadge";
import ReactionPopover from "../../ReactionPopover";
import Reactions from "../../Reactions";
import { H6Style } from "../../Typography";
import { PostType } from "../PostListItem";

const PostFooterWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.primary};
  margin: 8px 0;
`;

const CommentBar = styled.div`
  ${H6Style}
  width: 100%;
  flex: 1;
  padding: 12px;
  border-radius: 24px;
  background: #fafafa;
  cursor: pointer;
`;

interface ReactionIconProps {
  onClick?: () => void;
}

const ReactionIcon: React.FC<ReactionIconProps> = ({ onClick }) => (
  <Icon
    size="24px"
    src="/assets/reaction/add-icon.svg"
    onClick={onClick}
    alt="add reactions"
    className="cursor-pointer"
  />
);

interface PostFooterProps {
  post: PostType;
  showReactions?: boolean;
  isComment?: boolean;
  openComments?: () => void;
  onShare?: () => void;
  showReactionDetails?: () => void;
}

const PostFooter: React.FC<PostFooterProps> = ({
  post,
  showReactions,
  isComment,
  openComments,
  onShare,
  showReactionDetails
}) => {
  const { t } = useTranslation();

  const [showReactionsBar, setShowReactionsBar] = useState<boolean>(false);
  const [reactions, setReactions] = useState<ClanReactions>(
    post.reactions || {}
  );

  const hasReactions = useMemo(() => Object.keys(reactions).length, [
    reactions
  ]);

  const myMappedReactions = useMemo(
    () =>
      filterReactionTypesToValues(reactions).reduce(
        (myReactions, reaction) =>
          reaction.likedByMe
            ? myReactions.concat([reaction.reaction])
            : myReactions,
        [] as Array<ReactionTypes>
      ),
    [reactions]
  );

  const repliesAmount = (post as ClanPostListResult).repliesAmount || 0;
  const { sharing } = (post as ClanPostListResult) || {};

  const {
    groupsAmount = 0,
    profilesAmount = 0,
    organisationsAmount = 0,
    sharingShortcut
  } = sharing || {};

  const isShared =
    groupsAmount > 0 ||
    profilesAmount > 0 ||
    organisationsAmount > 0 ||
    !!sharingShortcut;

  return (
    <PostFooterWrapper>
      {(hasReactions ||
        repliesAmount > 0 ||
        isComment ||
        (!openComments && showReactions)) && (
        <>
          <FlexRow align="center" gap="8px">
            <Reactions reactions={reactions} onClick={showReactionDetails} />

            <ManSpreader />

            {openComments && repliesAmount > 0 && (
              <FlexRow
                onClick={openComments}
                columnGap="2px"
                align="center"
                className="cursor-pointer"
              >
                <span>{repliesAmount}</span>
                <IconBadge
                  size={32}
                  src="/assets/icon/comments.svg"
                  alt="add reactions"
                />
              </FlexRow>
            )}

            {showReactions && !openComments && (
              <ReactionIcon onClick={() => setShowReactionsBar(true)} />
            )}
          </FlexRow>

          {openComments && <Divider />}
        </>
      )}

      <FlexRow align="center" gap="8px">
        {showReactions && openComments && (
          <ReactionIcon onClick={() => setShowReactionsBar(true)} />
        )}

        {openComments && (
          <CommentBar onClick={openComments}>
            {t("clan_discussion_post.reply")}
          </CommentBar>
        )}

        {onShare && (
          <Icon
            onClick={onShare}
            src={`/assets/common/share${isShared ? "-green" : ""}.svg`}
            className="cursor-pointer"
          />
        )}
      </FlexRow>

      {showReactions && showReactionsBar && (
        <ReactionPopover
          post={post}
          myReactions={myMappedReactions}
          reactions={reactions}
          setReactions={setReactions}
          onClose={() => setShowReactionsBar(false)}
        />
      )}
    </PostFooterWrapper>
  );
};

export default PostFooter;
