/* eslint-disable @typescript-eslint/camelcase */
import { doRequest } from "../apiService";
import { configuration } from "../configuration";

// Google Classroom scopes: https://developers.google.com/classroom/guides/auth
export enum ClassroomScope {
  // Emails = "https://www.googleapis.com/auth/classroom.profile.emails",
  CoursesReadonly = "https://www.googleapis.com/auth/classroom.courses.readonly",
  RostersReadOnly = "https://www.googleapis.com/auth/classroom.rosters.readonly",
  CourseworkMeReadonly = "https://www.googleapis.com/auth/classroom.coursework.me.readonly"
  // AnnouncementsReadonly = "https://www.googleapis.com/auth/classroom.announcements.readonly"
}

export const CLASSROOM_SCOPES = [
  ClassroomScope.CoursesReadonly,
  ClassroomScope.RostersReadOnly,
  ClassroomScope.CourseworkMeReadonly
  // ClassroomScope.AnnouncementsReadonly
].join(" ");

const OAUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth`;
const REDIRECT_URL = `${configuration.apiUrl}/lms/v1/google-classroom/auth/callback`;
const CALLBACK_URL = `${window.location.href}?authorized=true`;

const GOOGLE_CLASSROOM_API_VERSION = 0;

export function buildOauthUrl(userId: number) {
  const state = btoa(
    JSON.stringify([GOOGLE_CLASSROOM_API_VERSION, userId, CALLBACK_URL])
  );

  const queryParams = new URLSearchParams({
    client_id: configuration.oauthClientId,
    redirect_uri: REDIRECT_URL,
    response_type: "code",
    access_type: "offline",
    prompt: "consent",
    include_granted_scopes: "true",
    scope: CLASSROOM_SCOPES,
    state
  });

  return `${OAUTH_URL}?${queryParams.toString()}`;
}

export function unauthorizeClassroom() {
  return doRequest("delete", "/lms/v1/google-classroom/auth");
}

export function authorizeByCode(accessCode: string) {
  return doRequest(
    "post",
    `/lms/v1/google-classroom/auth/device?code=${accessCode}`
  );
}

export function authorizeWeb(userId: number) {
  return new Promise((resolve, reject) => {
    let windowHandleClosed = false;

    const windowHandle = window.open(
      buildOauthUrl(userId),
      "_blank",
      "height=600,left=0,top=0",
      true
    );

    function closeWindow() {
      window.clearInterval(intervalId);
      windowHandle?.close();
      windowHandleClosed = true;
    }

    let loopCount = 2000;

    const intervalId = window.setInterval(() => {
      if (loopCount-- < 0) {
        closeWindow();
      } else if (windowHandle?.closed || windowHandleClosed) {
        window.clearInterval(intervalId);
        reject(new Error("USER_CANCELLED"));
      } else {
        let origin;
        try {
          origin = windowHandle?.location.origin;
          if (origin && CALLBACK_URL.indexOf(origin) === 0) {
            resolve(true);
            closeWindow();
          }
        } catch (e) {
          // User is currently on another domain and we can not access the href;
        }
      }
    }, 200);
  });
}
