import React from "react";
import styled from "styled-components";
import { H2, H4, H6 } from "../Typography";

interface HeadingWrapperProps {
  centered?: boolean;
}

const HeadingWrapper = styled.div<HeadingWrapperProps>`
  ${({ centered }) =>
    centered &&
    `
  text-align: center;
  margin: 0 auto;
  `}
`;

const Heading2Wrapper = styled(HeadingWrapper)`
  max-width: 80%;
  width: 100%;
`;

const Heading4Wrapper = styled(HeadingWrapper)`
  display: inline-block;

  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  div {
    margin-bottom: 0;
  }
`;

const StyledH2 = styled(H2)`
  margin-bottom: 0;
`;

const Divider = styled.div`
  width: 100%;
  margin: 8px 0;
  border-bottom: 2px solid ${(props) => props.theme.primary};
`;

interface HeadingProps {
  title: string;
  description?: string;
  centered?: boolean;
}

export const Heading2: React.FC<HeadingProps> = ({
  title,
  description,
  centered
}) => (
  <Heading2Wrapper centered={centered}>
    <StyledH2>{title}</StyledH2>
    <Divider />
    {description && <H6>{description}</H6>}
  </Heading2Wrapper>
);

export const Heading4: React.FC<HeadingProps> = ({
  title,
  description,
  centered
}) => (
  <Heading4Wrapper centered={centered}>
    <H4>{title}</H4>
    <Divider />
    {description && <H6>{description}</H6>}
  </Heading4Wrapper>
);
