import { FeedTypes } from "./Feeds";
import { waitUntilElementExists } from "./HTMLUtils";

export function scrollToItem(itemId: string, feedType: FeedTypes) {
  const itemIdentifier = feedType === FeedTypes.TASK ? "task" : "post";

  waitUntilElementExists(`[data-${itemIdentifier}-id="${itemId}"]`, 2000)
    .then((element) => {
      // Timeout because ionic ultra slow scrolling blocks our scrolling
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }, 100);
    })
    .catch(() => {
      console.error("Did not find post to scroll to!", itemId, feedType);
    });
}
