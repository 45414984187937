import { IonPage, IonRange } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import styled from "styled-components";

import CategoryHeader from "../../components/CategoryHeader";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import { SmoothRays } from "../../components/ClanSmoothRays";
import SettingsButton from "../../components/SettingsButton";
import SubPageHeader from "../../components/SubPageHeader";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import * as ROUTES from "../../routes";
import {
  disableGroupLms,
  enableGroupLms,
  getGroupDetailsById,
  GroupDetailsResponse,
  GroupService,
  removeWellBeingForGroup,
  switchToWellBeingInGroup
} from "../../util/api/OrganisationGroupService";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";

const SectionContent = styled.div`
  max-width: 90%;
  margin: 0 auto;
`;

const WellbeingSwitch = styled.div<{ disabled: boolean }>`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 1.4rem 0.7rem;
  grid-gap: 1.4rem;
  ${({ disabled }) => disabled && "opacity: 0.6;"}

  .range-tick {
    display: none;
  }

  p {
    margin: 0;
  }
`;

const StyledRange = styled(IonRange)`
  --bar-background: ${({ theme }) => theme.primary};
`;

const WellbeingSwitchLabels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Gilroy;

  > div {
    margin-right: 1.6rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

interface StateProps {
  editWellBeing: boolean;
  editLMS: boolean;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface GroupPreferencesProps
  extends RouteComponentProps,
    StateProps,
    DispatchProps {}

const GroupPreferences: React.FC<GroupPreferencesProps> = ({
  editWellBeing,
  editLMS,
  dispatchToast
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { groupId } = useParams<{ groupId: string }>();

  const [group, setGroup] = useState<GroupDetailsResponse | undefined>(
    undefined
  );

  const wellbeingQuestionsEnabled =
    group &&
    editWellBeing &&
    group.availableWellBeings.some((key) =>
      group.enabledServices.includes(key)
    );

  const classroomEditingEnabled =
    group && editLMS && group.availableLms.includes(GroupService.Classroom);

  const serviceEnabled = (service: string) =>
    group?.enabledServices.includes(service);

  useEffect(() => {
    getGroupDetailsById(Number(groupId))
      .then(setGroup)
      .catch((e) => console.error("Failed to fetch group details", e));
  }, [groupId]);

  const onClose = () => {
    history.push(ROUTES.GROUP_VIEW.replace(":groupId", groupId.toString()));
  };

  const toggleWellbeing = () => {
    if (wellbeingQuestionsEnabled) {
      disableWellbeingQuestions();
    } else {
      const wellbeingToActivate = group?.availableWellBeings[0];
      if (wellbeingToActivate) enableWellbeingQuestions(wellbeingToActivate);
      dispatchToast(
        t("group_preferences_view.wellbeing_enabled"),
        undefined,
        "info",
        [
          {
            label: t("general.skip")
          },
          {
            label: t("general.yes"),
            href: "https://www.clanbeat.com/well-being-feature-deep-dive/",
            primary: true
          }
        ]
      );
    }
  };

  const onWellbeingChange = (e: any) => {
    const wellbeingToActivate =
      group?.availableWellBeings[Number(e.target.value) - 1];

    if (wellbeingToActivate) enableWellbeingQuestions(wellbeingToActivate);
  };

  const enableWellbeingQuestions = (wellbeingToEnable: string) =>
    switchToWellBeingInGroup(Number(groupId), wellbeingToEnable)
      .then(setGroup)
      .catch((ex: any) =>
        console.error("Failed to activate wellbeing for group", ex)
      );

  const disableWellbeingQuestions = () =>
    removeWellBeingForGroup(Number(groupId))
      .then(setGroup)
      .catch((e) => console.error("Failed to turn off wellbeing for group", e));

  const enableGoogleClassroom = () =>
    enableGroupLms(Number(groupId), GroupService.Classroom)
      .then(setGroup)
      .catch((e) =>
        console.error("Failed to enable google classroom for group", e)
      );

  const disableGoogleClassroom = () =>
    disableGroupLms(Number(groupId), GroupService.Classroom)
      .then(setGroup)
      .catch((e) =>
        console.error("Failed to disable google classroom for group", e)
      );

  return (
    <ClanDesktopLayout>
      <IonPage>
        <ClanIonContent>
          <SmoothRays />

          <SubPageHeader
            title={t("group_preferences_view.title")}
            onBack={onClose}
          />

          {!!group && (
            <>
              <CategoryHeader
                title={t("group_preferences_view.check_in_settings")}
                actions={
                  editWellBeing
                    ? [
                        {
                          title: t(
                            `group_preferences_view.${
                              wellbeingQuestionsEnabled
                                ? "turn_off"
                                : "activate"
                            }`
                          ),
                          action: toggleWellbeing
                        }
                      ]
                    : []
                }
              />

              <SectionContent>
                <WellbeingSwitch disabled={!wellbeingQuestionsEnabled}>
                  <p>{t("group_preferences_view.wellbeing_switch_info")}</p>
                  <div>
                    <StyledRange
                      mode="ios"
                      value={
                        (group?.availableWellBeings.findIndex((key) =>
                          group?.enabledServices.includes(key)
                        ) || 0) + 1
                      }
                      min={1}
                      max={group?.availableWellBeings.length}
                      step={1}
                      snaps
                      ticks={false}
                      disabled={!wellbeingQuestionsEnabled}
                      onIonChange={onWellbeingChange}
                    />
                    <WellbeingSwitchLabels>
                      {group?.availableWellBeings.map((key) => (
                        <div key={key}>
                          {t(`group_preferences_view.${key}`)}
                        </div>
                      ))}
                    </WellbeingSwitchLabels>
                  </div>
                </WellbeingSwitch>
              </SectionContent>

              {classroomEditingEnabled && (
                <>
                  <CategoryHeader
                    title={t("group_preferences_view.integrations")}
                  />
                  <SectionContent>
                    <SettingsButton
                      title={t(
                        `group_preferences_view.${
                          serviceEnabled(GroupService.Classroom)
                            ? "disable"
                            : "enable"
                        }_classroom`
                      )}
                      indicator={
                        <img
                          src="assets/classroom.svg"
                          alt={t(
                            `group_preferences_view.${
                              serviceEnabled(GroupService.Classroom)
                                ? "disable"
                                : "enable"
                            }_classroom`
                          )}
                        />
                      }
                      onClick={
                        serviceEnabled(GroupService.Classroom)
                          ? disableGoogleClassroom
                          : enableGoogleClassroom
                      }
                    />
                  </SectionContent>
                </>
              )}
            </>
          )}
        </ClanIonContent>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: ({ token }) => ({
    editWellBeing: UserEnabledProperties.Group.editWellBeing(token),
    editLMS: UserEnabledProperties.Group.editLMS(token)
  }),
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: GroupPreferences
});
