import { IonButton, IonFooter, IonModal } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";
import * as ROUTES from "../../routes";
import { UserTaskResult } from "../../util/models/PlannerUserTaskModels";
import ClanHeaderRays from "../ClanHeaderRays";
import ClanLlamaMessage from "../ClanLlamaMessage";

export type ClanTaskCompleteModalProps = {
  taskCompleted: UserTaskResult;
  setTaskCompleted: (taskCompleted: UserTaskResult | undefined) => void;
};

const ClanTaskCompleteModal: React.FC<ClanTaskCompleteModalProps> = ({
  setTaskCompleted,
  taskCompleted
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <IonModal
      isOpen={!!taskCompleted}
      onDidDismiss={() => setTaskCompleted(undefined)}
    >
      <ClanHeaderRays short />
      <ClanIonContent>
        <ClanLlamaMessage
          messages={[
            t("planner.task_completed", {
              taskTitle: taskCompleted.title
            })
          ]}
        />
      </ClanIonContent>
      <IonFooter className="ion-no-border">
        <div
          className="h-clanBtn flex justify-center items-center text-center"
          onClick={() => {
            setTaskCompleted(undefined);
          }}
        >
          <h6 className="cursor-pointer w-3/4">{t("planner.skip")}</h6>
        </div>
        <IonButton
          expand="block"
          className="my-4 mx-20 font-extrabold font-gilroy h-16"
          style={{ "--border-radius": "100px" }}
          onClick={() => {
            history.push({
              pathname: ROUTES.REFLECTIONS,
              state: {
                reflectionTaskId: taskCompleted.id
              }
            });
            setTaskCompleted(undefined);
          }}
        >
          {t("planner.reflect")}
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};

export default ClanTaskCompleteModal;
