import { IonSelect, IonSelectOption } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import ClanFlowStepTemplate from "../../components/ClanFlowStepTemplate";
import FooterButtons, {
  PrimaryFooterButton,
  SecondaryFooterButton
} from "../../components/FooterButtons";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import { updateUserProfile } from "../../data/user/profile/profile.actions";
import { UserProfileState } from "../../data/user/profile/profile.state";
import {
  ProfileLanguagesAPI,
  ProfileNameKey
} from "../../util/api/ProfileService";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "./Onboarding";
import { fetchIgnoring404, setIgnoring404 } from "./OnboardingHelper";

interface OwnProps {
  setCurrentStep: any;
  isEditing: any;
}

interface DispatchProps {
  updateUserProfileConnect: typeof updateUserProfile;
  dispatchToast: typeof showToast;
}

interface StateProps {
  userProfileStateConnect: UserProfileState;
}

interface LanguagesStepProps extends StateProps, DispatchProps, OwnProps {}

const LanguagesStep: React.FC<LanguagesStepProps> = ({
  setCurrentStep,
  isEditing,
  dispatchToast,
  updateUserProfileConnect
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const [languages, setLanguages] = useState<ProfileNameKey[]>([]);
  const [userLanguages, setUserLanguages] = useState<ProfileNameKey[]>([]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchIgnoring404(
      [
        [ProfileLanguagesAPI.list, setLanguages, []],
        [ProfileLanguagesAPI.get, setUserLanguages, []]
      ],
      (loading: boolean) => setLoading(loading),
      dispatchToast
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLanguage = async () => {
    const values = userLanguages.map((language: any) => language.key);
    const response = await setIgnoring404(
      ProfileLanguagesAPI.put,
      values,
      (loading: boolean) => setLoading(loading),
      dispatchToast
    );
    if (response) {
      console.debug("[Onboarding_Page] Successfully saved languages.");
      updateUserProfileConnect({ languages: response });
      isEditing ? history.goBack() : setCurrentStep(4);
    }
  };

  return (
    <>
      <ClanIonContent>
        <OnboardingWrapper>
          <ClanFlowStepTemplate
            title={t("onboarding.languages_header")}
            headerClassName="text-center"
          >
            <div className="text-center">
              <IonSelect
                name="languages"
                placeholder={t("onboarding.languages")}
                className="text-clanH2 h-full font-extrabold font-gilroy"
                multiple={true}
                onIonChange={(e): void => {
                  setUserLanguages(e.detail.value);
                }}
                value={userLanguages}
                compareWith={(l1, l2) => {
                  return Array.isArray(l2)
                    ? l2.some((l) => l.key === l1.key)
                    : l2.key === l1.key;
                }}
              >
                {languages.map((language: { name: string; key: string }) => {
                  return (
                    <IonSelectOption key={language.key} value={language}>
                      {language.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </div>
          </ClanFlowStepTemplate>
        </OnboardingWrapper>
      </ClanIonContent>
      <FooterButtons>
        <SecondaryFooterButton
          disabled={isLoading}
          onClick={() => (isEditing ? history.goBack() : setCurrentStep(1))}
        >
          {isEditing ? t("onboarding.cancel") : t("general.back")}
        </SecondaryFooterButton>
        <PrimaryFooterButton disabled={isLoading} onClick={updateLanguage}>
          {isEditing ? t("onboarding.save") : t("onboarding.next")}
        </PrimaryFooterButton>
      </FooterButtons>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    userProfileStateConnect: state.userProfile
  }),
  mapDispatchToProps: {
    updateUserProfileConnect: updateUserProfile,
    dispatchToast: showToast
  },
  component: LanguagesStep
});
