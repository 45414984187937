import React, { useMemo } from "react";
import styled from "styled-components";

import { ClanReactions } from "../util/models/ClanPostModels";
import {
  filterReactionTypesToValues,
  ReactionTypes,
  sortAndFilterReactionTypes
} from "../util/Reactions";
import { FlexRow } from "./Flexbox";
import Icon from "./Icon";

const ReactionsWrapper = styled(FlexRow)<{ onClick?: any }>`
  ${({ onClick }) => !!onClick && "cursor: pointer;"}
`;

interface ReactionsProps {
  reactions: ClanReactions;
  onClick?: () => void;
}

const Reactions: React.FC<ReactionsProps> = ({ reactions, onClick }) => {
  const mappedReactions = useMemo(() => {
    return filterReactionTypesToValues(reactions).reduce(
      ({ myReactions, total }, reaction) => ({
        myReactions: reaction.likedByMe
          ? myReactions.concat([reaction.reaction])
          : myReactions,
        total: total + reaction.amount
      }),
      {
        myReactions: [] as Array<ReactionTypes>,
        total: 0
      }
    );
  }, [reactions]);

  return (
    <ReactionsWrapper align="center" columnGap="4px" onClick={onClick}>
      <FlexRow align="center" columnGap="2px">
        {sortAndFilterReactionTypes(reactions)
          .slice(0, 3)
          .map((reactionItem, i) => (
            <Icon
              size="24px"
              key={i}
              src={`/assets/reaction/${reactionItem.toLowerCase()}.svg`}
            />
          ))}
      </FlexRow>

      {mappedReactions.total > 3 && <span>+ {mappedReactions.total - 3}</span>}
    </ReactionsWrapper>
  );
};

export default Reactions;
