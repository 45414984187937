export const DEFAULT = "/";

export const START = "/start";
export const SIGN_UP = "/signup";
export const SETTINGS = "/settings";

// Tabs below belong to home page tabbed interface
export const HOME = "/home";
export const DASHBOARD = "/home/dashboard";
export const STATS_DASHBOARD = "/home/stats";
export const STATS_DASHBOARD_MOOD = "/home/stats/mood";
export const STATS_DASHBOARD_CHECK_IN = "/home/stats/mood/checkin/:questionKey";
export const STATS_DASHBOARD_ALERTS = "/home/stats/alerts";
export const STATS_DASHBOARD_FLAG = "/home/stats/alerts/flags/:flag";
export const STATS_DASHBOARD_REPORT = "/home/stats/alerts/reports/:report";
export const STATS_DASHBOARD_USAGE = "/home/stats/usage";
export const REPORT = "/home/stats/report";
export const ACTIVITY = "/home/activity";
export const PROFILE = "/home/profile";
export const PUBLIC_PROFILE = "/home/profile/:profileId";
export const PROFILE_TRENDS = "/home/profile/:profileId/trends";
export const CHATBOT = "/home/chatbot";
export const PROFILE_FEED_COMMENTS = "/home/profile/:post_id/comments";
export const PUBLIC_PROFILE_FEED_COMMENTS =
  "/home/profile/:profileId/:post_id/comments";
export const PUBLIC_FEEDS = "/home/feeds";
export const DISCUSSION_COMMENTS = "/home/feeds/discussion/:post_id/comments";
export const QUESTION_COMMENTS = "/home/feeds/question/:post_id/comments";
export const ANNOUNCEMENT_COMMENTS =
  "/home/feeds/announcement/:post_id/comments";
export const TASK_COMMENTS = "/home/feeds/task/:task_id/comments";
export const SHARED_FEED_COMMENTS = "/home/feeds/shared/:post_id/comments";
export const PEOPLE = "/home/people";
export const ONBOARDING = "/home/onboarding";
export const PLANNER_DAY = "/home/planner/day";
export const PLANNER_TASK = "/home/planner/task/:task_id";
export const PLANNER_EXISTING = "/home/planner/existing";
export const NUDGE_VIEW = "/home/nudging/:nudge_id";
export const DIRECT_MESSAGE = "/home/directmessage/:profile_id";
export const MESSAGES_HUB = "/home/messages";
export const WELLBEING_SURVEY_VIEW = "/home/survey";
export const PERSONALIZE_VIEW = "/home/personalize";
export const EDIT_BIO_VIEW = "/home/personalize/bio";

export const GROUPS_VIEW = "/home/groups";
export const GROUP_VIEW = "/home/group/:groupId";
export const GROUP_PREFERENCES_VIEW = "/home/group/:groupId/preferences";
export const GROUP_ADD_MEMBERS_VIEW = "/home/group/:groupId/add";

export const QUICK_HELP_VIEW = "/home/help";
export const REFLECTIONS = "/home/reflections";

export const GENERIC_NOTIFICATION_MESSAGE =
  "/home/notification/message/generic";

export const SETTINGS_ORGANISATIONS = "/home/settings/organisations";
export const GET_STARTED = "/home/get-started";
