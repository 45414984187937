import { OauthState } from "../../data/oauth/oauth.state";
import { OauthData } from "../../data/oauth/oauthDataService";
import { IdentityAware } from "../models/ClanProfileModels";
import { hasNoValue } from "../NullSafe";

type PropsType =
  | string[]
  | OauthState
  | OauthData
  | IdentityAware
  | undefined
  | null;

const resolveProps = (props: PropsType): string[] => {
  if (hasNoValue(props)) return [];
  if (Array.isArray(props)) {
    return props as string[];
  }
  if (hasNoValue((props as IdentityAware)?.identity)) {
    if (
      !(props as OauthData)?.hasToken ||
      !(props as OauthData)?.enabledProperties
    )
      return [];
    return (props as OauthData)?.enabledProperties;
  }
  return (props as IdentityAware)?.identity;
};

enum UserEnabledProperty {
  STATS_MOOD,
  STATS_MOOD_MY_PROFILE,
  STATS_USAGE,
  STATS_SCHOOL_FLAGS,
  STATS_SCHOOL,
  STATS_INDIVIDUAL_FLAGS,

  TEACHER_BADGE,
  ANTI_BULLY_REPORT,
  STUDENT,
  ADULT,

  WELL_BEING_MY_PROFILE,
  WELL_BEING_OTHER_PROFILE,
  WELL_BEING_DASHBOARD,

  GROUP_HUB,
  GROUP_LIST_LEAD_MINE,
  GROUP_LIST_MINE,
  GROUP_EDIT_NAME,
  GROUP_INV_CODE_SHOW,
  GROUP_INV_CODE_REFRESH,
  GROUP_PREF_WELL_BEING,
  GROUP_PREF_LMS,
  GROUP_CREATE,

  FEEDBACK_TEACHER,
  FEEDBACK_STUDENT,

  STUDENT_CHAT_STAFF,

  GROUP_MANAGE_DISABLED_USERS,
  GROUP_MANAGE_MANAGE_MEMBER,
  GROUP_MANAGE_INVITE_EMAIL,
  GROUP_MANAGE_GRANT_LEAD,
  GROUP_MANAGE_REVOKE_LEAD,
  GROUP_MANAGE_DISABLE_ENABLE_USER,
  GROUP_MANAGE_REMOVE_USER,
  GROUP_MANAGE_SEE_DISABLED_USERS,
  GROUP_MANAGE_SEE_PENDING_INVITATIONS,
  POST_ANNOUNCEMENT,

  SHARE_TASK
}

export type USER_IDENTITIES =
  | "GROUP_LEAD"
  | "TEACHER_BADGE"
  | "STUDENT"
  | "ADULT";

const hasPropertyAsString = (property: string, props: PropsType): boolean => {
  return resolveProps(props).includes(property);
};

const hasProperty = (
  property: UserEnabledProperty,
  props: PropsType
): boolean => {
  return hasPropertyAsString(UserEnabledProperty[property], props);
};

export const UserEnabledProperties = {
  AnalyticsStatus: {
    moodStats: (props: PropsType) =>
      hasProperty(UserEnabledProperty.STATS_MOOD, props),
    myOwnMood: (props: PropsType) =>
      hasProperty(UserEnabledProperty.STATS_MOOD_MY_PROFILE, props),
    usageStats: (props: PropsType) =>
      hasProperty(UserEnabledProperty.STATS_USAGE, props),
    schoolFlags: (props: PropsType) =>
      hasProperty(UserEnabledProperty.STATS_SCHOOL_FLAGS, props),
    schoolStats: (props: PropsType) =>
      hasProperty(UserEnabledProperty.STATS_SCHOOL, props),
    individualFlags: (props: PropsType) =>
      hasProperty(UserEnabledProperty.STATS_INDIVIDUAL_FLAGS, props)
  },
  UserStatus: {
    hasTeacherBadge: (props: PropsType) =>
      hasProperty(UserEnabledProperty.TEACHER_BADGE, props)
  },
  // Legacy roles should be replaced with granular properties in future
  Legacy: {
    isAdult: (props: PropsType) =>
      resolveProps(props).filter((e) => !!e).length > 0 && // It is a hack but we assume that without token there is no props
      !UserEnabledProperties.Legacy.isStudent(props),
    isStudent: (props: PropsType) =>
      hasProperty(UserEnabledProperty.STUDENT, props)
  },
  General: {
    hasTeacherFeedback: (props: PropsType) =>
      hasProperty(UserEnabledProperty.FEEDBACK_TEACHER, props),
    hasStudentFeedback: (props: PropsType) =>
      hasProperty(UserEnabledProperty.FEEDBACK_STUDENT, props)
  },
  WellBeing: {
    myProfile: (props: PropsType) =>
      hasProperty(UserEnabledProperty.WELL_BEING_MY_PROFILE, props),
    othersProfile: (props: PropsType) =>
      hasProperty(UserEnabledProperty.WELL_BEING_OTHER_PROFILE, props),
    dashboard: (props: PropsType) =>
      hasProperty(UserEnabledProperty.WELL_BEING_DASHBOARD, props)
  },
  Accessibility: {
    antiBullyReport: (props: PropsType) =>
      hasProperty(UserEnabledProperty.ANTI_BULLY_REPORT, props),
    studentTalkToAdult: (props: PropsType) =>
      hasProperty(UserEnabledProperty.STUDENT_CHAT_STAFF, props),
    canPostAnnouncement: (props: PropsType) =>
      hasProperty(UserEnabledProperty.POST_ANNOUNCEMENT, props)
  },
  Group: {
    groupHub: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_HUB, props),
    readGroupListAsLead: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_LIST_LEAD_MINE, props),
    readGroupList: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_LIST_MINE, props),
    createGroup: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_CREATE, props),
    readAccessCode: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_INV_CODE_SHOW, props),
    refreshAccessCode: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_INV_CODE_REFRESH, props),
    editWellBeing: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_PREF_WELL_BEING, props),
    editLMS: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_PREF_LMS, props)
  },
  ManageGroup: {
    disabledUsers: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_MANAGE_DISABLED_USERS, props),
    manageMembers: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_MANAGE_MANAGE_MEMBER, props),
    emailInvite: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_MANAGE_INVITE_EMAIL, props),
    grantLead: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_MANAGE_GRANT_LEAD, props),
    revokeLead: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_MANAGE_REVOKE_LEAD, props),
    disableEnableUser: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_MANAGE_DISABLE_ENABLE_USER, props),
    removeUser: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_MANAGE_REMOVE_USER, props),
    viewDisabledUsers: (props: PropsType) =>
      hasProperty(UserEnabledProperty.GROUP_MANAGE_SEE_DISABLED_USERS, props),
    seePendingInvitations: (props: PropsType) =>
      hasProperty(
        UserEnabledProperty.GROUP_MANAGE_SEE_PENDING_INVITATIONS,
        props
      )
  },
  LMS: {
    googleClassroom: {
      authorized: (props: PropsType) =>
        hasPropertyAsString("LMS_AUTHORIZED_GOOGLE-CLASSROOM", props),
      unAuthorized: (props: PropsType) =>
        hasPropertyAsString("LMS_UNAUTHORIZED_GOOGLE-CLASSROOM", props),
      enabled: (props: PropsType) =>
        UserEnabledProperties.LMS.googleClassroom.authorized(props) ||
        UserEnabledProperties.LMS.googleClassroom.unAuthorized(props)
    }
  },
  TASK: {
    canShare: (props: PropsType) =>
      hasProperty(UserEnabledProperty.SHARE_TASK, props)
  }
};
