import { IonContent, IonModal } from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";

import { SecondaryButton } from "../../components/Buttons/Button";
import { SmoothRays } from "../../components/ClanSmoothRays";
import { ReactComponent as LlamaIcon } from "../../icons/llama.svg";
import * as ROUTES from "../../routes";

const ModalLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const ContentWrapper = styled.div`
  max-width: 250px;
  margin: 0 auto;
  align-self: center;
  text-align: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  margin: 0 auto 20px;
`;

const LlamaImage = styled(LlamaIcon)`
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  width: 100%;
  display: block;
`;

const SkipBtn = styled.div`
  font-family: Gilroy;
  font-size: 14px;
  line-height: 48px;
  height: 48px;
  text-align: center;
  cursor: pointer;
  padding: 0 24px;
  color: #878396;
  letter-spacing: 0.2px;
`;

interface QuickHelpModalProps {
  onClose: () => void;
}

const QuickHelpModal: React.FC<QuickHelpModalProps> = ({
  children,
  onClose
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory();

  const { t } = useTranslation();

  const openQuickHelp = () => {
    setIsOpen(false);
    history.push(ROUTES.QUICK_HELP_VIEW);
  };
  const onSkip = () => setIsOpen(false);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonContent>
        <ModalLayout>
          <SmoothRays showInDesktop />
          <ContentWrapper>
            <LlamaImage />
            {children}
          </ContentWrapper>
          <ActionsWrapper>
            <SkipBtn onClick={onSkip}>{t("general.skip")}</SkipBtn>
            <SecondaryButton onClick={openQuickHelp}>
              {t("quick_help_modal.primary_action")}
            </SecondaryButton>
          </ActionsWrapper>
        </ModalLayout>
      </IonContent>
    </IonModal>
  );
};

export default QuickHelpModal;
