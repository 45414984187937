type CachedDataType<RESULT> = {
  timestamp: number;
  data: RESULT;
};

const ALL_CACHED_DATA: Map<string, CachedDataType<any>>[] = [];

export const clearCachedData = () => {
  try {
    ALL_CACHED_DATA.forEach((m) => m.clear());
  } catch (error) {
    console.error("Error while clearing the cache", error);
  }
};

export function getCachedMapHolder<RESULT>(
  timeoutInMills: number,
  method: (key: string) => Promise<RESULT>
): (key: string) => Promise<RESULT> {
  const CACHED_DATA = new Map<string, CachedDataType<RESULT>>();
  ALL_CACHED_DATA.push(CACHED_DATA);

  return async (key: string): Promise<RESULT> => {
    const time = new Date().getTime();

    const cachedData = CACHED_DATA.get(key);
    if (cachedData) {
      const cachedTime = cachedData.timestamp;
      if (cachedData.timestamp > 0 && cachedTime && cachedTime > time) {
        return cachedData.data;
      }
    }

    const response = await method(key);
    const result = {
      timestamp:
        timeoutInMills > 0
          ? new Date().getTime() + timeoutInMills
          : timeoutInMills,
      data: response
    } as CachedDataType<RESULT>;
    CACHED_DATA.set(key, result);
    return result.data;
  };
}

export function getCachedHolder<RESULT>(
  timeoutInSeconds: number,
  method: () => Promise<RESULT>
): () => Promise<RESULT> {
  const cache = getCachedMapHolder<RESULT>(timeoutInSeconds, method);
  return () => cache("DEFAULT");
}
