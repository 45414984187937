import { ImageState } from "./image.state";
import { withLoading } from "../../app/app.actions";
import { Dispatch } from "react";

export const SET_USER_PROFILE_IMAGE = "set-user-profile-image";

export type ProfileImageAction = {
  type: string;
  data: ImageState;
};

export const getSetProfileImageActionPayload = (imageState: ImageState) =>
  ({
    type: SET_USER_PROFILE_IMAGE,
    data: imageState
  } as ProfileImageAction);

export const setUserProfileImage = (
  userProfileImageState: ImageState
) => async (dispatch: Dispatch<any>) => {
  withLoading("image.actions:setUserProfileImage", async () => {
    dispatch(getSetProfileImageActionPayload(userProfileImageState));
  })(dispatch);
};
