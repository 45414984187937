import { css, CSSObject } from "styled-components";
export const maxWidthStyles = (maxWidth: number, styles: CSSObject) => {
  return `@media screen and (max-width: ${maxWidth}px) {
    ${css(styles).join("")}
  }`;
};
export const minWidthStyles = (minWidth: number, styles: CSSObject) => {
  return `@media screen and (min-width: ${minWidth}px) {
    ${css(styles).join("")}
  }`;
};

export const mobileOnlyStyles = (styles: CSSObject) => {
  return maxWidthStyles(
    Number(process.env.REACT_APP_CONFIG_DESKTOP_BREAKPOINT),
    styles
  );
};

export const desktopOnlyStyles = (styles: CSSObject) => {
  return minWidthStyles(
    Number(process.env.REACT_APP_CONFIG_DESKTOP_BREAKPOINT),
    styles
  );
};
