/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */
import { initializeApp } from "firebase/app";

type ConfigInterface = {
  oauthClientId: string;
  oauthClientIdAndroid: string;
  oauthClientIdIOS: string;
  apiUrl: string;
  showStoreLogs: boolean;
  rollbarAccessToken: string;
  appVersion: string;
  appBuild: string;
};

export const configuration: ConfigInterface = {
  oauthClientId: process.env.REACT_APP_OAUTH_CLIENT_ID || "",
  oauthClientIdAndroid: process.env.REACT_APP_OAUTH_CLIENT_ID_ANDROID || "",
  oauthClientIdIOS: process.env.REACT_APP_OAUTH_CLIENT_ID_IOS || "",
  apiUrl: process.env.REACT_APP_API_ENDPOINT || "",
  showStoreLogs: process.env.REACT_APP_SHOW_STORE_STATUS_CHANGE === "true",
  rollbarAccessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || "",
  appVersion: process.env.REACT_APP_VERSION || "",
  appBuild: process.env.REACT_APP_BUILD || ""
};

// Firebase settings object
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const firebaseApp = initializeApp(firebaseConfig);

if (process.env.REACT_APP_CONFIG_DISABLE_LOGS === "true") {
  console.log = () => {};
  console.debug = () => {};
  console.error = () => {};
}
