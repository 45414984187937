import i18n from "../i18n";
import { FeedTypes } from "./Feeds";

export function getSharingLabel(
  profiles: Array<number>,
  groups: Array<number>,
  roles: Array<string>
) {
  if (profiles.length > 0) {
    return `${profiles.length} ${i18n.t("clan_share_post.person", {
      count: profiles.length
    })}`;
  } else if (groups.length > 0) {
    return `${groups.length} ${i18n.t("clan_share_post.group", {
      count: groups.length
    })}`;
  } else if (roles.length > 0) {
    return `${roles.length} ${i18n.t("clan_share_post.role", {
      count: roles.length
    })}`;
  }
}

export function getPostPlaceholder(
  hasParentPost: boolean,
  feedType: FeedTypes
) {
  switch (feedType) {
    case FeedTypes.PERSONAL:
    case FeedTypes.SHARED_REFLECTIONS:
      return hasParentPost
        ? i18n.t("clan_edit_post.reflection_reply_placeholder")
        : i18n.t("clan_edit_post.reflection_placeholder");
    case FeedTypes.ANNOUNCEMENT_FEED:
      return hasParentPost
        ? i18n.t("clan_edit_post.announcement_reply_placeholder")
        : i18n.t("clan_edit_post.post_placeholder");
    case FeedTypes.QUESTION:
      return hasParentPost
        ? i18n.t("clan_edit_post.question_reply_placeholder")
        : i18n.t("clan_edit_post.question_placeholder");
    default:
      return hasParentPost
        ? i18n.t("clan_edit_post.post_reply_placeholder")
        : i18n.t("clan_edit_post.post_placeholder");
  }
}
