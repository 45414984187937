import React, { useEffect, useRef, useState } from "react";
import { IonAlert, IonTextarea } from "@ionic/react";
import ClanQuestionModal from "../ClanQuestionModal";
import { reportPost } from "../../util/ClanPostAPIService";
import { useTranslation } from "react-i18next";

interface ClanDiscussionPostReportModalProps {
  title: string;
  postId: string;
  onDone?: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const ClanDiscussionPostReportModal: React.FC<ClanDiscussionPostReportModalProps> = ({
  title,
  postId,
  isOpen,
  onDone,
  onCancel
}) => {
  const [text, setText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setText("");
    }
  }, [isOpen]);

  const reportTextRef = useRef<HTMLIonTextareaElement>(null);

  useEffect(() => {
    const reportTextarea = reportTextRef.current?.querySelector(
      ".native-textarea"
    );
    reportTextarea &&
      reportTextarea.setAttribute(
        "style",
        `height:${reportTextarea.scrollHeight}px`
      );
  }, [text]);

  return (
    <>
      <IonAlert
        isOpen={showConfirmation}
        mode="ios"
        cssClass="text-clanGray-200"
        onDidDismiss={() => setShowConfirmation(false)}
        header={t("clan_discussion_post.report_submitted")}
        message={t("clan_discussion_post.report_message")}
        buttons={[
          {
            text: t("general.ok"),
            handler: () => onDone && onDone(),
            cssClass: "text-black"
          }
        ]}
      />
      <ClanQuestionModal
        title={title}
        onYes={() => {
          setLoading(true);
          reportPost(postId, text)
            .then(() => {
              setLoading(false);
              setShowConfirmation(true);
            })
            .catch((error) => {
              setLoading(false);
              console.error(error);
              onCancel();
            });
        }}
        onNo={onCancel}
        yesText={t("general.report")}
        noText={t("clan_discussion_post.cancel")}
        isOpen={isOpen}
        loading={loading}
        disableSubmit={text.length === 0}
      >
        <IonTextarea
          ref={reportTextRef}
          rows={5}
          role="textbox"
          maxlength={1000}
          placeholder={t("clan_discussion_post.note")}
          className="leading-6 m-4 min-h-clanListItem w-auto"
          value={text}
          autocapitalize="sentences"
          onIonChange={(e) => setText(e.detail.value ? e.detail.value : "")}
        />
      </ClanQuestionModal>
    </>
  );
};

export default ClanDiscussionPostReportModal;
