import React from "react";
import styled from "styled-components";

const InterestsWrapper = styled.div<{ wrapped: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ wrapped }) =>
    wrapped
      ? `
        flex-direction: row; 
        flex-wrap: wrap; 
        justify-content: center;
      `
      : `flex-direction: column;`}
`;

const InterestItem = styled.div<{ active?: boolean }>`
  text-align: center;
  background: #fafafa;
  border-radius: 25px;
  padding: 1rem;
  margin: 0.2rem;
  cursor: pointer;

  border: 1px solid ${({ active }) => (active ? "#45DD85" : "transparent")};
`;

interface InterestListProps {
  interests: Array<string>;
  highlighted?: Array<string>;
  wrapped?: boolean;
  onClick?: (interest: string) => void;
}

const InterestList: React.FC<InterestListProps> = ({
  interests,
  highlighted = [],
  wrapped = false,
  onClick
}) => (
  <InterestsWrapper wrapped={wrapped}>
    {interests.map((interest: string, index: number) => {
      return (
        <InterestItem
          key={index}
          active={highlighted.includes(interest)}
          onClick={() => onClick && onClick(interest)}
        >
          {interest}
        </InterestItem>
      );
    })}
  </InterestsWrapper>
);

export default InterestList;
