import { useAsync, useAsyncEffect } from "@react-hook/async";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { connect } from "../../data/connect";
import { showQuickHelpModal } from "../../data/modals/modals.actions";
import { ModalID } from "../../data/modals/modals.state";

import { getPaginatedProfiles } from "../../util/api/ProfileService";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import {
  isReadyStateCancelOrFail,
  isReadyStateLoading
} from "../../util/AsyncEffectFilter";
import {
  addPersonalPost,
  addReflectionToTask
} from "../../util/ClanPostAPIService";
import { Views } from "../../util/Configuration";
import { FeedTypes, reSharePost } from "../../util/Feeds";
import { ClanEditPostEditDetails } from "../../util/models/ClanPostModels";
import { ClanMembershipListItemResponse } from "../../util/models/ClanProfileModels";
import { PaginatedStreamDataIterator } from "../../util/ResultDataIterator";
import PostModal from "./PostModal";
import StaffSearchModal from "./StaffSearchModal";
import * as ROUTES from "../../routes";
import { useViewFilterResult } from "../../util/Filters";

interface OwnProps {
  taskId?: string;
  onClose: () => void;
}

interface StateProps {
  isStudent: boolean;
}
interface DispatchProps {
  dispatchQuickHelpModal: typeof showQuickHelpModal;
}

interface TeacherReflectionModalProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const TeacherReflectionModal: React.FC<TeacherReflectionModalProps> = ({
  taskId,
  isStudent,
  dispatchQuickHelpModal,
  onClose
}) => {
  const [post, setPost] = useState<ClanEditPostEditDetails>({});
  const [selectedUserIDs, setSelectedUserIDs] = useState<Array<number>>([]);
  const [staffModalOpen, setStaffModalOpen] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  const [supportListApi, setSupportListApi] = useState<
    PaginatedStreamDataIterator<ClanMembershipListItemResponse> | undefined
  >(undefined);

  const [staffSelection, setStaffSelection] = useState<
    Array<ClanMembershipListItemResponse>
  >([]);

  const resolvedFilters = useViewFilterResult(undefined, Views.SupportList);

  useEffect(() => {
    if (resolvedFilters && resolvedFilters.defaultFilter) {
      setSupportListApi(
        getPaginatedProfiles(Views.SupportList, [resolvedFilters.defaultFilter])
      );
    } else {
      setSupportListApi(undefined);
    }
  }, [resolvedFilters]);

  useAsyncEffect(async () => {
    try {
      if (supportListApi === undefined || !staffModalOpen) {
        return;
      }
      await supportListApi.reset();
      const result = await supportListApi.getNext();
      setStaffSelection(result);
    } catch (error) {
      console.error("Error while fetching staff!", error);
    }
  }, [supportListApi, staffModalOpen]);

  const fetchMoreStatus = useAsyncEffect(async () => {
    try {
      if (supportListApi === undefined || staffSelection.length === 0) {
        return;
      }
      const hasNext = await supportListApi.hasNext();
      if (!hasNext || isReadyStateCancelOrFail(fetchMoreStatus)) {
        return;
      }
      const next = await supportListApi.getNext();
      if (isReadyStateCancelOrFail(fetchMoreStatus)) return;
      setStaffSelection([...staffSelection, ...next]);
    } catch (error) {
      console.error("Error while fetching more staff!.", error);
    }
  }, [staffSelection]);

  const onCounsellorSelected = (
    selectedMember: ClanMembershipListItemResponse
  ) => {
    if (selectedUserIDs.includes(selectedMember.userId)) {
      setSelectedUserIDs(
        selectedUserIDs.filter((userId) => userId !== selectedMember.userId)
      );
    } else {
      setSelectedUserIDs(selectedUserIDs.concat([selectedMember.userId]));
    }
  };

  const [submitStatus, submitReflection] = useAsync(async () => {
    const profilesToShareWith = selectedUserIDs.reduce((profileIDs, userId) => {
      const foundStaffMember = staffSelection.find(
        (staffMember: ClanMembershipListItemResponse) =>
          staffMember.userId === userId
      );

      return foundStaffMember && foundStaffMember.profileId
        ? [...profileIDs, foundStaffMember.profileId]
        : profileIDs;
    }, [] as Array<number>);

    if (taskId) {
      const sharedPost = await addReflectionToTask(taskId, {
        ...post,
        sharedProfiles: profilesToShareWith
      });
      if (!sharedPost || isReadyStateCancelOrFail(submitStatus)) return;

      return history.push(ROUTES.PUBLIC_FEEDS, {
        feedType: FeedTypes.SHARED_REFLECTIONS,
        highlightedPostId: sharedPost.id
      });
    }

    const reflection = await addPersonalPost(post);
    if (!reflection || isReadyStateCancelOrFail(submitStatus)) return;

    const sharedPost = await reSharePost(Views.ReflectionFeed, reflection.id, {
      sharedProfiles: profilesToShareWith
    });
    if (!sharedPost || isReadyStateCancelOrFail(submitStatus)) return;

    onClose();

    if (
      isStudent &&
      post.mood &&
      ["Worried", "Anxious", "Scared"].includes(post.mood)
    ) {
      dispatchQuickHelpModal(ModalID.QuickHelpTask, [
        t("quick_help_modal.p1"),
        t("quick_help_modal.p2")
      ]);
    }

    history.push(ROUTES.PUBLIC_FEEDS, {
      feedType: FeedTypes.SHARED_REFLECTIONS,
      highlightedPostId: sharedPost.id
    });
  });

  const onPostChange = (newPost: ClanEditPostEditDetails) =>
    setPost({ ...post, ...newPost });

  const onShareClicked = () => setStaffModalOpen(true);

  const shareLabel = () => {
    if (selectedUserIDs.length > 0) {
      return `${selectedUserIDs.length} ${t("clan_share_post.person", {
        count: selectedUserIDs.length
      })}`;
    }

    return t("clan_share_post.select");
  };

  const isDisabled =
    isReadyStateLoading(submitStatus) ||
    !post.text ||
    selectedUserIDs.length <= 0;

  return (
    <>
      <PostModal
        post={post}
        onPostChange={onPostChange}
        textPlaceholder={t("dashboard_components.feeling_question")}
        shareLabel={shareLabel()}
        disabled={isDisabled}
        onShareClicked={onShareClicked}
        onSubmit={submitReflection}
        onClose={onClose}
      />

      {staffModalOpen && (
        <StaffSearchModal
          title={t("clan_share_post.teacher_title")}
          description={t("clan_share_post.teacher_subtitle")}
          submitLabel={t("clan_share_post.select")}
          disabled={
            selectedUserIDs.length <= 0 || isReadyStateLoading(submitStatus)
          }
          staffList={staffSelection}
          selectedUserIDs={selectedUserIDs}
          onStaffClick={onCounsellorSelected}
          onSubmit={() => setStaffModalOpen(false)}
        />
      )}
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: ({ token }) => ({
    isStudent: !UserEnabledProperties.Legacy.isAdult(token)
  }),
  mapDispatchToProps: {
    dispatchQuickHelpModal: showQuickHelpModal
  },
  component: TeacherReflectionModal
});
