import { DataStorage } from "./DataStorage";

export class MemoryDataStorage implements DataStorage {
  private data: { [key: string]: string } = {};

  clear(): Promise<void> {
    this.data = {};
    return Promise.resolve();
  }

  get(options: {
    key: string;
  }): Promise<{
    value: string | null;
  }> {
    if (this.data[options.key] === undefined) {
      return Promise.resolve({ value: null });
    }
    return Promise.resolve({ value: this.data[options.key] });
  }

  keys(): Promise<{ keys: string[] }> {
    return Promise.resolve({ keys: Object.keys(this.data) });
  }

  remove(options: { key: string }): Promise<void> {
    if (this.data[options.key] !== undefined) {
      delete this.data[options.key];
    }
    return Promise.resolve(undefined);
  }

  set(options: { key: string; value: string }): Promise<void> {
    if (options.value !== undefined && options.value !== null) {
      this.data[options.key] = options.value;
    } else if (this.data[options.key]) {
      delete this.data[options.key];
    }
    return Promise.resolve();
  }

  setUnsafe(options: { key: string; value: string }): Promise<void> {
    throw new Error("The setLocal is not allowed!");
  }

  clearSafeStorage(): Promise<void> {
    throw new Error("The clearSafeStorage is not allowed!");
  }

  clearUnsafeStorage(): Promise<void> {
    throw new Error("The clearSafeStorage is not allowed!");
  }
}
