import { IonIcon } from "@ionic/react";
import {
  logoWhatsapp,
  logoFacebook,
  logoInstagram,
  chatbubbles,
  logoSnapchat,
  videocam,
  logoTwitch,
  logoXbox,
  logoPlaystation,
  logoYoutube,
  logoTwitter,
  bookmarks
} from "ionicons/icons";
import React from "react";

interface SocialIconProps {
  iconName: string;
  className?: string;
}

const SocialIcon: React.FC<SocialIconProps> = ({
  iconName,
  className = ""
}) => {
  switch (iconName) {
    case "Whatsapp":
      return (
        <IonIcon
          className={className}
          slot="start"
          icon={logoWhatsapp}
          color="clanicon"
        />
      );
    case "Facebook":
      return (
        <IonIcon
          className={className}
          slot="start"
          icon={logoFacebook}
          color="clanicon"
        />
      );
    case "Instagram":
      return (
        <IonIcon
          className={className}
          slot="start"
          icon={logoInstagram}
          color="clanicon"
        />
      );
    case "Discord":
      return (
        <IonIcon
          className={className}
          slot="start"
          icon={chatbubbles}
          color="clanicon"
        />
      );
    case "Snapchat":
      return (
        <IonIcon
          className={className}
          slot="start"
          icon={logoSnapchat}
          color="clanicon"
        />
      );
    case "Tiktok":
      return (
        <IonIcon
          className={className}
          slot="start"
          icon={videocam}
          color="clanicon"
        />
      );
    case "Twitch":
      return (
        <IonIcon
          className={className}
          slot="start"
          icon={logoTwitch}
          color="clanicon"
        />
      );
    case "Xbox":
      return (
        <IonIcon
          className={className}
          slot="start"
          icon={logoXbox}
          color="clanicon"
        />
      );
    case "PS4":
      return (
        <IonIcon
          className={className}
          slot="start"
          icon={logoPlaystation}
          color="clanicon"
        />
      );
    case "Youtube":
      return (
        <IonIcon
          className={className}
          slot="start"
          icon={logoYoutube}
          color="clanicon"
        />
      );
    case "Twitter":
      return (
        <IonIcon
          className={className}
          slot="start"
          icon={logoTwitter}
          color="clanicon"
        />
      );
    default:
      return (
        <IonIcon
          className={className}
          slot="start"
          icon={bookmarks}
          color="clanicon"
        />
      );
  }
};

export default SocialIcon;
