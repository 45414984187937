import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  IonText
} from "@ionic/react";
import { format } from "date-fns";
import {
  addOutline,
  arrowBack,
  arrowForward,
  ellipsisHorizontalCircleSharp
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { analyticsLogEvent } from "../../Analytics";
import { connect } from "../../data/connect";
import { OauthState } from "../../data/oauth/oauth.state";
import { ReactComponent as ExclamationYellowIcon } from "../../icons/exclamation-yellow.svg";
import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";
import { DashboardHeader } from "../../pages/StatsDashboard/StatsDashboard";
import * as ROUTES from "../../routes";
import { PostComplaint } from "../../util/Analytics";
import {
  ComplaintObject,
  ComplaintUserProfile,
  doComplaintAction,
  fetchComplaintActions,
  UserComplaintListResult
} from "../../util/Complaint";
import { isValidMood } from "../../util/MoodChoice";
import { filterReactionTypes } from "../../util/Reactions";
import Avatar from "../Avatar";
import ClanProfileAvatar from "../ClanProfileAvatar";
import {
  EmptyParagraph,
  EmptyTitle,
  EmptyViewWrapper,
  FlagHeader,
  FlagIndicator,
  HeaderRays,
  Title
} from "./FlagModal";
import FooterButtons, {
  PrimaryFooterButton,
  SecondaryFooterButton
} from "../FooterButtons";
import ListItem from "../ListItem/ListItem";
import { SectionContent } from "../Section";

const FlagHeaderReportModal = styled(FlagHeader)`
  padding: 25px;
  margin-top: 0;
`;

const Fab = styled.div`
  background: white;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  text-align: center;
  line-height: 3rem;
  font-size: 1.4rem;
`;

const ActionModalTitle = styled(Title)`
  margin: 20px 25px;
  text-align: left;
`;

const ListItemReportDescription = styled(IonItem)`
  border-radius: 25rem;
  margin: 5px 12px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-end: 0;
  --min-height: auto;
`;

const NavigationIcon = styled(Fab)`
  visibility: ${({ visible }: { visible: boolean }) =>
    visible ? "visible" : "hidden"};

  ion-icon {
    color: #45dd85;
    margin: 0.8rem;
  }
`;

const ExclamationIcon = styled(ExclamationYellowIcon)`
  width: 4.4rem;
  margin: 3rem;
`;

const EmptyView = () => (
  <EmptyViewWrapper>
    <HeaderRays />
    <ExclamationIcon />
    <EmptyTitle>No reports to show</EmptyTitle>
    <EmptyParagraph>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu
      tristique libero. Nam in neque dolor. In hac habitasse platea dictumst.
      Nulla ornare libero ut imperdiet dapibus. Fusce at sapien egestas, aliquet
      urna a, imperdiet elit.
    </EmptyParagraph>
  </EmptyViewWrapper>
);

interface StoreProps {
  tokenConnect: OauthState;
}

interface OwnProps {
  report: UserComplaintListResult;
  close: () => void;
  openPrevious?: () => void;
  openNext?: () => void;
  onReportAction: (report: UserComplaintListResult) => void;
}

interface ReportModalProps extends OwnProps, StoreProps {}

const ReportModal: React.FC<ReportModalProps> = ({
  report,
  close,
  tokenConnect,
  openPrevious,
  openNext,
  onReportAction
}) => {
  let isRedirecting = false;

  const history = useHistory();
  const [complaint, setComplaint] = useState<ComplaintObject | undefined>(
    undefined
  );
  const [postComplaint, setPostComplaint] = useState<PostComplaint | undefined>(
    undefined
  );
  const [actions, setActions] = useState<{ [key: string]: string[] }>({});
  const [reporterUser, setReporterUser] = useState<
    ComplaintUserProfile | undefined
  >(undefined);
  const [reportedUser, setReportedUser] = useState<
    ComplaintUserProfile | undefined
  >(undefined);

  const [showResolveAlert, setShowResolveAlert] = useState<boolean>(false);
  const [actionKey, setActionKey] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    setActionKey(undefined);
    setComplaint(
      report.complaint?.value.length > 0 ? report.complaint.value[0] : undefined
    );
    setPostComplaint(
      report.complaint?.value?.length > 0 && report.request.type === "POST"
        ? (report.complaint?.value[0] as PostComplaint)
        : undefined
    );
    setReporterUser(report?.reporterUser?.value);
    setReportedUser(report?.reportedUser?.value);
    setActions({});
    if (report.id) {
      fetchComplaintActions(tokenConnect, report.id).then(setActions);
    }
  }, [report, tokenConnect]);

  const onOpenProfile = (profileId: number) => () => {
    isRedirecting = true;
    analyticsLogEvent("STATS_REPORT_PROFILE_CLICK");

    history.push(
      ROUTES.PUBLIC_PROFILE.replace(":profileId", profileId.toString())
    );
  };

  useEffect(() => {
    if (!actionKey) return;
    if (!actions || !(actionKey in actions)) {
      return;
    }
    const localActions = actions;
    const actionValue = localActions[actionKey].shift();
    const localActionKey = actionKey;
    if (localActions[actionKey].length === 0) {
      delete localActions[actionKey];
    }
    setActionKey(undefined);
    setActions(localActions);

    if (actionKey === "read" && postComplaint?.type && postComplaint?.id) {
      analyticsLogEvent("STATS_REPORT_ACTION" + localActionKey);
      switch (postComplaint.type) {
        case "post":
          history.push(
            ROUTES.DISCUSSION_COMMENTS.replace(":post_id", postComplaint?.id)
          );
          return;
        case "question":
          history.push(
            ROUTES.DISCUSSION_COMMENTS.replace(":post_id", postComplaint?.id)
          );
          return;
        case "comment":
          postComplaint?.id &&
            history.push(
              ROUTES.DISCUSSION_COMMENTS.replace(":post_id", postComplaint?.id)
            );
          return;
      }
    }

    if (!actionValue) return;
    analyticsLogEvent("STATS_REPORT_ACTION" + localActionKey);
    (async () => {
      const result = await doComplaintAction(
        tokenConnect,
        report.id,
        localActionKey,
        actionValue
      );
      if (localActionKey === "complete") {
        const reportResult = result[0] as UserComplaintListResult;
        onReportAction(reportResult);
        close();
      }
    })();
  }, [
    actionKey,
    actions,
    close,
    history,
    postComplaint,
    report.id,
    tokenConnect,
    onReportAction
  ]);

  const onDidDismiss = () => {
    if (!isRedirecting) close();
  };

  return (
    <IonModal isOpen onDidDismiss={onDidDismiss}>
      <IonAlert
        isOpen={showResolveAlert}
        mode="ios"
        onDidDismiss={() => setShowResolveAlert(false)}
        header={t("report_modal.report_header")}
        message={t("report_modal.report_message")}
        buttons={[
          {
            text: t("report_modal.cancel"),
            handler: () => setShowResolveAlert(false),
            cssClass: "text-black"
          },
          {
            text: t("report_modal.resolve"),
            handler: () => {
              setActionKey("complete");
            },
            cssClass: "text-black"
          }
        ]}
      />
      <IonAlert
        isOpen={actionKey === "delete_ask"}
        mode="ios"
        onDidDismiss={() => setActionKey(undefined)}
        header={t("report_modal.delete_ask")}
        message={t("report_modal.delete_message")}
        buttons={[
          {
            text: t("report_modal.cancel"),
            handler: () => setActionKey(undefined),
            cssClass: "text-black"
          },
          {
            text: t("report_modal.delete"),
            handler: () => {
              setActionKey("delete");
            },
            cssClass: "text-black"
          }
        ]}
      />
      <ClanIonContent>
        {false && <EmptyView />}
        <FlagHeaderReportModal>
          <NavigationIcon
            onClick={openPrevious}
            visible={!!openPrevious}
            className="cursor-pointer"
          >
            <IonIcon icon={arrowBack} />
          </NavigationIcon>
          <FlagIndicator>
            <img alt="exclamation_header" src={`/assets/exclamation.svg`} />
          </FlagIndicator>
          <NavigationIcon
            onClick={openNext}
            visible={!!openNext}
            className="cursor-pointer"
          >
            <IonIcon icon={arrowForward} />
          </NavigationIcon>
        </FlagHeaderReportModal>

        <ActionModalTitle>
          {t("report_modal.reported", {
            recipient: reporterUser?.firstName,
            reporter: reportedUser?.firstName
          })}
        </ActionModalTitle>

        <DashboardHeader>{t("report_modal.students_involved")}</DashboardHeader>

        <SectionContent>
          {reportedUser?.profileId && (
            <ListItem
              avatar={
                reportedUser.images?.thumbnail?.url ||
                "https://www.gravatar.com/avatar?d=mm&s=140"
              }
              primary={reportedUser.fullName}
              onClick={onOpenProfile(reportedUser.profileId)}
            />
          )}
          {reporterUser?.profileId && (
            <ListItem
              avatar={
                reporterUser.images?.thumbnail?.url ||
                "https://www.gravatar.com/avatar?d=mm&s=140"
              }
              primary={reporterUser.fullName}
              onClick={onOpenProfile(reporterUser.profileId)}
            />
          )}
        </SectionContent>

        <DashboardHeader>{t("report_modal.post")}</DashboardHeader>
        <IonCard
          className="shadow-none overflow-visible cursor-pointer"
          onClick={() => setActionKey("read")}
        >
          <IonCardHeader className="flex flex-wrap">
            <div className="flex flex-no-wrap w-full">
              <ClanProfileAvatar src={reportedUser?.images?.thumbnail?.url} />
              <div className="ml-4 w-4/5">
                <div className="flex items-center">
                  <h4 className="font-extrabold font-gilroy m-0">
                    {reportedUser?.fullName}
                    {postComplaint?.mood &&
                    postComplaint?.mood.length > 0 &&
                    isValidMood(postComplaint?.mood[0])
                      ? t("report_modal.feeling", {
                          how: postComplaint?.mood[0]?.toLowerCase()
                        })
                      : null}
                    {postComplaint?.mood &&
                    postComplaint?.mood.length > 0 &&
                    isValidMood(postComplaint?.mood[0]) ? (
                      <img
                        className="w-6 h-6 ml-2 align-middle"
                        alt={postComplaint?.mood[0]}
                        src={`/assets/mood/${postComplaint?.mood[0]?.toLowerCase()}.svg`}
                      />
                    ) : null}
                  </h4>
                </div>
                <h5 className="text-gray-500 m-0">
                  <span>
                    {t("report_modal.asked", {
                      name: reportedUser?.fullName
                    })}
                  </span>
                  <span>
                    {postComplaint?.task &&
                      t("report_modal.reflection", {
                        task: postComplaint?.task?.name
                      })}
                  </span>
                  <span>
                    {complaint?.createdAt
                      ? format(
                          new Date(complaint?.createdAt),
                          "HH:mm | d MMM yyyy"
                        )
                      : ""}
                  </span>
                </h5>
              </div>
            </div>
            {postComplaint?.text ? (
              <IonText className="text-justify w-full mt-2">
                <section
                  className="text-clanP text-left whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{
                    __html: postComplaint?.text
                  }}
                />
              </IonText>
            ) : null}
          </IonCardHeader>
          <IonCardContent className="p-0">
            {postComplaint?.images?.thumbnail?.url ? (
              <IonItem>
                <IonImg
                  src={postComplaint?.images?.thumbnail?.url}
                  alt="Post image"
                />
              </IonItem>
            ) : null}
            <div className="flex flex-wrap justify-between items-center">
              <IonItem class="flex-1" disabled>
                <IonIcon icon={ellipsisHorizontalCircleSharp} />
              </IonItem>

              <span className="flex ml-auto">
                {postComplaint?.reactions !== undefined &&
                  Object.keys(postComplaint?.reactions).length !== 0 && (
                    <IonLabel className="ml-auto mr-1 flex items-center">
                      <span className="text-sm mr-2">
                        {Object.keys(postComplaint?.reactions).length}
                      </span>

                      {postComplaint?.reactions &&
                        Object.keys(postComplaint?.reactions).length !== 0 &&
                        filterReactionTypes(
                          postComplaint?.reactions
                        ).map((reactionItem, i) => (
                          <IonIcon
                            size="small"
                            className="visible mr-1"
                            key={i}
                            src={`/assets/reaction/${reactionItem.toLowerCase()}.svg`}
                          />
                        ))}
                    </IonLabel>
                  )}
                <IonFab className="relative">
                  <IonFabButton
                    size="small"
                    color="none"
                    className="w-8 h-8 m-0 black"
                    mode="ios"
                    disabled
                  >
                    <IonIcon md={addOutline} size="small" />
                  </IonFabButton>
                </IonFab>
              </span>
            </div>
          </IonCardContent>
        </IonCard>
        {"delete" in actions ? (
          <IonButton
            fill="clear"
            size="small"
            className="clan-list-item flex self-center border border-solid border-clanGreen-100 text-clanGreen-100 my-1 mx-12"
            disabled={!("delete" in actions) || actions["delete"].length === 0}
            onClick={() => setActionKey("delete_ask")}
          >
            {t("report_modal.delete_post")}
          </IonButton>
        ) : (
          <IonButton
            fill="clear"
            size="small"
            className="clan-list-item flex self-center border border-solid border-clanGreen-100 text-clanGreen-100 my-1 mx-12"
            disabled={true}
          >
            {t("report_modal.delete_success")}
          </IonButton>
        )}
        <DashboardHeader>
          {t("report_modal.message_from_reporter")}
        </DashboardHeader>
        <ListItemReportDescription>
          <Avatar
            url={
              reporterUser?.images?.thumbnail?.url ||
              "https://www.gravatar.com/avatar?d=mm&s=140"
            }
          />
          <section
            className="text-clanP text-left p-4 whitespace-pre-wrap rounded-clanListItem bg-clanGray-100"
            dangerouslySetInnerHTML={{
              __html: report?.description
            }}
          />
        </ListItemReportDescription>
      </ClanIonContent>
      <FooterButtons>
        <SecondaryFooterButton
          onClick={() => {
            analyticsLogEvent("STATS_REPORT_DISMISS");
            close();
          }}
        >
          {t("general.back")}
        </SecondaryFooterButton>
        {report.id && "complete" in actions && (
          <PrimaryFooterButton onClick={() => setShowResolveAlert(true)}>
            {t("report_modal.resolve_uc")}
          </PrimaryFooterButton>
        )}
      </FooterButtons>
    </IonModal>
  );
};

export default connect<OwnProps, StoreProps, {}>({
  mapStateToProps: ({ token }) => ({
    tokenConnect: token
  }),
  component: ReportModal
});
