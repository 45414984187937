import { Views } from "../../Configuration/types/Views.d";
import { FeedTypes } from "../types/FeedTypes.d";

export const findViewFilterByFeedType = (
  feedType: FeedTypes
): Views | undefined => {
  switch (feedType) {
    case FeedTypes.SHARED_REFLECTIONS:
      return Views.ReflectionFeed;
    case FeedTypes.QUESTION:
      return Views.QuestionFeed;
    case FeedTypes.DISCUSSION:
      return Views.DiscussionFeed;
    case FeedTypes.TASK:
      return Views.TasksFeed;
    case FeedTypes.ANNOUNCEMENT_FEED:
      return Views.Announcement;
    default:
      return undefined;
  }
};
