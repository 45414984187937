const regexp = /(http|https)?:?(\/\/)?(www\.)?videoask.com\/([a-zA-Z0-9_-]+)(?:|\/\?)/;

const resolveVideoAskHash = (url: string): string | undefined => {
  regexp.lastIndex = 0;
  const result = regexp.exec(url?.trim());
  if (!result) return undefined;
  return result[result.length - 1];
};

const menuItems = [
  "product",
  "help",
  "embed-widget",
  "pricing",
  "lead-generation",
  "audience-engagement",
  "get-feedback",
  "nps",
  "recruitment",
  "testimonials",
  "interactive-video",
  "inspiration",
  "templates",
  "feedback",
  "education",
  "real-estate",
  "sales",
  "whats-new"
];

export const resolveVideoAskLink = (
  url: string
): Promise<string | undefined> => {
  const hash = resolveVideoAskHash(url);
  if (hash === undefined) return Promise.resolve(undefined);
  if (menuItems.indexOf(hash) > -1) return Promise.resolve(undefined);
  return Promise.resolve(
    `https://www.videoask.com/${hash}?embed_type=native_app`
  );
  //TODO: allow camera and microphone later`<VideoContent><iframe src="https://www.videoask.com/${hash}" title="VideoAsk video player" frameborder="0" allow="camera *; microphone *; autoplay *; encrypted-media *; display-capture *;fullscreen; gyroscope" allowfullscreen></iframe></VideoContent>`
};
