import React from "react";
import {
  IonCard,
  IonCardHeader,
  IonSkeletonText,
  IonText,
  IonCardContent,
  IonItem,
  IonIcon
} from "@ionic/react";
import ClanProfileAvatar from "./ClanProfileAvatar";
import {
  ellipsisHorizontalCircleSharp,
  chatbubbles,
  star
} from "ionicons/icons";

interface ClanDiscussionSkeletonProps {
  numOfSkeletonPosts: number;
}
// The design of this component is based on ClanDiscussionPost and should be updated whenever that file's structure changes noticably
const ClanDiscussionSkeleton: React.FC<ClanDiscussionSkeletonProps> = ({
  numOfSkeletonPosts
}) => {
  return (
    <>
      {[...Array(numOfSkeletonPosts)].map((skeletonPost, i) => {
        return (
          <IonCard
            className="shadow-none overflow-visible"
            key={`skeleton_post_${i}`}
          >
            <IonCardHeader className="flex flex-wrap">
              <div className="flex flex-no-wrap w-full">
                <ClanProfileAvatar />
                <div className="ml-4 w-4/5">
                  <div className="flex items-center">
                    <h3 className="font-extrabold font-gilroy m-0">
                      <IonSkeletonText animated style={{ width: "40%" }} />
                    </h3>
                  </div>
                  <p className="text-gray-500 m-0">
                    <span>
                      <IonSkeletonText animated style={{ width: "60%" }} />
                    </span>
                    <span>
                      <IonSkeletonText animated style={{ width: "80%" }} />
                    </span>
                  </p>
                </div>
              </div>
              <IonText className="text-justify w-full">
                <IonSkeletonText animated style={{ width: "88%" }} />
                <IonSkeletonText animated style={{ width: "70%" }} />
                <IonSkeletonText animated style={{ width: "60%" }} />
              </IonText>
            </IonCardHeader>
            <IonCardContent className="p-0">
              <div className="flex flex-wrap justify-between">
                <IonItem>
                  <IonIcon icon={ellipsisHorizontalCircleSharp} size="small" />
                </IonItem>
                <IonItem>
                  <IonIcon icon={chatbubbles} size="small" />
                </IonItem>
                <IonItem>
                  <IonIcon icon={star} size="small" />
                </IonItem>
              </div>
            </IonCardContent>
          </IonCard>
        );
      })}
    </>
  );
};

export default ClanDiscussionSkeleton;
