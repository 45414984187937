import { OauthState } from "../../data/oauth/oauth.state";
import { OauthData } from "../../data/oauth/oauthDataService";

const hasFeature = (
  oauthState: OauthState | OauthData,
  feature: string,
  defaultValue = false
): boolean => {
  if (!oauthState.hasToken || !(oauthState as OauthData).features)
    return defaultValue;
  return (
    (oauthState as OauthData).features.find((r) => r === feature) !== undefined
  );
};

export const FeatureService = {
  PROFILE_DISABLE_SOCIAL_LINKS: (oauthState: OauthState | OauthData) =>
    hasFeature(oauthState, "PROFILE_DISABLE_SOCIAL_LINKS", true)
};
