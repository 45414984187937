import { useEffect, useState } from "react";

export enum ScreenSize {
  Phone,
  Tablet,
  Laptop,
  Desktop
}

interface ScreenWidthTriggerConf {
  min?: number;
  max?: number;
}

const useScreenWidthTrigger = ({ min, max }: ScreenWidthTriggerConf) => {
  const [isInBounds, setIsInBounds] = useState(
    isInWidthBounds(window.innerWidth)
  );

  function isInWidthBounds(screenWidth: number) {
    return screenWidth >= (min || 0) && screenWidth <= (max || Infinity);
  }

  useEffect(() => {
    function handleResize() {
      const newIsInBounds = isInWidthBounds(window.innerWidth);
      if (isInBounds !== newIsInBounds) {
        setIsInBounds(newIsInBounds);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInBounds]);

  return isInBounds;
};

export default useScreenWidthTrigger;
