import { IonContent, IonModal } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Views } from "../../util/Configuration";
import ClanSharePost from "../ClanDiscussionPost/edit/ClanSharePost";
import { SmoothRays } from "../ClanSmoothRays";
import FooterButtons, { PrimaryFooterButton } from "../FooterButtons";

const ShareModalContent = styled(IonContent)`
  --padding-bottom: 90px;
`;

interface ShareModalProps {
  disabled?: boolean;
  view: Views;
  groups: Array<number>;
  profiles: Array<number>;
  roles: Array<string>;
  onGroupsChange: (grups: Array<number>) => void;
  onProfilesChange: (profiles: Array<number>) => void;
  onRolesChange: (roles: Array<string>) => void;
  onSubmit?: () => void;
  onClose: () => void;
}

const ShareModal: React.FC<ShareModalProps> = ({
  view,
  groups,
  profiles,
  roles,
  onGroupsChange,
  onProfilesChange,
  onRolesChange,
  onSubmit,
  onClose
}) => {
  const { t } = useTranslation();

  return (
    <IonModal isOpen={true} onDidDismiss={onClose}>
      <ShareModalContent>
        <SmoothRays showInDesktop />
        <ClanSharePost
          view={view}
          setGroups={onGroupsChange}
          setProfiles={onProfilesChange}
          setRoles={onRolesChange}
          roles={roles}
          groups={groups}
          profiles={profiles}
        />
      </ShareModalContent>
      <FooterButtons>
        <PrimaryFooterButton onClick={onSubmit}>
          {t("clan_share_post.select")}
        </PrimaryFooterButton>
      </FooterButtons>
    </IonModal>
  );
};

export default ShareModal;
