import React from "react";
import ClanListInterestTemplate from "./ClanListInterestTemplate";
import { LANGUAGES_ENDPOINTS } from "../../util/ClanInterestsEndpoints";
import { useTranslation } from "react-i18next";
import { COMMUNITY_SLIDES } from "../../pages/People";
import { FilterGroupType } from "../../util/Filters";

type ClanLanguagesProps = {
  className?: string;
  filters?: FilterGroupType[];
  activeIndex: COMMUNITY_SLIDES;
};

const ClanLanguages: React.FC<ClanLanguagesProps> = ({
  className,
  filters,
  activeIndex
}) => {
  const { t } = useTranslation();
  return (
    <ClanListInterestTemplate
      title="Languages"
      filters={filters}
      endpoints={LANGUAGES_ENDPOINTS}
      profilesTitle={t("people_overview.speaking")}
      noManage={true}
      itemClassName="grid grid-cols-3auto-middle"
      cardClassName={className}
      activeIndex={activeIndex}
      withAmounts
    />
  );
};

export default ClanLanguages;
