import React, { CSSProperties } from "react";
import styled, { css, useTheme } from "styled-components";

const IconWrapper = styled.div<{ scale?: number }>`
  width: ${({ scale }) => Math.ceil(40 * (scale || 1))}px;
  height: ${({ scale }) => Math.ceil(40 * (scale || 1))}px;
`;

const IconBase = styled.div<{
  active?: boolean;
  animated?: boolean;
  scale?: number;
}>`
  --normal: #eceaf3;
  --normal-shadow: #d9d8e3;
  --normal-mouth: #9795a4;
  --normal-eye: #595861;
  --active: #f8da69;
  --active-shadow: #f4b555;
  --active-mouth: #f05136;
  --active-eye: #313036;
  --active-tear: #76b5e7;
  --active-shadow-sad: #e94f1d;

  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  transform-origin: top left;
  background: var(--sb, var(--normal));
  box-shadow: inset 3px -3px 4px var(--sh, var(--normal-shadow));

  ${({ scale }) =>
    scale &&
    css`
      transform: scale(${scale});
    `}

  ${({ animated }) =>
    animated &&
    css`
      transition: background 0.4s, box-shadow 0.4s, transform 0.3s;
    `}

  @keyframes shake {
    30% {
      transform: perspective(240px) rotateX(var(--step-1-rx, 0deg))
        rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg))
        translateZ(10px);
    }
    60% {
      transform: perspective(240px) rotateX(var(--step-2-rx, 0deg))
        rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg))
        translateZ(10px);
    }
    100% {
      transform: perspective(240px) translateZ(4px);
    }
  }

  @keyframes toggle {
    50% {
      transform: translateY(var(--middle-y, 0))
        scale(
          var(--middle-s-x, var(--middle-s, 1)),
          var(--middle-s-y, var(--middle-s, 1))
        )
        rotate(var(--middle-r, 0deg));
    }
  }

  div {
    width: 40px;
    height: 40px;
    position: relative;
    transform: perspective(240px) translateZ(4px);

    svg,
    &:before,
    &:after {
      display: block;
      position: absolute;
      left: var(--l, 9px);
      top: var(--t, 13px);
      width: var(--w, 8px);
      height: var(--h, 2px);
      transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
    }

    svg {
      fill: none;
      stroke: var(--s);
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-linejoin: round;

      ${({ animated }) =>
        animated &&
        css`
          transition: stroke 0.4s;
        `}

      &.eye {
        --s: var(--e, var(--normal-eye));
        --t: 17px;
        --w: 7px;
        --h: 4px;

        &.right {
          --l: 23px;
        }
      }

      &.mouth {
        --s: var(--m, var(--normal-mouth));
        --l: 11px;
        --t: 23px;
        --w: 18px;
        --h: 7px;
      }
    }

    &:before,
    &:after {
      content: "";
      z-index: var(--zi, 1);
      border-radius: var(--br, 1px);
      background: var(--b, var(--e, var(--normal-eye)));

      ${({ animated }) =>
        animated &&
        css`
          transition: background 0.4s;
        `}
    }
  }

  ${({ active, animated }) =>
    !active &&
    css`
      cursor: pointer;

      ${animated &&
      css`
        &:active {
          transform: scale(0.925);
        }
      `}
    `}

  ${({ active, animated }) =>
    active &&
    css`
      --sb: var(--active);
      --sh: var(--active-shadow);
      --m: var(--active-mouth);
      --e: var(--active-eye);
      div {
        animation: shake ${animated ? "0.8s" : "0s"} linear forwards;
      }
    `}
`;

const SadIcon = styled(IconBase)`
  --step-1-rx: -24deg;
  --step-1-ry: 20deg;
  --step-2-rx: -24deg;
  --step-2-ry: -20deg;

  @keyframes sad {
    40% {
      background: var(--active);
    }
    45% {
      box-shadow: inset 3px -3px 4px var(--active-shadow),
        inset 0 8px 10px var(--active-shadow-sad);
    }
  }

  div {
    &:before {
      --r: 20deg;
    }
    &:after {
      --l: 23px;
      --r: -20deg;
    }
    svg {
      &.eye {
        stroke-dasharray: 4.55;
        stroke-dashoffset: 8.15;
      }
    }
  }

  ${({ active, animated }) =>
    active &&
    css`
      animation: sad ${animated ? "1s" : "0s"} linear;

      div {
        &:before {
          --middle-y: -2px;
          --middle-r: 22deg;
          animation: toggle ${animated ? "0.8s" : "0s"} linear forwards;
        }
        &:after {
          --middle-y: 1px;
          --middle-r: -18deg;
          animation: toggle ${animated ? "0.8s" : "0s"} linear forwards;
        }
      }
    `}
`;

const ConfusingIcon = styled(IconBase)`
  --step-1-rx: 20deg;
  --step-1-ry: -12deg;
  --step-2-rx: -18deg;
  --step-2-ry: 14deg;

  @keyframes tear {
    0% {
      opacity: 0;
      transform: translateY(-2px) scale(0) translateZ(0);
    }
    50% {
      transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
    }
    20%,
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(24px) translateX(4px) rotateZ(-30deg)
        scale(0.7, 1.1) translateZ(0);
    }
  }

  div {
    &:before,
    &:after {
      --b: var(--active-tear);
      --sc: 0;
      --w: 5px;
      --h: 5px;
      --t: 15px;
      --br: 50%;
    }
    &:after {
      --l: 25px;
    }
    svg {
      &.eye {
        --t: 16px;
      }
      &.mouth {
        --t: 24px;
        stroke-dasharray: 9.5;
        stroke-dashoffset: 33.25;
      }
    }
  }

  ${({ active, animated }) =>
    active &&
    css`
      div {
        &:before,
        &:after {
          animation: tear ${animated ? "0.6s" : "0s"} linear forwards;
        }
      }
    `}
`;

const OkIcon = styled(IconBase)`
  --step-1-rx: -14deg;
  --step-1-rz: 10deg;
  --step-2-rx: 10deg;
  --step-2-rz: -8deg;

  div {
    &:before {
      --b: var(--m, var(--normal-mouth));
      --w: 5px;
      --h: 5px;
      --br: 50%;
      --t: 22px;
      --zi: 0;
      opacity: 0.5;
      box-shadow: 16px 0 0 var(--b);
      filter: blur(2px);
    }

    &:after {
      --sc: 0;
    }

    svg {
      &.eye {
        --t: 15px;
        --sc: -1;
        stroke-dasharray: 4.55;
        stroke-dashoffset: 8.15;
      }

      &.mouth {
        --t: 22px;
        --sc: -1;
        stroke-dasharray: 13.3;
        stroke-dashoffset: 23.75;
      }
    }
  }

  ${({ active, animated }) =>
    active &&
    css`
      div {
        svg {
          &.mouth {
            --middle-y: 1px;
            --middle-s: -1;
            animation: toggle ${animated ? "0.8s" : "0s"} linear forwards;
          }
        }
      }
    `}
`;

const GladIcon = styled(IconBase)`
  div {
    --step-1-rx: 18deg;
    --step-1-ry: 24deg;
    --step-2-rx: 18deg;
    --step-2-ry: -24deg;

    &:before {
      --sc: 0;
    }

    &:after {
      --b: var(--m, var(--normal-mouth));
      --l: 11px;
      --t: 23px;
      --w: 18px;
      --h: 8px;
      --br: 0 0 8px 8px;
    }

    svg {
      &.eye {
        --t: 14px;
        --sc: -1;
      }
    }
  }

  ${({ active, animated }) =>
    active &&
    css`
      div {
        &:after {
          --middle-s-x: 0.95;
          --middle-s-y: 0.75;
          animation: toggle ${animated ? "0.8s" : "0s"} linear forwards;
        }
      }
    `}
`;

const Mouth = () => (
  <svg className="mouth">
    <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5" />
  </svg>
);

const Eye = () => (
  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1" />
);

const LeftEye = () => (
  <svg className="eye left">
    <Eye />
  </svg>
);

const RightEye = () => (
  <svg className="eye right">
    <Eye />
  </svg>
);

export interface AnimatedIconProps {
  animated?: boolean;
  active?: boolean;
  scale?: number;
  style?: CSSProperties;
  onClick?: () => void;
}

export const Sad: React.FC<AnimatedIconProps> = (props) => (
  <IconWrapper {...props}>
    <SadIcon {...props}>
      <div>
        <LeftEye />
        <RightEye />
        <Mouth />
      </div>
    </SadIcon>
  </IconWrapper>
);

export const Confusing: React.FC<AnimatedIconProps> = (props) => (
  <IconWrapper {...props}>
    <ConfusingIcon {...props}>
      <div>
        <LeftEye />
        <RightEye />
        <Mouth />
      </div>
    </ConfusingIcon>
  </IconWrapper>
);

export const Ok: React.FC<AnimatedIconProps> = (props) => (
  <IconWrapper {...props}>
    <OkIcon {...props}>
      <div>
        <LeftEye />
        <RightEye />
        <Mouth />
      </div>
    </OkIcon>
  </IconWrapper>
);

export const Glad: React.FC<AnimatedIconProps> = (props) => (
  <IconWrapper {...props}>
    <GladIcon {...props}>
      <div>
        <LeftEye />
        <RightEye />
      </div>
    </GladIcon>
  </IconWrapper>
);

export const NoResult: React.FC<AnimatedIconProps> = ({
  active,
  onClick,
  scale
}) => {
  const theme = useTheme();

  return (
    <IconWrapper scale={scale}>
      <svg
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <mask
          id="mask0_2004_61475"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="80"
          height="80"
        >
          <circle
            cx="40"
            cy="40"
            r="40"
            fill={active ? theme.primary : "#EEE"}
          />
        </mask>
        <g mask="url(#mask0_2004_61475)">
          <circle
            cx="40"
            cy="40"
            r="40"
            fill={active ? theme.primary : "#EEE"}
          />
          <path
            d="M36.6 44H43C43 41.24 49.4 39.4 49.4 33.44C49.4 28.24 44.84 25.44 40.28 25.44C35.84 25.44 31.96 27.76 30.2 31.88L35.72 35.04C36.52 33.16 37.84 31.68 40.04 31.68C42.08 31.68 43 32.6 43 34C43 37.52 36.6 39.32 36.6 44ZM39.8 54.56C41.88 54.56 43.6 52.84 43.6 50.76C43.6 48.68 41.88 46.96 39.8 46.96C37.72 46.96 36 48.68 36 50.76C36 52.84 37.72 54.56 39.8 54.56Z"
            fill="black"
          />
        </g>
      </svg>
    </IconWrapper>
  );
};
