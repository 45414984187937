import React from "react";
import ClanProfileAvatar from "./ClanProfileAvatar";
import {
  ClanMembershipListItemResponse,
  RoleAwareProfileWithImage,
  ClanProfileListItemResponse
} from "../util/models/ClanProfileModels";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../routes";
import ClanTeacherBadge from "./ClanTeacherBadge";

interface ClanProfileListItemProps {
  profile:
    | RoleAwareProfileWithImage
    | ClanMembershipListItemResponse
    | ClanProfileListItemResponse;
  birthday?: string;
  onNavigation?: any;
}

// TODO: This component is very similar to ClanSelectableItem. It can be merged into that one
const ClanProfileListItem: React.FC<ClanProfileListItemProps> = ({
  profile,
  birthday = undefined,
  onNavigation
}) => {
  const history = useHistory();
  const name =
    (profile as ClanProfileListItemResponse).fullName ||
    (profile as RoleAwareProfileWithImage).name ||
    (profile as ClanMembershipListItemResponse).name;

  const profileId =
    (profile as ClanMembershipListItemResponse).profileId ||
    (profile as RoleAwareProfileWithImage).id ||
    (profile as ClanProfileListItemResponse).id;

  async function loadPerson(personId: number) {
    await history.push(
      ROUTES.PUBLIC_PROFILE.replace(":profileId", personId.toString())
    );
    if (onNavigation) {
      onNavigation();
    }
  }

  return (
    <div
      className="text-center mx-2 my-1 rounded-full p-3 bg-clanGray-100 grid grid-cols-3auto-middle min-h-clanListItem cursor-pointer"
      onClick={() => profileId && loadPerson(profileId)}
    >
      <ClanProfileAvatar
        src={profile.images?.thumbnail?.url}
        alt={`${name} avatar`}
        className="w-clanProfileAvatarSmall h-clanProfileAvatarSmall self-center justify-start border border-clanYellow-100 rounded-full border-solid"
      />
      <h5 className="whitespace-normal text-black self-center px-4">
        {name}
        <ClanTeacherBadge profile={profile} />
      </h5>
      {birthday && (
        <h5 className="whitespace-normal text-clanGray-200 self-center ml-auto">
          {birthday}
        </h5>
      )}
    </div>
  );
};

export default ClanProfileListItem;
