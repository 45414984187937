import {
  ConstantConfigurationBase,
  ViewFilterSharingBase
} from "./ViewFilterSharingBase";

export enum ViewSharings {
  EVERYONE = "EVERYONE",
  GROUPS_MINE = "GROUPS_MINE",
  ROLES_STAFF_ALL = "ROLES_STAFF_ALL",
  ROLES_STUDENTS_ALL = "ROLES_STUDENTS_ALL",
  ROLES_TEACHERS_ALL = "ROLES_TEACHERS_ALL"
}

export type ViewSharingType =
  | ViewTypes.GROUP
  | ViewTypes.ROLE
  | ViewTypes.ORGANISATION;

export type ViewSharingConfigurationItem = ConstantConfigurationBase<
  ViewSharings,
  ViewSharingType
>;

export type ViewSharingConfiguration = ViewFilterSharingBase<
  ViewSharings,
  ViewSharingType
>;
