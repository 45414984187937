import { doRequestAndReturnResult } from "../../../apiService";
import { OauthState } from "../../../data/oauth/oauth.state";
import { hasValue } from "../../NullSafe";

import {
  ChartResultFlagWithProfiles,
  ChartResultNumber,
  ChartResultPercentage
} from "../types/Results";
import { hasAnalyticsDashboardAccess } from "./HasAccess";
import {
  UnifiedStatsResult,
  UnifiedStatsResults
} from "../types/UnifiedStatsResult.d";
import { ChartType } from "../types/ChartType.d";
import {
  FilterGroupType,
  joinFilterGroupTypeId,
  resolveConstantGroupFilter
} from "../../Filters";

const getDashboardResults = async (
  url: string,
  groups?: string
): Promise<UnifiedStatsResults> => {
  const requestResult = await doRequestAndReturnResult<UnifiedStatsResult>(
    "get",
    url,
    undefined,
    // eslint-disable-next-line @typescript-eslint/camelcase
    groups ? { group_id: groups } : undefined
  );
  if (!requestResult?.numbers) {
    return { wellBeing: requestResult?.wellBeing };
  }
  const result = {
    numbers: [] as ChartResultNumber[],
    mood: [] as ChartResultPercentage[],
    flags: [] as ChartResultFlagWithProfiles[],
    wellBeing: requestResult?.wellBeing
  };

  requestResult.numbers.forEach((val) => {
    if (val.type === ChartType.NUMBER) {
      result.numbers.push(val as ChartResultNumber);
    } else if (val.type === ChartType.PERCENTAGE) {
      result.mood.push(val as ChartResultPercentage);
    } else if (val.type === ChartType.FLAG) {
      result.flags.push(val as ChartResultFlagWithProfiles);
    }
  });
  return result;
};

export const fetchAnalyticsDashboard = async (
  oauthData: OauthState,
  filters: FilterGroupType[]
): Promise<UnifiedStatsResults | undefined | null> => {
  if (!hasAnalyticsDashboardAccess(oauthData)) {
    return new Promise((resolve) => resolve(undefined));
  }
  const filter = resolveConstantGroupFilter(filters);
  try {
    if (filter) {
      return getDashboardResults(`/stats/v2/dashboard/${filter.filter}`);
    } else {
      const getGroupIdList = joinFilterGroupTypeId(filters);
      return getDashboardResults("/stats/v2/dashboard", getGroupIdList);
    }
  } catch (e) {
    if (e.response && e.response.status === 428) return null;
    else
      console.error(
        "[WELLBEING_SERVICE] Could not fetch wellbeing data due to unrecognized error"
      );
  }
};

export const fetchAnalyticsPersonalDashboard = async (
  profileId?: number | string
): Promise<UnifiedStatsResults | undefined | null> => {
  try {
    const url = hasValue(profileId)
      ? `/stats/v1/dashboard/personal/${profileId}`
      : "/stats/v1/dashboard/personal";
    return getDashboardResults(url);
  } catch (e) {
    if (e.response && e.response.status === 428) return null;
    else
      console.error(
        "[WELLBEING_SERVICE] Could not fetch wellbeing data due to unrecognized error"
      );
  }
};
