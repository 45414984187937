import styled, { css } from "styled-components";

import Card from "../Card";

export const ListItemCard = styled(Card)<{ onClick?: any }>`
  padding: 10px 10px 10px 14px;
  margin: 12px 0;

  ${({ onClick }) =>
    !onClick &&
    css`
      box-shadow: none;
    `}
`;
