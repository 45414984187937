import React from "react";
import { H3, H6 } from "../Typography";
import styled from "styled-components";
import { FlexRow } from "../Flexbox";

const IconHeadingWrapper = styled(FlexRow)`
  margin-bottom: 16px;
`;

const StyledH3 = styled(H3)`
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
`;

interface IconHeadingProps {
  title: string;
  description?: string;
  icon: any;
}

const IconHeading: React.FC<IconHeadingProps> = ({
  title,
  description,
  icon
}) => (
  <IconHeadingWrapper columnGap="12px" align="center">
    {icon}
    <div>
      <StyledH3>{title}</StyledH3>
      <H6>{description}</H6>
    </div>
  </IconHeadingWrapper>
);

export default IconHeading;
