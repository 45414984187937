import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as RemoveIcon } from "../../icons/remove.svg";
import { FilterGroupType } from "../../util/Filters";
import Badge from "../Badge";
import IconButton from "../Buttons/IconButton";

const SchoolFilterBadge = styled(Badge)`
  text-transform: uppercase;
  padding: 10px 22px;
`;

interface ActiveSchoolFilterProps {
  placeholder?: any;
  filters: Array<FilterGroupType>;
  clearFilters: () => void;
}

const ActiveSchoolFilter: React.FC<ActiveSchoolFilterProps> = ({
  placeholder,
  filters,
  clearFilters
}) => {
  const { t } = useTranslation();

  const getActiveSchoolFilter = () => {
    if (filters.length > 0) {
      if (filters.length === 1) {
        const activeFilter = filters[0];
        return activeFilter.isDefault ? null : activeFilter.label;
      }
      return t("school_filter.btn_selected_groups");
    }
    return null;
  };

  const activeSchoolFilter = getActiveSchoolFilter();

  if (activeSchoolFilter) {
    return (
      <SchoolFilterBadge>
        <div>{activeSchoolFilter}</div>
        <IconButton icon={<RemoveIcon />} onClick={clearFilters} />
      </SchoolFilterBadge>
    );
  }

  return placeholder || null;
};

export default ActiveSchoolFilter;
