import { ApiRequestErrorJSON } from "../../ApiService";

export enum FirebaseApiServiceErrorType {
  GENERIC,
  MAIL_NOT_VERIFIED
}

export class FirebaseApiServiceError extends Error {
  readonly isFirebaseApiServiceError = true;
  readonly type: FirebaseApiServiceErrorType;
  constructor(
    error: Error | string,
    type = FirebaseApiServiceErrorType.GENERIC
  ) {
    super(typeof error === "string" ? error.toString() : error.message);
    this.type = type;
    this.name = "FirebaseApiServiceError";

    // see example at https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, FirebaseApiServiceError.prototype);
    this.toJSON = () => {
      return {
        type: this.type,
        message: this.message,
        name: this.name,
        cause: this.cause.toString(),
        status: this.response?.status,
        statusText: this.response?.statusText,
        stack: this.cause.stack,
        request: this.request
      } as ApiRequestErrorJSON;
    };
  }
}
