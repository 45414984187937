import { AxiosInstance, AxiosResponse } from "axios";

import { configuration } from "../../../configuration";
import { ApiServiceMethod } from "../types/ApiServiceMethod.d";
import { ApiServiceRequestWithMethod } from "../types/ApiServiceRequest.d";
import { ApiRequestErrorImpl } from "./ApiRequestErrorImpl";

const getAdditionalHeader = () => {
  const { appVersion, appBuild } = configuration;

  if (appVersion && appBuild) {
    return { "X-Client-Version": `${appVersion}_${appBuild}` };
  } else {
    return { "X-Client-Version": `no-version` };
  }
};

// We export this for testing purposes. Never import or use it directly!
export const rawAxiosRequest = async <T>(
  axiosInstance: AxiosInstance,
  request: ApiServiceRequestWithMethod
): Promise<AxiosResponse<T>> => {
  try {
    const versionHeaders = getAdditionalHeader();
    const headers = request.headers
      ? { ...request.headers, ...versionHeaders }
      : { ...versionHeaders };
    return await axiosInstance({
      url: request.url,
      method: request.method,
      params: request.params,
      ...([
        ApiServiceMethod.POST,
        ApiServiceMethod.PUT,
        ApiServiceMethod.DELETE
      ].includes(request.method) && {
        data: request.data
      }),
      headers
    });
  } catch (error) {
    throw new ApiRequestErrorImpl({ error });
  }
};
