import { Dispatch } from "react";

import { ApiRequestError } from "../../util/ApiService";
import RollbarManager from "../../util/RollbarManager";
import { LatestTimestampForChatId } from "./app.state";
import {
  getFeedbackGivenCookie,
  getLatestActivityTimestampData,
  getLatestChathubTimestampsData,
  getLatestReportTimestampData,
  getRecievedFirstSurveyCookie,
  getTutorialOpenedCookie,
  setFeedbackGivenCookie,
  setInfoModalClosedCookie,
  setLatestActivityTimestampData,
  setLatestChathubTimestampsData,
  setLatestReportTimestampData,
  setRecievedFirstSurveyCookie,
  setTutorialOpenedCookie
} from "./appDataService";

export const ADD_LOADER_STATE = "add-loader-state";
export const REMOVE_LOADER_STATE = "remove-loader-state";

export const SET_APP_ERROR = "set-app-error";
export const RESET_APP_ERROR = "reset-app-error";
export const APP_RESET = "cb-app-reset";

export const SET_APP_READY = "set-app-ready";

export const SET_INFO_MODAL_CLOSED = "set-info-modal-seen";
export const SET_TUTORIAL_OPENED = "set-tutorial-opened";
export const SET_RECIEVED_FIRST_SURVEY = "set-recieved-first-survey";
export const SET_LATEST_ACTIVITY_TIMESTAMP = "set-latest-activity-timestamp";
export const SET_LATEST_CHAT_TIMESTAMPS = "set-latest-chat-timestamps";
export const SET_LATEST_REPORT_TIMESTAMP = "set-latest-report-timestamp";
export const SET_GIVEN_FEEDBACK = "set-given-feedback";

export type AppAction = {
  type: string;
  data: any;
};

export type ErrorAppAction = {
  type: string;
  data: ApiRequestError | string;
};

export const getSetAppReadyActionPayload = () =>
  ({
    type: SET_APP_READY,
    data: ""
  } as AppAction);

const getStartLoadingActionPayload = (identifier: string) => {
  return {
    type: ADD_LOADER_STATE,
    data: identifier
  } as AppAction;
};

const getStopLoadingActionPayload = (identifier: string) => {
  return {
    type: REMOVE_LOADER_STATE,
    data: identifier
  } as AppAction;
};

export const getAddAppErrorActionPayload = (error: ApiRequestError | string) =>
  ({
    type: SET_APP_ERROR,
    data: error
  } as ErrorAppAction);

export const getResetAppErrorActionPayload = () =>
  ({
    type: RESET_APP_ERROR
  } as AppAction);

export const setAppError = (error: ApiRequestError | string, force = false) => (
  dispatch: Dispatch<any>
) => {
  // Not trigerring error view for all errors cause in some cases (404, 401, 412) it's expected behavior
  if (
    !force &&
    typeof error !== "string" &&
    (error?.response?.status === 404 ||
      error?.response?.status === 401 ||
      error?.response?.status === 412 ||
      error?.response?.status === 428)
  ) {
    return;
  }

  withLoading("app.actions:setAppError", async () => {
    dispatch(getAddAppErrorActionPayload(error));
  })(dispatch);

  if (typeof error === "string") {
    RollbarManager.logError(new Error(error));
  } else {
    const code = error?.response?.status;
    const domain = error?.request?.baseUrl || undefined;
    RollbarManager.logError(error as Error, { code, domain });
  }
};

export const resetAppError = () => async (dispatch: Dispatch<any>) => {
  withLoading("app.actions:resetAppError", async () => {
    dispatch(getResetAppErrorActionPayload());
  })(dispatch);
};

export const getAppResetActionPayload = () =>
  ({
    type: APP_RESET,
    data: ""
  } as AppAction);

export const withLoading = <T>(
  identifier: string,
  callable: () => Promise<T>
) => async (dispatch: Dispatch<any>): Promise<T> => {
  dispatch(getStartLoadingActionPayload(identifier));
  try {
    return await callable();
  } finally {
    dispatch(getStopLoadingActionPayload(identifier));
  }
};

export const setInfoModalClosed = (infoModalClosed: boolean) => async (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: SET_INFO_MODAL_CLOSED,
    data: infoModalClosed
  } as AppAction);

  setInfoModalClosedCookie(infoModalClosed);
};

export const setRecievedFirstSurvey = (hasRecieved: boolean) => async (
  dispatch: Dispatch<any>
) => {
  setRecievedFirstSurveyCookie(hasRecieved).then(() => {
    dispatch({
      type: SET_RECIEVED_FIRST_SURVEY,
      data: hasRecieved
    } as AppAction);
  });
};

export const setGivenFeedback = (hasGiven: boolean) => async (
  dispatch: Dispatch<any>
) => {
  setFeedbackGivenCookie(hasGiven).then(() => {
    dispatch({
      type: SET_GIVEN_FEEDBACK,
      data: hasGiven
    } as AppAction);
  });
};

export const updateGivenFeedback = () => async (dispatch: Dispatch<any>) => {
  getFeedbackGivenCookie().then((hasGiven) => {
    dispatch({
      type: SET_GIVEN_FEEDBACK,
      data: hasGiven
    } as AppAction);
  });
};

export const updateLatestActivityTimestamp = () => async (
  dispatch: Dispatch<any>
) => {
  getLatestActivityTimestampData().then((timestamp) => {
    dispatch({
      type: SET_LATEST_ACTIVITY_TIMESTAMP,
      data: timestamp
    } as AppAction);
  });
};

export const updateLatestReportTimestamp = () => async (
  dispatch: Dispatch<any>
) => {
  getLatestReportTimestampData().then((timestamp) => {
    dispatch({
      type: SET_LATEST_REPORT_TIMESTAMP,
      data: timestamp
    } as AppAction);
  });
};

export const updateRecievedFirstSurvey = () => async (
  dispatch: Dispatch<any>
) => {
  getRecievedFirstSurveyCookie().then((hasRecievedFirstSurvey) => {
    dispatch({
      type: SET_RECIEVED_FIRST_SURVEY,
      data: hasRecievedFirstSurvey
    } as AppAction);
  });
};

export const setLatestActivityTimestamp = (timestamp: string) => async (
  dispatch: Dispatch<any>
) => {
  setLatestActivityTimestampData(timestamp).then(() => {
    dispatch({
      type: SET_LATEST_ACTIVITY_TIMESTAMP,
      data: timestamp
    } as AppAction);
  });
};

export const setLatestReportTimestamp = (timestamp: string) => async (
  dispatch: Dispatch<any>
) => {
  setLatestReportTimestampData(timestamp).then(() => {
    dispatch({
      type: SET_LATEST_REPORT_TIMESTAMP,
      data: timestamp
    } as AppAction);
  });
};

export const updateMessageRead = (chatId: string) => async (
  dispatch: Dispatch<any>
) => {
  getLatestChathubTimestampsData().then((timestamps) => {
    const newTimestamps = [...timestamps];

    // Adding 1 second as a hack because timestamp checking is broken
    const date = new Date();
    date.setSeconds(date.getSeconds() + 1);

    const timestampToUpdate = {
      id: chatId,
      timestamp: date.toISOString()
    };
    const chatIndex = newTimestamps.findIndex((t) => t.id === chatId);

    if (chatIndex >= 0) {
      newTimestamps.splice(chatIndex, 1, timestampToUpdate);
    } else {
      newTimestamps.push(timestampToUpdate);
    }

    setLatestChatTimestamps(newTimestamps)(dispatch);
  });
};

export const setLatestChatTimestamps = (
  timestamps: LatestTimestampForChatId[]
) => async (dispatch: Dispatch<any>) => {
  setLatestChathubTimestampsData(timestamps).then(() => {
    dispatch({
      type: SET_LATEST_CHAT_TIMESTAMPS,
      data: timestamps
    } as AppAction);
  });
};

export const updateLatestChatTimestamps = () => async (
  dispatch: Dispatch<any>
) => {
  getLatestChathubTimestampsData().then((timestamps) => {
    dispatch({
      type: SET_LATEST_CHAT_TIMESTAMPS,
      data: timestamps
    } as AppAction);
  });
};

export const updateTutorialOpened = () => async (dispatch: Dispatch<any>) => {
  getTutorialOpenedCookie().then((isOpened) => {
    dispatch({
      type: SET_TUTORIAL_OPENED,
      data: isOpened
    } as AppAction);
  });
};

export const setTutorialOpened = (isOpened: boolean) => async (
  dispatch: Dispatch<any>
) => {
  setTutorialOpenedCookie(isOpened).then(() => {
    dispatch({
      type: SET_TUTORIAL_OPENED,
      data: isOpened
    } as AppAction);
  });
};
