import {
  IonButton,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSlide,
  IonSlides
} from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";
import CategoryHeader from "../CategoryHeader";
import { useGroupQueryViewFilter } from "../../util/api/GroupsQueryService";
import { useAsyncEffect } from "@react-hook/async";
import {
  ViewFilterConfigurationItem,
  Views,
  ViewTypes
} from "../../util/Configuration";
import { FilterGroupType, ResolvedFilters } from "../../util/Filters";
import { constantFilterEquals } from "../../util/Filters/lib/FiltersEquals";
import { ReactComponent as FilterIcon } from "../../icons/filter.svg";
import IconButton from "../Buttons/IconButton";

const GroupsList = styled.div`
  margin: 0 auto 0.7rem;
  width: 90%;
  padding-top: env(safe-area-inset-top);
`;

const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.7rem;
`;

const GroupFilter = styled(IonItem)`
  border-radius: 25rem;
  margin: 10px 12px;
  cursor: pointer;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --padding-start: 20px;
  --padding-end: 0;
  --background: #fafafa;
  --min-height: auto;
`;

const RoleFilter = styled.div`
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background: #fafafa;
  margin: 0.5rem 0.3rem;
  width: 100%;
  height: 6rem;
  background: #fafafa;
  align-items: center;
  justify-items: center;
  cursor: pointer;
`;

const buildSlideOptions = (numOfFilters: number) => ({
  resizeObserver: true,
  observeSlideChildren: true,
  observer: true,
  observeParents: true,
  breakpoints: {
    320: {
      slidesPerView: 2.4,
      slidesPerColumn: 1
    },
    480: {
      slidesPerView: 3.4,
      slidesPerColumn: 1
    },
    [Number(process.env.REACT_APP_CONFIG_DESKTOP_BREAKPOINT)]: {
      slidesPerView: 2,
      slidesPerColumn: Math.ceil(numOfFilters / 2)
    }
  }
});

const GroupFilterTitle = styled(IonLabel)`
  font-size: 14px;
  font-family: Gilroy;
  text-transform: uppercase;
`;

const RoleFilterTitle = styled(IonLabel)`
  padding: 1rem;
  text-align: center;
  font-size: 14px;
  font-family: Gilroy;
  white-space: nowrap;
  text-transform: uppercase;
`;

const Checkbox = styled.input`
  visibility: hidden;
  width: 28px;
`;

const CheckboxLabel = styled.label<{ selected?: boolean }>`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  right: 0;
  position: absolute;
  top: -2px;
  width: 28px;
  ${({ selected }) =>
    selected && "background-color: #45DD85; border-color: #45DD85;"}

  &:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
    ${({ selected }) => selected && "opacity: 1"}
  }
`;

const toggleFilter = (
  filterItem: FilterGroupType,
  newFilters: FilterGroupType[],
  setNewFilters: (filter: FilterGroupType[]) => void,
  exclusive?: boolean
) => {
  if (newFilters.some((val) => constantFilterEquals(val, filterItem))) {
    // if filter exists let's remove it
    setNewFilters(
      newFilters.filter((val) => !constantFilterEquals(val, filterItem))
    );
    return;
  }
  if (exclusive || !filterItem.groupId) {
    // only one filter can be set
    setNewFilters([filterItem]);
    return;
  }
  setNewFilters([
    ...newFilters.filter((val) => !!filterItem.groupId === !!val.groupId),
    filterItem
  ]);
};

interface Filters {
  filterList: FilterGroupType[];
  newFilters: FilterGroupType[];
  setNewFilters: (newFilters: FilterGroupType[]) => void;
}

const Filters: React.FC<Filters> = ({
  filterList,
  newFilters,
  setNewFilters
}) => {
  const isChecked = (filterItem: FilterGroupType) =>
    newFilters.some((thisFilter) =>
      constantFilterEquals(filterItem, thisFilter)
    );

  return (
    <>
      {newFilters &&
        filterList.map((filter, index) => (
          <GroupFilter
            key={`FILTER_${index}`}
            onClick={(e) => {
              e.preventDefault();
              toggleFilter(filter, newFilters, setNewFilters);
            }}
          >
            <GroupFilterTitle>{filter.label}</GroupFilterTitle>
            <div className="relative">
              <Checkbox
                type="checkbox"
                checked={isChecked(filter)}
                onChange={() => toggleFilter(filter, newFilters, setNewFilters)}
              />
              <CheckboxLabel selected={isChecked(filter)} />
            </div>
          </GroupFilter>
        ))}
    </>
  );
};

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface FilterModalOwnProps {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  filters: FilterGroupType[];
  setFilters: (filters: FilterGroupType[]) => void;
  resolvedFilters: ResolvedFilters<ViewFilterConfigurationItem>;
  view: Views;
}

interface FilterModalProps extends FilterModalOwnProps, DispatchProps {}

const FilterModal: React.FC<FilterModalProps> = ({
  modalOpen,
  setModalOpen,
  filters,
  setFilters,
  resolvedFilters,
  dispatchToast,
  view
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [hasMoreGroups, setHasMoreGroups] = useState<boolean>(false);
  const [groups, setGroups] = useState<FilterGroupType[]>([]);
  const [newFilters, setNewFilters] = useState<FilterGroupType[]>(filters);
  const searchBarRef = useRef<HTMLIonSearchbarElement>(null);
  const [activateSearch, setActivateSearch] = useState<boolean>(false);
  const { t } = useTranslation();

  const groupDataIterator = useGroupQueryViewFilter(view);

  const checkNextGroups = () => {
    groupDataIterator
      .hasNext()
      .then(setHasMoreGroups)
      .catch((error) => {
        console.error("Failed to fetch next page of groups", error);
      });
  };

  const getNextGroups = async (isCleanLoad = false) => {
    try {
      const groupsResult = await groupDataIterator.getNext();
      const newGroups = groupsResult.map(
        (group) =>
          ({
            filter: group.name,
            view,
            label: group.name,
            type: ViewTypes.GROUP,
            groupId: group.id
          } as FilterGroupType)
      );
      if (isCleanLoad) {
        setGroups(newGroups);
      } else {
        setGroups([...groups, ...newGroups]);
      }
      checkNextGroups();
    } catch (error) {
      console.error("Failed to fetch next page of groups", error);
    }
  };

  useAsyncEffect(async () => {
    if (!modalOpen) {
      return;
    }
    try {
      await groupDataIterator.setQueryArgumentNoFetch("query", searchText);
      await groupDataIterator.reset();
      const result = await groupDataIterator.getNext();
      setGroups(
        result.map(
          (group) =>
            ({
              filter: group.name,
              view,
              label: group.name,
              type: ViewTypes.GROUP,
              groupId: group.id
            } as FilterGroupType)
        )
      );
      checkNextGroups();
    } catch (error) {
      console.error("Failed to fetch groups", error);
    }
  }, [searchText, groupDataIterator, modalOpen]);

  useEffect(() => {
    setNewFilters(filters);
  }, [filters]);

  const isChecked = (roleFilter: FilterGroupType) =>
    newFilters.some((thisFilter) =>
      constantFilterEquals(thisFilter, roleFilter)
    );

  return (
    <IonModal
      isOpen={modalOpen}
      onDidDismiss={() => {
        setModalOpen(false);
        setNewFilters(filters);
        setSearchText("");
      }}
      cssClass="tall-modal"
    >
      <IonHeader mode="ios">
        <GroupsList>
          <HeaderBar>
            <h2>{t("school_filter.view_filter")}</h2>
            <IonButton
              style={{
                "--padding-start": "0",
                "--padding-end": "0"
              }}
              className="text-clanGray-200 h-clanBtn text-clanH2 align-middle"
              color="tertiary"
              fill="clear"
              onClick={() => {
                if (activateSearch) {
                  setActivateSearch(false);
                  setSearchText("");
                } else {
                  setModalOpen(false);
                  setNewFilters(filters);
                  setSearchText("");
                }
              }}
            >
              <IonIcon
                size="large"
                icon={closeCircle}
                className="text-clanGray-200 h-12 w-12"
              />
            </IonButton>
          </HeaderBar>
        </GroupsList>
        {!activateSearch && resolvedFilters.userFilters.length !== 0 && (
          <div>
            <CategoryHeader
              className="my-4 mx-auto"
              title={t("school_filter.filter_type_role")}
              tooltipText={t("school_filter.filter_type_role_desc")}
            />
            <GroupsList>
              <p className="font-gilroy text-clanGray-200 m-0">
                {t("school_filter.role_filter_desc")}
              </p>
              <IonSlides
                options={buildSlideOptions(resolvedFilters.userFilters.length)}
                mode="ios"
              >
                {resolvedFilters.userFilters
                  .sort((item1, item2) =>
                    item1.isDefault && !item2.isDefault ? -1 : 0
                  )
                  .map((roleFilter, index) => (
                    <IonSlide key={`ROLE_${index}`}>
                      <RoleFilter
                        onClick={() =>
                          toggleFilter(
                            roleFilter,
                            newFilters,
                            setNewFilters,
                            true
                          )
                        }
                      >
                        <RoleFilterTitle>{roleFilter.label}</RoleFilterTitle>
                        <div className="relative">
                          <Checkbox
                            type="checkbox"
                            checked={isChecked(roleFilter)}
                            onChange={() =>
                              toggleFilter(
                                roleFilter,
                                newFilters,
                                setNewFilters,
                                true
                              )
                            }
                          />
                          <CheckboxLabel selected={isChecked(roleFilter)} />
                        </div>
                      </RoleFilter>
                    </IonSlide>
                  ))}
              </IonSlides>
            </GroupsList>
          </div>
        )}
      </IonHeader>
      {(groups.length > 0 ||
        resolvedFilters.groupsFilters.length > 0 ||
        resolvedFilters.defaultFilter?.type === ViewTypes.GROUP) && (
        <ClanIonContent>
          {!activateSearch && (
            <CategoryHeader
              className="my-4 mx-auto"
              title={t("school_filter.filter_type_group")}
              tooltipText={t("school_filter.filter_type_group_desc")}
            />
          )}
          <GroupsList>
            <p className="font-gilroy text-clanGray-200 m-0">
              {newFilters.filter((val) => !!val.groupId).length > 0
                ? `${newFilters.filter((val) => !!val.groupId).length} ${t(
                    "school_filter.group_filters_selected"
                  )}`
                : t("school_filter.group_filters_choose")}
            </p>
            <IonSearchbar
              value={searchText}
              className="p-0"
              mode="ios"
              placeholder={t("school_filter.search_group_filters_search")}
              ref={searchBarRef}
              onIonChange={(e) => {
                setSearchText(e.detail.value || "");
              }}
              onIonFocus={() =>
                view === Views.Community && setActivateSearch(true)
              }
            />
            <Filters
              filterList={resolvedFilters.groupsFilters}
              newFilters={newFilters}
              setNewFilters={setNewFilters}
            />
            {groups.length > 0 && (
              <Filters
                filterList={groups}
                newFilters={newFilters}
                setNewFilters={setNewFilters}
              />
            )}
            <IonSegment>
              <IonSegmentButton
                className="font-extrabold font-gilroy text-clanGreen-100"
                disabled={!hasMoreGroups}
                onClick={() => {
                  getNextGroups(false);
                }}
              >
                {hasMoreGroups
                  ? t("general.list_load_more")
                  : t("general.list_end")}
              </IonSegmentButton>
            </IonSegment>
            {groups.length === 0 && (
              <GroupFilterTitle>
                {t("school_filter.search_group_no_filters")}
              </GroupFilterTitle>
            )}
          </GroupsList>
        </ClanIonContent>
      )}
      <IonFooter mode="ios">
        <IonButton
          expand="block"
          className="my-4 mx-20 font-extrabold font-gilroy h-16"
          style={{ "--border-radius": "100px" }}
          onClick={() => {
            if (newFilters.length === 0) {
              dispatchToast(t("school_filter.at_least_one"));
            } else {
              setFilters(newFilters);
              setModalOpen(false);
            }
          }}
        >
          {t("school_filter.save")}
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};

const FilterModalConnected = connect<FilterModalOwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: FilterModal
});

interface FilterProps {
  resolvedFilters: ResolvedFilters<ViewFilterConfigurationItem>;
  filters: FilterGroupType[];
  setFilters: (filters: FilterGroupType[]) => void;
  view: Views;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface SchoolFilterProps extends FilterProps, DispatchProps {}

const SchoolFilter: React.FC<SchoolFilterProps> = ({
  resolvedFilters,
  setFilters,
  filters,
  view,
  dispatchToast
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onOpenFilter = () => {
    resolvedFilters.showNotPaidNotification
      ? dispatchToast(
          t("school_filter.filter_not_available"),
          undefined,
          "info",
          [
            {
              label: t("general.cancel")
            },
            {
              label: t("groups_view.get_in_touch"),
              href: "https://www.clanbeat.com/get-in-touch/",
              primary: true
            }
          ]
        )
      : setModalOpen((existingValue) => !existingValue);
  };

  return (
    <>
      <FilterModalConnected
        filters={filters}
        setFilters={setFilters}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        resolvedFilters={resolvedFilters}
        view={view}
      />

      <IconButton icon={<FilterIcon />} onClick={onOpenFilter} />
    </>
  );
};

export default connect<FilterProps, DispatchProps>({
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: SchoolFilter
});
