import {
  IonFooter,
  IonHeader,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonText
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { analyticsLogEvent } from "../../Analytics";
import { doRequestAndReturnResult } from "../../apiService";
import { getPostById } from "../../util/ClanPostAPIService";
import {
  ClanPostListResult,
  ClanPostResultDetails,
  ClanPostResultDetailsV3
} from "../../util/models/ClanPostModels";
import { SharedUserTaskResult } from "../../util/models/PlannerUserTaskModels";
import { ShareTabs } from "../../util/ShareOptions";
import { NewTask } from "../Planner/NewTaskModal";
import ClanSharePost from "./edit/ClanSharePost";
import { useTranslation } from "react-i18next";
import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";
import { Views } from "../../util/Configuration";
import { reSharePost } from "../../util/Feeds";

type ClanPostSharingModalType = {
  view: Views;
  enableDirectShare?: boolean;
  onClose?: () => void;
  onDone: (sharedPost?: ClanPostResultDetails) => void;
  post: ClanPostListResult | SharedUserTaskResult | NewTask;
};

const ClanPostSharingModal: React.FC<ClanPostSharingModalType> = ({
  view,
  enableDirectShare,
  post,
  onClose,
  onDone
}) => {
  const [shareToProfiles, setShareToProfiles] = useState<number[]>([]);
  const [shareToGroups, setShareToGroups] = useState<number[]>([]);
  const [shareToRoles, setShareToRoles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alreadyShared, setAlreadyShared] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (post && !enableDirectShare) {
      (async function () {
        const result = await getPostById(
          (post as ClanPostResultDetailsV3 | SharedUserTaskResult).id
        );
        if (result) {
          const {
            groupsAmount,
            profilesAmount,
            sharingShortcut,
            sharedProfiles,
            sharedGroups
          } = result.sharing || {};

          if (groupsAmount > 0 || profilesAmount > 0 || sharingShortcut) {
            setAlreadyShared(true);
          }

          setShareToProfiles((sharedProfiles || []).map(({ id }) => id));
          setShareToGroups((sharedGroups || []).map(({ id }) => id));

          if (sharingShortcut) setShareToRoles([sharingShortcut]);
        }
      })();
    }
  }, [post, enableDirectShare]);

  async function onSubmit() {
    if (enableDirectShare) {
      await setIsLoading(true);
      try {
        analyticsLogEvent("PLANNER_ITEM_add_directShare");
        const submittedTask: SharedUserTaskResult = await doRequestAndReturnResult(
          "post",
          "/usertask/v1/todo",
          post
        );
        doRequestAndReturnResult(
          "put",
          `/usertask/v1/todo/${submittedTask.id}/share`,
          {
            sharedProfiles: shareToProfiles,
            sharedGroups: shareToGroups,
            sharedShortcuts: shareToRoles
          }
        ).then(() => {
          onDone();
        });
      } finally {
        await setIsLoading(false);
      }
    } else if (post) {
      setIsLoading(true);
      reSharePost(
        view,
        (post as ClanPostListResult | SharedUserTaskResult).id,
        {
          sharedProfiles: shareToProfiles,
          sharedGroups: shareToGroups,
          sharedShortcuts: shareToRoles
        }
      )
        .then((sharedPost: ClanPostResultDetails) => {
          onDone(sharedPost);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      onDone();
    }
  }

  return (
    <IonModal isOpen onDidDismiss={onClose}>
      <IonHeader mode="ios" className="px-4">
        <div>
          <div className="mt-12 text-center">
            <IonText>
              <h3 className="my-1">{t("clan_discussion_post.share_post")}</h3>
            </IonText>
            <IonText>
              <h6>{t("clan_discussion_post.share_title")}</h6>
            </IonText>
          </div>
        </div>
      </IonHeader>
      <ClanIonContent>
        <ClanSharePost
          view={view}
          groups={shareToGroups}
          profiles={shareToProfiles}
          roles={shareToRoles}
          setRoles={setShareToRoles}
          setGroups={setShareToGroups}
          setProfiles={setShareToProfiles}
          defaultTab={ShareTabs.INDIVIDUAL}
          disableRoles
        />
      </ClanIonContent>
      <IonFooter>
        <IonSegment>
          <IonSegmentButton
            className="font-extrabold font-gilroy text-clanGreen-100"
            onClick={onClose}
          >
            {t("general.back")}
          </IonSegmentButton>
          <IonSegmentButton
            color="primary"
            className="font-extrabold bg-clanYellow-100 font-gilroy text-black"
            disabled={
              isLoading ||
              (!alreadyShared &&
                shareToGroups.length === 0 &&
                shareToProfiles.length === 0 &&
                shareToRoles.length === 0)
            }
            onClick={onSubmit}
          >
            {alreadyShared &&
            shareToGroups.length === 0 &&
            shareToProfiles.length === 0 &&
            shareToRoles.length === 0
              ? t("clan_discussion_post.unshare")
              : t("clan_discussion_post.share")}
          </IonSegmentButton>
        </IonSegment>
      </IonFooter>
    </IonModal>
  );
};

export default ClanPostSharingModal;
