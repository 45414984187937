import { arrowDown, arrowForward, arrowUp } from "ionicons/icons";
import React from "react";

import {
  AnimatedIconProps,
  Confusing,
  Glad,
  NoResult,
  Ok,
  Sad
} from "../../components/Survey/SurveyResponse";
import { AuthorizedApiResult } from "../ApiService";
import { ProfileWithImage } from "../models/ClanProfileModels";

import { ReactComponent as PositiveIcon } from "../../icons/survey/shift/positive.svg";
import { ReactComponent as NegativeIcon } from "../../icons/survey/shift/negative.svg";
import { ReactComponent as ConstantIcon } from "../../icons/survey/shift/constant.svg";

export type WellBeingResultType = {
  shift: number;
  result: number;
};

export type WellBeingQuestionProfile = {} & WellBeingResultType &
  ProfileWithImage;

export type WellBeingQuestion = {
  dateTime: string;
  label: string;
  question: string;
  key: string;
  profilesTotal: number;
  profilesAnswered: number;
  profiles: WellBeingQuestionProfile[];
  answersAggregated: { [key: number]: number };
} & WellBeingResultType;

export const getWellBeingSurveyResultProgressChangeIcon = (
  wellBeingResult: WellBeingResultType
): string => {
  return wellBeingResult.shift < 0
    ? arrowDown
    : wellBeingResult.shift > 0
    ? arrowUp
    : arrowForward;
};

export type WellBeingResponseTranslated<T extends WellBeingResultType> = {
  label: string;
  component: React.FC<AnimatedIconProps>;
  progressIcon: React.FC;
  result: T;
};

export enum WellBeingSurveyResponseType {
  question = "question",
  message = "message"
}

export type WellBeingSurveyResponse = {
  resultType: WellBeingSurveyResponseType;
};

export enum WellBeingSurveyQuestionResultMessageType {
  negative = "negative",
  neutral = "neutral",
  positive = "positive",
  timeout = "timeout",
  negativeStreak = "negative_streak",
  positiveStreak = "positive_streak",
  into = "intro"
}

export type WellBeingSurveyQuestionResultMessage = {
  type: WellBeingSurveyQuestionResultMessageType;
  message: string;
};

export type WellBeingSurveyQuestionResult = {
  messages: WellBeingSurveyQuestionResultMessage[];
} & WellBeingSurveyResponse;

export enum WellBeingSurveyQuestionType {
  number = "number"
}

export type WellBeingSurveyQuestion = {
  id: string;
  title: string;
  type: WellBeingSurveyQuestionType;
  minimumNumber?: number;
  maximumNumber?: number;
  key: string;
  group: string;
} & WellBeingSurveyResponse;

export type AnyWellBeingSurveyQuestion =
  | WellBeingSurveyQuestionResult
  | WellBeingSurveyQuestion;

export const getNextWellBeingSurveyQuestion = async (): Promise<AnyWellBeingSurveyQuestion> =>
  AuthorizedApiResult.get<AnyWellBeingSurveyQuestion>(`/userquestion/v2`);

export const answerWellBeingSurveyQuestion = async (
  id: string,
  value: number
): Promise<AnyWellBeingSurveyQuestion> =>
  AuthorizedApiResult.put<AnyWellBeingSurveyQuestion>(
    `/userquestion/v2/number/${id}?value=${value}`
  );

export const skipWellBeingSurveyQuestion = async (
  id: string
): Promise<AnyWellBeingSurveyQuestion> =>
  AuthorizedApiResult.delete<AnyWellBeingSurveyQuestion>(
    `/userquestion/v2/${id}/skip`
  );

export const WELL_BEING_NUMBER_LABELS = ["Sad", "Confusing", "OK", "Glad"];

const WELLBEING_IMAGES = {
  sad: Sad,
  confusing: Confusing,
  ok: Ok,
  glad: Glad
} as { [any: string]: React.FC<AnimatedIconProps> };

const NO_RESULT_LABEL = "No Answer";

export function getWellBeingNumericQuestionByResultNumber<
  T extends WellBeingResultType
>(wellBeingResult: T): WellBeingResponseTranslated<T> {
  const label =
    wellBeingResult.result <= 0 ||
    wellBeingResult.result > WELL_BEING_NUMBER_LABELS.length
      ? null
      : WELL_BEING_NUMBER_LABELS[wellBeingResult.result - 1];

  const component = label ? WELLBEING_IMAGES[label.toLowerCase()] : NoResult;

  const progressIcon = getShiftArrow(wellBeingResult.shift);

  return {
    label: label ? label : NO_RESULT_LABEL,
    component,
    progressIcon,
    result: wellBeingResult
  };
}

export const getWellBeingLabelAndImages = (
  min = 1,
  max = WELL_BEING_NUMBER_LABELS.length
): WellBeingResponseTranslated<WellBeingResultType>[] => {
  const result = [];
  for (let i = max; i >= min; i--) {
    result.push(
      getWellBeingNumericQuestionByResultNumber({
        result: i,
        shift: -1
      } as WellBeingResultType)
    );
  }
  return result;
};

export const getShiftArrow = (shift: number) =>
  shift < 0 ? NegativeIcon : shift > 0 ? PositiveIcon : ConstantIcon;
