import { IonPage } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { analyticsLogEvent } from "../Analytics";

import { LlamaUIButton } from "../components/Buttons/BigUIButton";
import ClanDesktopLayout from "../components/ClanDesktopLayout";
import Header from "../components/Headers/Header";
import { Heading2 } from "../components/Headings/Headings";
import { Section } from "../components/Section";
import { ClanIonContent } from "./DiscussionFeed/PublicFeeds";

const GetStarted = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <ClanDesktopLayout>
      <IonPage>
        <Header title="" onBack={() => history.goBack()} />
        <ClanIonContent>
          <Section>
            <Heading2
              title={t("get_started.heading1")}
              description={t("get_started.heading_description1")}
            />

            <LlamaUIButton
              primaryText={t("get_started.description1")}
              secondaryText={t("get_started.title1")}
              href="https://www.clanbeat.com/get-in-touch/"
              onClick={() => {
                analyticsLogEvent("TEACHER_GETSTARTED_GETINTOUCH");
              }}
            />

            <LlamaUIButton
              primaryText={t("get_started.description2")}
              secondaryText={t("get_started.title2")}
              href="https://calendly.com/mariasakarias"
              onClick={() => {
                analyticsLogEvent("TEACHER_GETSTARTED_SCHEDULE_CALL");
              }}
            />
          </Section>

          <Section>
            <Heading2
              title={t("get_started.heading2")}
              description={t("get_started.heading_description2")}
            />

            <LlamaUIButton
              primaryText={t("get_started.description3")}
              secondaryText={t("get_started.title3")}
              href="https://bit.ly/CB-guide-EN"
              onClick={() => {
                analyticsLogEvent("TEACHER_GETSTARTED_GUIDE");
              }}
            />
            <LlamaUIButton
              primaryText={t("get_started.description4")}
              secondaryText={t("get_started.title4")}
              href="http://bit.ly/CB-demo-EN"
              onClick={() => {
                analyticsLogEvent("TEACHER_GETSTARTED_DEMO");
              }}
            />
            <LlamaUIButton
              primaryText={t("get_started.description5")}
              secondaryText={t("get_started.title5")}
              href="https://www.clanbeat.com/materials/"
              onClick={() => {
                analyticsLogEvent("TEACHER_GETSTARTED_MATERIALS");
              }}
            />
          </Section>
        </ClanIonContent>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default GetStarted;
