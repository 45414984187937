import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import ClanInfoCard from "../../components/ClanInfoCard";
import FooterButtons, {
  PrimaryFooterButton
} from "../../components/FooterButtons";
import * as ROUTES from "../../routes";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "./Onboarding";

interface OnboaringSuccessStepProps {
  setCurrentStep: any;
}

const OnboaringSuccessStep: React.FC<OnboaringSuccessStepProps> = ({
  setCurrentStep
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <ClanIonContent>
        <OnboardingWrapper>
          <ClanInfoCard title={t("onboarding.success_header")} />
        </OnboardingWrapper>
      </ClanIonContent>
      <FooterButtons>
        <PrimaryFooterButton
          onClick={() => {
            history.replace(ROUTES.HOME);
            setCurrentStep(1);
          }}
        >
          {t("onboarding.success_super")}
        </PrimaryFooterButton>
      </FooterButtons>
    </>
  );
};

export default OnboaringSuccessStep;
