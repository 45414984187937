import { combineReducers } from "./combineReducers";
import { oauthReducer } from "./oauth/oauth.reducer";
import { OauthState } from "./oauth/oauth.state";
import { UserProfileState } from "./user/profile/profile.state";
import { ImageState } from "./user/image/image.state";
import { userProfileReducer } from "./user/profile/profile.reducer";
import { userProfileImageReducer } from "./user/image/image.reducer";
import { CBAppState } from "./app/app.state";
import { appReducer } from "./app/app.reducer";
import { toastsReducer } from "./toasts/toasts.reducer";
import { ToastsState } from "./toasts/toasts.state";
import { ModalsState } from "./modals/modals.state";
import { modalsReducer } from "./modals/modals.reducer";

import { FeedState } from "./feed/feed.state";
import { feedReducer } from "./feed/feed.reducer";

export const initialState: AppState = {
  token: { hasToken: false } as OauthState,
  userProfile: {
    isLocked: true,
    isCached: false
  } as UserProfileState,
  userProfileImage: { isCached: false } as ImageState,
  appState: {
    loaders: [],
    isLoading: false,
    isReady: false,
    error: undefined,
    infoModalClosed: false,
    tutorialOpened: true,
    hasRecievedFirstSurvey: false,
    lastActivityTimestamp: undefined,
    lastReportTimestamp: undefined,
    chatHubLatestTimestamps: undefined,
    feedbackGiven: false
  } as CBAppState,
  toasts: { toasts: [] } as ToastsState,
  modals: { modals: [] } as ModalsState,
  feed: { activeFilters: [] } as FeedState
};

export const reducers = combineReducers({
  token: oauthReducer,
  userProfile: userProfileReducer,
  userProfileImage: userProfileImageReducer,
  appState: appReducer,
  toasts: toastsReducer,
  modals: modalsReducer,
  feed: feedReducer
});

export type AppState = ReturnType<typeof reducers>;
