import { createGesture, IonPopover } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  addReactionToPost,
  removeReactionFromPost
} from "../util/ClanPostAPIService";
import {
  ClanCommentPostResult,
  ClanPostListResult,
  ClanPostListResultV1,
  ClanPostWithCommentsResult,
  ClanReactions
} from "../util/models/ClanPostModels";
import { REACTION_LIST, ReactionTypes } from "../util/Reactions";
import { FlexRow } from "./Flexbox";

export const StyledIonPopover = styled(IonPopover)`
  --width: 90%;
  --max-width: 480px;

  .popover-content {
    background: #e0e0e0 !important;
    overflow: visible;
    left: 50% !important;
    transform: translateX(-50%);
    border-radius: 100px;
    padding: 10px 15px;
  }
`;

const ReactionItem = styled.div`
  img {
    display: block;
  }

  &::after {
    top: -1rem !important;
    content: none !important;
    transition: all 300ms ease-in-out !important;
  }
`;

const ReactionItemWrapper = styled.div`
  position: relative;

  > div {
    transition: all 300ms ease-in-out;
    cursor: pointer;
  }

  &.highlighted {
    > div {
      &::after {
        content: attr(title) !important;
        opacity: 0 !important;
      }
    }

    &.highlighted-active {
      > div {
        transform: translateY(-16rem) scale(1.5);

        &::after {
          opacity: 1 !important;
        }
      }
    }
  }
`;

interface ReactionPopoverProps {
  post:
    | ClanPostListResult
    | ClanPostListResultV1
    | ClanCommentPostResult
    | ClanPostWithCommentsResult;

  myReactions: Array<ReactionTypes> | undefined;
  reactions: ClanReactions;
  setReactions: (reactions: ClanReactions) => void;
  onClose: () => void;
}

const ReactionPopover: React.FC<ReactionPopoverProps> = ({
  post,
  myReactions,
  reactions,
  setReactions,
  onClose
}) => {
  const { t } = useTranslation();

  const buildReactionGesture = (ref: HTMLDivElement) => {
    let timerId: any = null;
    ref &&
      createGesture({
        el: ref as Node,
        gestureName: "longpress",
        gesturePriority: 100,
        threshold: 0,
        passive: true,
        onStart: () => {
          timerId = setTimeout(() => {
            ref.classList.add("highlighted");
            ref.classList.add("highlighted-active");
          }, 200);
        },
        onEnd: () => {
          ref.classList.remove("highlighted-active");
          ref.classList.remove("highlighted");
          clearTimeout(timerId);
        }
      }).enable();
  };

  const alreadyReacted = (reaction: ReactionTypes): boolean =>
    reactions[reaction]?.likedByMe === true;

  const addOrRemoveReaction = (newReactionKey: ReactionTypes) => () => {
    if (alreadyReacted(newReactionKey)) {
      removeReactionFromPost(post.id, newReactionKey)
        .then((e) => e.reactions)
        .then(setReactions)
        .catch(console.error);
    } else {
      addReactionToPost(post.id, newReactionKey)
        .then((e) => e.reactions)
        .then(setReactions)
        .catch(console.error);
    }
  };

  return (
    <StyledIonPopover
      mode="ios"
      backdropDismiss={true}
      translucent
      isOpen={true}
      onDidDismiss={onClose}
    >
      <FlexRow align="center" columnGap="5px">
        {REACTION_LIST.map((reaction, index) => (
          <ReactionItemWrapper key={index} ref={buildReactionGesture}>
            <ReactionItem
              onClick={addOrRemoveReaction(reaction.key)}
              title={t(
                `reactions.${reaction.label
                  .toLocaleLowerCase()
                  .replace("'", "")
                  .replace(/\s/g, "")}`
              )}
              className="after:attr-content"
            >
              <img
                src={
                  (myReactions || []).includes(reaction.key)
                    ? reaction.imageActive
                    : reaction.image
                }
                alt={reaction.label}
              />
            </ReactionItem>
          </ReactionItemWrapper>
        ))}
      </FlexRow>
    </StyledIonPopover>
  );
};

export default ReactionPopover;
