import { IonButton, IonButtons, IonIcon, IonToolbar } from "@ionic/react";
import React from "react";
import { connect } from "../data/connect";
import { OauthState } from "../data/oauth/oauth.state";
import { OauthData } from "../data/oauth/oauthDataService";
import * as ROUTES from "../routes";

interface OwnProps {
  className?: string;
}

interface StateProps {
  oauthState: OauthState | OauthData;
}

interface ClanSecondaryToolbarProps extends StateProps, OwnProps {}

const ClanSecondaryToolbar: React.FC<ClanSecondaryToolbarProps> = ({
  className = "",
  oauthState
}) => {
  return (
    <IonToolbar className={className}>
      <IonButtons slot="end">
        <IonButton
          routerDirection="none"
          routerLink={ROUTES.SETTINGS}
          className="h-full"
        >
          <IonIcon src="/assets/navigation/settings.svg" className="text-5xl" />
        </IonButton>
      </IonButtons>
      {oauthState.hasToken && (
        <IonButtons slot="start">
          <IonButton
            routerDirection="none"
            routerLink={ROUTES.ACTIVITY}
            className="h-full"
          >
            <IonIcon
              src="/assets/navigation/notifications.svg"
              className="text-5xl"
            />
          </IonButton>
        </IonButtons>
      )}
    </IonToolbar>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    oauthState: state.token
  }),
  component: ClanSecondaryToolbar
});
