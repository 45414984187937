import { IonCard, IonCardContent } from "@ionic/react";
import styled from "styled-components";
import { desktopOnlyStyles, mobileOnlyStyles } from "../util/ResponsiveUtils";

export const ClanCard = styled(IonCard)`
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 2rem;
  margin: 0;
  ${desktopOnlyStyles({
    margin: "1rem 4rem"
  })}
  ${mobileOnlyStyles({
    width: "100%"
  })}
`;

export const ClanCardContent = styled(IonCardContent)`
  height: 100%;
  padding: 30px 0;
  overflow-y: auto;
`;

export const ClanCardHeader = styled.div`
  font-family: Gilroy;
  color: black;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: start;
  margin: 0 20px;
`;
