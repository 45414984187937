import { IonImg, IonModal } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";

import { analyticsLogEvent } from "../../Analytics";
import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";
import { PUBLIC_PROFILE } from "../../routes";
import { ChartResultFlagWithProfiles } from "../../util/Analytics";
import AdvancedCard from "../AdvancedCard";
import ClanHeaderRays from "../ClanHeaderRays";
import ClanLlamaMessage from "../ClanLlamaMessage";
import Header from "../Headers/Header";
import ListItem from "../ListItem/ListItem";
import { Section } from "../Section";
import { H5 } from "../Typography";

const StyledH5 = styled(H5)<{ color?: string }>`
  b {
    color: ${({ color }) => (color === "RED" ? "#FF6471" : "#45DD85")};
  }
`;

export const FlagHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 50px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
`;

const Fab = styled.div`
  background: white;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  text-align: center;
  line-height: 3rem;
  font-size: 1.4rem;
`;

export const FlagIndicator = styled.div`
  background: white;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.3rem;
  }
`;

export const Title = styled.div`
  font-family: Gilroy;
  font-size: 1rem;
  line-height: 1.29;
  text-align: center;
  color: #000000;
`;

export const EmptyTitle = styled(Title)`
  font-size: 1.2rem;
`;

export const EmptyParagraph = styled.p`
  text-align: center;
  color: #878396;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.29;
  letter-spacing: 0.203493px;
  margin: 0 1.8rem;
`;

export const EmptyViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const EmptyIcon = styled(IonImg)`
  width: 4.4rem;
  margin: 3rem;
`;

export const HeaderRays = styled(ClanHeaderRays)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const NavigationIcon = styled(Fab)`
  visibility: ${({ visible }: { visible: boolean }) =>
    visible ? "visible" : "hidden"};

  ion-icon {
    color: #45dd85;
    margin: 0.8rem;
  }
`;

interface FlagModalProps {
  flag: ChartResultFlagWithProfiles;
  close: () => void;
  openPrevious: () => void;
  openNext: () => void;
}

const FlagModal: React.FC<FlagModalProps> = ({
  flag,
  close,
  openPrevious,
  openNext
}) => {
  let isRedirecting = false;

  const history = useHistory();
  const { t } = useTranslation();

  const onOpenProfile = (profileId: number) => () => {
    isRedirecting = true;
    analyticsLogEvent("STATS_FLAG_PROFILE_CLICK");
    history.push(PUBLIC_PROFILE.replace(":profileId", profileId.toString()));
  };

  const onDidDismiss = () => {
    if (!isRedirecting) close();
  };
  return (
    <IonModal isOpen onDidDismiss={onDidDismiss}>
      <Header title={t("stats.flags")} onBack={close} />

      <ClanIonContent>
        <AdvancedCard
          primaryText={
            <StyledH5
              color={flag.type}
              dangerouslySetInnerHTML={{
                __html: flag.message
              }}
            />
          }
          onPrevClick={openPrevious}
          onNextClick={openNext}
        />

        <Section>
          {flag.profiles.length !== 0 ? (
            flag.profiles.map((profile) => (
              <ListItem
                key={profile.id}
                avatar={
                  profile.images.thumbnail?.url ||
                  "https://www.gravatar.com/avatar?d=mm&s=140"
                }
                primary={profile.name}
                onClick={onOpenProfile(profile.id)}
              />
            ))
          ) : (
            <ClanLlamaMessage
              messages={[
                t("stats.no_access"),
                t("stats.only_group_leads_access")
              ]}
            />
          )}
        </Section>
      </ClanIonContent>
    </IonModal>
  );
};

export default FlagModal;
