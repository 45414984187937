import {
  IonInput,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption
} from "@ionic/react";

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  LocationContext,
  MethodTypes,
  StartContentWrapper,
  StartDescription,
  StartTitle,
  StartTitleWrapper
} from "./Start";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { doOnboardClanBeatTeacher } from "../../util/api/Authentication";
import {
  getCountries,
  parsePhoneNumberFromString
} from "libphonenumber-js/max";
import FooterButtons, { FooterButton } from "../../components/FooterButtons";
import { showToast } from "../../data/toasts/toasts.actions";
import { ClanOauthResponse } from "../../util/models/ClanOauthResponse";
import { connect } from "../../data/connect";
import { CountryInformation, makeCountryList } from "./StartHelper";
import { useAsync } from "@react-hook/async";
import { isReadyStateLoading } from "../../util/AsyncEffectFilter";
import { getFirebaseIdToken } from "../../util/GoogleFirebase";
import { resolveFirstConstraintViolationErrorProperty } from "../../util/ApiService";
import RollbarManager from "../../util/RollbarManager";

const LabelListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  overflow-y: auto;
  margin-right: auto;
`;

const StyledInput = styled(IonInput)`
  --padding-start: 0;
  margin-top: 40px;
`;

const StyledSelect = styled(IonSelect)`
  font-family: Gilroy;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;

  padding: 0;
  margin-top: 10px;
`;

interface OwnProps {
  loading: boolean;
  mode: MethodTypes;
  onDone: (oauthResponse: ClanOauthResponse) => void;
  onDataDuplication: () => void;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface SchoolNameInputProps extends OwnProps, DispatchProps {}

const SchoolNameInput: React.FC<SchoolNameInputProps> = ({
  loading,
  mode,
  dispatchToast,
  onDataDuplication,
  onDone
}) => {
  const {
    getValues,
    trigger,
    errors,
    watch,
    control,
    setValue
  } = useFormContext();

  const watchSchoolName = watch("schoolName");
  const watchSchoolCountry = watch("schoolCountry");
  const schoolNameRef = useRef<HTMLIonInputElement | null>(null);
  //const [labels, setLabels] = useState<string[]>();
  const [labels] = useState<string[]>();
  const [countryList, setCountryList] = useState<CountryInformation[]>([]);

  // TODO: see the search comment below
  //const [searchTimeout, setSearchTimeout] = useState<number | undefined>(undefined);

  const { t } = useTranslation();
  const currentCountryInfo = useContext(LocationContext);

  useEffect(() => {
    setCountryList(makeCountryList(getCountries()));
  }, []);

  useEffect(() => {
    setValue("schoolCountry", currentCountryInfo.countryCode);
  }, [currentCountryInfo, setValue]);

  const [submitStatus, onSubmit] = useAsync(async () => {
    if (await trigger(["schoolName", "schoolCountry"])) {
      const firebaseToken = await getFirebaseIdToken();
      if (!firebaseToken) {
        dispatchToast(t("start.privacy.accept_error"));
        return;
      }
      const fullNumberString = `${
        getValues("countryCode")?.split(":")[0]
      }${getValues("number")}`;
      try {
        const onboardResponse = await doOnboardClanBeatTeacher(firebaseToken, {
          email: firebaseToken.email || getValues("email"),
          ...(fullNumberString &&
            parsePhoneNumberFromString(fullNumberString)?.isPossible() && {
              phoneNumber: fullNumberString
            }),
          organisationName: getValues().schoolName,
          country: getValues().schoolCountry
        });
        onDone(onboardResponse.response);
      } catch (e) {
        const constraintError = resolveFirstConstraintViolationErrorProperty(e);
        if (constraintError === "onboard_data_duplication") {
          dispatchToast(t("start.school.error_email_phone"));
          onDataDuplication();
          return;
        }
        dispatchToast(t("start.methods.error_school"));
        RollbarManager.logError(e as Error);
      }
    } else {
      dispatchToast(
        `Error - ${Object.keys(errors).reduce((allErrors, key) => {
          return allErrors.concat(errors[key].message);
        }, "")}`
      );
    }
  });

  return (
    <>
      <StartContentWrapper>
        <StartTitleWrapper>
          <StartTitle>
            <LabelListWrapper>
              <Controller
                control={control}
                render={({ onChange }) => (
                  <StyledInput
                    onIonChange={(e) => onChange(e.detail.value)}
                    className="text-clanH2 text-black font-extrabold font-gilroy"
                    placeholder={t("start.school.school_placeholder")}
                    inputMode="search"
                    value={getValues("schoolName")}
                    ref={schoolNameRef}
                    required
                  />
                )}
                name="schoolName"
                defaultValue={null}
                rules={{
                  required: {
                    value: mode === MethodTypes.TEACHER,
                    message: t("start.school.validation_required")
                  },
                  minLength: {
                    value: 3,
                    message: t("start.school.validation_length")
                  },
                  maxLength: {
                    value: 150,
                    message: t("start.school.validation_length")
                  }
                }}
              />
              {labels && labels?.length !== 0 && (
                <IonList className="w-full max-h-50vh overflow-auto z-100 absolute flex flex-col mx-2 top-100% left-0 shadow-md rounded-md">
                  {labels.map((label, index) => (
                    <IonLabel
                      key={index}
                      className="p-2 cursor-pointer"
                      onClick={() => {
                        setValue("schoolName", label);
                      }}
                    >
                      {label}
                    </IonLabel>
                  ))}
                </IonList>
              )}
            </LabelListWrapper>
          </StartTitle>
          <StartDescription>
            {t("start.school.school_description")}
          </StartDescription>
          <Controller
            control={control}
            render={({ onChange }) => (
              <StyledSelect
                onIonChange={(e) => onChange(e.detail.value)}
                placeholder={t("start.school.country_placeholder")}
                interface="action-sheet"
                value={getValues("schoolCountry")}
              >
                {countryList.map((country, i) => (
                  <IonSelectOption key={i} value={country.countryCode}>
                    {country.flag} {country.country}
                  </IonSelectOption>
                ))}
              </StyledSelect>
            )}
            name="schoolCountry"
            defaultValue={null}
            rules={{
              required: {
                value: mode === MethodTypes.TEACHER,
                message: t("start.school.validation_country_required")
              }
            }}
          />
        </StartTitleWrapper>
      </StartContentWrapper>
      <FooterButtons>
        <FooterButton
          disabled={
            loading ||
            !watchSchoolName ||
            !watchSchoolCountry ||
            isReadyStateLoading(
              submitStatus /*,
              schoolNameStatus,
              schoolNameSearchStatus*/
            )
          }
          onClick={onSubmit}
        >
          {t("general.next")}
        </FooterButton>
      </FooterButtons>
    </>
  );
};

export default connect<OwnProps, {}>({
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: SchoolNameInput
});
