import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export function getSharingText(
  sharingLabel: string | undefined,
  profilesAmount: number | undefined,
  groupsAmount: number | undefined,
  t: TFunction
) {
  if (sharingLabel) {
    return t("clan_sharing_info.visible_to", {
      target: t(`sharing_shortcut.${sharingLabel.toLowerCase()}`).toLowerCase()
    });
  } else if (profilesAmount && profilesAmount > 0) {
    return t("clan_sharing_info.visible_profiles", {
      count: profilesAmount
    });
  } else if (groupsAmount && groupsAmount > 0) {
    return t("clan_sharing_info.visible_groups", { count: groupsAmount });
  }

  return undefined;
}

interface ClanSharingInfoInlineProps {
  groupsAmount?: number;
  profilesAmount?: number;
  sharingLabel?: string;
  onInfoClicked: () => void;
  [any: string]: any;
}

interface SharingLabelProps {
  [any: string]: any;
}

const StyledSharingLabel = styled.span<SharingLabelProps>`
  cursor: pointer;
`;

const ClanSharingInfoInline: React.FC<ClanSharingInfoInlineProps> = ({
  groupsAmount,
  profilesAmount,
  sharingLabel,
  onInfoClicked,
  ...props
}) => {
  const { t } = useTranslation();
  const sharingText = getSharingText(
    sharingLabel,
    profilesAmount,
    groupsAmount,
    t
  );

  const SharingLabel: React.FC<SharingLabelProps> = ({ ...labelProps }) => {
    return (
      <StyledSharingLabel
        id="shared-with"
        onClick={onInfoClicked}
        {...labelProps}
      ></StyledSharingLabel>
    );
  };

  if (sharingLabel) {
    return (
      <>
        <span>{` | `}</span>
        <SharingLabel {...props}> {sharingText}</SharingLabel>
      </>
    );
  } else if (profilesAmount && profilesAmount > 0) {
    return (
      <>
        <span>{` | `}</span>
        <SharingLabel {...props}>{sharingText}</SharingLabel>
      </>
    );
  } else if (groupsAmount && groupsAmount > 0) {
    return (
      <>
        <span>{` | `}</span>
        <SharingLabel {...props}>{sharingText}</SharingLabel>
      </>
    );
  }

  return null;
};

export default ClanSharingInfoInline;
