import React, { useState } from "react";
import {
  StartContentWrapper,
  StartDescription,
  StartTitle,
  StartTitleWrapper
} from "./Start";

import { useTranslation } from "react-i18next";
import { Privacy } from "./StudentJoinCode";
import FooterButtons, { FooterButton } from "../../components/FooterButtons";
import { showToast } from "../../data/toasts/toasts.actions";
import { connect } from "../../data/connect";

interface OwnProps {
  loading: boolean;
  onDone: () => void;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface PrivacyTermsProps extends OwnProps, DispatchProps {}

const PrivacyTermsInput: React.FC<PrivacyTermsProps> = ({
  loading,
  onDone,
  dispatchToast
}) => {
  const { t } = useTranslation();

  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  const onSubmit = () => {
    if (termsChecked && privacyChecked) {
      onDone();
    } else {
      dispatchToast(t("start.privacy.accept_error"));
    }
  };

  return (
    <>
      <StartContentWrapper>
        <StartTitleWrapper>
          <StartTitle>{t("start.privacy.title")}</StartTitle>
          <StartDescription>{t("start.school.confirm")}</StartDescription>
          <Privacy
            privacyChecked={privacyChecked}
            termsChecked={termsChecked}
            setPrivacyChecked={setPrivacyChecked}
            setTermsChecked={setTermsChecked}
          />
        </StartTitleWrapper>
      </StartContentWrapper>
      <FooterButtons>
        <FooterButton disabled={loading} onClick={onSubmit}>
          {t("general.next")}
        </FooterButton>
      </FooterButtons>
    </>
  );
};

export default connect<OwnProps, {}>({
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: PrivacyTermsInput
});
