import { IonHeader, IonIcon } from "@ionic/react";
import React, { ReactNode } from "react";
import styled from "styled-components";

import Avatar from "../Avatar";
import { H5 } from "../Typography";

const StyledHeader = styled(IonHeader)`
  background: #fafafa;
  border-bottom: 3px solid ${({ theme }) => theme.primary};
  padding-top: env(safe-area-inset-top);
`;

const HeaderContent = styled.div`
  padding: 28px 16px;
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const BackButton = styled(IonIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

interface HeaderProps {
  title: string | ReactNode;
  avatar?: string;
  actions?: any;
  onBack?: () => void;
}

const Header: React.FC<HeaderProps> = ({
  title,
  avatar,
  actions,
  onBack,
  ...rest
}) => {
  return (
    <StyledHeader mode="ios">
      <HeaderContent {...rest}>
        {onBack && (
          <BackButton src="/assets/arrow_back_black.svg" onClick={onBack} />
        )}
        {avatar && <Avatar url={avatar} />}
        <TitleWrapper>
          <H5>{title}</H5>
        </TitleWrapper>
        {actions}
      </HeaderContent>
    </StyledHeader>
  );
};

export default Header;
