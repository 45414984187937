import {
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import ClanDiscussionPostRemoveModal from "../../components/ClanDiscussionPost/ClanDiscussionPostRemoveModal";
import ClanDiscussionPostReportModal from "../../components/ClanDiscussionPost/ClanDiscussionPostReportModal";
import ClanPostSharingModal from "../../components/ClanDiscussionPost/ClanPostSharingModal";
import ClanTaskPost from "../../components/ClanDiscussionPost/ClanTaskPost";
import PostListItem from "../../components/ClanDiscussionPost/PostListItem";
import ClanDiscussionSkeleton from "../../components/ClanDiscussionSkeleton";
import TabFilter from "../../components/TabFilter";
import LlamaBox from "../../components/LlamaBox";
import SharePostModal from "../../components/Modals/SharePostModal";
import { Paragraph } from "../../components/Section";
import { setAppError } from "../../data/app/app.actions";
import { connect } from "../../data/connect";
import * as ROUTES from "../../routes";
import { ApiRequestError } from "../../util/ApiService";
import { useFeedsFilterPaginator } from "../../util/ClanPostAPIEndpoints";
import { Views } from "../../util/Configuration";
import { FeedTypes, findViewFilterByFeedType } from "../../util/Feeds";
import { FilterGroupType } from "../../util/Filters";
import {
  ClanEditPostEditDetails,
  ClanPostListResult,
  ClanPostResultDetails
} from "../../util/models/ClanPostModels";
import { SharedUserTaskResult } from "../../util/models/PlannerUserTaskModels";
import { scrollToItem } from "../../util/ScrollUtils";
import { addPostImageToFeed } from "./DiscussionHelper";
import { OauthData } from "../../data/oauth/oauthDataService";

const StyledEmptyContent = styled(LlamaBox)`
  margin-top: 20px;
`;

const FeedTabFilter = styled(TabFilter)`
  padding-bottom: 0;
`;

export enum ProfileFeedFilter {
  ALL = "ALL",
  PERSONAL = "PRIVATE REFLECTIONS",
  PRIVATELY_SHARED = "SHARED REFLECTIONS"
}

interface FeedProps {
  id?: string;
  reflectionTaskId?: string;
  feedType: FeedTypes;
  showNewPost: boolean;
  setShowNewPost: (showNewPost: boolean) => void;
  filterEnabled?: boolean;
  highlightPostId?: string;
  filters: FilterGroupType[];
}

interface StateProps {
  token: string;
}

interface DispatchProps {
  setAppErrorConnect: typeof setAppError;
}

const Feed: React.FC<FeedProps & DispatchProps & StateProps> = ({
  token,
  reflectionTaskId,
  feedType = FeedTypes.DISCUSSION,
  filterEnabled,
  showNewPost,
  setShowNewPost,
  setAppErrorConnect,
  highlightPostId,
  filters
}) => {
  const history = useHistory();
  const feedIterator = useFeedsFilterPaginator(feedType, filters);
  const [commentsRoute, setCommentsRoute] = useState<string>();

  useEffect(() => {
    switch (feedType) {
      case FeedTypes.ANNOUNCEMENT_FEED: {
        setCommentsRoute(ROUTES.ANNOUNCEMENT_COMMENTS);
        break;
      }
      case FeedTypes.PERSONAL: {
        setCommentsRoute(ROUTES.PROFILE_FEED_COMMENTS);
        break;
      }
      case FeedTypes.DISCUSSION: {
        setCommentsRoute(ROUTES.DISCUSSION_COMMENTS);
        break;
      }
      case FeedTypes.QUESTION: {
        setCommentsRoute(ROUTES.QUESTION_COMMENTS);
        break;
      }
      case FeedTypes.TASK: {
        setCommentsRoute(ROUTES.TASK_COMMENTS);
        break;
      }
      case FeedTypes.SHARED_REFLECTIONS: {
        setCommentsRoute(ROUTES.SHARED_FEED_COMMENTS);
        break;
      }
      case FeedTypes.PUBLIC:
        break;
    }
  }, [feedType]);

  useEffect(() => {
    feedIterator && loadFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedIterator, token]);

  const [activeFilter, setActiveFilter] = useState<ProfileFeedFilter>(
    ProfileFeedFilter.ALL
  );

  const [postIdToHightlight, setPostIdToHighlight] = useState<
    string | undefined
  >(highlightPostId);

  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [reportPostId, setReportPostId] = useState<string>("");
  const [deletePostId, setDeletePostId] = useState<string>("");
  const [editPostId, setEditPostId] = useState<string>();
  const [sharePost, setSharePost] = useState<
    ClanPostListResult | SharedUserTaskResult | null
  >(null);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const [discussionResult, setDiscussionResult] = useState<
    Array<ClanPostListResult>
  >([]);
  const [feedTasks, setFeedTasks] = useState<Array<SharedUserTaskResult>>([]);

  const [
    discussionResultWithLocalImages,
    setDiscussionResultWithLocalImages
  ] = useState<Array<ClanPostListResult>>([]);
  const [postsWithLocalImages, setPostsWithLocalImages] = useState<
    ClanEditPostEditDetails[]
  >([]);

  const { t } = useTranslation();

  const loadFeed = async (newOrEditedPost?: ClanEditPostEditDetails) => {
    setEditPostId(undefined);
    setReportPostId("");
    setDeletePostId("");
    setShowSkeleton(true);
    setDiscussionResult([]);

    try {
      if (!feedIterator) {
        console.error("No iterator defined!");
        return;
      }
      await feedIterator.reset();
      const response = await feedIterator.getNext();
      if (feedType === FeedTypes.TASK) {
        setFeedTasks(response as SharedUserTaskResult[]);
      } else {
        setDiscussionResult(response as ClanPostListResult[]);
      }
      feedIterator.hasNext().then(setHasMore);
    } catch (e) {
      setAppErrorConnect(e as ApiRequestError);
      console.error("[FEED] Couldn't load discussion feed", e);
    } finally {
      setShowSkeleton(false);
    }

    setAnalyticsScreenName(`FEED_${feedType}`);
  };

  useEffect(() => {
    setDiscussionResultWithLocalImages(
      addPostImageToFeed(discussionResult, postsWithLocalImages)
    );
  }, [postsWithLocalImages, discussionResult]);

  useEffect(() => {
    if (!showNewPost && !!editPostId) {
      setShowNewPost(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPostId]);

  const doRefresh = (event: CustomEvent) => {
    analyticsLogEvent(`FEED_${feedType}_refresh`);
    loadFeed().then(event.detail.complete);
  };

  function onFeedTypeFilterChange(newFilter: string) {
    setActiveFilter(newFilter as ProfileFeedFilter);
  }

  function buildNoPostsMessage() {
    switch (feedType) {
      case FeedTypes.ANNOUNCEMENT_FEED:
        return t("feed.no_announcement");
      case FeedTypes.QUESTION:
        return t("feed.start_question");
      case FeedTypes.DISCUSSION:
        return t("feed.start_discussion");
      case FeedTypes.PERSONAL:
        return activeFilter === ProfileFeedFilter.PERSONAL
          ? t("feed.start_personal")
          : t("feed.start_personal_desc");
      case FeedTypes.TASK:
        return t("feed.start_task");
      case FeedTypes.SHARED_REFLECTIONS:
        return t("feed.start_shared");
    }
  }

  function filterPosts(
    posts: Array<ClanPostListResult>
  ): Array<ClanPostListResult | SharedUserTaskResult> {
    if (filterEnabled && feedType === FeedTypes.PERSONAL) {
      switch (activeFilter) {
        case ProfileFeedFilter.ALL:
          return posts;
        case ProfileFeedFilter.PERSONAL: {
          return posts.filter((post) => {
            if (!post) return 0;
            return (
              post.sharing.profilesAmount + post.sharing.groupsAmount === 0
            );
          });
        }
        case ProfileFeedFilter.PRIVATELY_SHARED: {
          return posts.filter(
            (post) =>
              post?.sharing.profilesAmount + post?.sharing.groupsAmount > 0
          );
        }
        default: {
          return posts;
        }
      }
    }

    return posts;
  }

  function closeShareModal() {
    setSharePost(null);
  }

  function onSharePostDone(sharedPost?: ClanPostResultDetails) {
    closeShareModal();
    loadFeed().then(() => {
      if (sharedPost) {
        if (feedType === FeedTypes.PERSONAL) {
          setActiveFilter(
            sharedPost.sharedGroups.length > 0 ||
              sharedPost.sharedProfiles.length > 0
              ? ProfileFeedFilter.PRIVATELY_SHARED
              : ProfileFeedFilter.PERSONAL
          );
        }

        setPostIdToHighlight(sharedPost.id);
      }
    });
  }

  const filteredPosts = filterPosts(discussionResultWithLocalImages);

  const loadNextPage = async () => {
    if (!feedIterator) {
      console.error("No iterator defined!");
      return;
    }
    const hasNext = await feedIterator.hasNext();
    setHasMore(hasNext);
    if (!hasNext) return;
    const result = await feedIterator.getNext();
    setDiscussionResult([
      ...discussionResult,
      ...(result as ClanPostListResult[])
    ]);
    feedIterator.hasNext().then(setHasMore);
  };

  const onPost = (postResult: ClanEditPostEditDetails) => {
    setEditPostId(undefined);
    setShowNewPost(false);

    setTimeout(() => {
      loadFeed().then(() => {
        postResult &&
          setPostsWithLocalImages((oldList) => [...oldList, postResult]);
        if (postResult.id) {
          if (
            feedType === FeedTypes.PERSONAL &&
            (postResult.sharedGroups?.length === 0 ||
              postResult.sharedProfiles?.length === 0)
          ) {
            setActiveFilter(ProfileFeedFilter.PERSONAL);
          }
          setPostIdToHighlight(postResult.id);
        }
      });
    }, 500);
  };

  useEffect(() => {
    if (postIdToHightlight) scrollToItem(postIdToHightlight, feedType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postIdToHightlight]);

  const closeNewPostModal = () => {
    setEditPostId(undefined);
    setShowNewPost(false);
  };

  const openPostComments = (postId: string) => () => {
    commentsRoute && history.push(commentsRoute.replace(":post_id", postId));
  };

  const onDeletePost = (postId: string) => () => setDeletePostId(postId);
  const onEditPost = (postId: string) => () => setEditPostId(postId);
  const onSharePost = (post: ClanPostListResult | SharedUserTaskResult) => () =>
    setSharePost(post);
  const onReportPost = (postId: string) => () => {
    if (feedType !== FeedTypes.PERSONAL && postId) {
      setReportPostId(postId);
    }
  };

  const showEmptyFeedMessage =
    feedIterator?.initialDataLoaded &&
    (feedType === FeedTypes.TASK
      ? feedTasks.length === 0
      : filteredPosts.length === 0);

  return (
    <>
      {filterEnabled && (
        <FeedTabFilter
          id="feed-filter"
          active={activeFilter}
          options={ProfileFeedFilter}
          onChange={onFeedTypeFilterChange}
        />
      )}

      <IonRefresher
        slot="fixed"
        onIonRefresh={doRefresh}
        pullFactor={0.5}
        pullMin={100}
        pullMax={200}
      >
        <IonRefresherContent />
      </IonRefresher>
      <ClanDiscussionPostRemoveModal
        title={t("general.delete_post")}
        postId={deletePostId}
        removeQuestion={t("general.delete_post_confirm")}
        onDone={(postIdToBeRemoved: string) => {
          setDiscussionResult(
            discussionResult.filter((post) => post.id !== postIdToBeRemoved)
          );
          setDeletePostId("");
        }}
        onCancel={() => setDeletePostId("")}
        isOpen={!!deletePostId}
      />
      <ClanDiscussionPostReportModal
        title={t("general.report_to_teacher")}
        postId={reportPostId}
        onDone={loadFeed}
        onCancel={() => setReportPostId("")}
        isOpen={!!reportPostId}
      />

      {showNewPost && (
        <SharePostModal
          reflectionTaskId={reflectionTaskId}
          postId={editPostId}
          feedType={feedType}
          onClose={closeNewPostModal}
          onPost={onPost}
        />
      )}

      {sharePost && (
        <ClanPostSharingModal
          view={findViewFilterByFeedType(feedType) || Views.DiscussionFeed}
          post={sharePost}
          onClose={closeShareModal}
          onDone={onSharePostDone}
        />
      )}

      {showSkeleton && <ClanDiscussionSkeleton numOfSkeletonPosts={10} />}

      {showEmptyFeedMessage && (
        <StyledEmptyContent>
          <Paragraph>{buildNoPostsMessage()}</Paragraph>
        </StyledEmptyContent>
      )}

      {!showSkeleton && !showEmptyFeedMessage && (
        <IonList id="profile-feed" className="bg-gray-100 pt-0">
          {feedType !== FeedTypes.TASK
            ? filteredPosts.map((post) => (
                <PostListItem
                  key={post.id}
                  post={post as ClanPostListResult}
                  showReactions={feedType !== FeedTypes.PERSONAL}
                  onOpenComments={openPostComments(post.id)}
                  onDelete={onDeletePost(post.id)}
                  onEdit={onEditPost(post.id)}
                  onReport={onReportPost(post.id)}
                  onShare={
                    feedType === FeedTypes.PERSONAL
                      ? onSharePost(post)
                      : undefined
                  }
                />
              ))
            : feedTasks.map((task) => (
                <ClanTaskPost
                  task={task}
                  key={task.id}
                  commentsPage={commentsRoute}
                />
              ))}
          <IonSegment>
            <IonSegmentButton
              className="font-extrabold font-gilroy text-clanGreen-100"
              disabled={!hasMore}
              onClick={loadNextPage}
            >
              {hasMore ? t("general.list_load_more") : t("general.list_end")}
            </IonSegmentButton>
          </IonSegment>
        </IonList>
      )}
    </>
  );
};

export default connect<FeedProps, StateProps, DispatchProps>({
  mapStateToProps: ({ token }) => ({ token: (token as OauthData).token }),
  mapDispatchToProps: {
    setAppErrorConnect: setAppError
  },
  component: Feed
});
