import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import * as ROUTES from "../../routes";
import { formatMessageDate } from "../../util/DateUtils";
import { ClanProfileListItemResponseV3 } from "../../util/models/ClanProfileModels";
import ClanProfileAvatar from "../ClanProfileAvatar";
import { FlexRow } from "../Flexbox";
import MoodIndicator from "../MoodIndicator";
import { H4, H4Style, H6, H6Style, PostFont } from "../Typography";

const StyledMessageListItem = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  margin: 20px 0;
`;

const ProfileName = styled(H4)`
  margin: 0;
`;

const MessageMetaWrapper = styled.div`
  max-width: calc(100% - 50px);
  cursor: pointer;
`;

const Message = styled.div<{ unread?: boolean }>`
  ${({ unread }) => (unread ? H4Style : H6Style)}
  margin: 0;
  max-width: 100%;
  overflow: hidden;

  p {
    ${PostFont}
    margin: 0;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MessageDate = styled(H6)`
  color: #878396;
  white-space: pre;
`;

interface MessageListItemProps {
  unread?: boolean;
  createdAt: string;
  content: string;
  mood?: string;
  profile: ClanProfileListItemResponseV3;
}

const MessageListItem: React.FC<MessageListItemProps> = ({
  unread,
  createdAt,
  profile,
  mood,
  content
}) => {
  const { id, fullName, images } = profile;
  const history = useHistory();

  function openChat() {
    history.push(ROUTES.DIRECT_MESSAGE.replace(":profile_id", id.toString()));
  }

  return (
    <StyledMessageListItem onClick={openChat}>
      <FlexRow gap="8px" align="center">
        <ClanProfileAvatar
          src={images?.thumbnail?.url || `/assets/navigation/profile.svg`}
        />

        <MessageMetaWrapper>
          <ProfileName>{fullName}</ProfileName>

          <FlexRow columnGap="8px" align="center">
            {mood && !content ? (
              <MoodIndicator
                bold={unread}
                format="messages_hub.feeling"
                mood={mood}
              />
            ) : (
              <Message
                dangerouslySetInnerHTML={{ __html: content }}
                unread={unread}
              />
            )}

            <MessageDate>{formatMessageDate(new Date(createdAt))}</MessageDate>
          </FlexRow>
        </MessageMetaWrapper>
      </FlexRow>
    </StyledMessageListItem>
  );
};

export default MessageListItem;
