export enum UserPropertiesPersistedDataType {
  INFO_MODAL_SEEN = "infoModalSeen",
  TUTORIAL_OPENED = "tutorialOpened",
  FEEDBACK_GIVEN = "feedbackGiven",
  RECIEVED_FIRST_SURVEY = "recievedFirstSurvey",
  LATEST_ACTIVITY_TIMESTAMP = "latestActivityTimestamp",
  LATEST_CHATHUB_TIMESTAMPS = "latestChathubTimestamps",
  LATEST_REPORT_TIMESTAMP = "lastReportTimestamp",
  DISCOVER_COMMUNITY_SEEN = "discoverCommunityShown"
}

export type UserPropertiesValueType = string | boolean | number | null;
export type UserPropertiesDataMap = {
  [key in UserPropertiesPersistedDataType]?: UserPropertiesValueType;
};
