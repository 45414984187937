import { hasValue } from "../../NullSafe";

const PERCENTAGE_NO_DIGITS_FORMATTER = Intl.NumberFormat(undefined, {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const PERCENTAGE_DIGITS_FORMATTER = Intl.NumberFormat(undefined, {
  style: "percent",
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
});

export const formatPercentage = (value?: number): string => {
  if (hasValue(value)) {
    if (value >= 100) {
      return PERCENTAGE_NO_DIGITS_FORMATTER.format(1);
    } else if (value === 0) {
      return PERCENTAGE_NO_DIGITS_FORMATTER.format(0);
    }
    return PERCENTAGE_DIGITS_FORMATTER.format(value / 100);
  }
  return PERCENTAGE_NO_DIGITS_FORMATTER.format(0);
};
