import {
  IonContent,
  IonNote,
  IonPage,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import ClanHeaderRays from "../../components/ClanHeaderRays";
import FooterButtons, {
  PrimaryFooterButton
} from "../../components/FooterButtons";
import NestPicker from "../../components/NestPicker";
import ActiveSchoolFilter from "../../components/SchoolView/ActiveSchoolFilter";
import SchoolFilter from "../../components/SchoolView/SchoolFilter";
import { connect } from "../../data/connect";
import { setFeedFilters } from "../../data/feed/feed.actions";
import { removeModal, showLlamaModal } from "../../data/modals/modals.actions";
import { ModalCallbackType, ModalID } from "../../data/modals/modals.state";
import * as ROUTES from "../../routes";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { Views } from "../../util/Configuration";
import { FeedTypes } from "../../util/Feeds";
import { FilterGroupType, useFilterResult } from "../../util/Filters";
import { desktopOnlyStyles } from "../../util/ResponsiveUtils";
import Feed from "./Feed";

interface FooterButton {
  title: string;
  disabled?: boolean | undefined;
  callback?: () => void;
}

export const ClanIonContent = styled(IonContent)`
  ${desktopOnlyStyles({
    "--offset-bottom": "auto !important",
    "--overflow": "hidden",
    overflow: "auto"
  })}
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.7rem;
  margin: 8px 16px 0;
  min-height: 32px;
`;

const HeaderTitle = styled.div`
  flex: 1;
  font-family: Gilroy;
  font-size: 1.3rem;
`;

interface DispatchProps {
  dispatchLlamaModal: typeof showLlamaModal;
  dispatchRemoveModal: typeof removeModal;
  dispatchSetFeedFilters: typeof setFeedFilters;
}

interface StateProps {
  canPostAnnouncement: boolean;
  defaultFilters: Array<FilterGroupType>;
}

const PublicFeeds: React.FC<StateProps & DispatchProps> = ({
  defaultFilters,
  canPostAnnouncement,
  dispatchSetFeedFilters,
  dispatchLlamaModal,
  dispatchRemoveModal
}) => {
  const location = useLocation();
  const history = useHistory();
  const locationState = location.state as {
    feedType?: FeedTypes;
    initialShowNewPost?: boolean;
    highlightedPostId?: string;
  };
  const feedType = locationState?.feedType || FeedTypes.DISCUSSION;
  const initialShowNewPost = !!locationState?.initialShowNewPost;
  const initialHighlightedPostId = locationState?.highlightedPostId;

  const [showNewPost, setShowNewPost] = useState<boolean>(
    feedType !== FeedTypes.ANNOUNCEMENT_FEED && initialShowNewPost
  );
  const [footerButton, setFooterButton] = useState<FooterButton>();

  const [selectFeed, setSelectFeed] = useState<FeedTypes>(feedType);
  const { t } = useTranslation();

  const [filters, setFilters, resolvedFilters] = useFilterResult(
    selectFeed,
    undefined,
    defaultFilters,
    (newFilters: Array<FilterGroupType>) => {
      dispatchSetFeedFilters(newFilters);
    }
  );

  useEffect(() => {
    const newFeedType = feedType || FeedTypes.DISCUSSION;
    setSelectFeed(newFeedType);

    if (newFeedType === FeedTypes.ANNOUNCEMENT_FEED && initialShowNewPost) {
      showAnnouncementModal();
    } else {
      setShowNewPost(initialShowNewPost);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (selectFeed) {
      case FeedTypes.ANNOUNCEMENT_FEED:
        if (canPostAnnouncement) {
          setFooterButton({
            title: t("feed.make_announcement").toUpperCase(),
            disabled: false,
            callback: showAnnouncementModal
          } as FooterButton);
        } else {
          setFooterButton(undefined);
        }
        break;
      case FeedTypes.DISCUSSION:
        setFooterButton({
          title: t("public_feeds.add_post"),
          disabled: false,
          callback: () => {
            setShowNewPost(true);
          }
        } as FooterButton);
        break;
      case FeedTypes.QUESTION:
        setFooterButton({
          title: t("public_feeds.ask_question"),
          disabled: false,
          callback: () => {
            setShowNewPost(true);
          }
        });
        break;
      case FeedTypes.TASK:
        setFooterButton({
          title: t("public_feeds.go_planner"),
          disabled: false,
          callback: () => {
            history.push(ROUTES.PLANNER_DAY);
          }
        });
        break;
      case FeedTypes.SHARED_REFLECTIONS:
        setFooterButton({
          title: t("profile.time_to_reflect"),
          disabled: false,
          callback: () => history.push(ROUTES.REFLECTIONS)
        } as FooterButton);
        break;
      default:
        setFooterButton(undefined);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, selectFeed, t]);

  const showAnnouncementModal = () =>
    dispatchLlamaModal(ModalID.AnnouncementInfo, {
      title: t("feed.announcement_title"),
      paragraphs: [t("feed.announcement_msg1"), t("feed.announcement_msg2")],
      actions: [
        {
          label: t("general.cancel"),
          action: () => {
            dispatchRemoveModal(ModalID.AnnouncementInfo);
          },
          type: ModalCallbackType.Close
        },
        {
          label: t("feed.make_announcement").toUpperCase(),
          action: () => {
            dispatchRemoveModal(ModalID.AnnouncementInfo);
            setShowNewPost(true);
          },
          type: ModalCallbackType.Primary
        }
      ]
    });

  const onClearFilters = () => {
    setFilters(
      resolvedFilters.defaultFilter ? [resolvedFilters.defaultFilter] : []
    );
    dispatchSetFeedFilters([]);
  };

  return (
    <ClanDesktopLayout>
      <IonPage id="discussion-page">
        <ClanIonContent>
          <ClanHeaderRays short />

          <HeaderContainer>
            <ActiveSchoolFilter
              filters={filters}
              clearFilters={onClearFilters}
              placeholder={
                <HeaderTitle>
                  <NestPicker />
                </HeaderTitle>
              }
            />

            {selectFeed !== FeedTypes.ANNOUNCEMENT_FEED && (
              <SchoolFilter
                resolvedFilters={resolvedFilters}
                setFilters={setFilters}
                filters={filters}
                view={(selectFeed as unknown) as Views}
              />
            )}
          </HeaderContainer>

          <div className="text-center">
            <div>
              <IonSegment
                scrollable
                onIonChange={(e) => {
                  switch (e.detail.value) {
                    case FeedTypes.ANNOUNCEMENT_FEED:
                      setSelectFeed(FeedTypes.ANNOUNCEMENT_FEED);
                      break;
                    case FeedTypes.DISCUSSION:
                      setSelectFeed(FeedTypes.DISCUSSION);
                      break;
                    case FeedTypes.QUESTION:
                      setSelectFeed(FeedTypes.QUESTION);
                      break;
                    case FeedTypes.TASK:
                      setSelectFeed(FeedTypes.TASK);
                      break;
                    case FeedTypes.SHARED_REFLECTIONS:
                      setSelectFeed(FeedTypes.SHARED_REFLECTIONS);
                      break;
                  }
                }}
                value={selectFeed}
                color="success"
              >
                <IonSegmentButton
                  value={FeedTypes.ANNOUNCEMENT_FEED}
                  layout="icon-start"
                >
                  <IonNote>{t("public_feeds.schoolwide")}</IonNote>
                </IonSegmentButton>
                <IonSegmentButton
                  value={FeedTypes.DISCUSSION}
                  layout="icon-start"
                >
                  <IonNote>{t("public_feeds.posts")}</IonNote>
                </IonSegmentButton>
                <IonSegmentButton
                  id="questions-feed-btn"
                  value={FeedTypes.QUESTION}
                  layout="icon-start"
                >
                  <IonNote>{t("public_feeds.qa")}</IonNote>
                </IonSegmentButton>
                <IonSegmentButton
                  id="tasks-feed-btn"
                  value={FeedTypes.TASK}
                  layout="icon-start"
                >
                  <IonNote>{t("public_feeds.tasks")}</IonNote>
                </IonSegmentButton>
                <IonSegmentButton
                  id="reflections-feed-btn"
                  value={FeedTypes.SHARED_REFLECTIONS}
                  layout="icon-start"
                >
                  <IonNote>{t("public_feeds.reflections")}</IonNote>
                </IonSegmentButton>
              </IonSegment>
            </div>
          </div>
          <Feed
            feedType={selectFeed}
            filters={filters}
            setShowNewPost={setShowNewPost}
            showNewPost={showNewPost}
            highlightPostId={initialHighlightedPostId}
          />
        </ClanIonContent>
        {footerButton && (
          <FooterButtons>
            <PrimaryFooterButton
              disabled={footerButton.disabled}
              onClick={footerButton.callback}
            >
              {footerButton.title}
            </PrimaryFooterButton>
          </FooterButtons>
        )}
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: ({ token, feed }) => ({
    defaultFilters: feed.activeFilters,
    canPostAnnouncement: UserEnabledProperties.Accessibility.canPostAnnouncement(
      token
    )
  }),
  mapDispatchToProps: {
    dispatchLlamaModal: showLlamaModal,
    dispatchRemoveModal: removeModal,
    dispatchSetFeedFilters: setFeedFilters
  },
  component: PublicFeeds
});
