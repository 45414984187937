import React, { FocusEvent, useState } from "react";
import { IonInput } from "@ionic/react";
import { InputChangeEventDetail } from "@ionic/core";
import styled from "styled-components";
import { H5Style } from "../Typography";

type TextAlign = "left" | "right" | "center";

type ClanInputProps = {
  value?: string;
  placeholder?: string;
  maxLength?: number;
  onChange?: (value: string) => void;
  onSet: (value: string) => void;
  onCancel: (value: string) => void;
  textAlign?: TextAlign;
};

const StyledIonInput = styled(IonInput)<{ textAlign: TextAlign }>`
  ${H5Style}
  text-align: ${(props) => props.textAlign};
`;

const resolveValue = (value: string | number | null | undefined): string => {
  return value === undefined || value === null ? "" : `${value}`;
};

const methodAndRequestBlur = (
  e: { setBlur: () => void; value?: string | number | null | undefined },
  method: (val: string) => void
) => {
  method(resolveValue(e.value));
  try {
    e.setBlur();
  } catch (err) {
    // ignore
  }
};

export default React.memo(
  ({
    value = "",
    placeholder,
    maxLength = 256,
    onChange,
    onSet,
    onCancel,
    textAlign = "left"
  }: ClanInputProps) => {
    const [changed, setChanged] = useState<boolean>(false);

    const onChangeInternal = (e: CustomEvent<InputChangeEventDetail>) => {
      if (onChange) onChange(resolveValue(e.detail.value));
    };

    const onKeyDownInternal = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
      if (e.keyCode === 13 || e.key === "Enter") {
        if (!changed) return;
        setChanged(false);
        methodAndRequestBlur(e.currentTarget, onSet);
      } else {
        setChanged(true);
      }
    };

    const onBlurInternal = (e: FocusEvent<HTMLIonInputElement>) => {
      if (!changed) return;
      setChanged(false);
      methodAndRequestBlur(e.target, onCancel);
    };

    return (
      <StyledIonInput
        placeholder={placeholder}
        onBlur={onBlurInternal}
        value={value}
        type="text"
        maxlength={maxLength}
        onIonChange={onChangeInternal}
        onKeyDown={onKeyDownInternal}
        textAlign={textAlign}
      />
    );
  }
);
