import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { analyticsLogEvent } from "../../Analytics";
import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";
import { EmptyText } from "../../pages/StatsDashboard/StatsDashboard";
import { PROFILE_TRENDS } from "../../routes";
import {
  getWellBeingLabelAndImages,
  getWellBeingNumericQuestionByResultNumber,
  WELL_BEING_NUMBER_LABELS,
  WellBeingQuestion,
  WellBeingQuestionProfile,
  WellBeingResponseTranslated
} from "../../util/api/WellBeingService";
import { dateLocaleFormatter } from "../../util/DateUtils";
import AdvancedCard from "../AdvancedCard";
import Badge from "../Badge";
import ClanLlamaMessage from "../ClanLlamaMessage";
import { FlexRow } from "../Flexbox";
import Header from "../Headers/Header";
import ListItem from "../ListItem/ListItem";
import { Section } from "../Section";
import TabFilter from "../TabFilter";
import { H5 } from "../Typography";

interface CheckInItemModalProps {
  close: () => void;
  wellBeingQuestion: WellBeingResponseTranslated<WellBeingQuestion>;
  nextQuestion: () => void;
  previousQuestion: () => void;
}

const CheckInItemModal: React.FC<CheckInItemModalProps> = ({
  close,
  wellBeingQuestion,
  nextQuestion,
  previousQuestion
}) => {
  let isRedirecting = false;

  const [activeQuestionResult, setActiveQuestionResult] = useState<number>(
    WELL_BEING_NUMBER_LABELS.length
  );
  const [activeQuestionProfiles, setActiveQuestionProfiles] = useState<
    WellBeingQuestionProfile[]
  >(() =>
    wellBeingQuestion.result.profiles.filter(
      (profile) => profile.result === activeQuestionResult
    )
  );

  const [activeResponseAmount, setActiveResponseAmount] = useState<number>(0);

  const { t } = useTranslation();

  useEffect(() => {
    setActiveQuestionProfiles(
      wellBeingQuestion.result.profiles.filter(
        (profile) => profile.result === activeQuestionResult
      )
    );
    setActiveResponseAmount(
      wellBeingQuestion.result.answersAggregated[activeQuestionResult] || 0
    );
  }, [
    activeQuestionResult,
    wellBeingQuestion.result.answersAggregated,
    wellBeingQuestion.result.profiles
  ]);
  const history = useHistory();

  const openProfileTrends = (profileId: number) => () => {
    isRedirecting = true;
    analyticsLogEvent("STATS_FLAG_PROFILE_CLICK");
    history.push(PROFILE_TRENDS.replace(":profileId", profileId.toString()));
  };

  const onDidDismiss = () => {
    if (!isRedirecting) close();
  };
  return (
    <IonModal isOpen onDidDismiss={onDidDismiss}>
      <Header title={t("stats.checkin_modal_title")} onBack={close} />

      <ClanIonContent>
        <AdvancedCard
          primaryText={
            <H5
              dangerouslySetInnerHTML={{
                __html: wellBeingQuestion.result.question
              }}
            />
          }
          onPrevClick={previousQuestion}
          onNextClick={nextQuestion}
          secondaryText={t("stats.checkin_asked", {
            time: dateLocaleFormatter(
              wellBeingQuestion.result.dateTime,
              "HH a, MMM d"
            )
          })}
        />

        <TabFilter>
          {getWellBeingLabelAndImages(0).map(
            ({ result, component: Component }) => (
              <Badge
                key={result.result}
                active={result.result === activeQuestionResult}
                onClick={() => setActiveQuestionResult(result.result)}
              >
                {wellBeingQuestion.result.answersAggregated[result.result] || 0}
                <Component
                  scale={0.8}
                  active={result.result === activeQuestionResult}
                />
              </Badge>
            )
          )}
        </TabFilter>

        <Section>
          {activeResponseAmount === 0 && (
            <EmptyText>{t("stats.checkin_noresponses")}</EmptyText>
          )}

          {activeQuestionProfiles.length !== activeResponseAmount && (
            <>
              {activeQuestionProfiles.length === 0 ? (
                <>
                  <ClanLlamaMessage
                    messages={[
                      t("stats.no_access"),
                      t("stats.only_group_leads_access")
                    ]}
                  />
                </>
              ) : (
                <div className="text-center mx-2 p-3 grid min-h-clanListItem items-center">
                  {t("stats.well_being_nolead_profiles_mismatch")}
                </div>
              )}
            </>
          )}

          {activeQuestionProfiles
            .map(getWellBeingNumericQuestionByResultNumber)
            .map(
              ({
                result,
                component: Component,
                progressIcon: ProgressIcon
              }) => (
                <ListItem
                  key={result.id}
                  onClick={openProfileTrends(result.id)}
                  avatar={result.images?.thumbnail?.url}
                  primary={result.name}
                  icon={
                    <FlexRow columnGap="4px" align="center">
                      <Component active scale={0.8} />
                      <ProgressIcon />
                    </FlexRow>
                  }
                />
              )
            )}
        </Section>
      </ClanIonContent>
    </IonModal>
  );
};

export default CheckInItemModal;
