import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CategoryHeader from "../../components/CategoryHeader";
import CheckInListItem from "../../components/ListItem/CheckInListItem";
import MoodPercentages from "../../components/MoodPercentages";
import { SectionContent } from "../../components/Section";
import { ChartResultNumber, ChartResultPercentage } from "../../util/Analytics";
import {
  getWellBeingNumericQuestionByResultNumber,
  WellBeingQuestion
} from "../../util/api/WellBeingService";
import { EmptyText } from "../StatsDashboard/StatsDashboard";

const TrendsWrapper = styled.div`
  padding-bottom: 2rem;
`;

interface ProfileTrendsProps {
  moodPercentages: Array<ChartResultPercentage>;
  chartNumbers: Array<ChartResultNumber>;
  wellBeingQuestions: Array<WellBeingQuestion>;
}

const ProfileTrends: React.FC<ProfileTrendsProps> = ({
  moodPercentages,
  chartNumbers,
  wellBeingQuestions
}) => {
  const { t } = useTranslation();

  const wellbeingData = wellBeingQuestions.map(
    getWellBeingNumericQuestionByResultNumber
  );

  return (
    <TrendsWrapper>
      <CategoryHeader
        title={t("profile_trends.wellbeing")}
        subtitle={t("profile_trends.subtitle")}
        tooltipText={t("profile_trends.wellbeing_tooltip")}
      />

      <SectionContent>
        {wellBeingQuestions.length > 0 ? (
          wellbeingData.map((wellbeingQuestion, index) => (
            <CheckInListItem
              simple
              key={index}
              wellbeingQuestion={wellbeingQuestion}
            />
          ))
        ) : (
          <SectionContent>
            <EmptyText>{t("profile_trends.wellbeing_empty")}</EmptyText>
          </SectionContent>
        )}
      </SectionContent>

      <CategoryHeader
        title={t("profile_trends.mood")}
        tooltipText={t("profile_trends.mood_tooltip")}
      />

      {moodPercentages.length > 0 && <MoodPercentages data={moodPercentages} />}
    </TrendsWrapper>
  );
};

export default ProfileTrends;
