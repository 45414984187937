import React from "react";
import styled from "styled-components";

import { ReactComponent as LlamaIcon } from "../icons/llama.svg";

const ContentWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 24px;
  text-align: center;
  border: 4px solid #fafafa;
  border-radius: 10px;
  background: white;

  > *:last-child {
    margin-bottom: 0;
  }
`;

const LlamaImage = styled(LlamaIcon)`
  display: block;
  margin: 0 auto 10px;
  max-width: 150px;
  width: 100%;
`;

const LlamaBox: React.FC = ({ children, ...props }) => (
  <ContentWrapper {...props}>
    <LlamaImage />
    {children}
  </ContentWrapper>
);

export default LlamaBox;
