import { OauthData } from "./oauthDataService";
import { getAppResetActionPayload } from "../app/app.actions";
import { dispatch } from "../AppContext";
import { initByRefreshToken, logoutFromBackend } from "../../util/ApiService";
import { removeOauth } from "./oauth.actions";
import { Storage } from "../../util/storage/DataStorage";
import { logoutFromFirebase } from "../../util/GoogleFirebase";
import { analyticsLogEvent } from "../../Analytics";
import { useAsync } from "@react-hook/async";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../routes";
import { History } from "history";
import { cleanupCachedData } from "../../util/UserProperties";

const runIgnoringErrors = async (
  callable: () => Promise<any>,
  message: string
) => {
  try {
    //console.debug(`Start ${message}`);
    await callable();
    //console.debug(`Done ${message}`);
  } catch (error) {
    console.debug(`Could not ${message}`, error);
  }
};

export const signOutUser = async (history: History) => {
  try {
    await runIgnoringErrors(
      async () => analyticsLogEvent("firebase logout"),
      "Log analytics firebase logout"
    );
    await runIgnoringErrors(logoutFromBackend, "Log out from back end");
    await runIgnoringErrors(logoutFromFirebase, "Log out from Firebase");
    await runIgnoringErrors(
      async () => removeOauth()(dispatch),
      "Remove the oauth"
    );
    await runIgnoringErrors(
      async () => dispatch(getAppResetActionPayload()),
      "Dispatch App reset action payload"
    );
    await runIgnoringErrors(
      async () => cleanupCachedData(),
      "Cleaning up cached data"
    );
    await runIgnoringErrors(
      async () => Storage.clearSafeStorage(),
      "Clear the storage"
    );
  } catch (error) {
    console.error("error while signing out", error);
  } finally {
    if (history.location.pathname !== ROUTES.START) {
      history.replace(ROUTES.START);
    }
  }
};

export const useSignOutUser = () => {
  const history = useHistory();
  return useAsync(async () => {
    await signOutUser(history);
  });
};

export const initOauthState = async (): Promise<OauthData> =>
  initByRefreshToken();
