import styled from "styled-components";

const BaseSectionHeading = styled.div`
  font-family: Gilroy;
  font-weight: 800;
  line-height: 28px;
  margin: 2rem auto 1rem;
  border-bottom: 2px solid ${({ theme }) => theme.primary};
  max-width: 90%;
`;

export const LargeSectionHeading = styled(BaseSectionHeading)`
  font-size: 24px;
  padding-bottom: 0.6rem;
`;

export const MediumSectionHeading = styled(BaseSectionHeading)`
  font-size: 18px;
  padding-bottom: 0.4rem;
`;

// TODO: SectionContent is not needed, wrap the whole section with <Section> and remove <SectionContent>
export const SectionContent = styled.div`
  width: 90%;
  margin: 24px auto;
`;

export const Section = styled.section`
  width: 90%;
  margin: 24px auto;
`;

export const Paragraph = styled.p<{ fontSize?: string }>`
  font-family: Gilroy;
  margin-top: 0;

  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
`;
