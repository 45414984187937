import { FilterGroupType } from "../types/index.d";
import { ViewSharingConfigurationItem } from "../../Configuration";

export const constantFilterEquals = (
  filter1: ViewSharingConfigurationItem,
  filter2: ViewSharingConfigurationItem
): boolean => {
  const filterGroup1: FilterGroupType = filter1 as FilterGroupType;
  const filterGroup2: FilterGroupType = filter2 as FilterGroupType;
  if (
    filterGroup1.groupId !== undefined &&
    filterGroup2.groupId !== undefined
  ) {
    return filterGroup1.groupId === filterGroup2.groupId;
  }
  if (
    filterGroup1.groupId !== undefined ||
    filterGroup2.groupId !== undefined
  ) {
    return false;
  }
  return filter1.filter === filter2.filter && filter1.type === filter2.type;
};
