import { IonIcon } from "@ionic/react";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  updateLatestActivityTimestamp,
  updateLatestChatTimestamps
} from "../data/app/app.actions";
import { LatestTimestampForChatId } from "../data/app/app.state";
import { connect } from "../data/connect";
import { OauthState } from "../data/oauth/oauth.state";
import { OauthData } from "../data/oauth/oauthDataService";
import * as ROUTES from "../routes";
import { desktopOnlyStyles } from "../util/ResponsiveUtils";
import { usePendingItemsInfo } from "../util/PendingItemsInfo";

const StyledToolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.7rem;
`;

const HeaderTitle = styled.div`
  flex: 1;
  margin-left: 1.2rem;
  font-family: Gilroy;
  font-size: 1.3rem;
  font-weight: 700;
`;

const HeaderNav = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 0.8rem;

  ${desktopOnlyStyles({
    display: "none"
  })}
`;

const StyledLink = styled(Link)`
  display: block;
  margin-right: 0.3rem;
`;

const StyledNavIcon = styled(IonIcon)<{ active?: boolean }>`
  width: 3rem;
  height: 3rem;
  ${({ active }) =>
    active && "border: 1px solid #45DD85; margin: -1px; border-radius: 99px;"}
`;

interface NavBtnProps {
  to: string;
  icon: string;
  active?: boolean;
}

const NavBtn: React.FC<NavBtnProps> = ({ to, icon, active }) => (
  <StyledLink to={to}>
    <StyledNavIcon src={icon} active={active} />
  </StyledLink>
);

interface OwnProps {
  className?: string;
  hideNav?: boolean;
}

interface StateProps {
  oauthState: OauthState | OauthData;
  lastActivityTimestampConnect: string | undefined;
  chatHubLatestTimestampsConnect: LatestTimestampForChatId[] | undefined;
}

interface DispatchProps {
  dispatchUpdateLatestActivityTimestamp: typeof updateLatestActivityTimestamp;
  dispatchUpdateLatestChatTimestamps: typeof updateLatestChatTimestamps;
}

interface ClanToolbarProps extends OwnProps, StateProps, DispatchProps {}

const ClanToolbar: React.FC<ClanToolbarProps> = ({
  lastActivityTimestampConnect,
  chatHubLatestTimestampsConnect,
  dispatchUpdateLatestActivityTimestamp,
  dispatchUpdateLatestChatTimestamps,
  oauthState,
  className,
  children,
  hideNav = false
}) => {
  const location = useLocation();
  const { activitiesPending, pendingChatMessages } = usePendingItemsInfo(
    oauthState,
    lastActivityTimestampConnect,
    chatHubLatestTimestampsConnect
  );

  useEffect(() => {
    dispatchUpdateLatestActivityTimestamp();
    dispatchUpdateLatestChatTimestamps();
  }, [
    dispatchUpdateLatestActivityTimestamp,
    dispatchUpdateLatestChatTimestamps
  ]);

  const isActive = (route: string) => location.pathname === route;

  return (
    <StyledToolbar id="clan-toolbar" className={className}>
      <HeaderTitle>{children}</HeaderTitle>
      {!hideNav && (
        <HeaderNav>
          {oauthState.hasToken && (
            <>
              <NavBtn
                to={ROUTES.ACTIVITY}
                active={activitiesPending}
                icon={`/assets/navigation/notifications${
                  isActive(ROUTES.ACTIVITY) ? "-active" : ""
                }.svg`}
              />
              <NavBtn
                to={ROUTES.MESSAGES_HUB}
                active={pendingChatMessages}
                icon={`/assets/navigation/discussion${
                  isActive(ROUTES.MESSAGES_HUB) ? "-active" : ""
                }.svg`}
              />
            </>
          )}
          <NavBtn to={ROUTES.SETTINGS} icon="/assets/navigation/settings.svg" />
        </HeaderNav>
      )}
    </StyledToolbar>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    oauthState: state.token,
    lastActivityTimestampConnect: state.appState.lastActivityTimestamp,
    chatHubLatestTimestampsConnect: state.appState.chatHubLatestTimestamps
  }),
  mapDispatchToProps: {
    dispatchUpdateLatestActivityTimestamp: updateLatestActivityTimestamp,
    dispatchUpdateLatestChatTimestamps: updateLatestChatTimestamps
  },
  component: ClanToolbar
});
