import { IonContent, IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ClanMembershipListItemResponse } from "../../util/models/ClanProfileModels";
import CategoryHeader from "../CategoryHeader";
import { SmoothRays } from "../ClanSmoothRays";
import FooterButtons, {
  PrimaryFooterButton,
  SecondaryFooterButton
} from "../FooterButtons";
import { MediumSectionHeading, Paragraph, SectionContent } from "../Section";

import { ReactComponent as GroupLeadIcon } from "../../icons/roles/group-lead2.svg";

const StyledCategoryHeader = styled(CategoryHeader)`
  margin-bottom: 10px;
`;

const StaffSearchContent = styled(IonContent)`
  --padding-bottom: 90px;
`;

const StyledSearchResult = styled.div<{ active?: boolean }>`
  background: #fafafa;
  border: 1px solid ${({ active }) => (active ? "#45DD85" : "#fafafa")};
  border-radius: 25px;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Avatar = styled.img`
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
`;

const Name = styled.div`
  font-family: Gilroy;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  margin-left: 10px;
`;

const LeadBadge = styled(GroupLeadIcon)`
  margin-left: 8px;
  width: 18px;
  height: 18px;
`;

interface SearchResultProps {
  name: string;
  active: boolean;
  isLead?: boolean;
  thumbnail?: string;
  onClick?: () => void;
}

const SearchResult: React.FC<SearchResultProps> = ({
  thumbnail,
  active,
  name,
  isLead,
  onClick
}) => (
  <StyledSearchResult active={active} onClick={onClick}>
    <Avatar src={thumbnail || "/assets/navigation/profile.svg"} />
    <Name>{name}</Name>
    {isLead && <LeadBadge />}
  </StyledSearchResult>
);

type StaffArray = Array<ClanMembershipListItemResponse>;

interface StaffSectionProps {
  title: string;
  staffList: StaffArray;
  selectedUserIDs: Array<number>;
  onSelect: (selectedMember: ClanMembershipListItemResponse) => void;
}

const StaffSection: React.FC<StaffSectionProps> = ({
  title,
  staffList,
  selectedUserIDs,
  onSelect
}) => {
  const onStaffSelect = (
    selectedMember: ClanMembershipListItemResponse
  ) => () => onSelect(selectedMember);

  return (
    <>
      <StyledCategoryHeader title={title} />
      <SectionContent>
        {staffList.map((staffMember) => (
          <SearchResult
            // isLead
            active={selectedUserIDs.includes(staffMember.userId)}
            key={staffMember.userId}
            name={staffMember.name}
            onClick={onStaffSelect(staffMember)}
          />
        ))}
      </SectionContent>
    </>
  );
};

interface GroupedStaff {
  teachers: StaffArray;
  leads: StaffArray;
  other: StaffArray;
}

interface StaffSearchModalProps {
  title: string;
  description: string;
  submitLabel?: string;
  staffList: StaffArray;
  selectedUserIDs?: Array<number>;
  disabled?: boolean;
  onStaffClick: (selectedMember: ClanMembershipListItemResponse) => void;
  onSubmit?: () => void;
  onClose?: () => void;
}

const StaffSearchModal: React.FC<StaffSearchModalProps> = ({
  title,
  description,
  staffList,
  selectedUserIDs = [],
  submitLabel,
  disabled,
  onStaffClick,
  onSubmit,
  onClose
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  const [groupedStaffList, setGroupedStaffList] = useState<GroupedStaff>({
    teachers: [],
    leads: [],
    other: []
  });

  useEffect(() => {
    setGroupedStaffList(groupStaff(staffList));
  }, [staffList]);

  function groupStaff(staff: StaffArray) {
    return staff.reduce(
      (groupedList, member) => {
        const { teachers, leads, other } = groupedList;
        const { identity } = member;
        if (identity.includes("GROUP_LEAD")) {
          return {
            ...groupedList,
            leads: [...leads, member]
          };
        } else if (identity.includes("TEACHER_BADGE")) {
          return {
            ...groupedList,
            teachers: [...teachers, member]
          };
        }

        return {
          ...groupedList,
          other: [...other, member]
        };
      },
      {
        teachers: [],
        leads: [],
        other: []
      } as GroupedStaff
    );
  }

  const closeModal = () => setIsOpen(false);

  const onStaffSelect = (selectedMember: ClanMembershipListItemResponse) =>
    onStaffClick(selectedMember);

  const { teachers, leads, other } = groupedStaffList;

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <StaffSearchContent>
        <SmoothRays showInDesktop />
        <MediumSectionHeading>{title}</MediumSectionHeading>
        <SectionContent>
          <Paragraph>{description}</Paragraph>
        </SectionContent>

        {leads.length > 0 && (
          <StaffSection
            title={t("staff_search_modal.leads")}
            staffList={leads}
            selectedUserIDs={selectedUserIDs}
            onSelect={onStaffSelect}
          />
        )}

        {teachers.length > 0 && (
          <StaffSection
            title={t("staff_search_modal.teachers")}
            staffList={teachers}
            selectedUserIDs={selectedUserIDs}
            onSelect={onStaffSelect}
          />
        )}

        {other.length > 0 && (
          <StaffSection
            title={t("staff_search_modal.other")}
            staffList={other}
            selectedUserIDs={selectedUserIDs}
            onSelect={onStaffSelect}
          />
        )}
      </StaffSearchContent>
      <FooterButtons>
        {onClose && (
          <SecondaryFooterButton onClick={closeModal}>
            {t("general.back")}
          </SecondaryFooterButton>
        )}
        {onSubmit && (
          <PrimaryFooterButton disabled={disabled} onClick={onSubmit}>
            {submitLabel || t("general.share")}
          </PrimaryFooterButton>
        )}
      </FooterButtons>
    </IonModal>
  );
};

export default StaffSearchModal;
