import { createGesture } from "@ionic/core";
import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { ellipsisHorizontalCircleSharp } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Zoomable from "react-instagram-zoom";
import styled from "styled-components";

import { connect } from "../../data/connect";
import { OauthState } from "../../data/oauth/oauth.state";
import { isSameProfile } from "../../data/oauth/oauthDataService";
import { StyledAvatar } from "../../pages/StatsDashboard/StatsDashboard";
import { dateLocaleFormatter } from "../../util/DateUtils";
import { useHtmlContentResolver } from "../../util/Html";
import {
  ClanPostListResultV1,
  ClanReactions
} from "../../util/models/ClanPostModels";
import { isValidMood } from "../../util/MoodChoice";
import {
  filterReactionTypes,
  filterReactionTypesToValues,
  ReactionTypes
} from "../../util/Reactions";
import ReactionPopover from "../ReactionPopover";
import { PostFont } from "../Typography";

const StyledSection = styled.section<PostProps>`
  ${PostFont}
  word-break: break-word;

  p {
    ${PostFont}
  }

  > * {
    margin: 0;
  }

  user-select: text;
  text-align: ${({ self }) => (self ? "right" : "left")};
`;

const MoodItem = styled.span`
  ${PostFont}
  user-select: text;
`;

const MessageContent = styled.div<{ outgoing?: boolean }>`
  width: 100%;
  ${({ outgoing }) => outgoing && "margin-left: auto;"};
`;

interface PostProps {
  self?: boolean;
}
const StyledPost = styled(IonItem)<PostProps>`
  overflow: visible;
  width: 80%;
  margin-left: ${({ self }) => (self ? "auto" : "initial")};
  padding: 1rem 0;
`;

interface StateProps {
  tokenConnect: OauthState;
}

type OwnProps = {
  message: ClanPostListResultV1;
  showTime: boolean;
  setMenuOpenMessageId: (id: string) => void;
};

interface MessageBlockProps extends OwnProps, StateProps {}

const MessageBlock: React.FC<MessageBlockProps> = ({
  message,
  showTime,
  setMenuOpenMessageId,
  tokenConnect
}) => {
  const { t } = useTranslation();
  const [reactions, setReactions] = useState<ClanReactions>(message.reactions);
  const [myReactions, setMyReactions] = useState<Array<ReactionTypes>>();
  const [showReactionsBar, setShowReactionsBar] = useState<boolean>(false);

  useEffect(() => {
    const myReactionsArray: Array<ReactionTypes> = [];
    filterReactionTypesToValues(reactions).forEach((reaction) => {
      if (reaction.likedByMe) {
        myReactionsArray.push(reaction.reaction);
      }
    });
    setMyReactions(myReactionsArray);
  }, [reactions]);

  useEffect(() => {
    setReactions(message.reactions);
  }, [message]);

  const self = isSameProfile(tokenConnect, message.userProfile?.id);
  const messageItemRef = useRef<HTMLIonItemElement>(null);

  const htmlResult = useHtmlContentResolver(message?.html);

  return (
    <div className="flex flex-col" id={`message-${message.id}`}>
      {showTime && message.createdAt && (
        <h5 className="text-gray-500 m-0 self-center">
          {dateLocaleFormatter(message?.createdAt, "MMMM d, HH:mm")}
        </h5>
      )}
      <StyledPost self={self} ref={messageItemRef}>
        {!self && message.userProfile?.images?.thumbnail?.url && (
          <StyledAvatar className="tab-button-small mb-4 flex-shrink-0">
            <img
              src={message.userProfile?.images?.thumbnail?.url}
              alt="profile_image"
            />
          </StyledAvatar>
        )}

        <MessageContent outgoing={self}>
          {!self && message.permissions.like && showReactionsBar && (
            <ReactionPopover
              post={message}
              myReactions={myReactions}
              reactions={reactions}
              setReactions={setReactions}
              onClose={() => setShowReactionsBar(false)}
            />
          )}

          <div
            className={`cursor-pointer ${
              self
                ? "bg-clanYellow-100 text-right"
                : "bg-clanGray-100 text-left"
            } rounded-clanCard text-clanP p-4 whitespace-pre-wrap`}
            ref={(ref) => {
              let timerId: any = null;
              ref &&
                createGesture({
                  el: ref as Node,
                  gestureName: "longpress",
                  gesturePriority: 100,
                  threshold: 0,
                  passive: true,
                  onStart: () => {
                    timerId = setTimeout(() => setShowReactionsBar(true), 200);
                  },
                  onEnd: () => clearTimeout(timerId)
                }).enable();
            }}
          >
            {htmlResult && (
              <StyledSection
                self={self}
                dangerouslySetInnerHTML={{
                  __html: htmlResult
                }}
              />
            )}
            {message.mood && isValidMood(message.mood) && (
              <MoodItem>
                {t("messages_hub.feeling", {
                  how: t(`moods.${message.mood?.toLowerCase()}`).toLowerCase()
                })}{" "}
                <img
                  className="w-6 h-6 ml-2 align-middle"
                  alt={message.mood}
                  src={`/assets/mood/${message.mood?.toLowerCase()}.svg`}
                />
              </MoodItem>
            )}
            {message.images?.thumbnail?.url && (
              <Zoomable>
                <img
                  id={message.id}
                  className="mt-4"
                  src={message.images?.thumbnail?.url}
                  alt="Post_image"
                />
              </Zoomable>
            )}
          </div>
          <div
            className={`flex m-1 items-center ${
              self ? "flex-row-reverse" : "flex-row"
            }`}
          >
            {(message.permissions.edit ||
              message.permissions.remove ||
              message.permissions.report) && (
              <IonIcon
                size="small"
                onClick={() => message?.id && setMenuOpenMessageId(message?.id)}
                className={`text-clanGray-300 cursor-pointer ${
                  self ? "ml-auto" : ""
                }`}
                icon={ellipsisHorizontalCircleSharp}
              />
            )}
            {Object.keys(reactions).length !== 0 && (
              <IonLabel
                className={`${
                  self ? "mr-auto" : "ml-auto pl-4"
                } mr-1 flex items-center`}
              >
                {filterReactionTypes(reactions).map((reactionItem, i) => (
                  <IonIcon
                    size="small"
                    className="visible mr-1"
                    key={i}
                    src={`/assets/reaction/${reactionItem.toLowerCase()}.svg`}
                  />
                ))}
              </IonLabel>
            )}
          </div>
        </MessageContent>
      </StyledPost>
    </div>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    tokenConnect: state.token
  }),
  component: MessageBlock
});
