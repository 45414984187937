import { getConfiguration } from "./ConfigurationCore";
import { Views } from "../types/Views.d";
import { ViewFilterConfiguration } from "../types/ViewFilters.d";

export const getConstantFilters = async (
  viewFilter: Views
): Promise<ViewFilterConfiguration> => {
  const conf = await getConfiguration();
  return conf.views[viewFilter].filters;
};
