import { IonItem } from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import CategoryHeader from "../../components/CategoryHeader";
import SocialIcon from "../../components/ClanSocialIcon";
import TabFilter from "../../components/TabFilter";
import ClanSimpleInterests, {
  InterestType
} from "../../components/PeopleOverview/ClanSimpleInterests";
import * as ROUTES from "../../routes";
import { UserSocialLinksType } from "../../util/SocialLinks";
import { useTranslation } from "react-i18next";
import { connect } from "../../data/connect";
import { OauthState } from "../../data/oauth/oauth.state";
import { OauthData } from "../../data/oauth/oauthDataService";
import { FeatureService } from "../../util/api/FeatureService";

const AboutWrapper = styled.div`
  padding-bottom: 2rem;
`;

const AboutSection = styled.div`
  margin-bottom: 1.8rem;
`;

const SectionContent = styled.div`
  width: 100%;
  padding: 0 2.5rem;
`;

export enum ProfileAboutSection {
  All = "ALL",
  Interests = "INTERESTS",
  BucketList = "BUCKET LIST",
  Contacts = "CONTACTS"
}

interface ProfileAboutProps {
  interests?: Array<string>;
  bucketList?: Array<string>;
  socialContacts: Array<UserSocialLinksType>;
}

interface StateProps {
  oauthState: OauthState | OauthData;
}
const ProfileAbout: React.FC<ProfileAboutProps & StateProps> = ({
  socialContacts,
  bucketList = [],
  interests = [],
  oauthState
}) => {
  const socialLinksDisabled = FeatureService.PROFILE_DISABLE_SOCIAL_LINKS(
    oauthState
  );
  const history = useHistory();
  const [activeTab, setActiveTab] = useState<ProfileAboutSection>(
    ProfileAboutSection.All
  );

  const onTabChange = (newTab: any) =>
    setActiveTab(newTab as ProfileAboutSection);

  const isVisible = (tab: ProfileAboutSection) =>
    [ProfileAboutSection.All, tab].includes(activeTab);

  const editInterests = () => {
    history.push(ROUTES.ONBOARDING, { currentStep: 4 });
  };

  const editBucketList = () => {
    history.push(ROUTES.ONBOARDING, { currentStep: 5 });
  };

  const editContacts = () => {
    history.push(ROUTES.ONBOARDING, { currentStep: 6 });
  };

  const { t } = useTranslation();

  const ProfileAboutSectionValues = Object.keys(ProfileAboutSection)
    .map((key) => [
      key,
      ProfileAboutSection[key as keyof typeof ProfileAboutSection]
    ])
    .filter(
      (values) =>
        values[1] !== ProfileAboutSection.Contacts || !socialLinksDisabled
    )
    .reduce((map, values) => ({ ...map, [values[0]]: values[1] }), {});

  return (
    <AboutWrapper>
      <TabFilter
        id="profile-filter"
        active={activeTab}
        options={ProfileAboutSectionValues}
        onChange={onTabChange}
      />

      {isVisible(ProfileAboutSection.Interests) && (
        <AboutSection>
          <CategoryHeader
            title={t("profile_about.interests")}
            tooltipText={t("profile_about.interests_desc")}
            actions={[
              { title: t("profile_about.edit"), action: editInterests }
            ]}
          />
          <SectionContent>
            <ClanSimpleInterests
              wrapped
              type={InterestType.Interest}
              interests={interests}
            />
          </SectionContent>
        </AboutSection>
      )}

      {isVisible(ProfileAboutSection.BucketList) && (
        <AboutSection>
          <CategoryHeader
            title={t("profile_about.bucket_list")}
            tooltipText={t("profile_about.bucket_list_desc")}
            actions={[
              { title: t("profile_about.edit"), action: editBucketList }
            ]}
          />
          <SectionContent>
            <ClanSimpleInterests
              type={InterestType.BucketList}
              interests={bucketList}
            />
          </SectionContent>
        </AboutSection>
      )}

      {!socialLinksDisabled && isVisible(ProfileAboutSection.Contacts) && (
        <AboutSection>
          <CategoryHeader
            title={t("profile_about.contacts")}
            tooltipText={t("profile_about.contacts_desc")}
            actions={[{ title: t("profile_about.edit"), action: editContacts }]}
          />
          <SectionContent>
            {socialContacts?.map((socialContact: UserSocialLinksType) => (
              <IonItem
                key={`user_social_${socialContact.name}`}
                button={false}
                color="tertiary"
                href={socialContact.url}
                target="_blank"
                className="clan-list-item mb-2 p-0"
              >
                <SocialIcon
                  iconName={socialContact.name}
                  className="mr-0 h-6 w-6"
                />
                <h5 className="text-center text-black flex-1 pl-3 pr-6">
                  {socialContact.name}: {socialContact.value}
                </h5>
              </IonItem>
            ))}
          </SectionContent>
        </AboutSection>
      )}
    </AboutWrapper>
  );
};

export default connect<ProfileAboutProps, {}, StateProps>({
  mapStateToProps: (state) => ({
    oauthState: state.token
  }),
  component: ProfileAbout
});
