import { IonIcon, IonItem } from "@ionic/react";
import { arrowForward } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useHistory } from "react-router-dom";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import { doRequestAndReturnResult } from "../../apiService";
import CategoryHeader from "../../components/CategoryHeader";
import CheckInListItem from "../../components/ListItem/CheckInListItem";
import CheckInItemModal from "../../components/Modals/CheckInItemModal";
import MoodPercentages from "../../components/MoodPercentages";
import { SectionContent } from "../../components/Section";
import { connect } from "../../data/connect";
import * as ROUTES from "../../routes";
import { ChartResultPercentage } from "../../util/Analytics";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import {
  getWellBeingNumericQuestionByResultNumber,
  WellBeingQuestion
} from "../../util/api/WellBeingService";
import { ClanPostDataNameAndId } from "../../util/models/ClanPostModels";
import { EmptyText } from "./StatsDashboard";

interface StateProps {
  wellbeingPermissions: boolean;
  moodPermissions: boolean;
}

interface OwnProps {
  moodPercentages: ChartResultPercentage[];
  wellBeingAnalytics: WellBeingQuestion[] | undefined | null;
}

interface WellBeingProps extends StateProps, OwnProps {}

const WellBeing: React.FC<WellBeingProps> = ({
  wellbeingPermissions,
  moodPermissions,
  moodPercentages,
  wellBeingAnalytics
}) => {
  const [groupId, setGroupId] = useState<number>();
  const history = useHistory();
  const { t } = useTranslation();

  const wellbeingData = (wellBeingAnalytics || []).map((i) => ({
    key: i.key,
    ...getWellBeingNumericQuestionByResultNumber<WellBeingQuestion>(i)
  }));

  useEffect(() => {
    setAnalyticsScreenName("ANALYTICS_WELLBEING_TAB");

    doRequestAndReturnResult("get", "group/v1").then((result) => {
      const groups = result as ClanPostDataNameAndId[];
      if (groups && Array.isArray(groups)) {
        // zero means more than one groups exists
        groups.length === 1 ? setGroupId(groups[0].id) : setGroupId(0);
      }
    });
  }, []);

  const onCheckInItemClick = (key: string) => () => {
    analyticsLogEvent("SURVEY_CHECK_IN_ITEM_CLICKED");
    openCheckInModal(key);
  };

  const closeCheckInModal = () => history.push(ROUTES.STATS_DASHBOARD_MOOD);

  const openCheckInModal = (key: string) =>
    history.push(ROUTES.STATS_DASHBOARD_CHECK_IN.replace(":questionKey", key));

  const getNewCheckInItemKey = (currentKey: string, step: number) => {
    const newCheckInItemIndex =
      (wellbeingData.findIndex((w) => w.key === currentKey) +
        wellbeingData.length +
        step) %
      wellbeingData.length;

    return wellbeingData[newCheckInItemIndex].key;
  };

  const onPreviousQuestion = (currentKey: string) => () =>
    openCheckInModal(getNewCheckInItemKey(currentKey, -1));

  const onNextQuestion = (currentKey: string) => () =>
    openCheckInModal(getNewCheckInItemKey(currentKey, 1));

  return (
    <div>
      <Route
        exact
        path={ROUTES.STATS_DASHBOARD_CHECK_IN}
        render={({
          match: {
            params: { questionKey }
          }
        }) => {
          const activeCheckInItem = wellbeingData.find(
            (w) => w.key === questionKey
          );

          return (
            activeCheckInItem && (
              <CheckInItemModal
                wellBeingQuestion={activeCheckInItem}
                close={closeCheckInModal}
                nextQuestion={onNextQuestion(activeCheckInItem.key)}
                previousQuestion={onPreviousQuestion(activeCheckInItem.key)}
              />
            )
          );
        }}
      />

      <CategoryHeader
        title={t("stats.last_checkin")}
        tooltipText={t("stats.last_checkin_tooltip")}
      />

      {wellBeingAnalytics !== undefined &&
        (wellBeingAnalytics ? (
          wellBeingAnalytics.length > 0 ? (
            <SectionContent>
              {wellbeingData.map((wellbeingQuestion) => (
                <CheckInListItem
                  key={wellbeingQuestion.key}
                  wellbeingQuestion={wellbeingQuestion}
                  onClick={onCheckInItemClick(wellbeingQuestion.key)}
                />
              ))}
            </SectionContent>
          ) : (
            <EmptyText>{t("stats.no_checkins")}</EmptyText>
          )
        ) : (
          <>
            <EmptyText>{t("wellbeing.empty_1")}</EmptyText>
            <EmptyText>{t("wellbeing.empty_2")}</EmptyText>
            <IonItem
              onClick={() =>
                groupId !== undefined && groupId !== 0
                  ? history.push(
                      ROUTES.GROUP_PREFERENCES_VIEW.replace(
                        ":groupId",
                        groupId.toString()
                      )
                    )
                  : history.push(ROUTES.GROUPS_VIEW)
              }
              className="mx-2 my-1 text-clanGreen-100 font-extrabold font-gilroy cursor-pointer"
            >
              <IonIcon
                slot="start"
                size="small"
                className="mr-1 h-4"
                icon={arrowForward}
              />
              {t("wellbeing.tap")}
            </IonItem>
          </>
        ))}

      {moodPermissions && moodPercentages.length > 0 && (
        <>
          <CategoryHeader
            title={t("stats.mood_level")}
            subtitle={t("stats.usage_subtitle")}
            tooltipText={t("stats.mood_level_tooltip")}
          />
          <MoodPercentages data={moodPercentages} />
        </>
      )}
    </div>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: ({ token }) => ({
    tokenConnect: token,
    wellbeingPermissions: UserEnabledProperties.WellBeing.dashboard(token),
    moodPermissions: UserEnabledProperties.AnalyticsStatus.moodStats(token)
  }),
  component: WellBeing
});
