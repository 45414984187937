import {
  PhoneAuthProvider,
  signInWithCredential,
  signInWithPhoneNumber
} from "firebase/auth";
import { History } from "history";
import { FirebaseAuthentication } from "@robingenz/capacitor-firebase-authentication";
import firebase from "firebase/compat";
import { isNativePlatform } from "../../AppUtils";
import { getFirebaseAuthentication } from "./FirebaseAuthConfiguration";
import { FirebaseApiServiceError } from "../types/FirebaseApiServiceError.d";
import { authenticateWithFirebaseUser } from "../../api/Authentication";

const _tryToSignInWithPhoneNumber = async (
  phoneNumber: string,
  appVerifier?: firebase.auth.ApplicationVerifier
): Promise<{ verificationId?: string }> => {
  if (isNativePlatform) {
    return FirebaseAuthentication.signInWithPhoneNumber({
      phoneNumber
    });
  } else {
    if (!appVerifier) {
      throw new FirebaseApiServiceError("App verifier must be defined!");
    }
    return signInWithPhoneNumber(
      getFirebaseAuthentication(),
      phoneNumber,
      appVerifier
    );
  }
};

export const tryToSignInWithPhoneNumber = async (
  phoneNumber: string,
  appVerifier?: firebase.auth.ApplicationVerifier
): Promise<string> => {
  const result = await _tryToSignInWithPhoneNumber(phoneNumber, appVerifier);
  if (!result.verificationId) {
    throw new FirebaseApiServiceError(
      "Could not authenticate with phone number!"
    );
  }
  return result.verificationId;
};

export const tryToSignInWithCredential = async (
  history: History,
  verificationId: string,
  verificationCode: string,
  checkSignUp: boolean
): Promise<boolean> => {
  if (isNativePlatform) {
    return FirebaseAuthentication.signInWithPhoneNumber({
      verificationId,
      verificationCode
    }).then(() => true);
    // Continue from auth state listener
  } else {
    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    const result = await signInWithCredential(
      getFirebaseAuthentication(),
      credential
    );
    const { token } = await result.user.getIdTokenResult();

    return authenticateWithFirebaseUser(
      history,
      { idToken: token },
      checkSignUp
    );
  }
};
