import { IonFooter, IonPage, IonProgressBar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import ClanFooterBtn from "../../components/ClanFooterBtn";
import ClanLoader from "../../components/ClanLoader";
import { connect } from "../../data/connect";
import { showToast } from "../../data/toasts/toasts.actions";
import { setUserProfileImage } from "../../data/user/image/image.actions";
import { setUserProfile } from "../../data/user/profile/profile.actions";
import { UserProfileState } from "../../data/user/profile/profile.state";
import { storeClanProfileResponse } from "../../data/user/profile/profileDataService";
import * as ROUTES from "../../routes";
import { ClanProfileResponse } from "../../util/models/ClanProfileModels";
import { CheckSignupDataResult } from "../Start/StartHelper";
import AppLanguageStep from "./AppLanguageStep";
import BirthdayStep from "./BirthdayStep";
import GenderStep from "./GenderStep";
import NameStep from "./NameStep";
import ProfileImageStep from "./ProfileImageStep";
import SignupSuccess from "./SignupSuccess";
import { ApiServiceMethod, AuthorizedApi } from "../../util/ApiService";
import ClanHeaderRays from "../../components/ClanHeaderRays";

export enum SignupSteps {
  APP_LANGUAGE = "app_language",
  NAME = "name",
  GENDER = "gender",
  BIRTHDAY = "birthday",
  PROFILE_IMAGE = "profile_image",
  SUCCESS = "success"
}

type OwnProps = RouteComponentProps;

interface StateProps {
  userProfileStateConnect: UserProfileState;
  userProfileImageThumbnailConnect: string | undefined;
}

interface DispatchProps {
  setUserProfileConnect: typeof setUserProfile;
  setUserProfileImageConnect: typeof setUserProfileImage;
  dispatchToast: typeof showToast;
}

type SignUserData = {
  firstName?: string;
  lastName?: string;
  gender?: string;
  genderInfo?: string;
  title?: string;
  dateOfBirth?: string;
};

interface SignupProps extends OwnProps, DispatchProps, StateProps {}

const Signup: React.FC<SignupProps> = ({
  history,
  location,
  setUserProfileConnect,
  setUserProfileImageConnect,
  dispatchToast,
  userProfileStateConnect,
  userProfileImageThumbnailConnect
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<SignupSteps>(
    SignupSteps.APP_LANGUAGE
  );
  const [image, setImage] = useState<string>();
  const { t } = useTranslation();
  const methods = useForm<SignUserData>({ shouldUnregister: false });

  const pushToErrors = (message: string) => {
    dispatchToast(message);
  };

  const [currentFooterBtns, setCurrentFooterBtns] = useState<
    | { title: string; primary: boolean; disabled: boolean; callback: any }[]
    | any
  >();

  useEffect(() => {
    const locationSignupData = location.state as CheckSignupDataResult & {
      isEditing: boolean;
      profileImagesResult: {
        url: string;
      };
    };
    const signupData = {
      ...locationSignupData,
      profileDataResult: userProfileStateConnect,
      profileImagesResult: { url: userProfileImageThumbnailConnect },
      viewToLoad: locationSignupData?.viewToLoad || SignupSteps.APP_LANGUAGE
    };
    if (
      signupData?.profileDataResult ||
      signupData?.profileImagesResult ||
      signupData?.isEditing
    ) {
      const profileDataResult = signupData?.profileDataResult;
      const profileImagesResult = signupData?.profileImagesResult;
      const viewToLoad = signupData?.viewToLoad;
      const editStep = signupData?.isEditing;
      if (editStep) {
        console.debug("[SIGNUP_PAGE] Edit mode on");
        setIsEditing(true);
      } else {
        console.debug("[SIGNUP_PAGE] Edit mode off");
        setIsEditing(false);
      }
      if (viewToLoad === SignupSteps.SUCCESS) {
        console.debug(
          "[SIGNUP_PAGE] All data already provided. Navigating to home..."
        );
        history.replace(ROUTES.ONBOARDING, { direction: "none" });
      }
      if (Object.values(SignupSteps).indexOf(viewToLoad) < 5) {
        methods.setValue("firstName", profileDataResult?.firstName);
        methods.setValue("lastName", profileDataResult?.lastName);
        methods.setValue("dateOfBirth", profileDataResult?.dateOfBirth);
        methods.setValue("title", profileDataResult?.title);
        if (["male", "female"].includes(profileDataResult?.gender || "")) {
          methods.setValue("gender", profileDataResult?.gender);
        } else {
          methods.setValue("genderInfo", profileDataResult?.gender || "");
        }
        if (profileImagesResult?.url?.length !== 0) {
          setImage(profileImagesResult?.url);
        }
      }
      setCurrentStep(viewToLoad);
    } else {
      console.debug(
        "[SIGNUP_PAGE] No state object was transferred. Asking user to provide all info"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (currentStep === SignupSteps.SUCCESS) {
      setAnalyticsScreenName("SIGNUP_SUCCESS");
      analyticsLogEvent("SIGNUP_SUCCESS_show");
    } else {
      setAnalyticsScreenName(`SIGNUP_${currentStep}`);
      analyticsLogEvent(`SIGNUP_${currentStep}_show`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const submitSignupData = async () => {
    console.debug("[SIGNUP_PAGE] Posting submitted data to backend...");
    try {
      setLoading(true);
      const birthDate = methods
        .getValues("dateOfBirth")
        ?.match(/\d{4}-\d{2}-\d{2}/);
      const signupDataResponse = await AuthorizedApi.rawRequest<{
        result: ClanProfileResponse;
      }>({
        method: ApiServiceMethod.POST,
        url: "/user/profile/v1",
        data: {
          dateOfBirth: birthDate ? birthDate[0] : undefined,
          firstName: methods.getValues("firstName"),
          gender:
            methods.getValues("genderInfo") || methods.getValues("gender"),
          lastName: methods.getValues("lastName"),
          title: methods.getValues("title")
        }
      });
      if (signupDataResponse && signupDataResponse.status === 200) {
        const userProfileState = await storeClanProfileResponse(
          signupDataResponse.data.result
        );
        await setUserProfileConnect(userProfileState);
        console.debug(
          "[SIGNUP_PAGE] Signup data submission response was successfull ",
          signupDataResponse
        );
        isEditing ? history.go(-2) : setCurrentStep(SignupSteps.PROFILE_IMAGE);
      } else {
        pushToErrors("Something went wrong. Try again later.");
        console.error(
          "[SIGNUP_PAGE] Couldn't recognize backend signupData response",
          signupDataResponse
        );
      }
    } catch (error) {
      pushToErrors(error.message);
      console.error(
        `[SIGNUP_PAGE] Couldn't submit signup info: ${error.message}`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const submitProfileImage = async () => {
    console.debug("[SIGNUP_PAGE] Posting submitted image to backend...");
    try {
      setLoading(true);
      const profileImageResponse = await AuthorizedApi.post<any>({
        url: "/user/profile/v1/image",
        data: { image }
      });
      if (profileImageResponse) {
        // TODO: Caching the image with the local file as server version takes time to be ready
        // In future can use push notification to detect when images are ready and cache them (and update app state)
        await setUserProfileImageConnect({
          profileImage: image,
          profileImageThumbnail: image,
          isCached: true
        });
        console.debug(
          "[SIGNUP_PAGE] Signup data submission response was successfull ",
          profileImageResponse
        );
        isEditing ? history.go(-2) : setCurrentStep(SignupSteps.SUCCESS);
      } else {
        pushToErrors("Something went wrong. Try again later.");
        console.error(
          "[SIGNUP_PAGE] Couldn't recognize backend profileImageResponse response",
          profileImageResponse
        );
      }
    } catch (error) {
      pushToErrors(error.message);
      console.error(
        `[SIGNUP_PAGE] Couldn't submit signup info: ${error.message}`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <ClanDesktopLayout leftComponent="">
      <IonPage id="signup-page">
        {!isEditing && (
          <IonProgressBar
            color="primary"
            className="h-2"
            value={Object.values(SignupSteps).indexOf(currentStep) / 5}
          />
        )}
        <ClanLoader message={t("general.loading")} showLoading={loading} />
        <ClanHeaderRays short />
        <FormProvider {...methods}>
          <AppLanguageStep
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            loading={loading}
            setCurrentFooterBtns={setCurrentFooterBtns}
            isEditing={isEditing}
          />
          <NameStep
            pushToErrors={pushToErrors}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            loading={loading}
            submitSignupData={submitSignupData}
            setCurrentFooterBtns={setCurrentFooterBtns}
            isEditing={isEditing}
            history={history}
          />
          <GenderStep
            pushToErrors={pushToErrors}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            loading={loading}
            setCurrentFooterBtns={setCurrentFooterBtns}
          />
          <BirthdayStep
            pushToErrors={pushToErrors}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            loading={loading}
            submitSignupData={submitSignupData}
            setCurrentFooterBtns={setCurrentFooterBtns}
            isEditing={isEditing}
            history={history}
          />
          <ProfileImageStep
            pushToErrors={pushToErrors}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            loading={loading}
            submitProfileImage={submitProfileImage}
            setCurrentFooterBtns={setCurrentFooterBtns}
            isEditing={isEditing}
            history={history}
            image={image}
            setImage={setImage}
          />
          <SignupSuccess
            currentStep={currentStep}
            image={image}
            setCurrentFooterBtns={setCurrentFooterBtns}
          />
        </FormProvider>
        <IonFooter>
          <ClanFooterBtn buttonObjects={currentFooterBtns} />
        </IonFooter>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    userProfileStateConnect: state.userProfile,
    userProfileImageThumbnailConnect:
      state.userProfileImage.profileImageThumbnail
  }),
  mapDispatchToProps: {
    setUserProfileConnect: setUserProfile,
    setUserProfileImageConnect: setUserProfileImage,
    dispatchToast: showToast
  },
  component: Signup
});
