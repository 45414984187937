import { IonAvatar, IonPage } from "@ionic/react";
import { useAsyncEffect } from "@react-hook/async";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import styled from "styled-components";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import Badge from "../../components/Badge";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import ClanLoader from "../../components/ClanLoader";
import Header from "../../components/Headers/Header";
import NestPicker from "../../components/NestPicker";
import ActiveSchoolFilter from "../../components/SchoolView/ActiveSchoolFilter";
import FilterButton from "../../components/SchoolView/SchoolFilter";
import TabFilter from "../../components/TabFilter";
import { connect } from "../../data/connect";
import { OauthState } from "../../data/oauth/oauth.state";
import { OauthData } from "../../data/oauth/oauthDataService";
import { showToast } from "../../data/toasts/toasts.actions";
import * as ROUTES from "../../routes";
import {
  ChartResultFlagWithProfiles,
  ChartResultNumber,
  ChartResultPercentage,
  fetchAnalyticsDashboard
} from "../../util/Analytics";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { WellBeingQuestion } from "../../util/api/WellBeingService";
import { isReadyStateLoading } from "../../util/AsyncEffectFilter";
import {
  fetchReportedComplaints,
  UserComplaintListResult
} from "../../util/Complaint";
import { Views } from "../../util/Configuration";
import { useFilterResult } from "../../util/Filters";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import Alerts from "./Alerts";
import UsageStats from "./UsageStats";
import WellBeing from "./WellBeing";

export const EmptyText = styled.p`
  padding: 0 1.8rem;
`;

export const Label = styled.span`
  white-space: normal;
  font-size: 1rem;
  line-height: 1.29;
`;

export const StyledAvatar = styled(IonAvatar)`
  margin: 0 0.7rem 0 0;
`;

export const DashboardHeader = styled.h2`
  margin: 20px 25px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
`;

interface StateProps {
  tokenConnect: OauthState;
  wellbeingPermissions: boolean;
}

interface DispatchProps {
  dispatchToast: typeof showToast;
}

interface StatsDashboardProps
  extends RouteComponentProps,
    StateProps,
    DispatchProps {}

const StatsDashboard: React.FC<StatsDashboardProps> = ({
  tokenConnect,
  wellbeingPermissions,
  dispatchToast
}) => {
  const { token } = tokenConnect as OauthData;

  const history = useHistory();

  const [chartFlags, setChartFlags] = useState<ChartResultFlagWithProfiles[]>(
    []
  );
  const [chartReports, setChartReports] = useState<UserComplaintListResult[]>(
    []
  );
  const [chartNumbers, setChartNumbers] = useState<ChartResultNumber[]>([]);
  const [moodPercentages, setMoodPercentages] = useState<
    ChartResultPercentage[]
  >([]);

  const [wellBeingAnalytics, setWellBeingAnalytics] = useState<
    WellBeingQuestion[] | undefined | null
  >();
  const { t } = useTranslation();
  const location = useLocation();

  const [filters, setFilters, resolvedFilters] = useFilterResult(
    undefined,
    Views.DataDashboard
  );

  const fetchStatus = useAsyncEffect(async () => {
    if (filters.length === 0) {
      return;
    }
    try {
      await setAnalyticsScreenName("STATS_DASHBOARD");
      const reportedComplaints = await fetchReportedComplaints(
        tokenConnect,
        filters
      );
      await analyticsLogEvent("tasks_list_teacher_dashboard");
      setChartReports(reportedComplaints ? reportedComplaints : []);

      const dashboard = await fetchAnalyticsDashboard(tokenConnect, filters);
      await setWellBeingAnalytics(dashboard?.wellBeing);

      setChartFlags(dashboard?.flags ? dashboard.flags : []);
      setChartNumbers(dashboard?.numbers ? dashboard.numbers : []);
      setMoodPercentages(dashboard?.mood ? dashboard.mood : []);
    } catch (e) {
      dispatchToast(e.message);
      console.error("[ANALYTICS] Could not fetch analytics dashboard", e);
    }
  }, [filters, token]);

  const onReportAction = (report: UserComplaintListResult) => {
    setChartReports((currentReports) =>
      currentReports.filter((r) => r.id !== report.id)
    );
  };

  const onTabClick = (route: string) => () => history.push(route);
  const isActive = (route: string) => location.pathname === route;

  const goBack = () => history.push(ROUTES.DASHBOARD);

  const onClearFilters = () =>
    setFilters(
      resolvedFilters.defaultFilter ? [resolvedFilters.defaultFilter] : []
    );

  return (
    <ClanDesktopLayout>
      {isReadyStateLoading(fetchStatus) && (
        <ClanLoader message={t("general.loading")} showLoading={true} />
      )}

      <IonPage>
        <ClanIonContent className="overflow-auto">
          <Header
            onBack={goBack}
            title={
              <ActiveSchoolFilter
                filters={filters}
                clearFilters={onClearFilters}
                placeholder={<NestPicker />}
              />
            }
            actions={
              <FilterButton
                resolvedFilters={resolvedFilters}
                setFilters={setFilters}
                filters={filters}
                view={Views.DataDashboard}
              />
            }
          />

          <TabFilter>
            <Badge
              active={isActive(ROUTES.STATS_DASHBOARD_MOOD)}
              onClick={onTabClick(ROUTES.STATS_DASHBOARD_MOOD)}
            >
              {t("tabs.well-being")}
            </Badge>
            <Badge
              active={isActive(ROUTES.STATS_DASHBOARD_ALERTS)}
              onClick={onTabClick(ROUTES.STATS_DASHBOARD_ALERTS)}
            >
              {t("tabs.alerts")}
            </Badge>
            <Badge
              active={isActive(ROUTES.STATS_DASHBOARD_USAGE)}
              onClick={onTabClick(ROUTES.STATS_DASHBOARD_USAGE)}
            >
              {t("tabs.usage")}
            </Badge>
          </TabFilter>

          {wellbeingPermissions ? (
            <Switch>
              <Route
                path={ROUTES.STATS_DASHBOARD_MOOD}
                render={() => (
                  <WellBeing
                    wellBeingAnalytics={wellBeingAnalytics}
                    moodPercentages={moodPercentages}
                  />
                )}
              />

              <Route
                path={ROUTES.STATS_DASHBOARD_ALERTS}
                render={() => (
                  <Alerts
                    flags={chartFlags}
                    reports={chartReports}
                    onReportAction={onReportAction}
                  />
                )}
              />

              <Route
                exact
                path={ROUTES.STATS_DASHBOARD_USAGE}
                render={() => <UsageStats chartNumbers={chartNumbers} />}
              />

              <Redirect to={ROUTES.STATS_DASHBOARD_MOOD} />
            </Switch>
          ) : (
            <Redirect to={ROUTES.DASHBOARD} />
          )}
        </ClanIonContent>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: ({ token }) => ({
    tokenConnect: token,
    wellbeingPermissions: UserEnabledProperties.WellBeing.dashboard(token)
  }),
  mapDispatchToProps: {
    dispatchToast: showToast
  },
  component: StatsDashboard
});
