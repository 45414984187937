import React from "react";
import { IonButton, IonLabel, IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";

type ScheduleButtonProps = {
  isScheduled: boolean;
  onClick: () => void;
};

const ScheduleButton: React.FC<ScheduleButtonProps> = ({
  isScheduled,
  onClick
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center mx-1">
      <IonButton
        id="schedule-task-btn"
        style={{
          "--padding-start": "0.5rem",
          "--padding-end": "0.5rem"
        }}
        fill="clear"
        color="dark"
        mode="ios"
        onClick={() => {
          onClick();
        }}
        className={`p-0 m-0 ${
          isScheduled ? "text-black" : "text-clanGray-200"
        }`}
      >
        <IonLabel
          className={`m-0 p-0 text-clanP ${
            isScheduled ? "text-clanGreen-100" : "text-clanGray-200"
          }`}
        >
          {t("planner.schedule")}
        </IonLabel>
        <IonIcon
          className="h-4 p-0 m-0"
          slot="start"
          icon="/assets/planner/duedate.svg"
        />
      </IonButton>
    </div>
  );
};

export default ScheduleButton;
