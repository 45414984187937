import React, { useEffect } from "react";
import { useHistory } from "react-router";
import * as ROUTES from "../../routes";
import ClanInfoCard from "../../components/ClanInfoCard";
import { useTranslation } from "react-i18next";
import { SignupSteps } from "./Signup";
import { useFormContext } from "react-hook-form";
import { connect } from "../../data/connect";
import { OauthState } from "../../data/oauth/oauth.state";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "../Onboarding/Onboarding";

import { ReactComponent as DefaultAvatarIcon } from "../../icons/default-avatar.svg";

interface OwnProps {
  currentStep: SignupSteps;
  setCurrentFooterBtns: any;
  image: string | undefined;
}

interface StateProps {
  clanbeatTokenConnect: OauthState;
}

interface SignupSuccess extends OwnProps, StateProps {}

const SignupSuccess: React.FC<SignupSuccess> = ({
  currentStep,
  image,
  setCurrentFooterBtns,
  clanbeatTokenConnect
}) => {
  const { getValues } = useFormContext();
  const history = useHistory();
  const { t } = useTranslation();
  const isAdult = UserEnabledProperties.Legacy.isAdult(clanbeatTokenConnect);
  const bodyParagraphs = [
    t("signup.success_1"),
    ...(!isAdult ? [t("signup.success_2")] : [])
  ];

  useEffect(() => {
    if (currentStep === SignupSteps.SUCCESS) {
      setCurrentFooterBtns([
        {
          title: t("signup.success_yay"),
          disabled: false,
          callback: async () => {
            isAdult
              ? history.replace(ROUTES.DASHBOARD)
              : history.replace(ROUTES.ONBOARDING);
          }
        }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  if (currentStep !== SignupSteps.SUCCESS) {
    return null;
  }

  return (
    <ClanIonContent>
      <OnboardingWrapper>
        <ClanInfoCard
          title={t("signup.success_welldone", { name: getValues().firstName })}
          bodyParagraphs={bodyParagraphs}
          image={
            image ? <img src={image} alt="Well done" /> : <DefaultAvatarIcon />
          }
        />
      </OnboardingWrapper>
    </ClanIonContent>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: ({ token }) => ({
    clanbeatTokenConnect: token
  }),
  component: SignupSuccess
});
