import React from "react";
import styled from "styled-components";
import Avatar from "../Avatar";

import { H6 } from "../Typography";
import { ListItemCard } from "./ListItemCard";

const StyledListItem = styled(ListItemCard)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  column-gap: 4px;
`;

const StyledPrimaryText = styled(H6)<{ bold?: boolean }>`
  flex: 1;
  ${({ bold }) => bold && `font-weight: bold;`}
`;

interface ListItemProps {
  primary: any;
  avatar?: string;
  icon?: any;
  small?: boolean;
  bold?: boolean;
  selected?: boolean;
  flat?: boolean;
  onClick?: () => void;
}

const ListItem: React.FC<ListItemProps> = ({
  primary,
  avatar,
  icon,
  selected,
  bold,
  small,
  flat,
  children,
  ...props
}) => (
  <StyledListItem {...props}>
    {avatar && <Avatar url={avatar} />}
    <StyledPrimaryText bold={bold}>{primary}</StyledPrimaryText>
    {icon && icon}
  </StyledListItem>
);

export default ListItem;
