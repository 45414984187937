import { IonDatetime, IonIcon, IonLabel } from "@ionic/react";
import { calendar } from "ionicons/icons";
import React, { useEffect } from "react";
import ClanFlowStepTemplate from "../../components/ClanFlowStepTemplate";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SignupSteps } from "./Signup";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { OnboardingWrapper } from "../Onboarding/Onboarding";

interface BirthdayStepProps {
  pushToErrors: (message: string) => void;
  setCurrentStep: (step: SignupSteps) => void;
  currentStep: SignupSteps;
  loading: boolean;
  submitSignupData: any;
  setCurrentFooterBtns: any;
  isEditing: any;
  history: any;
}

const BirthdayStep: React.FC<BirthdayStepProps> = ({
  pushToErrors,
  currentStep,
  setCurrentStep,
  loading,
  submitSignupData,
  setCurrentFooterBtns,
  isEditing,
  history
}) => {
  const { trigger, errors, watch, control, getValues } = useFormContext();
  const watchDateOfBirth = watch("dateOfBirth");
  const { t } = useTranslation();

  const birthdayStepBtn = async () => {
    if (await trigger("dateOfBirth")) {
      submitSignupData();
    } else {
      pushToErrors(
        Object.keys(errors).reduce((allErrors, key) => {
          return allErrors.concat(errors[key].message);
        }, "")
      );
    }
  };

  useEffect(() => {
    if (currentStep === SignupSteps.BIRTHDAY) {
      setCurrentFooterBtns(
        isEditing
          ? [
              {
                title: t("general.cancel"),
                disabled: false,
                callback: () => history.goBack()
              },
              {
                title: t("general.ok"),
                disabled: loading || !watchDateOfBirth,
                callback: birthdayStepBtn
              }
            ]
          : [
              {
                title: t("general.back"),
                disabled: false,
                callback: () => setCurrentStep(SignupSteps.GENDER)
              },
              {
                title: t("general.next"),
                disabled: loading || !watchDateOfBirth,
                callback: birthdayStepBtn
              }
            ]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, loading, watchDateOfBirth]);

  if (currentStep !== SignupSteps.BIRTHDAY) {
    return null;
  }

  return (
    <ClanIonContent>
      <OnboardingWrapper>
        <ClanFlowStepTemplate title={t("signup.birthday_header")}>
          <div className="text-center">
            <IonIcon icon={calendar} slot="start" />
            <Controller
              control={control}
              defaultValue={null}
              render={({ onChange }) => (
                <IonDatetime
                  onIonChange={(e) => onChange(e.detail.value)}
                  displayFormat="MMM DD, YYYY"
                  className="text-clanH2 h-full font-extrabold font-gilroy"
                  placeholder="- / - / ----"
                  max={(() => {
                    const date = new Date();
                    date.setFullYear(date.getFullYear() - 5);
                    return date.toISOString();
                  })()}
                  value={getValues("dateOfBirth")}
                />
              )}
              name="dateOfBirth"
              rules={{
                required: {
                  value: true,
                  message: t("signup.birthday_required")
                },
                pattern: {
                  value: /\d{4}-\d{2}-\d{2}/,
                  message: t("signup.birthday_valid")
                }
              }}
            />
            <IonLabel position="stacked">
              <h4 className="text-clanGreen-100">
                {t("signup.birthday_edit")}
              </h4>
            </IonLabel>
          </div>
        </ClanFlowStepTemplate>
      </OnboardingWrapper>
    </ClanIonContent>
  );
};

export default BirthdayStep;
