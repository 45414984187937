import { FirebaseUser } from "../../../../data/app/app.state";
import { ClanOauthResponse } from "../../../models/ClanOauthResponse";
import { setAppError } from "../../../../data/app/app.actions";
import { dispatch } from "../../../../data/AppContext";
import { History } from "history";
import { analyticsLogEvent } from "../../../../Analytics";
import { setOauth } from "../../../../data/oauth/oauth.actions";
import { showToast } from "../../../../data/toasts/toasts.actions";
import * as ROUTES from "../../../../routes";
import { StartSteps } from "../../../../pages/Start/Start";
import {
  ApiRequestResponseType,
  ApiResponseErrorType,
  ApiServiceMethod,
  UnAuthorizedApi
} from "../../../ApiService";
import { AutoOnboardRequest } from "../types";
import { AutoOnboardResponse } from "./OrganisationOnboardingService";
import moment from "moment-timezone";

export type ClanOauthRequestResponse = {
  data: ClanOauthResponse;
  status: number;
};

export const testIfUserExistsWithFirebaseUser = async (
  firebaseUser: FirebaseUser
): Promise<boolean> => {
  try {
    const response = await UnAuthorizedApi.rawRequest<ClanOauthResponse>({
      url: "/auth/v2",
      method: ApiServiceMethod.POST,
      headers: { Authorization: `Bearer ${firebaseUser.idToken}` }
    });
    return response.status === 202;
  } catch (e) {
    if (e.type === ApiResponseErrorType.HTTP_NOT_FOUND) {
      return false;
    }
    await setAppError(e)(dispatch);
    throw e;
  }
};

export const authenticateWithFirebaseUser = async (
  history: History,
  firebaseUser: FirebaseUser,
  checkSignUp: boolean,
  hash?: string
): Promise<boolean> => {
  const response = await UnAuthorizedApi.rawRequest<ClanOauthResponse>({
    url: "/auth/v2",
    data: hash,
    method: ApiServiceMethod.POST,
    headers: {
      Authorization: `Bearer ${firebaseUser.idToken}`,
      "content-type": "text/plain"
    }
  });
  if (
    response.status === ApiRequestResponseType.OK ||
    response.status === ApiRequestResponseType.CREATED
  ) {
    await analyticsLogEvent("login");
    await setOauth(response.data)(dispatch);
    return true;
  } else if (response.status === ApiRequestResponseType.ACCEPTED) {
    if (checkSignUp) {
      showToast("Please sign up first!")(dispatch);
      history.replace(ROUTES.START, {
        initialStep: StartSteps.ROLESELECT
      });
    } else {
      //throw new Error("User could not be created!");
    }
  }
  return false;
};

export const doOnboardClanBeatTeacher = async (
  firebaseUser: FirebaseUser,
  onboardRequest?: AutoOnboardRequest
): Promise<AutoOnboardResponse> => {
  if (onboardRequest && !onboardRequest?.timeZone) {
    try {
      onboardRequest.timeZone = moment.tz.guess();
    } catch (e) {
      // Ignore this as it is just a wild guess
      console.warn("Error while guessing the timezone", e);
    }
  }
  const response = await UnAuthorizedApi.rawRequest<ClanOauthResponse>({
    url: "/onboard/v1",
    data: onboardRequest,
    method: ApiServiceMethod.POST,
    headers: {
      Authorization: `Bearer ${firebaseUser.idToken}`
    }
  });
  return {
    isOrganisationCreated: response.status === 201,
    wasOrganizationAlreadyCreated: response.status === 200,
    response: response.data
  };
};
