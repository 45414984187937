import { IonPage, IonProgressBar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import { connect } from "../../data/connect";
import { OauthState } from "../../data/oauth/oauth.state";
import { OauthData } from "../../data/oauth/oauthDataService";
import { FeatureService } from "../../util/api/FeatureService";
import BucketListStep from "./BucketListStep";
import GoldenRuleStep from "./GoldenRuleStep";
import InterestsStep from "./InterestsStep";
import LanguagesStep from "./LanguagesStep";
import NationalitiesStep from "./NationalitiesStep";
import OnboardingIntroStep from "./OnboardingIntroStep";
import OnboaringSuccessStep from "./OnboaringSuccessStep";
import SocialLinksStep from "./SocialLinksStep";

export const OnboardingWrapper = styled.div`
  max-width: 90%;
  margin: 42px auto 0;
`;

interface StateProps {
  oauthState: OauthState | OauthData;
}

type OnBoardingProps = RouteComponentProps & StateProps;

const Onboarding: React.FC<OnBoardingProps> = ({ location, oauthState }) => {
  const editStep = (location.state as any)?.currentStep;
  const isEditing = typeof editStep === "number";

  const [currentStep, setCurrentStep] = useState<number>(editStep || 1);

  const socialLinksDisabled = FeatureService.PROFILE_DISABLE_SOCIAL_LINKS(
    oauthState
  );

  const setCurrentStepValue = (value: number) => {
    if (socialLinksDisabled && value === 6) {
      if (currentStep < 6) setCurrentStep(7);
      else setCurrentStep(8);
    } else {
      setCurrentStep(value);
    }
  };

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    setAnalyticsScreenName(`ONBOARDING_${currentStep}`);
    // noinspection JSIgnoredPromiseFromCall
    analyticsLogEvent(`ONBOARDING_${currentStep}_show`);
  }, [currentStep]);

  return (
    <ClanDesktopLayout leftComponent="">
      <IonPage id="onboarding-page">
        {!isEditing && (
          <IonProgressBar
            color="primary"
            className="h-2"
            // This is because nationalities is not part of onboarding flow anymore
            value={Math.max(currentStep - 1, 1) / 7}
          />
        )}

        {currentStep === 1 && (
          <OnboardingIntroStep setCurrentStep={setCurrentStepValue} />
        )}

        {currentStep === 2 && (
          <NationalitiesStep
            isEditing={isEditing}
            setCurrentStep={setCurrentStepValue}
          />
        )}

        {currentStep === 3 && (
          <LanguagesStep
            isEditing={isEditing}
            setCurrentStep={setCurrentStepValue}
          />
        )}

        {currentStep === 4 && (
          <InterestsStep
            isEditing={isEditing}
            setCurrentStep={setCurrentStepValue}
          />
        )}

        {currentStep === 5 && (
          <BucketListStep
            isEditing={isEditing}
            setCurrentStep={setCurrentStepValue}
          />
        )}

        {currentStep === 6 && (
          <SocialLinksStep
            isEditing={isEditing}
            setCurrentStep={setCurrentStepValue}
          />
        )}
        {currentStep === 7 && (
          <GoldenRuleStep setCurrentStep={setCurrentStepValue} />
        )}
        {currentStep === 8 && (
          <OnboaringSuccessStep setCurrentStep={setCurrentStepValue} />
        )}
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<RouteComponentProps, {}, {}>({
  mapStateToProps: (state) => ({
    oauthState: state.token
  }),
  component: Onboarding
});
