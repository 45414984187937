import React from "react";
import { ListItemCard } from "../ListItem/ListItemCard";
import styled from "styled-components";
import { H6 } from "../Typography";
import Button from "./Button";

import { ReactComponent as LlamaIcon } from "../../icons/llama-icon.svg";

const StyledUIButton = styled(ListItemCard)`
  padding: 16px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  justify-items: flex-start;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  grid-area: 1 / 2 / 3 / 3;
  align-self: center;

  img {
    display: block;
  }
`;

const LinkWrapper = styled.a`
  text-decoration: inherit;
  color: inherit;
`;

interface BigUIButtonProps {
  primaryText?: string;
  secondaryText?: string;
  icon?: any;
  iconUrl?: any;
  href?: string;
  onClick?: () => void;
}

const BigUIButton: React.FC<BigUIButtonProps> = ({
  primaryText,
  secondaryText,
  icon,
  iconUrl,
  href,
  onClick
}) => {
  const buttonContent = (
    <StyledUIButton onClick={onClick}>
      <H6>{primaryText}</H6>
      <Button>{secondaryText}</Button>
      {(icon || iconUrl) && (
        <IconWrapper>{icon || <img src={iconUrl} alt="" />}</IconWrapper>
      )}
    </StyledUIButton>
  );

  return href ? (
    <LinkWrapper href={href} target="_blank">
      {buttonContent}
    </LinkWrapper>
  ) : (
    buttonContent
  );
};

export default BigUIButton;

export const LlamaUIButton: React.FC<BigUIButtonProps> = (props) => (
  <BigUIButton {...props} icon={<LlamaIcon />} />
);
