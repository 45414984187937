import { IonIcon, IonImg } from "@ionic/react";
import {
  checkmarkOutline,
  homeSharp,
  peopleCircleOutline
} from "ionicons/icons";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import { connect } from "../data/connect";
import { ImageState } from "../data/user/image/image.state";
import { ReactComponent as FeedsActiveIcon } from "../icons/feeds-active.svg";
import * as ROUTES from "../routes";
import { desktopOnlyStyles } from "../util/ResponsiveUtils";

const StyledBottomNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0 0.25rem;
  border-top: 1px solid #ededed;
  box-sizing: content-box;
  background: #fff;
  ${desktopOnlyStyles({
    display: "none"
  })}
`;
interface StyledNavBtnProps {
  active: string;
}

const StyledNavBtn = styled(Link)<StyledNavBtnProps>`
  flex: 1;
  color: ${({ active, theme }) => (active === "true" ? theme.primary : "#666")};
  padding: 1rem 0;
`;

const NavBtnContent = styled.div`
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  background-color: #fafafa;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;

  ion-img {
    width: 100%;
    height: 100%;
  }
`;

const NavBtnIcon = styled(IonIcon)`
  font-size: 1.5rem;
  display: block;
  top: 50%;
  transform: translate3D(-50%, -50%, 0);
  position: absolute;
  left: 50%;
`;

const IconBtnWrapper = styled.div`
  display: block;
  top: 50%;
  transform: translate3D(-50%, -50%, 0);
  position: absolute;
  left: 50%;
  width: 1.5rem;
  height: 1.5rem;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const ProfileAvatar = styled(IonImg)`
  object-fit: cover;
`;

interface NavBtnProps {
  id: string;
  to: string;
  active: boolean;
  icon?: string;
  children?: (active: boolean) => JSX.Element;
}

const NavBtn: React.FC<NavBtnProps> = ({ children, id, to, active, icon }) => {
  return (
    <StyledNavBtn id={id} to={to} active={active.toString()}>
      <NavBtnContent>
        {icon && <NavBtnIcon icon={icon} />}
        {!icon && children && children(active)}
      </NavBtnContent>
    </StyledNavBtn>
  );
};

interface BottomNavOwnProps {
  className?: string;
}
interface BottomNavStateProps {
  profileImagesConnect: ImageState;
}

interface BottomNavProps extends BottomNavOwnProps, BottomNavStateProps {}

const BottomNav: React.FC<BottomNavProps> = ({
  profileImagesConnect,
  className
}) => {
  const location = useLocation();

  const isActive = (route: string) => location.pathname === route;

  return (
    <StyledBottomNav className={className}>
      <NavBtn
        id="nav-dashboard"
        to={ROUTES.DASHBOARD}
        active={isActive(ROUTES.DASHBOARD)}
        icon={homeSharp}
      />
      <NavBtn
        id="nav-planner"
        to={ROUTES.PLANNER_DAY}
        active={isActive(ROUTES.PLANNER_DAY)}
        icon={checkmarkOutline}
      />
      <NavBtn
        id="nav-profile"
        to={ROUTES.PROFILE}
        active={isActive(ROUTES.PROFILE)}
      >
        {() => (
          <ProfileAvatar
            alt="My profile"
            src={
              profileImagesConnect.profileImageThumbnail
                ? profileImagesConnect.profileImageThumbnail
                : `/assets/navigation/profile.svg`
            }
          />
        )}
      </NavBtn>
      <NavBtn
        id="nav-feeds"
        to={ROUTES.PUBLIC_FEEDS}
        active={isActive(ROUTES.PUBLIC_FEEDS)}
      >
        {(active: boolean) =>
          active ? (
            <IconBtnWrapper>
              <FeedsActiveIcon />
            </IconBtnWrapper>
          ) : (
            <NavBtnIcon src={`/assets/navigation/feeds.svg`} />
          )
        }
      </NavBtn>
      <NavBtn
        id="nav-people"
        to={ROUTES.PEOPLE}
        active={isActive(ROUTES.PEOPLE)}
        icon={peopleCircleOutline}
      />
    </StyledBottomNav>
  );
};

export default connect<BottomNavOwnProps, BottomNavStateProps>({
  mapStateToProps: (state) => ({
    profileImagesConnect: state.userProfileImage
  }),
  component: BottomNav
});
