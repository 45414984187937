import { GroupDetailsResponse } from "../../api/OrganisationGroupService";
import { AuthorizedApiResult } from "../../ApiService";

export const setGroupNameRequest = async (
  groupId: number | string,
  groupName: string
): Promise<GroupDetailsResponse> =>
  AuthorizedApiResult.put<GroupDetailsResponse>({
    url: `/group/v2/${groupId}/name`,
    data: groupName,
    headers: { "content-type": "text/plain" }
  });
