import {
  IonFooter,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonImg
} from "@ionic/react";
import React from "react";
import ClanHeaderRays from "../ClanHeaderRays";
import { useTranslation } from "react-i18next";
import { ClanIonContent } from "../../pages/DiscussionFeed/PublicFeeds";

export type ClanTaskSharedModalProps = {
  taskSharedTitle: string;
  onDone: () => void;
  sharedProfiles: Array<number>;
  sharedGroups: Array<number>;
};

const ClanTaskSharedModal: React.FC<ClanTaskSharedModalProps> = ({
  taskSharedTitle,
  sharedProfiles,
  sharedGroups,
  onDone
}) => {
  const { t } = useTranslation();
  return (
    <IonModal isOpen={!!taskSharedTitle} onDidDismiss={onDone}>
      <ClanHeaderRays short />
      <div className="text-center flex items-center justify-center">
        <IonImg src="/assets/shared_task.png" className="h-36"></IonImg>
      </div>
      <ClanIonContent>
        <div className="px-clanFlowStepScreenEdge text-center">
          <div className="border-solid border-clanYellow-100 border-opacity-75 border-t-1 border-b-1 m-clanRelativeFillScreen my-6">
            <IonText>
              <p className="max-w-clanFullScreenWidth mx-clan25percent">
                {taskSharedTitle}
              </p>
            </IonText>
          </div>
          <IonText>
            <h3 className="my-1">{t("planner.well_done")}</h3>
          </IonText>
          <IonText>
            <h4>{`${t("planner.shared_goal")} ${
              sharedGroups.length !== 0
                ? t("planner.your_group")
                : t("planner.classmates")
            }`}</h4>
            <br />
            <h4>{t("planner.others_can_add")}</h4>
          </IonText>
        </div>
      </ClanIonContent>
      <IonFooter>
        <IonSegment className="bg-clanYellow-100">
          <IonSegmentButton
            color="primary"
            className="font-extrabold font-gilroy text-black bg-clanYellow-100"
            onClick={onDone}
          >
            {t("planner.yay")}
          </IonSegmentButton>
        </IonSegment>
      </IonFooter>
    </IonModal>
  );
};

export default ClanTaskSharedModal;
