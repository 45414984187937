import { AuthorizedApiResult } from "../../ApiService";
import {
  UserPropertiesDataMap,
  UserPropertiesPersistedDataType,
  UserPropertiesValueType
} from "../types/UserPropertiesData.d";
import { getOauthData } from "../../../data/oauth/oauthDataService";

interface UserPropertyRequest {
  properties: UserPropertiesDataMap;
}

export const fetchUserProperties = async (): Promise<UserPropertiesDataMap> => {
  const token = await getOauthData();
  if (!token.hasToken) {
    return {};
  }
  const result = await AuthorizedApiResult.get<UserPropertyRequest>(
    "/user/v1/properties"
  );
  return result.properties;
};

export const sendUserProperties = async (
  data: UserPropertiesDataMap
): Promise<UserPropertiesDataMap> => {
  const token = await getOauthData();
  if (!token.hasToken) {
    return {};
  }
  const result = await AuthorizedApiResult.put<UserPropertyRequest>({
    url: "/user/v1/properties",
    data: { properties: data } as UserPropertyRequest
  });
  return result.properties;
};

export const sendUserProperty = async (
  key: UserPropertiesPersistedDataType,
  value: UserPropertiesValueType | undefined
): Promise<UserPropertiesDataMap> => {
  const props: UserPropertiesDataMap = {};
  props[key] = value ? value : null;
  return sendUserProperties(props);
};
