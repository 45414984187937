import { IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useLocation } from "react-router-dom";
import styled from "styled-components";

import { analyticsLogEvent, setAnalyticsScreenName } from "../../Analytics";
import ClanDesktopLayout from "../../components/ClanDesktopLayout";
import LargeTabs, { useLargeTabs } from "../../components/ClanLargeTabs";
import FooterButtons, {
  PrimaryFooterButton
} from "../../components/FooterButtons";
import ProfileMeta from "../../components/Profile/ProfileMeta";
import { connect } from "../../data/connect";
import { OauthData } from "../../data/oauth/oauthDataService";
import { ImageState } from "../../data/user/image/image.state";
import { UserProfileState } from "../../data/user/profile/profile.state";
import { ReactComponent as ProfileBgImage } from "../../icons/profile-bg.svg";
import * as ROUTES from "../../routes";
import { UserEnabledProperties } from "../../util/api/UserEnabledPropertiesService";
import { desktopOnlyStyles } from "../../util/ResponsiveUtils";
import { mapSocialLinks } from "../../util/SocialLinks";
import { ClanIonContent } from "../DiscussionFeed/PublicFeeds";
import { SignupSteps } from "../Signup/Signup";
import ProfileAbout from "./ProfileAbout";
import ProfileInsights from "./ProfileInsights";

const ProfileImageRays = styled(ProfileBgImage)`
  ${desktopOnlyStyles({
    display: "none"
  })}
`;

export enum ProfileTabs {
  InsightsTab = "Insights",
  AboutTab = "About",
  TrendsTab = "Trends"
}

interface StateProps {
  profileId?: number;
  profileData: UserProfileState;
  profileImagesConnect: ImageState;
  isTeacher: boolean;
  canSeeOwnWellbeing: boolean;
}

type OwnProps = RouteComponentProps;

interface ProfileProps extends StateProps, OwnProps {}

const Profile: React.FC<ProfileProps> = ({
  history,
  profileId,
  profileData,
  profileImagesConnect,
  isTeacher,
  canSeeOwnWellbeing
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const locationState = location.state as {
    initialShowNewPost?: boolean;
  };

  const initialShowNewPost = locationState?.initialShowNewPost;

  const [activeTab] = useLargeTabs(ProfileTabs);

  const [profileImage, setProfileImage] = useState<string | undefined>();
  const [showNewPost, setShowNewPost] = useState<boolean>(!!initialShowNewPost);

  useEffect(() => {
    (async () => {
      await setAnalyticsScreenName("PROFILE");
      await analyticsLogEvent("PROFILE_show");
    })();
  }, []);

  useEffect(() => {
    if (profileImagesConnect) {
      analyticsLogEvent("PROFILE_set_image");
      setProfileImage(profileImagesConnect.profileImage);
    }
  }, [profileImagesConnect]);

  const onProfileImageChange = () =>
    history.push(ROUTES.SIGN_UP, {
      profileImagesResult: {
        url: profileImagesConnect.profileImage
      },
      viewToLoad: SignupSteps.PROFILE_IMAGE,
      isEditing: true
    });

  const openTrends = () =>
    !!profileId &&
    history.push(
      ROUTES.PROFILE_TRENDS.replace(":profileId", profileId.toString())
    );

  return (
    <ClanDesktopLayout>
      <IonPage>
        <ClanIonContent id="profile-content" className="flex">
          <ProfileImageRays className="w-full -mb-32" />

          <ProfileMeta
            showToolbar
            fullName={profileData?.fullName}
            profileImage={profileImage}
            dateOfBirth={profileData?.dateOfBirth}
            nationalities={profileData?.nationalities}
            languages={profileData?.languages}
            onProfileImageChange={onProfileImageChange}
            isTeacher={isTeacher}
          />

          <LargeTabs
            type={ProfileTabs}
            tabs={[
              {
                id: "profiletab-insights",
                key: ProfileTabs.InsightsTab,
                title: t("profile.insights"),
                icon: "/assets/profile-insights.svg",
                activeIcon: "/assets/profile-insights-white.svg"
              },
              {
                id: "profiletab-about",
                key: ProfileTabs.AboutTab,
                title: t("profile.about"),
                icon: "/assets/about.svg",
                activeIcon: "/assets/about-white.svg"
              },
              {
                id: "profiletab-trends",
                key: ProfileTabs.TrendsTab,
                title: t("profile.trends"),
                icon: "/assets/insights.svg",
                activeIcon: "/assets/insights-white.svg",
                onClick: openTrends
              }
            ]}
          />

          {activeTab === ProfileTabs.InsightsTab && (
            <ProfileInsights
              showNewPost={showNewPost}
              setShowNewPost={setShowNewPost}
            />
          )}
          {activeTab === ProfileTabs.AboutTab && (
            <ProfileAbout
              interests={profileData?.interests}
              bucketList={profileData?.bucketList}
              socialContacts={mapSocialLinks(profileData?.socialLinks)}
            />
          )}
        </ClanIonContent>
        <FooterButtons>
          <PrimaryFooterButton onClick={() => history.push(ROUTES.REFLECTIONS)}>
            {t("profile.time_to_reflect")}
          </PrimaryFooterButton>
        </FooterButtons>
      </IonPage>
    </ClanDesktopLayout>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    profileId: (state.token as OauthData).profileId,
    profileData: state.userProfile,
    profileImagesConnect: state.userProfileImage,
    isTeacher: UserEnabledProperties.Legacy.isAdult(state.token),
    canSeeOwnWellbeing: UserEnabledProperties.WellBeing.myProfile(state.token)
  }),
  component: Profile
});
