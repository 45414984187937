import { AsyncReducerState, useAsyncEffect } from "@react-hook/async";
import React, { useEffect, useState } from "react";

export const useValueAsyncEffect = <ValueType, ErrorType extends any = Error>(
  defaultValue: ValueType,
  asyncCallback: () => Promise<ValueType | undefined>,
  dependencies?: React.DependencyList
): [ValueType, AsyncReducerState<ValueType, ErrorType>] => {
  const [result, setResult] = useState<ValueType>(defaultValue);
  const resultResolver = useAsyncEffect<ValueType | undefined, ErrorType>(
    asyncCallback,
    dependencies
  );

  useEffect(() => {
    if (
      resultResolver.status === "success" &&
      resultResolver.value !== undefined &&
      resultResolver.value !== result
    ) {
      setResult(resultResolver.value);
    }
  }, [result, resultResolver]);
  return [result, resultResolver];
};
