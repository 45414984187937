import React, { useState, useEffect } from "react";
import { UserTaskResult } from "../../util/models/PlannerUserTaskModels";
import { IonButton, IonIcon, IonItem, IonLabel, IonChip } from "@ionic/react";
import {
  checkmarkCircleOutline,
  ellipseOutline,
  addCircleSharp
} from "ionicons/icons";
import { WEIGHTSCALE } from "../../pages/Planner/PlannerDay";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../routes";
import { format } from "date-fns";
import { et } from "date-fns/locale";
import ClanProfileAvatar from "../ClanProfileAvatar";
import { ProfileWithImage } from "../../util/models/ClanProfileModels";
import i18n from "../../i18n";

export type PlannerItemProps = {
  item: UserTaskResult;
  onCompleteSwitch?: () => void;
  onAdd?: () => void;
  addDisabled?: boolean;
  onClick?: () => void;
  dateChosen?: Date;
  className?: string;
  chipClassName?: string;
  cardClassName?: string;
  sharedTaskOwner?: ProfileWithImage;
};

const PlannerItem: React.FC<PlannerItemProps> = ({
  item,
  onCompleteSwitch,
  onAdd,
  addDisabled = false,
  onClick,
  dateChosen,
  className = "",
  chipClassName = "",
  cardClassName = "",
  sharedTaskOwner
}) => {
  const history = useHistory();
  const [weightClass, setWeightClass] = useState<string>("text-clanGray-200");
  const [weightIcon, setWeightIcon] = useState<string>("weight-none");
  const onItemClicked = async (target?: HTMLElement) => {
    if (!onClick) return;
    if (
      target?.tagName.toUpperCase() !== "ION-LABEL" &&
      target?.tagName.toUpperCase() !== "ION-ITEM"
    ) {
      return;
    }
    onClick();
  };

  useEffect(() => {
    switch (item?.weight) {
      case undefined:
        setWeightClass("text-clanGray-200");
        setWeightIcon("weight-none");
        break;
      case WEIGHTSCALE.EASY:
        setWeightClass("text-clanGreen-100");
        setWeightIcon("weight-easy");
        break;
      case WEIGHTSCALE.MEDIUM:
        setWeightClass("text-clanYellow-100");
        setWeightIcon("weight-medium");
        break;
      case WEIGHTSCALE.HARD:
        setWeightClass("text-red-600");
        setWeightIcon("weight-hard");
        break;
      default:
        setWeightClass("text-clanGray-200");
        setWeightIcon("weight-none");
    }
  }, [item]);

  async function loadPerson(personId: number) {
    history.push(
      ROUTES.PUBLIC_PROFILE.replace(":profileId", personId.toString())
    );
  }

  return (
    <>
      <div
        className={`flex flex-col border border-solid border-r-0 border-l-0 border-t-0 border-gray-100 ${className}`}
      >
        <IonItem
          className={`my-1 cursor-pointer ${cardClassName}`}
          color="light"
          onClick={(e) => onItemClicked(e.target as HTMLElement)}
          style={{
            "--padding-start": "0",
            "--padding-end": "0",
            "--inner-padding-end": "0",
            "--inner-padding-start": "0"
          }}
        >
          {sharedTaskOwner ? (
            <ClanProfileAvatar
              src={sharedTaskOwner.images?.thumbnail?.url}
              className="h-8 w-8 mr-2 cursor-pointer"
              onClick={() => loadPerson(sharedTaskOwner.id)}
            />
          ) : (
            <IonIcon
              className={`h-4 ml-0 mr-2 ${weightClass}`}
              slot="start"
              icon={`/assets/planner/${weightIcon}.svg`}
            />
          )}
          <IonLabel className="whitespace-normal text-clanH4 font-notoSans">
            {item.title}
          </IonLabel>
          {!onCompleteSwitch ? null : (
            <IonButton
              onClick={() => {
                onCompleteSwitch();
              }}
              className="text-clanGreen-100 h-clanBtn text-clanH2 align-middle cursor-pointer"
              color="light"
            >
              {item.completedDate ? (
                <IonIcon
                  className="text-clanGreen-100 text-3xl"
                  icon={checkmarkCircleOutline}
                />
              ) : (
                <IonIcon
                  className="text-clanGreen-100 text-3xl"
                  icon={ellipseOutline}
                />
              )}
            </IonButton>
          )}
          {!onAdd ? null : (
            <IonButton
              disabled={addDisabled}
              onClick={() => {
                onAdd();
              }}
              className="text-clanGreen-100 align-middle m-0"
              color="light"
            >
              <IonIcon
                className={`text-3xl ${
                  addDisabled ? "text-clanGray-300" : "text-clanGreen-100"
                }`}
                icon={addCircleSharp}
              />
            </IonButton>
          )}
        </IonItem>
        <span className={`self-start flex flex-wrap my-0 ${chipClassName}`}>
          {item?.dueDate && (
            <IonChip className="bg-clanGray-100 m-0 text-clanH5 self-start">
              <IonIcon
                size="small"
                className="text-clanGray-200"
                icon="/assets/planner/duedate.svg"
              />
              <IonLabel className="text-clanGray-200">
                {i18n.language === "et"
                  ? format(new Date(item.dueDate), "LLL d", { locale: et })
                  : format(new Date(item.dueDate), "LLL d")}
              </IonLabel>
            </IonChip>
          )}

          {item?.labels &&
            item?.labels.map((label, index) => (
              <IonChip
                key={index}
                className="bg-clanGray-100 m-0 text-clanH5 self-start"
              >
                <IonLabel
                  className="text-clanGreen-100 cursor-pointer"
                  onClick={() =>
                    dateChosen &&
                    history.push(ROUTES.PLANNER_EXISTING, {
                      dateChosen,
                      label
                    })
                  }
                >
                  #{label}
                </IonLabel>
              </IonChip>
            ))}
        </span>
      </div>
    </>
  );
};

export default PlannerItem;
